<h1 mat-dialog-title>Validar celular</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <h5>Se envió un mensaje de texto con el código a {{newValue}}</h5>
        <app-input-errors-global fieldName="newValue"></app-input-errors-global>
        <app-input-errors-global fieldName="code"></app-input-errors-global>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="100%" appearance="fill">
                <mat-label>Código</mat-label>
                <input matInput type="text" placeholder="000000" formControlName="code" autocapitalize="off" maxlength="6">
                <mat-error *ngIf="validateRequiredForm('code')">{{requiredErrorMessage}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="accent">
            <span class="material-icons">check</span> Validar
        </button>
        <button mat-button mat-dialog-close>Cerrar</button>
    </div>
</form>