import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { subscriptionpaymentDTO } from 'src/app/models/subscriptionpayment/subscriptionpayment.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { HtmlViewerComponent } from 'src/app/views/html-viewer/html-viewer.component';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogProofOfPaymentComponent } from '../dialog-proof-of-payment/dialog-proof-of-payment.component';
import { DialogSubscriptionDetailComponent } from '../dialog-subscription-detail/dialog-subscription-detail.component';

@Component({
	selector: 'app-subscriptionpayment-list',
	templateUrl: './subscriptionpayment-list.component.html',
	styleUrls: ['./subscriptionpayment-list.component.scss']
})

export class SubscriptionpaymentListComponent implements OnInit {
	@Input() isLender: boolean;
	@Input() showNew: boolean;
	@Input() showRenew: boolean;
	@Input() loanId: string;

	subscriptionPaymentColumns: string[] = [
		'description',
		'numberOperations',
		'period',
		'quantity',
		'subscripcionStartDate',
		'subscripcionEndDate',
		'amount',
		'status',
		'statusPlan'
	];

	subscriptionPaymentDataSource: MatTableDataSource<subscriptionpaymentDTO>;
	rowSubscriptionPaymentSelected: subscriptionpaymentDTO = <subscriptionpaymentDTO>{};
	indexSelected = null;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;


	constructor(private subscriptionpaymentService: SubscriptionpaymentService,
		private alertGlobalService: AlertGlobalService,
		private dialog: MatDialog,
		private router: Router,
		private authService: AuthenticationService) { }

	ngOnInit(): void {
		this.getSubscriptionPayments();
	}

	getSubscriptionPayments(): void {
		this.subscriptionpaymentService.getSubscriptionPayments(false, 1).subscribe((data) => {
			this.subscriptionPaymentDataSource = new MatTableDataSource(data);
			var dato = data[0];
			if (data) {
				this.subscriptionPaymentDataSource.paginator = this.paginator;
				this.subscriptionPaymentDataSource.sort = this.sort;

				if (data.length == 1 && data.find(x => x.idPlan == 1)) {
					this.showNew = true;
				}
			}
			if (data == null) {
				this.showNew = true;
			}
			if (dato.statusPlan == "Inactiva") {
				this.showRenew = true
			}

		});
	}

	onDeletePayment(loanPaymentId: string, amount: number) { }

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.subscriptionPaymentDataSource.filter = filterValue.trim().toLowerCase();
	}

	openSubscriptionNew() { }

	openLink(e: any): void {
		const dialogRef = this.dialog.open(DialogProofOfPaymentComponent, {
			autoFocus: false,
			disableClose: true
		});

		let instance = dialogRef.componentInstance;
		instance.rowSubscriptionPaymentSelected = e;
	}

	openDetail(e: subscriptionpaymentDTO): void {
		const dialogRef = this.dialog.open(DialogSubscriptionDetailComponent, {
			autoFocus: false,
			disableClose: true,
			height: '80%',
			width: '80%'
		});

		let instance = dialogRef.componentInstance;
		instance.subscriptionId = e.id;
	}

	openCardPayment(orderId: string): void {
		const dialogRef = this.dialog.open(HtmlViewerComponent, {
			autoFocus: false,
			width: '100vh',
			disableClose: true
		});
		let instance = dialogRef.componentInstance;
		instance.documentGeneratorType = 2;
		instance.orderId = orderId;
	}

	sendBillByEmail(id: string, email: string) {
		this.subscriptionpaymentService.sendCFDIsByEmail(id, email).subscribe((data) => {
			this.alertGlobalService.showAlertSuccess("Correo enviado satistactoriamente");
		});
	}

	cancelBill(subscriptionId: string, facturamaId: string) {
		this.subscriptionpaymentService.cancelCFDIs(subscriptionId, facturamaId).subscribe((data) => {
			this.alertGlobalService.showAlertSuccess("Correo enviado satistactoriamente");
		});
	}

	onRowClicked(row, index) {
		this.rowSubscriptionPaymentSelected = row;
		this.indexSelected = index;
	}

	getPeriod(e: number) {
		if (e == 1) {
			return "Mensual"
		} else if (e == 2) {
			return "Anual"
		}
	}

	deletePayment(subscriptionId: string) {
		this.dialog.open(DialogConfirmComponent, {
			data: {
				message: '¿Desea eliminar la ficha de pago?'
			}
		}).afterClosed().subscribe((confirmado: Boolean) => {
			if (confirmado) {
				this.subscriptionpaymentService.deleteSubscription(subscriptionId).subscribe((data) => {
					this.alertGlobalService.showAlertSuccess("Eliminado satistactoriamente");
					this.getSubscriptionPayments();
				});
			}
		});
	}

	openNew(action: string) {
		this.router.navigate(['suscripciones/contratar-plan/' + action]);
	}
}
