import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-tutorial',
  templateUrl: './dialog-tutorial.component.html',
  styleUrls: ['./dialog-tutorial.component.scss']
})
export class DialogTutorialComponent implements OnInit {
  pdfSrc = "https://api-dev.a-lender.com/api/v1/documentgenerator/getPDFTutorial";
  generated = true;

  constructor(
    public dialog: MatDialogRef<DialogTutorialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    closeDialog(): void {
      this.dialog.close(false);
    }
    confirm(): void {
      this.dialog.close(true);
    }

  ngOnInit(): void {
  }

}
