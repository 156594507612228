<p>Selecciona el renglón de la factura deseada</p>

<div class="d-flex justify-content-end mb-4">
  <div class="form-group col-md-4 col-12">
    <label>Buscar</label>
    <div>
      <i class="bx bx-search input_icon"></i>
      <input class="form-control" (keyup)="applyFilter($event)" #filterText placeholder="" type="text" />
    </div>
  </div>
</div>

<div class="table-responsive">
  <div class="react-bootstrap-table">
    <table class="text-center" *ngIf="billDataSource" matTableResponsive mat-table [dataSource]="billDataSource"
      [ngClass]="isLender ? 'bg-primary' : 'bg-secondary'">
      <ng-container matColumnDef="DateCreated">
        <th mat-header-cell *matHeaderCellDef> Fecha </th>
        <td mat-cell *matCellDef="let row"> {{row.dateCreated | date:'medium'}} </td>
      </ng-container>

      <ng-container matColumnDef="SubTotal">
        <th mat-header-cell *matHeaderCellDef> SubTotal </th>
        <td mat-cell *matCellDef="let row"> {{row.subtotal | currency}} </td>
      </ng-container>

      <ng-container matColumnDef="Total">
        <th mat-header-cell *matHeaderCellDef> Total </th>
        <td mat-cell *matCellDef="let row"> {{row.total | currency}} </td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> Estatus </th>
        <td mat-cell *matCellDef="let row"> {{row.status}} </td>
      </ng-container>

      <ng-container matColumnDef="viewFile">
        <th mat-header-cell *matHeaderCellDef> Ver </th>
        <td mat-cell *matCellDef="let row">
          <i *ngIf="row.urlNavigate != null" class="bx bx-show"
            (click)="previewFile(row.urlNavigate, row.fileOriginalName)"></i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="billColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: billColumns;"></tr>
    </table>
  </div>
</div>

<mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

<div *ngIf="billDataSource" class="d-flex justify-content-end">
  <mat-button-toggle-group name="menu" aria-label="menu">
    <div class="d-flex flex-wrap gap-2 justify-content-end">
      <button class="btn btn-primary" routerLink="/facturas/generar-factura">
        Generar
      </button>
    </div>
  </mat-button-toggle-group>
</div>