<div>
    <h5>Detalle de Base de Datos</h5>
    <hr>

    <div class="d-flex justify-content-end mb-4">
        <div class="form-group col-md-4 col-12">
            <label>Buscar</label>
            <div>
                <i class="bx bx-search input_icon"></i>
                <input class="form-control" (keyup)="applyFilter($event)" #filterText placeholder=""
                    type="text" />
            </div>
        </div>
    </div>
</div>

<div id="content">
    <div class="table-responsive">
        <div class="react-bootstrap-table">
            <table *ngIf="administratorDataSource != null" mat-table
                [dataSource]="administratorDataSource" class="table table align-middle"
                [ngClass]="isLender ? 'bg-primary' : 'bg-secondary'">
                <tbody *ngIf="data.entry == 'details'">
                    <ng-container matColumnDef="dateCreated">
                        <th mat-header-cell *matHeaderCellDef>Fecha registro usuario </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.dateCreated | date: 'd MMM y'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef> Primer nombre</th>
                        <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="middleName">
                        <th mat-header-cell *matHeaderCellDef> Segundo nombre</th>
                        <td mat-cell *matCellDef="let row"> {{row.middleName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName1">
                        <th mat-header-cell *matHeaderCellDef> Primer apellido</th>
                        <td mat-cell *matCellDef="let row"> {{row.lastName1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName2">
                        <th mat-header-cell *matHeaderCellDef> Segundo apellido</th>
                        <td mat-cell *matCellDef="let row"> {{row.lastName2}} </td>
                    </ng-container>
                    <ng-container matColumnDef="gender">
                        <th mat-header-cell *matHeaderCellDef> Género</th>
                        <td mat-cell *matCellDef="let row"> {{row.gender}} </td>
                    </ng-container>
                    <ng-container matColumnDef="educationLevel">
                        <th mat-header-cell *matHeaderCellDef> Nivel de estudios</th>
                        <td mat-cell *matCellDef="let row"> {{row.educationLevel}} </td>
                    </ng-container>
                    <ng-container matColumnDef="occupation">
                        <th mat-header-cell *matHeaderCellDef> Ocupación</th>
                        <td mat-cell *matCellDef="let row"> {{row.occupation}} </td>
                    </ng-container>
                    <ng-container matColumnDef="birthdayDate">
                        <th mat-header-cell *matHeaderCellDef> Fecha de nacimiento</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.birthdayDate | date: 'd MMM y' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="placeOfBirthName">
                        <th mat-header-cell *matHeaderCellDef> Estado nacimiento</th>
                        <td mat-cell *matCellDef="let row"> {{row.placeOfBirthName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="countryBirth">
                        <th mat-header-cell *matHeaderCellDef> País nacimiento</th>
                        <td mat-cell *matCellDef="let row"> {{row.countryBirth}} </td>
                    </ng-container>
                    <ng-container matColumnDef="districtName">
                        <th mat-header-cell *matHeaderCellDef> Domicilio colonia</th>
                        <td mat-cell *matCellDef="let row"> {{row.districtName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="city">
                        <th mat-header-cell *matHeaderCellDef> Domicilio municipio</th>
                        <td mat-cell *matCellDef="let row"> {{row.city}} </td>
                    </ng-container>
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef> Domicilio estado</th>
                        <td mat-cell *matCellDef="let row"> {{row.state}} </td>
                    </ng-container>
                    <ng-container matColumnDef="country">
                        <th mat-header-cell *matHeaderCellDef> Domicilio país </th>
                        <td mat-cell *matCellDef="let row"> {{row.country}} </td>
                    </ng-container>
                    <ng-container matColumnDef="phoneNumber">
                        <th mat-header-cell *matHeaderCellDef> Teléfono celular </th>
                        <td mat-cell *matCellDef="let row"> {{row.phoneNumber}} </td>
                    </ng-container>
                    <ng-container matColumnDef="userName">
                        <th mat-header-cell *matHeaderCellDef> Correo electrónico </th>
                        <td mat-cell *matCellDef="let row"> {{row.userName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="packageName">
                        <th mat-header-cell *matHeaderCellDef>Suscripción actual</th>
                        <td mat-cell *matCellDef="let row"> {{row.packageName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="packagePrice">
                        <th mat-header-cell *matHeaderCellDef>Precio suscripción</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.packagePrice | currency }} </td>
                    </ng-container>
                    <ng-container matColumnDef="amountWithoutTAX">
                        <th mat-header-cell *matHeaderCellDef>Monto suscripción actual sin IVA</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.amountWithoutTAX | currency }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Monto suscripción actual con IVA </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.amount | currency }} </td>
                    </ng-container>
                    <ng-container matColumnDef="subscripcionStartDate">
                        <th mat-header-cell *matHeaderCellDef>Fecha inicio suscripción actual </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.subscripcionStartDate | date: 'd MMM y'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="subscripcionEndDate">
                        <th mat-header-cell *matHeaderCellDef>Fecha fin suscripción actual </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.subscripcionEndDate | date: 'd MMM y'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="typeSubscription">
                        <th mat-header-cell *matHeaderCellDef>Tipo de usuario </th>
                        <td mat-cell *matCellDef="let row"> {{row.typeSubscription}} </td>
                    </ng-container>
                    <ng-container matColumnDef="subscripcionActivated">
                        <th mat-header-cell *matHeaderCellDef>Estatus usuario </th>
                        <td mat-cell *matCellDef="let row"> {{row.subscripcionActivated}} </td>
                    </ng-container>
                    <ng-container matColumnDef="activeLoansRequest">
                        <th mat-header-cell *matHeaderCellDef>Solicitudes </th>
                        <td mat-cell *matCellDef="let row" class="text-center"> {{row.activeLoansRequest}} </td>
                    </ng-container>
                    <ng-container matColumnDef="sumAmountActiveLoansRequest">
                        <th mat-header-cell *matHeaderCellDef>Monto solicitudes </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.sumAmountActiveLoansRequest |
                            currency }} </td>
                    </ng-container>
                    <ng-container matColumnDef="authorizedLoans">
                        <th mat-header-cell *matHeaderCellDef>Solicitudes autorizadas </th>
                        <td mat-cell *matCellDef="let row" class="text-center"> {{row.authorizedLoans}} </td>
                    </ng-container>
                    <ng-container matColumnDef="sumAuthorizedLoans">
                        <th mat-header-cell *matHeaderCellDef>Monto solicitudes autorizadas </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.sumAuthorizedLoans | currency }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="activeLoans">
                        <th mat-header-cell *matHeaderCellDef>Préstamos </th>
                        <td mat-cell *matCellDef="let row" class="text-center"> {{row.activeLoans}} </td>
                    </ng-container>
                    <ng-container matColumnDef="sumAmountActiveLoans">
                        <th mat-header-cell *matHeaderCellDef>Monto préstamos </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.sumAmountActiveLoans | currency }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="payliquidatedLoans">
                        <th mat-header-cell *matHeaderCellDef>Préstamos liquidados </th>
                        <td mat-cell *matCellDef="let row" class="text-center"> {{row.payliquidatedLoans}} </td>
                    </ng-container>
                    <ng-container matColumnDef="sumPayliquidatedLoans">
                        <th mat-header-cell *matHeaderCellDef>Monto préstamos liquidados</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.sumPayliquidatedLoans | currency }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="detailsAdministratorColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: detailsAdministratorColumns; let i = index"
                        (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
                </tbody>
                <tbody *ngIf="data.entry == 'loanRequests' || data.entry == 'loans'">
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>Fecha de registro</th>
                        <td mat-cell *matCellDef="let row" class="text-right">{{row.date | date: 'd MMM y'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lenderFullName">
                        <th mat-header-cell *matHeaderCellDef>Nombre del Prestamista</th>
                        <td mat-cell *matCellDef="let row">{{row.lenderFullName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="userName">
                        <th mat-header-cell *matHeaderCellDef>Datos de Prestamista</th>
                        <td mat-cell *matCellDef="let row">{{row.userName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="fullName">
                        <th mat-header-cell *matHeaderCellDef>Nombre del Solicitante</th>
                        <td mat-cell *matCellDef="let row">{{row.fullName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Datos de Solicitante</th>
                        <td mat-cell *matCellDef="let row">{{row.email}}</td>
                    </ng-container>
                    <ng-container matColumnDef="loanAmount">
                        <th mat-header-cell *matHeaderCellDef>Monto solicitado</th>
                        <td mat-cell *matCellDef="let row">{{row.loanAmount | currency}}</td>
                    </ng-container>
                    <ng-container matColumnDef="monthlyRate">
                        <th mat-header-cell *matHeaderCellDef>Interés mensual</th>
                        <td mat-cell *matCellDef="let row">{{row.monthlyRate*0.0100 | percent:'1.2-5'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="totalPeriodPay">
                        <th mat-header-cell *matHeaderCellDef>Monto total a pagar</th>
                        <td mat-cell *matCellDef="let row">{{row.totalPeriodPay | currency}}</td>
                    </ng-container>
                    <ng-container matColumnDef="statusName">
                        <th mat-header-cell *matHeaderCellDef>Estatus</th>
                        <td mat-cell *matCellDef="let row">{{row.statusName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="balance">
                        <th mat-header-cell *matHeaderCellDef>Saldo a la fecha</th>
                        <td mat-cell *matCellDef="let row">{{row.balance | currency}}</td>
                    </ng-container>
                    <ng-container matColumnDef="balanceExpired">
                        <th mat-header-cell *matHeaderCellDef>Saldo con atraso</th>
                        <td mat-cell *matCellDef="let row">{{row.balanceExpired | currency}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="administrationColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: administrationColumns; let i = index"
                        (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
                </tbody>
                <tbody *ngIf="data.entry == 'loanPayments'">
                    <ng-container matColumnDef="datePayment">
                        <th mat-header-cell *matHeaderCellDef>Fecha de pago</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.datePayment}} </td>
                    </ng-container>
                    <ng-container matColumnDef="loanId">
                        <th mat-header-cell *matHeaderCellDef>ID préstamo</th>
                        <td mat-cell *matCellDef="let row"> {{row.loanId}} </td>
                    </ng-container>
                    <ng-container matColumnDef="paymentAmount">
                        <th mat-header-cell *matHeaderCellDef>Monto del pago</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.paymentAmount | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="wayToPay">
                        <th mat-header-cell *matHeaderCellDef>Forma de pago</th>
                        <td mat-cell *matCellDef="let row"> {{getWayToPay(row.wayToPay)}} </td>
                    </ng-container>
                    <ng-container matColumnDef="borrowerName">
                        <th mat-header-cell *matHeaderCellDef>Nombre del solicitante</th>
                        <td mat-cell *matCellDef="let row"> {{row.borrowerName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="loanAmount">
                        <th mat-header-cell *matHeaderCellDef>Monto del préstamo</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.loanAmount | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="totalPeriodPay">
                        <th mat-header-cell *matHeaderCellDef>Monto total a pagar</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.totalPeriodPay | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="balance">
                        <th mat-header-cell *matHeaderCellDef>Saldo total a la fecha </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.balance | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="balanceExpired">
                        <th mat-header-cell *matHeaderCellDef>Saldo con atraso a la fecha </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.balanceExpired | currency}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="administrationColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: administrationColumns; let i = index"
                        (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
                </tbody>
            </table>
        </div>
    </div>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>

    <div class="d-flex flex-wrap gap-2 justify-content-end">
        <button class="btn btn-outline-primary external-btn" mat-dialog-close>
            Cerrar
        </button>

        <button class="btn btn-outline-primary external-btn" (click)="exportExcel()">
            Excel
        </button>
    </div>
</div>
