<form [formGroup]="pldJsonForm">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelPLDState" class="mb-4 bg-white" (opened)="panelPLDOpen(true)"
            (closed)="panelPLDOpen(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <h5>Datos persona políticamente expuesta (PPE)</h5>
                        <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
                <div *ngIf="validateFormrequest('IsPPE')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-IsPPE" class="">Solicitante es PPE<span
                                *ngIf="validateFormrequestJson('IsPPE')">*</span></label>
                        <select class="form-control" formControlName="value-IsPPE"
                            [ngClass]="validateRequiredFormF8('value-IsPPE') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-9 mb-3">

                </div>
                <div *ngIf="validateFormrequest('Occupation')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Occupation" class="">Puesto/ Ocupación<span
                                *ngIf="validateFormrequestJson('Occupation')">*</span></label>
                        <input class="form-control" type="text" placeholder=""
                            formControlName="value-Occupation" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Dependence')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Dependence" class="">Dependencia<span
                                *ngIf="validateFormrequestJson('Dependence')">*</span></label>
                        <input class="form-control" type="text" placeholder=""
                            formControlName="value-Dependence" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('StartDate')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <div class="form-group">
                            <label for="StartDate">Fecha de Inicio</label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-StartDate" type="date" />
                        </div>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('EndDate')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <div class="form-group">
                            <label for="EndDate">Fecha de Término</label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-EndDate" type="date" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div *ngIf="validateFormrequest('FamilyPPE')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FamilyPPE" class="">Familiar es PPE<span
                                *ngIf="validateFormrequestJson('FamilyPPE')">*</span></label>
                        <select class="form-control" formControlName="value-FamilyPPE"
                            [ngClass]="validateRequiredFormF8('value-FamilyPPE') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Relationship')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label>Tipo de relación </label>
                        <select class="form-control" formControlName="value-Relationship">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of typeRelationshipList" value="{{item.key}}">
                                {{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6 mb-3">

                </div>
                <div *ngIf="validateFormrequest('FirstName')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FirstName" class="">Nombre Familiar PPE<span
                                *ngIf="validateFormrequestJson('FirstName')">*</span></label>
                        <input class="form-control" type="text" placeholder=""
                            formControlName="value-FirstName" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('MiddleName')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-MiddleName" class="">Segundo Nombre<span
                                *ngIf="validateFormrequestJson('MiddleName')">*</span></label>
                        <input class="form-control" type="text" placeholder=""
                            formControlName="value-MiddleName" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('LastName1')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-LastName1" class="">Primer Apellido<span
                                *ngIf="validateFormrequestJson('LastName1')">*</span></label>
                        <input class="form-control" type="text" placeholder=""
                            formControlName="value-LastName1" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('LastName2')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-LastName2" class="">Segundo Apellido<span
                                *ngIf="validateFormrequestJson('LastName2')">*</span></label>
                        <input class="form-control" type="text" placeholder=""
                            formControlName="value-LastName2" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FamilyOccupation')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FamilyOccupation" class="">Puesto/ Ocupación<span
                                *ngIf="validateFormrequestJson('FamilyOccupation')">*</span></label>
                        <input class="form-control" type="text" placeholder=""
                            formControlName="value-FamilyOccupation" autocomplete="off"
                            autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FamilyDependence')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FamilyDependence" class="">Dependencia<span
                                *ngIf="validateFormrequestJson('FamilyDependence')">*</span></label>
                        <input class="form-control" type="text" placeholder=""
                            formControlName="value-FamilyDependence" autocomplete="off"
                            autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FamilyStartDate')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <div class="form-group">
                            <label for="FamilyStartDate">Fecha de Inicio</label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-FamilyStartDate" type="date" />
                        </div>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FamilyEndDate')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <div class="form-group">
                            <label for="FamilyEndDate">Fecha de Término</label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-FamilyEndDate" type="date" />
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>