<h1 mat-dialog-title>IVA</h1>
<form class="w-100" [formGroup]="taxForm" (ngSubmit)="onSubmitTax()">
    <div *ngIf="mode === 'new'" class="d-flex align-items-end mb-4">
        <div class="col-lg-3 col-md-6">
            <div class="form-group">
                <label>Tipo de IVA </label>
                <select class="form-control" formControlName="description"
                    [ngClass]="submitted && validateRequiredFormTax('description') ? 'is-invalid' : ''">
                    <option value="">Seleccione</option>
                    <option *ngFor="let item of taxList" value="{{item.value}}">{{item.value}}</option>
                </select>
            </div>
        </div>
        <button class="btn btn-outline-primary internal-btn">
            Agregar
        </button>
    </div>

    <div class="table-responsive">
        <div class="react-bootstrap-table">
            <table *ngIf="dsTax" mat-table [dataSource]="dsTax">
                <ng-container matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef>Número</th>
                    <td mat-cell *matCellDef="let row">{{row.number}}</td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Iva</th>
                    <td mat-cell *matCellDef="let row">{{row.description}}</td>
                </ng-container>

                <ng-container matColumnDef="buttonRemove">
                    <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                    <td mat-cell *matCellDef="let row; let i = index;">
                        <i class="bx bx-x" *ngIf="mode === 'new'" (click)="onDeleteTax(i)"></i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="taxColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: taxColumns;"></tr>
            </table>
        </div>
    </div>
</form>

<div class="d-flex flex-wrap mt-4 gap-2 justify-content-end">
    <button class="btn btn-outline-primary external-btn" mat-dialog-close>
        Cerrar
    </button>
    <button class="btn btn-primary external-btn" *ngIf="mode === 'new'" [mat-dialog-close]="onClickTax()">
        Guardar
    </button>
</div>