<form class="form-horizontal" [formGroup]="formrequestJsonForm">
    <mat-accordion *ngIf="checkApplyMoralPerson">
        <mat-expansion-panel [expanded]="panelCompanyDataOpenState" class="mb-4 bg-white"
            (opened)="panelCompanyDataOpen(true)" (closed)="panelCompanyDataOpen(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h5>Datos Empresa</h5>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
                <div class="col-md-9 mb-3">
                    <div class="form-group">
                        <label for="value-BusisnessName">Razón social<span
                                *ngIf="validateFormrequestJson('BusisnessName')">*</span></label>
                        <input type="text" formControlName="value-BusisnessName" class="form-control"
                            [ngClass]="panelCompanyDataOpenState && dr && validateRequiredFormF2('value-BusisnessName') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-BusisnessRfc">
                            RFC <span *ngIf="validateFormrequestJson('BusisnessRfc')">*</span>
                        </label>
                        <input type="text" formControlName="value-BusisnessRfc" class="form-control" minlength="10"
                            maxlength="13"
                            [ngClass]="panelCompanyDataOpenState && dr && validateRequiredFormF2('value-BusisnessRfc') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-DateConstitution">
                            Fecha constitución <span *ngIf="validateFormrequestJson('DateConstitution')">*</span>
                        </label>
                        <input autofocus="autofocus" class="form-control" formControlName="value-DateConstitution"
                            type="date" />
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-WritingNumber">
                            Número escritura <span *ngIf="validateFormrequestJson('WritingNumber')">*</span>
                        </label>
                        <input type="number" formControlName="value-WritingNumber" class="form-control"
                            [ngClass]="panelCompanyDataOpenState && dr && validateRequiredFormF2('value-WritingNumber') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-NotaryNumber">Número notario<span
                                *ngIf="validateFormrequestJson('NotaryNumber')">*</span></label>
                        <input type="number" formControlName="value-NotaryNumber" class="form-control"
                            [ngClass]="panelCompanyDataOpenState && dr && validateRequiredFormF2('value-NotaryNumber') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-NotaryName">Nombre notario<span
                                *ngIf="validateFormrequestJson('NotaryName')">*</span></label>
                        <input type="text" formControlName="value-NotaryName" class="form-control"
                            [ngClass]="panelCompanyDataOpenState && dr && validateRequiredFormF2('value-NotaryName') ? 'is-invalid' : ''">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
        <mat-expansion-panel [expanded]="panelTypePersonOpenState" class="mb-4 bg-white"
            (opened)="panelTypePersonOpen(true)" (closed)="panelTypePersonOpen(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h5>{{ checkApplyMoralPerson ? 'Datos Representante Legal' : 'Datos de persona física'}}</h5>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
                <div *ngIf="validateFormrequest('FirstName')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FirstName">Nombre<span
                                *ngIf="validateFormrequestJson('FirstName')">*</span></label>
                        <input type="text" formControlName="value-FirstName" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-FirstName') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('MiddleName')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-MiddleName">Segundo nombre<span
                                *ngIf="validateFormrequestJson('MiddleName')">*</span></label>
                        <input type="text" formControlName="value-MiddleName" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-MiddleName') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('LastName1')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-LastName1">Primer apellido<span
                                *ngIf="validateFormrequestJson('LastName1')">*</span></label>
                        <input type="text" formControlName="value-LastName1" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-LastName1') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('LastName2')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-LastName2">Segundo apellido<span
                                *ngIf="validateFormrequestJson('LastName2')">*</span></label>
                        <input type="text" formControlName="value-LastName2" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-LastName2') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Alias') && personType != 1" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Alias">Alias<span *ngIf="validateFormrequestJson('Alias')">*</span></label>
                        <input type="text" formControlName="value-Alias" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-Alias') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('DatePowers') && personType == 1" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-DatePowers">Fecha poderes<span
                                *ngIf="validateFormrequestJson('DatePowers')">*</span></label>
                        <input autofocus="autofocus" class="form-control" formControlName="value-DatePowers" type="date"
                            [ngClass]="dr && validateRequiredFormF2('value-DatePowers') ? 'is-invalid' : ''" />
                    </div>
                </div>
                <div *ngIf="validateFormrequest('RelationShip') && personType != 1" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label>{{relationship}} *</label>
                        <select #selectRelationShip class="form-control" formControlName="value-RelationShip"
                            (change)="onChangeRelationShip(selectRelationShip.value)"
                            [ngClass]="dr && validateRequiredFormF2('value-RelationShip') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of getContactRelationshipList(personType)" value="{{item.key}}">
                                {{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('NumberWritingPowers') && personType == 1" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-NumberWritingPowers">Número escritura poderes<span
                                *ngIf="validateFormrequestJson('NumberWritingPowers')">*</span></label>
                        <input type="text" formControlName="value-NumberWritingPowers" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-NumberWritingPowers') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('RelationShipType') && personType != 1" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-RelationShipType">Tipo parentesco<span
                                *ngIf="validateFormrequestJson('RelationShipType')">*</span></label>
                        <select class="form-control" formControlName="value-RelationShipType"
                            [ngClass]="dr && validateRequiredFormF2('value-RelationShipType') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of typeRelationshipList" value="{{item.key}}">
                                {{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Position') && personType == 1" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Position">Puesto/ cargo<span
                                *ngIf="validateFormrequestJson('Position')">*</span></label>
                        <input type="text" formControlName="value-Position" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-Position') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Gender')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Gender">Género<span *ngIf="validateFormrequestJson('Gender')">*</span></label>
                        <select class="form-control" formControlName="value-Gender"
                            [ngClass]="dr && validateRequiredFormF2('value-Gender') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of genders" value="{{item.code}}">{{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('BirthdayDate')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-BirthdayDate">Fecha de nacimiento<span
                                *ngIf="validateFormrequestJson('BirthdayDate')">*</span></label>
                        <input autofocus="autofocus" class="form-control" formControlName="value-BirthdayDate"
                            type="date"
                            [ngClass]="dr && validateRequiredFormF2('value-BirthdayDate') ? 'is-invalid' : ''" />
                    </div>
                </div>
                <div *ngIf="validateFormrequest('CountryBirthId')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-CountryBirthId">País de nacimiento<span
                                *ngIf="validateFormrequestJson('CountryBirthId')">*</span></label>
                        <select #selectCountryBirth class="form-control" formControlName="value-CountryBirthId"
                            (change)="onChangeSelectCountrieBirth(selectCountryBirth.value)"
                            [ngClass]="dr && validateRequiredFormF2('value-CountryBirthId') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of countries" value="{{item.code}}">{{item.name}}
                            </option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('PlaceOfBirth')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-PlaceOfBirth">Estado de nacimiento<span
                                *ngIf="validateFormrequestJson('PlaceOfBirth')">*</span></label>
                        <select class="form-control" formControlName="value-PlaceOfBirth"
                            [ngClass]="dr && validateRequiredFormF2('value-PlaceOfBirth') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of statesBirth" value="{{item.code}}">{{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Nationality')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Nationality">Nacionalidad<span
                                *ngIf="validateFormrequestJson('Nationality')">*</span></label>
                        <select class="form-control" formControlName="value-Nationality"
                            [ngClass]="dr && validateRequiredFormF2('value-Nationality') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of nationalities" value="{{item.code}}">
                                {{getNationalityName(item.name)}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Curp')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Curp">CURP<span *ngIf="validateFormrequestJson('Curp')">*</span></label>
                        <input type="text" formControlName="value-Curp" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-Curp') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Rfc')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Rfc">RFC<span *ngIf="validateFormrequestJson('Rfc')">*</span></label>
                        <input type="text" formControlName="value-Rfc" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-Rfc') ? 'is-invalid' : ''" minlength="10"
                            maxlength="13">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('PersonId')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-PersonId">INE<span
                                *ngIf="validateFormrequestJson('PersonId')">*</span></label>
                        <input type="text" formControlName="value-PersonId" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-PersonId') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('SerieFiel')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-SerieFiel">Número de serie FIEL<span
                                *ngIf="validateFormrequestJson('SerieFiel')">*</span></label>
                        <input type="text" formControlName="value-SerieFiel" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-SerieFiel') ? 'is-invalid' : ''">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>