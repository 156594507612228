import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-legalconditions-select-list',
  templateUrl: './legalconditions-select-list.component.html',
  styleUrls: ['./legalconditions-select-list.component.scss']
})
export class LegalConditionsSelectListComponent implements OnInit {

  @Input() onlyText: boolean = false;
  @Input() selected: string = "";

  selectedValue: string = "";
  showText: boolean = false;
  text: string = "";
  nameList: string = "Tipo de amortización";
  list: KeyValue<number, string>[] = [
    { key: 1, value: "Palabra" },
    { key: 2, value: "Legal" },
  ];

  constructor() { }

  ngOnInit(): void {
    this.showText = this.onlyText;
    this.text = this.list.find(x => x.key === parseInt(this.selected)).value;
    this.selectedValue = this.selected.toString();
  }

}
