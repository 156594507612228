import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { GlobalLists } from 'src/app/global/global-data';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { Country } from 'src/app/models/loanrequest/loanrequest.model';
import { DialogPersonDataComponent } from '../dialog-person-data/dialog-person-data.component';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-dialog-economic-activity',
	templateUrl: './dialog-economic-activity.component.html',
	styleUrls: ['./dialog-economic-activity.component.scss']
})

export class DialogEconomicActivityComponent implements OnInit {
	economicActivityJsonForm: FormGroup = this.formBuilder.group({});	
	elementsFormRequest: any;
	submit = false;	

	FormrequestJson: FormRequestAnswer[];
	panelEconomicActivityState = false;
	panelEconomicActivityLabState = false;
	panelEconomicActivityBusState = false;
	economicActivitySectorList = GlobalLists.economicActivitySectorList;
	economicActivityBusTypeList = GlobalLists.economicActivityBusTypeList;
	economicActivityBusFormList = GlobalLists.economicActivityBusFormList;
	countries: Country[];
	states: any;
	cities: any;
	statesBus: any;
	citiesBus: any;
	
	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;
	@Input() personType: number;
	@Input() occupation: number[];
	@Input() personData: DialogPersonDataComponent;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService,
		private datePipe: DatePipe,
		private catalogService: CatalogService,
		) {		
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get f8() { return this.economicActivityJsonForm.controls; }

	ngOnInit(): void {
		this.createEconomicActivityForm();
		this.getCountries();

		if (this.completed) {
			this.dr = true;
		}
	}

	showRelation() {
		if (this.personData != undefined) {
			if (this.personData.relationshipSelected == '6') {
				return true;
			}
		}

		return false;
	}

	getCountries() {
		this.catalogService.getCountries()
      	.subscribe((countries) => {
        	this.countries = (countries as Country[]).filter(x => x.code == "MX" || x.code == "US");
      	});
	}

	getStates(countryBirthId) {
		this.catalogService.getStates(countryBirthId)
		.subscribe((states) => {
		this.states = states;
		});
	}

	getStatesBus(countryBirthId) {
		this.catalogService.getStates(countryBirthId)
		.subscribe((states) => {
		this.statesBus = states;
		});
	}

	getCitys(stateId) {
		this.catalogService.getCities(this.f8["value-LabCountryId"].value, stateId)
		.subscribe((cities) => {
		  this.cities = cities;
		});
	}

	getCitysBus(stateId) {
		this.catalogService.getCities(this.f8["value-BusCountryId"].value, stateId)
		.subscribe((cities) => {
		  this.citiesBus = cities;
		});
	}

	getIsDisabledSave() {
		if (this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) {
			return true;
		} else {
			return false;
		}
	}

	createEconomicActivityForm() {
		this.loanrequestService.getEconomicActivity(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.FormrequestJson = result.optionForm;

				var formJson = this.FormrequestJson;

				for (var key in formJson) {
					if (formJson[key]['conditionName'] != null) {
						if (this.isLender) {
							if (formJson[key]['required']) {
								this.economicActivityJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.economicActivityJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
							}
						} else {
							if (formJson[key]['required']) {
								this.economicActivityJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.economicActivityJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [ Validators.maxLength(150), Validators.minLength(0)]))
							}
						}

						if (formJson[key]['conditionName'] == "LabCountryId" && formJson[key]['value'] != "") {
							this.getStates(formJson[key]['value']);
						}

						if (this.FormrequestJson[key]['conditionName'] == "LabStateId" && this.FormrequestJson[key]['value'] != "") {
							this.getCitys(this.FormrequestJson[key]['value']);
						}

						if (this.getIsDisabledSave()) {
							this.f8["value-" + formJson[key]['conditionName']].disable();
						}

						if (formJson[key]['conditionName'] == "BusCountryId" && formJson[key]['value'] != "") {
							this.getStatesBus(formJson[key]['value']);
						}

						if (this.FormrequestJson[key]['conditionName'] == "BusStateId" && this.FormrequestJson[key]['value'] != "") {
							this.getCitysBus(this.FormrequestJson[key]['value']);
						}
					}
				}

				if (this.f8['value-LabAdmissionDate'].value != "") {
					this.f8['value-LabAdmissionDate'].patchValue(this.datePipe.transform(this.f8['value-LabAdmissionDate'].value, 'yyyy-MM-dd'));
				}

				if (this.f8['value-BusAntiquity'].value != "") {
					this.f8['value-BusAntiquity'].patchValue(this.datePipe.transform(this.f8['value-BusAntiquity'].value, 'yyyy-MM-dd'));
				}
			}
		});
	}

	validateRequiredFormF8(formControlName: string): boolean {
		if (this.f8[formControlName] == null) {
			return;
		}

		if(this.f8[formControlName].errors !== null && this.f8[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateFormrequestJson(val: string) {
		if (!this.validateFormrequest(val)) {
			return;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	validateFormrequest(val: string) {
		if (this.FormrequestJson == null) {
			return false;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val) != null) {
			return true;
		}

		return false;
	}

	panelEconomicActivityOpen(e) {
		this.panelEconomicActivityState = e;
	}

	panelEconomicActivitLabyOpen(e) {
		this.panelEconomicActivityLabState = e;
	}

	panelEconomicActivityBusOpen(e) {
		this.panelEconomicActivityBusState = e;
	}

	onChangeSelectCountriesRequest(countryId: string) {
		this.states = [];
		this.cities = [];
		this.catalogService.getStates(countryId)
		  .subscribe((states) => {
			this.f8["value-LabStateId"].patchValue("");
			this.states = states;
		});
	}

	onChangeSelectCountriesBusRequest(countryId: string) {
		this.statesBus = [];
		this.citiesBus = [];
		this.catalogService.getStates(countryId)
		  .subscribe((states) => {
			this.f8["value-BusStateId"].patchValue("");
			this.statesBus = states;
		});
	}

	onChangeSelectStatesRequest(stateId: string) {
		this.cities = [];
		this.catalogService.getCities(this.f8["value-LabCountryId"].value, stateId)
		  .subscribe((cities) => {
			this.f8["value-LabCityId"].patchValue("");
			this.cities = cities;
		});
	}

	onChangeSelectStatesBusRequest(stateId: string) {
		this.citiesBus = [];
		this.catalogService.getCities(this.f8["value-BusCountryId"].value, stateId)
		  .subscribe((cities) => {
			this.f8["value-BusCityId"].patchValue("");
			this.citiesBus = cities;
		});
	}
}
