<div class="container-fluid">
  <div class="row">
    <div class="col-xl-9 col-lg-8 order-lg-1 order-2">
      <div class="card p-4">
        <div class="justify-content-center row">
          <div class="col-xl-8">
            <div class="align-items-center row">
              <div class="col-4">
                <h5 class="mb-0">Blog</h5>
              </div>
            </div>
            <hr class="mb-4">
            <div *ngFor="let blog of blogs | async">
              <div>
                <h5 class="text-dark">{{blog.category}}</h5>
                <p class="text-muted">{{ blog.createAt | date:'mediumDate' }}</p>
                <div class="position-relative mb-3">
                  <img src="assets/images/blog/img-2.9ad853b2.jpg" class="img-thumbnail">
                </div>
                <ul class="row list-inline mb-2">
                  <li class="list-inline-item mr-4 p-0 float-left">
                    <a class="text-muted d-flex align-items-center"><span class="material-icons mr-2 font-size-16">category</span> Categoría</a>
                  </li>
                  <li class="list-inline-item p-0">
                    <a class="text-muted d-flex align-items-center"><span class="material-icons mr-2 font-size-16">comment</span> 12 Comentarios</a>
                  </li>
                </ul>
                <p>{{blog.value.substring(0, 100)}}</p>
                <div>
                  <a class="text-primary d-flex align-items-center" (click)="goPost(blog.category);">Leer más <span class="material-icons ml-2 font-size-16">arrow_forward</span></a>
                </div>
              </div>
              <hr class="my-4">
            </div>
            <mat-paginator [pageSizeOptions]="[4, 8, 12]"></mat-paginator>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3 col-lg-4 order-lg-2 order-1 mb-lg-0 mb-4">
      <div class="card">
        <div class="p-4 card-body">
          <form [formGroup]="searchBlogForm" (ngSubmit)="onSubmit()">
            <div class="search-box">
              <p class="text-muted">Buscar un artículo</p>
              <div class="position-relative mb-3">
                <input type="text" class="form-control rounded bg-light border-light blog" formControlName="blog" placeholder="Ingresa el nombre">
              </div>
              <button type="submit" class="btn btn-custom m-0 d-flex align-items-center">Buscar <span class="material-icons ml-2 font-size-16">arrow_forward</span></button>
            </div>
          </form>
          <hr class="my-4">
          <div><p class="text-muted">Categorías</p>
            <ul class="list-unstyled font-weight-medium">
              <li>
                <a class="text-muted py-2 d-block" (click)="onClick('Artículo 1')">
                  <span class="material-icons mr-2 font-size-16">category</span> Categoría 1
                </a>
              </li>
              <li>
                <a class="text-muted py-2 d-block" (click)="onClick('Artículo 2')">
                  <span class="material-icons mr-2 font-size-16">category</span> Categoría 2
                </a>
              </li>
              <li>
                <a class="text-muted py-2 d-block" (click)="onClick('Artículo 3')">
                  <span class="material-icons mr-2 font-size-16">category</span> Categoría 3
                </a>
              </li>
              <li>
                <a class="text-muted py-2 d-block" (click)="onClick('Artículo 4')">
                  <span class="material-icons mr-2 font-size-16">category</span> Categoría 4
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
