<div class="container-fluid">
    <h4>Importar {{action}}</h4>
    <div class="card">
        <div class="card-body">
            <mat-horizontal-stepper class="simulator" linear #stepper labelPosition="bottom">
                <mat-step [optional]="false">
                    <ng-template matStepperIcon="edit">
                        <i class="bx bx-check"></i>
                    </ng-template>
                    <ng-template matStepperIcon="active">
                        <i class="bx bx-check"></i>
                    </ng-template>
                    <ng-template matStepLabel>Importar</ng-template>

                    <div class="d-flex flex-wrap gap-2 justify-content-end">
                        <button class="btn btn-outline-primary external-btn" routerLink="/importacion">
                            Cerrar
                        </button>

                        <button class="btn btn-outline-primary external-btn" (click)="fileInput.click()">
                            Explorar
                        </button>

                        <input input hidden #fileInput type="file" (change)="onFileChange($event)" multiple="false"/>
                    </div>
                </mat-step>

                <mat-step>
                    <ng-template matStepLabel>Validar</ng-template>
                    <div *ngIf="loanImport.length > 0">
                        <div class="d-flex justify-content-end mb-4">
                            <div class="form-group col-md-4 col-12">
                                <label>Buscar</label>
                                <div>
                                    <i class="bx bx-search input_icon"></i>
                                    <input class="form-control" (keyup)="applyFilter($event)" #filterText placeholder="" type="text" />
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <div class="react-bootstrap-table">
                                <table *ngIf="importDataSource" mat-table [dataSource]="importDataSource" class="bg-primary">
                                    <ng-container matColumnDef="externalReference">
                                        <th mat-header-cell *matHeaderCellDef>Referencia externa</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.externalReference}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="firstName">
                                        <th mat-header-cell *matHeaderCellDef>Primer nombre</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.firstName}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="middleName">
                                        <th mat-header-cell *matHeaderCellDef>Segundo nombre</th>
                                        <td mat-cell *matCellDef="let row"> {{row.middleName}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="lastName1">
                                        <th mat-header-cell *matHeaderCellDef>Primer apellido</th>
                                        <td mat-cell *matCellDef="let row"> {{row.lastName1}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="lastName2">
                                        <th mat-header-cell *matHeaderCellDef>Segundo apellido</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.lastName2}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef>Correo</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.email}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="loanAmount">
                                        <th mat-header-cell *matHeaderCellDef>Monto</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.loanAmount}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="typeOfPeriod">
                                        <th mat-header-cell *matHeaderCellDef>Frecuencia pagos</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.typeOfPeriod}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="totalPeriods">
                                        <th mat-header-cell *matHeaderCellDef>Número pagos</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.totalPeriods}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="monthlyRate">
                                        <th mat-header-cell *matHeaderCellDef>Interés mensual</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.monthlyRate*0.0100 | percent:'1.2-5'}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="startDate">
                                        <th mat-header-cell *matHeaderCellDef>Fecha inicio</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.startDate | date: 'mediumDate'}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="typeOfCredit">
                                        <th mat-header-cell *matHeaderCellDef>Tipo préstamo</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.typeOfCredit}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="typeOfAmortization">
                                        <th mat-header-cell *matHeaderCellDef>Calculo interéses</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.typeOfAmortization}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="monthlyPorcentOnArrears">
                                        <th mat-header-cell *matHeaderCellDef>Interes moratorio mensual</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.monthlyPorcentOnArrears*0.0100 | percent:'1.2-5'}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="periodAmortizeStart">
                                        <th mat-header-cell *matHeaderCellDef>Periodo inicial</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.periodAmortizeStart}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="taxPorcent">
                                        <th mat-header-cell *matHeaderCellDef>IVA</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.taxPorcent}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="percentRetentionISR">
                                        <th mat-header-cell *matHeaderCellDef>Retención ISR</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.percentRetentionISR}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef>Estatus</th>
                                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.status}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="edit">
                                        <th mat-header-cell *matHeaderCellDef> Detalles </th>
                                        <td mat-cell *matCellDef="let row">
                                            <i class="bx bx-edit"
                                                (click)="openDetails(row)"></i>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="buttonRemove">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Eliminar
                                        </th>
                                        <td mat-cell *matCellDef="let row; let i = index;">
                                            <i class="bx bx-x" (click)="onDelete(i)"></i>
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef></td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="importColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: importColumns; let i = index" (click)="onRowClicked(row, i)"
                                        [ngClass]="{'row-selected': indexSelected === i }"></tr>
                                </table>
                            </div>
                        </div>

                        <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>
                    </div>



                    <div class="d-flex flex-wrap gap-2 justify-content-end">
                        <button class="btn btn-outline-primary external-btn" routerLink="/importacion">
                            Cerrar
                        </button>

                        <button class="btn btn-outline-primary external-btn" matStepperPrevious>
                            Atrás
                        </button>

                        <button class="btn btn-outline-primary external-btn" (click)="clear()" [disabled]="loanImport.length == 0">
                            Limpiar
                        </button>

                        <button class="btn btn-primary external-btn" matStepperNext [disabled]="loanImport.length == 0">
                            Continuar
                        </button>
                    </div>
                </mat-step>

                <mat-step>
                    <ng-template matStepLabel>Confirmar</ng-template>

                    <div class="flex-grow-1 align-self-center">
                        <div class="text-muted">
                            <p class="mb-2">Confirmación de registros</p>
                            <h5 class="mb-1">
                                Total registros: 100
                            </h5>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap gap-2 justify-content-end">
                        <button class="btn btn-outline-primary external-btn" routerLink="/importacion">
                            Cerrar
                        </button>

                        <button class="btn btn-outline-primary external-btn" matStepperPrevious>
                            Atrás
                        </button>

                        <button class="btn btn-primary external-btn">
                            Guardar
                        </button>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>
