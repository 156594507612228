import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { GlobalLists } from 'src/app/global/global-data';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { LoanRequestDTO } from 'src/app/models/loanrequest/loanrequest.dto.model';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-dialog-person-data',
	templateUrl: './dialog-person-data.component.html',
	styleUrls: ['./dialog-person-data.component.scss']
})

export class DialogPersonDataComponent implements OnInit {
	formrequestJsonForm: FormGroup = this.formBuilder.group({});
	statesBirth: any;
	genders: any;
	countries: any;
	nationalities: any;
	submit = false
	FormrequestJson: FormRequestAnswer[];

	loadFormRequestJsonForm = false;

	panelCompanyDataOpenState: any;
	panelTypePersonOpenState: any;

	contactRelationshipList = GlobalLists.contactRelationshipList;
	typeRelationshipList = GlobalLists.typeRelationshipList;

	@Input() mode = "";
	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() borrowerCustomerId: string;
	@Input() contactRelationship: string;
	@Input() loanRequestDTO: LoanRequestDTO;
	@Input() personType: number;
	@Input() legalConditionsValue: number;
	@Input() dr: boolean;
	@Input() isDisabled: boolean = false;
	@Input() validateExternalServices: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private catalogService: CatalogService,
		private loanrequestService: LoanrequestService,
		private customerService: CustomerService,
		private datePipe: DatePipe
	) {
	}

	checkApplyMoralPerson = false;
	relationship = "";
	relationshipSelected = "";

	isLender = this.authService.currentUserModeLenderValue;

	get f2() { return this.formrequestJsonForm.controls; }

	ngOnInit(): void {
		this.getGenders();
		this.getCountries();
		this.getNationalities();
		this.createFormrequestJsonForm(true);
	}

	getGenders() {
		this.catalogService.getGenders()
			.subscribe((data) => {
				this.genders = data;
			});
	}

	getCountries() {
		this.catalogService.getCountries()
			.subscribe((countries) => {
				this.countries = countries;
			});
	}

	getNationalities() {
		this.catalogService.getNationalities()
			.subscribe((nationalities) => {
				this.nationalities = nationalities;
			});
	}

	getStates(countryBirthId) {
		this.catalogService.getStates(countryBirthId)
			.subscribe((states) => {
				this.statesBirth = states;
			});
	}

	onChangeSelectCountrieBirth(countryId: string) {
		this.catalogService.getStates(countryId).subscribe((statesBirth) => {
			this.statesBirth = statesBirth;
		});

		if (countryId == "MX" || countryId == "US") {
			if (this.legalConditionsValue == 3) {
				this.f2["value-PlaceOfBirth"].setValidators([Validators.required]);
				this.FormrequestJson.find(x => x.conditionName == "PlaceOfBirth").required = true;
			}
		} else {
			this.f2["value-PlaceOfBirth"].clearValidators();
			this.FormrequestJson.find(x => x.conditionName == "PlaceOfBirth").required = false;
		}
	}

	getIsDisabledSave() {
		if ((this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) && this.isDisabled) {
			return true;
		} else {
			return false;
		}
	}

	createFormrequestJsonForm(onChange: boolean) {
		this.loanrequestService.getLoanFormRequest(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.FormrequestJson = result.optionForm;
				var loadBorrowerCustomer = true;

				for (var key in this.FormrequestJson) {
					if (this.FormrequestJson[key]['conditionName'] != null) {
						if (this.isLender) {
							if (this.FormrequestJson[key]['required']) {
								this.formrequestJsonForm.addControl("value-" + this.FormrequestJson[key]['conditionName'], new FormControl(this.FormrequestJson[key]['value'] + "", [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.formrequestJsonForm.addControl("value-" + this.FormrequestJson[key]['conditionName'], new FormControl(this.FormrequestJson[key]['value'] + "", [Validators.maxLength(150), Validators.minLength(0)]))
							}
						} else {
							if (this.FormrequestJson[key]['required']) {
								this.formrequestJsonForm.addControl("value-" + this.FormrequestJson[key]['conditionName'], new FormControl(this.FormrequestJson[key]['value'] + "", [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.formrequestJsonForm.addControl("value-" + this.FormrequestJson[key]['conditionName'], new FormControl(this.FormrequestJson[key]['value'] + "", [Validators.maxLength(150), Validators.minLength(0)]))
							}
						}

						if (onChange && this.FormrequestJson[key]['conditionName'] == "CountryBirthId" && this.FormrequestJson[key]['value'] != "") {
							this.getStates(this.FormrequestJson[key]['value']);
						}

						if (this.FormrequestJson[key]['value'] != "" && loadBorrowerCustomer) {
							loadBorrowerCustomer = false;
						}

						if (this.getIsDisabledSave()) {
							this.f2["value-" + this.FormrequestJson[key]['conditionName']].disable();
						}
					}
				}

				if (this.f2['value-DateConstitution'] != null) {
					this.f2['value-DateConstitution'].patchValue(this.datePipe.transform(this.f2['value-DateConstitution'].value, 'yyyy-MM-dd'));
				}

				if (this.f2['value-DatePowers'] != null) {
					this.f2['value-DatePowers'].patchValue(this.datePipe.transform(this.f2['value-DatePowers'].value, 'yyyy-MM-dd'));
				}

				if (this.f2['value-BirthdayDate'] != null) {
					this.f2['value-BirthdayDate'].patchValue(this.datePipe.transform(this.f2['value-BirthdayDate'].value, 'yyyy-MM-dd'));
				}

				if (this.personType == 1) {
					this.checkApplyMoralPerson = true;
					this.relationship = "Relación";
				} else {
					this.checkApplyMoralPerson = false;
					this.relationship = "Relación o parentesco";
				}

				if (this.f2['value-RelationShip'].value != "0") {
					this.f2['value-RelationShipType'].disable({ onlySelf: true })
					this.f2['value-RelationShipType'].patchValue("");
				}

				if (this.mode == "loanrequest") {
					if (loadBorrowerCustomer) {
						this.customerService.getCustomer(this.borrowerCustomerId).subscribe((dataBorrower) => {
							Object.getOwnPropertyNames(this.f2).forEach((prop) => {
								var form = prop.split("-")

								if (form[0] == "value") {
									if (form[1] == "CountryBirthId") {
										this.getStates(dataBorrower[form[1].charAt(0).toLowerCase() + form[1].slice(1)]);
									}
								}
							});
						})
					}

					if (this.f2['value-RelationShip'].value == "") {
						this.f2['value-RelationShip'].patchValue(this.contactRelationship);
					}

					this.onChangeRelationShip(this.f2['value-RelationShip'].value);

					if (this.f2['value-FirstName'].value == "") {
						this.f2['value-FirstName'].patchValue(this.loanRequestDTO.firstNameQs);
					}

					if (this.f2['value-MiddleName'].value == "") {
						if (this.loanRequestDTO.middleNameQs != null) {
							this.f2['value-MiddleName'].patchValue(this.loanRequestDTO.middleNameQs);
						}
					}

					if (this.f2['value-LastName1'].value == "") {
						this.f2['value-LastName1'].patchValue(this.loanRequestDTO.lastName1Qs);
					}

					if (this.f2['value-LastName1'].value == "") {
						this.f2['value-LastName1'].patchValue(this.loanRequestDTO.lastName1Qs);
					}

					if (this.f2['value-BusisnessName'].value == "") {
						if (this.loanRequestDTO.busisnessName != null) {
							this.f2['value-BusisnessName'].patchValue(this.loanRequestDTO.busisnessName);
						}
					}

					this.formrequestJsonForm.updateValueAndValidity();
				}

				this.loadFormRequestJsonForm = true;

				this.applyMoralPerson(false);

				if (this.validateExternalServices) {
					this.requiredExternalServices();
				}
			}
		});
	}

	applyMoralPerson(check: boolean) {
		if (check) {
			if (this.checkApplyMoralPerson) {
				this.checkApplyMoralPerson = false;
			} else {
				this.checkApplyMoralPerson = true;
			}
		}

		var checked = this.checkApplyMoralPerson;

		if (checked) {
			this.f2["value-BusisnessName"].setValidators([Validators.required]);
			this.f2["value-BusisnessRfc"].setValidators([Validators.required]);
			this.f2["value-DateConstitution"].setValidators([Validators.required]);
			this.f2["value-WritingNumber"].setValidators([Validators.required]);
			this.f2["value-NotaryNumber"].setValidators([Validators.required]);
			this.f2["value-NotaryName"].setValidators([Validators.required]);

			this.FormrequestJson.find(x => x.conditionName == 'BusisnessName').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessRfc').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'DateConstitution').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'WritingNumber').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'NotaryNumber').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'NotaryName').required = true;

			this.formrequestJsonForm.updateValueAndValidity();
		} else {
			this.f2["value-BusisnessName"].clearValidators();
			this.f2["value-BusisnessName"].patchValue("");
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessName').required = false;
			this.f2["value-BusisnessRfc"].clearValidators();
			this.f2["value-BusisnessRfc"].patchValue("");
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessRfc').required = false;
			this.f2["value-DateConstitution"].clearValidators();
			this.f2["value-DateConstitution"].patchValue("");
			this.FormrequestJson.find(x => x.conditionName == 'DateConstitution').required = false;
			this.f2["value-WritingNumber"].clearValidators();
			this.f2["value-WritingNumber"].patchValue("");
			this.FormrequestJson.find(x => x.conditionName == 'WritingNumber').required = false;
			this.f2["value-NotaryNumber"].clearValidators();
			this.f2["value-NotaryNumber"].patchValue("");
			this.FormrequestJson.find(x => x.conditionName == 'NotaryNumber').required = false;
			this.f2["value-NotaryName"].clearValidators();
			this.f2["value-NotaryName"].patchValue("");
			this.FormrequestJson.find(x => x.conditionName == 'NotaryName').required = false;

			this.formrequestJsonForm.updateValueAndValidity();
		}
	}

	validateFormrequestJson(val: string) {
		if (!this.validateFormrequest(val)) {
			return;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	validateFormrequest(val: string) {
		if (this.FormrequestJson == null) {
			return false;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val) != null) {
			return true;
		}

		return false;
	}

	validateRequiredFormF2(formControlName: string): boolean {
		if (this.f2[formControlName] == null) {
			return;
		}

		if (this.f2[formControlName].errors !== null && this.f2[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	panelCompanyDataOpen(e) {
		this.panelCompanyDataOpenState = e;
	}

	panelTypePersonOpen(e) {
		this.panelTypePersonOpenState = e;
	}

	onChangeRelationShip(e: string) {
		this.relationshipSelected = e;

		if (e == "0") {
			this.f2['value-RelationShipType'].enable()
		} else {
			this.f2['value-RelationShipType'].disable()
			this.f2['value-RelationShipType'].patchValue("");
		}
	}

	disabledMode() {
		if (this.mode == "loan") {
			return true;
		} else {
			return false;
		}

	}

	getContactRelationshipList(personType: number) {
		if (personType == 1) {
			return this.contactRelationshipList.filter(x => x.key >= 8 && x.key <= 14)
		} else {
			return this.contactRelationshipList.filter(x => (x.key >= 0 && x.key <= 7) || (x.key >= 13 && x.key <= 14))
		}
	}

	getNationalityName(nationality: string) {
		return nationality.substring(0, 1).toUpperCase() + nationality.substring(1).toLowerCase()
	}

	requiredExternalServices() {
		this.FormrequestJson.find(x => x.conditionName == "FirstName").required = true;
		this.FormrequestJson.find(x => x.conditionName == "LastName1").required = true;
		this.FormrequestJson.find(x => x.conditionName == "Email").required = true;
		this.FormrequestJson.find(x => x.conditionName == "BirthdayDate").required = true;
		this.FormrequestJson.find(x => x.conditionName == "Rfc").required = true;
		this.FormrequestJson.find(x => x.conditionName == "Curp").required = true;
		this.FormrequestJson.find(x => x.conditionName == "Nationality").required = true;
		this.FormrequestJson.find(x => x.conditionName == "Gender").required = true;
		this.FormrequestJson.find(x => x.conditionName == "PersonId").required = true;
		this.FormrequestJson.find(x => x.conditionName == "PhoneMobile").required = true;

		this.f2["value-FirstName"].setValidators([Validators.required]);
		this.f2["value-LastName1"].setValidators([Validators.required]);
		this.f2["value-Email"].setValidators([Validators.required]);
		this.f2["value-BirthdayDate"].setValidators([Validators.required]);
		this.f2["value-Rfc"].setValidators([Validators.required]);
		this.f2["value-Curp"].setValidators([Validators.required]);
		this.f2["value-Nationality"].setValidators([Validators.required]);
		this.f2["value-Gender"].setValidators([Validators.required]);
		this.f2["value-PersonId"].setValidators([Validators.required]);
		this.f2["value-PhoneMobile"].setValidators([Validators.required]);
	}
}
