import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NewExists } from 'src/app/models/quote/quotesend.model';
import { QuoteService } from 'src/app/services/quote/quote.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-search-customer',
	templateUrl: './dialog-search-customer.component.html',
	styleUrls: ['./dialog-search-customer.component.scss']
})

export class DialogSearchCustomerComponent implements OnInit {
	searchCustomerColumns = [
		'firstName',
		'middleName',
		'lastName1',
		'lastName2',
		'phoneMobile',
		'email'
	];

	rowSelected:NewExists = <NewExists>{};
	indexSelected = null;
	
	searchCustomerForm: FormGroup;
	searchCustomer = <NewExists[]>[];
	dsSearchCustomer = new MatTableDataSource<NewExists>();
	submited = false;

	constructor(
		private quoteService: QuoteService,
		private dialogRef: MatDialogRef<DialogSearchCustomerComponent>,
		private changeDetector: ChangeDetectorRef) {
		
	}

	get fSearchCustomerForm() { return this.searchCustomerForm.controls; }

	ngOnInit(): void {
		this.submited = false;
		this.getSearchCustomer();
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	onRowClicked(row, index) {
		this.rowSelected = row;
		this.indexSelected = index;
	}

	onClickPage() {
		this.rowSelected = <NewExists>{};
		this.indexSelected = null;
	}
	
	getSearchCustomer() {
		this.quoteService.searchCustomer().subscribe((result) => {
			if (result !== null) {
				this.searchCustomer = result;
	
				this.dsSearchCustomer = new MatTableDataSource<NewExists>(this.searchCustomer);
			}
		});
	}

	onSubmitSearchCustomer() {
		this.submited = true;
		this.dialogRef.close();
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dsSearchCustomer.filter = filterValue.trim().toLowerCase();
	}
}