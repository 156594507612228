import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { DatePipe, KeyValue } from '@angular/common';
import { FornitureTypeService } from 'src/app/services/fornitureType/fornitureType.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { GlobalLists } from 'src/app/global/global-data';

export interface FormRequestElement {
	conditionName: string;
	value: string;
	enabled: boolean;
	required: boolean;
}

export interface DialogData {
	number: number,
	warranty: string,
	warrantysForm: any;
	loanRequestId: string;
	warrantysCompleted: boolean;
	loanRequestStatus: number;
	borrowerName: string
}

@Component({
	selector: 'app-dialog-warranty-pledge-details',
	templateUrl: './dialog-warranty-pledge-details.component.html',
	styleUrls: ['./dialog-warranty-pledge-details.component.scss']
})

export class DialogWarrantyPledgeDetailsComponent implements OnInit {
	mode = "";
	pledgeGuaranteeJsonForm: FormGroup = this.formBuilder.group({});
	submit = false;	
	dataSourceformPledgeJson = new MatTableDataSource<FormRequestDTO>([]);
	elementsFormPledge: any;
	fornitureTypeList: KeyValue<number, string>[] = [];

	columnsformPledge: string[] = [
		'conditionName',
		'value',
		'required'
	];

	FormrequestJson: FormRequestDTO[];

	panelPledgeOpenState = true;

	ownerList: KeyValue<number, string>[] = [
		{ key: 0, value: "Solicitante" },
		{ key: 1, value: "Otros" }
	];

	poofOfPaymentTypeList = GlobalLists.poofOfPaymentTypeList;
	conditionsList = GlobalLists.conditionsList;
	debtsList = GlobalLists.debtsList;
	contactRelationshipList = GlobalLists.contactRelationshipList;
	currencyMask = GlobalLists.currencyMask;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService,
		private alertGlobalService: AlertGlobalService,
		private dialogRef: MatDialogRef<DialogWarrantyPledgeDetailsComponent>,
		private fornitureTypeService: FornitureTypeService,
		private datePipe: DatePipe,
		@Inject(MAT_DIALOG_DATA) public data:DialogData
		) {
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get f6() { return this.pledgeGuaranteeJsonForm.controls; }
	getFornitureType(id) { return this.fornitureTypeList.find(x => x.key === parseInt(id)).value; }

	ngOnInit(): void {
		this.getFornitureTypes();
		this.createPledgeGuaranteeForm();
	}

	getFornitureTypes() {
		this.fornitureTypeService.getList()
		.subscribe((list)=>{
		  this.fornitureTypeList = list;
		});
	}

	getOwner() {
		this.fornitureTypeService.getList()
		.subscribe((list)=>{
		  this.fornitureTypeList = list;
		});
	}

	getIsDisabledSave() {
		if (this.data.loanRequestStatus == 9 || this.data.loanRequestStatus == 4) {
			return true;
		} else {
			return false;
		}
	}

	createPledgeGuaranteeForm() {
		this.FormrequestJson = this.data.warrantysForm;
		var formJson = this.FormrequestJson;
		
		for (var key in formJson) {
			if (this.isLender) {
				if (formJson[key]['required']) {
					this.pledgeGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
				} else {
					this.pledgeGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			} else {
				if (formJson[key]['required']) {
					this.pledgeGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
				} else {
					this.pledgeGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			}

			if (formJson[key]['conditionName'] == "FornitureOther" && formJson[key]['value'] == "") {
				this.f6["value-FornitureOther"].disable();
				this.f6["value-FornitureOther"].patchValue('');
			}

			if (formJson[key]['conditionName'] == "OwnerName" && formJson[key]['value'] == "") {
				this.f6["value-OwnerName"].disable();
				this.f6["value-OwnerName"].patchValue('');
			}

			if (formJson[key]['conditionName'] == "RelationShip" && formJson[key]['value'] == "") {
				this.f6["value-RelationShip"].disable();
				this.f6["value-RelationShip"].patchValue('');
			}

			if (this.getIsDisabledSave()) {
				this.f6["value-" + formJson[key]['conditionName']].disable();
			}
		}
		
		if (this.f6['value-InvoiceDate'].value != "") {
			this.f6['value-InvoiceDate'].patchValue(this.datePipe.transform(this.f6['value-InvoiceDate'].value, 'yyyy-MM-dd'));
		}
		
		const ELEMENT_DATA: (FormRequestElement)[] = this.data.warrantysForm;
		this.elementsFormPledge = ELEMENT_DATA;

		this.dataSourceformPledgeJson.data = ELEMENT_DATA as FormRequestDTO[];
	}

	validateRequiredFormF6(formControlName: string): boolean {
		if(this.f6[formControlName].errors !== null && this.f6[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	isGroup(index, item): boolean{
		return item.isGroupBy;
	}

	onSubmitFormPledgeJson() {
		// if (this.pledgeGuaranteeJsonForm.invalid) {
		// 	return;
		// }
		
		var warrantysForm: WarrantysForm;
		var pledgeGuaranteeAnswer: FormRequestAnswer[] = [];

		for (let val of this.elementsFormPledge){
			var value: any = "";

			if (val["conditionName"] != null) {
				if (val["conditionName"] == "InvoiceDate") {
					if (this.f6["value-" + val["conditionName"]].value != "") {
						value = this.datePipe.transform(this.f6["value-" + val["conditionName"]].value.toString(), 'yyyy-MM-ddT00:00:00')
					}
				} else {
					value = this.f6["value-" + val["conditionName"]].value.toString();
				}
			}

			pledgeGuaranteeAnswer.push({ 
				conditionName: val["conditionName"], 
				required: val["required"],
				value: value
			});
		}

		warrantysForm = {
			number: this.data.number,
			warranty: this.data.warranty,
			warrantyForm: pledgeGuaranteeAnswer,
			warrantyFormCompleted: 0
		}

		this.loanrequestService.UpdatePledgeGuaranteeJsonForm(this.data.loanRequestId, warrantysForm).subscribe((result) => {    
			this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			this.submit = true
			this.dialogRef.close();
		});
	}

	onChangeSelectFornitureType(value: string) {
		if (value == "Otro mueble") {
		  this.f6["value-FornitureOther"].patchValue('');
		  this.f6["value-FornitureOther"].enable();

		  this.f6["value-FornitureOther"].setValidators([Validators.required]);
		  this.f6["value-FornitureOther"].updateValueAndValidity();
		} else {
		  this.f6["value-FornitureOther"].disable();
		  this.f6["value-FornitureOther"].patchValue('');

		  this.f6["value-FornitureOther"].clearValidators();
		  this.f6["value-FornitureOther"].updateValueAndValidity();
		}
	}

	onChangeSelectOwner(value: string) {
		if (value === "1") {
			this.f6["value-OwnerName"].patchValue('');
			this.f6["value-OwnerName"].enable();
  
			this.f6["value-OwnerName"].setValidators([Validators.required]);
			this.f6["value-OwnerName"].updateValueAndValidity();

			
			this.f6["value-RelationShip"].patchValue('');
			this.f6["value-RelationShip"].enable();
  
			this.f6["value-RelationShip"].setValidators([Validators.required]);
			this.f6["value-RelationShip"].updateValueAndValidity();
		  } else {
			this.f6["value-OwnerName"].disable();
			this.f6["value-OwnerName"].patchValue(this.data.borrowerName);
  
			this.f6["value-OwnerName"].clearValidators();
			this.f6["value-OwnerName"].updateValueAndValidity();


			this.f6["value-RelationShip"].disable();
			this.f6["value-RelationShip"].patchValue('');
  
			this.f6["value-RelationShip"].clearValidators();
			this.f6["value-RelationShip"].updateValueAndValidity();
		  }
	}

	validateFormrequestJson(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	panelPledgeOpen(e) {
		this.panelPledgeOpenState = e;
	}

	getInvoiceNumberText(e: string) {
		if (e == "Factura") {
			return "Número/ folio factura";
		} else if (e == "Pedimiento") {
			return "Número/ Pedimiento";
		} else if (e == "Nota") {
			return "Número/ Nota";
		} else if (e == "Carta") {
			return "Número/ Carta";
		} else if (e == "Otro") {
			return "Número/ Otro";
		} else {
			return "Número/ folio factura"
		}
	}

	getInvoiceDateText(e: string) {
		if (e == "Factura") {
			return "Fecha factura";
		} else if (e == "Pedimiento") {
			return "Fecha pedimiento";
		} else if (e == "Nota") {
			return "Fecha nota";
		} else if (e == "Carta") {
			return "Fecha carta";
		} else if (e == "Otro") {
			return "Fecha otro";
		} else {
			return "Fecha factura"
		}
	}

	getContactRelationshipList(personType: string) {
		if (personType == "1" ) {
			return this.contactRelationshipList.filter(x => x.key >= 8 && x.key <= 14)
		} else {
			return this.contactRelationshipList.filter(x => (x.key >= 0 && x.key <= 7) || (x.key >= 13 && x.key <= 14))
		}
	}
}
