import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertyTypeService {

  propertyTypeList: KeyValue<number, string>[] = [
    { key: 1, value: "Terreno" },
    { key: 2, value: "Casa" },
    { key: 3, value: "Edificio" },
    { key: 4, value: "Departamento" },
    { key: 5, value: "Local" },
    { key: 6, value: "Otro" }
  ];

  private propertyTypeList$ = new BehaviorSubject<KeyValue<number, string>[]>(this.propertyTypeList);

  constructor() { }

  getList() {
    return this.propertyTypeList$.asObservable();
  }
}
