<div [ngClass]="topNav ? 'container-fluid' : ''">
  <div *ngIf="summary">
    <h4 [ngClass]="topNav ? 'd-block' : 'd-none'">Dashboard Administración</h4>
    <div class="row">
      <div [ngClass]="topNav ? 'd-block' : 'd-none'">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-md-5">
                <div class="d-flex">
                  <div class="me-3">
                    <img src="{{ authService.currentUserValue.profilePicture }}"
                      class="avatar-md rounded-circle img-thumbnail"
                      alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                  </div>
                  <div class="flex-grow-1 align-self-center">
                    <div class="text-muted">
                      <p class="mb-2">Bienvenido de nuevo</p>
                      <h5 class="mb-1">
                        {{ authService.currentUserValue.firstName }} {{
                        authService.currentUserValue.lastName1 }}
                      </h5>
                      <p class="mb-0">Perfil: Administrador</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="align-self-center col-lg-6 col-md-7">
                <div class="text-lg-center mt-4 mt-md-0">
                  <div class="row">
                    <div class="col-sm-4 col-6 mb-2">
                      <p class="text-muted text-truncate mb-2">Registros activos</p>
                      <h5 class="mb-0">{{ userActived }}</h5>
                    </div>
                    <div class="col-sm-4 col-6 mb-2">
                      <p class="text-muted text-truncate mb-2">Clientes activos </p>
                      <h5 class="mb-0">{{ clientActived }}</h5>
                    </div>
                    <div class="col-sm-4 col-6 mb-2">
                      <p class="text-muted text-truncate mb-2">Usuarios activos</p>
                      <h5 class="mb-0">{{ userActived + clientActived }}</h5>
                    </div>
                    <div class="col-sm-4 col-6 mb-sm-0 mb-2">
                      <p class="text-muted text-truncate mb-2">Registros inactivos</p>
                      <h5 class="mb-0">{{ userInactived }}</h5>
                    </div>
                    <div class="col-sm-4 col-6 mb-sm-0 mb-2">
                      <p class="text-muted text-truncate mb-2">Clientes inactivos</p>
                      <h5 class="mb-0">{{ clientInactived }}</h5>
                    </div>
                    <div class="col-sm-4 col-6">
                      <p class="text-muted text-truncate mb-2">Usuarios inactivos</p>
                      <h5 class="mb-0">{{ clientInactived + userInactived }}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="clearfix mt-4 mt-lg-0">
                  <div class="float-end">
                    <button id="buttonSetLender" (click)="showDetailsModal('details')" class="btn btn-primary">
                      Ver Usuarios
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Seccion 2-->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>Usuarios <span class="small"></span></h5>
            <hr>

            <div class="row mb-2">
              <div class="col-sm-4 col-6 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">
                  <i class="bx bxs-square in-progress"></i> Registros
                </p>
                <h5>
                  {{ userGraphics }}
                </h5>
              </div>
              <div class="col-sm-4 col-6 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">
                  <i class="bx bxs-square complete"></i> Clientes
                </p>
                <h5>
                  {{ clientGraphics }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas class="w-full" baseChart [data]="userDataChartDataYear" [options]="userDataoptions"
                  [type]="userTypeChartData"></canvas>
              </div>
            </div>
            <div class="float-end">
              <form [formGroup]="userFilterForm" (ngSubmit)="onSubmitUserFilterForm()" class="d-flex">
                <div class="me-4 w-custom">
                  <div class="form-group">
                    <select #selectYear class="form-control" formControlName="year">
                      <option value="">Todo</option>
                      <option *ngFor="let item of userFilterYearList" value="{{item.key}}">
                        {{item.value}}
                      </option>
                    </select>
                  </div>
                </div>
                <button class="btn btn-primary w-custom" type="submit">
                  Filtrar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Suscripciones <span class="small">(Cantidad)</span></h5>
            <hr>

            <div *ngIf="userAdministration" class="row mb-2">
              <div *ngFor="let item of suscriptionPlans; let indexOfelement=index;" class="col mb-2 basic">
                <p class="d-flex text-muted text-truncate mb-2">
                  <i class="bx bxs-square"></i> {{item.packageName}}
                </p>
                <h5>
                  {{this.SubscriptionChartData.datasets[0]['data'][indexOfelement]}}
                </h5>
              </div>
              <div class="col mb-2">
                <p class="d-flex text-muted text-truncate mb-2">
                  Total
                </p>
                <h5>{{subscriptionsCount}}</h5>
              </div>

              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="SubscriptionChartData" [options]="Subscriptiondataptions"
                  [type]="SubscriptiondataChartData"></canvas>
              </div>
              <div class="clearfix mt-4 mt-lg-0">
                <div class="float-end">
                  <button class="btn light" disabled>
                    Ver Suscripciones
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Suscripciones <span class="small">(Monto)</span></h5>
            <hr>

            <div *ngIf="userAdministration" class="row mb-2">
              <div *ngFor="let item of suscriptionPlans; let indexOfelement=index;" class="col mb-2 basic">
                <p class="d-flex text-muted text-truncate mb-2">
                  <i class="bx bxs-square"></i> {{item.packageName}}
                </p>
                <h5>
                  {{this.SubscriptionsalesChartData.datasets[0]['data'][indexOfelement] | currency }}
                </h5>
              </div>
              <div class="col mb-2">
                <p class="d-flex text-muted text-truncate mb-2">
                  Total
                </p>
                <h5>{{subscriptionsAmount | currency}}</h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="SubscriptionsalesChartData" [options]="Subscriptiondataptions"
                  [type]="SubscriptiondataChartData"></canvas>
              </div>
              <div class="clearfix mt-4 mt-lg-0">
                <div class="float-end">
                  <button id="buttonSetLender" (click)="showDetailsModalsubscriptions()" class="btn btn-primary">
                    Ver Suscripciones
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Seccion 2-->
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Solicitudes <span class="small">(Capital)</span></h5>
            <hr>

            <div class="row mb-2">
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted mb-2">
                  <i class="bx bxs-square in-progress"></i> En Proceso <span class="small ms-1">(EP + PA)</span>
                </p>
                <h5>
                  {{ summary.sq_LoanAmount_process | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">
                  <i class="bx bxs-square complete"></i> Autorizadas
                </p>
                <h5>
                  {{ summary.sq_LoanAmount_authorized | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">Total</p>
                <h5 class="mb-0">
                  {{ (summary.sq_LoanAmount_process + summary.sq_LoanAmount_authorized) | currency }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="loanRequestChartData" [options]="useroptions" [type]="userchartType"></canvas>
              </div>
            </div>
            <div class="float-end">
              <a class="btn btn-primary" (click)="showDetailsModal('loanRequests')">
                Ver Solicitudes
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Préstamos <span class="small">(Capital)</span></h5>
            <hr>

            <div class="row mb-2">
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">
                  <i class="bx bxs-square in-progress"></i> {{ isLender ? 'Otorgados' : 'Recibidos' }}
                </p>
                <h5>
                  {{ summary.sq_SumLoanAmount | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">
                  <i class="bx bxs-square complete"></i> Liquidados
                </p>
                <h5>
                  {{ summary.sq_SumLoanAmountLiquidated | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">Total</p>
                <h5 class="mb-0">
                  {{ (summary.sq_SumLoanAmount + summary.sq_SumLoanAmountLiquidated) | currency }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="loanChartData" [options]="useroptions" [type]="userchartType"></canvas>
              </div>
            </div>
            <div class="float-end">
              <a class="btn btn-primary" (click)="showDetailsModal('loans')">
                Ver Préstamos
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Pagos <span class="small">(Capital + Intereses)</span></h5>
            <hr>

            <div class="row mb-2">
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">
                  <i class="bx bxs-square in-progress"></i> {{ isLender ? 'Recibidos' : 'Realizados'}}
                </p>
                <h5>
                  {{ summary.sq_SumPaymentAmount | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square complete"></i>
                  Liquidados
                </p>
                <h5>
                  {{ summary.sq_SumPaymentAmountLiquidated | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">Total</p>
                <h5 class="mb-0">
                  {{ (summary.sq_SumPaymentAmount + summary.sq_SumPaymentAmountLiquidated) | currency
                  }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="paymentsChartData" [options]="useroptions" [type]="userchartType"></canvas>
              </div>
            </div>
            <div class="float-end">
              <a class="btn btn-primary" (click)="showDetailsModal('loanPayments')">
                Ver Pagos
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Saldo total <span class="small">(Capital + Intereses)</span></h5>
            <hr>

            <div class="row mb-2">
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">
                  <i class="bx bxs-square in-progress"></i> Sin atraso
                </p>
                <h5>
                  {{ summary.sq_BalanceWithoutArrears | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square complete"></i>
                  Con atraso
                </p>
                <h5>
                  {{ summary.sq_BalanceWithArrears | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">Total</p>
                <h5 class="mb-0">
                  {{ (summary.sq_BalanceWithoutArrears + summary.sq_BalanceWithArrears) | currency }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="balanceChartData" [options]="useroptions" [type]="userchartType"></canvas>
              </div>
            </div>
            <div class="clearfix mt-4 mt-lg-0">
              <div class="float-end">
                <button class="btn light" disabled>
                  Ver Total
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
