import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { DialogShowConditionsExternalServices } from 'src/app/components/dialog-show-conditions-external-services/dialog-show-conditions-external-services.component';
import { SummaryAccountStatement } from 'src/app/models/account-statement/summaryaccountstatement.dto.model';
import { ExternalServicesDTO } from 'src/app/models/loan/loan.dto.model';
import { subscriptionpaymentDTO } from 'src/app/models/subscriptionpayment/subscriptionpayment.dto.model';
import { Balance, Cart } from 'src/app/models/subscriptionpayment/subscriptionpayment.model';
import { AccountStatementService } from 'src/app/services/account-statement/account-statement.service';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanService } from 'src/app/services/loan/loan.service';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit {
	summary: SummaryAccountStatement;
	@Input() isLender: boolean;
	@Input() loanId: string;
	personType: number;


	subscriptionPaymentColumns: string[] = [
		'description',
		'numberOperations',
		'period',
		'quantity',
		'subscripcionStartDate',
		'subscripcionEndDate',
		'amount',
		'status',
		'statusPlan'
	];

	subscriptionPaymentDataSource: MatTableDataSource<subscriptionpaymentDTO>;
	rowSubscriptionPaymentSelected: subscriptionpaymentDTO = <subscriptionpaymentDTO>{};
	dataSource= new MatTableDataSource<ExternalServicesDTO>([]);
	dataSourceExternalServices = null;
	indexSelected = null;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	cartFinalizedBuy: boolean = false;
	showNew = false;
	cart: Cart;
	sumTotalCart = 0.0;
	sumTotalPay = 0.0;
	currentBalance = 0.0;
	suficientBalance: boolean = false;
	externalServiceSelected: ExternalServicesDTO;

	constructor(public authService: AuthenticationService,
		private router: Router,
		private dialog: MatDialog,
		private alertGlobalService: AlertGlobalService,
		private currencyPipe: CurrencyPipe,
		private loanService: LoanService,
		private subscriptionpaymentService: SubscriptionpaymentService) { }

	ngOnInit(): void {
		this.cart = null;
		this.getExternalServices();

		this.authService.userModeLender.subscribe((data) => {
			this.isLender = data;

			if (this.authService.currentUserValue) {
				this.fillCarList();
			}
		});
	}

	fillCarList() {
		this.subscriptionpaymentService.getCartList().subscribe((data) => {
			this.cart = data;
			this.setSumCarPay();

      this.getPlans();
		});
	}

	getPlans() {
		this.subscriptionpaymentService.getExternalServicesList().subscribe((data) => {
			this.dataSourceExternalServices = data;
		});
	}

	setSumCarPay() {
		this.sumTotalCart = this.cart.externalServicesList.reduce((sum, item) => sum + item.amount, 0);
		this.currentBalance = this.sumTotalCart;
		this.sumTotalPay = this.cart.myBalance.balance - this.sumTotalCart;
		this.suficientBalance = this.cart.myBalance.balance >= this.sumTotalCart ? true : false;
	}

	openNew(action: string) {
		this.router.navigate(['suscripciones/contratar-plan/' + action]);
	}

	deleteItem(id: string) {
		this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Remover elemento',
				message: '¿Deseas remover este elemento?'
			}
		}).afterClosed().subscribe(
			(confirmado: Boolean) => {
				if (confirmado) {
					this.subscriptionpaymentService.deleteItem(id, 1).subscribe(
						(result) => {
							const index = this.cart.externalServicesList.findIndex(item => item.id === id);

							if (index !== -1) {
								this.cart.externalServicesList.splice(index, 1);
							}

							this.setSumCarPay();
							this.subscriptionpaymentService.updateCarList(this.subscriptionpaymentService.getCarListItems() - 1);
							this.alertGlobalService.showAlertSuccess("Elemento ha sido removido");
						}
					);
				}
			}
		);
	}

	setPayment() {
		if (this.suficientBalance == false) {
			this.dialog.open(DialogConfirmComponent, {
				data: {
					title: 'Saldo insuficiente',
					message: '¿Deseas recargar saldo?'
				}
			}).afterClosed().subscribe(
				(confirmado: Boolean) => {
					if (confirmado) {
						this.router.navigate(['mi-cuenta/recargar-saldo']);
					}
				}
			);
			return;
		}

    this.subscriptionpaymentService.payCartList().subscribe(
      (result) => {
        this.alertGlobalService.showAlertSuccess("Pago realizado correctamente");
        this.cart.externalServicesList = [];
        this.cart.myBalance.balance = this.sumTotalPay;
        this.cartFinalizedBuy = true;
        this.subscriptionpaymentService.updateCarList(this.cart.externalServicesList.length);
      }
    );
	}

	capitalizeFirstLetter(value: string): string {
		if (!value || value.length === 0) {
		  return '';
		}

		const words = value.split(' ');
		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
		return capitalizedWords.join(' ');
	}

	getExternalServices() {
		this.loanService.getExternalServices(0).subscribe((result) => { 
			this.dataSource.data = result;
		});
	}

	showConditionsExternalServices(serviceName: string) {
		this.dialog.open(DialogShowConditionsExternalServices, {
			data: {
				serviceName: serviceName
			}
		}).afterClosed().subscribe(
			(confirmado: Boolean) => { }
		);
	}

	openCollection(externalServiceCollections: ExternalServicesDTO) {
		this.externalServiceSelected = externalServiceCollections;


	}

	openRoute(){

		this.router.navigate(['servicios/']);
	}
}
