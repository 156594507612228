<div>
    <h5>Validar teléfono del contacto</h5>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="subtittle">
                Se envió un código de verificación por SMS al número {{data.phoneMobileFormat}}
            </div>
            <app-input-errors-global fieldName="newValue"></app-input-errors-global>
            <app-input-errors-global fieldName="code"></app-input-errors-global>

            <div class="col-md-3 mb-3">
                <div class="form-group">
                    <label for="code">Código</label>
                    <input type="text" [textMask]="{mask: phoneCodeMask}" formControlName="code" class="form-control">
                    <mat-error *ngIf="submitted && validateRequiredForm('code')">{{requiredErrorMessage}}</mat-error>
                </div>
            </div>
        </div>

        <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" mat-dialog-close>
                Cerrar
            </button>
            <button class="btn btn-primary external-btn">
                Validar
            </button>
        </div>
    </form>
</div>
