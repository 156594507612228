import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogCommentDocumentComponent } from '../dialog-comment-document/dialog-comment-document.component';
import { CurrencyPipe, KeyValue } from '@angular/common';
import { TypeofamortizationService } from 'src/app/services/typeofamortization/typeofamortization.service';
import { TypeofcreditService } from 'src/app/services/typeofcredit/typeofcredit.service';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { LoanRequestDetailDTO, LoanRequestDTO } from 'src/app/models/loanrequest/loanrequest.dto.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { MatTableDataSource } from '@angular/material/table';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { RequiredDocumentDTO } from 'src/app/models/required-documents/requiredDocuments.dto.model';
import { DialogPreviewDocumentComponent } from '../dialog-preview-document/dialog-preview-document.component';
import { DialogWarrantyPeopleDetailsComponent } from '../dialog-warranty-people-details/dialog-warranty-people-details.component';
import { DialogWarrantyObligorJointDetailsComponent } from '../dialog-warranty-obligorjoint-details/dialog-warranty-obligorjoint-details.component';
import { DialogWarrantyMortgageDetailsComponent } from '../dialog-warranty-mortgage-details/dialog-warranty-mortgage-details.component';
import { DialogWarrantyPledgeDetailsComponent } from '../dialog-warranty-pledge-details/dialog-warranty-pledge-details.component';
import { DialogWarrantyLiquidDetailsComponent } from '../dialog-warranty-liquid-details/dialog-warranty-liquid-details.component';
import { DialogWarrantyOtherDetailsComponent } from '../dialog-warranty-other-details/dialog-warranty-other-details.component';
import { HtmlViewerComponent } from 'src/app/views/html-viewer/html-viewer.component';
import { AmortizeService } from 'src/app/services/amortize/amortize.service';
import { AdvanceOptions, Amortize, Commission } from 'src/app/models/amortize/amortize.model';
import { GlobalData, GlobalLists } from 'src/app/global/global-data';
import { Uuidv4Service } from 'src/app/services/uuidv4/uuidv4.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DialogPersonDataComponent } from '../dialog-person-data/dialog-person-data.component';
import { DialogAddressDataComponent } from '../dialog-address-data/dialog-address-data.component';
import { DialogContactDataComponent } from '../dialog-contact-data/dialog-contact-data.component';
import { DialogEconomicActivityComponent } from '../dialog-economic-activity/dialog-economic-activity.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DialogStatusResultComponent } from '../dialog-status-result/dialog-status-result.component';
import { DialogBalanceSheetDataComponent } from '../dialog-balance-sheet-data/dialog-balance-sheet-data.component';
import { DialogBankDataComponent } from '../dialog-bank-data/dialog-bank-data.component';
import { DialogSocioeconomicComponent } from '../dialog-socioeconomic/dialog-socioeconomic.component';
import { DialogReferenceDataComponent } from '../dialog-reference-data/dialog-reference-data.component';
import { DialogFamilyDataComponent } from '../dialog-family-data/dialog-family-data.component';
import { DialogEconomicFlowComponent } from '../dialog-economic-flow/dialog-economic-flow.component';
import { DialogPatrimonialDataComponent } from '../dialog-patrimonial-data/dialog-patrimonial-data.component';
import { CollectionLog, ExternalServicesDTO } from 'src/app/models/loan/loan.dto.model';
import { ExternalServiceDetailsComponent } from '../external-services-details/external-service-details.component';
import { AccountStatementComponent } from 'src/app/views/account-statement/account-statement.component';
import { LoanService } from 'src/app/services/loan/loan.service';
import { MatStepper } from '@angular/material/stepper';
import { DialogShowConditionsExternalServices } from '../dialog-show-conditions-external-services/dialog-show-conditions-external-services.component';

@Component({
	selector: 'app-loan-resume',
	templateUrl: './loan-resume.component.html',
	styleUrls: ['./loan-resume.component.scss'],
	providers: [{
		provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
	}]
})

export class LoanResumeComponent implements OnInit {
	@Input() loanRequestId: string = "";
	@Input() loanId: string = "";
	@Input() id: string = "";
	@Input() fromCustomerId: string = "";
	@Input() toCustomerId: string = "";

	@ViewChild('personData') personData: DialogPersonDataComponent;
	@ViewChild('addressData') addressData: DialogAddressDataComponent;
	@ViewChild('contactData') contactData: DialogContactDataComponent;
	@ViewChild('economicActivityData') economicActivityData: DialogEconomicActivityComponent;
	@ViewChild('patrimonialData') patrimonialData: DialogPatrimonialDataComponent;
	@ViewChild('economicFlowData') economicFlowData: DialogEconomicFlowComponent;
	@ViewChild('familyData') familyData: DialogFamilyDataComponent;
	@ViewChild('referenceData') referenceData: DialogReferenceDataComponent;
	@ViewChild('socioeconomicData') socioeconomicData: DialogSocioeconomicComponent;
	@ViewChild('bankData') bankData: DialogBankDataComponent;
	@ViewChild('balanceSheetData') balanceSheetData: DialogBalanceSheetDataComponent;
	@ViewChild('statusResultData') statusResultData: DialogStatusResultComponent;
	@ViewChild('checkApplyMoralPerson') checkApplyMoralPerson: MatSlideToggle;
	@ViewChild('accountStatement') accountStatement: AccountStatementComponent;
	@ViewChild('externalServiceDetails') externalServiceDetails: ExternalServiceDetailsComponent;
	@ViewChild('stepperLoan') stepperLoan: MatStepper;

	formrequestJsonForm: FormGroup = this.formBuilder.group({});
	loanrequestAdvanceOptionsForm: FormGroup;

	loanDetails: LoanRequestDetailDTO[] = [];
	FormrequestJson: FormRequestDTO[];

	borrowerCustomerId: any;

	legalConditionsList = GlobalLists.legalConditionsList;
	typeOfAmortizationList: KeyValue<number, string>[] = [];
	typeOfCreditList: KeyValue<number, string>[] = [];
	typeOfPeriodList: KeyValue<number, string>[] = [];

	legalConditionsValue: number;
	isLender = this.authService.currentUserModeLenderValue;
	elementsFormRequest: any;
	loanRequestStatus: number;

	genders: any;
	countries: any;
	states: any;
	cities: any;
	statesBirth: any;
	nationalities: any;

	progress: number[] = [];

	totalAmortize: any;
	startDate: any;
	borrowerMail: any;
	idRequest: any;
	borrowerName: any;
	borrowerPhoneMobile: any;
	lenderEmail: any;
	lenderPhoneMobile: any;
	lenderCustomerFullName: any;
	typeOfCredit: any;
	warrantyDataJson: any;
	monthlyRate: any;
	monthlyPorcentOnArrears: any;
	taxPorcent: any;
	periodAmortizeStart: any;
	typeOfAmortization = "";
	typeOfPeriodDesc = "";
	totalPeriods: any;
	warrantys: string = "";
	periodicPayment: any;
	totalCommission = 0;
	isCommission: any
	customPayments: any;
	totalInterest = 0;
	totalPeriodPay = 0;
	amortize: Amortize;
	statusName: string;
	borrowerProfilePicture: string;

	panelIntegrateFileOpenState: any;
	panelWarrantyOpenState: any;
	panelDocumentsOpenState: any;
	panelAuthorizeRequest: any;
	panelDocumentsLegalOpenState: any;
	panelDocumentsLegalLoadOpenState: any;
	panelDeliveryMethodOpenState: any;
	panelLoanProceedingsOpenState = true;
	panelDetailsOpenState = true;
	panelCompanyDataOpenState: any;
	panelTypePersonOpenState: any;
	panelAddressOpenState: any;
	panelContactDataOpenState: any;
	panelLoanHistoricAditionalServicesOpenState = false;

	dataSourceformRequest = new MatTableDataSource<FormRequestDTO>([]);
	dataSourceformWarrantys = new MatTableDataSource<WarrantysForm>(<WarrantysForm[]>[]);
	dataSourceRequiredDocuments = new MatTableDataSource<RequiredDocumentDTO>([]);
	dataSourceRequiredDocumentsLegal = new MatTableDataSource<RequiredDocumentDTO>([]);
	dataSourceExternalServices = new MatTableDataSource<ExternalServicesDTO>([]);

	elementsFormWarrantys: WarrantysForm[] = [];

	columnsRequiredDocuments: string[] = [
		'description',
		'required',
		'uploaded',
		'viewFile'
	];

	columnsRequiredDocumentsLegal: string[] = [
		'description',
		'required',
		'uploaded',
		'viewFile'
	];

	columnsRequiredLoadDocumentsLegal: string[] = [
		'description',
		'uploaded',
		'viewFile'
	];

	columnsFormWarrantys: string[] = [
		'number',
		'warranty',
		'warrantyFormCompleted',
		'warrantyForm',
	];

	columnsExternalServices: string[] = [
		'serviceName',
		'dateCreated',
		'amount',
		'statusName',
		'id'
	];

	contactRelationshipList = GlobalLists.contactRelationshipList;
	typeRelationshipList = GlobalLists.typeRelationshipList;

	economicActivityActive = false;
	patrimonialActive = false;
	economicFlowActive = false;
	familyActive = false;
	referenceActive = false;
	socioeconomicActive = false;
	bankActive = false;
	balanceSheetActive = false;
	statusResultActive = false;
	personDataActive = false;
	pldActive = false;

	loadFormRequestJsonForm = false;
	personType: number;
	occupation: number[] = [];

	loanRequestDTO: LoanRequestDTO;

	dr = false;

	addressDataJsonCompleted = false;
	contactDataJsonCompleted = false;
	economicActivityDataJsonCompleted = false;
	patrimonialDataJsonCompleted = false;
	economicFlowDataJsonCompleted = false;
	familyDataJsonCompleted = false;
	referenceDataJsonCompleted = false;
	socioeconomicDataJsonCompleted = false;
	bankDataJsonCompleted = false;
	balanceSheetDataJsonCompleted = false;
	statusResultDataJsonCompleted = false;
	pldDataJsonCompleted = false;

	contactRelationShip: string;
	mode = "loan"

	externalServiceSelected: ExternalServicesDTO;
	showExternalServices = false;
	dsCollectionLog = new MatTableDataSource<CollectionLog>([]);
	rowCollectionLogSelected: CollectionLog;
	@ViewChild('stepperExternalServices') stepperExternalServices: MatStepper;

	constructor(
		private spinnerService: SpinnerService,
		private typeofperiodService: TypeofperiodService,
		private typeofamortizationService: TypeofamortizationService,
		private typeofcreditService: TypeofcreditService,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private loanrequestService: LoanrequestService,
		private loanService: LoanService,
		private authService: AuthenticationService,
		private currencyPipe: CurrencyPipe,
		public amortizeService: AmortizeService,
		private uuidv4Service: Uuidv4Service,
		private router: Router,
		private route: ActivatedRoute,
		private changeDetector: ChangeDetectorRef) {
		this.spinnerService.requestStarted();

		this.loanRequestId = this.route.snapshot.paramMap.get('loanRequestId');
	}

	ngOnInit(): void {
		this.createAdvanceOptionsForm();
		this.getTypeofamortizationList();
		this.getTypeOfCreditList();
		this.getTypeOfPeriodList();
		this.getLoanRequest();
	}

	ngAfterViewInit(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewChecked(): void {
		this.changeDetector.detectChanges();
	}

	get f_advanceOptions() { return this.loanrequestAdvanceOptionsForm.controls; }

	getTypeofamortizationList() {
		this.typeofamortizationService.getList()
			.subscribe((list) => {
				this.typeOfAmortizationList = list;
			});
	}

	getTypeOfCreditList() {
		this.typeofcreditService.getList()
			.subscribe((list) => {
				this.typeOfCreditList = list;
			});
	}

	getTypeOfPeriodList() {
		this.typeofperiodService.getList()
			.subscribe((list) => {
				this.typeOfPeriodList = list;
			});
	}

	previewFile(url: string, fileName: string) {
		const dialogRef = this.dialog.open(DialogPreviewDocumentComponent, {
			autoFocus: false,
			width: '80%'
		});
		let instance = dialogRef.componentInstance;
		instance.url = url;
		instance.extensionFile = /[^.]+$/.exec(fileName)[0];
	}

	openDialogHtmlViewer(description): void {
		this.viewDocumentLegal(description);
	}

	viewDocumentLegal(description: string) {
		var documentGeneratorType = -1;

		if (description == "Contrato") {
			documentGeneratorType = 0
		} else if (description == "Pagaré") {
			documentGeneratorType = 1
		}

		const dialogRef = this.dialog.open(HtmlViewerComponent, {
			autoFocus: false,
			width: '80vh',
			disableClose: true
		});

		let instance = dialogRef.componentInstance;

		instance.documentGeneratorType = documentGeneratorType;
		instance.loanRequestId = this.loanRequestId;
	}

	getLoanRequest() {
		this.loanrequestService.getLoanRequest(this.loanRequestId).subscribe((result) => {
			var data = JSON.parse(result.amortizationTableJson);

			var startDateStr = result.loanRequestDetails.find(x => x.conditionName === 'StartDate').newConditionValue;
			var startDateArray = startDateStr.split("/");
			this.startDate = new Date(startDateArray[2] + "/" + startDateArray[1] + "/" + startDateArray[0]);
			var m = false;

			this.id = result.id;
			this.loanId = result.loanId;
			this.fromCustomerId = result.borrowerCustomerId;
			this.toCustomerId = result.lenderCustomerId;

			this.borrowerCustomerId = result.borrowerCustomerId;
			this.loanDetails = result.loanRequestDetails;

			var firstAmount: any = "";
			var breakAmount: Boolean = false;
			data.table.forEach((item: any) => {
				if (item.period != 0) {
					if (breakAmount == false) {
						if (firstAmount == "") {
							firstAmount = item.periodPay;
						} else {
							if (firstAmount != item.periodPay) {
								firstAmount = "Variable";
								breakAmount = true;
							}
						}
					}

				}
			});

			this.periodicPayment = firstAmount;
			this.totalAmortize = data.totalAmortize;
			this.totalInterest = data.totalInterest + data.totalInterestTax;
			this.totalCommission = data.totalCommission + data.totalCommissionTax;
			this.totalPeriodPay = data.totalPeriodPay;

			if (this.totalCommission) {
				this.isCommission = "Si";
			} else {
				this.isCommission = "No";
			}

			if (result.customPayments) {
				this.customPayments = "Si"
			} else {
				this.customPayments = "No"
			}

			this.warrantys = "";
			this.loanDetails.forEach((item) => {
				if (item.conditionName === "AnnualRate") {
					this.loanDetails.find(x => x.oldConditionValue === item.oldConditionValue).oldConditionValue = (parseFloat(item.oldConditionValue) / 12).toString();
				}

				if (item.conditionName == "WarrantyDataJson") {
					JSON.parse(item.newConditionValue).forEach((item) => {
						this.warrantys += item['description'] + ", ";
					});

					this.warrantys = this.warrantys.substring(0, this.warrantys.length - 2);

					if (this.warrantys == "") {
						this.warrantys = "Sin garantía(s)";
					}
				}

				if (item.conditionName == "LegalConditions") {
					this.legalConditionsValue = Number.parseInt(item.newConditionValue);
				}
			});

			var retentionValue = 0;

			if (this.loanDetails.find(x => x.conditionName == "PercentRetentionISR")) {
				retentionValue = parseFloat(this.loanDetails.find(x => x.conditionName == "PercentRetentionISR").newConditionValue + "");
			}

			this.amortize = <Amortize>{
				loanAmount: parseFloat((this.loanDetails.find(x => x.conditionName == "LoanAmount").newConditionValue + "").replace(/,/g, "")),
				annualRate: parseFloat(this.loanDetails.find(x => x.conditionName == "AnnualRate").newConditionValue),
				totalPeriods: parseInt(this.loanDetails.find(x => x.conditionName == "TotalPeriods").newConditionValue),
				periodAmortizeStart: parseInt(this.loanDetails.find(x => x.conditionName == "PeriodAmortizeStart").newConditionValue),
				typeOfPeriod: parseInt(this.loanDetails.find(x => x.conditionName == "TypeOfPeriod").newConditionValue),
				typeOfAmortization: parseInt(this.loanDetails.find(x => x.conditionName == "TypeOfAmortization").newConditionValue),
				taxPorcent: (this.loanDetails.find(x => x.conditionName == "TaxPorcent").newConditionValue) == "16" ? GlobalData.TAX : 0,
				startDate: new Date(this.loanDetails.find(x => x.conditionName == "StartDate").newConditionValue),
				commissions: JSON.parse(this.loanDetails.find(x => x.conditionName == "Commissions").newConditionValue) as Commission[],
				trackId: this.uuidv4Service.uuidv4(),
				retention: retentionValue
			};

			this.amortizeService.setTable(this.amortize, data);

			var typeOfAmortization = parseInt(result.loanRequestDetails.find(x => x.conditionName === 'TypeOfAmortization').newConditionValue);
			var typeOfPeriod = parseInt(result.loanRequestDetails.find(x => x.conditionName === 'TypeOfPeriod').newConditionValue);
			this.totalPeriods = parseInt(result.loanRequestDetails.find(x => x.conditionName === 'TotalPeriods').newConditionValue);

			this.typeOfAmortization = this.typeOfAmortizationList.find(x => x.key === typeOfAmortization).value;
			this.typeOfPeriodDesc = this.typeOfPeriodList.find(x => x.key === typeOfPeriod).value;

			this.statusName = result.statusName;

			this.idRequest = this.loanRequestId;
			this.borrowerName = result.borrowerCustomerFullName;
			this.borrowerProfilePicture = result.borrowerprofilePicture;

			if (result.formRequestEmail == "") {
				this.borrowerMail = result.contact;
			} else {
				this.borrowerMail = result.formRequestEmail;
			}

			if (result.formRequestPhoneMobile == "") {
				this.borrowerPhoneMobile = result.phoneMobileQs;
			} else {
				this.borrowerPhoneMobile = result.formRequestPhoneMobile;
			}

			this.lenderEmail = result.lenderEmail;
			this.lenderPhoneMobile = result.lenderPhoneMobile;
			this.lenderCustomerFullName = result.lenderCustomerFullName;
			this.typeOfCredit = this.typeOfCreditList.find(x => x.key === result.typeOfCredit).value;

			this.warrantyDataJson = result.warrantyDataJson
			this.monthlyRate = result.monthlyRate;
			this.monthlyPorcentOnArrears = result.monthlyPorcentOnArrears;
			this.taxPorcent = this.loanDetails.find(x => x.conditionName == "TaxPorcent").newConditionValue;
			this.periodAmortizeStart = result.periodAmortizeStart;

			this.loanRequestStatus = result.status
			this.loanRequestDTO = result;

			if (result.completed) {
				this.loadFormRequestJsonForm = true;
			}

			if (result.loanRequestDetails.find(x => x.conditionName === 'PersonType') != null) {
				this.personType = parseInt(result.loanRequestDetails.find(x => x.conditionName === 'PersonType').newConditionValue);
				this.occupation = eval(result.loanRequestDetails.find(x => x.conditionName === 'Occupation').newConditionValue);
			}

			this.contactRelationShip = result.contactRelationShip;

			var peopleExists = false;
			var obligorJointExists = false;
			var mortgageExists = false;
			var pledgeExists = false;
			var liquidExists = false;
			var otherExists = false;
			this.elementsFormWarrantys = [];

			if (result.advanceOptions != null) {
				this.economicActivityActive = false;
				this.patrimonialActive = false;
				this.economicFlowActive = false;
				this.familyActive = false;
				this.referenceActive = false;
				this.socioeconomicActive = false;
				this.bankActive = false;
				this.balanceSheetActive = false;
				this.statusResultActive = false;

				var options = JSON.parse(result.advanceOptions) as AdvanceOptions[]
				this.f_advanceOptions['AdvanceOptionsDataJson'].patchValue(result.advanceOptions)

				for (let option of options) {
					if (option.description == "Datos actividad económica") {
						this.economicActivityActive = true;
					} else if (option.description == "Datos patrimoniales") {
						this.patrimonialActive = true;
					} else if (option.description == "Datos económicos") {
						this.economicFlowActive = true;
					} else if (option.description == "Datos familiares") {
						this.familyActive = true;
					} else if (option.description == "Datos referencias") {
						this.referenceActive = true;
					} else if (option.description == "Datos socioeconómicos") {
						this.socioeconomicActive = true;
					} else if (option.description == "Datos bancarios") {
						this.bankActive = true;
					} else if (option.description == "Datos balance general") {
						this.balanceSheetActive = true;
					} else if (option.description == "Datos estado de resultados") {
						this.statusResultActive = true;
					}
				}
			}

			if (result.peopleGuaranteeDataJson != null) {
				peopleExists = true;

				var peopleResult = JSON.parse(result.peopleGuaranteeDataJson) as WarrantysForm[];

				for (let v of peopleResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (result.obligorJointGuaranteeDataJson != null) {
				var obligorJointResult = JSON.parse(result.obligorJointGuaranteeDataJson) as WarrantysForm[];
				obligorJointExists = true;

				for (let v of obligorJointResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (result.mortgageGuaranteeDataJson != null) {
				var mortgageResult = JSON.parse(result.mortgageGuaranteeDataJson) as WarrantysForm[];
				mortgageExists = true;

				for (let v of mortgageResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (result.pledgeGuaranteeDataJson != null) {
				var pledgeResult = JSON.parse(result.pledgeGuaranteeDataJson) as WarrantysForm[];
				pledgeExists = true;

				for (let v of pledgeResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (result.liquidGuaranteeDataJson != null) {
				var liquidResult = JSON.parse(result.liquidGuaranteeDataJson) as WarrantysForm[];
				liquidExists = true;

				for (let v of liquidResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (result.otherGuaranteeDataJson != null) {
				var otherResult = JSON.parse(result.otherGuaranteeDataJson) as WarrantysForm[];
				otherExists = true;

				for (let v of otherResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			this.dataSourceformWarrantys.data = this.elementsFormWarrantys;

			this.dataSourceRequiredDocuments.data = JSON.parse(result.documentsRequiredConfigJson) as RequiredDocumentDTO[];
			this.dataSourceRequiredDocumentsLegal.data = JSON.parse(result.documentsLegalRequiredConfigJson) as RequiredDocumentDTO[];

			this.spinnerService.requestEnded()

			this.getExternalServices();
			this.getCollectionLog();
		});
	}

	createAdvanceOptionsForm() {
		this.loanrequestAdvanceOptionsForm = this.formBuilder.group({
			AdvanceOptionsDataJson: ['[]']
		});
	}

	openWarrantyDetails(number: number, warranty: string, warrantysJson) {
		if (warranty == "Aval") {
			const dialogRef = this.dialog.open(DialogWarrantyPeopleDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Obligado solidario") {
			const dialogRef = this.dialog.open(DialogWarrantyObligorJointDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Hipotecaria") {
			const dialogRef = this.dialog.open(DialogWarrantyMortgageDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Prendaria") {
			const dialogRef = this.dialog.open(DialogWarrantyPledgeDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Liquida") {
			const dialogRef = this.dialog.open(DialogWarrantyLiquidDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Otra") {
			const dialogRef = this.dialog.open(DialogWarrantyOtherDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		}

	}

	validateFormrequestJson(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	validateVisiblePersonMoral(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).visible) {
			return true;
		} else {
			return false;
		}
	}

	panelIntegrateFileOpen(e) {
		this.panelIntegrateFileOpenState = e;
	}

	panelWarrantyOpen(e) {
		this.panelWarrantyOpenState = e;
	}

	panelDocumentsOpen(e) {
		this.panelDocumentsOpenState = e;
	}

	panelAuthorizeRequestOpen(e) {
		this.panelAuthorizeRequest = e;
	}

	panelDocumentsLegalOpen(e) {
		this.panelDocumentsLegalOpenState = e;
	}

	panelDocumentsLegalLoadOpen(e) {
		this.panelDocumentsLegalLoadOpenState = e;
	}

	panelDeliveryMethodOpen(e) {
		this.panelDeliveryMethodOpenState = e;
	}

	panelLoanProceedingsOpen(e) {
		this.panelLoanProceedingsOpenState = e;
	}

	panelLoanHistoricAditionalServicesOpen(e) {
		this.panelLoanHistoricAditionalServicesOpenState = e;
	}

	panelCompanyDataOpen(e) {
		this.panelCompanyDataOpenState = e;
	}

	panelTypePersonOpen(e) {
		this.panelTypePersonOpenState = e
	}

	panelAdressOpen(e) {
		this.panelAddressOpenState = e;
	}

	panelContactDataOpen(e) {
		this.panelContactDataOpenState = e;
	}

	openDialogComment(description: string) {
		const dialogRef = this.dialog.open(DialogCommentDocumentComponent, {
			autoFocus: false,
			width: '100vh'
		});
		let instance = dialogRef.componentInstance;
		instance.loanRequestId = this.loanRequestId;
		instance.documentType = description;
		instance.borrowerName = this.borrowerName;
	}

	exportExcel() {
		var dataToExport = [];
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("Employee Data");
		let header = ["#", "Fecha de pago", "Capital", "Interés", "IVA Interés", "Comisión", "IVA Comisión", "Pago total", "Saldo final"]

		if (this.amortizeService.currentAmortizeTableValue.totalRetention) {
			if (!header.find(x => x == 'Retención ISR' && 'Pago neto')) {
				header.push('Retención ISR');
				header.push('Pago neto');
			} else {
				header.filter(x => x !== "Retención ISR" && x !== "Pago neto");
			}
		}

		let headerRow = worksheet.addRow(header);

		for (let i of this.amortizeService.currentAmortizeTableValue.table) {
			if (!this.amortizeService.currentAmortizeTableValue.totalRetention) {
				dataToExport.push({
					period: i.period,
					datePayment: i.datePayment,
					amortization: this.currencyPipe.transform(i.amortization),
					interest: this.currencyPipe.transform(i.interest),
					interestTax: this.currencyPipe.transform(i.interestTax),
					commission: this.currencyPipe.transform(i.commission),
					commissionTax: this.currencyPipe.transform(i.commissionTax),
					periodPay: this.currencyPipe.transform(i.periodPay),
					balance: this.currencyPipe.transform(i.balance),
				})
			} else {
				dataToExport.push({
					period: i.period,
					datePayment: i.datePayment,
					amortization: this.currencyPipe.transform(i.amortization),
					interest: this.currencyPipe.transform(i.interest),
					interestTax: this.currencyPipe.transform(i.interestTax),
					commission: this.currencyPipe.transform(i.commission),
					commissionTax: this.currencyPipe.transform(i.commissionTax),
					periodPay: this.currencyPipe.transform(i.periodPay),
					balance: this.currencyPipe.transform(i.balance),
					retention: this.currencyPipe.transform(i.retention),
					netPeriodPay: this.currencyPipe.transform(i.netPeriodPay),
				})
			}
		}

		if (!this.amortizeService.currentAmortizeTableValue.totalRetention) {
			dataToExport.push({
				period: "Total",
				datePayment: "",
				amortization: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalAmortize),
				interest: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalInterest),
				interestTax: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalInterestTax),
				commission: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalCommission),
				commissionTax: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalCommissionTax),
				periodPay: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalPeriodPay),
				balance: ""
			})
		} else {
			dataToExport.push({
				period: "Total",
				datePayment: "",
				amortization: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalAmortize),
				interest: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalInterest),
				interestTax: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalInterestTax),
				commission: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalCommission),
				commissionTax: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalCommissionTax),
				periodPay: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalPeriodPay),
				balance: "",
				retention: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalRetention),
				netPeriodPay: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalNetPeriodPay)
			})
		}

		for (let x1 of dataToExport) {
			let x2 = Object.keys(x1);
			let temp = []

			for (let y of x2) {
				temp.push(x1[y])
			}

			worksheet.addRow(temp)
		}

		let fname = "alender tabla de pagos "

		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, fname + '- ' + `${new Date().toISOString()}.xlsx`);
		});
	}

	getExternalServices() {
		this.loanService.getExternalServices(this.personType).subscribe((result) => {
			var dataCollectionServices = result.filter(x => (x.serviceName == 'Llamada de cobranza' || x.serviceName == 'Visita de cobranza'))
			this.dataSourceExternalServices.data = dataCollectionServices;
		});
	}

	getCollectionLog() {
		this.loanService.getCollectionLog(this.id).subscribe((result) => {
			this.dsCollectionLog.data = result;

			if (localStorage.getItem("returnDetailService") != null) {
				this.stepperLoan.selectedIndex = 4;
				localStorage.removeItem("returnDetailService");
			}
		});
	}

	openDetails(externalServiceSelected: ExternalServicesDTO) {
		this.externalServiceSelected = externalServiceSelected;
		this.showExternalServices = true;

		if (this.externalServiceDetails != null) {
			this.externalServiceDetails.setExternalServiceSelected(this.externalServiceSelected);
		}
	}

	openLink(row: CollectionLog) {
		this.rowCollectionLogSelected = row;
		this.router.navigate(['prestamos/' + this.loanRequestId + '/detalle-de-servicio/' + row.id])
	}
	

	getRowCollectionLogSelected() {
		return this.rowCollectionLogSelected;
	}

	capitalizeFirstLetter(value: string): string {
		if (!value || value.length === 0) {
			return '';
		}

		const words = value.split(' ');
		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
		return capitalizedWords.join(' ');
	}

	showConditionsExternalServices(serviceName: string) {
		this.dialog.open(DialogShowConditionsExternalServices, {
			data: {
				serviceName: serviceName
			}
		}).afterClosed().subscribe(
			(confirmado: Boolean) => { }
		);
	}
}
