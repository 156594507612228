import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  // categories = [];
  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    // this.getCategories();
  }

  // getCategories() {
  //   this.notificationService.getCategoriesAvailable()
  //     .subscribe((result) => {
  //       this.categories = result;
  //     });
  // }
}
