import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { DatePipe, KeyValue } from '@angular/common';
import { GlobalLists } from 'src/app/global/global-data';
import { Country } from 'src/app/models/loanrequest/loanrequest.model';

export interface FormRequestElement {
	conditionName: string;
	value: string;
	enabled: boolean;
	required: boolean;
}

export interface DialogData {
	number: number,
	warranty: string,
	warrantysForm: any;
	loanRequestId: string;
	warrantysCompleted: boolean;
	loanRequestStatus: number;
}

@Component({
	selector: 'app-dialog-warranty-obligorjoint-details',
	templateUrl: './dialog-warranty-obligorjoint-details.component.html',
	styleUrls: ['./dialog-warranty-obligorjoint-details.component.scss']
})

export class DialogWarrantyObligorJointDetailsComponent implements OnInit {
	mode = "";
	obligorJointGuaranteeJsonForm: FormGroup = this.formBuilder.group({});
	genders: any;
	countries: Country[];
	statesBirth: any;
	nationalities: any;
	submit = false;
	statesObligorJoint: any;
	citiesObligorJoint: any;
	dataSourceformobligorJointJson = new MatTableDataSource<FormRequestDTO>([]);
	elementsFormObligorJoint: any;

	columnsformRequest: string[] = [
		'conditionName',
		'value',
		'required'
	];

	FormrequestJson: FormRequestDTO[];

	panelTypePersonOpenState: any;
	panelAddressOpenState: any;
	panelContactDataOpenState: any;
	
	contactRelationshipList = GlobalLists.contactRelationshipList;
	typeRelationshipList = GlobalLists.typeRelationshipList;
	phoneMask = GlobalLists.phoneMask;
	
	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private catalogService: CatalogService,
		private loanrequestService: LoanrequestService,
		private alertGlobalService: AlertGlobalService,
		private dialogRef: MatDialogRef<DialogWarrantyObligorJointDetailsComponent>,
		private datePipe: DatePipe,
		@Inject(MAT_DIALOG_DATA) public data:DialogData
		) {
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get f4() { return this.obligorJointGuaranteeJsonForm.controls; }

	ngOnInit(): void {
		this.getGenders();
		this.getCountries();
		this.getNationalities();
		this.createObligorJointGuaranteeForm();
	}

	getGenders() {
		this.catalogService.getGenders()
		  .subscribe((data) => {
			this.genders = data;
		});
	}

	getCountries() {
		this.catalogService.getCountries()
      	.subscribe((countries) => {
        	this.countries = countries as Country[];
      	});
	}

	getNationalities() {
		this.catalogService.getNationalities()
		  .subscribe((nationalities) => {
			this.nationalities = nationalities;
		});
	}

	getStates(countryBirthId) {
		this.catalogService.getStates(countryBirthId)
		.subscribe((states) => {
		this.statesBirth = states;
		});
	}

	getStatesObligorJoint(countryId) {
		this.catalogService.getStates(countryId)
		.subscribe((states) => {
		this.statesObligorJoint = states;
		});
	}

	getCitysObligorJoint(stateId) {
		this.catalogService.getCities(this.f4["value-CountryId"].value, stateId)
		.subscribe((cities) => {
		  this.citiesObligorJoint = cities;
		});
	}

	onChangeSelectCountrieBirth(countryId: string) {
		this.catalogService.getStates(countryId)
			.subscribe((statesBirth) => {
			this.statesBirth = statesBirth;

			if (countryId == "MX" || countryId == "US") {
				this.f4['value-PlaceOfBirth'].patchValue("");
				this.f4["value-PlaceOfBirth"].setValidators([Validators.required]);
				this.FormrequestJson.find(x => x.conditionName == "PlaceOfBirth").required = true;
			} else {
				this.f4['value-PlaceOfBirth'].patchValue("");
				this.f4["value-PlaceOfBirth"].clearValidators();
				this.FormrequestJson.find(x => x.conditionName == "PlaceOfBirth").required = false;
			}
		});
	}

	onChangeSelectCountriesObligorJoint(countryId: string) {
		this.catalogService.getStates(countryId)
		  .subscribe((states) => {
			this.f4["value-StateId"].patchValue("");
			this.statesObligorJoint = states;
		});
	}

	onChangeSelectStatesObligorJoint(stateId: string) {
		this.catalogService.getCities(this.f4["value-CountryId"].value, stateId)
		  .subscribe((cities) => {
			this.f4["value-CityId"].patchValue("");
			this.citiesObligorJoint = cities;
		});
	}

	getIsDisabledSave() {
		if (this.data.loanRequestStatus == 9 || this.data.loanRequestStatus == 4) {
			return true;
		} else {
			return false;
		}
	}

	createObligorJointGuaranteeForm() {
		this.FormrequestJson = this.data.warrantysForm;
		var formJson = this.FormrequestJson;

		for (var key in formJson) {
			if (this.isLender) {
				if (formJson[key]['required']) {
					this.obligorJointGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0) ]))
				} else {
					this.obligorJointGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			} else {
				if (formJson[key]['required']) {
					this.obligorJointGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
				} else {
					this.obligorJointGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			}

			if (formJson[key]['conditionName'] == "CountryBirthId" && formJson[key]['value'] != "") {
				this.getStates(formJson[key]['value']);
			}

			if (formJson[key]['conditionName'] == "CountryId" && formJson[key]['value'] != "") {
				this.getStatesObligorJoint(formJson[key]['value']);
			}

			if (formJson[key]['conditionName'] == "StateId" && formJson[key]['value'] != "") {
				this.getCitysObligorJoint(formJson[key]['value']);
			}

			if (this.getIsDisabledSave()) {
				this.f4["value-" + formJson[key]['conditionName']].disable();
			}
		}

		if (this.f4['value-BirthdayDate'].value != "") {
			this.f4['value-BirthdayDate'].patchValue(this.datePipe.transform(this.f4['value-BirthdayDate'].value, 'yyyy-MM-dd'));
		}
		
		const ELEMENT_DATA: (FormRequestElement)[] = this.data.warrantysForm;
		this.elementsFormObligorJoint = ELEMENT_DATA;

		this.dataSourceformobligorJointJson.data = ELEMENT_DATA as FormRequestDTO[];
	}

	validateRequiredFormF4(formControlName: string): boolean {
		if(this.f4[formControlName].errors !== null && this.f4[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	isGroup(index, item): boolean{
		return item.isGroupBy;
	}

	onSubmitFormobligorJointJson() {
		// if (this.obligorJointGuaranteeJsonForm.invalid) {
		// 	return;
		// }
		
		var warrantysForm: WarrantysForm;
		var obligorJointGuaranteeAnswer: FormRequestAnswer[] = [];

		for (let val of this.elementsFormObligorJoint){
			var value: any = "";

			if (val["conditionName"] != null) {
				if (val["conditionName"] == "BirthdayDate") {
					if (this.f4["value-" + val["conditionName"]].value != "") {
						value = this.datePipe.transform(this.f4["value-" + val["conditionName"]].value.toString(), 'yyyy-MM-ddT00:00:00')
					}
				} else {
					value = this.f4["value-" + val["conditionName"]].value.toString();
				}
			}

			obligorJointGuaranteeAnswer.push({ 
				conditionName: val["conditionName"], 
				required: val["required"],
				value: value
			});
		}

		warrantysForm = {
			number: this.data.number,
			warranty: this.data.warranty,
			warrantyForm: obligorJointGuaranteeAnswer,
			warrantyFormCompleted: 0
		}

		this.loanrequestService.UpdateObligorJointGuaranteeJsonForm(this.data.loanRequestId, warrantysForm).subscribe((result) => {    
			this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			this.submit = true
			this.dialogRef.close();
		});		
	}

	validateFormrequestJson(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	panelTypePersonOpen(e) {
		this.panelTypePersonOpenState = e;
	}

	panelAdressOpen(e) {
		this.panelAddressOpenState = e;
	}

	panelContactDataOpen(e) {
		this.panelContactDataOpenState = e;
	}

	onChangeRelationShip(e: string) {
		if (e == "0") {
			this.f4['value-RelationShipType'].enable()
			this.f4['value-RelationShipType'].patchValue("1");
		} else {
			this.f4['value-RelationShipType'].disable()
			this.f4['value-RelationShipType'].patchValue("");
		}
	}

	getNationalityName(nationality: string) {
		return nationality.substring(0, 1).toUpperCase() + nationality.substring(1).toLowerCase()
	}

	getCountriesDomicile() {
		if (this.countries == null) {
			return;
		}

		return this.countries.filter(x => x.code == "MX" || x.code == "US")
	}
}
