import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
	selector: 'app-loan-import-resume',
	templateUrl: './loan-import-resume.component.html',
	styleUrls: ['./loan-import-resume.component.scss'],
})

export class LoanImportResumeComponent implements OnInit {
	constructor (
		) {
	}

	ngOnInit(): void {
	}

	export(action: string) {
		var data: AOA;
		var fileName: string;

		if (action == "prestamos") {
			data = [
				["Referencia externa", "Primer nombre", "Segundo nombre", "Primer apellido", "Segundo Apellido", "Correo", "Monto", "Frecuencia de pagos", "Número de pagos", "Interés mensual", "Fecha de inicio", "Tipo de préstamo", "Cálculo de interéses", "Interés moratorio mensual", "Periodo inicial", "IVA", "Retención ISR"],
				["000000", "ejemplo", "", "-", "-", "correo@ejemplo.com", 1000, "Mensual", 2, 0, new Date(), "Amortizable", "Simple", 0, 1, 0, 0]
			];

			fileName = "layout_solicitudes.xlsx";
		} else if (action == "pagos") {
			data = [
				["Referencia externa", "Monto", "Fecha"],
				["000000", 1000, new Date()]
			];
			
			fileName = "layout_pagos.xlsx";
		}

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		/* save to file */
		XLSX.writeFile(wb, fileName);
	}
}
