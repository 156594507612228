import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Comments } from 'src/app/models/comments/comments.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DocumentServiceService } from 'src/app/services/document-service/document-service.service';

@Component({
  selector: 'app-dialog-comment-document',
  templateUrl: './dialog-comment-document.component.html',
  styleUrls: ['./dialog-comment-document.component.scss']
})
export class DialogCommentDocumentComponent implements OnInit {

  loanRequestId: string;
  documentType: string;  
  commentForm: FormGroup;
  submitted = false;
  requiredErrorMessage: string = "Este campo es requerido.";
  postComment = true;
  borrowerName: string;  

  dataSourceComments = new MatTableDataSource<Comments>([]);
  
  columnsComments: string[] = [
	  'dateCreated',
    'name',
	  'comment'
	];

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<DialogCommentDocumentComponent>,
              private documentService: DocumentServiceService,
              private authService: AuthenticationService,
              private alertGlobalService: AlertGlobalService) {
    this.createCommentForm();
  }

  get f1() { return this.commentForm.controls; }

  createCommentForm() {
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getComments();
  }

  getComments() {
    this.documentService.getComments(this.loanRequestId, this.documentType)
      .subscribe((data) => {
        if (data !== null) {
          this.dataSourceComments.data = data;
          // this.commentsSent = data.filter(x => x.customerId === this.authService.currentUserValue.customerId);
          // this.commentsReceived = data.filter(x => x.customerId !== this.authService.currentUserValue.customerId);
        }
        
      });
  }

  onSubmit() {
    this.submitted = true;

    if (this.commentForm.invalid && this.commentForm.invalid) {
        return;
    }

    let comment = {
      loanRequestId: this.loanRequestId,
      documentType: this.documentType,
      customerId: this.authService.currentUserValue.customerId,
      comment: this.f1['comment'].value
    }

    this.documentService.addComment(comment)
      .subscribe((data) => {
        this.alertGlobalService.showAlertSuccess('Comentario agregado con éxito.');
      });

    this.dialogRef.close();
  }

  validateRequiredForm(formControlName: string): boolean {
    if(this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
      return true;
    }
    return false;
  }

  getNameComment(customerId: string) {
    if (customerId === this.authService.currentUserValue.customerId) {
      return this.authService.currentUserValue.firstName + ' ' + this.authService.currentUserValue.lastName1
    } else {
      return this.borrowerName;
    }
  }
}
