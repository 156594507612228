<div class="container-fluid">
    <h4>Política del Uso de Cookies</h4>
    <div class="card">
        <div class="card-body">
            <p class="text">
                Este aviso es emitido por Apoyo Soluciones Productivas, S.A.P.I. de C.V. sus filiales y/o subsidiarias, y/o sus partes relacionadas (el “<u>A-Lender</u>”), con domicilio en Av. Lázaro Cárdenas No. 2400 Pte., Oficina C31-4, Col. Residencial San Agustín, San Pedro Garza García, N.L., CP 66269 , dirección electrónica: www.a-lender.com (el “<u>Sitio”</u>) y con correo electrónico de contacto hola@a-lender.com (el “<u>Correo Electrónico</u>”), con la finalidad de dar a conocer a cualquier persona que acceda o haga uso del Sitio (el “<u>Usuario”</u>), el uso de cookies que hace A-Lender en el mismo.
            </p>

            <p class="text">
                Las partes entenderán como cookies, aquellos archivos cortos que habitualmente consisten en letras y números, que se colocan en la computadora, tablet, teléfono o dispositivo similar que el Usuario utilice para acceder al Sitio (“<u>cookies”</u>) y/o aquellas tecnologías similares, como etiquetas de píxel, balizas web, GIF transparentes, JavaScript y almacenamiento local (“<u>balizas web</u>”), que puedan estar relacionadas con el Sitio.
            </p>

            <p class="text">
                El acceso y/o uso del Sitio implica la aceptación plena de los términos aquí contenidos. En consecuencia, es responsabilidad única y exclusiva del Usuario, leer previamente esta política de uso y sus modificaciones correspondientes, cada vez que accede al Sitio. Si en cualquier momento, el Usuario no estuviera de acuerdo total o parcialmente con los presentes términos, deberá, a su criterio, deshabilitar las cookies establecidas o abstenerse inmediatamente de usar el Sitio.
            </p>

            <p class="text">
                <b>OBJETO.</b>
                
                Con la finalidad de proporcionar al Usuario la mejor funcionalidad posible del Sitio e información personalizada adecuada a sus intereses, A-Lender sus socios y/o aliados, podrán hacer uso de cookies, para recopilar datos que permitan brindar información analítica, así como la identificación personal, o que podría permitir la identificación personal bajo la combinación con otra información, en cuyo caso, se considerará regido bajo los términos establecidos en el Aviso de Privacidad de A-Lender y en el presente documento.
            </p>

            <p class="text">
                A-Lender, como operador del Sitio, puede poner cookies en el dispositivo por el cual el Usuario accede a este. Estas cookies se llaman cookies "de origen". Las cookies dispuestas por proveedores que presten servicios a través del Sitio, tales como correo electrónico, se les llama “de terceros”. A-Lender no controla el uso de esta tecnología, o la información resultante, y no es responsable por ninguna acción o política de esas terceras partes.
            </p>

            <p class="text">
                A-Lender hace del conocimiento del Usuario que, por medio de las cookies, es posible obtener y utilizar información agregada y de otro tipo que NO IDENTIFICA a los Usuarios individuales (como su sistema operativo, su versión de navegador y la URL de origen, incluidos mensajes de correo electrónico y anuncios publicitarios) para mejorar su experiencia y comprender los patrones de tráfico. Esta tecnología se utiliza como una herramienta para compilar estadísticas agregadas acerca del uso del Sitio para análisis y desempeño y optimización del mismo.
            </p>

            <p class="text">
                A-Lender manifiesta que puede también hacer uso de tecnologías similares, como las balizas web (a veces llamadas "píxeles de seguimiento" o "GIF transparentes"). Estos son archivos gráficos, muy pequeños, contienen un identificador único que permite reconocer la visita al Sitio o notificar a A-Lender cuando el Usuario abra un correo electrónico que haya sido enviado por A-Lender. Generalmente, estas tecnologías se sustentan en cookies para que funcionen de manera correcta, por lo que su disminución afecta directamente su funcionamiento.
            </p>

            <p class="text">
                <b>TIPO DE COOKIES</b>
                
                A-Lender y sus proveedores de servicios podrán hacer uso de uno o más tipos de cookies, en adelante descritas:
            </p>

            <ul>
                <li><b>Cookies esenciales.</b> Requeridas para mejorar el funcionamiento del Sitio, permitiendo al Usuario recorrer el Sitio y hacer uso de sus características (por ejemplo, para permitirle al Usuario iniciar sesión en áreas seguras del Sitio o utilizar un carro de compras). La deshabilitación de estas cookies provocará un entorpecimiento del desempeño del Sitio, y posiblemente dejará sin disponibilidad las características del Sitio y su servicio.
                <li><b>Cookies de análisis y personalización.</b>  Diseñadas para analizar la actividad del Usuario en el Sitio y/o en los sitios donde A-Lender publique o promocione contenidos, mismas que se usan para asegurar que el Usuario pueda encontrar la información que busca en el Sitio de una manera más fácil. Estas cookies permiten el reconocimiento y contabilización de visitantes al Sitio, así como la medición del desempeño de campañas publicitarias y optimización del contenido del Sitio por parte de A-Lender.
                <li><b>Cookies de funcionalidad.</b> Utilizadas para reconocer al Usuario cuando ingresa o regresa al Sitio de A-Lender, mediante la personalización del Contenido, uso del nombre registrado como Usuario y preferencias tales como: idioma, región, etc.
                <li><b>Cookies de publicidad.</b> Estas cookies son utilizadas con el objeto de resaltar el contenido del Sitio que sea más relevante para el Usuario, en función de los intereses aparentes recopilados según su actividad en línea, incluidas las visitas al Sitio, las páginas que ha visitado y la selección de enlaces y anuncios. Lo anterior permite que, mediante el uso de plataformas de administración y edición de datos, se enlacen avisos publicitarios en el Sitio propiedad de A-Lender o en cualquier otro, con la finalidad de mantener una promoción continua de los productos y servicios visitados en el Sitio.
            </ul>

            <p class="text">
                <b>DEL USO DE COOKIES.</b>
                
                El uso de cookies permite a A-Lender, y a sus proveedores de servicio, recabar ciertos datos sensibles del Usuario a fin de: i) obtener información acerca de sus visitas al Sitio; ii) procesar los pedidos del Usuario; iii) analizar sus patrones de visita para mejoras del Sitio; iv) ofrecer publicidad, comunicaciones y contenidos de A-Lender y de terceros aliados en el Sitio y en el de terceros que sean específicos en relación con sus intereses; v) recordar el idioma y otras preferencias del Usuario; vi) ayudar al Usuario a obtener la información que busca; vii) ofrecer un servicio seguro para las transacciones en línea; viii) cuantificar el acceso y uso del Sitio; ix) ofrecer una funcionalidad eficaz y en concordancia a la audiencia del Sitio, entre otros.
            </p>

            <p class="text">
                El registro de información personal por parte del Usuario en el Sitio, permite a A-Lender que, por medio de la asociación de dicha información con otra información que se recabe a través de terceros que se encuentre directa o indirectamente relacionada con el uso del Sitio, se use la misma con fines de análisis y de mercadeo, siempre estadística y no individualizada.
            </p>

            <p class="text">
                <b>TIPOS DE COOKIES RESPECTO A SUS PERMANENCIA EN EL SISTEMA.</b>
                
                La función y permanencia de las cookies en el sistema del dispositivo a través del cual se acceda al Sitio, pueden ser:
            </p>

            <ul>
                1.	"De sesión", las cuales se activan en el momento de su acceso al Sitio y hasta la finalización de esa sesión. Estas cookies vencen y se eliminan automáticamente cuando se cierra el navegador de Internet; o 
                
                2.	“Persistentes” las cuales permanecen en el dispositivo de acceso al Sitio entre sesiones de navegación. La cantidad de tiempo que una cookie persistente permanece en su dispositivo varía de una cookie a otra.
                
            </ul>

            <p class="text">
                El propósito para el cual A-Lender hace uso de cookies es: i) para almacenamiento de preferencias del Usuario, con la finalidad de mantenerlas disponibles para su próxima visita; ii) para mantener información de frecuencia y variación en tiempo de visita al Sitio; y iii) para probar la eficacia de los esfuerzos de publicidad.
            </p>

            <p class="text">
                <b>ADMINISTRACIÓN DE COOKIES POR EL USUARIO.</b>
                
                El Usuario, a través de la configuración de su navegador de Internet, podrá decidir si desea o no aceptar cookies del Sitio. A-Lender manifiesta que, el bloqueo por parte del Usuario de todas las cookies, puede provocar el bloqueo o afectación total o parcial en el acceso y uso del Sitio.
            </p>

            <p class="text">
                A-Lender se compromete a hacer buen uso de los datos personales y datos sensibles provistos por el Usuario, y que lo hará conforme al Aviso de Privacidad que se encuentra en el Sitio. Cualquier duda o comentario respecto al uso de cookies por parte de A-Lender, puede ser dirigida al Correo Electrónico. En dicho caso, favor de incluir la información de contacto, el nombre del sitio web de A-Lender y una descripción detallada de la solicitud o preocupación relacionada con las cookies.
            </p>

            <p class="text">
                <b>ACEPTACIÓN DE LOS TÉRMINOS.</b>
                
                El Usuario reconoce que, mediante el acceso y uso del Sitio, manifiesta su aceptación plena y sin reservas y, por tanto, su adhesión a la versión de estos términos publicada en el momento en que acceda al Sitio, en términos de lo establecido por los artículos 1803 y 1834 Bis del Código Civil Federal, 80, 81, 89 y demás relativos y aplicables del Código de Comercio y la legislación aplicable para México. Es responsabilidad única y exclusiva del Usuario, leer previamente estos términos y sus modificaciones correspondientes, cada vez que accede al Sitio. Si, en cualquier momento, el Usuario no estuviera de acuerdo, total o parcialmente con los presentes términos, deberá abstenerse inmediatamente de acceder y usar el Sitio. Por lo anterior, con la aceptación de los presentes términos, el Usuario consiente expresamente sujetarse a los mismos, por lo que manifiesta haber leído el contenido de todas y cada una de las disposiciones y ratifica su contenido.
            </p>

            <p class="text">
                <b>ACUERDO TOTAL.</b>
                
                El Usuario reconoce y acepta que A-Lender puso a su disposición toda la información para entender el alcance y características del Sitio. De igual forma manifiesta que, previo al acceso al Sitio, analizó las características de éste y, por consiguiente, está de acuerdo con ellas.
            </p>
            
            <p class="text">
                Fecha de primera emisión: 1 de enero de 2022.
                
                Fecha de última modificación: 14 de octubre de 2022.    
            </p>
        </div>
    </div>
</div>