<div [ngClass]="topNav ? 'container-fluid' : ''">
  <div *ngIf="summary">
    <h4 [ngClass]="topNav ? 'd-block' : 'd-none'">Tablero</h4>
    <div class="row">
      <div [ngClass]="topNav ? 'd-block' : 'd-none'">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-md-5">
                <div class="d-flex">
                  <div class="me-3">
                    <img src="{{ authService.currentUserValue.profilePicture }}"
                      class="avatar-md rounded-circle img-thumbnail"
                      alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                  </div>
                  <div class="flex-grow-1 align-self-center">
                    <div class="text-muted">
                      <p class="mb-2">Bienvenido de nuevo</p>
                      <h5 class="mb-1">
                        {{ authService.currentUserValue.firstName }} {{ authService.currentUserValue.lastName1 }}
                      </h5>
                      <p class="mb-2" *ngIf="isLender">
                        Soy prestamista
                      </p>
                      <p class="mb-2" *ngIf="!isLender">
                        Soy solicitante
                      </p>
                      <a class="text-link" routerLink="/mi-perfil">Actualizar mi perfil</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="align-self-center col-lg-6 col-md-7">
                <div class="mt-4 mt-md-0">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6 col-12 mb-2 mb-sm-0">
                      <p class="text-muted text-truncate mb-2">Suscripción actual</p>
                      <h5 class="mb-0">{{ summary.descriptions }}</h5>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12 mb-2 mb-sm-0">
                      <p class="text-muted text-truncate mb-2">Vence en</p>
                      <h5 class="mb-0">{{ summary.remainingDays }} {{ summary.remaining }}</h5>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12 mb-2 mb-sm-0">
                      <p class="text-muted text-truncate mb-2">Operaciones restantes</p>
                      <h5 class="mb-0">{{ summary.remainingDays == 0 ? 0 : summary.numberOperations -
                        summary.countLoanRequests }}</h5>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                      <p class="text-muted text-truncate mb-2">Saldo disponible</p>
                      <h5 class="mb-0">
                        {{ summary.currentBalance | currency}} <span class="small_text">MXN</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mt-lg-0 mt-3">
                <div class="float-end">
                  <button class="btn btn-primary" routerLink="/mi-cuenta">
                    Mi cuenta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Solicitudes <span class="small">(Capital)</span></h5>
            <hr>

            <div class="row mb-2">
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square in-progress"></i> En Proceso</p>
                <h5>
                  {{ summary.sq_LoanAmount_process | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square complete"></i> Autorizadas</p>
                <h5>
                  {{ summary.sq_LoanAmount_authorized | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">Total</p>
                <h5 class="mb-0">
                  {{ (summary.sq_LoanAmount_process + summary.sq_LoanAmount_authorized) | currency }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="loanRequestChartData" [options]="options" [type]="chartType">
                </canvas>
              </div>
            </div>
            <div *ngIf="topNav" class="float-end">
              <a id="buttonSetLender" class="btn btn-primary"
                routerLink="/solicitud-de-prestamo">
                Ver Solicitudes
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Préstamos <span class="small">(Capital)</span></h5>
            <hr>

            <div class="row mb-2">
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square in-progress"></i> {{ isLender ?
                  'Otorgados' : 'Recibidos' }}</p>
                <h5>
                  {{ summary.sq_SumLoanAmount | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square complete"></i> Liquidados</p>
                <h5>
                  {{ summary.sq_SumLoanAmountLiquidated | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">Total</p>
                <h5 class="mb-0">
                  {{ (summary.sq_SumLoanAmount + summary.sq_SumLoanAmountLiquidated) | currency }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="loanChartData" [options]="options" [type]="chartType">
                </canvas>
              </div>
            </div>
            <div *ngIf="topNav" class="float-end">
              <a id="buttonSetLender" class="btn btn-primary"
                routerLink="/prestamos">
                Ver Préstamos
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Pagos <span class="small">(Capital + Intereses)</span></h5>
            <hr>

            <div class="row mb-2">
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square in-progress"></i> {{ isLender ?
                  'Recibidos' : 'Realizados' }}</p>
                <h5>
                  {{ summary.sq_SumPaymentAmount | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square complete"></i> Liquidados</p>
                <h5>
                  {{ summary.sq_SumPaymentAmountLiquidated | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">Total</p>
                <h5 class="mb-0">
                  {{ (summary.sq_SumPaymentAmount + summary.sq_SumPaymentAmountLiquidated) | currency
                  }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="paymentsChartData" [options]="options" [type]="chartType">
                </canvas>
              </div>
            </div>
            <div *ngIf="topNav" class="float-end">
              <a id="buttonSetLender" class="btn btn-primary" routerLink="/pagos">
                Ver Pagos
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Saldo total <span class="small">(Capital + Intereses)</span></h5>
            <hr>

            <div class="row mb-2">
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square in-progress"></i> Sin atraso</p>
                <h5>
                  {{ summary.sq_BalanceWithoutArrears | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2"><i class="bx bxs-square complete"></i> Con atraso</p>
                <h5>
                  {{ summary.sq_BalanceWithArrears | currency }}
                </h5>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <p class="d-flex text-muted text-truncate mb-2">Total</p>
                <h5 class="mb-0">
                  {{ (summary.sq_BalanceWithoutArrears + summary.sq_BalanceWithArrears) | currency }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <canvas baseChart [data]="balanceChartData" [options]="options" [type]="chartType">
                </canvas>
              </div>
            </div>
            <div *ngIf="topNav" class="float-end">
              <a id="buttonSetLender" class="btn btn-primary" routerLink="/reportes">
                Ver Reportes
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!topNav" class="float-end">
    <button class="btn btn-outline-primary external-btn" disabled>
      PDF
    </button>
  </div>
</div>
