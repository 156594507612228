import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LoanRequestAnswer } from 'src/app/models/loanrequest/loanrequest.answer.model';
import { LoanRequestDTO, LoanRequestEvaluationDTO } from 'src/app/models/loanrequest/loanrequest.dto.model';
import { KobraAccountViewModel, LoanRequest, LoanRequestAdministratoViewModel, LoanRequestOptionsData, LoanRequestOptionsForm, LoanRequestSave } from 'src/app/models/loanrequest/loanrequest.model';
import { LoanRequestFormDTO } from 'src/app/models/loanrequest/loanrequestform.dto.model';
import { LoanRequestForm } from 'src/app/models/loanrequest/loanrequestform.model';
import { MortgageGuarantee } from 'src/app/models/loanrequest/mortgage-guarantee.model';
import { ObligorJoint } from 'src/app/models/loanrequest/obligor-joint.model';
import { PeopleGuarantee } from 'src/app/models/loanrequest/people-guarantee.model';
import { PledgeGuarantee } from 'src/app/models/loanrequest/pledge-guarantee.model';
import { LiquidGuarantee } from 'src/app/models/loanrequest/liquid-guarantee.model';
import { OtherGuarantee } from 'src/app/models/loanrequest/other-guarantee.model';
import { AlertGlobalService } from '../alert-global/alert-global.service';
import { ApiService } from '../api/api.service';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { LoanDisbursement } from 'src/app/models/loan/loan.dto.model';
import { DocumentsLegalGenerated } from 'src/app/models/loanrequest/loanrequestdocumentsgenerated.models';
import { PaymentsJson } from 'src/app/models/loanrequest/loanrequestPaymentsJson.models';

@Injectable({
  providedIn: 'root'
})
export class LoanrequestService {

  constructor(private apiService: ApiService,
              private alertGlobal: AlertGlobalService) { }

  createLoanRequest(loanRequest: LoanRequest) {
    return this.apiService.post('loanrequest', '', loanRequest)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  getLoanRequest(loanRequestId: string) {
    return this.apiService.get('loanrequest', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestDTO;
    }));
  }

  getLoanRequestMade(customerId: string, status = "") {
    return this.apiService.get('loanrequest', 'made', customerId + "/" + status)
    .pipe(map(response => {
      return response.data as LoanRequestDTO[];
    }));
  }

  getLoanRequestReceived(customerId: string, status = "") {
    return this.apiService.get('loanrequest', 'received', customerId + "/" + status)
    .pipe(map(response => {
      return response.data as LoanRequestDTO[];
    }));
  }

  getLoanRequestStatus(customerId: string) {
    return this.apiService.get('loanrequest', 'status', '')
    .pipe(map(response => {
      return response.data as KeyValue<number, string>[];
    }));
  }

  answerLoanRequest(loanRequestId: string, data: LoanRequestAnswer) {
    return this.apiService.put('loanrequest', 'answer', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  closeLoanRequest(loanRequestId: string, sendContact: Boolean) {
    return this.apiService.put('loanrequest', 'close', loanRequestId + "/" + sendContact)
    .pipe(map(response => {
      if (response.status === 200) {
        this.alertGlobal.showAlertSuccess("Prestamo entregado con éxito.");
      }
      return response.data as String;
    }));
  }

  saveLoanRequestForm(loanRequestForm: LoanRequestForm) {
    return this.apiService.post('loanrequest', 'forms', loanRequestForm)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  addComment(loanRequestDetailId: string, comment: any) {
    return this.apiService.post('loanrequest', 'comment/' + loanRequestDetailId, comment)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  getComments(loanRequestDetailId: string) {
    return this.apiService.get('loanrequest', 'comment/list', loanRequestDetailId)
    .pipe(map(response => {
      return response.data as any;
    }));
  }

  cancel(loanRequestId: string) {
    return this.apiService.post('loanrequest', 'cancel/' + loanRequestId)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  remove(loanRequestId: string) {
    return this.apiService.delete('loanrequest', 'remove', loanRequestId)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  getForms(loanRequestId: string) {
    return this.apiService.get('loanrequest', 'forms', loanRequestId)
    .pipe(map(response => {
      return response.data as LoanRequestFormDTO;
    }));
  }

  updateDocumentsRequired(loanRequestId: string, data: string) {
    return this.apiService.put('loanrequest', 'documentsrequired', loanRequestId, { data: data })
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  updateDocumentsLegalRequired(loanRequestId: string, data: string) {
    return this.apiService.put('loanrequest', 'documentslegalrequired', loanRequestId, { data: data })
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  updatePeopleGuarantee(loanRequestId: string, data: PeopleGuarantee[]) {
    return this.apiService.put('loanrequest', 'LoanRequestFormPeopleGuarantee', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  updateObligorJoint(loanRequestId: string, data: ObligorJoint[]) {
    return this.apiService.put('loanrequest', 'LoanRequestFormObligorJoint', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  updateMortgageGuarantee(loanRequestId: string, data: MortgageGuarantee[]) {
    return this.apiService.put('loanrequest', 'LoanRequestFormMortgageGuarantee', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  updatePletgeGuarantee(loanRequestId: string, data: PledgeGuarantee[]) {
    return this.apiService.put('loanrequest', 'LoanRequestFormPledgeGuarantee', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  updateLiquidGuarantee(loanRequestId: string, data: LiquidGuarantee) {
    return this.apiService.put('loanrequest', 'LoanRequestFormLiquidGuarantee', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  updateOtherGuarantee(loanRequestId: string, data: OtherGuarantee) {
    return this.apiService.put('loanrequest', 'LoanRequestFormOtherGuarantee', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  // updateLegalConditions(loanRequestId: string, legalcondition: number) {
  //   return this.apiService.put('loanrequest', 'LoanRequestLegalConditions', loanRequestId, legalcondition)
  //   .pipe(map(response => {
  //     return response.data as String;
  //   }));
  // }

  updateFormRequest(loanRequestId: string, data: LoanRequestSave, documentsCompleted: boolean) {
    return this.apiService.put('loanrequest', 'LoanRequestUpdateFormRequest', loanRequestId + "/" + documentsCompleted, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdatePeopleGuaranteeJsonForm(loanRequestId: string, data: WarrantysForm) {
    return this.apiService.put('loanrequest', 'UpdatePeopleGuaranteeJsonForm', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdateObligorJointGuaranteeJsonForm(loanRequestId: string, data: WarrantysForm) {
    return this.apiService.put('loanrequest', 'UpdateObligorJointGuaranteeJsonForm', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdateMortgageGuaranteeJsonForm(loanRequestId: string, data: WarrantysForm) {
    return this.apiService.put('loanrequest', 'UpdateMortgageGuaranteeJsonForm', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdatePledgeGuaranteeJsonForm(loanRequestId: string, data: WarrantysForm) {
    return this.apiService.put('loanrequest', 'UpdatePledgeGuaranteeJsonForm', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdateLiquidGuaranteeJsonForm(loanRequestId: string, data: WarrantysForm) {
    return this.apiService.put('loanrequest', 'UpdateLiquidGuaranteeJsonForm', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdateOtherGuaranteeJsonForm(loanRequestId: string, data: WarrantysForm) {
    return this.apiService.put('loanrequest', 'UpdateOtherGuaranteeJsonForm', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdateDocumentsRequiredConfigJson(loanRequestId: string) {
    return this.apiService.put('loanrequest', 'UpdateDocumentsRequiredConfigJson', loanRequestId)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdateDocumentsLegalRequiredConfigJson(loanRequestId: string) {
    return this.apiService.put('loanrequest', 'UpdateDocumentsLegalRequiredConfigJson', loanRequestId)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdateDocumentsLegalGenerated(loanRequestId: string, data: DocumentsLegalGenerated[]) {
    return this.apiService.put('loanrequest', 'LoanRequestUpdateDocumentsLegalGenerated', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  SaveDisbursementForm(loanRequestId: string, loanPayment: LoanDisbursement) {
    return this.apiService.put('loanrequest', 'SaveDisbursement', loanRequestId, loanPayment)
    .pipe(map(response => {
      if (response.status === 200) {
        this.alertGlobal.showAlertSuccess("Préstamo autorizado con éxito.");
      }
      return response.data as String;
    }));
  }

  UpdatePaymentsJson(loanRequestId: string, data: PaymentsJson) {
    return this.apiService.put('loanrequest', 'UpdatePaymentsJson', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  AuthorizationLoanRequest(loanRequestId: string) {
    return this.apiService.put('loanrequest', 'AuthorizationLoanRequest', loanRequestId)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  UpdateEvaluation(loanRequestId: string, data: LoanRequestEvaluationDTO[]) {
    return this.apiService.put('loanrequest', 'UpdateEvaluationRequest', loanRequestId, data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }
  
  getLoanFormRequest(loanRequestId: string) {
    return this.apiService.get('loanrequest/getLoanFormRequest', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsForm;
    }));
  }

  getAddressDataRequest(loanRequestId: string) {
    return this.apiService.get('loanrequest/getAddressDataRequest', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsForm;
    }));
  }

  getContactDataRequest(loanRequestId: string) {
    return this.apiService.get('loanrequest/getContactDataRequest', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsData;
    }));
  }

  getEconomicActivity(loanRequestId: string) {
    return this.apiService.get('loanrequest/getEconomicActivity', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsForm;
    }));
  }

  getPatrimonialData(loanRequestId: string) {
    return this.apiService.get('loanrequest/getPatrimonialData', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsData;
    }));
  }

  getEconomicFlow(loanRequestId: string) {
    return this.apiService.get('loanrequest/getEconomicFlow', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsData;
    }));
  }

  getFamilyData(loanRequestId: string) {
    return this.apiService.get('loanrequest/getFamilyData', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsForm;
    }));
  }

  getReferenceData(loanRequestId: string) {
    return this.apiService.get('loanrequest/getReferenceData', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsData;
    }));
  }

  getSocioeconomicData(loanRequestId: string) {
    return this.apiService.get('loanrequest/getSocioeconomicData', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsForm;
    }));
  }

  getBankData(loanRequestId: string) {
    return this.apiService.get('loanrequest/getBankData', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsData;
    }));
  }

  getBalanceSheetData(loanRequestId: string) {
    return this.apiService.get('loanrequest/getBalanceSheetData', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsData;
    }));
  }

  getStatusResultData(loanRequestId: string) {
    return this.apiService.get('loanrequest/getStatusResultData', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsData;
    }));
  }

  getPLDData(loanRequestId: string) {
    return this.apiService.get('loanrequest/getPLDData', loanRequestId, "", false)
    .pipe(map(response => {
      return response.data as LoanRequestOptionsForm;
    }));
  }

  sendExpedientMail(loanRequestId: string, borrowerEmail: string, borrowerName: string, lenderEmail: string, lenderName:string) {
    return this.apiService.post('loanrequest', 'sendExpedientMail/' + loanRequestId + "/" + borrowerEmail + "/" + borrowerName + "/" + lenderEmail + "/" + lenderName)
    .pipe(map(response => {
      return response.data as String;
    }));
  }
  
  getExpedient(loanRequestId: string) {
    return this.apiService.get('loanrequest/getExpedient', loanRequestId, "", true)
    .pipe(map(response => {
      return response.data;
    }));
  }

  getLoanRequestAdministrator(entry: string) {
    return this.apiService.get('loanrequest', 'getLoanRequestAdministrator', entry)
    .pipe(map(response => {
      return response.data as LoanRequestAdministratoViewModel[];
    }));
  }

  newAccount(data: KobraAccountViewModel) {
    return this.apiService.post('loanrequest', 'KobraNewAccount', data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  SendMailCompleteValidation(loanRequestId: string, borrowerEmail: string, borrowerName: string, lenderEmail: string, lenderName: string) {
    return this.apiService.post('loanrequest', 'SendCompleteValidationMail/' + loanRequestId + "/" + borrowerEmail + "/" + borrowerName + "/" + lenderEmail + "/" + lenderName)
    .pipe(map(response => {
      return response.data as String;
    }));
  }
}
