import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalLists } from 'src/app/global/global-data';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-send-info-suscriptions',
  templateUrl: './send-info-suscriptions.component.html',
  styleUrls: ['./send-info-suscriptions.component.scss']
})
export class SendInfoSuscriptionsComponent implements OnInit {

  commentsForm: FormGroup;
  submitted = false;
  suscriptions = GlobalLists.suscriptions;
  
  constructor(private formBuilder: FormBuilder,
              private notificationService: NotificationService,
              private dialogRef: MatDialogRef<SendInfoSuscriptionsComponent>) {
    this.createcommentsForm();
  }

  ngOnInit(): void {
  }

  get f_commentsForm() { return this.commentsForm.controls; }

  createcommentsForm() {
    this.commentsForm = this.formBuilder.group({
      name: ['', [Validators.required]],      
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      suscription: ['', [Validators.required]],
      comments: ['', [Validators.required]]
    });
  }

  commentsOnSubmit(e) {
    this.submitted = true;

    if (this.commentsForm.invalid) {
        return;
    }

    const data = {
      name: this.f_commentsForm['name'].value,
      email: this.f_commentsForm['email'].value,
      phoneNumber: this.f_commentsForm['phoneNumber'].value,
      suscription: this.f_commentsForm['suscription'].value.toString(),
      comments: this.f_commentsForm['comments'].value
    }
    this.notificationService.sendCommentsForSuscriptions(data)
      .subscribe(() => {
        this.commentsForm.reset();
        this.dialogRef.close();
      });
  }

}
