<h1 mat-dialog-title>{{conditionName}}</h1>
<form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex="50%" appearance="fill">
            <mat-label>Comentario</mat-label>
            <textarea matInput formControlName="comment" autocomplete="off" autocapitalize="off" maxlength="100"></textarea>
            <mat-error *ngIf="validateRequiredForm('comment')">{{requiredErrorMessage}}</mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
        <div fxFlex="25%"></div>
        <div fxFlex="23%" mat-dialog-actions align="end">
            <button mat-raised-button color="warn">
                <span class="material-icons">comment</span> Comentar
            </button>
        </div>        
    </div>
</form>

<div mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="Recibidos">
            <mat-card *ngFor="let item of commentsReceived">{{item.dataCreated | date :'short'}} - {{item.comment}}</mat-card>
        </mat-tab>
        <mat-tab label="Enviados">
            <mat-card *ngFor="let item of commentsSent">{{item.dataCreated | date :'short'}} - {{item.comment}}</mat-card>
        </mat-tab>            
    </mat-tab-group>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</div>
