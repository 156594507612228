<div mat-dialog-content>
    <label>Generar factura</label>
    <label>Seleccione el tipo de factura a generar</label>
    <mat-horizontal-stepper linear #stepperBill labelPosition="bottom">
        <mat-step [stepControl]="billTypeForm" [optional]="false">
            <ng-template matStepLabel>Tipo de factura</ng-template>
            <div>
                <form [formGroup]="billTypeForm" (ngSubmit)="onSubmitBillTypeForm()">
                    <div mat-dialog-content>
                        <mat-form-field fxFlex="33%" appearance="fill">
                        <label class="label-input" matPrefix>Tipo de factura </label>
                        <mat-select #selectTypeOfBill formControlName="typeOfBill" (selectionChange)="onChangeSelectTypeOfBill(selectTypeOfBill.value)">
                            <mat-option value="">Seleccione</mat-option>
                            <mat-option *ngFor="let item of typeOfBilltList" value="{{item.key}}">{{item.value}}</mat-option>
                        </mat-select>
                        <mat-hint>Selecciona un tipo</mat-hint>
                        </mat-form-field>
                        <!-- <mat-error *ngIf="validateBillTypedForm('typeOfBill')">{{requiredBillTypeErrorMessage}}</mat-error> -->
                    </div>
                    <div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxLayoutAlign="center center">
                            <button mat-raised-button color="accent">Continuar <span class="material-icons">east</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </mat-step>        

        <mat-step [stepControl]="subscriptionsForm" [optional]="false">
            <ng-template matStepLabel>Suscripciones</ng-template>
            <div>
                <form [formGroup]="subscriptionsForm" (ngSubmit)="onSubmitSubscriptionsForm()">
                    <app-input-errors-global *ngFor="let item of f_subscriptionsFormProp" fieldName="{{item}}"></app-input-errors-global>

                    <div mat-dialog-content>
                            <div *ngIf="billDataSource">
                                <table matTableResponsive mat-table matSort [dataSource]="billDataSource" (matSortChange)="onClickSort()" (dblclick)="doubleClick($event, rowSelected.id)">
                                    <ng-container matColumnDef="subscripcionStartDate">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha inicio </th>
                                        <td mat-cell *matCellDef="let row"> {{row.subscripcionStartDate | date:'medium'}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
                                        <td mat-cell *matCellDef="let row"> {{row.amount | currency}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="description">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Paquete </th>
                                        <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="fullName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre completo </th>
                                        <td mat-cell *matCellDef="let row"> {{row.fullName}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="numberCard">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tarjeta </th>
                                        <td mat-cell *matCellDef="let row">
                                        <div *ngIf="row.methodNumber === 1">
                                            ***{{row.numberCard}}
                                        </div>
                                        </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo </th>
                                        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </th>
                                        <td mat-cell *matCellDef="let row"> {{row.status}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="subscripcionEndDate">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha fin </th>
                                        <td mat-cell *matCellDef="let row"> {{row.subscripcionEndDate | date:'medium'}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="methodPayment">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Método de pago </th>
                                        <td mat-cell *matCellDef="let row"> {{row.methodPayment}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="subscriptionColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: subscriptionColumns; let i = index" (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>
                            </div>
                    </div>
                        
                    <div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxLayoutAlign="center center">
                            <button type="button" mat-stroked-button matStepperPrevious><mat-icon>west</mat-icon> Atrás</button>
                            <button mat-raised-button color="accent" [disabled]="getIsDisabledNext()">Continuar <span class="material-icons">east</span></button>
                        </div>
                    </div>           
                </form>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Resumen</ng-template>
            <div>
                <form (ngSubmit)="onSubmitBillResumeForm()">
                    <span class="card-errors"></span>
                    <div mat-dialog-content>
                        <mat-list>
                            <div mat-subheader>
                                Resumen
                            </div>
                            <mat-list-item>
                                <mat-icon mat-list-icon>info</mat-icon>
                                <div mat-line>Monto</div>
                                <div mat-line class="subtitle-list">{{totalAmount | currency}}</div>
                            </mat-list-item>
                             <mat-list-item>
                                <mat-icon mat-list-icon>info</mat-icon>
                                <div mat-line>Paquete</div>
                                <div mat-line class="subtitle-list">{{rowSelected.description}}</div>
                            </mat-list-item>
                            <mat-list-item>
                                <mat-icon mat-list-icon>info</mat-icon>
                                <div mat-line>Correo</div>
                                <div mat-line class="subtitle-list">{{rowSelected.email}}</div>
                            </mat-list-item>
                        </mat-list>

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxLayoutAlign="center center">
                            <button type="button" mat-stroked-button matStepperPrevious><mat-icon>west</mat-icon> Atrás</button>
                            <button mat-raised-button color="accent" type="submit">Generar factura <span class="material-icons">attach_money</span></button>
                        </div>
                    </div>                
                </form>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>