import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PeriodsService {

  periodsList: KeyValue<number, string>[] = [
    { key: 1, value: "Mensual" },
    { key: 2, value: "Anual" },
  ];

  private periodsList$ = new BehaviorSubject<KeyValue<number, string>[]>(this.periodsList);

  constructor() { }

  getList() {
    return this.periodsList$.asObservable();
  }
}
