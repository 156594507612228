import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FornitureTypeService {

  fornitureTypeList: KeyValue<number, string>[] = [
    { key: 1, value: "Vehículo" },
    { key: 2, value: "Maquinaria" },
    { key: 3, value: "Equipo" },
    { key: 4, value: "Mobilario" },
    { key: 5, value: "Intangibles" },
    { key: 6, value: "Otro" }
  ];

  private fornitureTypeList$ = new BehaviorSubject<KeyValue<number, string>[]>(this.fornitureTypeList);

  constructor() { }

  getList() {
    return this.fornitureTypeList$.asObservable();
  }
}
