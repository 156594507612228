import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalLists } from 'src/app/global/global-data';
import { AdvanceOptions } from 'src/app/models/amortize/amortize.model';

@Component({
  selector: 'app-dialog-advance-options',
  templateUrl: './dialog-advance-options.component.html',
  styleUrls: ['./dialog-advance-options.component.scss']
})

export class DialogAdvanceOptionsComponent implements OnInit {
  mode = "";
  advanceOptionsForm: FormGroup;
  submited = false;

  advanceOptionsColumns = [
    'number',
    'description',
    'buttonRemove'
  ];

  typeOfAdvanceOptions = GlobalLists.typeOfAdvanceOptions;

  advanceOptions = <AdvanceOptions[]>[];

  dsAdvanceOptions = new MatTableDataSource<AdvanceOptions>(this.advanceOptions);

  constructor(private formBuilder: FormBuilder) {
    this.createAdvanceOptionsForm();
  }

  get fAdvanceOptions() { return this.advanceOptionsForm.controls; }

  ngOnInit(): void {

    if (this.advanceOptions) {
      this.dsAdvanceOptions = new MatTableDataSource<AdvanceOptions>(this.advanceOptions);
    }
  }

  createAdvanceOptionsForm() {
    this.advanceOptionsForm = this.formBuilder.group({
      description: ['', Validators.required]
    });
  }

  onSubmitAdvanceOptions() {
    if (this.advanceOptionsForm.invalid) {
      return;
    }

    if (this.dsAdvanceOptions.data.filter(x => x.description == this.fAdvanceOptions['description'].value).length > 0 ) {
      return;
    }

    this.addAdvanceOptions();
  }

  onDeleteAdvanceOptions(index: number): void {
    this.advanceOptions.splice(index, 1);
    this.dsAdvanceOptions = new MatTableDataSource<AdvanceOptions>(this.advanceOptions);
  }

  addAdvanceOptions(): void {
    var lastAdvanceOptionsNumber = 0;
		
		if (this.advanceOptions.length > 0)
		{
			lastAdvanceOptionsNumber = Math.max.apply(Math, this.advanceOptions.map(function(o: { number: any; }) { return o.number; }))
		}

    this.advanceOptions.push(<AdvanceOptions>{
			number: lastAdvanceOptionsNumber + 1,
      description: this.fAdvanceOptions['description'].value
    });

    this.dsAdvanceOptions = new MatTableDataSource<AdvanceOptions>(this.advanceOptions);
    this.fAdvanceOptions['description'].patchValue("");
  }

  onClickAdvanceOptions() {
    this.submited = true;
    
    if (this.submited) {
      return this.advanceOptions;
    } else {
      return '';
    }
  }

}
