import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { Amortize, Payment, PrelationConfig } from '../../models/amortize/amortize.model';
import { AmortizeTableResponse } from '../../models/amortize/amortize.table.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalLists } from 'src/app/global/global-data';

@Injectable({
  providedIn: 'root'
})
export class AmortizeService {

  private amortizeTableResponse$ = new BehaviorSubject<AmortizeTableResponse>(<AmortizeTableResponse>{});
  private amortizeTableExtraPaymentsResponse$ = new BehaviorSubject<AmortizeTableResponse>(<AmortizeTableResponse>{});
  private amortizeReq$ = new BehaviorSubject<Amortize>(<Amortize>{});

  constructor(private apiService: ApiService) { }

  public get currentAmortizeTableValue(): AmortizeTableResponse {
    return this.amortizeTableResponse$.value;
  }

  getAmortizeTableResponseObserver(): Observable<AmortizeTableResponse> {
    return this.amortizeTableResponse$.asObservable();
  }

  public get currentAmortizeTableExtraPaymentsResponseValue(): AmortizeTableResponse {
    return this.amortizeTableExtraPaymentsResponse$.value;
  }

  public get currentAmortizeRequestValue(): Amortize {
    return this.amortizeReq$.value;
  }

  getAmortizeTableExtraPaymentsResponseObserver(): Observable<AmortizeTableResponse> {
    return this.amortizeTableExtraPaymentsResponse$.asObservable();
  }

  getAmortizeRequestObserver(): Observable<Amortize> {
    return this.amortizeReq$.asObservable();
  }

  resetAmortizeRequestObserver() {
    return this.amortizeReq$.next(<Amortize>{});
  }

  resetAmortizeTableResponseObserver() {
    this.amortizeTableResponse$.next(<AmortizeTableResponse>{});
  }

  resetAmortizeTableExtraPaymentsResponseObserver() {
    this.amortizeTableExtraPaymentsResponse$.next(<AmortizeTableResponse>{});
  }

  generateTable(amortize: Amortize) {
    this.apiService.post('amortize', 'table', amortize)
      .subscribe((response => {
        this.amortizeTableExtraPaymentsResponse$.next(response.data as AmortizeTableResponse);
        this.amortizeTableResponse$.next(response.data as AmortizeTableResponse);
        this.amortizeReq$.next(amortize);
      }));
  }

  recalculatePayments(payments: Payment[]) {
    let req = this.amortizeReq$.value;
    req.payments = payments;
    req.prelationConfigs = GlobalLists.prelationConfigs;

    this.apiService.post('amortize', 'table', this.amortizeReq$.value)
      .subscribe((response => {
          this.amortizeTableExtraPaymentsResponse$.next(response.data as AmortizeTableResponse);
          this.amortizeTableResponse$.next(response.data as AmortizeTableResponse);
          this.amortizeReq$.next(req);
      }));
  }

  setTable(amortize: Amortize, amortizeTableResponse: AmortizeTableResponse) {
    this.amortizeTableExtraPaymentsResponse$.next(amortizeTableResponse);
    this.amortizeTableResponse$.next(amortizeTableResponse);
    this.amortizeReq$.next(amortize);
  }
}
