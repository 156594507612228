import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Workbook } from 'exceljs';
import { LoanDTO, LoanExport } from 'src/app/models/loan/loan.dto.model';
import { LoanService } from 'src/app/services/loan/loan.service';
import { DialogLoanpaymentComponent } from '../dialog-loanpayment/dialog-loanpayment.component';
import * as fs from 'file-saver';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import * as XLSX from 'xlsx';
import { each } from 'jquery';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

type AOA = any[][];

@Component({
  selector: 'app-loan-list',
  templateUrl: './loan-list.component.html',
  styleUrls: ['./loan-list.component.scss']
})
export class LoanListComponent implements OnInit {

	@Input() isLender: boolean = false;
	@Input() paymentMode: boolean = false;
	@Input() buttonsAction: boolean = true;

	loanColumns: string[] = [
		'dateCreated',
		// 'lenderLoanCode',
		// 'borrowerLoanCode',
		'lenderName',
		'borrowerName',
		'loanAmount',
		'monthlyRate',
		'totalPayment',
		'currentBalance',
		'balanceOnArrears',
		'statusName'
	];
	loanDataSource: MatTableDataSource<LoanDTO> = new MatTableDataSource([]);

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	rowSelected:LoanDTO = <LoanDTO>{};
	indexSelected = null;
	selectedFiles: FileList;


	data: AOA = [[1, 2], [3, 4]];

	constructor(private loanService: LoanService,
		private router: Router,
				private dialog: MatDialog,
				private alertGlobalService: AlertGlobalService,
				private currencyPipe: CurrencyPipe) { }

	ngOnInit(): void {
		this.getLoans();
	}

	getLoans() {
		if (this.isLender) {
			this.loanService.getLoansPerLender()
			.subscribe((result) => {
			this.loanDataSource = new MatTableDataSource(result);
			this.loanDataSource.paginator = this.paginator;
			this.loanDataSource.sort = this.sort;
			});
		} else {
			this.loanService.getLoansPerBorrower()
			.subscribe((result) => {
			this.loanDataSource = new MatTableDataSource(result);
			this.loanDataSource.paginator = this.paginator;
			this.loanDataSource.sort = this.sort;
			});
		}
	}

	showDialogDoPayment(loanId: string, fromCustomerId: string, toCustomerId: string) {
		const dialogRef = this.dialog.open(DialogLoanpaymentComponent, {
			autoFocus: false,
			width: '80%'
		});
		let instance = dialogRef.componentInstance;
		instance.loanId = loanId;
		instance.fromCustomerId = fromCustomerId;
		instance.toCustomerId = toCustomerId;
	}

	onRowClicked(row, index) {
		this.rowSelected = row;
		this.indexSelected = index;
	}

	onClickPage() {
		this.rowSelected = <LoanDTO>{};
		this.indexSelected = null;
	}

	onClickSort() {
		this.rowSelected = <LoanDTO>{};
		this.indexSelected = null;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.loanDataSource.filter = filterValue.trim().toLowerCase();
	}

	doubleClick(event, loanRequestId)  {
		if (!this.buttonsAction) {
			return;
		}

		if (loanRequestId == undefined) {
			return;
		}

		this.router.navigate(['prestamos/' + loanRequestId]);
	}

	exportExcel() {
		var dataToExport = [];
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("Employee Data");
		let header=["Fecha", "ID préstamo", "Nombre del Solicitante", "Monto del Préstamo", "Interés mensual", "Monto total a pagar", "Saldo total a la fecha", "Saldo con atraso a la fecha", "Estatus"]
		let headerRow = worksheet.addRow(header);

		for ( let i of this.loanDataSource.data) {
			dataToExport.push({
				dateCreated: i.dateCreated,
				contact: this.isLender ? i.lenderLoanCode : i.borrowerLoanCode,
				contactName: this.isLender ? i.lenderName : i.borrowerName,
				loanAmount: this.currencyPipe.transform(i.loanAmount),
				monthlyRate: i.monthlyRate,
				totalPayment: this.currencyPipe.transform(i.totalPayment),
				currentBalance: this.currencyPipe.transform(i.currentBalance),
				balanceOnArrears: this.currencyPipe.transform(i.balanceOnArrears),
				statusName: i.statusName
			})
		}

		for (let x1 of dataToExport) {
			let x2 = Object.keys(x1);
			let temp=[]

			for(let y of x2) {
			temp.push(x1[y])
			}

			worksheet.addRow(temp)
		}

		let fname="alender reporte cartera "

		//add data and file name and download
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, fname+'- '+`${new Date().toISOString()}.xlsx`);
		});
	}

	selectFile(event: any) {
		this.selectedFiles = event.target.files;

		var fileName = this.selectedFiles.item(0).name
		var extension = fileName.replace(/^.*\./, '');

		if (extension != "xlsx") {
			this.alertGlobalService.showAlertWarning("Archivo inválido.");
			return;
		}

		const target: DataTransfer = <DataTransfer>(event.target);

		if (target.files.length !== 1) {
			this.alertGlobalService.showAlertWarning("No se pueden usar varios archivos");
			return;
		}

		const reader: FileReader = new FileReader();
		reader.onload = (e: any) => {
			/* read workbook */
			const bstr: string = e.target.result;
			const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

			/* grab first sheet */
			const wsname: string = wb.SheetNames[0];
			const ws: XLSX.WorkSheet = wb.Sheets[wsname];

			/* save data */
			this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

			var loanExport:LoanExport[] = []

			var list = [
				'Primer nombre'
				, 'Segundo nombre'
				, 'Primer apellido'
				, 'Segundo Apellido'
				, 'Correo'
				, 'Monto'
				, 'Frecuencia de pagos'
				, 'Número de pagos'
				, 'Interés mensual'
				, 'Fecha de inicio'
				, 'Tipo de préstamo'
				, 'Cálculo de interéses'
				, 'Interés moratorio mensual'
				, 'Periodo inicial'
				, 'IVA'
				, 'Retención ISR'
			]

			var n = 0;
			var columnValited = false;

			this.data.forEach(element => {
				if (!columnValited) {
					element.forEach(elementElement => {
						if (elementElement != list[n]) {
							this.alertGlobalService.showAlertWarning("Columna '" + list[n] + "' no válida");
							reader.abort;
							return;
						}
						n++;
					});
					columnValited = true;
				} else {
					if (element.length == 16) {
						loanExport.push(
							{
								primerNombre: element[0],
								segundoNombre: element[1],
								primerApellido: element[2],
								segundoApellido: element[3],
								correo: element[4],
								monto: element[5],
								frecuenciaPagos: element[6],
								numeroPagos: element[7],
								interesMensual: element[8],
								fechaInicio: element[9],
								tipoPréstamo: element[10],
								calculoInteréses: element[11],
								interesMoratorioMensual: element[12],
								PeriodoInicial: element[13],
								IVA: element[14],
								retenciónISR: element[15]
							}
						);
					}
				}
			});
		};

		reader.readAsBinaryString(target.files[0]);
	}
}
