import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { AccountStatement } from '../../models/account-statement/account-statement.model';
import { map } from 'rxjs/operators';
import { AccountStatementMailDTO, SummaryAccountStatement } from 'src/app/models/account-statement/summaryaccountstatement.dto.model';

@Injectable({
  providedIn: 'root'
})
export class AccountStatementService {

  constructor(private apiService: ApiService) { }

  getAccountStatement(loanId: string) {
    return this.apiService.get('accountstatement', '', loanId)
      .pipe(map(response => {
        return response.data as AccountStatement;
      }));
  }

  getSummary(customerId: string, isLender: boolean) {
    return this.apiService.get('accountstatement', 'getsummary/' + customerId + '/' + (isLender ? 'true' : 'false'))
      .pipe(map(response => {
        return response.data as SummaryAccountStatement;
      }));
  }

  getSummaryAdministrator(isLender: boolean) {
    return this.apiService.get('accountstatement', 'getsummaryAdministrator/' + (isLender ? 'true' : 'false'))
      .pipe(map(response => {
        return response.data as SummaryAccountStatement;
      }));
  }

  sendAccountStatementMail(accountStatementMailDTO: AccountStatementMailDTO) {
    return this.apiService.post('accountstatement', 'sendAccountStatementMail', accountStatementMailDTO )
    .pipe(map(response => {
      return response.data as String;
    }));
  }
}
