import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BankData, LoanRequestOptionsData } from 'src/app/models/loanrequest/loanrequest.model';
import { GlobalLists } from 'src/app/global/global-data';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { LoanRequestDTO } from 'src/app/models/loanrequest/loanrequest.dto.model';

@Component({
	selector: 'app-dialog-bank-data',
	templateUrl: './dialog-bank-data.component.html',
	styleUrls: ['./dialog-bank-data.component.scss']
})

export class DialogBankDataComponent implements OnInit {
	bankDataColumns = [
		'bankName',
		'tarjetNumber',
		'bankNumber',
		'clabe',
		'firstName',
		'middleName',
		'lastName1',
		'lastName2',
		'buttonRemove'
	];

	panelBankDataState = false;
	bankList = GlobalLists.bankList;
	
	bankDataForm: FormGroup;
	bankData = <BankData[]>[];
	bankDataOptions: LoanRequestOptionsData;
	dsBankData = new MatTableDataSource<BankData>(this.bankData);

	firstName = "";
	middleName = "";
	lastName1 = "";
	lastName2 = "";
	name = "";
	
	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;
	@Input() loanRequestDTO: LoanRequestDTO;
	@Input() personType: number;

	constructor(
		private formBuilder: FormBuilder,
		private loanrequestService: LoanrequestService,
		private authService: AuthenticationService
		) {		
	}

	submited = false;
	
	isLender = this.authService.currentUserModeLenderValue;

	get fBankData() { return this.bankDataForm.controls; }

	ngOnInit(): void {
		this.createBankDataForm();
		this.setLoanFormRequest();

		this.loanrequestService.getBankData(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.bankData = result.bankData;

				this.dsBankData = new MatTableDataSource<BankData>(this.bankData);

				this.updateBankDataData();
			}
		});

		if (this.completed) {
			this.dr = true;
		}
	}

	setLoanFormRequest() {
		this.loanrequestService.getLoanFormRequest(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				var getLoan = result.optionForm;

				if (this.personType == 1) {
					this.name = "Razón social titular";

					this.fBankData['firstName'].patchValue(getLoan.find(x => x.conditionName == "BusisnessName").value);
					this.fBankData['middleName'].disable();
					this.fBankData['lastName1'].disable();
					this.fBankData['lastName2'].disable();
				} else {
					this.name = "Nombre titular";

					this.fBankData['middleName'].enable();
					this.fBankData['lastName1'].enable();
					this.fBankData['lastName2'].enable();

					if (this.loanRequestDTO.documentsRequiredConfigJsonCompleted) {
						this.fBankData['firstName'].patchValue(getLoan.find(x => x.conditionName == "FirstName").value);
						this.fBankData['middleName'].patchValue(getLoan.find(x => x.conditionName == "MiddleName").value);
						this.fBankData['lastName1'].patchValue(getLoan.find(x => x.conditionName == "LastName1").value);
						this.fBankData['lastName2'].patchValue(getLoan.find(x => x.conditionName == "LastName2").value);
					} else {
						this.fBankData['firstName'].patchValue(this.loanRequestDTO.firstNameQs);
						this.fBankData['middleName'].patchValue(this.loanRequestDTO.middleNameQs);
						this.fBankData['lastName1'].patchValue(this.loanRequestDTO.lastName1Qs);
						this.fBankData['lastName2'].patchValue(this.loanRequestDTO.lastName2Qs);
					}
				}
			}
		});
	}

	createBankDataForm() {
		this.bankDataForm = this.formBuilder.group({
			bankName: ['', Validators.required],
			bankNumber: ['', Validators.required],
			tarjetNumber: ['', Validators.required],
			clabe: [''],
			firstName: ["", Validators.required],
			middleName: [""],
			lastName1: ["", Validators.required],
			lastName2: [""]
		});
	}

	onSubmitBankData() {
		this.submited = true;
		
		if (this.bankDataForm.invalid || this.getIsDisabledSave()) {
		  return;
		}
	
		this.addBankData();
	}
	
	onDeleteBankData(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.bankData.splice(index, 1);
		this.dsBankData = new MatTableDataSource<BankData>(this.bankData);

		this.updateBankDataData();
	}
	
	addBankData(): void {
		this.bankData.push(<BankData>{
			bankName: this.fBankData['bankName'].value,
			bankNumber: this.fBankData['bankNumber'].value ? this.fBankData['bankNumber'].value + "" : "",
			tarjetNumber: this.fBankData['tarjetNumber'].value ? this.fBankData['tarjetNumber'].value + "" : "",
			clabe: this.fBankData['clabe'].value,
			firstName: this.fBankData['firstName'].value,
			middleName: this.fBankData['middleName'].value,
			lastName1: this.fBankData['lastName1'].value,
			lastName2: this.fBankData['lastName2'].value
		});

		this.dsBankData = new MatTableDataSource<BankData>(this.bankData);
		this.bankDataForm.reset();

		this.updateBankDataData();
	}

	panelBankDataOpen(e) {
		this.panelBankDataState = e;
	}

	updateBankDataData() {
		var loanRequestAdvanceOptionsData = <LoanRequestOptionsData>{
			bankData: this.bankData
		};
		
		this.bankDataOptions = loanRequestAdvanceOptionsData;
	}

	validateFormrequestJson() {
		if (this.personType == 1) {
			return false;
		} else {
			return true;
		}
	}

	validateRequiredFormBank(formControlName: string): boolean {
		if (this.fBankData[formControlName] == null) {
			return;
		}

		if(this.fBankData[formControlName].errors !== null && this.fBankData[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	getIsDisabledSave() {
		if (this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) {
			return true;
		} else {
			return false;
		}
	}
}
