import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SummaryAccountStatement } from 'src/app/models/account-statement/summaryaccountstatement.dto.model';
import { subscriptionpaymentDTO } from 'src/app/models/subscriptionpayment/subscriptionpayment.dto.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanService } from 'src/app/services/loan/loan.service';
import { CollectionLogResponse, OriginalName } from 'src/app/models/loan/loan.dto.model';
import { DocumentServiceService } from 'src/app/services/document-service/document-service.service';
import { RequiredDocumentDTO } from 'src/app/models/required-documents/requiredDocuments.dto.model';
import * as L from 'leaflet';
import { LoanResumeComponent } from 'src/app/components/loan-resume/loan-resume.component';
import { KeyValue } from '@angular/common';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DialogPreviewDocumentComponent } from 'src/app/components/dialog-preview-document/dialog-preview-document.component';


@Component({
  selector: 'app-detail-service',
  templateUrl: './detail-service.component.html',
  styleUrls: ['./detail-service.component.scss']
})

export class DetailServiceComponent implements OnInit {
  @Input() externalId: string = "";
  @Input() loanRequestId: string = null;
  summary: SummaryAccountStatement;
  @Input() isLender: boolean;
  @Input() loanId: string;

  subscriptionPaymentColumns: string[] = [
    'description',
    'numberOperations',
    'period',
    'quantity',
    'subscripcionStartDate',
    'subscripcionEndDate',
    'amount',
    'status',
    'statusPlan'
  ];

  @ViewChild('divExport') divExport!: ElementRef;
  @ViewChild(LoanResumeComponent) loanResumeComponent: LoanResumeComponent;
  @ViewChild('gaugeCanvas', { static: false }) gaugeCanvas: ElementRef;

  subscriptionPaymentDataSource: MatTableDataSource<subscriptionpaymentDTO>;
  rowSubscriptionPaymentSelected: subscriptionpaymentDTO = <subscriptionpaymentDTO>{};
  indexSelected = null;
  collectionLogResponse: any;
  requiredDocumentDTO: RequiredDocumentDTO[];
  typeOfPeriodList: KeyValue<number, string>[] = [];
  externalServiceType = "";
  mapLoaded = false;
  panelDataLoanOpenState = false;

  loanFullName = "";
  loanMobile = "";
  loanEmail = "";
  loanAddress = "";
  typeOfPeriodDesc = "";
  stylesHash: any;
  currentOrigin: any;
  resultData: any;
  dataQuestionsTotal: any = [];
  dataQuestions: any = [];
  newQuestions: any = [];
  newQuestionsHalf: any = [];
  newArchivesFilter: any = [];
  firstHalf: any;
  secondHalf: any;
  boolData: boolean = false;
  datelimit: Date = new Date("2024-03-13T10:01:03.3266538-06:00");
  phonesKobra: any;
  emailsKobra: any;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  showNew = false;
  OriginalName: any;
  NavigateUrl: OriginalName;
  moduleName: string;
  typeService: string;

  constructor(public authService: AuthenticationService,
    private router: Router,
    private spinnerService: SpinnerService,
    private loanService: LoanService,
    private dialog: MatDialog,
    private typeofperiodService: TypeofperiodService,
    private documentService: DocumentServiceService,
    private route: ActivatedRoute) {

    this.externalId = this.route.snapshot.paramMap.get('externalId');
    this.loanRequestId = this.route.snapshot.paramMap.get('loanRequestId');
    this.moduleName = this.route.snapshot.paramMap.get('module');
  }

  ngOnInit(): void {
    this.getTypeOfPeriodList();
    this.getCurrentUrl();

    //localStorage.setItem("returnDetailService", "1");

    this.loanService.getOriginalName(this.externalId).subscribe(
      (data) => {

        this.NavigateUrl = data
      });

    if (environment.production == true) {
      this.stylesHash = '.b3e3a8f3e87bf153';
    } else {
      this.stylesHash = "";
    }

    this.authService.userModeLender.subscribe((data) => {
      this.isLender = data;

      if (this.authService.currentUserValue) {
        this.loanService.getCollectionLogDetails(this.externalId).subscribe(
          (data) => {
            this.collectionLogResponse = data;
            this.resultData = this.collectionLogResponse.callCenterResponse;

            this.loanFullName = this.collectionLogResponse.dataSend?.firstName + ' ' + this.collectionLogResponse.dataSend?.middleName + ' ' + this.collectionLogResponse.dataSend?.lastName1 + ' ' + this.collectionLogResponse.dataSend?.lastName2;
            this.loanMobile = this.collectionLogResponse.dataSend?.phone;
            this.loanEmail = this.collectionLogResponse.dataSend?.email;
            if (this.loanRequestId != null) {
              //this.typeOfPeriodDesc = this.typeOfPeriodList.find(x => x.key === this.collectionLogResponse.typeOfPeriod).value;

            }

            if (data.serviceName == 'Visita de cobranza') {
              this.externalServiceType = "visita";
              this.typeService = "Cobranza"
            } else if (data.serviceName == 'Visita de verificación') {
              this.externalServiceType = "visita de verificación";
              this.typeService = "Verificación"
            }

            if (data.serviceName == 'Visita de cobranza' || data.serviceName == 'Visita de verificación') {

              this.filterQuestionsKobra(this.collectionLogResponse.kobraResponse.checklist)
              var dataPhoneskobra = this.collectionLogResponse.kobraResponse.phones;
              this.phonesKobra = dataPhoneskobra.filter(phone => Boolean(phone));

              var dataEmailskobra = this.collectionLogResponse.kobraResponse.emails;
              this.emailsKobra = dataEmailskobra.filter(email => Boolean(email));


              if (this.loanRequestId != null) {
                this.documentService.getDocumentsVisitExternalServicesUser(data.kobraResponse.visitID).subscribe(
                  (data) => {
                    this.requiredDocumentDTO = data;
                  }
                );
              } else {
                this.documentService.getDocumentsVisitExternalServicesUser(data.kobraResponse.visitID).subscribe(
                  (data) => {
                    this.requiredDocumentDTO = data;
                  }
                );
              }
            } else if (data.serviceName == 'Llamada de cobranza') {
              this.externalServiceType = "llamada";

              const datelimitString = this.datelimit.toISOString().split('T')[0];
              const dateCreatedString = new Date(data.dateCreated).toISOString().split('T')[0];
              if (dateCreatedString > datelimitString) {
                this.boolData = true;
              }


              if ((data.statusName === "Finalizado" && this.resultData != null) && this.boolData) {
                this.documentService.getOriginalName(this.externalId).subscribe(
                  (data) => {
                    this.OriginalName = data;
                  }
                );
              }
              if (this.resultData != null) {
                this.resultData.forEach(item => {
                  if (item.answer !== null) {
                    this.dataQuestionsTotal = [];
                    const dateCreated = item.dateCreated;
                    const dateStartManagement = item.dateStartManagement;
                    const dateEndManagement = item.dateEndManagement;
                    const agent = item.agent;
                    const phone = item.phone;
                    const callTypeName = item.callTypeName;
                    const contactTypeName = item.contactTypeName;
                    const questions = item.questions
                    const processedAnswers = this.countAnswers(questions);
                    this.dataQuestions.push({
                      dateCreated: dateCreated,
                      dateStartManagement: dateStartManagement,
                      dateEndManagement: dateEndManagement,
                      agent: agent,
                      phone: phone,
                      callTypeName: callTypeName,
                      contactTypeName: contactTypeName,
                      questions: processedAnswers
                    });
                  }
                });
              }
            }
            const dataSend = this.collectionLogResponse.dataSend;


            this.loanAddress = [
              dataSend?.street,
              dataSend?.exteriorNumber,
              dataSend?.districtName,
              dataSend?.zipCode ? 'CP. ' + dataSend.zipCode : undefined,
              dataSend?.cityName,
              dataSend?.stateName,
              dataSend?.countryName
            ]
              .filter(part => part)
              .join(', ');

          }
        );
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.mapLoaded == false && (this.externalServiceType == "visita" || this.externalServiceType == "visita de verificación")) {
      const map = L.map('map').setView([parseFloat(this.collectionLogResponse.kobraResponse.lat), parseFloat(this.collectionLogResponse.kobraResponse.lng)], 15);

      const content = '/assets/images/market.png';

      var customIcon = L.icon({
        iconUrl: content,
        iconSize: [25, 41],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© a-lender'
      }).addTo(map);

      L.marker([parseFloat(this.collectionLogResponse.kobraResponse.lat), parseFloat(this.collectionLogResponse.kobraResponse.lng)], {
        icon: customIcon
      })
        .addTo(map)
        .bindPopup('Ubicación de visita')
        .openPopup();

      this.mapLoaded = true;
    }
  }

  getServiceFields(serviceType: string): { label: string; key: string }[] {
    if (serviceType === 'visita') {
      return [
        { label: 'Correo electrónico', key: 'email' },
        { label: 'Teléfono celular', key: 'phone' },
        { label: 'Dirección', key: 'address.street' },
      ];
    } else if (serviceType === 'llamada') {
      return [
        { label: 'Teléfono celular', key: 'phone' },
        { label: 'Dirección', key: 'address.street' },
        { label: 'Correo electrónico', key: 'email' },
      ];
    }
    return [];
  }

  getFieldData(key: string): string {
    const keys = key.split('.');
    let value: any = this.collectionLogResponse.dataSend;

    for (const k of keys) {
      if (k === 'street') {
        return this.loanAddress;
      }

      value = value ? value[k] : '';
    }

    return value !== undefined ? value : '';
  }

  openNew(action: string) {
    this.router.navigate(['suscripciones/contratar-plan/' + action]);
  }

  export() {
    this.panelDataLoanOpen(true);
    this.spinnerService.requestStarted();

    this.delay(() => {
      const elementToExport = document.getElementById('elementToExport');
      const content = elementToExport.innerHTML;


      const stylesLink = this.generateStylesLink();
      const windowsEmergent = window.open('', '', 'width=800,height=600');


      windowsEmergent.document.write(`
        <html>
        <head>
        <title>a-lender</title>
          ${stylesLink}
        </head>
        <style>
        @page {
          size: 297mm 210mm; 
          margin: 10mm;
        }

        .btn {
          display: none !important;
        }
        body {
          font-family: "Poppins", sans-serif;
        }
        .mat-button-toggle-disabled {
          pointer-events: none;
      }
      .row .custom-heading {
        margin-top: 20px;
      }
      
      .mat-button-toggle-checked {
          background-color: white;
      }

      .col-md-3 {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        width: 25%; }

        .col-md-6 {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          width: 50%; }
      
      .row-selected {
          background-color: #8ba2bd;
      }
      
      .subtittle {
          color: gray;
          font-size: 14;
          padding-bottom: 1rem;
      }
      
      #map {
          height: 400px;
      }
      
      p {
        color: $c_black;
        font-size: 14px;
        font-weight: 500;
      }
      
      .row {
        .col-md-3.col-6 {
          p {
            margin-bottom: 0.5rem;
          }
      
          &:last-child, &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4) {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
      </style>
        <body>
          ${content}
        </body>
        </html>
      `);
      windowsEmergent.document.close();

      windowsEmergent.onload = () => {
        windowsEmergent.print();
      };

      windowsEmergent.onafterprint = () => {
        windowsEmergent.close();
        this.spinnerService.requestEnded();
      };
    }, 1500);
  }

  generateStylesLink() {
    const stylesLink = document.createElement('link');
    stylesLink.setAttribute('rel', 'stylesheet');
    stylesLink.setAttribute('type', 'text/css');
    stylesLink.setAttribute('href', `${this.currentOrigin}/styles${this.stylesHash}.css`);
    document.head.appendChild(stylesLink);
    return stylesLink.outerHTML;
  }

  getCurrentUrl() {
    this.currentOrigin = window.location.origin;
  }

  panelDataLoanOpen(e) {
    this.panelDataLoanOpenState = e;
  }

  delay(callback: () => void, ms: number) {
    setTimeout(callback, ms);
  }
  capitalizeFirstLetter(value: string): string {
    if (!value || value.length === 0) {
      return '';
    }

    const words = value.split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return capitalizedWords.join(' ');
  }

  calculateTimeDifference(startDate: Date, endDate: Date): string {
    const diffInSeconds = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = diffInSeconds % 60;

    return `${minutes} min ${seconds} s`;
  }

  getDuration(startDate: Date, endDate: Date): string {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const diffInMillis = end.getTime() - start.getTime();
    const minutes = Math.floor(diffInMillis / 60000);
    const seconds = Math.floor((diffInMillis % 60000) / 1000);

    return `${minutes} min ${seconds < 10 ? '0' : ''}${seconds} s`;
  }

  return() {
    if (this.loanRequestId != null) {

      this.router.navigate([this.moduleName])
    } else {
      this.router.navigate([this.moduleName])
    }

  }

  getTypeOfPeriodList() {
    this.typeofperiodService.getList().subscribe((list) => {
      this.typeOfPeriodList = list;
    });
  }

  extractFtpUrl(url: string): string {
    const domainRegex = /^(?:https?:\/\/)?[^/]+/i;
    url = url.replace(domainRegex, '');


    if (environment.production == true) {
      url = "https://storage-prod.a-lender.com" + url;
    } else {
      url = "https://storage-dev.a-lender.com" + url;
    }
    return url;
  }


  countAnswers(questions: any) {

    let newQuestionsResult = [];
    let newQuestionsHalf = [];
    let newArchivesFilterResult = [];
    let answer = 0;
    let additionalCount = 0;

    const questionAdd = {
      "answer": "Otro",
      "comments": "Comentarios",
      "formContact": "Forma de contacto",
      "amount": "Monto por pagar",
      "date": "Fecha",
      "form": "Forma de pago",
      "otherForm": "Otra forma de pago",
      "hour": "Hora para volver a realizar la llamada",
      "name": "",
      "url": ""
    };


    var newQuestions = questions.filter(function (question) {
      return question.idQuestion !== 16.5;
    });

    var newArchivesFilter = questions.filter(function (question) {
      return question.idQuestion == 16.5;
    });

    newQuestions.forEach(item => {
      if (item.answer !== null) {
        const itemQuestion = item.question;
        let itemAnswer = item.answer;

        if (itemQuestion === "Evaluación de llamada" && itemAnswer === 0) {
          itemAnswer = "N/A";
        }

        newQuestionsResult.push({ Question: itemQuestion, Answer: itemAnswer });
        answer++;
      }
      if (item.additionalAnswer !== null) {
        item.additionalAnswer.forEach(subItem => {
          for (const key in subItem) {
            if (subItem[key] !== null) {
              let itemAddQuestion = key;
              const itemAddAnswer = subItem[key];
              if (questionAdd.hasOwnProperty(itemAddQuestion)) {
                itemAddQuestion = questionAdd[itemAddQuestion];
              }
              newQuestionsResult.push({ Question: itemAddQuestion, Answer: itemAddAnswer });
              additionalCount++;
            }
          }
        });
      }
    });

    const halfwayIndex = Math.ceil(newQuestionsResult.length / 2);
    newQuestionsHalf = newQuestionsResult.splice(halfwayIndex);

    newArchivesFilter.forEach(item => {
      if (item.additionalAnswer !== null) {
        item.additionalAnswer.forEach(subItem => {
          for (const key in subItem) {
            if (subItem[key] !== null) {
              let itemAddQuestion = key;
              const itemAddAnswer = subItem[key];
              if (itemAddQuestion == "url") {
                newArchivesFilterResult.push({ url: itemAddAnswer });
              }
              additionalCount++;
            }
          }
        });
      }
    });

    return {
      questionResult: newQuestionsResult,
      questionHalf: newQuestionsHalf,
      filesArchives: newArchivesFilterResult
    };
  }

  openImage(url: string) {

    const domainRegex = /^(?:https?:\/\/)?[^/]+/i;
    url = url.replace(domainRegex, '');


    if (environment.production == true) {
      url = "https://storage-prod.a-lender.com" + url;
    } else {
      url = "https://storage-dev.a-lender.com" + url;
    }
    var fileImage = url

    if (fileImage != "") {

      const dialogRef = this.dialog.open(DialogPreviewDocumentComponent, {
        autoFocus: false,
        width: '80%'
      });
      let instance = dialogRef.componentInstance;
      instance.extensionFile = /[^.]+$/.exec("foto.jpg")[0];
    }
  }

  openDocument() {
    const dialogRef = this.dialog.open(DialogPreviewDocumentComponent, {
      autoFocus: false,
      width: '80%'
    });
    let instance = dialogRef.componentInstance;
    instance.url = this.NavigateUrl[0].urlNavigate;
    instance.extensionFile = /[^.]+$/.exec(this.OriginalName)[0];
  }

  filterQuestionsKobra(Data: any): any {
    if (Data && Array.isArray(Data)) {
      Data.forEach(item => {
        item.answers.forEach(answer => {
          if (answer.value === 'true') {
            answer.value = "Si";
          } else if (answer.value === 'false') {
            answer.value = "No";
          } else if (answer.AnswerTypeName === 'datepicker') {
            const dateParts = answer.Value.split('/');
            if (dateParts.length === 3) {
              answer.Value = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
            }
          } else if (answer.AnswerTypeName === 'decimal') {
            answer.value = "$" + answer.Value;
          }
        });
      });

      const filteredData = Data.filter(item => item.answers.some(answer => answer.value !== null));

      const halfwayIndex = Math.ceil(filteredData.length / 2);
      this.firstHalf = filteredData.slice(0, halfwayIndex);
      this.secondHalf = filteredData.slice(halfwayIndex);
    } else {
      this.firstHalf = [];
      this.secondHalf = [];
    }
  }


}
