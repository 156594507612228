import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserAdministration, SubcriptionAdministrationViewModel } from 'src/app/models/user/user.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
        selector: 'app-dialog-show-details-administrator-subscriptions',
        templateUrl: './dialog-show-details-administrator-subscriptions.component.html',
        styleUrls: ['./dialog-show-details-administrator-subscriptions.component.scss']
})
        export class DialogShowDetailsAdministratorSubscriptionsComponent implements OnInit, AfterViewInit {
        @ViewChild(MatPaginator) paginator: MatPaginator;
        @ViewChild(MatSort) sort: MatSort;
        // detailsAdministratorDataSource: MatTableDataSource<SubcriptionAdministrationViewModel>;
        administratorsubscriptionsDataSource: MatTableDataSource<any>;
        isLender = false;

        rowSelected: SubcriptionAdministrationViewModel = <SubcriptionAdministrationViewModel>{};
        indexSelected = null;

        detailsAdministratorColumns: string[] = [
                'subscripcionStartDate',
                'description',
                'packageOperations',
                'idPeriod',
                'periodQuantity',
                'firstName',
                'middleName',
                'lastName1',
                'lastName2',
                'userName',
                'isEnabled',
                'subscripcionEndDate',
                'amountWithoutTAX',
                'amount',
                'idMethodPayment',
                'status',
                'subscriptionType'
          ];

          constructor(
		public dialog: MatDialogRef<DialogShowDetailsAdministratorSubscriptionsComponent>,
		private authService: AuthenticationService,
		private changeDetector: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: UserAdministration) { }
		private datePipe: DatePipe
		private currencyPipe: CurrencyPipe


	closeDialog(): void {
		this.dialog.close(false);
	}
	confirm(): void {
		this.dialog.close(true);
	}

	ngOnInit(): void {
		this.isLender = this.authService.currentUserModeLenderValue;

        if (this.data.entry == "details") {
                this.administratorsubscriptionsDataSource = new MatTableDataSource(this.data.subcriptionAdministrationViewModel);
                this.administratorsubscriptionsDataSource.paginator = this.paginator;
                this.administratorsubscriptionsDataSource.sort = this.sort;
        }
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewInit(): void {
        if (this.administratorsubscriptionsDataSource != null) {
                this.administratorsubscriptionsDataSource.paginator = this.paginator;
                this.administratorsubscriptionsDataSource.sort = this.sort;
        }
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.administratorsubscriptionsDataSource.filter = filterValue.trim().toLowerCase();
	}

	onRowClicked(row, index) {
	  this.rowSelected = row;
	  this.indexSelected = index;
	}

	onClickPage() {
	  this.rowSelected = <SubcriptionAdministrationViewModel>{};
	  this.indexSelected = null;
	}

        exportExcel() {
                var dataToExport = [];
                let workbook = new Workbook();
                let worksheet = workbook.addWorksheet("Detalle");
                let header=[
                "Fecha inicio suscripción","Nombre suscripción","No. operaciones","Periodicidad","No. períodos","Primer nombre","Segundo nombre","Primer apellido","Segundo apellido","Correo electrónico","Estatus plan","Fecha fin suscripción actual","Monto suscripción actual sin IVA ","Monto suscripción actual con IVA","Metodo de pago","Estado plan","Tipo de suscripción"
                ]
                let headerRow = worksheet.addRow(header);

                for ( let i of this.administratorsubscriptionsDataSource.data) {
                dataToExport.push({
                        subscripcionStartDate:new Date(i.subscripcionStartDate).toLocaleDateString('en-GB'),
                        description: i.description,
                        packageOperations: i.packageOperations,
                        idPeriod: i.idPeriod,
                        periodQuantity: i.periodQuantity,
                        firstName: i.firstName,
                        middleName: i.middleName,
                        lastName1: i.lastName1,
                        lastName2: i.lastName2,
                        userName: i.userName,
                        isEnabled: i.isEnabled,
                        subscripcionEndDate:new Date(i.subscripcionEndDate).toLocaleDateString('en-GB'),
                        amountWithoutTAX: Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(i.amountWithoutTAX),
                        amount:Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(i.amount),
                        idMethodPayment: i.idMethodPayment,
                        status: i.status,
                        subscriptionType: i.subscriptionType
                        })
                 }

                for (let x1 of dataToExport) {
                        let x2 = Object.keys(x1);
                        let temp=[]

                        for(let y of x2) {
                        temp.push(x1[y])
                        }

                        worksheet.addRow(temp)
                }

                let fname="Detalle de Base de Datos"

                //add data and file name and download
                workbook.xlsx.writeBuffer().then((data) => {
                        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        fs.saveAs(blob, fname+'- '+`${new Date().toISOString()}.xlsx`);
                });
        }
}
