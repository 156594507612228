<h1 mat-dialog-title>Garantías</h1>
<form class="w-100" [formGroup]="warrantysForm" (ngSubmit)="onSubmitWarranty()">
    <div *ngIf="mode === 'new'" class="d-flex align-items-end mb-4">
        <div class="col-lg-3 col-md-6">
            <div class="form-group">
                <label>Tipo de garantía</label>
                <select class="form-control" formControlName="description"
                    [ngClass]="submitted && validateRequiredFormWarrantys('description') ? 'is-invalid' : ''">
                    <option value="">Seleccione</option>
                    <option *ngFor="let item of filteredOptions | async" value="{{item}}">
                        {{item}}
                    </option>
                </select>
            </div>
        </div>
        <button class="btn btn-outline-primary internal-btn">
            Agregar
        </button>
    </div>

    <div class="table-responsive">
        <div class="react-bootstrap-table">
            <table *ngIf="dsWarrantys" mat-table [dataSource]="dsWarrantys">
                <ng-container matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef>Número</th>
                    <td mat-cell *matCellDef="let row">{{row.number}}</td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Tipo de garantía</th>
                    <td mat-cell *matCellDef="let row">{{row.description}}</td>
                </ng-container>

                <ng-container matColumnDef="buttonRemove">
                    <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                    <td mat-cell *matCellDef="let row; let i = index;">
                        <i class="bx bx-x" *ngIf="mode === 'new'" (click)="onDeleteWarranty(i)"></i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="warrantysColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: warrantysColumns;"></tr>
            </table>
        </div>
    </div>
</form>

<div class="d-flex flex-wrap gap-2 mt-4 justify-content-end">
    <button class="btn btn-outline-primary external-btn" mat-dialog-close>
        Cerrar
    </button>
    <button class="btn btn-primary external-btn" *ngIf="mode === 'new'" [mat-dialog-close]="onClickWarranty()">
        Guardar
    </button>
</div>