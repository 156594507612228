<div class="col-md-6">
    <div class="card">
        <div class="card-body">
            <h5>Préstamos</h5>
            <div class="float-end">
                <a id="buttonSetLender" class="btn btn-primary" routerLink="/importacion/prestamos">
                    ir
                </a>
            </div>
            <div class="float-end">
                <a id="buttonSetLender" class="btn btn-primary" (click)="export('prestamos')">
                    Descargar
                </a>
            </div>
        </div>
    </div>
</div>
<div class="col-md-6">
    <div class="card">
        <div class="card-body">
            <h5>Pagos</h5>
            <div class="float-end">
                <a id="buttonSetLender" class="btn btn-primary" (click)="export('pagos')">
                    Descargar
                </a>
                <a id="buttonSetLender" class="btn btn-primary" routerLink="/importacion/pagos">
                    ir
                </a>
            </div>
        </div>
    </div>
</div>
