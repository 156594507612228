import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RequiredDocumentDTO } from 'src/app/models/required-documents/requiredDocuments.dto.model';

@Injectable({
  providedIn: 'root'
})
export class RequiredDocumentsLegalConfigService {

  requiredDocuments: RequiredDocumentDTO[] = <RequiredDocumentDTO[]> [{
    Id: 0,
    Type: "contract",
    Description: "Contrato",
    Required: true,
    Disabled: false
  }, {
    Id: 1,
    Type: "pagare",
    Description: "Pagaré",
    Required: true,
    Disabled: false
  }
]

  private requiredDocuments$ = new BehaviorSubject<RequiredDocumentDTO[]>(this.requiredDocuments);

  constructor() { }

  public get currentrequiredDocuments(): RequiredDocumentDTO[] {
    return this.requiredDocuments$.value;
  }

  getRequiredDocumentObserver(): Observable<RequiredDocumentDTO[]> {
    return this.requiredDocuments$.asObservable();
  }

  resetRequiredDocumentObserver() {
    this.requiredDocuments$.next(this.requiredDocuments);
  }

  patchRequiredDocument(data: RequiredDocumentDTO[]) {
    this.requiredDocuments$.next(data);
  }
}
