import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { billDTO } from 'src/app/models/bill/bill.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { HtmlViewerComponent } from 'src/app/views/html-viewer/html-viewer.component';
import { DialogGenerateBillComponent } from '../dialog-generate-bill/dialog-generate-bill.component';
import { DialogPreviewDocumentComponent } from '../dialog-preview-document/dialog-preview-document.component';

@Component({
	selector: 'app-bill-list',
	templateUrl: './bill-list.component.html',
	styleUrls: ['./bill-list.component.scss']
})

export class BillListComponent implements OnInit {
	@Input() isLender: boolean;
	@Input() loanId: string;

	billColumns: string[] = [
		'DateCreated',
		'SubTotal',
		'Total',
		'Status',
		'viewFile'
	];

  	billDataSource: MatTableDataSource<billDTO>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

  	constructor(private subscriptionpaymentService: SubscriptionpaymentService,
		private alertGlobalService: AlertGlobalService,
		private dialog: MatDialog,
		private authService: AuthenticationService) { }

	ngOnInit(): void {
		this.getBills();
		this.isLender = this.authService.currentUserModeLenderValue;
	}

	getBills(): void {
		this.subscriptionpaymentService.getBills().subscribe((data) => {
			this.billDataSource = new MatTableDataSource(data);
			if (data) {
				this.billDataSource.paginator = this.paginator;
				this.billDataSource.sort = this.sort;
				}
			}
		);
	}

	onDeletePayment(loanPaymentId: string, amount: number) {}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.billDataSource.filter = filterValue.trim().toLowerCase();
	}

	openGenerateBill() {
		const dialogRef = this.dialog.open(DialogGenerateBillComponent, {
			autoFocus: false,
			width: '80%'
		  });
		let instance = dialogRef.componentInstance;

		dialogRef.afterClosed().subscribe((result) => {
			this.getBills();
		});
	}

	openLink(orderId: string): void {
		const dialogRef = this.dialog.open(HtmlViewerComponent, {
			autoFocus: false,
			width: '100vh',
			disableClose: true
		  });
		let instance = dialogRef.componentInstance;
		instance.documentGeneratorType = 2;
		instance.orderId = orderId;
	}

	sendBillByEmail(id: string, email: string) {
		this.subscriptionpaymentService.sendCFDIsByEmail(id, email).subscribe((data) => {
			this.alertGlobalService.showAlertSuccess("Correo enviado satistactoriamente");
		});
	}

	cancelBill(billId: string, facturamaId: string) {
		this.subscriptionpaymentService.cancelCFDIs(billId, facturamaId).subscribe((data) => {
			this.alertGlobalService.showAlertSuccess(data.responseCancelbill);
			this.getBills();
		});
	}

	previewFile(url: string, fileName: string) {
		const dialogRef = this.dialog.open(DialogPreviewDocumentComponent, {
			autoFocus: false,
			width: '80%'
		});
		
		let instance = dialogRef.componentInstance;
		instance.url = url;
		instance.extensionFile = /[^.]+$/.exec(fileName)[0];
	}
}
