<div class="container-fluid">
    <h4>Términos y Condiciones de Uso del Software</h4>
    <div class="card">
        <div class="card-body">
            <p class="title">
                TÉRMINOS Y CONDICIONES DE USO DEL SOFTWARE A TRAVÉS DE PLATAFORMA DIGITAL O APLICACIÓN WEB.
            </p>
            <p>
                Los Términos y Condiciones de uso que a continuación se presentan (los “Términos”) constituyen el acuerdo íntegro entre Apoyo Soluciones Productivas, S.A.P.I. de C.V., sus filiales y/o subsidiarias, y/o sus partes relacionadas (“A-Lender”), quien es legítimo propietario o autorizado para comercializar y usar el Software “A-Lender” para su acceso vía web o a través de la aplicación móvil (la “Plataforma”) con domicilio en Av. Lázaro Cárdenas No. 2400 Pte., Oficina C31-4, Col. Residencial San Agustín, San Pedro Garza García, N.L., CP 66269 (el “Domicilio”), dirección electrónica www.a-lender.com (el “Sitio”) y con correo electrónico de contacto hola@a-lender.com (el “Correo Electrónico”); y la persona física y/o moral que acceda a ella. La utilización de la Plataforma, por parte de cualquier persona, le atribuye la calidad de usuario (el “Usuario”) y ello implica su adhesión plena e incondicional a estos Términos.
            </p>
            <p>
                <b>1. OBJETO</b>
                A-Lender pone la Plataforma a disposición del Usuario, una herramienta tecnológica cuya finalidad principal es administrar tus préstamos, y cuya información es proporcionada por A-Lender, o por personas vinculadas de manera directa o indirecta con él (los “<u>Contenidos</u>”). El Usuario reconoce que la Plataforma tiene como objetivo la prestación de servicios de administración de préstamos o cuentas por cobrar entre, familia, clientes, empleados o amigos, misma que está alojada en la nube (nube significa espacio de procesamiento y almacenamiento de datos y aplicaciones en servidores físicos que están en un Centro de Datos), por medio del uso de dispositivos digitales electrónicos, tales como: computadora, teléfono inteligente, tablet, etc. (los “<u>Servicios</u>”). No obstante lo anterior, A-Lender no garantiza la resolución efectiva de todas las necesidades del Usuario.
            </p>
            <p>
                Los Servicios se prestan en la modalidad de Software as a Service (el “<u>SaaS</u>”), que implica que el Usuario recibe los servicios en la nube (como se define más adelante), a través de planes mensuales, con cargo directo y recurrente las tarjetas de crédito, débito o determinados motores de pago, según haya elegido el Usuario al registrarse en la Plataforma. Los Servicios que contrata el Usuario, según eligió al registrarse y darse de alta en la Plataforma, contienen los siguientes componentes:
            </p>
            <p>
                La Plataforma de A-Lender es una forma de administración de préstamos entre familiares, amigos, clientes y empleados. Es una herramienta para que el Usuario gestione todo el ciclo del préstamo: cotizar, integrar un expediente del cliente con datos y documentos, evaluar y autorizar la solicitud, generar documentación legal como contrato y pagaré, registrar pagos, consultar estados de cuenta, enviar automáticamente recordatorios y/o requerimientos de pago y descargar reportes de la situación de su portafolio
            </p>

            <p>
                El Usuario acepta cumplir con todos y cada uno de los procedimientos que las leyes aplicables señalen, respecto a la adquisición de servicios en línea o digitales; y, por su parte, A-Lender se compromete a respetar y hacer cumplir los derechos del Usuario y dar un correcto uso a los datos que se recaben con dicho propósito, conforme al Aviso de Privacidad que se encuentra en la Plataforma.
            </p>
            <p>
                <b>2. USO CONTRACTUAL DE LA PLATAFORMA.</b>
                En virtud de los presentes Términos, A-Lender autoriza y otorga al Usuario el uso y goce de 1 (una) licencia de uso de la Plataforma para uso personal, misma que implica el registro y acceso de la misma. Dicho otorgamiento se realiza bajo la modalidad “as is”, temporal, de manera no exclusiva, no comercializable y no sublicenciable, para la adquisición de los Servicios.
            </p>
            <p>
                Los Servicios que el Usuario podrá adquirir a través de la Plataforma (y que eligió al momento de crear su usuario y contraseña), están dispuestos en planes que contienen una oferta de servicios adecuada a las necesidades del Usuario, mismos que se describen en adelante:
            </p>
            <ul>
                a.	<u>Prueba Gratis.</u> Esta prueba incluye, el registro y apertura de una cuenta en A-Lender en donde tendrás acceso al simulador de préstamos, panel de control y soporte y 5 (cinco) operación activa y hasta por un máximo de 30 días naturales.<br />
                b.	<u>Plan Básico.</u> Este plan incluye, el registro y apertura de una cuenta en A-Lender en donde tendrás acceso al simulador de préstamos, panel de control y soporte y 5 (cinco) operaciones activas.<br />
                c.	<u>Plan Estándar.</u> Este plan incluye, el registro y apertura de una cuenta en A-Lender en donde tendrás acceso al simulador de préstamos, panel de control y soporte y 10 (diez)) operaciones activas.<br />
                d.	<u>Plan Premium.</u> Este plan incluye, el registro y apertura de una cuenta en A-Lender en donde tendrás acceso al simulador de préstamos, panel de control y soporte y 50 (cincuenta)) operaciones activas.<br />
                e.	<u>Plan Profesional.</u> Este plan incluye, el registro y apertura de una cuenta en A-Lender en donde tendrás acceso al simulador de préstamos, panel de control y soporte y 100 (cien) operaciones activas.
            </ul>
            <p>
                <u>Contratación Electrónica.</u> El Usuario reconoce que el uso y acceso a la Plataforma incluye la capacidad de celebrar contratos para adquirir los Servicios y/o a realizar transacciones electrónicamente. Por lo anterior, el Usuario reconoce que los envíos electrónicos constituyen su aceptación e intención de obligarse y pagar, en tiempo y forma, por tales servicios y transacciones. Dicha obligación se considerará aplicable a todos los registros relacionados a todas las transacciones que se realicen a través de la Plataforma y los Servicios, incluyendo los avisos de cancelación, políticas de uso, contratos y aplicaciones.
            </p>
            <p>
                <u>Hospedaje.</u> A-Lender hospedará las Licencias en la nube de su elección (nube significa espacio de procesamiento y almacenamiento de datos y aplicaciones en servidores físicos que están en un Centro de Datos de algún tercero). El hospedaje tiene una disponibilidad adecuada. No obstante lo anterior, A-Lender no será responsable de cualquier caída, ausencia total o parcial de disponibilidad, ni de pérdida total o parcial de datos.
            </p>
            <p>
                <u>Veracidad de datos.</u> El Usuario reconoce que A-Lender no realizará investigación alguna para validar la exactitud y veracidad de los datos provistos por el Usuario, por lo que en caso que presentan omisiones, inexactitudes o errores, libera de cualquier responsabilidad a A-Lender, respecto de cualquier daño o perjuicio que dichos actos pudieran causarle.
            </p>
            <p>
                <u>Vigencia.</u> Queda entendido que la suscripción a los Servicios tendrá una vigencia indefinida, hasta en tanto no exista una instrucción de baja, bloqueo o cancelación por parte del Usuario, la cual deberá ser solicitada por escrito al Correo Electrónico. No obstante, y sin perjuicio de lo anterior, los cargos correspondientes a cada Servicio se generarán de conformidad a la temporalidad especificada para cada uno, en tanto el mismo no sea cancelado en los términos descritos en el presente párrafo. Si el Usuario cancela los Servicios, deberá pagar los servicios que se hayan prestado hasta la fecha de terminación efectiva.
            </p>
            <p>
                <u>Terminación.</u> A-Lender, a su absoluta discreción, podrán dar por terminados los Servicios, en cualquier momento, bastando para ello un aviso simple por escrito.
            </p>
            <p>
                <u>Precio.</u> El Usuario pagará a A-Lender por los Servicios elegidos al ingresar a la Plataforma dependiendo del tipo de Usuario y plan, será gratuito. El precio de los Servicio son los que en adelante se describen, en el entendido de que los mismos podrán variar de tiempo en tiempo, y a discreción única de A-Lender, por lo que A-Lender no será responsable de cualquier menoscabo que dicha alteración de precio pudiera causar al Usuario. El precio de los Servicios, según el Plan elegido, es:
            </p>
            <ul>
                a.	<u>Prueba Gratis.</u> a un precio de $0.00 M.N. (cero pesos 00/100 moneda nacional) más el IVA por 30 días. El precio anterior puede cambiar en cualquier momento sin necesidad de aviso previo.<br />
                b.	<u>Plan Básico.</u> a un precio de $99.00 M.N. (noventa y nueve pesos 00/100 moneda nacional) más el IVA al mes. El precio anterior puede cambiar en cualquier momento sin necesidad de aviso previo.<br />
                c.	<u>Plan Estándar.</u> a un precio de $149.00 M.N. (ciento cuarenta y nueve pesos 00/100 moneda nacional) más el IVA al mes. El precio anterior puede cambiar en cualquier momento sin necesidad de aviso previo.<br />
                d.	<u>Plan Premium.</u> a un precio de $624.00 M.N. (seiscientos cincuenta y cuatro pesos 00/100 moneda nacional) más el IVA al mes. El precio anterior puede cambiar en cualquier momento sin necesidad de aviso previo.<br />
                e.	<u>Plan Profesional.</u> a un precio de $999.00 M.N. (novecientos noventa y nueve pesos 00/100 moneda nacional) más el IVA al mes. El precio anterior puede cambiar en cualquier momento sin necesidad de aviso previo.
            </ul>
            <p>
                <u>Métodos de Pago.</u> El Usuario se obliga a realizar el pago de los Servicios en pesos mexicanos. El cobro de los Servicios, incluyendo cualquier impuesto aplicable, se realizará a través del motor de pagos de Grupo Conektame S.A. de C.V. (CONEKTA), mediante cargo mensual recurrente. A-Lender, para comodidad del Usuario, ofrece diferentes modalidades de pago que deberá elegir al crear su Usuario y contraseña, siendo estos: (i) cargo directo a tarjeta de crédito; o (ii) cargo directo a tarjeta de débito; o (iii) mediante el motor de pagos de Grupo Conektame S.A. de C.V. (CONEKTA). Los cargos aquí mencionados se realizarán de manera mensual recurrente, hasta existir un aviso por escrito del Usuario, donde solicite el cambio de forma de pago o, en su caso, la cancelación de los Servicios; situación en la cual el Usuario deberá cumplir con su obligación de pago aplicable al periodo mensual donde se haya manifestado el deseo de cancelación.
            </p>
            <p>
                El Usuario, como único responsable del pago oportuno de los Servicios, se obliga a proporcionar datos reales, válidos y vigentes de la tarjeta de crédito, tarjeta de débito o motor de pagos, donde se realizará el cargo mensual recurrente. De igual manera, el Usuario declara y garantiza en este acto que los recursos económicos que serán invertidos para el pago de todas y cada una de las obligaciones conferidas en este documento, provienen de fuentes y/o actividades lícitas.  En caso de que A-Lender no puedan realizar el mencionado cargo al motor de pago elegido por el Usuario, A-Lender se reserva el derecho de revocar o restringir el acceso del Usuario a los Servicios hasta en tanto el pago sea realizado en su totalidad.
            </p>
            <p>
                Cualquier cargo operativo, o tasa establecidos por los servicios ofrecidos a través de servidores o portales de terceros (motores de pago o bancos), están completamente regulados por términos y condiciones dispuestos por dichos terceros o por las leyes aplicables, por lo cual el Usuario deslinda en este acto a A-Lender de cualquier responsabilidad respecto a la forma, tiempo y cantidad en que sean efectuados los cobros, aun cuando dicho cobro sea considerado excedido, indebido o que viole algún derecho del Usuario. De esta forma, el Usuario se obliga a mantener en paz y a salvo en todo momento a A-Lender de cualquier proceso judicial que se llegare a entablar por razón del uso de servidores o portales de terceros.
            </p>
            <p>
                Cualquier cambio en la forma de pago del Usuario, deberá ser realizado en línea. Dicho cambio podrá generar la interrupción temporal del acceso a los Servicios, mientras se realiza la verificación de la nueva información otorgada. El Usuario reconoce y acepta que A-Lender podrá usar los servicios de cobranza de terceros, con fines de cobro de cualquier adeudo pendiente de pago por razón de los Servicios, para lo cual, el Usuario se obliga a colaborar de buena fe para la liquidación total de los adeudos.
            </p>
            <p>
                <u>Facturación.</u> En caso de que el Usuario requiera comprobante fiscal, deberá solicitar el mismo por escrito al Correo Electrónico para que este sea generado. Es condición imprescindible para la emisión de dicho comprobante, que el Usuario compruebe fehacientemente el pago de los Servicios en cuestión. El Usuario acepta que A-Lender podrá contactarle periódicamente, vía correo electrónico a la dirección de correo electrónico asociada a su cuenta de registro, con avisos de facturación y otras comunicaciones relacionadas con los Servicios, ya sean de: i) promoción de productos propios o de terceros; ii) mejora en el servicio; iii) cambios en los Servicios, etc.
            </p>
            <p>
                <b>3. USO Y ACCESO A LA PLATAFORMA</b>
                Para acceder a los Servicios, es necesario ser mayor de edad y proporcionar la información requerida para crear una cuenta, de tal manera que es responsabilidad del Usuario mantener la información verídica, exacta, actualizada y disponible. La falta de actualización de su cuenta dará lugar a que no pueda acceder y utilizar los Servicios, así como la suspensión o cancelación de esta.
            </p>
            <p>
                El Usuario es el único responsable frente a A-Lender, y cualquier tercero, respecto de su conducta al acceder, consultar y proporcionar información en la Plataforma y de las consecuencias que se puedan derivar de una utilización, con fines o efectos ilícitos o contrarios al objeto de la Plataforma, su contenido, elaborado o no por A-Lender, publicado o no bajo su nombre de forma oficial; así como aquellas consecuencias que se puedan derivar de la utilización contraria al contenido de estos Términos que sea lesiva de los intereses o derechos de terceros, o que de cualquier forma pueda dañar, inutilizar o deteriorar la Plataforma e impedir el normal disfrute de otros usuarios.
            </p>
            <p>
                <b>4. USO DE OTROS PRODUCTOS Y SERVICIOS</b>
                Los componentes o las funciones de los Servicios, incluidos aquellos que implican la compra y descarga de productos o servicios adicionales, requieren un software diferente u otros acuerdos de licencia o términos de uso, por lo que el Usuario deberá leer, aceptar y obligarse a aquellos términos de uso establecidos, de manera independiente, como condición para poder utilizar estos componentes o características particulares del Servicio.
            </p>
            <p>
                <b>5. IMPRECISIONES DE LA PLATAFORMA</b>
                El Contenido de la Plataforma y/o de los Servicios provistos, pueden contener inexactitudes y/o errores tipográficos. A-Lender no garantiza la exactitud del Contenido y se reserva el derecho, a su entera discreción, de corregir cualquier error u omisión en cualquier parte de la Plataforma y/o los Servicios y a realizar cualquier cambio en las características, funcionalidad o Contenido en cualquier momento. A-Lender, así como cualquier persona relacionada y/o afiliada a A-Lender, incluyendo, sin limitar, directores, apoderados, representantes, administradores, empleados, accionistas y/o agentes, presentes o anteriores, o aliados, no serán responsables de errores u omisiones en los Contenidos de la Plataforma.
            </p>
            <p>
                <b>6. ESTANCIA EN LA PLATAFORMA</b>
                El Usuario es el único responsable frente a A-Lender, y cualquier tercero, respecto de su conducta al acceder, consultar y proporcionar información en la Plataforma y de las consecuencias que se puedan derivar de una utilización, con fines o efectos ilícitos o contrarios al objeto de la Plataforma, su contenido, elaborado o no por A-Lender, publicado o no bajo su nombre de forma oficial; así como aquellas consecuencias que se puedan derivar de la utilización contraria al contenido de estos Términos que sea lesiva de los intereses o derechos de terceros, o que de cualquier forma pueda dañar, inutilizar o deteriorar la Plataforma e impedir el normal disfrute de otros usuarios
            </p>
            <p>
                <b>7. RESPONSABILIDAD RESPECTO A LOS CONTENIDOS </b>
                <u>Uso correcto de los Contenidos.</u> El Usuario se compromete a:
            </p>
            <ul>
                a)	utilizar la Plataforma y sus Contenidos de acuerdo a las leyes aplicables y de orden público, absteniéndose de realizar acto que menoscabe, deteriore, inutilice o dañe la imagen y/o información divulgada por A-Lender o de alguna manera lesione derechos o intereses de terceras personas, vinculadas directa o indirectamente a éste;<br />
                b)	no copiar, difundir, modificar, reproducir, distribuir o utilizar de manera alguna con o sin fines de lucro los contenidos y los elementos utilizados en la Plataforma, a menos que se cuente con la autorización expresa y por escrito de A-Lender;<br />
                c)	no modificar o manipular las marcas, logotipos, avisos comerciales, nombres comerciales y signos distintivos en general de A-Lender, de la Plataforma o de las personas vinculadas con A-Lender (salvo que cuente con su autorización por escrito);<br />
                d)	suprimir, eludir o modificar los Contenidos y los elementos utilizados en la Plataforma, así como los dispositivos técnicos de protección, o cualquier mecanismo o procedimiento establecido en la Plataforma.
            </ul>
            <p>
                Queda excluida de los puntos anteriores, aquella información generada a través de la Plataforma para uso y manejo del Usuario, misma que podrá ser impresa y/o copiada para los intereses que más convengan al mismo. En caso de que el Usuario sea una persona moral, se apegará a lo dispuesto por el artículo 148, fracción IV de la Ley Federal del Derecho de Autor. El Usuario reconoce y acepta que el uso de la Plataforma y de los Contenidos, es bajo su exclusiva y estricta responsabilidad, por lo que A-Lender no será, en ningún momento y bajo ninguna circunstancia, responsable por cualquier desperfecto o problema que se presente en el equipo de cómputo (hardware) o programas de cómputo (software) que utilice el Usuario para acceder o navegar en cualquier parte de la Plataforma.
            </p>
            <p>
                A-Lender tiene derecho a realizar, durante intervalos temporales definidos, campañas promocionales para promover el registro de nuevos miembros en la Plataforma. A-Lender se reserva el derecho de modificar los términos y condiciones de los Servicios, así como de proceder a la exclusión de cualquiera de los mismos. A-Lender declara que todos los Contenidos, y los elementos utilizados en la Plataforma, se encuentran debidamente registrados y protegidos bajo las autoridades y leyes correspondientes en México. El Usuario se obliga a respetar todos los derechos contenidos en el Aviso de Derecho de Autor establecido en la Plataforma.
            </p>
            <p>
                <u>Plataforma y contenidos ajenos a la Plataforma y a los Contenidos de A-Lender.</u> A-Lender podrá hacer uso de su derecho de publicación de cualquier material informativo y/o de sitios o subsitios propiedad de terceros, vinculados o no a A-Lender, que considere de interés para los Usuarios. No obstante lo anterior, A-Lender se deslinda de toda responsabilidad, del acceso y/o uso que realice el Usuarios de la información ahí contenida y/o del uso, origen y destino de la información que se desprenda de ligas distintas (vínculo, hipervínculo, link). Toda publicación realizada dentro de la Plataforma, por parte de los Usuarios, no genera obligación de pago ante terceros por razón de promoción, publicación y/o manejo de información y/o imagen, a menos que se cuente con un contrato previamente firmado con A-Lender.
            </p>
            <p>
                <u>Negación y retiro de acceso a la Plataforma y los Contenidos.</u> A-Lender se reserva el derecho a negar o retirar el acceso a la Plataforma, o sus Contenidos, en cualquier momento, sin responsabilidad alguna para A-Lender y sin previo aviso al Usuario o usuarios que incumplan de manera total o parcial con las condiciones aquí establecidas y/o que realicen acciones o actos tendientes a:
            </p>
            <ul>
                1.	“asediar" o de otra manera acosar o molestar a otros Usuarios;<br />
                2.	hacerse pasar como representante o empleado de A-Lender, realizando declaraciones falsas o de otro modo erróneas de su vinculación con A-Lender;<br />
                3.	recopilar o almacenar datos personales de otros usuarios en relación con la conducta y las actividades prohibidas;<br />
                4.	falsificar encabezados o manipular identificadores de la Plataforma, con la finalidad de ocultar el origen de los Contenidos;<br />
                5.	cargar, publicar, enviar por correo electrónico, transmitir o proporcionar de otro modo, cualquier contenido respecto del cual no tenga derecho a transmitir, en virtud de los términos contenidos en la Ley Federal de Protección a la Propiedad Industrial (“<u>LFPPI”</u>), la Ley Federal del Derecho de Autor (“<u>LFDA”</u>), y la Ley Federal de Protección de Datos Personales en Posesión de Particulares (“<u>LFPDPPP”</u>) o de relaciones contractuales protegidos por convenios de confidencialidad y no divulgación;<br />
                6.	cargar, publicar, enviar por correo electrónico, transmitir o proporcionar de otro modo, materiales que contengan virus informáticos o cualquier otro código informático, archivos o programas diseñados para interrumpir, destruir o limitar la funcionalidad del software, hardware o de equipos de telecomunicaciones conectados a la Plataforma;<br />
                7.	hacer uso de la Plataforma de una manera que pudiera dañar, deshabilitar, recargar o alterar los servidores de A-Lender o las conexiones de redes;<br />
                8.	ignorar requisitos, procedimientos, políticas o normas de redes conectadas a la Plataforma que pudieran interferir con el uso y goce de la Plataforma por parte de cualquier tercero; y<br />
                9.	acceder de manera no autorizada a cuentas, sistemas informáticos o redes conectadas a los servidores de A-Lender, a través de ataques propios de piratas informáticos, el descifrado de contraseñas o cualquier otro método para obtener o tratar de obtener materiales o información con cualquier medio que no se ofrece intencionalmente a través de la Plataforma.
            </ul>
            <p>
                El Usuario acepta indemnizar y mantener en paz y a salvo a A-Lender y sus funcionarios, agentes, empleados, socios, proveedores y licenciantes frente a cualquier reclamo o demanda, así como a cubrir los honorarios razonables de abogados, que promueva cualquier tercero en contra de A-Lender a causa del contenido que el Usuario envíe, publique, transmita o proporcione de un modo distinto al previsto en la Plataforma. Lo anterior, sin perjuicio del derecho de A-Lender de realizar las acciones judiciales necesarias para reclamar los daños y perjuicios que dichas acciones por parte del Usuario pudieran causarle.
            </p>
            <p>
                <u>Responsabilidad respecto a los Contenidos.</u> A-Lender no asume responsabilidad alguna derivada, de manera enunciativa más no limitativa de: (i) la utilización que el Usuario pueda hacer de los materiales de esta Plataforma, o de los Contenidos, o de los sitios web de enlace, ya sean prohibidos o permitidos, en infracción de los derechos de propiedad intelectual y/o industrial de contenidos de la web o de terceros; (ii) los eventuales daños y perjuicios al Usuario causados por un funcionamiento normal o anormal de las herramientas de búsqueda, de la organización o la localización de los Contenidos y/o acceso a la Plataforma y, en general, de los errores o problemas que se generen en el desarrollo o instrumentación de los elementos técnicos que la Plataforma facilite al Usuario; (iii) los contenidos de aquellas páginas a las que el Usuario pueda acceder desde enlaces incluidos en la Plataforma, ya sean autorizados o no; (iv) los actos u omisiones de terceros, independientemente de la relación que dichos terceros pudieran tener con A-Lender; (v) el acceso de menores de edad a los Contenidos, así como el envío de información personal que estos pudieran realizar; (vi) las comunicaciones o diálogos en el transcurso de los debates, foros, chats y comunidades virtuales que se organicen a través de o en torno a la Plataforma de enlace, ni responderá, por tanto, de los eventuales daños y perjuicios que sufra el Usuario a consecuencia de dichas comunicaciones y/o diálogos; etc.
            </p>
            <p>
                <u>Responsabilidad respecto a fallas tecnológicas.</u> A-Lender no será responsable en forma alguna, cuando se produzcan: (i) errores o retrasos en el acceso a la Plataforma a la hora de introducir los datos en el formulario de solicitud, la lentitud o imposibilidad de recepción por parte de los destinatarios de la confirmación de la solicitud o cualquier anomalía que pueda surgir cuando tales incidencias sean debidas a problemas en la red Internet, caso fortuito o fuerza mayor o cualquier otra contingencia imprevisible ajena a A-Lender; (ii) fallos o incidencias que pudieran producirse en las comunicaciones, ya sea borrándose o por transmisiones incompletas, de manera que no se garantiza que los servicios de la Plataforma estén constantemente operativos; (iii) de los errores o daños producidos a la Plataforma por un mal uso del servicio por parte del Usuario; (iv) de la no operatividad o problemas en la dirección de correo electrónico facilitada por el Usuario para el envío de la confirmación de la solicitud realizada. En todo caso, A-Lender se compromete a solucionar los problemas que puedan surgir y a ofrecer todo el apoyo necesario al Usuario para llegar a una solución rápida y satisfactoria de la incidencia. Asimismo, A-Lender tiene derecho a realizar durante intervalos temporales definidos, campañas promocionales para promover el registro de nuevos miembros en su servicio. A-Lender se reserva el derecho de modificar las condiciones de comercio electrónico, así como proceder a la exclusión de cualquiera de los términos allí contenidos.
            </p>
            <p>
                <b>8. DE LOS DATOS SOLICITADOS</b> 
                El Usuario reconoce y acepta que, para el cumplimiento del objetivo de la Plataforma, será necesario que A-Lender recabe datos personales y datos personales sensibles, a fin de incluirla en formatos, listados, bases de datos u otros medios físicos, digitales y/o electrónicos, para llevar a cabo el registro adecuado de los mismos y ofrecer los Servicios. A-Lender no se responsabiliza de las consecuencias que pudieran derivarse de la omisión, consciente o inconsciente, hecha por parte del Usuario al respecto.
            </p>
            <p>
                Por tal motivo, mediante la aceptación a los presentes Términos, el Usuario renuncia expresamente a presentar cualquier tipo de reclamación, demanda, juicio o procedimiento legal ante cualquier autoridad mexicana o extranjera en contra de A-Lender, así como cualquier persona relacionada y/o afiliada a A-Lender, incluyendo, sin limitar, directores, apoderados, representantes, administradores, empleados, accionistas y/o agentes, presentes o anteriores, por cualquier acto que se derive, o pudiere derivar, del uso de la Plataforma y de los Servicios, o de cualquier servicio derivado de dicho uso.
            </p>
            <p>
                <b>9. DATOS DE CARÁCTER PERSONAL</b>
                En cumplimiento a los términos previstos en la LFPDPPP, A-Lender, como responsable del tratamiento de sus datos personales, hace del conocimiento del Usuario que la información que el Usuario provea en esta Plataforma será tratada de conformidad con lo indicado en el Aviso de Privacidad contenido en la Plataforma. Para utilizar o gozar de algunos de los Contenidos, es necesario que el Usuario proporcione previamente a A-Lender ciertos datos de carácter personal (“<u>Datos Personales</u>”).
            </p>
            <p>
                Al acceder a la Plataforma, o a cualquiera de los Contenidos en que los Datos Personales son requeridos, el Usuario está autorizando a A-Lender a realizar análisis y estudios con base en ellos. El Usuario se obliga a proporcionar Datos Personales verdaderos y fidedignos. En caso de que el Usuario diera información falsa o confusa, A-Lender no asume responsabilidad alguna de los resultados que dichos actos ocasionen al Usuario, teniendo la facultad de negar el acceso a la Plataforma y sus Contenidos, sin perjuicio de que pueda requerir las indemnizaciones a que hubiere lugar.
            </p>
            <p>
                <b>10. MANEJO DE LOS DATOS E INFORMACIÓN</b>
                Toda la información que el Usuario proporcione, durante su acceso a la Plataforma, es de carácter estrictamente confidencial y será manipulada únicamente por personal interno de A-Lender. Tal y como se refiere en el Aviso de Privacidad, los Datos Personales de los Usuarios podrán encontrar como fin primario o secundario la promoción de servicios, por lo cual el Usuario, en este acto autoriza y manifiesta su aceptación en la utilización de sus datos para fines estadísticos, promocionales y de mercadotecnia, así como cualquier otro establecido en el presente documento o en el multicitado Aviso de Privacidad.
            </p>
            <p>
                <b>11. INDICADORES DE DATOS</b>
                La información que el Usuario provea en la Plataforma, real o histórica, se procesa y ordena, para que genere indicadores de datos, mismos que A-Lender podrá usar para tomar decisiones pertinentes a su negocio, siempre de manera estadística y no individualizada. El Usuario, en este acto, autoriza el acceso a A-Lender a la información proporcionada y generada en la Plataforma, en términos del presente documento y del Aviso de Privacidad.
            </p>
            <p>
                <b>12. DE LA INFORMACIÓN PROVISTA POR EL USUARIO</b>
                El Usuario reconoce y acuerda que A-Lender puede, durante la vigencia de los Servicios, depender de o usar datos, material u otra información entregada por el Usuario, y que para ello no requieren investigación independiente alguna o verificación, por lo que A-Lender estará facultado para basarse en la exactitud y plenitud de dicha información para prestar los Servicios. El Usuario es responsable de la información que comparten a terceros y a quiénes es compartida, por lo cual se deslinda en este acto de cualquier responsabilidad presente o futura a A-Lender.
            </p>
            <p>
                Asimismo, toda la información que el Usuario publiquen por cualquier otro medio pierde de manera inmediata y para siempre el carácter de secrecía y confidencialidad, liberando de toda responsabilidad A-Lender respecto a su uso y divulgación, sujeto a los términos y condiciones establecidos en el Aviso de Privacidad, cuando aplique.
            </p>
            <p>
                <b>13. RESPONSABILIDAD Y CALIDAD EN LA PRESTACIÓN DE LOS SERVICIOS</b>
                El Usuario reconoce que la Plataforma es una herramienta tecnológica que es un medio para que el Usuario pueda desarrollar una actividad específico, por lo cual acepta que A-Lender no garantiza la calidad, idoneidad y/o disponibilidad de los servicios brindados o solicitados a través del uso de la Plataforma y/o mediante su uso. El Usuario expresamente reconoce y acepta todos y cada uno de los riesgos derivados del uso de la Plataforma, liberando a A-Lender de cualquier responsabilidad presente o futura que se pudiera presentar. En este sentido, A-Lender no será responsable frente al Usuario, o cualquier persona relacionada a este, por cualquier tipo de daño o reclamo derivado de deficiencias en los Servicios, o por cualquier error, omisión y/o falsedad en la información proporcionada por el Usuario, ya sea a través de la Plataforma o cualquier otro medio.
            </p>
            <p>
                <b>14. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD</b>
                El Usuario es el único responsable del uso que haga a la Plataforma y su Contenido. El Usuario reconoce que la información de la Plataforma y de los Servicios se proporcionan “como están”, sin ninguna garantía expresa o implícita de comerciabilidad o de aptitud para un fin determinado. A-Lender no garantiza la precisión ni la integridad de la información, textos, gráficos, enlaces u otros elementos contenidos en la Plataforma o Contenido. A-Lender no garantiza la operación ininterrumpida o libre de todo error de la Plataforma y/o su Contenido. Puesto que toda la información referida en la Plataforma y su Contenido se encuentra en la nube, A-Lender no controla ni garantiza la ausencia de virus en los Contenidos, ni la ausencia de otros elementos en los Contenidos que puedan producir alteraciones en el sistema informático del Usuario (software y/o hardware) o en los documentos electrónicos almacenados en su sistema informático.
            </p>
            <p>
                Todo material descargado u obtenido de un modo distinto al previsto en la Plataforma, será bajo responsabilidad y riesgo único del Usuario, respecto de los daños que pudiera causar en el sistema informático del dispositivo a través del cual realice su conexión y/o la pérdida de datos que derive de la descarga de ese material. En ningún caso, ni A-Lender ni sus proveedores serán responsables de daño alguno derivado del uso de la Plataforma o Contenido, o de no poder usarlos (EN PARTICULAR, SIN LIMITACIÓN ALGUNA, DE LOS DAÑOS DIRECTOS O INDIRECTOS, MORALES, INCIDENTALES, EXCESIVOS, REMOTOS Y/O EVENTUALES, PERJUICIOS, LUCRO CESANTE, INTERRUPCIÓN DE LA ACTIVIDAD COMERCIAL O PÉRDIDA DE INFORMACIÓN O DATOS Y/O INFRACCIONES DE SEGURIDAD), aun cuando se hubiera advertido a A-Lender de dicha posibilidad.
            </p>
            <p>
                <b>15. USO DE COOKIES</b>
                A-Lender informa al Usuario que, mediante el uso de cookies y tecnologías similares, busca: i) garantizar la mejor experiencia posible en la Plataforma; y ii) proporcionar al Usuario información sobre sus preferencias de servicios y de mercadeo, ayudándolo así a obtener la información adecuada. En caso de que el Usuario requiera de mayor información respecto al uso de cookies y tecnologías similares, A-Lender pone a su disposición la Política de Uso de Cookies.
            </p>
            <p>
                <b>16. COMPATIBILIDAD DE LOS DISPOSITIVOS ELECTRÓNICOS</b>
                El Usuario será responsable de obtener los dispositivos o hardware que sean compatibles con la Plataforma y los Servicios, toda vez que A-Lender no garantiza que estos funcionen correctamente en cualquier dispositivo. De igual manera, el Usuario acepta no utilizar dispositivos, software o cualquier otro medio tendiente a interferir tanto en las actividades y/u operaciones de los Servicios o en la Plataforma o en las bases de datos y/o información que se contenga en el mismo.
            </p>
            <p>
                <b>17. MANTENIMIENTO DE LA PLATAFORMA</b>
                Para llevar a cabo trabajos de mantenimiento, A-Lender se reserva el derecho de suspender el acceso y/o modificar el Contenido, así como a eliminar o deshabilitar el acceso a la Plataforma o a los Servicios, sin previo aviso. El acceso a la Plataforma y los Servicios depende de la disponibilidad de la red que tenga el Usuario, por lo que A-Lender no será responsable por cualquier imposibilidad de acceder a la misma, derivada de circunstancias que se encuentren fuera de control de A-Lender, así como por caso fortuito o de fuerza mayor. A-Lender, cuando lo considere necesario para el correcto funcionamiento de la Plataforma, podrá realizará los parches, actualizaciones, correcciones de “bugs” y mejoras menores a la Plataforma.
            </p>
            <p>
                <b>18. SOPORTE</b>
                A-Lender ofrece al Usuario el servicio de soporte técnico y orientación básica para la utilización de las herramientas y las funcionalidades de la Plataforma, pudiendo ser por vía Chat en Línea, correo electrónico, o cualquier otro medio que A-Lender considere conveniente y factible, en los horarios indefinidos que de igual forma designe para tal efecto, mediante previo aviso. Este servicio no tendrá ningún costo adicional. Asimismo, el Usuario que hubiere solicitado el Soporte, acepta y autoriza a A-Lender para tener acceso pleno a toda la información proporcionada en la Plataforma, sin ninguna limitación. En este sentido y en beneficio del Usuario, A-Lender se obliga a guardar plena secrecía y confidencialidad, respecto a la información a la que tenga acceso.
            </p>
            <p>
                <b>19. PROPIEDAD INDUSTRIAL Y DERECHO DE AUTOR</b>
                A-Lender autoriza al Usuario a utilizar la Plataforma, exclusivamente bajo los términos aquí expresados, sin que ello implique que concede al Usuario licencia o autorización alguna, o algún tipo de derecho distinto al antes mencionado, respecto de la Propiedad Industrial y Derecho de Autor de A-Lender,  entendiéndose como ello: todas las marcas registradas y/o usadas en México o en el extranjero por A-Lender; todo derecho sobre invenciones (patentadas o no), diseños industriales, modelos de utilidad, información confidencial, nombres comerciales, secretos industriales, avisos comerciales, reservas de derechos, nombres de dominio; así como todo tipo de derechos patrimoniales sobre obras y creaciones protegidas por derechos de autor y demás formas de propiedad industrial o intelectual reconocida o que lleguen a reconocer las leyes correspondientes.
            </p>
            <p>
                El Usuario reconoce y acepta que A-Lender es legítimo propietario, o tiene los derechos necesarios, sobre la Plataforma, incluidos los nombres comerciales de A-Lender, marcas comerciales, marcas de servicio, logotipos, nombres de dominio y otras características distintivas de la marca contenidas en ellos (las “<u>Marcas Registradas de A-Lender</u>”), independientemente de que esos derechos estén registrados o no, y de cualquier lugar del mundo en el que puedan existir esos derechos, y que están protegidos por las leyes y tratados internacionales sobre propiedad industrial y derecho de autor. Por lo anterior, el Usuario acepta que las Marcas Registradas de A-Lender no podrán ser objeto de copia, reproducción, modificación, publicación, carga, envío, transmisión o distribución en modo alguno. Salvo indicación expresa en contrario en este documento, A-Lender no concede al Usuario ningún derecho expreso ni implícito en virtud de patentes, derecho de autor, marcas comerciales o información de secretos industriales. El Usuario reconoce y conviene que la Plataforma, así como todos los diseños del mismo, son y, serán en todo momento, propiedad de A-Lender.
            </p>
            <p>
                <u>Retroalimentación.</u> En caso de que el Usuario proporcione algún comentario a A-Lender respecto de la funcionalidad y el rendimiento de la Plataforma (incluida la identificación de posibles errores y mejoras), en este acto, el Usuario autoriza a A-Lender para que haga uso, sin restricción, de todos los derechos, títulos e intereses sobre los comentarios expresados. Lo anterior, sin que ello se considere como un derecho moral del Usuario para requerir participación o retribución monetaria alguna, o restricción en el uso de dichos comentarios para su explotación por parte de A-Lender.
            </p>
            <p>
                <b>20. OTRAS DISPOSICIONES</b>
                El Usuario acepta que una versión impresa de los presentes Términos, y de cualquier comunicación enviada y/o recibida en forma electrónica, será admisible como medio probatorio en cualquier procedimiento judicial y/o administrativo.
            </p>
            <p>
                <b>21. MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES DE USO DE LA PLATAFORMA</b>
                A-Lender se reserva el derecho de, en cualquier momento, modificar y/o renovar unilateralmente y sin previo aviso los términos y condiciones de uso de la Plataforma, con la obligación de publicar un mensaje en la Plataforma que contenga un aviso al Usuario de que han sido realizadas ciertas modificaciones a los Términos. Será derecho exclusivo del Usuario, el aceptar o rechazar dichas modificaciones. En caso de que el Usuario no esté de acuerdo con las modificaciones hechas, podrá enviar solicitud de cancelación y terminación de su cuenta en la Plataforma, al Correo Electrónico. A-Lender se compromete a hacer efectiva la cancelación de la cuenta en un plazo no mayor a 30 (treinta) días naturales, a partir de la fecha de recepción de la solicitud del Usuario.
            </p>
            <p>
                Asimismo, A-Lender se reserva el derecho, en cualquier momento y sin previo aviso, de eliminar o deshabilitar el acceso del Usuario a la Plataforma. El Usuario siempre dispondrá de los Términos en la Plataforma de forma visible, y libremente accesible para cuantas consultas quiera realizar. En cualquier caso, la aceptación de estos Términos será un paso previo e indispensable a la adquisición de cualquier Servicio.
            </p>
            <p>
                <b>22. DIVISIBILIDAD</b>
                En caso de que cualquier término, condición o estipulación contenida en estos Términos se determine ineficaz, ilegal o sin efecto, el mismo podrá ser excluido del cuerpo del presente y el restante continuará en vigor y efecto en forma tan amplia como en derecho proceda.
            </p>
            <p>
                <b>23. ACTUALIZACIONES</b>
                A-Lender podrá revisar y actualizar, en cualquier momento, estos Términos, manteniendo en todo momento el acceso libre a todo usuario que desee conocerlo. A-Lender se reserva el derecho de modificar, en cualquier momento, la presentación y configuración de la Plataforma, así como estos Términos. Por ello, A-Lender recomienda al Usuario dar lectura atenta cada vez que acceda a la Plataforma. No obstante lo anterior, el Usuario siempre dispondrá de estos Términos en la Plataforma, de forma visible y accesible en cualquier momento. Algunas cláusulas de estos Términos pueden estar supeditadas a términos y condiciones designados expresamente y que se encuentren en la Plataforma o en determinados sitios web.
            </p>
            <p>
                <b>24. DERECHOS.</b>
                Cualquier derecho que no se haya conferido expresamente en este documento, se entiende reservado a A-Lender.
            </p>
            <p>
                <b>25. LEY Y JURISDICCIÓN APLICABLE</b>
                En todo lo relacionado con la interpretación y cumplimiento de lo aquí dispuesto, las Partes aceptan someterse a la legislación federal de México y a la jurisdicción de los tribunales competentes en la Ciudad de Monterrey, Nuevo León, México; renunciando a cualquier otra jurisdicción que, por razón de sus domicilios presentes o futuros, pudiese corresponderles.
            </p>
            <p>
                <b>26. FORMA DIGITAL, ELECTRÓNICA O EN LÍNEA</b>
                La Partes acuerdan que la forma para perfeccionar el acuerdo de voluntades entre ellas es el de formato Digital, Electrónico o en Línea, en donde bastará manifestar su voluntad por medio de la aceptación de los presentes Términos, así como proporcionar los datos personales e información bancaria en la Plataforma o en las distintas aplicaciones de los licenciantes, sin requerir estampar la firma en documento alguno.
            </p>
            <p>
                <b>27. ACEPTACIÓN DE LOS TÉRMINOS</b>
                El Usuario reconoce que, mediante el acceso, suscripción y uso de la Plataforma, los Servicios y/o Contenidos o derivados, manifiesta su aceptación plena y sin reservas y, por tanto, su adhesión a la versión de los Términos publicada en el momento en que acceda a la Plataforma, en términos de lo establecido por los artículos 1803 y 1834 Bis del Código Civil Federal, 80, 81, 89 y demás relativos y aplicables del Código de Comercio y la legislación aplicable para México. Es responsabilidad única y exclusiva del Usuario, leer previamente estos Términos y sus modificaciones correspondientes, cada vez que accede a la Plataforma. Si en cualquier momento, el Usuario no estuviera de acuerdo, total o parcialmente con los presentes Términos, deberá abstenerse inmediatamente de acceder y usar la Plataforma y los Servicios provistos. Por lo anterior, con la aceptación de los presentes Términos, el Usuario consiente expresamente sujetarse a los mismos, celebrando así un acuerdo de uso de Plataforma con A-Lender, por lo que manifiesta haber leído el contenido de todas y cada una de las disposiciones y ratifica su contenido.
            </p>
            <p>
                <b>28. ACUERDO TOTAL</b>
                
                El Usuario reconoce y acepta que A-Lender puso a su disposición toda la información necesaria para entender el alcance y características de la Plataforma y los Servicios. De igual forma, manifiesta que, previo al acceso a la Plataforma, analizó las características de esta y, por consiguiente, está de acuerdo con ella.
            </p>
            <p>
                Fecha de primera emisión: 1 de enero de 2022.
                Fecha de última modificación: 14 de octubre de 2022.
            </p>
        </div>
    </div>
</div>