import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HtmlViewerComponent } from '../../views/html-viewer/html-viewer.component';

@Component({
  selector: 'app-document-generator-list',
  templateUrl: './document-generator-list.component.html',
  styleUrls: ['./document-generator-list.component.scss']
})
export class DocumentGeneratorListComponent implements OnInit {

  @Input() loanRequestId: string;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialogHtmlViewer(documentGeneratorType: number): void {
    const dialogRef = this.dialog.open(HtmlViewerComponent, {
      autoFocus: false,
      width: '80vh'
    });
    let instance = dialogRef.componentInstance;
    instance.documentGeneratorType = documentGeneratorType;
    instance.loanRequestId = this.loanRequestId;
  }

}
