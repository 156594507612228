import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogTutorialComponent } from 'src/app/components/dialog-tutorial/dialog-tutorial.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  isLender = false;
  myTxt = '';
  opened = false;
  borrowerSwitchActive: boolean;
  blogId = '';
  activeTab = 0;
  panelOpenState = false;

  constructor(private dialog: MatDialog,
              private authService: AuthenticationService,) { }

  ngOnInit(): void {
    this.isLender = this.authService.currentUserModeLenderValue;
    this.borrowerSwitchActive = false;

    $('button').click(() => {});
  }

  openDialogTutorial() {
		this.dialog.open(DialogTutorialComponent, {
			panelClass: ['md:w-3/5', 'w-full'],
  			height: '90%',
  			width: '90%',
		    data: {
				title: '',
				message: '',
				accept: true
		    }
		})
		.afterClosed()
		.subscribe((confirmado: Boolean) => {
		});
	}
}