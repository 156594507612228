import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WarrantyService {

  warrantyList: KeyValue<number, string>[] = [
    { key: 1, value: "Sin garantía" },
    { key: 2, value: "Aval" },
    { key: 3, value: "Obligado solidario" },
    { key: 4, value: "Hipotecaria" },
    { key: 5, value: "Prendaria" },
    { key: 6, value: "Garantía Líquida" },
    { key: 7, value: "Otra" }
  ]; 
  
  private warrantyList$ = new BehaviorSubject<KeyValue<number, string>[]>(this.warrantyList);

  constructor() { }

  getList() {
    return this.warrantyList$.asObservable();
  }
}
