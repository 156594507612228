import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoanService } from 'src/app/services/loan/loan.service';

export interface DialogData {
}

@Component({
	selector: 'app-dialog-show-conditions-external-services.',
	templateUrl: './dialog-show-conditions-external-services.component.html',
	styleUrls: ['./dialog-show-conditions-external-services.component.scss']
})

export class DialogShowConditionsExternalServices implements OnInit, AfterViewInit {
	serviceName: string;
	amountCallPf: number = 0;
	amountVisitPf: number = 0;
	amountCallPm: number = 0;
	amountVisitPm: number = 0;
	amountVisiVerificationtPf: number;
	amountVisiVerificationtPm: number;
	amountCallVerificationtPf: number;
	amountCallVerificationtPm: number;

	kobraCoverage = [
		{
		  "estado": "Aguascalientes",
		  "municipio": ["Aguascalientes"]
		},
		{
		  "estado": "Baja California",
		  "municipio": ["Tijuana", "Mexicali"]
		},
		{
		  "estado": "Ciudad de México",
		  "municipio": [
			"Álvaro Obregón",
			"Azcapotzalco",
			"Benito Juárez",
			"Coyoacán",
			"Cuajimalpa de Morelos",
			"Cuauhtémoc",
			"Gustavo A. Madero",
			"Iztacalco",
			"Iztapalapa",
			"La Magdalena Contreras",
			"Miguel Hidalgo",
			"Milpa Alta",
			"Tláhuac",
			"Tlalpan",
			"Venustiano Carranza",
			"Xochimilco"
		  ]
		},
		{
		  "estado": "Chihuahua",
		  "municipio": ["Ciudad Juárez"]
		},
		{
		  "estado": "Coahuila",
		  "municipio": ["Torreón"]
		},
		{
		  "estado": "Durango",
		  "municipio": ["Durango", "Lerdo", "Gómez Palacio"]
		},
		{
		  "estado": "Estado de México",
		  "municipio": [
			"Atizapán de Zaragoza",
			"Chalco",
			"Chicoloapan",
			"Chimalhuacán",
			"Nezahualcóyotl",
			"Coacalco de Berriozábal",
			"Ecatepec de Morelos",
			"Ixtapaluca",
			"La Paz",
			"Lerma",
			"Metepec",
			"Naucalpan de Juárez",
			"Tecámac",
			"Texcoco",
			"Tlalnepantla de Baz",
			"Toluca",
			"Tultepec",
			"Tultitlán",
			"Valle de Chalco Solidaridad",
			"Zinacantepec"
		  ]
		},
		{
		  "estado": "Guanajuato",
		  "municipio": [
			"Apaseo el Grande",
			"Celaya",
			"Dolores",
			"Irapuato",
			"León",
			"Salamanca",
			"Silao"
		  ]
		},
		{
		  "estado": "Jalisco",
		  "municipio": [
			"Guadalajara",
			"El Salto",
			"San Pedro Tlaquepaque",
			"Tlajomulco de Zúñiga",
			"Tonalá",
			"Zapopan"
		  ]
		},
		{
		  "estado": "Michoacán",
		  "municipio": ["Acuitzio", "Madero", "Morelia", "Tarímbaro"]
		},
		{
		  "estado": "Morelos",
		  "municipio": [
			"Cuernavaca",
			"Emiliano Zapata",
			"Jiutepec",
			"Jojutla",
			"Temixco",
			"Yautepec",
			"Zacatepec"
		  ]
		},
		{
		  "estado": "Nuevo León",
		  "municipio": [
			"Apodaca",
			"García",
			"General Escobedo",
			"Guadalupe",
			"Monterrey",
			"San Nicolás de los Garza",
			"San Pedro Garza García",
			"Santa Catarina"
		  ]
		},
		{
		  "estado": "Puebla",
		  "municipio": [
			"Cuautlancingo",
			"Puebla",
			"Ajalpan",
			"Atlixco",
			"Amozoc",
			"Huejotzingo",
			"Tehuacán",
			"San Pedro Cholula"
		  ]
		},
		{
		  "estado": "Querétaro",
		  "municipio": ["Corregidora", "El Marqués", "Querétaro", "San Juan del Río"]
		},
		{
		  "estado": "Quintana Roo",
		  "municipio": ["Benito Juárez", "Felipe Carrillo Puerto", "José María Morelos"]
		},
		{
		  "estado": "San Luis Potosí",
		  "municipio": ["San Luis Potosí", "Soledad de Graciano Sánchez"]
		},
		{
		  "estado": "Sinaloa",
		  "municipio": ["Culiacán", "Mazatlán"]
		},
		{
		  "estado": "Tamaulipas",
		  "municipio": ["Altamira", "Ciudad Madero", "Tampico"]
		},
		{
		  "estado": "Tabasco",
		  "municipio": ["Cárdenas", "Centro"]
		},
		{
		  "estado": "Veracruz",
		  "municipio": [
			"Acultzingo",
			"Ciudad Mendoza",
			"Coatepec",
			"Coscomatepec",
			"Cuitláhuac",
			"Maltrata",
			"Nogales",
			"Orizaba",
			"Paso del Macho",
			"Potrero",
			"Poza Rica",
			"Veracruz",
			"Xalapa",
			"Yanga"
		  ]
		},
		{
		  "estado": "Nayarit",
		  "municipio": ["Tepic"]
		},
		{
		  "estado": "Yucatán",
		  "municipio": ["Mérida"]
		},
		{
		  "estado": "Zacatecas",
		  "municipio": ["Zacatecas", "Guadalupe"]
		}
	  ];


	constructor(
		public dialog: MatDialogRef<DialogShowConditionsExternalServices>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private loanService: LoanService) { }

	closeDialog(): void {
		this.dialog.close(false);
	}
	confirm(): void {
		this.dialog.close(true);
	}

	ngOnInit() {
		this.serviceName = this.data.serviceName;
		this.getExternalServices();
	}

	ngAfterViewInit(): void { }

	getExternalServices() {
		this.loanService.getExternalServicesLanding().subscribe((result) => {
			this.amountCallPf = result.find(x => x.typePerson ==  0 && x.serviceName == "Llamada de cobranza").amount;
			this.amountCallPm = result.find(x => x.typePerson ==  1 && x.serviceName == "Llamada de cobranza").amount;

			this.amountCallVerificationtPf = result.find(x => x.typePerson ==  0 && x.serviceName == "Llamada de verificación").amount;
			this.amountCallVerificationtPm = result.find(x => x.typePerson ==  1 && x.serviceName == "Llamada de verificación").amount;

			this.amountVisitPf = result.find(x => x.typePerson ==  0 && x.serviceName == 'Visita de cobranza').amount
			this.amountVisitPm = result.find(x => x.typePerson ==  1 && x.serviceName == 'Visita de cobranza').amount

			this.amountVisiVerificationtPf = result.find(x => x.typePerson ==  0 && x.serviceName == 'Visita de verificación').amount
			this.amountVisiVerificationtPm = result.find(x => x.typePerson ==  1 && x.serviceName == 'Visita de verificación').amount
		});
	}
}
