<mat-horizontal-stepper [selectedIndex]="steptIndex" class="bg-transparent" linear #stepperSignup
    labelPosition="bottom">
    <ng-template matStepperIcon="edit">
        <i class="bx bx-check"></i>
    </ng-template>
    <ng-template matStepperIcon="active">
        <i class="bx bx-check"></i>
    </ng-template>
    <mat-step [stepControl]="createAccountForm" [optional]="false" [editable]="false">
        <ng-template matStepLabel>Datos de contacto</ng-template>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card overflow-hidden">
                        <div class="bg-primary bg-soft-primary">
                            <div class="d-flex align-items-center">
                                <div class="col-8 col-sm-9 col-md-9">
                                    <div class="text-white p-4">
                                        <h5 class="text-white">Datos de contacto</h5>
                                        <p class="mb-0">Ingresa tus datos</p>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-3 col-md-3">
                                    <div class="avatar-md">
                                        <span class="avatar-title rounded-circle bg-light">
                                            <a href="/">
                                                <img src="assets/images/icons/c_wing.svg" height="34" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form class="p-2" [formGroup]="createAccountForm" (ngSubmit)="onSubmitCreateAccountForm()">
                                <div class="form-horizontal">
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label>Correo electrónico*</label>
                                            <input autocapitalize="off" autofocus="autofocus"
                                                class="form-control" formControlName="userName"
                                                placeholder="Escribe tu correo" type="email"
                                                [ngClass]="createAccountFormSubmitted && validateRequiredForm('userName') ? 'is-invalid' : ''"/>
                                            <app-input-errors-global fieldName="userName" class="mat-error">
                                            </app-input-errors-global>
                                            <mat-error
                                                *ngIf="createAccountFormSubmitted && f_createAccountForm['userName'].errors && f_createAccountForm['userName'].errors['required']">
                                                Este campo es requerido.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="createAccountFormSubmitted && f_createAccountForm['userName'].errors && f_createAccountForm['userName'].errors['email']">
                                                Ingresa un correo válido.
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-group"> <label>Contraseña*</label>
                                            <div> <i [ngClass]="getClass()" aria-hidden="true"
                                                    class="fas fa-eye input_icon" (click)="hide = !hide"></i> <input
                                                    class="form-control" formControlName="password"
                                                    placeholder="Escribe tu contraseña"
                                                    [type]="hide ? 'password' : 'text'"
                                                    [ngClass]="createAccountFormSubmitted && validateRequiredForm('password') ? 'is-invalid' : ''"/> </div>
                                            <app-input-errors-global fieldName="password" class="mat-error">
                                            </app-input-errors-global>
                                            <mat-error
                                                *ngIf="createAccountFormSubmitted && f_createAccountForm['password'].errors && f_createAccountForm['password'].errors['required']">
                                                Este campo es requerido.</mat-error>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-group"> <label>Confirma tu contraseña*</label>
                                            <div> <i [ngClass]="getClassC()" aria-hidden="true"
                                                    class="fas fa-eye input_icon"
                                                    (click)="hideConfirm = !hideConfirm"></i> <input
                                                    class="form-control" formControlName="confirmPassword"
                                                    placeholder="Escribe tu contraseña nuevamente"
                                                    [type]="hideConfirm ? 'password' : 'text'"
                                                    [ngClass]="createAccountFormSubmitted && validateRequiredForm('confirmPassword') ? 'is-invalid' : ''" />
                                                </div>
                                            <mat-error
                                                *ngIf="createAccountFormSubmitted && f_createAccountForm.confirmPassword.errors && f_createAccountForm.confirmPassword.errors.required">
                                                Este campo es requerido.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="createAccountFormSubmitted && createAccountForm.hasError('notSame')">
                                                Las contraseñas no coinciden.
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="form-check"> <input class="form-check-input"
                                            formControlName="acceptTerms" type="checkbox" /> <label
                                            class="form-check-label"> Acepto los <a href="/terminos-y-condiciones"
                                                target="_blank">Términos y condiciones</a> y el <a
                                                href="/aviso-de-privacidad" target="_blank">Aviso de Privacidad</a> de a-lender.</label>
                                    </div>
                                    <mat-error
                                        *ngIf="createAccountFormSubmitted && f_createAccountForm['acceptTerms'].errors && f_createAccountForm['acceptTerms'].errors['required']">
                                        Debe aceptar los Términos y condiciones, así como el Aviso de
                                        Privacidad.
                                    </mat-error>

                                    <div class="mt-3 text-center">
                                      <button
                                        class="btn_primary dark"
                                        type="submit"
                                      >
                                        <a class="nav-link">Continuar</a>
                                      </button>
                                    </div>

                                    <div class="mt-4 text-center">
                                        <p class="mb-0"> Ya tienes una cuenta <a href="/iniciar-sesion"
                                                class="fw-medium text-primary"> Inicia sesión</a> </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step [optional]="false" [editable]="false">
        <ng-template matStepLabel>Validación de cuenta</ng-template>
        <app-validate-email></app-validate-email>
    </mat-step>

    <mat-step [stepControl]="personalDataForm" [optional]="false" [editable]="false">
        <ng-template matStepLabel>Datos de usuario</ng-template>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card overflow-hidden">
                        <div class="bg-primary bg-soft-primary">
                            <div class="d-flex align-items-center">
                                <div class="col-8 col-sm-9 col-md-9">
                                    <div class="text-white p-4">
                                        <h5 class="text-white">Datos de usuario</h5>
                                        <p class="mb-0">Ingresa tus datos</p>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-3 col-md-3">
                                    <div class="avatar-md">
                                        <span class="avatar-title rounded-circle bg-light">
                                            <a href="/">
                                                <img src="assets/images/icons/c_wing.svg" height="34" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form class="p-2" [formGroup]="personalDataForm" (ngSubmit)="onSubmitPersonalDataForm()">
                                <div class="form-horizontal">
                                    <div class="mb-3">
                                        <div class="form-group"> <label>Primer nombre</label> <input
                                                autocomplete="firstname" autofocus="autofocus"
                                                class="form-control" formControlName="firstName"
                                                placeholder="Escribe tu primer nombre" type="text" /> </div>
                                        <mat-error
                                            *ngIf="personalDataFormSubmitted && f_personalDataForm['firstName'].errors && f_personalDataForm['firstName'].errors['required']">
                                            Este campo es requerido.</mat-error>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label>Segundo nombre</label>
                                            <input autocomplete="middleName" autofocus="autofocus"
                                                class="form-control" formControlName="middleName"
                                                placeholder="Escribe tu segundo nombre" type="text" />
                                            <mat-error
                                                *ngIf="personalDataFormSubmitted && f_personalDataForm['middleName'].errors && f_personalDataForm['middleName'].errors['required']">
                                                Este campo es requerido.
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-group"> <label>Primer apellido</label> <input
                                                autocomplete="lastname" autofocus="autofocus"
                                                class="form-control" formControlName="lastName1"
                                                placeholder="Escribe tu primer apellido" type="text" />
                                            <mat-error
                                                *ngIf="personalDataFormSubmitted && f_personalDataForm['lastName1'].errors && f_personalDataForm['lastName1'].errors['required']">
                                                Este campo es requerido.
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-group"> <label>Segundo apellido</label> <input
                                                autocomplete="lastname" autofocus="autofocus"
                                                class="form-control" formControlName="lastName2"
                                                placeholder="Escribe tu segundo apellido" type="text" />
                                            <mat-error
                                                *ngIf="personalDataFormSubmitted && f_personalDataForm['lastName2'].errors && f_personalDataForm['lastName2'].errors['required']">
                                                Este campo es requerido.
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-group"> <label>Fecha de nacimiento</label> <input
                                                autocomplete="off" autofocus="autofocus"
                                                class="form-control" formControlName="birthdayDate"
                                                [max]="'maxBirthdayDate'" type="date" />
                                            <mat-error
                                                *ngIf="personalDataFormSubmitted && f_personalDataForm['birthdayDate'].errors && f_personalDataForm['birthdayDate'].errors['required']">
                                                Este campo es requerido.
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="mt-3 d-grid">
                                        <button class="btn btn-primary btn-block" type="submit">
                                            Crear cuenta
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-step>
</mat-horizontal-stepper>
