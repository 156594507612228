import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DocumentGeneratorService } from 'src/app/services/document-generator/document-generator.service';
import { DocumentGeneratorType } from '../../enums/documentGeneratorType.enum';
import htmlToPdfmake from "html-to-pdfmake"
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import FileSaver from 'file-saver';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { DatePipe } from '@angular/common';
import { RequiredFieldsDocumentDTO } from 'src/app/models/required-documents/requiredDocuments.dto.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { LoanpaymentService } from 'src/app/services/loanpayment/loanpayment.service';
import { ProofOfPaymentMailDTO } from 'src/app/models/loanpayment/loanpayment.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';

@Component({
	selector: 'app-html-viewer',
	templateUrl: './html-viewer.component.html',
	styleUrls: ['./html-viewer.component.scss']
})

export class HtmlViewerComponent implements OnInit {
	documentGeneratorType: number;
	loanRequestId: string;
	html: SafeHtml;
	orderId: string;
	documentValid: boolean = true;
	requiredFields: any;
	dataSourceRequiredFields = new MatTableDataSource<RequiredFieldsDocumentDTO>([]);
	documentGenerated: boolean = false;
	htmlString: string;
	lenderEmail: string;
	borrowerMail: string;
	body: string;

	columnsRequiredFields: string[] = [
	'Profile',
	'ConditionText'
	];

	constructor(private documentGeneratorService: DocumentGeneratorService,
	private subscriptionpaymentService: SubscriptionpaymentService,
	private loanpaymentService: LoanpaymentService,
	private loanrequestService: LoanrequestService,
	private dialog: MatDialog,
	private datePipe: DatePipe,
	private alertGlobalService: AlertGlobalService,
				private sanitizer: DomSanitizer) { }

	ngOnInit(): void {
		if (this.documentGeneratorType === DocumentGeneratorType.CONTRACT) {
			this.getContract();
		} else if (this.documentGeneratorType === DocumentGeneratorType.PROMISSORY_NOTE) {
			this.getPromissoryNote();
		} else if (this.documentGeneratorType === DocumentGeneratorType.OXXO) {
			this.getOxxoFile(this.orderId);
			this.documentGenerated = true;
		} else if (this.documentGeneratorType === DocumentGeneratorType.VAUCHER_OF_PAYMENT) {
			this.html = this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
			this.documentGenerated = true;
		
		} else if (this.documentGeneratorType === DocumentGeneratorType.LOAN_REQUEST_EXPEDIENTs) {
			this.GetExpedient();
		}
	}

	getContract() {
		this.documentGeneratorService.getContract(this.loanRequestId)
			.subscribe((data) => {
			var type = typeof data;
			if (type == "string") {
				this.documentValid = true;
				this.html = this.sanitizer.bypassSecurityTrustHtml(data.toString());
			} else {
				this.documentValid = false;
				this.requiredFields = data as RequiredFieldsDocumentDTO[];
				this.dataSourceRequiredFields.data = this.requiredFields;
			}

			this.documentGenerated = true;
		});
	}

	GetExpedient() {
		this.loanrequestService.getExpedient(this.loanRequestId)
			.subscribe((data) => {
			var type = typeof data;
			if (type == "string") {
				this.documentValid = true;
				this.html = this.sanitizer.bypassSecurityTrustHtml(data.toString());
			}

			this.documentGenerated = true;
		});
	}

	getPromissoryNote() {
		this.documentGeneratorService.getPromissoryNote(this.loanRequestId)
			.subscribe((data) => {
			var type = typeof data;

			if (type == "string") {
				this.documentValid = true;
				this.html = this.sanitizer.bypassSecurityTrustHtml(data.toString());
			} else {
				this.documentValid = false;
				this.requiredFields = data as RequiredFieldsDocumentDTO[];
				this.dataSourceRequiredFields.data = this.requiredFields;
			}

			this.documentGenerated = true;
		});
	}

	print() {      
		var mywindow = window.open();
		var documentName = "";

		if (this.documentGeneratorType == 0) {
		documentName = "contrato"
		} else if (this.documentGeneratorType == 1) {
		documentName = "pagare"
		} else if (this.documentGeneratorType == 2) {
		documentName = "oxxo"
		}

		var fileName = documentName + this.datePipe.transform(Date.now(), 'ddMMyyyyHHmmss');

		var htmlString = this.html.toString();
		htmlString = htmlString.replace("SafeValue must use [property]=binding:", "")
		htmlString = htmlString.replace("(see http://g.co/ng/security#xss)", "")

		mywindow.document.write(htmlString);
		mywindow.print();

		return true;
	}

	pdfSrc = "";

	printPdf(base64: any) {
		const byteArray = new Uint8Array(
		  atob(base64)
			.split("")
			.map(char => char.charCodeAt(0))
		);
		const file = new Blob([byteArray], { type: "application/octet-stream" });
		const fileURL = URL.createObjectURL(file);
		this.pdfSrc = fileURL;
		window.open(fileURL);
	}


	exportPDF() {
		var htmlString = this.html.toString();

		htmlString = htmlString.replace("SafeValue must use [property]=binding:", "")
		htmlString = htmlString.replace("(see http://g.co/ng/security#xss)", "")
		htmlString = htmlString.replace("font-size: 12px", "font-size: 2px")
		
		var element = document.getElementById('contentGenerator').innerHTML;

		var fileName = "alender-contract" + '-'+ new Date().toISOString() + ".pdf"
		var html = htmlToPdfmake(element);
		const documentDefinition = { 
			content: [html],
			styles: { font: 'Calibri !important;' }
		};

		pdfMake.createPdf(documentDefinition).open();
		return;

		const file = new Blob([document.getElementById("content").innerHTML], {type: 'application/pdf'});

		FileSaver.saveAs(file, "test.pdf")
	}

	getOxxoFile(orderId: string) {
		this.subscriptionpaymentService.searchOrderOxxo(orderId).subscribe((data) => {
			this.html = this.sanitizer.bypassSecurityTrustHtml(data.toString());
			
			this.documentValid = true;
		})
	}

	sendContactMail() {
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          message: '¿Desea enviar comprobante de pago al solicitante?'
        }
      })
	  .afterClosed()
	  .subscribe((confirmado: Boolean) => {
		var cc = "";

		if (confirmado) {
			//cc = this.borrowerMail;
		}
		
		var proofOfPaymentMail = <ProofOfPaymentMailDTO> {
			to: this.borrowerMail,
			cc: cc,
			body: this.body
		}
		
		this.loanpaymentService.sendProofOfPaymentMail(proofOfPaymentMail).subscribe((result) => {
			this.alertGlobalService.showAlertSuccess("Correo enviado satistactoriamente");
			dialogRef.closed;
		});
      });
	}
}