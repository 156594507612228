<mat-accordion>
    <mat-expansion-panel [expanded]="panelPatrimonialState" class="mb-4 bg-white" (opened)="panelPatrimonialOpen(true)" (closed)="panelPatrimonialOpen(false)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="d-flex">
                    <h5>Datos patrimoniales</h5>
                    <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelActiveState" class="mb-4"
                (opened)="panelActiveOpen(true)" (closed)="panelActiveOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Relación de bienes / activos</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form [formGroup]="patrimonialActiveForm" (ngSubmit)="onSubmitPatrimonialActive()">
                    <div class="row">
                        <div class="subtittle2">Agregar bienes</div>

                        <div class="col-lg-3 col-md-6 marginCustom mb-3">
                            <div class="form-group">
                                <label>Tipo de bien*</label>
                                <select #selectActiviteType class="form-control"
                                    formControlName="bienType">
                                    <option *ngFor="let item of activiteList" value="{{item.value}}">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 marginCustom mb-3">
                            <div class="form-group">
                                <label>Valor estimado*</label>
                                <input class="form-control" type="number" placeholder=""
                                    formControlName="estimatedValue" autocomplete="off"
                                    autocapitalize="off">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                            <div>
                                <div class="form-group"> <label>Descripción*</label>
                                  <input class="form-control" type="text" placeholder="" formControlName="description" autocomplete="off" autocapitalize="off">
                                </div>
                            </div>
                            <button class="btn btn-outline-primary internal-btn">
                                Agregar
                            </button>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <div class="react-bootstrap-table">
                            <table *ngIf="dsPatrimonialActiveData" mat-table [dataSource]="dsPatrimonialActiveData">
                                <ng-container matColumnDef="bienType">
                                    <th mat-header-cell *matHeaderCellDef> Tipo de bien </th>
                                    <td mat-cell *matCellDef="let row"> {{row.bienType}} </td>
                                    <td mat-footer-cell *matFooterCellDef> Total </td>
                                </ng-container>

                                <ng-container matColumnDef="estimatedValue">
                                    <th mat-header-cell *matHeaderCellDef> Valor estimado </th>
                                    <td mat-cell *matCellDef="let row"> <span>{{row.estimatedValue |
                                            currency}}</span> </td>
                                    <td mat-footer-cell *matFooterCellDef> {{getPatrimonialActiveTotal()
                                        | currency}} </td>
                                </ng-container>

                                <ng-container matColumnDef="description">
                                    <th mat-header-cell *matHeaderCellDef> Descripción </th>
                                    <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <ng-container matColumnDef="buttonRemove">
                                    <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                                    <td mat-cell *matCellDef="let row; let i = index;">
                                        <i class="bx bx-x" (click)="onDeletePatrimonialActive(i)"></i>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="patrimonialActiveColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: patrimonialActiveColumns;">
                                </tr>
                                <tr mat-footer-row *matFooterRowDef="patrimonialActiveColumns"></tr>
                            </table>
                        </div>
                    </div>
                </form>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelPassiveState" (opened)="panelPassiveOpen(true)" (closed)="panelPassiveOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Relación de deudas / pasivos</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form [formGroup]="patrimonialPassiveForm" (ngSubmit)="onSubmitPatrimonialPassive()">
                    <div class="row">
                        <div class="subtittle2">Agregar pasivos</div>

                        <div class="col-lg-3 col-md-6 marginCustom mb-3">
                            <div class="form-group">
                                <label>Tipo de deuda*</label>
                                <select #selectDebtType class="form-control" formControlName="debtType">
                                    <option *ngFor="let item of passiveList" value="{{item.value}}">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 marginCustom mb-3">
                            <div class="form-group">
                                <label>Saldo por pagar total*</label>
                                <input class="form-control" type="number" placeholder=""
                                    formControlName="balancePayable" autocomplete="off"
                                    autocapitalize="off">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                            <div>
                                <div class="form-group"> <label>Descripción*</label>
                                  <input
                                        class="form-control" type="text" placeholder=""
                                        formControlName="description" autocomplete="off"
                                        autocapitalize="off">
                                </div>
                            </div>
                            <button class="btn btn-outline-primary internal-btn">
                                Agregar
                            </button>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <div class="react-bootstrap-table">
                            <table *ngIf="dsPatrimonialPassiveData" mat-table
                                [dataSource]="dsPatrimonialPassiveData">
                                <ng-container matColumnDef="debtType">
                                    <th mat-header-cell *matHeaderCellDef> Tipo de bien </th>
                                    <td mat-cell *matCellDef="let row"> {{row.debtType}} </td>
                                    <td mat-footer-cell *matFooterCellDef> Total </td>
                                </ng-container>

                                <ng-container matColumnDef="balancePayable">
                                    <th mat-header-cell *matHeaderCellDef>Valor estimado</th>
                                    <td mat-cell *matCellDef="let row">
                                        <span>{{row.balancePayable | currency}}</span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef>
                                        {{getPatrimonialPassiveTotal() | currency}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="description">
                                    <th mat-header-cell *matHeaderCellDef>Descripción</th>
                                    <td mat-cell *matCellDef="let row">{{row.description}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <ng-container matColumnDef="buttonRemove">
                                    <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                                    <td mat-cell *matCellDef="let row; let i = index;">
                                        <i class="bx bx-x" (click)="onDeletePatrimonialPassive(i)"></i>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="patrimonialPassiveColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: patrimonialPassiveColumns;">
                                </tr>
                                <tr mat-footer-row *matFooterRowDef="patrimonialPassiveColumns"></tr>
                            </table>
                        </div>
                    </div>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-expansion-panel>
</mat-accordion>
