
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { TypeofamortizationService } from 'src/app/services/typeofamortization/typeofamortization.service';
import { TypeofcreditService } from 'src/app/services/typeofcredit/typeofcredit.service';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { LoanRequestDTO, } from 'src/app/models/loanrequest/loanrequest.dto.model';
import { FormOptionsViewModel, } from 'src/app/models/loanrequest/form-request.dto.model';
import { MatTableDataSource } from '@angular/material/table';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { RequiredDocumentDTO } from 'src/app/models/required-documents/requiredDocuments.dto.model';
import { DialogPreviewDocumentComponent } from '../dialog-preview-document/dialog-preview-document.component';
import { DialogWarrantyPeopleDetailsComponent } from '../dialog-warranty-people-details/dialog-warranty-people-details.component';
import { DialogWarrantyObligorJointDetailsComponent } from '../dialog-warranty-obligorjoint-details/dialog-warranty-obligorjoint-details.component';
import { DialogWarrantyMortgageDetailsComponent } from '../dialog-warranty-mortgage-details/dialog-warranty-mortgage-details.component';
import { DialogWarrantyPledgeDetailsComponent } from '../dialog-warranty-pledge-details/dialog-warranty-pledge-details.component';
import { DialogWarrantyLiquidDetailsComponent } from '../dialog-warranty-liquid-details/dialog-warranty-liquid-details.component';
import { DialogWarrantyOtherDetailsComponent } from '../dialog-warranty-other-details/dialog-warranty-other-details.component';
import { HtmlViewerComponent } from 'src/app/views/html-viewer/html-viewer.component';
import { AmortizeService } from 'src/app/services/amortize/amortize.service';
import { AdvanceOptions, Amortize, Commission } from 'src/app/models/amortize/amortize.model';
import { GlobalLists } from 'src/app/global/global-data';
import { Uuidv4Service } from 'src/app/services/uuidv4/uuidv4.service';
import { DialogPersonDataComponent } from '../dialog-person-data/dialog-person-data.component';
import { DialogAddressDataComponent } from '../dialog-address-data/dialog-address-data.component';
import { DialogContactDataComponent } from '../dialog-contact-data/dialog-contact-data.component';
import { DialogEconomicActivityComponent } from '../dialog-economic-activity/dialog-economic-activity.component';
import { DialogStatusResultComponent } from '../dialog-status-result/dialog-status-result.component';
import { DialogBalanceSheetDataComponent } from '../dialog-balance-sheet-data/dialog-balance-sheet-data.component';
import { DialogBankDataComponent } from '../dialog-bank-data/dialog-bank-data.component';
import { DialogSocioeconomicComponent } from '../dialog-socioeconomic/dialog-socioeconomic.component';
import { DialogReferenceDataComponent } from '../dialog-reference-data/dialog-reference-data.component';
import { DialogFamilyDataComponent } from '../dialog-family-data/dialog-family-data.component';
import { DialogEconomicFlowComponent } from '../dialog-economic-flow/dialog-economic-flow.component';
import { DialogPatrimonialDataComponent } from '../dialog-patrimonial-data/dialog-patrimonial-data.component';
import { AccountStatementComponent } from 'src/app/views/account-statement/account-statement.component';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CallCenterService } from 'src/app/services/callCenter/callCenter.service';
import { CallCenterData, CreateCall } from 'src/app/models/loan/callCenter.dto.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ContactPhone } from 'src/app/models/loan/loan.dto.model';
import { UserLogged } from 'src/app/models/user/userlogged.model';
import { environment } from 'src/environments/environment';
import { DocumentServiceService } from 'src/app/services/document-service/document-service.service';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { MatSort } from '@angular/material/sort';
import { first } from 'rxjs';
import { DialogShowCallRatingComponent } from '../dialog-show-details-call-rating/dialog-show-details-call-rating.component';
import * as stringSimilarity from 'string-similarity';

@Component({
	selector: 'app-callcenter-resume',
	templateUrl: './callcenter-resume.component.html',
	styleUrls: ['./callcenter-resume.component.scss'],
	providers: [{
		provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
	}]
})

export class CallcenterResumeComponent implements OnInit {
	@Input() externalId: string = "";
	@Input() totalCalls: string = "";
	currentUser: UserLogged = new UserLogged;


	@ViewChild('conditionsData') conditionsData: DialogPersonDataComponent;
	@ViewChild('personData') personData: DialogPersonDataComponent;
	@ViewChild('addressData') addressData: DialogAddressDataComponent;
	@ViewChild('contactData') contactData: DialogContactDataComponent;
	@ViewChild('economicActivityData') economicActivityData: DialogEconomicActivityComponent;
	@ViewChild('patrimonialData') patrimonialData: DialogPatrimonialDataComponent;
	@ViewChild('economicFlowData') economicFlowData: DialogEconomicFlowComponent;
	@ViewChild('familyData') familyData: DialogFamilyDataComponent;
	@ViewChild('referenceData') referenceData: DialogReferenceDataComponent;
	@ViewChild('socioeconomicData') socioeconomicData: DialogSocioeconomicComponent;
	@ViewChild('bankData') bankData: DialogBankDataComponent;
	@ViewChild('balanceSheetData') balanceSheetData: DialogBalanceSheetDataComponent;
	@ViewChild('statusResultData') statusResultData: DialogStatusResultComponent;
	@ViewChild('accountStatement') accountStatement: AccountStatementComponent;
	@ViewChild('inputElement', { static: false }) inputElement: ElementRef<HTMLInputElement>;
	@ViewChild('datepickerElement', { static: false }) datepickerElement: ElementRef<HTMLInputElement>;
	@ViewChild('textareaElement', { static: false }) textareaElement: ElementRef<HTMLInputElement>;
	@ViewChild(MatSort) sort: MatSort;

	history: any[];
	borrowerCustomerId = "";

	legalConditionsValue: number;
	isLender = false;
	loanRequestStatus: number;

	isNumber: boolean = false;

	progress: number[] = [];

	borrowerMail = "";
	borrowerPhoneMobile = "";
	panelIntegrateFileOpenState = true;
	panelDetailsOpenState = true;
	panelDetailsOpenOption = true;
	panelContactDataOpenState = false;

	dateStartManagement: Date;
	dateEndtManagement: Date;
	formQuestionJson: any;
	formJson: any;
	questionsJson: FormOptionsViewModel[] = [];
	anwersManagementQuestions: any;
	callScript: string;
	typeCall = "";
	minutes: number = 0;
	seconds: number = 0;
	additionalOption: string;
	startDateString = this.datePipe.transform(new Date(), "yyyy-MM-dd");
	startTimeString = this.datePipe.transform(new Date(), "HH:mm");
	dateStartCall = '';
	lenderNameAgent = "";
	welcome: any;
	buttonClicked: boolean = true;
	currencyMask = GlobalLists.currencyMask;
	phoneMask = GlobalLists.phoneMask;
	nextQuestion: number = 0;
	amountPromise = null;
	selectedDate = "";
	activeButton = false;
	selectValue = ""
	isPromiseRegistered = false;
	isButtonsP = false;
	lenderFullName = "";
	loanFullName = "";
	dayVisit: string;
	hourStart: string;
	hourEnd: string;
	visitType: string;
	statusCall: string;
	bank: string = null;
	accountBank: string;
	clabeBank: string;
	cardNumber: string;
	commentsOption: string;
	linkPay: string;
	starFormQuestion: boolean = false;
	answersRasosnsNoPay: any;
	booleanRasosnsNoPay: boolean = false;
	disabledPhone: boolean = false;

	radioRadioInput: string = null;
	inputRadioInput: string = null;
	mountDatePay: number = null;
	dateDatePay: Date = null;
	formPayDatePay: string = null;
	otherFormPayDatePay: string = null;
	inputInputThree: number = null;
	inputTextAreaDate: string = null;
	dateTextAreaDate: Date = null;
	dateDateFive: Date = null;
	dateDateNumber: Date = null;
	formContactDateNumber: string = null;
	phoneAdds = [];
	selectPhone: string;
	phoneNumbers: string[] = [];
	principalPhone: any;
	dataResponse: any = [];
	callRating = false;

	stars: number[] = [1, 2, 3, 4, 5];
	ratings: number[] = [1, 2, 3, 4, 5];
	numberCalls: number[] = [5, 4, 3, 2, 1];
	selectedRating: number = 0;
	selectedRatingStar: number = 0;
	hoveredRating: number = 0;
	hoveredRatingCall: number = 0;
	nextSection = false;
	comentts: string = '';
	formatInvalid: boolean = false;
	selectedFiles: File[] = [];
	currentNumber = 1;
	uploadFile: boolean[] = [];
	documents = [];
	limitedImage: any;
	maxLength: number = 170;
	lengCaracte: number = this.maxLength;
	colors = ["#fc1404", "#fc9404", "#fce404", "#7ee004", "#33da3c"];

	dataSourceformWarrantys = new MatTableDataSource<WarrantysForm>(<WarrantysForm[]>[]);
	dataSourceRequiredDocuments = new MatTableDataSource<RequiredDocumentDTO>([]);
	dataSourceRequiredDocumentsLegal = new MatTableDataSource<RequiredDocumentDTO>([]);

	elementsFormWarrantys: WarrantysForm[] = [];

	columnsRequiredDocuments: string[] = [
		'description',
		'required',
		'uploaded',
		'viewFile'
	];

	columnsRequiredDocumentsLegal: string[] = [
		'description',
		'required',
		'uploaded',
		'viewFile'
	];

	columnsFormWarrantys: string[] = [
		'number',
		'warranty',
		'warrantyFormCompleted',
		'warrantyForm',
	];

	callCenterResultColumns: string[] = [
		'dateCreated',
		'firstNameLoan',
		'callTypeName',
		'agent',
		'phone',
		'actions'
	];

	callsListDataSource: MatTableDataSource<CreateCall>;
	dsContactPhoneData: MatTableDataSource<ContactPhone>;

	economicActivityActive = false;
	patrimonialActive = false;
	economicFlowActive = false;
	familyActive = false;
	referenceActive = false;
	socioeconomicActive = false;
	bankActive = false;
	balanceSheetActive = false;
	statusResultActive = false;
	personDataActive = false;
	pldActive = false;

	loadFormRequestJsonForm = false;
	personType: number;
	occupation: number[] = [];

	loanRequestDTO: LoanRequestDTO;

	dr = false;

	addressDataJsonCompleted = false;
	contactDataJsonCompleted = false;
	economicActivityDataJsonCompleted = false;
	patrimonialDataJsonCompleted = false;
	economicFlowDataJsonCompleted = false;
	familyDataJsonCompleted = false;
	referenceDataJsonCompleted = false;
	socioeconomicDataJsonCompleted = false;
	bankDataJsonCompleted = false;
	balanceSheetDataJsonCompleted = false;
	statusResultDataJsonCompleted = false;
	pldDataJsonCompleted = false;

	contactRelationShip: string;
	mode = "loan";
	formattedDate = "";
	inputAnswerObjetion: any;
	inputTypeAnswerObjetion: any;


	loanRequestId;
	loanRequestValidation: boolean = false;
	callCenterData: CallCenterData;
	totalNumberCalls: any;

	id = "";
	formPayData: string;
	otherFormPay: string;

	contactPhoneColumns = [
		'phoneType',
		'phone',
		'comments',
		'select'
	];
	answerSelectPay: any;
	answerSelectCall: number;
	answerNumberCall: number;
	nameCall: any;
	debtRason: string;
	typeLoanDebtor: string;
	hoverNumberCalls: number;
	externalStatus: number;
	texto: string = '';
	diccionario: string[] = ['razón', 'préstamo', 'recibió', 'ningún'];
	moduleName: string;


	constructor(
		private spinnerService: SpinnerService,
		private typeofperiodService: TypeofperiodService,
		private callCenterService: CallCenterService,
		private typeofamortizationService: TypeofamortizationService,
		private typeofcreditService: TypeofcreditService,
		private authService: AuthenticationService,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private loanrequestService: LoanrequestService,
		public amortizeService: AmortizeService,
		private uuidv4Service: Uuidv4Service,
		private location: Location,
		private changeDetector: ChangeDetectorRef,
		private documentServiceService: DocumentServiceService,
		private router: Router,
		private datePipe: DatePipe,
		private alertGlobalService: AlertGlobalService,
		private route: ActivatedRoute) {

		this.authService.currentUser.subscribe(x => this.currentUser = x);

		if (this.currentUser.userType != 1) {
			this.router.navigate(['/inicio']);
		}
		this.externalId = this.route.snapshot.paramMap.get('externalId');
	}

	ngOnInit(): void {
		this.getCallCenterData();
		this.callCenterService.getCalls().subscribe(
			(result) => {
				const totalCallsString = result.find(x => x.id === this.externalId).totalCalls;
				const firstNumber = parseInt(totalCallsString.match(/\d+/)[0], 10);
				this.totalNumberCalls = firstNumber + 1;
				this.hoverNumberCalls = this.totalNumberCalls;
			});

	}


	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	getCallCenterData() {
		this.callCenterService.getCallData(this.externalId).subscribe(
			(result) => {
				this.callCenterData = result;
				this.loanRequestId = result.loanRequestId;
				this.typeCall = result.callTypeName;
				this.formQuestionJson = result.formQuestionJson;
				this.anwersManagementQuestions = result.anwersManagementObjetions;
				this.formJson = result.formQuestionJson;
				this.lenderNameAgent = this.authService.currentUserValue.firstName + " " + this.authService.currentUserValue.lastName1;
				this.lenderFullName = this.capitalizeFirstLetter(result.lenderFullName);
				this.loanFullName = this.capitalizeFirstLetter(result.applicantName);
				this.dayVisit = result.dayVisit;
				this.hourStart = result.hourStart;
				this.hourEnd = result.hourEnd;
				this.visitType = result.visitType;
				this.bank = result.bank;
				this.accountBank = result.account;
				this.clabeBank = result.clabe;
				this.cardNumber = result.cardNumber;
				this.commentsOption = result.comments;
				this.statusCall = result.statusName;
				this.linkPay = result.linkPay;
				this.nameCall = this.capitalizeFirstLetter(result.nameCall);
				this.debtRason = result.debtRason;
				this.typeLoanDebtor = result.typeLoanDebtor;

				if (this.loanRequestId != null) {
					this.loanRequestValidation = true;
				}

				if (result.phoneAdds.length === 0) {
					this.principalPhone = {
						phoneType: "Celular principal",
						phone: result.phone,
						comments: ""
					};
					result.phoneAdds.push(this.principalPhone)

				} else if (result.phoneAdds.length > 0) {
					result.phoneAdds.map((data: any) => {
						this.principalPhone = {
							phoneType: "Celular principal",
							phone: result.phone,
							comments: ""
						}
					})
					result.phoneAdds.unshift(this.principalPhone);
				}

				this.dsContactPhoneData = new MatTableDataSource(result.phoneAdds);

				this.callsListDataSource = new MatTableDataSource(result.callsList);

				this.callScript = this.typeCall == 'Cobranza' ? "Script de llamada Cobranza" : "Sin status de Llamada";

				const hour = new Date().getHours();
				this.welcome = hour >= 12 && hour <= 20 ? "¡Buenas Tardes!" : (hour > 20 || hour <= 5) ? "¡Buenas Noches!" : "¡Buenos Días!";

				this.applyClass();
				if (this.loanRequestValidation == true) {
					this.getLoanRequest();
				}

			}
		);
	}

	capitalizeFirstLetter(value: string): string {
		if (!value || value.length === 0) {
			return '';
		}

		const words = value.split(' ');
		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
		return capitalizedWords.join(' ');
	}

	onViewDetailCall(row: CreateCall) {

		this.router.navigate(['/historial-de-llamadas'], {
			state: {
				rowData: row,
				lenderFullName: this.callCenterData.lenderFullName,
				applicantName: this.callCenterData.applicantName,
				externalId: this.externalId
			}
		});
	}

	previewFile(url: string, fileName: string) {
		const dialogRef = this.dialog.open(DialogPreviewDocumentComponent, {
			autoFocus: false,
			width: '80%'
		});
		let instance = dialogRef.componentInstance;
		instance.url = url;
		instance.extensionFile = /[^.]+$/.exec(fileName)[0];
	}

	openDialogHtmlViewer(description): void {
		this.viewDocumentLegal(description);
	}

	viewDocumentLegal(description: string) {
		var documentGeneratorType = -1;

		if (description == "Contrato") {
			documentGeneratorType = 0
		} else if (description == "Pagaré") {
			documentGeneratorType = 1
		}

		const dialogRef = this.dialog.open(HtmlViewerComponent, {
			autoFocus: false,
			width: '80vh',
			disableClose: true
		});

		let instance = dialogRef.componentInstance;

		instance.documentGeneratorType = documentGeneratorType;
		instance.loanRequestId = this.loanRequestId;
	}

	getLoanRequest() {
		this.loanrequestService.getLoanRequest(this.loanRequestId).subscribe((result) => {
			this.loanRequestDTO = result;
			this.id = this.loanRequestDTO.id;
			this.borrowerCustomerId = this.loanRequestDTO.borrowerCustomerId;

			if (this.loanRequestDTO.formRequestEmail == "") {
				this.borrowerMail = this.loanRequestDTO.contact;
			} else {
				this.borrowerMail = this.loanRequestDTO.formRequestEmail;
			}

			if (this.loanRequestDTO.formRequestPhoneMobile == "") {
				this.borrowerPhoneMobile = this.loanRequestDTO.phoneMobileQs;
			} else {
				this.borrowerPhoneMobile = this.loanRequestDTO.formRequestPhoneMobile;
			}

			this.loanRequestStatus = this.loanRequestDTO.status
			this.legalConditionsValue = Number.parseInt(this.loanRequestDTO.loanRequestDetails.find(x => x.conditionName == "TotalPeriods").newConditionValue);

			if (this.loanRequestDTO.completed) {
				this.loadFormRequestJsonForm = true;
			}

			if (this.loanRequestDTO.loanRequestDetails.find(x => x.conditionName === 'PersonType') != null) {
				this.personType = parseInt(this.loanRequestDTO.loanRequestDetails.find(x => x.conditionName === 'PersonType').newConditionValue);
				this.occupation = eval(this.loanRequestDTO.loanRequestDetails.find(x => x.conditionName === 'Occupation').newConditionValue);
			}

			this.contactRelationShip = this.loanRequestDTO.contactRelationShip;

			var peopleExists = false;
			var obligorJointExists = false;
			var mortgageExists = false;
			var pledgeExists = false;
			var liquidExists = false;
			var otherExists = false;
			this.elementsFormWarrantys = [];

			if (this.loanRequestDTO.advanceOptions != null) {
				this.economicActivityActive = false;
				this.patrimonialActive = false;
				this.economicFlowActive = false;
				this.familyActive = false;
				this.referenceActive = false;
				this.socioeconomicActive = false;
				this.bankActive = false;
				this.balanceSheetActive = false;
				this.statusResultActive = false;

				var options = JSON.parse(this.loanRequestDTO.advanceOptions) as AdvanceOptions[]

				for (let option of options) {
					if (option.description == "Datos actividad económica") {
						this.economicActivityActive = true;
					} else if (option.description == "Datos patrimoniales") {
						this.patrimonialActive = true;
					} else if (option.description == "Datos económicos") {
						this.economicFlowActive = true;
					} else if (option.description == "Datos familiares") {
						this.familyActive = true;
					} else if (option.description == "Datos referencias") {
						this.referenceActive = true;
					} else if (option.description == "Datos socioeconómicos") {
						this.socioeconomicActive = true;
					} else if (option.description == "Datos bancarios") {
						this.bankActive = true;
					} else if (option.description == "Datos balance general") {
						this.balanceSheetActive = true;
					} else if (option.description == "Datos estado de resultados") {
						this.statusResultActive = true;
					}
				}
			}

			if (this.loanRequestDTO.peopleGuaranteeDataJson != null) {
				peopleExists = true;

				var peopleResult = JSON.parse(this.loanRequestDTO.peopleGuaranteeDataJson) as WarrantysForm[];

				for (let v of peopleResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (this.loanRequestDTO.obligorJointGuaranteeDataJson != null) {
				var obligorJointResult = JSON.parse(this.loanRequestDTO.obligorJointGuaranteeDataJson) as WarrantysForm[];
				obligorJointExists = true;

				for (let v of obligorJointResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (this.loanRequestDTO.mortgageGuaranteeDataJson != null) {
				var mortgageResult = JSON.parse(this.loanRequestDTO.mortgageGuaranteeDataJson) as WarrantysForm[];
				mortgageExists = true;

				for (let v of mortgageResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (this.loanRequestDTO.pledgeGuaranteeDataJson != null) {
				var pledgeResult = JSON.parse(this.loanRequestDTO.pledgeGuaranteeDataJson) as WarrantysForm[];
				pledgeExists = true;

				for (let v of pledgeResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (this.loanRequestDTO.liquidGuaranteeDataJson != null) {
				var liquidResult = JSON.parse(this.loanRequestDTO.liquidGuaranteeDataJson) as WarrantysForm[];
				liquidExists = true;

				for (let v of liquidResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			if (this.loanRequestDTO.otherGuaranteeDataJson != null) {
				var otherResult = JSON.parse(this.loanRequestDTO.otherGuaranteeDataJson) as WarrantysForm[];
				otherExists = true;

				for (let v of otherResult) {
					this.elementsFormWarrantys.push({
						number: v.number,
						warranty: v.warranty,
						warrantyForm: v.warrantyForm,
						warrantyFormCompleted: v.warrantyFormCompleted
					})
				}
			}

			this.dataSourceformWarrantys.data = this.elementsFormWarrantys;

			this.dataSourceRequiredDocuments.data = JSON.parse(this.loanRequestDTO.documentsRequiredConfigJson) as RequiredDocumentDTO[];
			this.dataSourceRequiredDocumentsLegal.data = JSON.parse(this.loanRequestDTO.documentsLegalRequiredConfigJson) as RequiredDocumentDTO[];

			this.spinnerService.requestEnded()
		});
	}

	openWarrantyDetails(number: number, warranty: string, warrantysJson) {
		if (warranty == "Aval") {
			const dialogRef = this.dialog.open(DialogWarrantyPeopleDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Obligado solidario") {
			const dialogRef = this.dialog.open(DialogWarrantyObligorJointDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Hipotecaria") {
			const dialogRef = this.dialog.open(DialogWarrantyMortgageDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Prendaria") {
			const dialogRef = this.dialog.open(DialogWarrantyPledgeDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Liquida") {
			const dialogRef = this.dialog.open(DialogWarrantyLiquidDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		} else if (warranty == "Otra") {
			const dialogRef = this.dialog.open(DialogWarrantyOtherDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});
		}
	}

	panelIntegrateFileOpen(e) {
		this.panelIntegrateFileOpenState = e;
	}

	panelContactDataOpen(e) {
		this.panelContactDataOpenState = e;
	}

	clearAnswerAndAdditionalAnswer(idQuestion: number): void {
		const lastIndex = this.questionsJson.findIndex(q => q.idQuestion === idQuestion);
		for (let i = lastIndex + 1; i < this.questionsJson.length; i++) {
			this.questionsJson[i].answer = null;
			this.questionsJson[i].additionalAnswer = null;
		}
	}

	startTimer() {
		this.dateStartManagement = new Date();
		setInterval(() => {
			this.seconds++;
			if (this.seconds === 60) {
				this.seconds = 0;
				this.minutes++;
			}
			this.dateStartCall = `${this.minutes} min ${this.seconds} s`;
		}, 1000);

		this.buttonClicked = true;
		this.disabledPhone = true
		this.questionsJson.push(this.formQuestionJson.find(x => x.idQuestion >= 1.0 && x.idQuestion < 2.0));
	}

	onSelectChange(selectedValue: string, idQuestion: number) {
		const questionMap = {
			"Yo no debo": 1,
			"Ya pagué": 2,
			"Yo no debo esa cantidad": 3,
			"Ahorita no tengo dinero": 4,
			"Le pago después": 5,
			"Puedo pagar una parte": 6,
			"No voy a pagar": 7,
			"No tengo, ni quiero pagar": 8,
			"No me llamen para cobrar": 9,
			"Yo hablo con el Prestamista": 10
		};

		const formPayMap = {
			"Efectivo": "Su pago en Efectivo lo realizaría directamente con @LenderFullName el día @DatePromise por la cantidad de @AmountPromise",
			"Cheque": "Su pago en Cheque lo realizaría directamente con @LenderFullName el día @DatePromise por la cantidad de @AmountPromise",
			"Depósito en cuenta": "Su pago por Depósito en cuenta lo realizaría directamente a nombre de @LenderFullName el día @DatePromise por la cantidad de @AmountPromise , le proporciono los datos adicionales",
			"Transferencia electrónica": "Su pago por Transferencia Electrónica en cuenta lo realizaría directamente a nombre de @LenderFullName el día @DatePromise por la cantidad de @AmountPromise , le proporciono los datos adicionales",
			"En especie": "Su pago en @Other lo realizaría directamente con @LenderFullName el día @DatePromise por la cantidad de @AmountPromise",
			"Descuento de nómina": "Su pago por Descuento por Nómina lo realizaría directamente con @LenderFullName el día @DatePromise por la cantidad de @AmountPromise",
			"Tarjeta de crédito/ débito": "Su pago por Tarjeta de Crédito / Débito lo realizaría directamente a la tarjeta a nombre de @LenderFullName el día @DatePromise por la cantidad de @AmountPromise , le proporciono los datos adicionales",
			"Vales de despensa": "Su pago por Vales de Despensa lo realizaría directamente con @LenderFullName el día @DatePromise por la cantidad de @AmountPromise",
			"Condonación": "Su pago por Condonación lo realizaría directamente con @LenderFullName el día @DatePromise por la cantidad de @AmountPromise",
			"Otra": "Su pago @Other realizaría directamente con @LenderFullName el día @DatePromise por la cantidad de @AmountPromise",
		};

		if (idQuestion === 1.0 && selectedValue != null) {
			const questionId = selectedValue;
			if (questionId != null) {
				var nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion >= 2.0 && x.idQuestion <= 2.0);
				this.questionsJson = this.questionsJson.filter(x => x.idQuestion <= idQuestion);
				this.questionsJson.push(...nextQuestionObj);
			}
		}

		if (idQuestion === 3.5 && selectedValue != null) {
			const questionId = selectedValue;
			if (questionId != null) {
				var nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion >= 3.6 && x.idQuestion <= 4.1);
				this.questionsJson = this.questionsJson.filter(x => x.idQuestion <= idQuestion);
				this.questionsJson.push(...nextQuestionObj);
			}
		}

		if (idQuestion === 5.1 && questionMap[selectedValue]) {
			const questionId = questionMap[selectedValue];
			this.inputAnswerObjetion = this.anwersManagementQuestions.find(x => x.idQuestion === questionId)?.type
			this.additionalOption = this.anwersManagementQuestions.find(x => x.idQuestion === questionId)?.question;
			if (questionMap != null) {
				var nextQuestionObj = this.formJson.filter(x => x.idQuestion >= 5.2 && x.idQuestion <= 5.5);
				this.questionsJson = this.questionsJson.filter(x => x.idQuestion <= idQuestion);
				this.questionsJson.push(...nextQuestionObj);
			}

		}

		if (idQuestion === 6.3 && formPayMap[selectedValue]) {
			this.formPayData = formPayMap[selectedValue];

		}

		if (idQuestion === 6.3) {
			this.selectValue = selectedValue;
			if (selectedValue != null) {
				this.isButtonsP = true;
			}
		}

		if (idQuestion === 9.1 || idQuestion === 12.2 || idQuestion === 10.2) {
			this.selectValue = selectedValue;
			if (selectedValue != null) {
				this.activateButton();
			}
		}

		if (idQuestion === 12.2) {
			this.selectValue = selectedValue;
			if (selectedValue === "Volver a realizar llamada") {

				var nextQuestionObj = this.formJson.filter(x => x.idQuestion >= 12.3 && x.idQuestion <= 12.4);
				this.questionsJson = this.questionsJson.filter(x => x.idQuestion <= idQuestion);
				this.questionsJson.push(...nextQuestionObj);

			} else if (selectedValue === "Ninguna") {

				var nextQuestionObj = this.formJson.filter(x => x.idQuestion >= 12.3 && x.idQuestion <= 12.4);
				const filteredQuestions = this.questionsJson.filter(x => x.idQuestion >= 12.3 && x.idQuestion <= 12.4);
				const indexToRemove = this.questionsJson.indexOf(filteredQuestions[0]);

				if (indexToRemove > -1) {
					this.questionsJson.splice(indexToRemove, 2);
				}
			}
		}

		if (idQuestion === 10.2) {
			this.selectValue = selectedValue;
			if (selectedValue === "Volver a realizar llamada") {

				var nextQuestionObj = this.formJson.filter(x => x.idQuestion >= 10.3 && x.idQuestion <= 10.4);
				this.questionsJson = this.questionsJson.filter(x => x.idQuestion <= idQuestion);
				this.questionsJson.push(...nextQuestionObj);

			} else if (selectedValue === "Ninguna") {

				var nextQuestionObj = this.formJson.filter(x => x.idQuestion >= 10.3 && x.idQuestion <= 10.4);
				const filteredQuestions = this.questionsJson.filter(x => x.idQuestion >= 10.3 && x.idQuestion <= 10.4);
				const indexToRemove = this.questionsJson.indexOf(filteredQuestions[0]);

				if (indexToRemove > -1) {
					this.questionsJson.splice(indexToRemove, 2);
				}
			}
		}
	}

	clearField(dropdownElement: HTMLSelectElement) {
		if (this.inputElement && this.inputElement.nativeElement) {
			this.inputElement.nativeElement.value = '';
		}
		if (this.datepickerElement && this.datepickerElement.nativeElement) {
			this.datepickerElement.nativeElement.value = '';
		}
		if (this.textareaElement && this.textareaElement.nativeElement) {
			this.textareaElement.nativeElement.value = '';
		}
		if (dropdownElement) {
			dropdownElement.selectedIndex = -1;
		}
	}

	registerPromise(idQuestion: number) {
		if (idQuestion === 6.3) {
			this.otherFormPay = this.formJson.find(x => x.idQuestion === 6.3).additionalAnswer;
			this.applyClassFormPay();
			this.replaceAmountPromise(this.selectValue);
			this.activateButton();
			this.getFormJson();

			var nextQuestionObj = this.formJson.filter(x => x.idQuestion == 6.4);
			this.questionsJson.push(...nextQuestionObj);

			if (this.selectValue == "Depósito en cuenta" || this.selectValue == "Transferencia electrónica" || this.selectValue == "Tarjeta de crédito/ débito") {
				var nextObj = this.formJson.filter(x => x.idQuestion == 6.5);
				this.questionsJson.push(...nextObj);

			} else {
				var nextObj = this.formJson.filter(x => x.idQuestion == 6.5);
				var filteredScripts = nextObj.map(x => {
					const filteredScripts = x.scripts.filter(script => script.node <= 3 || (script.node >= 8 && script.node <= 9));
					return { ...x, scripts: filteredScripts };
				});
				this.questionsJson.push(...filteredScripts);
			}

		}
		this.isPromiseRegistered = true;

		if (this.isButtonsP) {
			this.isButtonsP = false;
		}
	}

	getFormJson() {
		this.callCenterService.getCallData(this.externalId).subscribe(
			(result) => {
				this.callCenterData = result;
				this.formJson = this.callCenterData.formQuestionJson;
			});
	}

	getMask(inputType: string | null): any {
		if (inputType === 'Currency') {
			return {
				mask: this.currencyMask,
				pipe: this.currencyFormatPipe
			};
		} else if (inputType === 'Number') {
			return {
				mask: this.phoneMask,
			};
		} else if (inputType === 'Text') {
			return {
				mask: false,
			};
		} else if (inputType === null) {
			return {
				mask: false,
			};
		}
	}

	currencyFormatPipe(value: string): string {
		const numericValue = value.replace(/[^\d.-]/g, '');

		const numberValue = parseFloat(numericValue);

		if (!isNaN(numberValue)) {
			const formattedValue = numberValue.toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2
			});
			return formattedValue;
		} else {
			return '';
		}
	}


	applyClass() {
		const currencyFormatter = new Intl.NumberFormat('es-MX', {
			style: 'currency',
			currency: 'MXN',
			minimumFractionDigits: 2
		});
		if (this.formQuestionJson) {
			const replacements = {
				"@FullNameLoan": this.loanFullName,
				"@Agent": this.lenderNameAgent,
				"@Welcome": this.welcome,
				"@LenderFullName": this.nameCall,
				"@PhoneMobile": this.callCenterData.phone,
				"@AdressLoan": this.callCenterData.addressLoan,
				"@PhoneList": this.callCenterData.phoneList,
				"@DaysLate": this.callCenterData.daysLate,
				"@CapitalExpired": currencyFormatter.format(this.callCenterData.totalBalanceLate),
				"@FirstDatePayment": this.callCenterData.firstDatePayment,
				"@StartDate": this.callCenterData.startDate,
				"@LoanAmount": currencyFormatter.format(this.callCenterData.loanAmount)
			};
			this.formQuestionJson.forEach(item => {
				if (item.scripts) {
					item.scripts.forEach(script => {
						Object.entries(replacements).forEach(([tag, value]) => {
							if (script.speech.includes(tag)) {
								const regex = new RegExp(tag, 'g');
								script.speech = script.speech.replace(
									regex,
									matchedTag => (matchedTag === tag ? `<span class='fw-bold'>${value}</span>` : matchedTag)
								);
							}
						});
					});
				}
				if (item.question && item.question.includes("@FullNameLoan")) {
					item.question = item.question.replace(
						"@FullNameLoan",
						`${this.loanFullName}`
					);
				} if (item.question && item.question.includes("@LenderFullName")) {
					item.question = item.question.replace(
						"@LenderFullName",
						`${this.nameCall}`
					);
				}
				if (item.question && item.question.includes("@LenderFullName")) {
					item.question = item.question.replace(
						"@LenderFullName",
						`${this.nameCall}`
					);
				}
			});
		}

		if (this.anwersManagementQuestions) {
			this.anwersManagementQuestions.forEach(item => {
				if (item.question.includes("@LenderFullName")) {
					item.question = item.question.replace(
						"@LenderFullName",
						`<span class='fw-bold'>${this.nameCall}</span>`
					);
				}
				if (item.question.includes("@StartDate")) {
					const formattedStartDate = this.datePipe.transform(this.callCenterData.startDate, 'dd MMM yyyy');
					item.question = item.question.replace(
						"@StartDate",
						`<span class='fw-bold'>${formattedStartDate}</span>`
					);
				}
				if (item.question.includes("@LoanAmount")) {
					const formattedLoanAmount = currencyFormatter.format(this.callCenterData.loanAmount);
					item.question = item.question.replace(
						"@LoanAmount",
						`<span class='fw-bold'>${formattedLoanAmount}</span>`
					);
				}
			});
		}
	}

	onInputChange(value: string, idQuestion: number) {
		this.amountPromise = value;
	}

	applyClassFormPay() {
		if (this.formJson) {
			const replacements = {
				"@PromiseScript": this.formPayData,
				"@Bank": this.bank,
				"@AccountNumber": this.accountBank,
				"@Clabe": this.clabeBank,
				"@CardNumber": this.cardNumber,
				"@Other": this.otherFormPay
			};
			this.formJson.forEach(item => {
				if (item.scripts) {
					item.scripts.forEach(script => {
						Object.entries(replacements).forEach(([tag, value]) => {
							if (script.speech.includes(tag)) {
								const regex = new RegExp(tag, 'g');
								script.speech = script.speech.replace(
									regex,
									matchedTag => (matchedTag === tag ? `${value}` : matchedTag)
								);
							}
						});
					});
				}
			});
		}
	}

	replaceAmountPromise(selectValue) {
		this.formattedDate = this.datePipe.transform(this.selectedDate, 'dd MMM yyyy');
		this.formJson.forEach(item => {
			if (item.scripts) {
				item.scripts.forEach(script => {
					if (script.speech.includes("@AmountPromise")) {
						script.speech = script.speech.replace(
							"@AmountPromise",
							`<span class='fw-bold'>${this.amountPromise.includes('.') ? this.amountPromise : this.amountPromise + '.00'}</span>`
						);
					}
					if (script.speech.includes("@DatePromise")) {
						script.speech = script.speech.replace(
							"@DatePromise",
							`<span class='fw-bold'>${this.formattedDate}</span>`
						);
					}
					if (script.speech.includes("@HalfPay")) {
						script.speech = script.speech.replace(
							"@HalfPay",
							`<span class='fw-bold'>${selectValue}</span>`
						);
					}
					if (script.speech.includes("@Agent")) {
						script.speech = script.speech.replace(
							"@Agent",
							`<span class='fw-bold'>${this.lenderNameAgent}</span>`
						);
					}
					if (script.speech.includes("@LenderFullName")) {
						script.speech = script.speech.replace(
							"@LenderFullName",
							`<span class='fw-bold'>${this.nameCall}</span>`
						);
					}
				});
			}
		});
	}

	activateButton(): void {
		this.activeButton = true;
	}

	nextQuestions(nextQuestion: number, idQuestion: number) {
		let nextQuestionObj;

		this.clearAnswerAndAdditionalAnswer(idQuestion)

		if (nextQuestion === 2.0) {
			nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion >= 2.1 && x.idQuestion <= 2.2);
		} else if (nextQuestion === 5.0) {
			nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion == 5.1);
			this.nextQuestion = nextQuestion;
			this.isPromiseRegistered = false;
			this.booleanRasosnsNoPay = true;
			this.isButtonsP = true;
		} else if (nextQuestion === 6.0) {
			nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion >= 6.0 && x.idQuestion <= 6.3);
			this.nextQuestion = nextQuestion;
		} else if (nextQuestion === 7.0) {
			nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion >= 7.0 && x.idQuestion <= 7.9);
			this.activateButton();
			this.nextQuestion = nextQuestion;
		} else if (nextQuestion === 3.0) {
			nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion >= 3.0 && x.idQuestion <= 3.6);
			this.nextQuestion = nextQuestion;
		} else if (nextQuestion === 12.0) {
			nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion >= 12.0 && x.idQuestion <= 12.2);
			this.nextQuestion = nextQuestion;
		} else if (nextQuestion === 10.2) {
			nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion >= 10.2 && x.idQuestion <= 10.2);
			this.nextQuestion = nextQuestion;
		} else {
			nextQuestionObj = this.formQuestionJson.filter(x => x.idQuestion >= nextQuestion && x.idQuestion <= nextQuestion + 1);
			this.nextQuestion = nextQuestion;
		}

		this.questionsJson = this.questionsJson.filter(x => x.idQuestion <= idQuestion);
		this.questionsJson.push(...nextQuestionObj);
	}


	promiseReplace(idQuestion: number) {
		const filteredQuestions = this.questionsJson.filter(x => x.idQuestion >= 6.4 && x.idQuestion <= 6.5);
		const indexToRemove = this.questionsJson.indexOf(filteredQuestions[0]);

		if (indexToRemove > -1) {
			this.questionsJson.splice(indexToRemove, 2);
		}
		this.isPromiseRegistered = false;
		this.isButtonsP = true;
	}

	callRatingModal() {
		this.dateEndtManagement = new Date();

		const findAnswer = this.questionsJson.find(x => x.idQuestion == 2.2 && x.answer == "Si");
		const findAnswerQ = this.questionsJson.find(x => x.idQuestion == 8.2 && x.answer == "Si");

		if ((findAnswer || findAnswerQ)) {

			this.starFormQuestion = true;
			this.setCallRating(true);
		} else {

			this.setCallRating(true)
		}

		this.addFileInput()
	}

	processAssessmentQuestions(idQuestion: number, answer: any): void {
		const assessmentQuestions = this.formJson.filter(x => x.idQuestion === idQuestion);
		assessmentQuestions.forEach(question => {
			this.history.push({
				idQuestion: question.idQuestion,
				orderQuestion: question.orderQuestion,
				question: question.question,
				answer: answer,
				additionalAnswer: question.additionalAnswer
			});
		});
	}

	reasonNoPaymentFunction() {
		this.clearAnswerAndAdditionalAnswer(5.1);
		if (this.inputAnswerObjetion === 'RadioInput') {
			this.answersRasosnsNoPay = [{
				Answer: this.radioRadioInput,
				Amount: this.inputRadioInput
			}]
		}
		if (this.inputAnswerObjetion === 'DatePay') {
			this.answersRasosnsNoPay = [{
				Amount: this.mountDatePay,
				Date: this.datePipe.transform(this.dateDatePay, 'dd/MM/yyyy'),
				Form: this.formPayDatePay,
				OtherForm: this.otherFormPayDatePay,
			}]
		}
		if (this.inputAnswerObjetion === 'Input') {
			this.answersRasosnsNoPay = [{
				Amount: this.inputInputThree
			}]
		}
		if (this.inputAnswerObjetion === 'TextAreaDate') {
			this.answersRasosnsNoPay = [{
				Comments: this.inputTextAreaDate,
				Date: this.datePipe.transform(this.dateTextAreaDate, 'dd/MM/yyyy'),
			}]
		}

		if (this.inputAnswerObjetion === 'Datepicker') {
			this.answersRasosnsNoPay = [{
				Date: this.datePipe.transform(this.dateDateFive, 'dd/MM/yyyy'),
			}]
		}

		if (this.inputAnswerObjetion === 'DateNumber') {
			this.answersRasosnsNoPay = [{
				Date: this.datePipe.transform(this.dateDateNumber, 'dd/MM/yyyy'),
				FormContact: this.formContactDateNumber
			}]
		}
	}

	reasonNoPaymentAnswers(idQuestion: number, additionalAnswer: any): void {
		const index = this.history.findIndex(x => x.idQuestion === idQuestion);
		if (index !== -1) {
			this.history[index].additionalAnswer = additionalAnswer;
		}
	}

	urlArchives(idQuestion: number, additionalAnswer: any[]): void {
		const assessmentQuestions = this.formJson.filter(x => x.idQuestion === idQuestion);
		assessmentQuestions.forEach(question => {
			this.history.push({
				idQuestion: question.idQuestion,
				orderQuestion: question.orderQuestion,
				question: question.question,
				answer: question.answer,
				additionalAnswer: additionalAnswer
			});
		});
	}

	dialogExternalStatus() {
		const dialogEstatus = this.dialog.open(DialogShowCallRatingComponent, {
			autoFocus: false,
			height: '23%',
			width: '25%',
			disableClose: true,
			data: {
				externalStatus: this.externalStatus,
				responseCall: this.totalNumberCalls,
			}
		});

		dialogEstatus.afterClosed().subscribe((result) => {
			if (result) {
				this.externalStatus = result.externalStatus;
				this.getResponses();
			}
		});
	}



	getResponses() {
		const answeredQuestions = this.formQuestionJson.filter(question => question.answer || question.additionalAnswer);

		this.history = answeredQuestions.map(question => {
			if (question.type === 'Datepicker') {
				return {
					idQuestion: question.idQuestion,
					orderQuestion: question.orderQuestion,
					question: question.question,
					answer: this.datePipe.transform(question.answer, 'dd/MM/yyyy'),
					additionalAnswer: question.additionalAnswer ? [{ hour: question.additionalAnswer }] : null
				};
			} else {
				return {
					idQuestion: question.idQuestion,
					orderQuestion: question.orderQuestion,
					question: question.question,
					answer: question.answer,
					additionalAnswer: question.additionalAnswer ? [{ Answer: question.additionalAnswer }] : null
				};
			}
		});

		if (this.booleanRasosnsNoPay == true) {
			this.reasonNoPaymentAnswers(5.1, this.answersRasosnsNoPay);

		}
		var valueSelect = this.selectedRating.toString();
		var valueStar = this.selectedRatingStar.toString();
		this.processAssessmentQuestions(13.5, valueSelect);
		this.processAssessmentQuestions(14.5, valueStar);
		this.processAssessmentQuestions(17.5, this.totalNumberCalls.toString());

		if (this.comentts != "") {
			this.processAssessmentQuestions(15.5, this.comentts);
		}

		if (this.dataResponse.length !== 0) {
			this.urlArchives(16.5, this.dataResponse);
		}
		const createCall: CreateCall = {
			dateCreated: new Date(Date.now()),
			dateStartManagement: this.dateStartManagement,
			dateEndManagement: this.dateEndtManagement,
			agent: this.lenderNameAgent,
			phone: this.selectPhone,
			callTypeName: this.typeCall,
			contactTypeName: this.callCenterData.contactTypeName,
			questions: this.history
		}

		this.callCenterService.createCall(this.externalId, createCall, this.externalStatus).subscribe(
			(result) => {
				this.router.navigate(['/centro-de-llamadas']);
			}
		);
	}

	selectPhoneCall(row: any) {
		if (this.selectPhone === row.phone) {
			this.selectPhone = null;
		} else {
			this.selectPhone = row.phone + ' - ' + row.phoneType;
			this.buttonClicked = false;
		}
	}

	truncatePhone(phone: string): string {
		return phone.substring(0, 10);
	}

	return() {
		this.router.navigate(['/centro-de-llamadas/']);
	}

	setCallRating(val: boolean) {
		this.callRating = val;
		if (this.callRating) {
			this.callScript = "Evaluación de llamada Cobranza"
		}

	}

	evaluations() {
		const selectIntentionPay = {
			0: "Nula, No se logró hacer contacto.",
			1: "Muy Baja, Expresa negativa a realizar el pago o no reconoce adeudo. No se obtuvo promesa de pago.",
			2: "Baja, Expresa dificultad para realizar el pago. No se obtuvo promesa de pago y/o no muestra disposición para contactar al acreedor.",
			3: "Media, No expresa negativa ni voluntad para realizar el pago. No se obtuvo promesa de pago y/o si muestra disposición para contactar al acreedor.",
			4: "Alta, Expresa voluntad para realizar el pago. Se obtuvo promesa de pago parcial y/o muestra disposición para contactar al acreedor.",
			5: "Muy Alta, Expresa muy buena voluntad para realizar el pago a la brevedad posible. Se obtuvo promesa de pago completo."
		}

		const selectCall = {
			0: "Nula, No se logró hacer contacto.",
			1: "Negativa Alta, Se recibió la llamada de manera hostil o agresiva, expresando claramente su descontento o molestia desde el inicio de la conversación.",
			2: "Negativa Moderada, Se recibió la llamada con cierta resistencia o disgusto, pero se mantuvo la conversación sin llegar a un punto de confrontación.",
			3: "Neutral, Se recibió la llamada de manera estándar, sin expresar abiertamente molestia o interés.",
			4: "Positiva Moderada, Se recibió la llamada con cierta amabilidad, se mantuvo la conversación mostrando cierto interés.",
			5: "Positiva Alta, Se recibió la llamada con amabilidad mostrando interés en mantener una conversación abierta al diálogo."
		}

		const selectNumberCall = {
			0: "Nula, No se logró hacer contacto.",
			1: "Muy Fácil, Se logró hacer contacto al primer intento.",
			2: "Fácil, Se logró hacer contacto después de 2 intentos.",
			3: "Media, Se logró hacer contacto después de 3 intentos.",
			4: "Difícil, Se logró hacer contacto después de 4 intentos.",
			5: "Muy Difícil, Se logró hacer contacto después de 5 intentos."
		}

		if (this.selectedRating) {
			this.answerSelectCall = selectCall[this.selectedRating];

		} else {
			this.answerSelectCall = selectCall[this.hoveredRatingCall];
		}
		if (this.selectedRatingStar) {

			this.answerSelectPay = selectIntentionPay[this.selectedRatingStar];
		} else {
			this.answerSelectPay = selectIntentionPay[this.hoveredRating];
		}
		if (this.totalNumberCalls) {

			this.answerNumberCall = selectNumberCall[this.totalNumberCalls];
		} else {
			this.answerNumberCall = selectNumberCall[this.hoverNumberCalls];
		}

	}

	selectRatingCall(rating: number, type: string) {
		if (type === 'rating') {
			this.selectedRating = rating;
		} else if (type === 'star') {
			this.selectedRatingStar = rating;
		} else if (type === 'numberCall') {
			this.totalNumberCalls = rating;
		}
	}

	openRating() {
		this.nextSection = true;
		this.evaluations();
	}

	endcall() {
		if (this.nextSection) {
			const questionValidate = this.formQuestionJson.find(question => question.idQuestion == "2").answer
			if (questionValidate == "Si") {
				this.dialogExternalStatus();
			} else {
				this.externalStatus = 1;
				this.getResponses();
			}
		}
	}

	onCallHover(value: number): void {
		this.hoverNumberCalls = value;
		this.evaluations();
	}
	onStarHover(star: number): void {
		this.hoveredRating = star;
		this.evaluations();
	}

	onRatingHoveRrating(rating: number): void {
		this.hoveredRatingCall = rating;
		this.evaluations();
	}
	resetHover(): void {
		this.hoveredRating;
		this.hoveredRatingCall;
		this.hoverNumberCalls = this.totalNumberCalls;
		this.evaluations();
	}

	addFileInput(): void {
		this.documents.push({ "id": 0, "url": "" });
		if (this.documents.length == 4) {
			this.alertGlobalService.showAlertWarning("Se ha alcanzado el límite de 4 imágenes. No se pueden cargar más imágenes.");
		}
	}

	validateFile(input: HTMLInputElement): boolean {
		if (input.files && input.files.length > 0) {
			const allowedExtensions = ['.jpg', '.jpeg', '.png'];
			const fileExtension = input.files[0].name.toLowerCase().slice((Math.max(0, input.files[0].name.lastIndexOf(".")) || Infinity) + 1);
			if (allowedExtensions.indexOf(`.${fileExtension}`) === -1) {
				return false;
			}
		}
		return true;
	}

	selectFile(event, index): void {
		var count = index + 1;
		const input = event.target as HTMLInputElement;

		if (!this.validateFile(input)) {
			this.alertGlobalService.showAlertWarning("Imagen no válida");
			return;
		}

		if (input && input.files) {
			const photoArray = input.files[0];
			const fileOriginalName = photoArray.name;
			const loanRequestDate: Date = new Date();
			let loanReqId = "";

			if (this.loanRequestId != null) {
				loanReqId = this.loanRequestId;
			}

			this.spinnerService.requestStarted();

			this.documentServiceService
				.UploadOtherFiles(
					loanReqId,
					'CallCenterPhoto',
					loanRequestDate,
					photoArray,
					'CallCenter',
					fileOriginalName,
					count
				)
				.then((data) => {
					const currentDir = data;
					if (currentDir) {
						this.documents[index] = { "id": count, "url": currentDir.url }
						this.dataResponse.push(currentDir);
						this.uploadFile[index] = count;
					}
					if (this.documents.length < 4) {
						this.addFileInput();
					}
					this.spinnerService.requestEnded();
				})
				.catch((error) => {
					console.error('Error al cargar el archivo:', error);
					this.spinnerService.requestEnded();
				});
		}
	}


	openImage(index) {
		var count = index + 1

		var url = this.documents.find(x => x.id == count).url

		const domainRegex = /^(?:https?:\/\/)?[^/]+/i;
		url = url.replace(domainRegex, '');


		if (environment.production == true) {
			url = "https://storage-prod.a-lender.com" + url;
		} else {
			url = "https://storage-dev.a-lender.com" + url;
		}
		var fileImage = url

		if (fileImage != "") {

			const dialogRef = this.dialog.open(DialogPreviewDocumentComponent, {
				autoFocus: false,
				width: '80%'
			});
			let instance = dialogRef.componentInstance;
			instance.url = fileImage;
			instance.extensionFile = /[^.]+$/.exec("foto.jpg")[0];
		}
	}

	deleteImage(index: number) {
		var count = index + 1
		var url = this.documents.find(x => x.id == count).url

		const domainRegex = /^(?:https?:\/\/)?[^/]+/i;
		url = url.replace(domainRegex, '');

		const match = url.match(/\/([^/]+)$/);

		if (match && match[1]) {
			var fileSaved = match[1];
		}

		var fileSavedName = fileSaved;
		var documentId = url;

		this.documentServiceService.deleteDocumentArchive(
			fileSavedName,
			documentId
		)
			.then((data) => {

			})
			.catch((error) => {
				console.error('Error al cargar el archivo:', error);
			});


		this.uploadFile[index] = false;
		this.documents.splice(index, 1);
		this.dataResponse.splice(index, 1);
	}

	getColor(index: number): string {
		return this.colors[index % this.colors.length];
	}
	// Importa la librería para calcular la similitud entre strings

	verificarOrtografia(): void {
		const palabras = this.comentts.split(/\s+/); // Dividimos el texto en palabras
		for (let i = 0; i < palabras.length; i++) {
			const palabra = palabras[i]; // La palabra original
			const palabraNormalizada = this.normalizarPalabra(palabra); // Normalizamos la palabra
			const palabraCorregida = this.corregirPalabra(palabraNormalizada); // Corregimos la palabra si es necesario
			if (palabraCorregida) {
				palabras[i] = palabraCorregida; // Reemplazamos la palabra en el array
			}
		}
		// Actualizamos el texto del textarea con las palabras corregidas
		this.comentts = palabras.join(' ');
	}

	corregirPalabra(palabra: string): string | null {
		const palabraEncontrada = this.diccionario.find(palabraDiccionario => this.normalizarPalabra(palabraDiccionario) === palabra);
		if (palabraEncontrada) {
			return palabraEncontrada; // Devolvemos la palabra del diccionario si la encontramos
		} else {
			// Si no encontramos una coincidencia exacta, buscamos palabras similares
			const resultados = stringSimilarity.findBestMatch(palabra, this.diccionario);
			const mejorCoincidencia = resultados.bestMatch.target;
			// Solo devolvemos la mejor coincidencia si la similitud es mayor a un cierto umbral
			if (resultados.bestMatch.rating > 0.5) {
				return mejorCoincidencia;
			} else {
				return null; // Si no encontramos ninguna palabra similar, devolvemos null
			}
		}
	}

	normalizarPalabra(palabra: string): string {
		// Esta función normaliza la palabra para ignorar los acentos al hacer la comparación
		return palabra.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
	}

	updateMaxCaract() {
		this.lengCaracte = this.maxLength - this.comentts.length;
	}

}
