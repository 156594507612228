import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { ValidateAccountService } from 'src/app/services/validate-account/validate-account.service';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { CustomErrorStateMatcher } from 'src/app/functions/custom-error';
import { CompleteAccount } from 'src/app/models/user/completeaccount.model';
import { MatStepper } from '@angular/material/stepper';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {
	mode: string;
	countries: any;
	currencies: any;
	personTypes: any;

	steptIndex: number = 0;

	selectActorForm: FormGroup;
	createAccountForm: FormGroup;
	validateAccountForm: FormGroup;
	personalDataForm: FormGroup;
	formPhoneValidation: FormGroup;

	selectActorFormSubmitted = false;
	createAccountFormSubmitted = false;
	validateAccountSubmitted = false;
	personalDataFormSubmitted = false;

	matcher = new CustomErrorStateMatcher();
	maxBirthdayDate = new Date();
	xsMode = false; //smartphone
	mdMode = false; //tablet
	hide = true;
	hideConfirm = true;

	requiredErrorMessage: string = "Este campo es requerido.";

	@ViewChild('stepperSignup') stepperSignup: MatStepper;

	constructor(private formBuilder: FormBuilder,
		private userService: UserService,
		private authService: AuthenticationService,
		private router: Router,
		private validateAccountService: ValidateAccountService,
		private catalogService: CatalogService) {
			// redirect to home if already logged in
			if (this.authService.currentUserValue) { 
				this.router.navigate(['/inicio']);
			}

			this.startSelectActorForm();
			this.startCreateAccountForm();
			this.startPersonalDataForm();
			this.createFormPhoneValidation();
		}

	startSelectActorForm() {
		this.selectActorForm = this.formBuilder.group({
			completed: [false, Validators.requiredTrue ],
		});
	}

	startCreateAccountForm() {
		this.createAccountForm = this.formBuilder.group({
			userName: ['', [Validators.required, Validators.email] ],
			password: ['', Validators.required ],
			confirmPassword: ['', Validators.required],
			acceptTerms: [false, Validators.requiredTrue ],
			completed: [false, Validators.requiredTrue ],
		},
		{
			validator: this.checkPasswords
		});
	}

	startValidateAccountForm() {
		this.validateAccountForm = this.formBuilder.group({
			completed: [false, Validators.requiredTrue ],
		});
	}

	startPersonalDataForm() {
		this.personalDataForm = this.formBuilder.group({
			firstName: ['', [Validators.required, Validators.maxLength(50)] ],
			middleName: ['', [Validators.maxLength(50)] ],
			lastName1: ['', [Validators.required, Validators.maxLength(50)] ],
			lastName2: ['', [Validators.maxLength(50)] ],
			birthdayDate: ['', Validators.required ]
		});
	}

	createFormPhoneValidation() {
		this.formPhoneValidation = this.formBuilder.group({
			code: ['', Validators.required]
		});
	}

	ngOnInit(): void {
		this.getCountries();
		this.getCurrencies();
		this.getPersonTypes();

		this.checkValidationAccount();

		this.setActorInitial('lender');
		this.xsMode = window.innerWidth <= 576;
		this.mdMode = window.innerWidth > 576 && window.innerWidth <= 768;
	}

	ngAfterViewInit(): void {
		var buttonSetLender: HTMLElement = document.getElementById('buttonSetLender') as HTMLElement;
		var buttonSetBorrower: HTMLElement = document.getElementById('buttonSetBorrower') as HTMLElement;
		
		if (window.location.pathname.includes("lender")) {
			localStorage.setItem('activateInitialUserMode',"Lender");
			buttonSetLender.click();
		} else if (window.location.pathname.includes("borrower")) {
			localStorage.setItem('activateInitialUserMode', "borrower");
			buttonSetBorrower.click();
		}
	}

	getCountries() {
		this.catalogService.getCountries()
		.subscribe((data) => {
			this.countries = data;
		});
	}

	getCurrencies() {
		this.catalogService.getCurrencies()
		.subscribe((data) => {
			this.currencies = (data as any).filter(x => ['MXN', 'COP', 'CAD', 'USD'].includes(x.code));
		});
	}

	getPersonTypes() {
		this.catalogService.getPersonTypes()
			.subscribe((personTypes) => {
			this.personTypes = personTypes;
			});
	}

	get f_selectActorForm() { return this.selectActorForm.controls; }
	get f_createAccountForm() { return this.createAccountForm.controls; }
	get f_personalDataForm() { return this.personalDataForm.controls; }
	get f_formPhoneValidation() { return this.formPhoneValidation.controls; }

	onSubmitSelectActorFormSubmitted() {
		this.selectActorFormSubmitted = true;

		if (this.selectActorForm.invalid) {
			return;
		}
	}

	onSubmitCreateAccountForm() {
		this.createAccountFormSubmitted = true;

		this.f_createAccountForm['completed'].patchValue(true);

		if (this.createAccountForm.invalid) {
			this.f_createAccountForm['completed'].patchValue(false);
			return;
		}

		const user = <User> {
			userName: this.createAccountForm.value.userName + "",
			phoneNumber: "",
			password: this.createAccountForm.value.password,
			firstName: "",
			middleName: "",
			lastName1: "",
			lastName2: "",
			birthdayDate: "1900-01-01T00:00:00",
			countryPhoneCode: "52",
			countryCode: "MX",
			languageCode: "es",
			currencyCode: "MXN",
			personType: 612, // persona física
			isLender: localStorage.getItem('userModeLender') === 'true',
			createNoUser: false
		};
		
		this.userService.create(user).subscribe(data => {
			this.validateAccountService.setEmailObserver(user.userName);
			
			this.steptIndex = 1;
		});
	}

	onSubmitPersonalDataForm() {
		this.personalDataFormSubmitted = true;

		if (this.personalDataForm.invalid) {
			return;
		}

		const completeAccountData = <CompleteAccount> {
			firstName: this.f_personalDataForm['firstName'].value,
			middleName: this.f_personalDataForm['middleName'].value,
			lastName1: this.f_personalDataForm['lastName1'].value,
			lastName2: this.f_personalDataForm['lastName2'].value,
			birthdayDate: this.f_personalDataForm['birthdayDate'].value,
		};

		var email = "";
		this.validateAccountService.getEmailObserver()
			.subscribe((data) => {
			email = data;
			});

		this.userService.completeAccount(email, completeAccountData)
		.subscribe(data => {
			this.validateAccountService.setEmailObserver(null);
			//redirect to login
			setTimeout(function () {
			window.location.href = "/iniciar-sesion";
			}, 2000);
		});
	}

	onReset() {
		this.createAccountForm.reset();
		this.personalDataForm.reset();
	}

	checkPasswords(group: FormGroup) {
		let password = group.get('password').value;
		let confirmPassword = group.get('confirmPassword').value;

		return password === confirmPassword ? null : { notSame: true };   
	}

	checkValidationAccount() {
		this.validateAccountService.getValidationSuccessObserver()
			.subscribe((success) => {
			if (success) {
				this.steptIndex = 2;
			}
		});
	}

	setActorInitial(actor: string): void {
		if (actor === "lender") {
			this.authService.changeUserModeLenderValue(true);
		}

		if (actor === "borrower") {
			this.authService.changeUserModeLenderValue(false);
		}

		this.f_selectActorForm['completed'].patchValue(true);
	}

	setMail() {
		var val = this.f_createAccountForm['userName'].value + "";

		this.f_createAccountForm['userName'].patchValue(val.trim())
	}

	getMaxDate() {
		return new Date()
	}

	getClass() {
	  if (this.hide) {
		return 'fa fa-eye';
  
	  } else {
		return 'fa fa-eye-slash';
	  }
	}

	getClassC() {
	  if (this.hideConfirm) {
		return 'fa fa-eye';
  
	  } else {
		return 'fa fa-eye-slash';
	  }
	}

	validateRequiredForm(formControlName: string): boolean {
		if(this.f_createAccountForm[formControlName].errors !== null && this.f_createAccountForm[formControlName].errors['required']) {
		  return true;
		}
		return false;
	}
}