<h1 mat-dialog-title>Comprobante</h1>

<div mat-dialog-content>
    <div *ngIf="rowSubscriptionPaymentSelected" id="content">
        <div>
            <div class="p1 w-100 flex-wrap">
                <div class="d-flex">
                    <div>
                        <img *ngIf="rowSubscriptionPaymentSelected.methodPayment != 'OXXO Pay'" src="https://storage-dev.a-lender.com/files/images/credit_card.svg" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                        <img *ngIf="rowSubscriptionPaymentSelected.methodPayment == 'OXXO Pay'" src="https://storage-dev.a-lender.com/files/images/bar_code.svg" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                    </div>
                    <div>
                        <div>
                            MONTO PAGADO
                        </div>
                        <div>
                            {{rowSubscriptionPaymentSelected.amount | currency}} MXP
                        </div>
                    </div>
                </div>
                <div>
                    a-lender
                </div>
                <div>
                    Apoyo Soluciones Productivas, S.A.P.I. de C.V.
                </div>
                <div>
                    ASP080626KY8
                </div>
                <div>
                    Lázaro Cárdenas No. 2400 Pte., Of. C31-4
                </div>
                <div>
                    Col. Residencial San Agustín
                </div>
                <div>
                    San Pedro Garza García, N.L.
                </div>
                <div>
                    C.P. 66269
                </div>
            </div>
            <div class="p1">
                <div *ngIf="rowSubscriptionPaymentSelected.methodPayment != 'OXXO Pay'">
                    NUMERO DE TARJETA: ****{{rowSubscriptionPaymentSelected.numberCard}}
                </div>
                <div *ngIf="rowSubscriptionPaymentSelected.methodPayment == 'OXXO Pay'">
                    PAGADO EN OXXO
                </div>
            </div>
            <div class="p1">
                <div>
                    Fecha pago: {{rowSubscriptionPaymentSelected.subscripcionStartDate | date:'medium'}}
                </div>
                <div>
                    Plan: {{rowSubscriptionPaymentSelected.description}}
                </div>
                <div>
                    Período: {{rowSubscriptionPaymentSelected.periodQuantity}} {{rowSubscriptionPaymentSelected.idPeriod == 1 ? 'Mensual' : 'Anual'}}
                </div>
                <div>
                    Vencimiento: {{rowSubscriptionPaymentSelected.subscripcionEndDate | date:'medium'}}
                </div>
            </div>
            <div class="p1 w-100 flex-wrap">
                <div>
                    Gracias por su pago, dudas o comentarios: hola@a-lender.com
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button class="btn btn-outline-primary external-btn" mat-dialog-close>
        Cerrar
    </button>
    <button class="btn btn-outline-primary external-btn" (click)="print()">
        Imprimir
    </button>
</div>