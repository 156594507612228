import { Component, Input, OnInit } from '@angular/core';
import { SummaryAccountStatement } from 'src/app/models/account-statement/summaryaccountstatement.dto.model';
import { AccountStatementService } from 'src/app/services/account-statement/account-statement.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ChartData, ChartType } from 'chart.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  summary: SummaryAccountStatement;
  @Input() topNav: boolean = true;

  constructor(public authService: AuthenticationService,
    private router: Router,
    private accountStatementService: AccountStatementService) { }

  isLender = false;

  c_primary: string = "#3F2A56";
  c_primary_opacity: string = "#8573A8";
  c_primary_soft: string = "#AC9BC6";
  c_primary_light: string = "#DBD3EA";

  public chartType: ChartType = 'doughnut'
  public options = {
    plugins: {
      legend: {
        display: false,
      }
    }
  };

  //loanRequest graphics
  public loanRequestChartLabels: string[] = ['En proceso', 'Autorizadas'];
  public loanRequestChartData: ChartData<'doughnut'> = {
    labels: this.loanRequestChartLabels,
    datasets: [
      {
        label: "Monto",
        data: [0, 0],
        backgroundColor: [this.c_primary_soft, this.c_primary],
        hoverOffset: 4
      }
    ]
  };

  //loan graphics
  public loanChartLabels: string[] = [!this.isLender ? 'Otorgados' : 'Recibidos', 'Liquidados'];
  public loanChartData: ChartData<'doughnut'> = {
    labels: this.loanChartLabels,
    datasets: [
      {
        label: "Monto",
        data: [0, 0],
        backgroundColor: [this.c_primary_soft, this.c_primary],
        hoverOffset: 4
      }
    ]
  };

  //payments graphics
  public paymentsChartLabels: string[] = [!this.isLender ? 'Recibidos' : 'Realizados', 'Liquidados'];
  public paymentsChartData: ChartData<'doughnut'> = {
    labels: this.paymentsChartLabels,
    datasets: [
      {
        label: "Monto",
        data: [0, 0],
        backgroundColor: [this.c_primary_soft, this.c_primary],
        hoverOffset: 4
      }
    ]
  };

  //balance graphics
  public balanceChartLabels: string[] = ['Sin atraso', 'Con atraso'];
  public balanceChartData: ChartData<'doughnut'> = {
    labels: this.balanceChartLabels,
    datasets: [
      {
        label: "Monto",
        data: [0, 0],
        backgroundColor: [this.c_primary_soft, this.c_primary],
        hoverOffset: 4
      }
    ]
  };

  ngOnInit(): void {
    this.authService.userModeLender.subscribe((data) => {
      this.isLender = data;

      if (this.authService.currentUserValue) {
        this.accountStatementService.getSummary(this.authService.currentUserValue.customerId, this.isLender).subscribe((summaryData) => {
          this.summary = summaryData;

          this.authService.setCurrentBalanceValue(this.summary.currentBalance);

          this.loanRequestChartData.datasets[0]['data'] = [
            this.summary.sq_LoanAmount_process,
            this.summary.sq_LoanAmount_authorized
          ];

          this.loanChartData.datasets[0]['data'] = [
            this.summary.sq_SumLoanAmount,
            this.summary.sq_SumLoanAmountLiquidated
          ];

          this.paymentsChartData.datasets[0]['data'] = [
            this.summary.sq_SumPaymentAmount,
            this.summary.sq_SumPaymentAmountLiquidated
          ];

          this.balanceChartData.datasets[0]['data'] = [
            this.summary.sq_BalanceWithoutArrears,
            this.summary.sq_BalanceWithArrears
          ];
        });
      }
    });
  }
}
