import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { UserLogged, UsserIP } from '../../models/user/userlogged.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserLogged>;
  public currentUser: Observable<UserLogged>;
  public userModeLenderSubject: BehaviorSubject<boolean>;
  public userModeLender: Observable<boolean>;
  public currentIPSubject: BehaviorSubject<any> | null;
  public currentIP: Observable<any>;
  public currentDialogActivateSubject: BehaviorSubject<boolean>;
  public currentDialogActivate: Observable<boolean>;
  private currentBalanceSubject: BehaviorSubject<number>;
  public currentBalance: Observable<number>;

  constructor(private apiService: ApiService) {
    this.currentUserSubject = new BehaviorSubject<UserLogged>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
    this.userModeLenderSubject = new BehaviorSubject<boolean>(localStorage.getItem('userModeLender') === 'true');
    this.userModeLender = this.userModeLenderSubject.asObservable();
    this.currentIPSubject = new BehaviorSubject<UsserIP>(null);
    this.currentIP = this.currentIPSubject.asObservable();
    this.currentDialogActivateSubject = new BehaviorSubject<boolean>(false);
    this.currentDialogActivate = this.currentDialogActivateSubject.asObservable();
    this.currentBalanceSubject = new BehaviorSubject<number>(0);
    this.currentBalance = this.currentBalanceSubject.asObservable();
  }

  public get currentUserValue(): UserLogged {
    return this.currentUserSubject.value;
  }

  public get currentUserModeLenderValue(): boolean {
    return this.userModeLenderSubject.value;
  }

  public get currentDialogActivateValue(): boolean {
    return this.currentDialogActivateSubject.value;
  }

  public get currentBalanceValue(): number {
    return this.currentBalanceSubject.value;
  }

  public setCurrentBalanceValue(value: number) {
    this.currentBalanceSubject.next(value);
  }

  // public get currentUserModeLenderSelectedLenderValue(): boolean {
  //   return this.userModeWelcomeLenderSelectedSubject.value;
  // }

  public get currentIPSubjectValue(): UsserIP {
    return this.currentIPSubject.value;
  }

  authenticate(user: string, password: string, city: string, ipAddress: string, region: string, country: string) {
    return this.apiService.post('user', 'authenticate', { user, password, city, ipAddress, region, country })
      .pipe(map(response => {
        const data = response.data as UserLogged;
        localStorage.setItem('currentUser', JSON.stringify(data));
        this.currentUserSubject.next(data);
        if (data.isLender) {
          localStorage.setItem('userModeLender', 'true');
        } else {
          localStorage.setItem('userModeLender', 'false');
        }
        this.userModeLenderSubject.next(data.isLender);
        return data;
      }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('quoteTemp');
    localStorage.removeItem('tableAmortizeTemp');
    localStorage.removeItem('userModeWelcomeLenderSelected');
    localStorage.removeItem('activateInitialUserMode');
    localStorage.removeItem('ipAddress');
    this.currentUserSubject.next(new UserLogged);
  }

  changeUserModeLenderValue(value: any) {
    localStorage.setItem('userModeLender', value.toString());
    this.userModeLenderSubject.next(value);
  }

  setIpAddress() {
    this.apiService.getIpAddress().subscribe(ipAddress => {
      this.currentIPSubject.next(ipAddress as UsserIP)
      localStorage.setItem("ipAddress", JSON.stringify(ipAddress))
    });
  }

  changeCurrentDialogActivateValue(value: boolean) {
    this.currentDialogActivateSubject.next(value);
  }

  getData(dataReponse: any) {
    const data = dataReponse as UserLogged;
    localStorage.setItem('currentUser', JSON.stringify(data));
    this.currentUserSubject.next(data);
    if (data.isLender) {
      localStorage.setItem('userModeLender', 'true');
    } else {
      localStorage.setItem('userModeLender', 'false');
    }
    this.userModeLenderSubject.next(data.isLender);
    return data;
  }
}