import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { GlobalLists } from 'src/app/global/global-data';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { DialogAddressDataComponent } from '../dialog-address-data/dialog-address-data.component';
import { DialogPersonDataComponent } from '../dialog-person-data/dialog-person-data.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ContactPhone, CreateExternalServicesCollection, ExternalServicesDTO } from 'src/app/models/loan/loan.dto.model';
import { LoanService } from 'src/app/services/loan/loan.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { MatTableDataSource } from '@angular/material/table';
import { Country } from 'src/app/models/loanrequest/loanrequest.model';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CustomerDTO } from 'src/app/models/customer/customer.dto.model';
import { Customer } from 'src/app/models/customer/customer.model';
import { QuoteSend } from 'src/app/models/quote/quotesend.model';
import { UserService } from 'src/app/services/user/user.service';
import { RequiredDocumentsConfigService } from 'src/app/services/required-documents-config/required-documents-config.service';
import { Payment } from 'src/app/models/amortize/amortize.model';
import { AmortizeService } from 'src/app/services/amortize/amortize.service';
import { RequiredDocumentsLegalConfigService } from 'src/app/services/required-documents-legal-config/required-documents-legal-config.service';
import { QuoteService } from 'src/app/services/quote/quote.service';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';

@Component({
	selector: 'app-external-service-collection',
	templateUrl: './external-service-collection.component.html',
	styleUrls: ['./external-service-collection.component.scss']
})

export class ExternalServiceCollectionComponent implements OnInit {
	@ViewChild('stepper') stepper: MatStepper;
	@Input() personData: DialogPersonDataComponent;
	@Input() addressData: DialogAddressDataComponent;
	@Input() addressCountriesJson: Country[];
	@Input() externalServiceSelected: ExternalServicesDTO;
	@Input() stepperExternalServices: MatStepper;
	@Input() loansId: string = null;

	loanStreet: any = '';
	loanExteriorNumber: any = '';
	loanInteriorNumber: any = '';
	loanDistrictName: any = '';
	loanCountryId: any = '';
	loanStateId: any = '';
	loanCityId: any = '';
	loanZipCode: any = '';

	firstNameClient: string = '';
	secondNameClient: string = '';
	lastNameClient: string = '';
	lastName2Client: string = '';
	phoneClient: string = '';
	phoneOtherClient:string = '';
	emailClient: string = '';
	emailOtherClient: string = '';

	fiscalStates: any;
	fiscalCities: any;
	panelContactDataOpenState: any;

	countryArray = [
		{
			"code": "MX",
			"name": "Mexico"
		}
	];

	kobraCoverage = [
		{
			"estado": "Aguascalientes",
			"municipio": ["Aguascalientes"]
		},
		{
			"estado": "Baja California",
			"municipio": ["Tijuana", "Mexicali"]
		},
		{
			"estado": "Ciudad de México",
			"municipio": [
				"Álvaro Obregón",
				"Azcapotzalco",
				"Benito Juárez",
				"Coyoacán",
				"Cuajimalpa de Morelos",
				"Cuauhtémoc",
				"Gustavo A. Madero",
				"Iztacalco",
				"Iztapalapa",
				"La Magdalena Contreras",
				"Miguel Hidalgo",
				"Milpa Alta",
				"Tláhuac",
				"Tlalpan",
				"Venustiano Carranza",
				"Xochimilco"
			]
		},
		{
			"estado": "Chihuahua",
			"municipio": ["Ciudad Juárez"]
		},
		{
			"estado": "Coahuila",
			"municipio": ["Torreón"]
		},
		{
			"estado": "Durango",
			"municipio": ["Durango", "Lerdo", "Gómez Palacio"]
		},
		{
			"estado": "Estado de México",
			"municipio": [
				"Atizapán de Zaragoza",
				"Chalco",
				"Chicoloapan",
				"Chimalhuacán",
				"Nezahualcóyotl",
				"Coacalco de Berriozábal",
				"Ecatepec de Morelos",
				"Ixtapaluca",
				"La Paz",
				"Lerma",
				"Metepec",
				"Naucalpan de Juárez",
				"Tecámac",
				"Texcoco",
				"Tlalnepantla de Baz",
				"Toluca",
				"Tultepec",
				"Tultitlán",
				"Valle de Chalco Solidaridad",
				"Zinacantepec"
			]
		},
		{
			"estado": "Guanajuato",
			"municipio": [
				"Apaseo el Grande",
				"Celaya",
				"Dolores",
				"Irapuato",
				"León",
				"Salamanca",
				"Silao"
			]
		},
		{
			"estado": "Jalisco",
			"municipio": [
				"Guadalajara",
				"El Salto",
				"San Pedro Tlaquepaque",
				"Tlajomulco de Zúñiga",
				"Tonalá",
				"Zapopan"
			]
		},
		{
			"estado": "Michoacán",
			"municipio": ["Acuitzio", "Madero", "Morelia", "Tarímbaro"]
		},
		{
			"estado": "Morelos",
			"municipio": [
				"Cuernavaca",
				"Emiliano Zapata",
				"Jiutepec",
				"Jojutla",
				"Temixco",
				"Yautepec",
				"Zacatepec"
			]
		},
		{
			"estado": "Nuevo León",
			"municipio": [
				"Apodaca",
				"García",
				"General Escobedo",
				"Guadalupe",
				"Monterrey",
				"San Nicolás de los Garza",
				"San Pedro Garza García",
				"Santa Catarina"
			]
		},
		{
			"estado": "Puebla",
			"municipio": [
				"Cuautlancingo",
				"Puebla",
				"Ajalpan",
				"Atlixco",
				"Amozoc",
				"Huejotzingo",
				"Tehuacán",
				"San Pedro Cholula"
			]
		},
		{
			"estado": "Querétaro",
			"municipio": ["Corregidora", "El Marqués", "Querétaro", "San Juan del Río"]
		},
		{
			"estado": "Quintana Roo",
			"municipio": ["Benito Juárez", "Felipe Carrillo Puerto", "José María Morelos"]
		},
		{
			"estado": "San Luis Potosí",
			"municipio": ["San Luis Potosí", "Soledad de Graciano Sánchez"]
		},
		{
			"estado": "Sinaloa",
			"municipio": ["Culiacán", "Mazatlán"]
		},
		{
			"estado": "Tamaulipas",
			"municipio": ["Altamira", "Ciudad Madero", "Tampico"]
		},
		{
			"estado": "Tabasco",
			"municipio": ["Cárdenas", "Centro"]
		},
		{
			"estado": "Veracruz",
			"municipio": [
				"Acultzingo",
				"Ciudad Mendoza",
				"Coatepec",
				"Coscomatepec",
				"Cuitláhuac",
				"Maltrata",
				"Nogales",
				"Orizaba",
				"Paso del Macho",
				"Potrero",
				"Poza Rica",
				"Veracruz",
				"Xalapa",
				"Yanga"
			]
		},
		{
			"estado": "Nayarit",
			"municipio": ["Tepic"]
		},
		{
			"estado": "Yucatán",
			"municipio": ["Mérida"]
		},
		{
			"estado": "Zacatecas",
			"municipio": ["Zacatecas", "Guadalupe"]
		}
	];

	validateForm = [{
		"busisnessNameLender": "Razón social en Acreedor",
		"firstNameLender": "Nombre en Acreedor",
		"lastNameLender1": "Primer apellido en Acreedor",
		"phoneLender": "Teléfono celular en Acreedor",
		"busisnessNameDebto": "Razón social en Deudor",
		"firstNameDebto": "Nombre en Deudor",
		"lastName1Debto": "Primer apellido en Deudor",
		"street": "Calle",
		"exteriorNumber": "Número exterior",
		"districtName": "Colonia",
		"stateId": "Estado",
		"cityId": "Municipio",
		"zipCode": "País",
		"countryId": "Código postal",
		"startDate": "Fecha en Datos préstamo / Adeudo",
		"loanAmount": "Monto en Datos préstamo / Adeudo",
		"totalPending": "Monto Total a Pagar",
		"totalBalanceToLiquited": "Saldo Total Pendiente por Pagar",
		"totalBalanceLate": "Saldo Exigible"

	}];

	relationshipName = "";
	relationShipTypeName = "";
	statesBirth: any;
	states: any;
	cities: any;
	panelTypePersonOpenState = false;
	panelAddressOpenState = false;
	panelOtherDataOpenState = true;
	panelCompanyDataOpenState = false;
	modifydata = true;
	countryName = "";
	stateName = "";
	cityName = "";
	visitTypeName = "";
	externalServiceType: string = null;
	customerData: any;


	dr = true;

	externalServiceForm: FormGroup;
	currencyMask = GlobalLists.currencyMask;
	typeRelationshipList = GlobalLists.typeRelationshipList;
	phoneMask = GlobalLists.phoneMask;
	visitTypeList = GlobalLists.visitTypeList;
	bankList = GlobalLists.bankList;
	phoneList = GlobalLists.phoneList;
	dataCustomer: CustomerDTO;
	personType: number = 0;
	selectedTypeLender: number = 0;
	selectedTypeLoan: string = "Préstamo";
	selectedLoan: number = 1;
	selectedTypeCall: string;
	selectedCall: number = 3;

	cardMask = GlobalLists.cardMask;
	accountMask = GlobalLists.accountMask;
	clabeMask = GlobalLists.clabeMask;
	personTypeList = GlobalLists.personTypeList;
	selectedType: any;
	contactPhone = <ContactPhone[]>[];
	contactPhoneForm: FormGroup;
	dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);
	isDisabled: boolean = false;
	adittionalPhone: any;
	validateRequeridPhone: boolean = false;
	FormrequestJson: FormRequestDTO[];
	amortizationTableJson = "[]";


	contactPhoneColumns = [
		'phoneType',
		'phone',
		'comments',
		'buttonRemove'
	];

	formattedHours: string[] = [];
	payments: Payment[] = [];
	startDateString = this.datePipe.transform(new Date(), "yyyy-MM-dd");
	originalDate: any = null;
	startDateOrginal: any = new Date().toISOString().slice(0, 10);nameTypeCondition: string;
;
	fistDateDateOrginal: any = null;
	nameTypeLender: string;
	startDatePeriod: any

	totalAmortize = 0;
	totalInterest = 0;
	totalInterestTax = 0;
	totalCommission = 0;
	totalCommissionTax = 0;
	totalPeriodPay = 0;
	totalRetention = 0;
	totalNetPeriodPay = 0
	nameCalling: any;
	aditionalCall: boolean = false;
	idLoans: boolean = false;
	AdressClient: string = null;

	constructor(
		private formBuilder: FormBuilder,
		private changeDetector: ChangeDetectorRef,
		private alertGlobalService: AlertGlobalService,
		private loanService: LoanService,
		private currencyPipe: CurrencyPipe,
		private dialog: MatDialog,
		private router: Router,
		private datePipe: DatePipe,
		private amortizeService: AmortizeService,
		private authService: AuthenticationService,
		private customerService: CustomerService,
		private userService: UserService,
		private requiredDocumentsLegalConfigService: RequiredDocumentsLegalConfigService,
		private requiredDocumentsConfigService: RequiredDocumentsConfigService,
		private quoteService: QuoteService,
		private subscriptionpaymentService: SubscriptionpaymentService,
		private catalogService: CatalogService) {

	}

	get fExternalService() { return this.externalServiceForm.controls; }
	get fExternalServiceProp() { return Object.getOwnPropertyNames(this.fExternalService); }
	get fContactPhone() { return this.contactPhoneForm.controls; }

	ngOnInit(): void {
		this.generateHours();
		this.setExternalServiceCollection(this.externalServiceSelected);
		this.updateContactData();
		this.createContactPhoneForm();
		this.getCustomer();
	}


	setExternalServiceCollection(externalServiceSelected: ExternalServicesDTO) {
		this.externalServiceSelected = externalServiceSelected;

		if (this.loansId != null) {
			this.idLoans = true;
			this.loanStreet = this.addressData.FormrequestJson.find(x => x.conditionName == "Street")?.value;
			this.loanExteriorNumber = this.addressData.FormrequestJson.find(x => x.conditionName == "ExteriorNumber")?.value;
			this.loanInteriorNumber = this.addressData.FormrequestJson.find(x => x.conditionName == "InteriorNumber")?.value;
			this.loanDistrictName = this.addressData.FormrequestJson.find(x => x.conditionName == "DistrictName")?.value;
			this.loanCountryId = this.addressData.FormrequestJson.find(x => x.conditionName == "CountryId")?.value;
			this.loanStateId = this.addressData.FormrequestJson.find(x => x.conditionName == "StateId")?.value;
			this.loanCityId = this.addressData.FormrequestJson.find(x => x.conditionName == "CityId")?.value;
			this.loanZipCode = this.addressData.FormrequestJson.find(x => x.conditionName == "ZipCode")?.value;

			this.firstNameClient = this.personData.FormrequestJson.find(x => x.conditionName == "FirstName")?.value;
			this.secondNameClient = this.personData.FormrequestJson.find(x => x.conditionName == "MiddleNameDebto")?.value;
			this.lastNameClient = this.personData.FormrequestJson.find(x => x.conditionName == "LastName1")?.value;
			this.lastName2Client = this.personData.FormrequestJson.find(x => x.conditionName == "lastName2")?.value;
			this.phoneClient = this.personData.FormrequestJson.find(x => x.conditionName == "PhoneMobile")?.value;
			this.emailClient = this.personData.FormrequestJson.find(x => x.conditionName == "Email")?.value;
		}

		this.personType = externalServiceSelected.typePerson;
		this.selectedType = this.personTypeList.find(item => item.key === externalServiceSelected.typePerson).value;
		this.nameTypeLender = this.personTypeList.find(x => x.key == this.selectedTypeLender).value;

		if (externalServiceSelected.serviceName == "Visita de cobranza") {
			this.externalServiceType = "visita";
			this.nameTypeCondition = "visita"
		} else if (externalServiceSelected.serviceName == "Llamada de cobranza") {
			this.externalServiceType = "llamada";
			this.nameTypeCondition = "llamada"
			this.aditionalCall = true;
		} else if (externalServiceSelected.serviceName == "Visita de verificación") {
			this.externalServiceType = "visita de verificación";
			this.nameTypeCondition = "visita"
		} else if (externalServiceSelected.serviceName == "Llamada de verificación") {
			this.externalServiceType = "llamada verificación";
			this.nameTypeCondition = "llamada"
			this.aditionalCall = true;
		}


		this.createExternalServiceFormOther();
	}

	getCustomer() {
		this.customerService.getCustomer(this.authService.currentUserValue.customerId).subscribe(
			(result) => {
				this.customerData = result;
			});
	}

	ngAfterViewInit(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	getStatesBirth(countryBirthId) {
		this.catalogService.getStates(countryBirthId).subscribe(
			(states) => {
				this.statesBirth = states;
			}
		);
	}

	getStates(countryId) {
		this.catalogService.getStates(countryId).subscribe(
			(states) => {
				this.states = states;
				this.stateName = this.states.find(x => x.code == this.addressData.FormrequestJson.find(x => x.conditionName == 'StateId').value).name;
			}
		);
	}

	getCitys(stateId) {
		this.catalogService.getCities(this.fExternalService["countryId"].value, stateId).subscribe(
			(cities) => {
				this.cities = cities;
				this.cityName = this.cities.find(x => x.code == this.fExternalService['cityId'].value).name;
			}
		);
	}


	getContactRelationshipList() {
		if (this.selectedType == 1) {
			return this.personData.contactRelationshipList.filter(x => x.key >= 8 && x.key <= 14)
		} else {
			return this.personData.contactRelationshipList.filter(x => (x.key >= 0 && x.key <= 7) || (x.key >= 13 && x.key <= 14))
		}
	}

	onChangeRelationShip(e: string) {
		if (e == "0") {
			this.fExternalService['relationShipType'].enable()
		} else {
			this.fExternalService['relationShipType'].disable()
			this.fExternalService['relationShipType'].patchValue("");
		}

		this.relationshipName = this.personData.contactRelationshipList.find(x => x.key == parseInt(e)).value;
	}

	onChangeRelationShipType(e: string) {
		this.relationShipTypeName = this.personData.typeRelationshipList.find(x => x.key == parseInt(e)).value;
	}

	onChangeSelectCountrieBirth(countryId: string) {
		this.catalogService.getStates(countryId).subscribe((statesBirth) => {
			this.statesBirth = statesBirth;
		});

		if (countryId == "MX" || countryId == "US") {
		} else {
			this.fExternalService["placeOfBirth"].clearValidators();
		}
	}

	onChangeSelectCountriesRequest(countryId: string, fiscal: boolean) {
		if (!fiscal) {
			this.states = [];
			this.cities = [];
			this.catalogService.getStates(countryId).subscribe(
				(states) => {
					this.fExternalService["stateId"].patchValue("");

					this.states = states;
				}
			);
		} else {
			this.fiscalStates = [];
			this.fiscalCities = [];
			this.catalogService.getStates(countryId).subscribe(
				(states) => {
					this.fExternalService["fiscalStateId"].patchValue("");

					this.fiscalStates = states;
				}
			);
		}

		this.countryName = this.countryArray.find(x => x.code == this.fExternalService['countryId'].value).name;


	}

	onChangeSelectStatesRequest(stateId: string, fiscal: boolean) {
		if (!fiscal) {
			this.cities = [];
			this.catalogService.getCities(this.fExternalService["countryId"].value, stateId).subscribe(
				(cities) => {
					var citis = this.fExternalService["cityId"].patchValue("");
					this.cities = cities;
				}
			);
		} else {
			this.fiscalCities = [];
			this.catalogService.getCities(this.fExternalService["fiscalCountryId"].value, stateId).subscribe(
				(cities) => {
					this.fExternalService["fiscalCityId"].patchValue("");
					this.fiscalCities = cities;
				}
			);
		}

		this.stateName = this.states.find(x => x.code == this.fExternalService["stateId"].value).name;

		if (this.externalServiceType === "visita" || this.externalServiceType === "visita de verificación") {
			if (this.stateName && this.kobraCoverage) {
				var validation = this.kobraCoverage.some(x => x.estado === this.stateName);

				if (validation) {
					this.dr = true;
				} else {
					this.alertGlobalService.showAlertError("No hay cobertura para este estado")
					this.dr = false;
				}
			}
		}
	}


	onChangeSelectCityRequest() {
		if (this.externalServiceType === "visita" || this.externalServiceType === "visita de verificación") {
			this.cityName = this.cities.find(x => x.code == this.fExternalService['cityId'].value).name;

			if (this.cityName && this.stateName) {
				var validation = this.kobraCoverage.some(item =>
					item.municipio.includes(this.cityName));

				if (validation) {
					this.dr = true;
				} else {
					this.alertGlobalService.showAlertError("No hay cobertura para este municipio")
					this.dr = false;
				}
			}
		}
	}

	panelTypePersonOpen(e) {
		this.panelTypePersonOpenState = e;
	}

	panelAdressOpen(e) {
		this.panelAddressOpenState = e;
	}

	panelOtherDataOpen(e) {
		this.panelOtherDataOpenState = e;
	}

	panelCompanyDataOpen(e) {
		this.panelCompanyDataOpenState = e;
	}

	getInvalidControls(formGroup: FormGroup): string[] {
		const invalidControls: string[] = [];

		Object.keys(formGroup.controls).forEach(key => {
			const control = formGroup.get(key);
			if (control.invalid) {
				invalidControls.push(key);
			}
		});

		return invalidControls;
	}

	onSubmit() {
		if (this.externalServiceForm.invalid) {
			const invalidControls = this.getInvalidControls(this.externalServiceForm);

			invalidControls.forEach(controlName => {
				//const control = this.externalServiceForm.get(controlName);
				const nameFormControl = this.validateForm[0][controlName];
				this.alertGlobalService.showAlertError(`Favor de llenar el campo faltante: ${nameFormControl} `)
			});
			return;
		}

		if (this.fExternalService['dayVisit'].value != null) {
			const dateOrigin = new Date(this.fExternalService['dayVisit'].value);
			this.startDateOrginal = dateOrigin.toISOString().slice(0, 10);

			this.startDatePeriod = this.datePipe.transform(dateOrigin, "dd/MM/yyyy");
		}
		if (this.fExternalService['startDate'].value != null) {
			const dateValue = new Date(this.fExternalService['startDate'].value);
			this.startDateOrginal = dateValue.toISOString().slice(0, 10);
		}

		if (this.externalServiceSelected.serviceName == 'Llamada de cobranza' && (this.countryName == null || this.countryName == "")) {
			this.countryName = "";
			this.stateName = "";
			this.cityName = ""
		} else {
			this.stateName = this.states.find(x => x.code == this.fExternalService['stateId'].value).name;
			this.cityName = this.cities.find(x => x.code == this.fExternalService['cityId'].value).name;
		}

		let lenderFullName = '';

		const firstName = this.fExternalService['firstNameLender'].value ? this.fExternalService['firstNameLender'].value : '';
		const secondName = this.fExternalService['secondNameLender'].value ? ' ' + this.fExternalService['secondNameLender'].value : '';
		const lastName1 = this.fExternalService['lastNameLender1'].value ? ' ' + this.fExternalService['lastNameLender1'].value : '';
		const lastName2 = this.fExternalService['lastNameLender2'].value ? ' ' + this.fExternalService['lastNameLender2'].value : '';

		const cleanedFirstName = firstName.trim() !== '' ? firstName : '';
		const cleanedSecondName = secondName.trim() !== '' ? secondName : '';
		const cleanedLastName1 = lastName1.trim() !== '' ? lastName1 : '';
		const cleanedLastName2 = lastName2.trim() !== '' ? lastName2 : '';

		if (cleanedFirstName !== '' || cleanedLastName1 !== '') {
			lenderFullName = cleanedFirstName + cleanedSecondName + cleanedLastName1 + cleanedLastName2;
		}

		if (this.selectedCall == 1) {
			this.selectedTypeCall = this.fExternalService['tradename'].value;
		} else if (this.selectedCall == 2) {
			this.selectedTypeCall = this.fExternalService['busisnessNameLender'].value;
		} else if (this.selectedCall == 3) {
			this.selectedTypeCall = lenderFullName;
		}

		var CreateExternalServicesCollection: CreateExternalServicesCollection;

		CreateExternalServicesCollection = {
			busisnessNameLender: this.fExternalService['busisnessNameLender'].value,
			lenderFullName: lenderFullName,
			tradename: this.fExternalService['tradename'].value,
			busisnessNameDebto: this.fExternalService['busisnessNameDebto'].value,
			firstName: this.fExternalService['firstNameDebto'].value,
			middleName: this.fExternalService['middleNameDebto'].value,
			lastName1: this.fExternalService['lastName1Debto'].value,
			lastName2: this.fExternalService['lastName2Debto'].value,
			typePersonLender: this.nameTypeLender,
			typeLoanDebtor: this.selectedTypeLoan,
			phoneOtherDebto: this.fExternalService['phoneOtherDebto'].value,
			phone: this.fExternalService['phone'].value,
			phoneOtherLender: this.fExternalService['phoneOtherLender'].value,
			phoneLender: this.fExternalService['phoneLender'].value,
			email: this.fExternalService['email'].value,
			emailOtherDebto: this.fExternalService['emailOtherDebto'].value,
			emailLender: this.fExternalService['emailLender'].value,
			emailOtherLender: this.fExternalService['emailOtherLender'].value,
			street: this.fExternalService['street'].value,
			exteriorNumber: this.fExternalService['exteriorNumber'].value,
			interiorNumber: this.fExternalService['interiorNumber'].value,
			districtName: this.fExternalService['districtName'].value,
			countryName: this.countryName,
			stateName: this.stateName,
			cityName: this.cityName,
			zipCode: this.fExternalService['zipCode'].value.toString(),
			idDebt: this.fExternalService['idDebt'].value,
			totalPending: Number(this.fExternalService['totalPending'].value.replace(/[^0-9.-]+/g, '')),
			loanAmount: Number(this.fExternalService['loanAmount'].value.replace(/[^0-9.-]+/g, '')),
			totalPaid: Number(this.fExternalService['totalPaid'].value.replace(/[^0-9.-]+/g, '')),
			totalBalanceToLiquited: Number(this.fExternalService['totalBalanceToLiquited'].value.replace(/[^0-9.-]+/g, '')),
			totalBalanceLate: Number(this.fExternalService['totalBalanceLate'].value.replace(/[^0-9.-]+/g, '')),
			daysLate: 0,
			dayVisit: this.originalDate,
			hourStart: this.fExternalService['hourStart'].value,
			hourEnd: this.fExternalService['hourEnd'].value,
			comments: this.fExternalService['comments'].value,
			linkPay: this.fExternalService['linkPay'].value,
			numberOfPayments: Number(this.fExternalService['numberOfPayments'].value),
			startDate: this.startDateOrginal,
			term: this.fExternalService['term'].value,
			visitType: this.fExternalService['visitType'].value,
			bank: this.fExternalService['bank'].value,
			account: this.fExternalService['account'].value.toString(),
			clabe: this.fExternalService['clabe'].value.toString(),
			cardNumber: this.fExternalService['cardNumber'].value.toString(),
			debtRason: this.fExternalService['debtRason'].value,
			nameCall: this.selectedTypeCall,

			callType: this.externalServiceType == "llamada" ? 1 : null,
			contactType: this.externalServiceType == "llamada" ? 1 : null,
			personType: this.selectedType,
			phoneAdds: this.adittionalPhone,
			referenceAddress: this.fExternalService['referenceAddress'].value
		};

		/*if(CreateExternalServicesCollection != null){
			this.quoteSend()
		}*/

		this.loanService.createExternalServiceCollection(this.externalServiceSelected.id, this.loansId, CreateExternalServicesCollection).subscribe(
			(result) => {
				this.subscriptionpaymentService.updateCarList(this.subscriptionpaymentService.getCarListItems() + 1);

				this.dialog.open(DialogConfirmComponent, {
					data: {
						title: 'Agregado al carrito',
						message: 'Tu ' + this.externalServiceSelected.serviceName + ' para ' + this.capitalizeFirstLetter(this.fExternalService['firstNameDebto'].value + ' ' + this.fExternalService['lastName1Debto'].value) + '.',
						btnCancel: 'Seguir comprando',
						btnConfirm: 'Ir al carrito',
					}
				}).afterClosed().subscribe(
					(confirmado: Boolean) => {
						if (confirmado) {
							this.router.navigate(['/carrito']);
						} else {
							window.location.reload();
						}
					}
				);
				this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			}
		);
	}

	validateRequiredForm(formControlName: string): boolean {
		if (this.fExternalService[formControlName] == null) {
			return;
		}

		if (this.fExternalService[formControlName].errors !== null && (this.fExternalService[formControlName].errors['required'] || this.fExternalService[formControlName].errors['minlength'])) {
			return true;
		}
		return false;
	}

	setModifyData(val: boolean) {
		this.modifydata = val;
	}

	onChangeVisitType() {
		this.visitTypeName = this.visitTypeList.find(x => x.key == this.fExternalService['visitType'].value).value;
	}

	return() {
		/*this.stepperExternalServices.selectedIndex = 0;
		this.modifydata = false;*/
		window.location.reload();
	}

	validateFormrequestJson(val: string): boolean {
		const field = this.externalServiceForm.get(val);
		return field && field.validator && field.validator({} as AbstractControl) && field.validator({} as AbstractControl).hasOwnProperty('required');
	}

	generateHours() {
		for (let i = 9; i <= 20; i++) {
			const startHour = i.toString().padStart(2, '0') + ':00';
			const formattedHourRange = startHour
			this.formattedHours.push(formattedHourRange);
		}
	}

	checkHourEndRange(control) {
		const hourStart = this.externalServiceForm.get('hourStart').value;
		const hourEnd = control.value;

		if (hourStart && hourEnd && hourEnd < hourStart) {
			return { hourRange: true };
		} else {
			return null;
		}
	}

	checkHourStartRange(control) {
		const hourEnd = this.externalServiceForm.get('hourEnd').value;
		const hourStart = control.value;

		if (hourStart && hourEnd && hourStart > hourEnd) {
			return { hourRange: true };
		} else {
			return null;
		}
	}

	showHelp(control: string) {
		var help = GlobalLists.helpControlsList.find(x => x.control === control);
		return help.description;
	}

	capitalizeFirstLetter(value: string): string {
		if (!value || value.length === 0) {
			return '';
		}
		const words = value.split(' ');
		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
		return capitalizedWords.join(' ');
	}

	createContactPhoneForm() {
		this.contactPhoneForm = this.formBuilder.group({
			phoneType: ['', Validators.required],
			phone: [null, [Validators.minLength(10)]],
			comments: ['']
		});
	}

	addContactPhone(): void {
		const phoneValue = this.fContactPhone['phone'].value;
		if (phoneValue != null) {
			const phoneNumber = phoneValue.replace(/[^0-9]/g, '');

			if (phoneNumber.length !== 10) {
				this.validateRequeridPhone = true
				return;
			}
			this.validateRequeridPhone = false;
			this.contactPhone.push(<ContactPhone>{
				phoneType: this.fContactPhone['phoneType'].value,
				phone: phoneNumber,
				comments: this.fContactPhone['comments'].value,
			});

			this.dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);
			this.contactPhoneForm.reset();

			this.updateContactData();
		} else {
			this.validateRequeridPhone = true

		}

	}

	onSubmitContactPhone() {
		if (this.contactPhoneForm.invalid || this.getIsDisabledSave()) {
			return;
		}

		this.addContactPhone();
	}

	getIsDisabledSave() {
		if (this.isDisabled) {
			return true;
		} else {
			return false;
		}
	}

	onDeleteContactPhone(index: number): void {
		if (this.getIsDisabledSave()) {
			return;
		}

		this.contactPhone.splice(index, 1);
		this.dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);

		this.updateContactData();
	}

	updateContactData() {
		this.adittionalPhone = this.contactPhone.map((phoneData) => ({
			phoneType: phoneData.phoneType,
			phone: phoneData.phone.replace(/-/g, ''),
			comments: phoneData.comments
		}));
	}


	createExternalServiceFormOther() {
		this.externalServiceForm = this.formBuilder.group(
			{
				lenderFullName: [''],

				busisnessNameLender: [''],
				tradename: [''],
				firstNameLender: [this.authService.currentUserValue.firstName],
				secondNameLender: [this.authService.currentUserValue.middleName],
				lastNameLender1: [this.authService.currentUserValue.lastName1],
				lastNameLender2: [this.authService.currentUserValue.lastName2],
				phoneLender: [this.authService.currentUserValue.phoneNumber],
				phoneOtherLender: [''],
				emailLender: [this.authService.currentUserValue.username],
				emailOtherLender: [''],


				typePersonLender: ['0'],
				typePersonDebtor: [this.personType],
				typeLoanDebtor: ['1'],

				busisnessNameDebto: [''],
				firstNameDebto: [this.firstNameClient ? this.firstNameClient : ''],
				middleNameDebto: [this.secondNameClient ? this.secondNameClient : ''],
				lastName1Debto: [this.lastNameClient ? this.lastNameClient : ''],
				lastName2Debto: [this.lastName2Client ? this.lastName2Client : ''],
				tradenameDebto: [''],

				phone: [this.phoneClient],
				phoneOtherDebto: [''],
				email: [this.emailClient],
				emailOtherDebto: [''],

				street: [this.loanStreet],
				exteriorNumber: [this.loanExteriorNumber],
				interiorNumber: [this.loanInteriorNumber],
				districtName: [this.loanDistrictName],
				countryId: [this.loanCountryId],
				stateId: [this.loanStateId],
				cityId: [this.loanCityId],
				zipCode: [this.loanZipCode],

				startDate: [null],
				idDebt: [''],
				loanAmount: [''],
				numberOfPayments: [''],
				totalBalancePayment: [''],
				totalBalanceLate: [''],
				totalPaid: [''],
				totalBalanceToLiquited: [''],
				totalPending: [''],
				debtRason: [''],
				nameCall: [''],

				term: [''],

				dayVisit: [null],
				hourStart: [''],
				hourEnd: [''],
				visitType: [''],
				bank: [''],
				account: [''],
				clabe: [''],
				cardNumber: [''],
				comments: [null],
				linkPay: [null],
				adittionalPhone: [''],
				referenceAddress: ['']
			}
		);

		this.fExternalService["busisnessNameLender"].clearValidators();
		this.fExternalService["busisnessNameDebto"].clearValidators();


		if (this.externalServiceSelected.serviceName === 'Visita de cobranza' || this.externalServiceSelected.serviceName === 'Visita de verificación') {

			if (this.externalServiceSelected.serviceName == 'Visita de cobranza') {
				this.externalServiceType = "visita"
				this.fExternalService["totalBalanceLate"].setValidators([Validators.required]);
				this.fExternalService["totalBalanceToLiquited"].setValidators([Validators.required]);
				this.fExternalService["startDate"].setValidators([Validators.required]);
				this.fExternalService["loanAmount"].setValidators([Validators.required]);
				this.fExternalService["totalPending"].setValidators([Validators.required]);
			} else if (this.externalServiceSelected.serviceName == 'Visita de verificación') {
				this.externalServiceType = "visita de verificación"
			}

			if (this.personType == 1) {
				this.fExternalService["busisnessNameDebto"].setValidators([Validators.required]);
				this.fExternalService["busisnessNameDebto"].updateValueAndValidity();
			}


			this.fExternalService["firstNameLender"].setValidators([Validators.required]);
			this.fExternalService["lastNameLender1"].setValidators([Validators.required]);
			this.fExternalService["phoneLender"].setValidators([Validators.required]);

			this.fExternalService["firstNameDebto"].setValidators([Validators.required]);
			this.fExternalService["lastName1Debto"].setValidators([Validators.required]);
			this.fExternalService["phone"].setValidators([Validators.required]);

			this.fExternalService["street"].setValidators([Validators.required]);
			this.fExternalService["exteriorNumber"].setValidators([Validators.required]);
			this.fExternalService["districtName"].setValidators([Validators.required]);
			this.fExternalService["countryId"].setValidators([Validators.required]);
			this.fExternalService["stateId"].setValidators([Validators.required]);
			this.fExternalService["cityId"].setValidators([Validators.required]);
			this.fExternalService["zipCode"].setValidators([Validators.required]);


		} else if (this.externalServiceSelected.serviceName == 'Llamada de cobranza') {
			this.externalServiceType = "llamada"

			if (this.personType == 1) {
				this.fExternalService["busisnessNameDebto"].setValidators([Validators.required]);
				this.fExternalService["busisnessNameDebto"].updateValueAndValidity();
			}

			this.fExternalService["firstNameLender"].setValidators([Validators.required]);
			this.fExternalService["lastNameLender1"].setValidators([Validators.required]);
			this.fExternalService["phoneLender"].setValidators([Validators.required]);

			this.fExternalService["firstNameDebto"].setValidators([Validators.required]);
			this.fExternalService["lastName1Debto"].setValidators([Validators.required]);
			this.fExternalService["phone"].setValidators([Validators.required]);

			this.fExternalService["startDate"].setValidators([Validators.required]);
			this.fExternalService["loanAmount"].setValidators([Validators.required]);
			this.fExternalService["totalBalanceLate"].setValidators([Validators.required]);
			this.fExternalService["totalPending"].setValidators([Validators.required]);
			this.fExternalService["totalBalanceToLiquited"].setValidators([Validators.required]);

		}

		if (this.fExternalService['countryId'].value != "") {
			this.getStates(this.fExternalService['countryId'].value);
			this.countryName = this.countryArray.find(x => x.code == this.fExternalService['countryId'].value).name;
		}

		if (this.fExternalService['stateId'].value != "") {
			this.getCitys(this.fExternalService['stateId'].value);
		}

		this.externalServiceForm.get('hourStart').setValidators(this.checkHourStartRange.bind(this));
		this.externalServiceForm.get('hourStart').updateValueAndValidity();

		this.externalServiceForm.get('hourEnd').setValidators(this.checkHourEndRange.bind(this));
		this.externalServiceForm.get('hourEnd').updateValueAndValidity();
	}

	panelContactDataOpen(e) {
		this.panelContactDataOpenState = e;
	}

	selectPersonType(data: number) {
		this.personType = data;
		this.selectedType = this.personTypeList.find(x => x.key == data).value;
		this.getExternalServices(this.personType)
		this.fExternalService["busisnessNameDebto"].clearValidators();
		this.fExternalService["busisnessNameDebto"].updateValueAndValidity();
		if (data == 1) {
			this.fExternalService["busisnessNameDebto"].setValidators([Validators.required]);
			this.fExternalService["busisnessNameDebto"].updateValueAndValidity();
		}
	}

	selectLender(data: number) {
		this.selectedTypeLender = data;
		if (data) {
			this.nameTypeLender = this.personTypeList.find(x => x.key == this.selectedTypeLender).value;
			this.fExternalService["busisnessNameLender"].clearValidators();
			this.fExternalService["busisnessNameLender"].updateValueAndValidity();
			this.fExternalService["tradename"].clearValidators();
			this.fExternalService["tradename"].updateValueAndValidity();
		}
		if (data == 1) {
			this.fExternalService["busisnessNameLender"].setValidators([Validators.required]);
			this.fExternalService["busisnessNameLender"].updateValueAndValidity();
			this.fExternalService["tradename"].setValidators([Validators.required]);
			this.fExternalService["tradename"].updateValueAndValidity();
			this.selectedCall = 1;
		} if (data == 2) {
			this.fExternalService["tradename"].setValidators([Validators.required]);
			this.fExternalService["tradename"].updateValueAndValidity();
			this.selectedCall = 1;
		}
	}

	selectTypeLoan(data: number) {
		this.selectedLoan = data;
		if (data == 1) {
			this.selectedTypeLoan = "Préstamo";
		} else if (data == 2) {
			this.selectedTypeLoan = "Cuentas por Cobrar"
		}
	}

	selectNameCall(data: number) {
		this.selectedCall = data;
	}

	getExternalServices(data: number) {
		this.loanService.getExternalServices(data).subscribe((result) => {
			this.externalServiceSelected = result.find(x => x.serviceName === this.externalServiceSelected.serviceName)
		});
	}


	quoteSend() {


		var quoteSend: QuoteSend = <QuoteSend>{
			quoteId: null,
			contactName: this.fExternalService['firstNameDebto'].value,
			contact: this.fExternalService['email'].value,
			middleName: this.fExternalService['middleNameDebto'].value,
			lastName1: this.fExternalService['lastName1Debto'].value,
			lastName2: this.fExternalService['lastName2Debto'].value,
			alias: "",
			phoneMobile: this.fExternalService['phone'].value,
			loanAmount: Number(this.fExternalService['loanAmount'].value.replace(/[^0-9.-]+/g, '')),
			annualRate: 0,
			totalPeriods: Number(this.fExternalService['numberOfPayments'].value),
			periodAmortizeStart: 0,
			typeOfPeriod: 0,
			typeOfAmortization: 0,
			taxPorcent: 0,
			startDate: this.startDateOrginal,
			typeOfCredit: 0,
			customerId: this.authService.currentUserValue.customerId,
			name: "Enviada a " + this.fExternalService['firstNameDebto'].value,
			isDefault: false,
			expireDate: new Date(),
			comments: "",
			documentsRequiredConfigJson: JSON.stringify(this.requiredDocumentsConfigService.currentrequiredDocuments),
			amortizationTableJson: this.amortizationTableJson,
			commissionsJson: "",
			monthlyPorcentOnArrears: 0,
			paymentsJson: JSON.stringify(this.amortizeService.currentAmortizeRequestValue.payments),
			lenderCreated: this.authService.currentUserModeLenderValue,
			fromContactName: (this.authService.currentUserValue.firstName + ' ' + this.authService.currentUserValue.middleName).replace("null", "").trimEnd(),
			fromContact: this.authService.currentUserValue.username,
			warrantyDataJson: "",
			documentsLegalRequiredConfigJson: JSON.stringify(this.requiredDocumentsLegalConfigService.currentrequiredDocuments),
			customPayments: false,
			legalConditions: 0,
			creditDestination: "Otro",
			sendContact: false,
			contactRelationship: "",
			percentRetentionISR: 0,
			personType: this.selectedType,
			occupation: "",
			busisnessName: this.fExternalService['busisnessNameDebto'].value,
			page: "",
			newExists: 0,
			fromLoanRequestId: null,
			creditDestinationOther: ""
		}

		this.userService.validateUserExists(this.fExternalService['firstNameLender'].value, this.fExternalService['firstNameDebto'].value,).subscribe(data => {
			if (data.userExists) {
				this.quoteService.send(quoteSend).subscribe((data) => {

				});
			} else {
				var customer: Customer = <Customer>{
					firstName: this.fExternalService['firstNameDebto'].value,
					middleName: this.fExternalService['middleNameDebto'].value,
					lastName1: this.fExternalService['lastName1Debto'].value,
					lastName2: this.fExternalService['lastName2Debto'].value,
					contactNoUser: this.fExternalService['email'].value,
					gender: ""
				}

				this.customerService.createCustomer(customer).subscribe(data => {
					this.quoteService.send(quoteSend).subscribe((data) => {
						this.alertGlobalService.showAlertSuccess("Quote con éxito.");
					});
				})
			}
		});
	}

}
