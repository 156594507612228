
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { TypeofamortizationService } from 'src/app/services/typeofamortization/typeofamortization.service';
import { TypeofcreditService } from 'src/app/services/typeofcredit/typeofcredit.service';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { LoanRequestDetailDTO, LoanRequestDTO, } from 'src/app/models/loanrequest/loanrequest.dto.model';
import { AmortizeService } from 'src/app/services/amortize/amortize.service';
import { Amortize, Commission } from 'src/app/models/amortize/amortize.model';
import { GlobalData } from 'src/app/global/global-data';
import { Uuidv4Service } from 'src/app/services/uuidv4/uuidv4.service';

@Component({
	selector: 'app-conditions-data',
	templateUrl: './conditions-data.component.html',
	styleUrls: ['./conditions-data.component.scss'],
})

export class ConditionsDataComponent implements OnInit {
	@Input() loanRequestDTO: LoanRequestDTO;

	startDate: Date;
	loanId = "";
	loanDetails: LoanRequestDetailDTO[] = [];
	periodicPayment = "";
	totalAmortize: number;
	totalInterest: number;
	totalCommission: number;
	isCommission = "";
	customPayments = "";
	warrantys = "";
	totalPeriods: number;
	typeOfAmortization = "";
	typeOfPeriodDesc = "";
	borrowerName = "";
	borrowerMail = "";
	borrowerPhoneMobile = "";
	lenderEmail = "";
	lenderCustomerFullName = "";
	lenderPhoneMobile = "";
	typeOfCredit = "";
	monthlyRate: number;
	monthlyPorcentOnArrears: number;
	taxPorcent: number;
	periodAmortizeStart: number;
	amortize: Amortize;

	typeOfAmortizationList: KeyValue<number, string>[] = [];
	typeOfPeriodList: KeyValue<number, string>[] = [];
	typeOfCreditList: KeyValue<number, string>[] = [];

	constructor(
		private typeofperiodService: TypeofperiodService,
		private typeofamortizationService: TypeofamortizationService,
		private typeofcreditService: TypeofcreditService,
		public amortizeService: AmortizeService,
		private uuidv4Service: Uuidv4Service,
		private changeDetector: ChangeDetectorRef) {

	}

	ngOnInit(): void {
		this.getTypeofamortizationList();
		this.getTypeOfPeriodList();
		this.getTypeOfCreditList();

		var startDateStr = this.loanRequestDTO.loanRequestDetails.find(x => x.conditionName === 'StartDate').newConditionValue;
		var startDateArray = startDateStr.split("/");
		this.startDate = new Date(startDateArray[2] + "/" + startDateArray[1] + "/" + startDateArray[0]);
		this.loanId = this.loanRequestDTO.loanId;
		this.borrowerName = this.loanRequestDTO.borrowerCustomerFullName;

		if (this.loanRequestDTO.formRequestEmail == "") {
			this.borrowerMail = this.loanRequestDTO.contact;
		} else {
			this.borrowerMail = this.loanRequestDTO.formRequestEmail;
		}

		if (this.loanRequestDTO.formRequestPhoneMobile == "") {
			this.borrowerPhoneMobile = this.loanRequestDTO.phoneMobileQs;
		} else {
			this.borrowerPhoneMobile = this.loanRequestDTO.formRequestPhoneMobile;
		}

		this.lenderCustomerFullName = this.loanRequestDTO.lenderCustomerFullName;
		this.lenderEmail = this.loanRequestDTO.lenderEmail;
		this.lenderPhoneMobile = this.loanRequestDTO.lenderPhoneMobile;

		var data = JSON.parse(this.loanRequestDTO.amortizationTableJson);

		this.totalAmortize = data.totalAmortize;
		this.totalInterest = data.totalInterest + data.totalInterestTax;
		this.totalCommission = data.totalCommission + data.totalCommissionTax;
		this.typeOfCredit = this.typeOfCreditList.find(x => x.key === this.loanRequestDTO.typeOfCredit).value;

		var typeOfAmortization = parseInt(this.loanRequestDTO.loanRequestDetails.find(x => x.conditionName === 'TypeOfAmortization').newConditionValue);
		var typeOfPeriod = parseInt(this.loanRequestDTO.loanRequestDetails.find(x => x.conditionName === 'TypeOfPeriod').newConditionValue);

		this.typeOfAmortization = this.typeOfAmortizationList.find(x => x.key === typeOfAmortization).value;
		this.typeOfPeriodDesc = this.typeOfPeriodList.find(x => x.key === typeOfPeriod).value;
		this.periodAmortizeStart = this.loanRequestDTO.periodAmortizeStart;
		this.totalPeriods = parseInt(this.loanRequestDTO.loanRequestDetails.find(x => x.conditionName === 'TotalPeriods').newConditionValue);

		this.warrantys = "";
		this.loanDetails = this.loanRequestDTO.loanRequestDetails;
		this.loanDetails.forEach(
			(item) => {
				if (item.conditionName === "AnnualRate") {
					this.loanDetails.find(x => x.oldConditionValue === item.oldConditionValue).oldConditionValue = (parseFloat(item.oldConditionValue) / 12).toString();
				}

				if (item.conditionName == "WarrantyDataJson") {
					JSON.parse(item.newConditionValue).forEach((item) => {
						this.warrantys += item['description'] + ", ";
					});

					this.warrantys = this.warrantys.substring(0, this.warrantys.length - 2);

					if (this.warrantys == "") {
						this.warrantys = "Sin garantía(s)";
					}
				}
			}
		);

		this.monthlyPorcentOnArrears = this.loanRequestDTO.monthlyPorcentOnArrears;
		this.monthlyRate = this.loanRequestDTO.monthlyRate;
		this.taxPorcent = parseFloat(this.loanDetails.find(x => x.conditionName == "TaxPorcent").newConditionValue);

		var firstAmount: any = "";
		var breakAmount: Boolean = false;

		data.table.forEach(
			(item: any) => {
				if (item.period != 0) {
					if (breakAmount == false) {
						if (firstAmount == "") {
							firstAmount = item.periodPay;
						} else {
							if (firstAmount != item.periodPay) {
								firstAmount = "Variable";
								breakAmount = true;
							}
						}
					}
				}
			}
		);

		this.periodicPayment = firstAmount;
		this.totalCommission = data.totalCommission + data.totalCommissionTax;
		this.isCommission = this.totalCommission ? "Si" : "No";
		this.customPayments = this.loanRequestDTO.customPayments ? "Si" : "No";

		var retentionValue = 0;

		if (this.loanDetails.find(x => x.conditionName == "PercentRetentionISR")) {
			retentionValue = parseFloat(this.loanDetails.find(x => x.conditionName == "PercentRetentionISR").newConditionValue + "");
		}

		this.amortize = <Amortize>{
			loanAmount: parseFloat((this.loanDetails.find(x => x.conditionName == "LoanAmount").newConditionValue + "").replace(/,/g, "")),
			annualRate: parseFloat(this.loanDetails.find(x => x.conditionName == "AnnualRate").newConditionValue),
			totalPeriods: parseInt(this.loanDetails.find(x => x.conditionName == "TotalPeriods").newConditionValue),
			periodAmortizeStart: parseInt(this.loanDetails.find(x => x.conditionName == "PeriodAmortizeStart").newConditionValue),
			typeOfPeriod: parseInt(this.loanDetails.find(x => x.conditionName == "TypeOfPeriod").newConditionValue),
			typeOfAmortization: parseInt(this.loanDetails.find(x => x.conditionName == "TypeOfAmortization").newConditionValue),
			taxPorcent: (this.loanDetails.find(x => x.conditionName == "TaxPorcent").newConditionValue) == "16" ? GlobalData.TAX : 0,
			startDate: new Date(this.loanDetails.find(x => x.conditionName == "StartDate").newConditionValue),
			commissions: JSON.parse(this.loanDetails.find(x => x.conditionName == "Commissions").newConditionValue) as Commission[],
			trackId: this.uuidv4Service.uuidv4(),
			retention: retentionValue
		};

		this.amortizeService.setTable(this.amortize, data);
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	getTypeofamortizationList() {
		this.typeofamortizationService.getList().subscribe(
			(list) => {
				this.typeOfAmortizationList = list;
			}
		);
	}

	getTypeOfPeriodList() {
		this.typeofperiodService.getList().subscribe(
			(list) => {
				this.typeOfPeriodList = list;
			}
		);
	}

	getTypeOfCreditList() {
		this.typeofcreditService.getList().subscribe(
			(list) => {
				this.typeOfCreditList = list;
			}
		);
	}
}