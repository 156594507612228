<h1 mat-dialog-title>{{documentType}}</h1>
<form *ngIf="postComment" [formGroup]="commentForm" (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex appearance="fill">
            <mat-label>Comentario</mat-label>
            <textarea matInput formControlName="comment" autocomplete="off" autocapitalize="off" maxlength="100"></textarea>
            <mat-error *ngIf="validateRequiredForm('comment')">{{requiredErrorMessage}}</mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="warn">
            <span class="material-icons">comment</span> Comentar
        </button>
    </div>
</form>

<div mat-dialog-content>
    <table mat-table fxFlex="100%" matTableResponsive [dataSource]="dataSourceComments">
        <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef> Fecha </th>
            <td mat-cell *matCellDef="let row"> {{row.dateCreated | date :'short'}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let row"> {{getNameComment(row.customerId)}} </td>
        </ng-container>

        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef> Comentario </th>
            <td mat-cell *matCellDef="let row"> {{row.comment}} </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="columnsComments"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsComments;"></tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</div>
