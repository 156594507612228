import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { MortgageGuarantee } from 'src/app/models/loanrequest/mortgage-guarantee.model';
import { DatePipe, KeyValue } from '@angular/common';
import { PropertyTypeService } from 'src/app/services/propertyType/propertyType.service';
import { GlobalLists } from 'src/app/global/global-data';
import { Country } from 'src/app/models/loanrequest/loanrequest.model';

export interface FormRequestElement {
	conditionName: string;
	value: string;
	enabled: boolean;
	required: boolean;
}

export interface DialogData {
	number: number,
	warranty: string,
	warrantysForm: any;
	loanRequestId: string;
	warrantysCompleted: boolean;
	loanRequestStatus: number;
	borrowerName: string;
}

@Component({
	selector: 'app-dialog-warranty-mortgage-details',
	templateUrl: './dialog-warranty-mortgage-details.component.html',
	styleUrls: ['./dialog-warranty-mortgage-details.component.scss']
})

export class DialogWarrantyMortgageDetailsComponent implements OnInit {
	mode = "";
	mortgageGuaranteeJsonForm: FormGroup = this.formBuilder.group({});
	genders: any;
	countriesMortgage: Country[];
	statesBirth: any;
	citiesMortgage: any;
	submit = false;	
	statesMortgage: any;
	dataSourceformMortgageJson = new MatTableDataSource<FormRequestDTO>([]);
	dataSourceformMortgageJsonData = new MatTableDataSource<MortgageGuarantee>(<MortgageGuarantee[]>[]);
	elementsFormMortgage: any;
	propertyTypeList: KeyValue<number, string>[] = [];

	columnsformMortgage: string[] = [
		'conditionName',
		'value',
		'required'
	];

	FormrequestJson: FormRequestDTO[];

	panelRealEstateDataOpenState: any;
	panelAddressOpenState: any;

	typeOfProofOfOwnershipList = GlobalLists.typeOfProofOfOwnershipList;
	lienFreeList = GlobalLists.yesnoList;
	ownerList = GlobalLists.ownerList;
	debtsList = GlobalLists.debtsMortgageList;
	currencyMask = GlobalLists.currencyMask;

	contactRelationshipList = GlobalLists.contactRelationshipList;
	
	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private catalogService: CatalogService,
		private loanrequestService: LoanrequestService,
		private alertGlobalService: AlertGlobalService,
		private dialogRef: MatDialogRef<DialogWarrantyMortgageDetailsComponent>,
		private datePipe: DatePipe,
		private propertyTypeService: PropertyTypeService,
		private changeDetector: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data:DialogData
		) {
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get f5() { return this.mortgageGuaranteeJsonForm.controls; }
	getPropertyType(id) { return this.propertyTypeList.find(x => x.key === parseInt(id)).value; }

	ngOnInit(): void {
		this.getGenders();
		this.getCountries();
		this.getPropertyTypes()
		this.createMortgageGuaranteeForm();
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	getGenders() {
		this.catalogService.getGenders()
		  .subscribe((data) => {
			this.genders = data;
		});
	}

	getCountries() {
		this.catalogService.getCountries()
      	.subscribe((countries) => {
        	this.countriesMortgage = (countries as Country[]).filter(x => x.code == "MX" || x.code == "US");
      	});
	}

	getCitysMortgage(stateId) {
		this.catalogService.getCities(this.f5["value-CountryId"].value, stateId)
		.subscribe((cities) => {
		  this.citiesMortgage = cities;
		});
	}

	getPropertyTypes() {
		this.propertyTypeService.getList()
		.subscribe((list)=>{
		  this.propertyTypeList = list;
		});
	}

	getStates(countryBirthId) {
		this.catalogService.getStates(countryBirthId)
		.subscribe((states) => {
		this.statesBirth = states;
		});
	}

	getStatesMortgage(countryId) {
		this.catalogService.getStates(countryId)
		.subscribe((states) => {
		this.statesMortgage = states;
		});
	}

	onChangeSelectCountrieBirth(countryId: string) {
		this.catalogService.getStates(countryId)
			.subscribe((statesBirth) => {
			this.statesBirth = statesBirth;
		});
	}

	onChangeSelectCountriesMortgage(countryId: string) {
		this.catalogService.getStates(countryId)
		  .subscribe((states) => {
			this.f5["value-StateId"].patchValue("");
			this.statesMortgage = states;
		});
	}

	onChangeSelectStatesMortgage(stateId: string) {
		this.catalogService.getCities(this.f5["value-CountryId"].value, stateId)
		  .subscribe((cities) => {
			this.f5["value-CityId"].patchValue("");
			this.citiesMortgage = cities;
		});
	}

	onChangeSelectOwner(value: string) {
		if (value === "Otros") {
			this.f5["value-OwnerName"].patchValue('');
			this.f5["value-OwnerName"].enable();
  
			this.f5["value-OwnerName"].setValidators([Validators.required]);
			this.f5["value-OwnerName"].updateValueAndValidity();

			
			this.f5["value-RelationShip"].patchValue('');
			this.f5["value-RelationShip"].enable();
  
			this.f5["value-RelationShip"].setValidators([Validators.required]);
			this.f5["value-RelationShip"].updateValueAndValidity();
		  } else {
			this.f5["value-OwnerName"].disable();
			this.f5["value-OwnerName"].patchValue(this.data.borrowerName);
  
			this.f5["value-OwnerName"].clearValidators();
			this.f5["value-OwnerName"].updateValueAndValidity();


			this.f5["value-RelationShip"].disable();
			this.f5["value-RelationShip"].patchValue('');
  
			this.f5["value-RelationShip"].clearValidators();
			this.f5["value-RelationShip"].updateValueAndValidity();
		  }
	}

	getIsDisabledSave() {
		if (this.data.loanRequestStatus == 9 || this.data.loanRequestStatus == 4) {
			return true;
		} else {
			return false;
		}
	}

	createMortgageGuaranteeForm() {
		this.FormrequestJson = this.data.warrantysForm;
		var formJson = this.FormrequestJson;
		
		for (var key in formJson) {
			if (this.isLender) {
				if (formJson[key]['required']) {
					this.mortgageGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
				} else {
					this.mortgageGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			} else {
				if (formJson[key]['required']) {
					this.mortgageGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
				} else {
					this.mortgageGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			}

			if (formJson[key]['conditionName'] == "CountryBirthId" && formJson[key]['value'] != "") {
				this.getStatesMortgage(formJson[key]['value']);
			}

			if (formJson[key]['conditionName'] == "CountryId" && formJson[key]['value'] != "") {
				this.getStatesMortgage(formJson[key]['value']);
			}

			if (formJson[key]['conditionName'] == "StateId" && formJson[key]['value'] != "") {
				this.getCitysMortgage(formJson[key]['value']);
			}

			if (formJson[key]['conditionName'] == "PropertyOther" && formJson[key]['value'] == "") {
				this.f5["value-PropertyOther"].disable();
				this.f5["value-PropertyOther"].patchValue('');
			}

			if (formJson[key]['conditionName'] == "OwnerName" && formJson[key]['value'] == "") {
				this.f5["value-OwnerName"].disable();
				this.f5["value-OwnerName"].patchValue('');
			}

			if (formJson[key]['conditionName'] == "RelationShip" && formJson[key]['value'] == "") {
				this.f5["value-RelationShip"].disable();
				this.f5["value-RelationShip"].patchValue('');
			}

			if (this.getIsDisabledSave()) {
				this.f5["value-" + formJson[key]['conditionName']].disable();
			}
		}
		
		if (this.f5['value-WrittingDate'].value != "") {
			this.f5['value-WrittingDate'].patchValue(this.datePipe.transform(this.f5['value-WrittingDate'].value, 'yyyy-MM-dd'));
		}

		const ELEMENT_DATA: (FormRequestElement)[] = this.data.warrantysForm;
		this.elementsFormMortgage = ELEMENT_DATA;

		this.dataSourceformMortgageJson.data = ELEMENT_DATA as FormRequestDTO[];
	}

	validateRequiredFormF5(formControlName: string): boolean {
		if(this.f5[formControlName].errors !== null && this.f5[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	isGroup(index, item): boolean{
		return item.isGroupBy;
	}

	onSubmitFormMortgageJson() {
		// if (this.mortgageGuaranteeJsonForm.invalid) {
		// 	return;
		// }
		
		var warrantysForm: WarrantysForm;
		var mortgageGuaranteeAnswer: FormRequestAnswer[] = [];

		for (let val of this.elementsFormMortgage){
			var value: any = "";

			if (val["conditionName"] != null) {
				if (val["conditionName"] == "WrittingDate") {
					if (this.f5["value-" + val["conditionName"]].value != "") {
						value = this.datePipe.transform(this.f5["value-" + val["conditionName"]].value.toString(), 'yyyy-MM-ddT00:00:00')
					}
				} else {
					value = this.f5["value-" + val["conditionName"]].value.toString();
				}
			}

			mortgageGuaranteeAnswer.push({ 
				conditionName: val["conditionName"], 
				required: val["required"],
				value: value
			});
		}

		warrantysForm = {
			number: this.data.number,
			warranty: this.data.warranty,
			warrantyForm: mortgageGuaranteeAnswer,
			warrantyFormCompleted: 0
		}
		this.loanrequestService.UpdateMortgageGuaranteeJsonForm(this.data.loanRequestId, warrantysForm).subscribe((result) => {    
			this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			this.submit = true
			this.dialogRef.close();
		});
	}

	onChangeSelectPropertyType(value) {
		if (value === "Otro Inmueble") {
			this.f5["value-PropertyOther"].patchValue('');
			this.f5["value-PropertyOther"].enable();

			this.f5["value-PropertyOther"].setValidators([Validators.required]);
			this.f5["value-PropertyOther"].updateValueAndValidity();
		} else {
			this.f5["value-PropertyOther"].disable();
			this.f5["value-PropertyOther"].patchValue('');

			this.f5["value-PropertyOther"].clearValidators();
			this.f5["value-PropertyOther"].updateValueAndValidity();
		}
	}

	validateFormrequestJson(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	panelRealEstateDataOpen(e) {
		this.panelRealEstateDataOpenState = e;
	}

	panelAdressOpen(e) {
		this.panelAddressOpenState = e;
	}

	getWrittingNumberText(e: string) {
		if (e == "Escritura") {
			return "Número escritura";
		} else if (e == "Título de propiedad") {
			return "Número título de propiedad";
		} else if (e == "Padrón catastral") {
			return "Número padrón catastral";
		} else if (e == "Contrato compra-venta") {
			return "Número contrato compra-venta";
		} else {
			return "Número escritura"
		}
	}

	getWrittingDateText(e: string) {
		if (e == "Escritura") {
			return "Fecha escritura";
		} else if (e == "Título de propiedad") {
			return "Fecha título de propiedad";
		} else if (e == "Padrón catastral") {
			return "Fecha padrón catastral";
		} else if (e == "Contrato compra-venta") {
			return "Fecha contrato compra-venta";
		} else {
			return "Fecha escritura"
		}
	}
}
