<div class="container">
	<div class="row justify-content-center">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="card overflow-hidden">
				<div class="bg-primary bg-soft-primary">
					<div class="d-flex align-items-center">
						<div class="col-8 col-sm-9 col-md-9">
							<div class="text-white p-4">
								<h5 class="text-white">Confirma tu correo electrónico</h5>
								<p class="mb-0">Ingresa el código de validación</p>
							</div>
						</div>
						<div class="col-4 col-sm-3 col-md-3">
							<div class="avatar-md">
								<span class="avatar-title rounded-circle bg-light">
									<a href="/">
										<img src="assets/images/icons/c_wing.svg" height="34" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<form class="p-2" [formGroup]="validateForm" (ngSubmit)="onSubmit()">
						<div class="form-horizontal">
							<div class="mb-3">
								<div class="form-group">
									<label>Código de validación</label>
									<input
										autofocus="autofocus"
										class="form-control"
										formControlName="code"
										maxlength="6"
										label="Código de validación"
										placeholder="XXXXXX"
										type="numeric"
										value=""
									/>
									<mat-error *ngIf="submitted && f.code.errors && f.code.errors['required']">Este campo es requerido.</mat-error>
									<mat-error *ngIf="submitted && f.code.errors && f.code.errors.minLength">Sólo se permiten 6 dígitos.</mat-error>
								</div>
							</div>

							<div class="mt-3 text-center">
                <button
                  class="btn_primary dark"
                  type="submit"
                >
                  <a class="nav-link">Continuar</a>
                </button>
							</div>

							<div class="mt-4 d-grid">
								<button
									class="btn btn-white btn-block"
									type="submit"
									[disabled]="disableSentCodeButton" (click)="sendCode()"
								>
									Recibir un código nuevo
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
