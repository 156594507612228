<!-- <div class="modal-container" [ngClass]="!modalActive ? 'd-none' : ''">
  <div class="">
    <div class="modal fade show" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button class="close" (click)="close()">
              <span aria-hidden="true">×</span>
            </button>
            <img class="img-fluid" src="/assets/images/promoLanzamiento.png"
              alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" (click)="close()"></div>
  </div>
</div> -->

<div class="row bg_landing">
  <section id="inicio">
    <div class="container-fluid py-md-5 py-0">
      <div class="align-items-center row justify-content-between">
        <div class="col-md-6 text-md-start text-center">
          <p class="title dark">¿Te deben dinero?</p>
          <p class="subtitle-index fw-normal dark fSize15" style="margin-top: 2rem;">Conoce a-lender, la plataforma para
            administrar y cobrar tus<span class="fw-bold"> préstamos</span> y <span class="fw-bold"> cuentas por cobrar</span> como un profesional.</p>
          <p class="subtitle-index fw-normal dark" style="margin-top: 2rem;">
            <span class="fw-bold fSize15">
              Fácil / Rápido / Seguro
            </span>
          </p>
          <button class="btn_primary dark mt-5">
            <a class="nav-link btn-big" href="/crear-cuenta" routerLinkActive="active">Comienza aquí</a>
          </button>
          <img class="img-fluid move three" src="/assets/images/newImages/signoFondo1.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move four" src="/assets/images/newImages/signoFondo1.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move five" src="/assets/images/newImages/signoFondo3.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
        <div class="col-md-6 mb-md-0 mb-5">
          <img class="img-fluid" src="/assets/images/newImages/principal-banner.png"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
      </div>
    </div>
  </section>

  <section id="como">
    <div class="container-fluid py-md-5 py-0">
      <div class="align-items-center row justify-content-between">
        <div class="col-md-12 text-md-start text-center">
          <p class="title dark">¿Cómo funciona?</p>
          <div class="row mt-5">
            <div class="col-lg-3 col-md-6 mb-md-0 mb-5 dark">
              <button class="btn_primary square">
                <span class="nav-link">1</span>
              </button>
              <p class="subtitle fw-bold my-4">Regístrate</p>
              <p class="text">Crea tu usuario y contraseña para entrar a la plataforma.</p>
              <img class="icon" src="/assets/images/newImages/comoFunciona1.webp"
                alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
            </div>
            <div class="col-lg-3 col-md-6 mb-md-0 mb-5 dark">
              <button class="btn_primary square">
                <span class="nav-link">2</span>
              </button>
              <p class="subtitle fw-bold my-4">Comienza</p>
              <p class="text">Inicia tu prueba gratis de 30 días o adquiere un plan de acuerdo a tus necesidades.</p>
              <img class="icon" src="/assets/images/newImages/comoFunciona2.webp"
                alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
            </div>
            <div class="col-lg-3 col-md-6 mb-md-0 mb-5 dark">
              <button class="btn_primary square">
                <span class="nav-link">3</span>
              </button>
              <p class="subtitle fw-bold my-4">Descubre</p>
              <p class="text">Conoce los servicios de llamada y visita de cobranza.</p>
              <img class="icon" src="/assets/images/newImages/comoFunciona3.webp"
                alt="a-lender | Plataforma de Administración de Préstamos">
            </div>
            <div class="col-lg-3 col-md-6 mb-md-0 mb-5 dark">
              <button class="btn_primary square">
                <span class="nav-link">4</span>
              </button>
              <p class="subtitle fw-bold my-4">Disfruta</p>
              <p class="text">¡No pierdas más tiempo ni dinero! Con a-lender cobra más y recupera tu dinero.</p>
              <img class="icon" src="/assets/images/newImages/comoFunciona4.webp"
                alt="a-lender | Plataforma de Administración de Préstamos">
            </div>
          </div>
          <img class="img-fluid move one" src="/assets/images/newImages/signoFondo6.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move two" src="/assets/images/newImages/signoFondo2.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move three" src="/assets/images/newImages/signoFondo1.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move four" src="/assets/images/newImages/signoFondo1.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move six" src="/assets/images/newImages/signoFondo3.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
      </div>
    </div>
    <div class="mask"></div>
  </section>

  <section id="quien">
    <div class="container-fluid py-md-5 py-0">
      <div class="align-items-center row justify-content-around">
        <div class="col-md-7 text-md-start text-center order-first">
          <p class="title dark col-lg-7 col-md-10 col-sm-7 mx-md-0 mx-auto mb-md-0 mb-4">¿Quién puede usar a-lender?
          </p>
          <div class="d-md-flex d-block mt-5">
            <p class="subtitle fw-normal dark">Si te deben<span class="transparent">.</span></p>
            <p class="subtitle fw-normal dark"><span class="tw fw-bold dark"></span></p>
          </div>
          <p class="text fw-normal dark mt-2">En general, cualquier persona o empresa que tenga cuentas por cobrar, de
            manera ocasional o recurrente, no importa el número ni el monto de las operaciones.</p>
          <img class="img-fluid move one" src="/assets/images/newImages/signoFondo1.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move two" src="/assets/images/newImages/signoFondo3.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
        <div class="col-md-4 mx-auto order-second">
          <img class="img-fluid" src="/assets/images/newImages/illustracion5.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move three" src="/assets/images/newImages/signoFondo3.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move four" src="/assets/images/newImages/signoFondo6.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move five" src="/assets/images/newImages/signoFondo2.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
      </div>
    </div>
    <div class="mask"></div>
  </section>

  <section id="disenatuprestamo">
    <div class="container-fluid py-md-5 py-0">
      <div class="align-items-center row justify-content-between">
        <div class="col-md-8 text-md-start text-center mb-md-0 mb-5">
          <p class="title dark">¡Diseña tus préstamos!</p>
          <p class="black text">Utiliza el <span class="fw-bold primary">simulador</span> y crea
            préstamos
            a la medida. Elije fácilmente tus términos y condiciones, y genera tablas de pagos personalizadas.</p>
          <button class="btn_primary dark mt-5">
            <a class="nav-link btn-big" routerLinkActive="active" (click)="openSimulator(true)">Cotizador</a>
          </button>
          <img class="img-fluid move four" src="/assets/images/newImages/signoFondo2.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move five" src="/assets/images/newImages/signoFondo4.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move six" src="/assets/images/newImages/signoFondo1.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
        <div class="col-md-4 mx-auto">
          <img class="img-fluid" src="/assets/images/newImages/illustracion6.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move two" src="/assets/images/newImages/signoFondo3.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move three" src="/assets/images/newImages/signoFondo1.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
      </div>
    </div>
  </section>

  <section id="porque">
    <div class="container-fluid py-md-5 py-0">
      <div class="align-items-center row justify-content-around">
        <div class="col-md-6 mb-md-0 mb-5">
          <img class="img-fluid" src="/assets/images/newImages/illustracion2.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move two" src="/assets/images/newImages/signoFondo2.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
        <div class="col-md-5 text-md-start text-center">
          <p class="title dark">¿Por qué a-lender?</p>
          <p class="subtitle dark">Fácil de usar</p>
          <p class="text dark">Diseña, registra y da seguimiento a tus préstamos de manera clara y sencilla.</p>
          <img class="img-fluid move three" src="/assets/images/newImages/signoFondo3.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move four" src="/assets/images/newImages/signoFondo6.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move five" src="/assets/images/newImages/signoFondo1.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
      </div>
    </div>
  </section>
  <section id="porque2">
    <div class="container-fluid py-md-5 py-0">
      <div class="align-items-center row justify-content-around">
        <div class="col-md-9">
          <p class="subtitle dark mb-5">Todo en un solo lugar</p>
          <div class="row mx-0 gap-5">
            <div class="col-lg-5 col-10 bg_table">
              <table class="table font-size-16 mb-0">
                <tbody>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Cotizador de préstamos</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Alta de solicitudes</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Integración de expediente y
                        carga de documentos</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Validación de CURP, INE,
                        RFC</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Validación de identificación
                        oficial y comprobante de domicilio</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Validación de teléfono y
                        correo</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Verificaciones
                        telefónicas</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Verificaciones
                        domiciliarias</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Consulta de historial de
                        crédito</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-lg-5 col-10 bg_table">
              <table class="table font-size-16 mb-0">
                <tbody>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Evaluación y autorización de
                        solicitudes</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Generación de contratos y
                        pagarés</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Mensajes, recordatorios y
                        requerimientos de pagos a solicitante</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Avisos de pago</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Envío de estados de
                        cuenta</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Llamadas de cobranza</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Visitas de cobranza</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">
                      <i class="bx bx-check me-4 primary"></i> <span class="fw-bold dark">Avisos de liquidación</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <img class="img-fluid move two" src="/assets/images/newImages/signoFondo2.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
        <div class="col-md-3 d-md-flex d-none">
          <img class="img-fluid" src="/assets/images/newImages/illustracion3.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move three" src="/assets/images/newImages/signoFondo3.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move four" src="/assets/images/newImages/signoFondo6.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move five" src="/assets/images/newImages/signoFondo1.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
      </div>
    </div>
    <div class="mask"></div>
  </section>
  <section id="porque3">
    <div class="container-fluid py-md-5 py-0">
      <div class="align-items-center row justify-content-around">
        <div class="col-md-3 d-md-flex d-none">
          <img class="img-fluid" src="/assets/images/newImages/illustracion4.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move one" src="/assets/images/newImages/signoFondo7.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move two" src="/assets/images/newImages/signoFondo1.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
        <div class="col-md-4 text-md-start text-center">
          <p class="subtitle dark">Seguro y confiable</p>
          <p class="text dark">Ten en orden tus operaciones con la tranquilidad de tener el control de la información y
            el estatus de tu portafolio.</p>
          <p class="text dark">Más fácil de recuperar.</p>
          <img class="img-fluid move three" src="/assets/images/newImages/signoFondo3.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move four" src="/assets/images/newImages/signoFondo6.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move five" src="/assets/images/newImages/signoFondo1.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
      </div>
    </div>
  </section>

  <section id="planesyprecios">
    <app-subscriptionpayment-new #subscriptionpaymentNew [isNew]="true"></app-subscriptionpayment-new>
  </section>

  <section id="servicios">
    <div class="container-fluid" [ngClass]="!isNew ? 'py-3' : 'py-5'">
      <div class="align-items-center row justify-content-between">
        <div class="mx-auto light">
          <p class="title dark" *ngIf="isNew">Servicios adicionales</p>
          <div [ngClass]="!isNew ? 'mb-5' : 'my-5'">
            <p class="text dark">
              Con a-lender solicita llamadas y/o visitas de cobranza y deja que nuestros agentes se encarguen de cobrar
              tu dinero.
            </p>
          </div>

          <div class="row justify-content-sm-start justify-content-center content">

           <!--<div class="plan-options mb-5 mx-3" *ngFor="let item of dataViewCallVerification"
            [ngClass]="{'btn_plan': item.typePerson === 0, 'btn_plan_change': item.typePerson !== 0}">
            <p class="text fw-bold">{{item.serviceName}}</p>
            <p class="mb-2"> A {{ item.typePerson === 1 ? 'persona moral' : 'persona física' }}</p>
            <div class="align-items-center d-flex justify-content-center">
              <img class="" *ngIf="item.typePerson === 0" style=" width:25px;"
                src="/assets/images/logos/white-PF-01.png" />
              <img class="" *ngIf="item.typePerson !== 0" style=" width:25px;"
                src="/assets/images/logos/white-PF-02.png" />
              <div class="form-check form-switch">
                <input (change)="onCheck($event,item)" [checked]="item.typePerson" class="form-check-input"
                  [ngClass]="{'color-btn': item.typePerson === 0, 'color-btn-switch': item.typePerson !== 0}"
                  type="checkbox" />
              </div>
              <img class="" *ngIf="item.typePerson === 0" style=" width:25px;"
                src="/assets/images/logos/white-PM-02.png" />
              <img class="" *ngIf="item.typePerson !== 0" style=" width:25px;"
                src="/assets/images/logos/white-PM-01.png" />
            </div>
            <p class="subtitle">
              {{(item.amount | currency).replace(".00", "")}} <span class="small_text">MXN</span>
            </p>
            <p class="mb-4">Incluye IVA</p>
            <button class="mb-2"
              [ngClass]="{'btn_yellow_light ': item.typePerson === 0, 'btn_primaryOP_light': item.typePerson !== 0}">
              <a class="nav-link" (click)="selectService(item)">Seleccionar</a>
            </button>
            <p *ngIf=" item.serviceName == 'Llamada de verificación'" class="md-2 mt-1" style="font-size: x-small">*Disponible
              en todo México</p>
              <p class="md-2 text-link condtions" (click)="showConditionsExternalServices(item.serviceName)">Ver condiciones</p>
          </div>

          <div class="plan-options mb-5 mx-3" *ngFor="let item of dataViewVisitVerification"
          [ngClass]="{'btn_plan': item.typePerson === 0, 'btn_plan_change': item.typePerson !== 0}">
          <p class="text fw-bold">{{item.serviceName}}</p>
          <p class="mb-2">A {{ item.typePerson === 1 ? 'persona moral' : 'persona física' }}</p>
          <div class="align-items-center d-flex justify-content-center">
            <img class="" *ngIf="item.typePerson === 0" style=" width:25px;"
              src="/assets/images/logos/white-PF-01.png" />
            <img class="" *ngIf="item.typePerson !== 0" style=" width:25px;"
              src="/assets/images/logos/white-PF-02.png" />
            <div class="form-check form-switch">
              <input (change)="onCheck($event,item)" [checked]="item.typePerson" class="form-check-input"
                [ngClass]="{'color-btn': item.typePerson === 0, 'color-btn-switch': item.typePerson !== 0}"
                type="checkbox" />
            </div>
            <img class="" *ngIf="item.typePerson === 0" style=" width:25px;"
              src="/assets/images/logos/white-PM-02.png" />
            <img class="" *ngIf="item.typePerson !== 0" style=" width:25px;"
              src="/assets/images/logos/white-PM-01.png" />
          </div>
          <p class="subtitle">
            {{(item.amount | currency).replace(".00", "")}} <span class="small_text">MXN</span>
          </p>
          <p class="mb-4">Incluye IVA</p>
          <button class="mb-2"
            [ngClass]="{'btn_yellow_light ': item.typePerson === 0, 'btn_primaryOP_light': item.typePerson !== 0}">
            <a class="nav-link" (click)="selectService(item)">Seleccionar</a>
          </button>
          <p *ngIf=" item.serviceName == 'Visita de verificación'" class="md-2 mt-1" style="font-size: x-small">*Sujeto a
            <a class="text-link white" (click)="showConditionsExternalServices('covarage')">cobertura</a></p>
            <p class="md-2 condtions"  (click)="showConditionsExternalServices(item.serviceName)">Ver condiciones</p>
        </div>-->

            <div class="plan-options mb-5 mx-3" *ngFor="let item of dataViewCall"
              [ngClass]="{'btn_plan': item.typePerson === 0, 'btn_plan_change': item.typePerson !== 0}">
              <p class="text fw-bold">{{item.serviceName}}</p>
              <p class="mb-2"> A {{ item.typePerson === 1 ? 'persona moral' : 'persona física' }}</p>
              <div class="align-items-center d-flex justify-content-center">
                <img class="" *ngIf="item.typePerson === 0" style=" width:25px;"
                  src="/assets/images/logos/white-PF-01.png" />
                <img class="" *ngIf="item.typePerson !== 0" style=" width:25px;"
                  src="/assets/images/logos/white-PF-02.png" />
                <div class="form-check form-switch">
                  <input (change)="onCheck($event,item)" [checked]="item.typePerson" class="form-check-input"
                    [ngClass]="{'color-btn': item.typePerson === 0, 'color-btn-switch': item.typePerson !== 0}"
                    type="checkbox" />
                </div>
                <img class="" *ngIf="item.typePerson === 0" style=" width:25px;"
                  src="/assets/images/logos/white-PM-02.png" />
                <img class="" *ngIf="item.typePerson !== 0" style=" width:25px;"
                  src="/assets/images/logos/white-PM-01.png" />
              </div>
              <p class="subtitle">
                {{(item.amount | currency).replace(".00", "")}} <span class="small_text">MXN</span>
              </p>
              <p class="mb-4">Incluye IVA</p>
              <button class="mb-2"
                [ngClass]="{'btn_yellow_light ': item.typePerson === 0, 'btn_primaryOP_light': item.typePerson !== 0}">
                <a class="nav-link" (click)="selectService(item)">Seleccionar</a>
              </button>
              <p *ngIf=" item.serviceName == 'Llamada de cobranza'" class="md-2 mt-1" style="font-size: x-small">*Disponible
                en todo México</p>
                <p class="md-2 text-link condtions" (click)="showConditionsExternalServices(item.serviceName)">Ver condiciones</p>
            </div>

            <div class="plan-options mb-5 mx-3" *ngFor="let item of dataViewVisit"
              [ngClass]="{'btn_plan': item.typePerson === 0, 'btn_plan_change': item.typePerson !== 0}">
              <p class="text fw-bold">{{item.serviceName}}</p>
              <p class="mb-2">A {{ item.typePerson === 1 ? 'persona moral' : 'persona física' }}</p>
              <div class="align-items-center d-flex justify-content-center">
                <img class="" *ngIf="item.typePerson === 0" style=" width:25px;"
                  src="/assets/images/logos/white-PF-01.png" />
                <img class="" *ngIf="item.typePerson !== 0" style=" width:25px;"
                  src="/assets/images/logos/white-PF-02.png" />
                <div class="form-check form-switch">
                  <input (change)="onCheck($event,item)" [checked]="item.typePerson" class="form-check-input"
                    [ngClass]="{'color-btn': item.typePerson === 0, 'color-btn-switch': item.typePerson !== 0}"
                    type="checkbox" />
                </div>
                <img class="" *ngIf="item.typePerson === 0" style=" width:25px;"
                  src="/assets/images/logos/white-PM-02.png" />
                <img class="" *ngIf="item.typePerson !== 0" style=" width:25px;"
                  src="/assets/images/logos/white-PM-01.png" />
              </div>
              <p class="subtitle">
                {{(item.amount | currency).replace(".00", "")}} <span class="small_text">MXN</span>
              </p>
              <p class="mb-4">Incluye IVA</p>
              <button class="mb-2"
                [ngClass]="{'btn_yellow_light ': item.typePerson === 0, 'btn_primaryOP_light': item.typePerson !== 0}">
                <a class="nav-link" (click)="selectService(item)">Seleccionar</a>
              </button>
              <p *ngIf=" item.serviceName == 'Visita de cobranza'" class="md-2 mt-1" style="font-size: x-small">*Sujeto a
                <a class="text-link white" (click)="showConditionsExternalServices('covarage')">cobertura</a></p>
                <p class="md-2 condtions"  (click)="showConditionsExternalServices(item.serviceName)">Ver condiciones</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section id="contacto">
    <div class="container-fluid py-md-5 py-0">
      <div class="align-items-center row justify-content-around">
        <div class="col-md-4 order-first">
          <form class="form-group" [formGroup]="commentsForm" (ngSubmit)="commentsOnSubmit()">
            <div class="form-floating mb-3">
              <input autocomplete="name" class="form-control" formControlName="name" id="floatingnameInput" name="name"
                placeholder="Escribe tu nombre" type="text">
              <label for="floatingnameInput">Nombre</label>
              <mat-error *ngIf="submitted && f_commentsForm['name'].hasError('required')">Este campo es
                obligatorio</mat-error>
            </div>
            <div class="form-floating mb-3">
              <input autocomplete="email" class="form-control" formControlName="email" id="floatingemailInput"
                pattern="^[^@]+@[^@]+\.[a-zA-Z]{2,}$" placeholder="Escribe tu email" type="email">
              <label for="floatingemailInput">Correo</label>
              <mat-error *ngIf="submitted && f_commentsForm['email'].hasError('required')">Este campo es
                obligatorio</mat-error>
              <mat-error *ngIf="submitted && f_commentsForm['email'].hasError('email')">El formato del correo no es
                válido</mat-error>
            </div>
            <div class="form-floating mb-3">
              <textarea type="text" class="form-control" formControlName="comments" id="floatingcommentTextarea"
                name="comments" placeholder="Escribe tus dudas o comentarios" rows="5"></textarea>
              <label for="floatingcommentTextarea">Dudas o comentarios</label>
              <mat-error *ngIf="submitted && f_commentsForm['comments'].hasError('required')">Este campo es
                obligatorio</mat-error>
            </div>
            <!--div class="form-check mb-2">
                            <input class="form-check-input" type="checkbox" id="floatingCheck">
                            <label class="form-check-label" for="floatingCheck">No soy un robot</label>
                        </div-->
            <div class="form-floating mb-3">
              <re-captcha formControlName="captcha" (resolved)="resolved($event)"></re-captcha>
            </div>
            <div class="mt-2">
              <button class="btn_primary light" type="submit">
                <a class="nav-link btn-big">Enviar</a>
              </button>
            </div>
          </form>
          <img class="img-fluid move one" src="/assets/images/newImages/signoFondo6.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move two" src="/assets/images/newImages/signoFondo2.webp"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move three" src="/assets/images/newImages/signoFondo3.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
        <div class="col-md-4 text-md-start text-center order-second mb-md-0 mb-5">
          <p class="title dark">¿Necesitas más información?</p>
          <p class="text dark">Envíanos tus dudas o comentarios y nos pondremos en contacto contigo.</p>

          <div class="social">
            <a href="https://www.instagram.com/a_lendermx/" class="me-4" target="_blank" rel="noreferrer">
              <i class="bx bxl-instagram-alt"></i>
            </a>
            <a href="https://www.facebook.com/alendermx/" class="me-4" target="_blank" rel="noreferrer">
              <i class="bx bxl-facebook"></i>
            </a>
            <a href="https://twitter.com/a_lendermx/" class="d-inline-flex me-4" target="_blank" rel="noreferrer">
              <svg viewBox="0 0 24 24" aria-hidden="true"
                class="r-18jsvk2 r-4qtqp9 r-yyyyoo r-16y2uox r-8kz0gk r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-lrsllp"
                style="height: 1.813rem;">
                <g>
                  <path
                    d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z">
                  </path>
                </g>
              </svg>
            </a>
            <a href="https://linkedin.com/company/a-lender/" target="_blank" rel="noreferrer">
              <i class="bx bxl-linkedin"></i>
            </a>
          </div>
          <img class="img-fluid move four" src="/assets/images/newImages/signoFondo2.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move five" src="/assets/images/newImages/signoFondo2.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
          <img class="img-fluid move six" src="/assets/images/newImages/signoFondo1.svg"
            alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
        </div>
      </div>
    </div>
    <div class="mask"></div>
  </section>
</div>
