import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-help-info',
  templateUrl: './dialog-help-info.component.html',
  styleUrls: ['./dialog-help-info.component.scss']
})
export class DialogHelpInfoComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() isHtml: boolean = false;
  htmlDescription: SafeHtml;
  
  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  load(): void {
    if (this.isHtml) {
      this.htmlDescription = this.transform(this.description);
    } else {
      this.htmlDescription = this.description;
    }
  }

  private transform(value: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }

}
