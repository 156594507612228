import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(private apiService: ApiService) { }

  getBanks() {
    return this.apiService.get('catalog', 'bank')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getCities(countryId: string, stateId: string) {
    return this.apiService.get('catalog', 'country' + '/' + countryId + '/' + 'state' + '/' + stateId + '/city')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getCountries() {
    return this.apiService.get('catalog', 'country')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getCurrencies() {
    return this.apiService.get('catalog', 'currency')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getGenders() {
    return this.apiService.get('catalog', 'gender')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getLanguages() {
    return this.apiService.get('catalog', 'language')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getPhoneCodes() {
    return this.apiService.get('catalog', 'phonecode')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getStates(countryId: string) {
    return this.apiService.get('catalog', 'country' + '/' + countryId + '/state')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getPersonTypes() {
    return this.apiService.get('catalog', 'persontype')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getCivilStatus() {
    return this.apiService.get('catalog', 'civilstatus')
    .pipe(map(response => {
      return response.data;
    }));
  }

  getNationalities() {
    return this.apiService.get('catalog', 'nationality')
    .pipe(map(response => {
      return response.data;
    }));
  }

  dateAdd(type: string, date: string, value: number) {
    return this.apiService.get('catalog', 'date/add', type + '/' + date + '/' + value, false)
    .pipe(map(response => {
      return response.data;
    }));
  }
}
