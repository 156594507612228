<h1 class="mb-2" mat-dialog-title>Buscar solicitante</h1>
<div class="d-flex justify-content-end">
    <mat-form-field class="input_filter">
        <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #filterText autocapitalize="off"
            autocomplete="off">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
</div>

<div class="dataTable">
    <div class="table-responsive">
        <div class="react-bootstrap-table">
            <table *ngIf="dsSearchCustomer" mat-table [dataSource]="dsSearchCustomer"
                (dblclick)="onSubmitSearchCustomer()">
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
                </ng-container>

                <ng-container matColumnDef="middleName">
                    <th mat-header-cell *matHeaderCellDef> Segundo nombre </th>
                    <td mat-cell *matCellDef="let row"> {{row.middleName}} </td>
                </ng-container>

                <ng-container matColumnDef="lastName1">
                    <th mat-header-cell *matHeaderCellDef> Primer apellido </th>
                    <td mat-cell *matCellDef="let row"> {{row.lastName1}} </td>
                </ng-container>

                <ng-container matColumnDef="lastName2">
                    <th mat-header-cell *matHeaderCellDef> Segundo apellido </th>
                    <td mat-cell *matCellDef="let row"> {{row.lastName2}} </td>
                </ng-container>

                <ng-container matColumnDef="phoneMobile">
                    <th mat-header-cell *matHeaderCellDef> Teléfono celular </th>
                    <td mat-cell *matCellDef="let row"> {{row.phoneMobile}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Correo electrónico </th>
                    <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="searchCustomerColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: searchCustomerColumns; let i = index"
                    (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
            </table>
        </div>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>
</div>

<div class="d-flex flex-wrap gap-2 justify-content-end">
    <button class="btn btn-outline-primary external-btn" mat-dialog-close>
        Cerrar
    </button>
    <button class="btn btn-primary external-btn" (click)="onSubmitSearchCustomer()">
        Aceptar
    </button>
</div>