<div *ngIf="buttonsAction">
    <h5>Detalle de pagos</h5>
    <hr>

    <p>Selecciona el renglón del pago deseado</p>

    <div class="d-flex justify-content-end mb-4">
        <div class="form-group col-md-4 col-12">
            <label>Buscar</label>
            <div>
                <i class="bx bx-search input_icon"></i>
                <input class="form-control" (keyup)="applyFilter($event)" #filterText placeholder="" type="text" />
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="table-responsive">
        <div class="react-bootstrap-table">
            <table mat-table [dataSource]="loanPaymentDataSource" class="table table align-middle" [ngClass]="isLender ? 'bg-primary' : 'bg-secondary'">
                <tbody>
                    <ng-container matColumnDef="datePayment">
                        <th mat-header-cell *matHeaderCellDef> Fecha de pago </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.datePayment | date:'short'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lenderLoanCode">
                        <th [hidden]="isLender" mat-header-cell *matHeaderCellDef> ID préstamo </th>
                        <td [hidden]="isLender" mat-cell *matCellDef="let row"> {{row.loanId}} </td>
                    </ng-container>
                    <ng-container matColumnDef="borrowerLoanCode">
                        <th [hidden]="!isLender" mat-header-cell *matHeaderCellDef> ID préstamo </th>
                        <td [hidden]="!isLender" mat-cell *matCellDef="let row"> {{row.loanId}} </td>
                    </ng-container>
                    <ng-container matColumnDef="paymentAmount">
                        <th mat-header-cell *matHeaderCellDef> Monto del pago </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.paymentAmount | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="wayToPay">
                        <th mat-header-cell *matHeaderCellDef> Forma de pago </th>
                        <td mat-cell *matCellDef="let row"> {{getWayToPay(row.wayToPay)}} </td>
                    </ng-container>
                    <ng-container matColumnDef="borrowerName">
                        <th mat-header-cell *matHeaderCellDef> Nombre del solicitante </th>
                        <td mat-cell *matCellDef="let row"> {{row.borrowerName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="loanAmount">
                        <th mat-header-cell *matHeaderCellDef> Monto del préstamo </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.loanAmount | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="totalPayment">
                        <th mat-header-cell *matHeaderCellDef> Monto total a pagar </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.totalPayment | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="currentBalance">
                        <th mat-header-cell *matHeaderCellDef> Saldo total a la fecha </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.balance | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="balanceOnArrears">
                        <th mat-header-cell *matHeaderCellDef> Saldo con atraso a la fecha </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.balanceExpired | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="buttonDeletePayment">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                            <i class="bx bx-x" (click)="onDeletePayment(row.loanPaymentId, row.paymentAmount)"></i>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="loanPaymentColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: loanPaymentColumns;"></tr>
                </tbody>
            </table>
        </div>
    </div>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" [ngClass]="!buttonsAction ? '' : 'mb-0'"></mat-paginator>

    <div class="d-flex flex-wrap gap-2 justify-content-end">
        <button class="btn btn-outline-primary external-btn" (click)="exportExcel()" *ngIf="!buttonsAction">
            Excel
        </button>
    </div>
</div>
