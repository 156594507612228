import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogSimulatorComponent } from 'src/app/components/dialog-simulator/dialog-simulator.component';
import { UserLogged } from 'src/app/models/user/userlogged.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
// import jump from 'jump.js'
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import Typewriter from 't-writer.js';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { ExternalServicesDTO } from 'src/app/models/loan/loan.dto.model';
import { MatTableDataSource } from '@angular/material/table';
import { LoanService } from 'src/app/services/loan/loan.service';
import { DialogShowConditionsExternalServices } from 'src/app/components/dialog-show-conditions-external-services/dialog-show-conditions-external-services.component';
import { ExternalServiceCollectionComponent } from 'src/app/components/external-services-collection/external-service-collection.component';

@Component({
	selector: 'app-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
	@ViewChild('externalServiceCollection') externalServiceCollection: ExternalServiceCollectionComponent;
	@Input() isNew: boolean = true;
	opened = false;
	currentUser: UserLogged;
	hiddenMenu: boolean = true;
	currentUserModeLenderSelectedLenderValue: boolean = null;
	token: string | undefined;


	commentsForm: FormGroup;
	submitted = false;
	dataKeyreCaptcha = environment.datasitekey;
	personTypeCall: number = 0;
	personTypeVisit: number = 0;
	personTypeCallVerification: number =0;
	personTypeVisitVerification: number = 0;
	dataSourceExternalServices = new MatTableDataSource<ExternalServicesDTO>([]);
	externalServiceSelected: ExternalServicesDTO;
	dataViewCall: any;
	dataViewVisit: any;
	dataViewCallVerification: any;
	dataViewVisitVerification: any;

	serviceName: string;

	changeBolean: boolean = false;
	selectedCall: boolean = false;
	selectedVisit: boolean = false;


	private fragment: string;

	private body = document.querySelector("body");
	modalActive = true;

	constructor(private authService: AuthenticationService,
		private router: Router,
		private dialog: MatDialog,
		private formBuilder: FormBuilder,
		private notificationService: NotificationService,
		private loanService: LoanService,
		private route: ActivatedRoute) {
		this.authService.currentUser.subscribe(x => this.currentUser = x);
		this.createcommentsForm();
		this.token = undefined;
	}

	ngOnInit(): void {
		this.getExternalServices();
		if (this.currentUser) {
		} else {
			const target = document.querySelector('.tw');

			const writer = new Typewriter(target, {
				cursorColor: 'black',
				loop: true,
				typeColor: 'black'
			})

			writer
				.strings(
					2500,
					"familiares.",
					"amigos.",
					"clientes.",
					"conocidos.",
					"empleados."
				)
				.start()
		}

		this.route.fragment.subscribe(fragment => {

		});
		this.router.navigate(['/inicio']);
	}

	onShowMenu() {
		this.hiddenMenu = false;
	}

	onCloseMenu() {
		this.hiddenMenu = true;
	}

	openSimulator(isLender: boolean = true) {
		this.authService.changeUserModeLenderValue(isLender);
		let config = new MatDialogConfig();
		config = {
			maxWidth: '100vw',
			maxHeight: '100vh',
			height: '100%',
			width: '100%',
			panelClass: 'full-screen-modal',
		};
		const dialog = this.dialog.open(DialogSimulatorComponent, config);
	}

	get f_commentsForm() { return this.commentsForm.controls; }

	createcommentsForm() {

		this.commentsForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			comments: ['', [Validators.required]],
			captcha: ['', [Validators.required]]
		});
	}

	commentsOnSubmit() {
		this.submitted = true;

		if (this.commentsForm.invalid) {
			return;
		}

		const data = {
			name: this.f_commentsForm['name'].value,
			email: this.f_commentsForm['email'].value,
			comments: this.f_commentsForm['comments'].value
		}

		this.notificationService.sendComments(data)
			.subscribe(() => {
				this.commentsForm.reset();
			});
	}

	jumpSection() {
		// jump(1600);
	}

	resolved(captchaResponse: string) {
	}

	showPromotion() {
		this.dialog.open(DialogConfirmComponent, {
			autoFocus: false,
			height: '85%',
			width: '80%',
			disableClose: true,
			data: {
				promotion: true,
				accept: true
			}
		}
		).afterClosed().subscribe((confirmado: Boolean) => {
		});
	}

	close() {
		this.modalActive = false;
		// this.body.classList.remove("modal-open");
	}

	showConditionsExternalServices(serviceName: string) {
		this.dialog.open(DialogShowConditionsExternalServices, {
			data: {
				serviceName: serviceName
			}
		}).afterClosed().subscribe(
			(confirmado: Boolean) => { }
		);
	}

	getExternalServices() {
		this.loanService.getExternalServicesLanding().subscribe((result) => {
			this.dataSourceExternalServices.data = result;
			this.dataViewCall = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeCall && x.serviceName == "Llamada de cobranza");
			this.dataViewVisit = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeVisit && x.serviceName == "Visita de cobranza");
			//this.dataViewCallVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeCallVerification && x.serviceName == "Llamada de verificación");
			//this.dataViewVisitVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeVisitVerification && x.serviceName == "Visita de verificación");
		});
	}

	onCheck(data: any, item: any) {

		if (item.typePerson == 0 && item.serviceName == "Llamada de cobranza") {
			this.personTypeCall = 1;
		} else if (item.typePerson == 1 && item.serviceName == "Llamada de cobranza") {
			this.personTypeCall = 0;
		}

		if (item.typePerson == 0 && item.serviceName == "Visita de cobranza") {
			this.personTypeVisit = 1;
		} else if (item.typePerson == 1 && item.serviceName == "Visita de cobranza") {
			this.personTypeVisit = 0;
		}

		this.dataViewCall = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeCall && x.serviceName == "Llamada de cobranza");
		this.dataViewVisit = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeVisit  && x.serviceName == "Visita de cobranza");

		if (item.typePerson == 0 && item.serviceName == "Llamada de verificación") {
			this.personTypeCallVerification = 1;
		} else if (item.typePerson == 1 && item.serviceName == "Llamada de verificación") {
			this.personTypeCallVerification = 0;
		}

		if (item.typePerson == 0 && item.serviceName == "Visita de verificación") {
			this.personTypeVisitVerification = 1;
		} else if (item.typePerson == 1 && item.serviceName == "Visita de verificación") {
			this.personTypeVisitVerification = 0;
		}

		this.dataViewCallVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeCallVerification && x.serviceName == "Llamada de verificación");
		this.dataViewVisitVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeVisitVerification && x.serviceName == "Visita de verificación");
	}

	selectService(externalServiceCollections: ExternalServicesDTO) {
		this.externalServiceSelected = externalServiceCollections;
		this.serviceName = externalServiceCollections.serviceName;

		this.router.navigate(['servicios/'])
		/*if (this.externalServiceCollection != null) {
			this.externalServiceCollection.setExternalServiceCollection(this.externalServiceSelected);
		}*/
	}
	

}
