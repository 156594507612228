import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalLists } from 'src/app/global/global-data';
import { Warranty } from 'src/app/models/amortize/amortize.model';

@Component({
	selector: 'app-dialog-warrantys-conditions',
	templateUrl: './dialog-warrantys-conditions.component.html',
	styleUrls: ['./dialog-warrantys-conditions.component.scss']
})

export class DialogWarrantysConditionsComponent implements OnInit {
	mode = "";
	warrantysForm: FormGroup;
	submitted = false;

	warrantysColumns = [
		'number',
		'description',
		'buttonRemove'
	];

	typeOfWarrantys = GlobalLists.typeOfWarranties;

	filteredOptions: Observable<string[]>;

	warrantys = <Warranty[]>[];

	dsWarrantys = new MatTableDataSource<Warranty>(this.warrantys);

	constructor(
		private formBuilder: FormBuilder,
		private changeDetector: ChangeDetectorRef) {
		this.createWarrantysForm();
	}

	get fWarrantys() { return this.warrantysForm.controls; }

	ngOnInit(): void {
		if (this.warrantys) {
			this.dsWarrantys = new MatTableDataSource<Warranty>(this.warrantys);
		}

		this.filteredOptions = this.warrantysForm.controls['description'].valueChanges
			.pipe(
				startWith(''),
				map(value => this._filter(value))
			);
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	createWarrantysForm() {
		this.warrantysForm = this.formBuilder.group({
			description: ['', Validators.required]
		});
	}

	onSubmitWarranty() {
		this.submitted = true;

		if (this.warrantysForm.invalid) {
			return;
		}

		this.addWarranty();
	}

	onDeleteWarranty(index: number): void {
		this.warrantys.splice(index, 1);
		this.dsWarrantys = new MatTableDataSource<Warranty>(this.warrantys);
	}

	addWarranty(): void {
		var lastWarrantysNumber = 0;

		if (this.warrantys.length > 0) {
			lastWarrantysNumber = Math.max.apply(Math, this.warrantys.map(function (o: { number: any; }) { return o.number; }))
		}

		this.warrantys.push(<Warranty>{
			number: lastWarrantysNumber + 1,
			description: this.fWarrantys['description'].value
		});

		this.dsWarrantys = new MatTableDataSource<Warranty>(this.warrantys);
		this.fWarrantys['description'].patchValue("");
	}

	private _filter(value: string): string[] {
		let filterValue = '';
		if (value) {
			filterValue = value.toLowerCase();
		}
		return this.typeOfWarrantys.filter(option => option.toLowerCase().includes(filterValue));
	}

	onClickWarranty() {
		return this.warrantys;
	}

	validateRequiredFormWarrantys(formControlName: string): boolean {
		if (this.fWarrantys[formControlName] == null) {
			return;
		}

		if (this.fWarrantys[formControlName].errors !== null && this.fWarrantys[formControlName].errors['required']) {
			return true;
		}

		return false;
	}
}
