<h1 class="mb-2" mat-dialog-title>Préstamos</h1>
<div class="d-flex justify-content-end">
    <mat-form-field class="input_filter">
        <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #filterText autocapitalize="off" autocomplete="off">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
</div>

<div class="dataTable">
    <table *ngIf="dsSearchLoan" mat-table [dataSource]="dsSearchLoan" (dblclick)="onSubmitSearchLoan()">
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Estatus </th>
            <td mat-cell *matCellDef="let row"> {{getStatus(row.loanRequestStatus)}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let row"> {{getName(row.firstName, row.middleName, row.lastName1, row.lastName2)}} </td>
        </ng-container>
        
        <ng-container matColumnDef="phoneMobile">
            <th mat-header-cell *matHeaderCellDef> Teléfono celular </th>
            <td mat-cell *matCellDef="let row"> {{row.phoneMobile}} </td>
        </ng-container>
        
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Correo electrónico </th>
            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="searchLoanColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: searchLoanColumns; let i = index" (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>
</div>

<div class="d-flex flex-wrap gap-2 justify-content-end">
    <button class="btn btn-outline-primary external-btn" mat-dialog-close>
        Cerrar
    </button>  
</div>