import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { QuoteDTO } from 'src/app/models/quote/quote.dto.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { QuoteService } from 'src/app/services/quote/quote.service';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.scss']
})
export class QuoteListComponent implements OnInit {

  quoteColumns: string[] = [
    'dateCreated',
    'loanAmount',
    'startDate',
    'typeOfPeriod',
    'totalPeriods',
    'monthlyRate',
    'totalInterest',
    'totalCommissions',
    'totalPayment',
    'goToSimulator'
  ];
  quoteDataSource: MatTableDataSource<QuoteDTO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  typeofperiodList = [];

  constructor(private quoteService: QuoteService,
              private authService: AuthenticationService,
              private typeofperiodService: TypeofperiodService) { }

  ngOnInit(): void {
    this.getTypeofperiods();
    this.getQuotes();
  }

  getQuotes() {
    this.quoteService.getList(this.authService.currentUserValue.customerId)
      .subscribe((result) => {
        if (result) {
          this.quoteDataSource = new MatTableDataSource(result);
        } else {
          this.quoteDataSource = new MatTableDataSource([]);
        }
        
        this.quoteDataSource.paginator = this.paginator;
        this.quoteDataSource.sort = this.sort;
      });
  }

  getTypeofperiods() {
    this.typeofperiodService.getList()
      .subscribe((result) => {
        this.typeofperiodList = result;
      });
  }

  getTypeofperiodDesc(key) {
    return this.typeofperiodList.find(x => x.key === key).value;
  }

  goToSimulator(quoteId) {
    localStorage.removeItem('quoteTemp');
    localStorage.removeItem('tableAmortizeTemp');
    window.location.href = "simulator/" + quoteId;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.quoteDataSource.filter = filterValue.trim().toLowerCase();
  }
}
