import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RequiredDocumentDTO } from 'src/app/models/required-documents/requiredDocuments.dto.model';
import { RequiredDocumentsConfigService } from 'src/app/services/required-documents-config/required-documents-config.service';

@Component({
  selector: 'app-required-documents',
  templateUrl: './required-documents.component.html',
  styleUrls: ['./required-documents.component.scss']
})
export class RequiredDocumentsComponent implements OnInit {

  requiredDocuments: RequiredDocumentDTO[];

  columnsRequiredDocuments: string[] = [
    'description',
    'required'
  ];

  constructor(private requiredDocumentsConfigService: RequiredDocumentsConfigService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.requiredDocumentsConfigService.getRequiredDocumentObserver().subscribe((data) => {
      this.requiredDocuments = data;
      this.cdRef.detectChanges();
    });
  }

  patchRequiredDocument() {
    this.requiredDocumentsConfigService.patchRequiredDocument(this.requiredDocuments)
  }
}
