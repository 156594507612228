import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Balance, BalanceMovements } from 'src/app/models/subscriptionpayment/subscriptionpayment.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})

export class AccountComponent implements OnInit {
	rowSelected: BalanceMovements = <BalanceMovements>{};
	indexSelected: number;
	balanceMovementsDataSource: MatTableDataSource<BalanceMovements>;

	myBalance: Balance = null;

	@Input() isLender: boolean;
	@Input() showNew: boolean;
	@Input() showRenew: boolean;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

  activePanel: MatExpansionPanel | null = null;
	person: string;
	erorTarjet: void;

  togglePanel(panel: MatExpansionPanel) {
    if (panel === this.activePanel) {
      if (panel.expanded) {
        panel.close();
        this.activePanel = null;
      } else {
        panel.open();
      }
    } else {
      if (this.activePanel) {
        this.activePanel.close();
      }
      panel.open();
      this.activePanel = panel;

	  this.balanceMovementsDataSource = new MatTableDataSource(this.myBalance.balanceMovements);
	  this.balanceMovementsDataSource.paginator = this.paginator;
	  this.balanceMovementsDataSource.sort = this.sort;
    }
  }

	balanceMovementsColumns: string[] = [
		'date',
		'movement',
		'amount',
		'methodPayment',
		'errorMessage'
	];

	constructor(public authService: AuthenticationService,
		private router: Router,
		private subscriptionpaymentService: SubscriptionpaymentService) { }

	ngOnInit(): void {
		this.authService.userModeLender.subscribe((data) => {
			this.isLender = data;
		});

		this.subscriptionpaymentService.getBalanceMovements().subscribe((summaryData) => {
			this.myBalance = summaryData;
		});
		
	}

	onClickPage() {
		this.rowSelected = <BalanceMovements>{};
		this.indexSelected = null;
	}

	onRowClicked(row, index) {
		this.balanceMovementsDataSource = row;
		this.indexSelected = index;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.balanceMovementsDataSource.filter = filterValue.trim().toLowerCase();
	}

	openNew(action: string) {
		this.router.navigate(['suscripciones/contratar-plan/' + action]);
	}

}
