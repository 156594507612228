import { Component, ViewChild, HostListener } from '@angular/core';
import { AuthenticationService } from '././services/authentication/authentication.service';
import { Router } from '@angular/router';
import { UserLogged } from './models/user/userlogged.model';
import { Location } from "@angular/common";
import { NotificationService } from './services/notification/notification.service';
import { SignalrService } from './services/signalr/signalr.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogTutorialComponent } from './components/dialog-tutorial/dialog-tutorial.component';
import { SubscriptionsIndexComponent } from './components/subscriptions-index/subscriptions-index.component';
import { NotificationDTO } from './models/notification/notification.dto.model';
import { IndexComponent } from './views/index/index.component';
import { SubscriptionpaymentService } from './services/subscriptionpayment/subscriptionpayment.service';
import { Cart } from './models/subscriptionpayment/subscriptionpayment.model';
declare global {
	interface Window { Conekta: any, dynamic: string }
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent {
	opened = false;
	currentUser: UserLogged = new UserLogged;
	// Dropdowns header
	menuDropdown: boolean = false;
	settingsDropdown: boolean = false;
	shortMenu: boolean = false;
	largeMenu: boolean = false;
	// Finish dropdowns header
	loggedMode: boolean = false;
	indexMode: boolean = false;
	loginMode: boolean = false;
	signupMode: boolean = false;
	validateEmailMode: boolean = false;
	forgotPasswordMode: boolean = false;
	resetPasswordMode: boolean = false;
	termsMode: boolean = false;
	privacyMode: boolean = false;
	cookiesPolicyMode: boolean = false;
	legalMessage: boolean = false;
	blogMode: boolean = false;
	faqMode: boolean = false;
	mediapermalinkMode: boolean = false;
	questionMode: boolean = false;
	billMode: boolean = false;
	notFoundMode: boolean = false;
	borrowerSwitchActive: boolean = false;
	lenderSwitchActive: boolean = false;
	notificationsCount: number = 0;
	notificationShow: string = "0";

	cartItemsCount: number = 0;
	cartItemsShow: string = "0";
	currentUserModeLenderSelectedLenderValue: any;
	menuListActive: boolean = false;
	showLayout: boolean = false;
	activateInitialUserMode: string | null = null;
	notificationList: NotificationDTO[] = [];
	IdSelected: string;
	@ViewChild('appIndex') appIndex: IndexComponent;
	body = document.querySelector("body");
	cart: Cart;
	currentYear = new Date().getFullYear();

	constructor(private authService: AuthenticationService,
		private router: Router,
		private location: Location,
		private notificationService: NotificationService,
		private dialog: MatDialog,
		public signalrService: SignalrService,
		private subscriptionpaymentService: SubscriptionpaymentService
	) {
		this.authService.currentUser.subscribe(x => this.currentUser = x);
	}

	ngOnInit() {
		this.notificationService.setAppComponent(this);
		this.subscriptionpaymentService.setAppComponent(this);

		var currentPath = this.location.path();

		if (!localStorage.getItem('currentUser') && !currentPath.includes("/iniciar-sesion")
			&& !currentPath.includes("/crear-cuenta") && !currentPath.includes("/crear-cuenta/")
			&& !currentPath.includes("/resetear-contrasena")
			&& !currentPath.includes("/forgotpassword")
			&& !currentPath.includes("/validateemail")
			&& !currentPath.includes("/terminos-y-condiciones")
			&& !currentPath.includes("/aviso-de-privacidad")
			&& !currentPath.includes("/politica-de-cookies")
			&& !currentPath.includes("/aviso-legal")
			&& !currentPath.includes("/blog")
			&& !currentPath.includes("/preguntas-frecuentes")
			&& !currentPath.includes("/preguntas")
			&& !currentPath.includes("/bill")
			&& !currentPath.includes("/inicio")
			&& !currentPath.includes("/home")
			&& !currentPath.includes("/login")
			&& !currentPath.includes("/signup")
			&& !currentPath.includes("/suscripciones")
			&& currentPath !== '') {
			if (currentPath.includes("/solicitud-de-prestamo") && (currentPath.includes("/solicitante") || currentPath.includes("/prestamista"))) {
				window.location.href = '/iniciar-sesion?redirect=' + currentPath;
			} else if (currentPath.includes("/prestamos") && (currentPath.includes("/detalle-de-servicio"))) {
				window.location.href = '/iniciar-sesion?redirect=' + currentPath;
			} else {
				this.notFoundMode = true;
				this.showLayout = true;
				this.indexMode = true;
				// this.body.classList.add("modal-open");
			}
		}

		this.currentUserModeLenderSelectedLenderValue = this.authService.currentUserModeLenderValue;

		this.activateInitialUserMode = localStorage.getItem('activateInitialUserMode');

		if (this.currentUserModeLenderSelectedLenderValue == null && this.activateInitialUserMode == null) {
			this.menuListActive = false;
		} else {
			this.menuListActive = true;
		}

		if (currentPath.includes("solicitante") || localStorage.getItem("solicitante") || localStorage.getItem("prestamista")) {
			this.menuListActive = true;

			if (localStorage.getItem("solicitante")) {
				this.switchActor(false, false);

				this.authService.changeUserModeLenderValue(false);
				// this.authService.changeUserModeSelectedValue(false);

			} else if (localStorage.getItem("prestamista")) {
				this.switchActor(true, false);

				this.authService.changeUserModeLenderValue(true);
				// this.authService.changeUserModeSelectedValue(true);
			}

			var arr = currentPath.split("/")
			arr.pop()

			currentPath = "/" + arr[1] + "/" + arr[2]
			localStorage.setItem("solicitante", currentPath)
		}

		this.authService.currentUser.subscribe(x => this.currentUser = x);

		this.signalrService.startConnection();
		this.signalrService.notificationListener();

		localStorage.removeItem("solicitante");
		localStorage.removeItem("prestamista");

		if (this.currentUser && this.currentUser.emailConfirmed) {
			this.loggedMode = true;

			this.getUnRead();
			this.getCartItems();

			this.authService.userModeLender
				.subscribe((result) => {
					if (result) {
						this.borrowerSwitchActive = false;
						this.lenderSwitchActive = true;
					} else {
						this.borrowerSwitchActive = true;
						this.lenderSwitchActive = false;
					}
				});

			if (localStorage.getItem('quoteTemp') && !currentPath.includes("/simulador")) {
				window.location.href = "/simulador";
			}

			return;
		}

		if (currentPath.trim() === "" || currentPath.trim().includes("/home") || currentPath.trim() === "/" || currentPath.trim().includes("/inicio")) {
			this.indexMode = true;
			this.showLayout = false;
			// this.body.classList.add("modal-open");
			return;
		}

		if (currentPath.includes("/login")) {
			window.location.href = "/iniciar-sesion";
			return;
		}

		if (currentPath.includes("/iniciar-sesion")) {
			this.loginMode = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/signup")) {
			window.location.href = "/crear-cuenta";
			return;
		}

		if (currentPath.includes("/crear-cuenta")) {
			this.signupMode = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/validateemail")) {
			this.validateEmailMode = true;
			this.showLayout = true;
			return;
		}

		if (this.currentUser && !this.currentUser.emailConfirmed && !currentPath.includes("/validateemail")) {
			window.location.href = "/validateemail/?email=" + this.currentUser.username;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/forgotpassword")) {
			this.forgotPasswordMode = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/resetear-contrasena")) {
			this.resetPasswordMode = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/terminos-y-condiciones")) {
			this.termsMode = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/aviso-de-privacidad")) {
			this.privacyMode = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/politica-de-cookies")) {
			this.cookiesPolicyMode = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/aviso-legal")) {
			this.legalMessage = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/blog")) {
			this.blogMode = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/preguntas-frecuentes")) {
			this.faqMode = true;
			this.showLayout = true;
			return;
		}

		if (currentPath.includes("/mediapermalink")) {
			this.mediapermalinkMode = true;
			return;
		}

		if (currentPath.includes("/preguntas")) {
			this.questionMode = true;
			return;
		}

		if (currentPath.includes("/bill")) {
			this.billMode = true;
			return;
		}
	}

	isBiggerScreen() {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width < 768) {
			return true;
		} else {
			return false;
		}
	}

	onGoToLogin(): void {
		this.router.navigate(['/iniciar-sesion']);
	}

	showSettingDropdown() {
		if (!this.settingsDropdown) {
			this.settingsDropdown = true;
		} else {
			this.settingsDropdown = false;
		}
	}

	showMenuDropdown() {
		if (!this.menuDropdown) {
			this.menuDropdown = true;
		} else {
			this.menuDropdown = false;
		}
	}

	showShortMenu() {
		if (!this.shortMenu) {
			this.shortMenu = true;
		} else {
			this.shortMenu = false;
		}
	}

	showLargeMenu() {
		if (!this.largeMenu) {
			this.largeMenu = true;
		} else {
			this.largeMenu = false;
		}
	}

	hideDropdown() {
		if (this.settingsDropdown || this.menuDropdown || this.shortMenu || this.largeMenu) {
			this.settingsDropdown = false;
			this.menuDropdown = false;
			this.shortMenu = false;
			this.largeMenu = false;
		}
	}

	onLogout(): void {
		this.opened = false;
		this.authService.logout();
		//this.router.navigate(['/'])
		window.location.href = "/";
	}

	switchActor(isLender: boolean, href: boolean) {
		this.authService.changeUserModeLenderValue(isLender);
		// this.authService.changeUserModeSelectedValue(isLender);

		if (isLender) {
			this.lenderSwitchActive = true;
			this.borrowerSwitchActive = false;
		} else {
			this.lenderSwitchActive = false;
			this.borrowerSwitchActive = true;
		}

		if (href) {
			window.location.href = "/";
		}
	}

	getUnRead(): void {
		this.notificationService.getUnRead().subscribe((data: NotificationDTO[]) => {
			this.notificationList = data;
		});

		this.notificationService.getNotificationCountObserver().subscribe((value) => {
			if (value >= 10) {
				this.notificationShow = "+9";
				this.notificationsCount = value;
			} else {
				this.notificationShow = value + "";
				this.notificationsCount = value;
			}
		});
	}

	public getCartItems() {
		this.subscriptionpaymentService.getCartList().subscribe(
			(data) => {
				this.cart = data;

				const cartItems = this.cart.externalServicesList.length;

				this.updateCarList(cartItems);
			}
		);
	}

	updateCarList(cartItems: number) {
		if (cartItems >= 10) {
			this.cartItemsShow = "+9";
			this.cartItemsCount = cartItems;
		} else {
			this.cartItemsShow = cartItems + "";
			this.cartItemsCount = cartItems;
		}
	}

	getCarListItems() {
		return this.cartItemsCount;
	}

	openDialogTutorial() {
		this.dialog.open(DialogTutorialComponent, {
			panelClass: ['md:w-3/5', 'w-full'],
			height: '95vh',
			width: '90%',
			data: {
				title: '',
				message: '',
				accept: true
			}
		})
			.afterClosed()
			.subscribe((confirmado: Boolean) => {
			});
	}

	getProfileSrc() {
		return this.currentUser.profilePicture
	}

	showSuscriptions() {
		const dialogRef = this.dialog.open(SubscriptionsIndexComponent, {
			autoFocus: false,
			width: '80%',
			height: '90%'
		});
	}

	getNotificationList() {
		return this.notificationList.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()).slice(0, 2)
	}

	readNotification(notificationId: string): void {
		this.notificationService.readNotification(notificationId)
			.subscribe(() => {
				this.getUnRead();
			});
	}
}
