import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LoanDTO } from 'src/app/models/loan/loan.dto.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanService } from 'src/app/services/loan/loan.service';

@Component({
  selector: 'app-loanpayment',
  templateUrl: './loanpayment.component.html',
  styleUrls: ['./loanpayment.component.scss']
})
export class LoanpaymentComponent implements OnInit {

  loanId: string = "";
  loans: LoanDTO[] = [];
  isLender = false;
  borrowerCustomerId: string;

  constructor(private route: ActivatedRoute,
              private loanService: LoanService,
              private authService: AuthenticationService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isLender = this.authService.currentUserModeLenderValue;
    this.getLoans();
  }

  getLoans() {
    this.loanService.getLoansPerLender()
      .subscribe((result) => {
        this.loans = result;
      });

    if (this.authService.currentUserModeLenderValue) {
      this.loanService.getLoansPerLender()
      .subscribe((result) => {
        this.loans = result;
      });
    } else {
      this.loanService.getLoansPerBorrower()
      .subscribe((result) => {
        this.loans = result;
      });
    }
  }
}
