<h1 mat-dialog-title>Documentos digitales</h1>
<div mat-dialog-content>
      <table>
        <tr>
            <th></th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <td><mat-icon mat-list-icon>insert_drive_file</mat-icon></td>
            <td>Contrato</td>
            <td><a (click)="openDialogHtmlViewer(0)" mat-button color="primary">Ver documento</a></td>
        </tr>
        <tr>
            <td><mat-icon mat-list-icon>insert_drive_file</mat-icon></td>
            <td>Pagaré</td>
            <td><a (click)="openDialogHtmlViewer(1)" mat-button color="primary">Ver documento</a></td>
        </tr>
      </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</div>
