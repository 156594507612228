<div *ngIf="buttonsAction">
    <h5>Detalle de préstamos</h5>
    <hr>

    <p>Selecciona el renglón del préstamo deseado</p>

    <div class="d-flex justify-content-end mb-4">
        <div class="form-group col-md-4 col-12">
            <label>Buscar</label>
            <div>
                <i class="bx bx-search input_icon"></i>
                <input class="form-control" (keyup)="applyFilter($event)" #filterText placeholder="" type="text" />
            </div>
        </div>
    </div>
</div>

<div class="table-responsive">
    <div class="react-bootstrap-table">
        <table *ngIf="loanDataSource" mat-table [dataSource]="loanDataSource"
            (dblclick)="doubleClick($event, rowSelected.loanRequestId)"
            [ngClass]="isLender ? 'bg-primary' : 'bg-secondary'">
            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef> Fecha</th>
                <td mat-cell *matCellDef="let row" class="text-right"> {{row.startDate | date: 'mediumDate'}} </td>
            </ng-container>
            <ng-container matColumnDef="lenderName">
                <th [hidden]="isLender" mat-header-cell *matHeaderCellDef>Nombre del Prestamista</th>
                <td [hidden]="isLender" mat-cell *matCellDef="let row"> {{row.lenderName}}</td>
            </ng-container>
            <ng-container matColumnDef="borrowerName">
                <th [hidden]="!isLender" mat-header-cell *matHeaderCellDef>Nombre del Solicitante</th>
                <td [hidden]="!isLender" mat-cell *matCellDef="let row"> {{row.borrowerName}}</td>
            </ng-container>
            <ng-container matColumnDef="loanAmount">
                <th mat-header-cell *matHeaderCellDef>Monto del préstamo</th>
                <td mat-cell *matCellDef="let row" class="text-right"> {{row.loanAmount | currency}} </td>
            </ng-container>
            <ng-container matColumnDef="monthlyRate">
                <th mat-header-cell *matHeaderCellDef>Interés mensual</th>
                <td mat-cell *matCellDef="let row" class="text-right"> {{row.monthlyRate*0.0100 | percent:'1.2-5'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="totalPayment">
                <th mat-header-cell *matHeaderCellDef>Monto total a pagar</th>
                <td mat-cell *matCellDef="let row" class="text-right"> {{row.totalPayment | currency}} </td>
            </ng-container>
            <ng-container matColumnDef="currentBalance">
                <th mat-header-cell *matHeaderCellDef>Saldo a la fecha</th>
                <td mat-cell *matCellDef="let row" class="text-right"> {{row.currentBalance | currency}} </td>
            </ng-container>
            <ng-container matColumnDef="balanceOnArrears">
                <th mat-header-cell *matHeaderCellDef>Saldo con atraso</th>
                <td mat-cell *matCellDef="let row" class="text-right"> {{row.balanceOnArrears | currency}} </td>
            </ng-container>
            <ng-container matColumnDef="statusName">
                <th mat-header-cell *matHeaderCellDef>Estatus</th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="row.status === 0">
                        <button class="unclickable" type="button" mat-icon-button color="accent">
                            <mat-icon>lens</mat-icon>
                        </button> {{row.statusName}}
                    </div>
                    <div *ngIf="row.status === 1">
                        <button class="unclickable" type="button" mat-icon-button color="accent">
                            <mat-icon>lens</mat-icon>
                        </button> {{row.statusName}}
                    </div>
                    <div *ngIf="row.status === 2">
                        <button class="unclickable" type="button" mat-icon-button>
                            <mat-icon>lens</mat-icon>
                        </button> {{row.statusName}}
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="loanColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: loanColumns; let i = index" (click)="onRowClicked(row, i)"
                [ngClass]="{'row-selected': indexSelected === i }"></tr>
        </table>
    </div>
</div>

<mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>

<div class="d-flex flex-wrap gap-2 justify-content-end">
    <button class="btn btn-outline-primary external-btn" routerLink="/inicio" *ngIf="buttonsAction">
        Cerrar
    </button>

    <button class="btn btn-outline-primary external-btn" *ngIf="buttonsAction && !paymentMode && rowSelected.loanRequestId != undefined"
        [routerLink]="['/prestamos', rowSelected.loanRequestId]">
        Abrir
    </button>

    <button class="btn btn-outline-primary external-btn" (click)="exportExcel()" *ngIf="!buttonsAction">
        Excel
    </button>
</div>
