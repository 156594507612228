import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { checkPhoneVerification } from 'src/app/models/user/checkPhoneVerification';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { CompleteAccount } from 'src/app/models/user/completeaccount.model';
import { UserService } from 'src/app/services/user/user.service';
import { ValidateAccountService } from 'src/app/services/validate-account/validate-account.service';

export interface DialogData {
	contact: string;
	registrationMethod: number;
}

@Component({
	selector: 'app-complete-account',
	templateUrl: './dialog-complete-account.component.html',
	styleUrls: ['./dialog-complete-account.component.scss']
})

export class DialogCompleteAccountComponent implements OnInit {
	form: FormGroup;
	personalDataForm: FormGroup;
	requiredErrorMessage: string = "Este campo es requerido.";
	personalDataFormSubmitted = false;
	steptIndex: number = 0;
	xsMode = false; //smartphone
	mdMode = false; //tablet
	maxBirthdayDate = new Date();
	
	constructor(private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<DialogCompleteAccountComponent>,
		@Inject(MAT_DIALOG_DATA) public data:DialogData,
		private notificationService: NotificationService,
		private userService: UserService,
		private validateAccountService: ValidateAccountService,
		private alertGlobalService: AlertGlobalService) {
			this.createForm();
			this.startPersonalDataForm();
	}

	get f1() { return this.form.controls; }
	get f_personalDataForm() { return this.personalDataForm.controls; }

	createForm() {
		this.form = this.formBuilder.group({
			code: ['', Validators.required]
		});
	}

	startPersonalDataForm() {
		this.personalDataForm = this.formBuilder.group({
			firstName: ['', [Validators.required, Validators.maxLength(50)] ],
			middleName: ['', [Validators.maxLength(50)] ],
			lastName1: ['', [Validators.required, Validators.maxLength(50)] ],
			lastName2: ['', [Validators.maxLength(50)] ],
			birthdayDate: ['', Validators.required ]
		});
	}

	ngOnInit(): void {
		this.checkValidationAccount();
		this.xsMode = window.innerWidth <= 576;
		this.mdMode = window.innerWidth > 576 && window.innerWidth <= 768;
	}

	onSubmitPhoneValidation() {
		if(this.form.invalid) {
			return;
		}

		this.checkCode();
	}

	validateRequiredForm(formControlName: string): boolean {
		if(this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
			return true;
		}
		return false;
	}

	checkCode() {
		let data = <checkPhoneVerification> {
			to: this.data.contact,
			code: this.f1['code'].value
		}

		this.notificationService.checkCodeVerification(data).subscribe(() => {
			this.alertGlobalService.showAlertSuccess("Código verificado con éxito.");
			this.dialogRef.close();
		});
	}

	onSubmitPersonalDataForm() {
		this.personalDataFormSubmitted = true;

		if (this.personalDataForm.invalid) {
			return;
		}

		const completeAccountData = <CompleteAccount> {
			firstName: this.f_personalDataForm['firstName'].value,
			middleName: this.f_personalDataForm['middleName'].value,
			lastName1: this.f_personalDataForm['lastName1'].value,
			lastName2: this.f_personalDataForm['lastName2'].value,
			birthdayDate: this.f_personalDataForm['birthdayDate'].value,
		};

		var email = this.data.contact;

		this.userService.completeAccount(email, completeAccountData)
		.subscribe(data => {
			this.validateAccountService.setEmailObserver(null);
			//redirect to login
			setTimeout(function () {
			window.location.href = "/iniciar-sesion";
			}, 2000);
		});
	}

	checkValidationAccount() {
		this.validateAccountService.getValidationSuccessObserver()
			.subscribe((success) => {
			if (success) {
				this.steptIndex = 1;
			}
		});
	}
	
	getMaxDate() {
		return new Date()
	}

	validateRequiredFormPerson(formControlName: string): boolean {
		if (this.f_personalDataForm[formControlName] == null) {
			return;
		}

		if (this.f_personalDataForm[formControlName].errors !== null && this.f_personalDataForm[formControlName].errors['required']) {
			return true;
		}

		return false;
	}
}
