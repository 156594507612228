import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-warranty-select-list',
  templateUrl: './warranty-select-list.component.html',
  styleUrls: ['./warranty-select-list.component.scss']
})
export class WarrantySelectListComponent implements OnInit {

  @Input() onlyText: boolean = false;
  // @Input() selected: string[] = ['1'];
  // @Input() selected: Array<string>;
  @Input() selected: any;

  // selectedValue: string[] = ['1'];
  selectedValue: any;
  showText: boolean = false;
  text: string = "";

  nameList: string = "Garantía";
  list: KeyValue<number, string>[] = [
    { key: 1, value: "Sin garantía" },
    { key: 2, value: "Aval" },
    { key: 3, value: "Obligado solidario" },
    { key: 4, value: "Hipotecaria" },
    { key: 5, value: "Prendaria" },
    { key: 6, value: "Garantía Líquida" },
    { key: 7, value: "Otra" }
    // { key: 1, value: "Aval" },
    // { key: 2, value: "Hipotecaria" },
  ];
  
  constructor() { }

  ngOnInit(): void {
    this.showText = this.onlyText;
    // this.text = this.list.find(x => x.key === parseInt(this.selected)).value;
    // this.text = this.list.find(x => x.key === this.selected).value;
    // this.selectedValue = this.selected.toString();
    var arrayString = "";
    arrayString = this.selected;

    arrayString = arrayString.replace(/'/g, '"');
    arrayString = JSON.parse(arrayString);

    for (let v of arrayString){
      this.text += this.list.find(x => x.key === parseInt(v)).value + ", "
    }

    this.selectedValue = arrayString;

    this.text = this.text.slice(0, -2)
  }

}
