<div>
  <div *ngIf="blogId==null">
    <app-blog-list></app-blog-list>
  </div>

  <div *ngIf="blogId=='Artículo 1'">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <button class="btn btn-outline-white position-absolute" (click)="return()">
            <span class="material-icons text-center black-text font-weight-bold">west</span>
          </button>
          <div class="pt-3">
            <div class="row justify-content-center">
              <div class="col-xl-8">
                <div class="text-center">
                  <h4>Artículo 1</h4>
                  <p class="text-muted mb-4">10 Abril, 2020</p></div>
                <hr>
                <div class="text-center">
                  <div class="row">
                    <div class="col-sm-4">
                      <div><p class="text-muted mb-2">Categoría</p><h5 class="font-size-15">Artículo 1</h5></div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mt-4 mt-sm-0"><p class="text-muted mb-2">Fecha</p><h5 class="font-size-15">10 Abril 2021</h5></div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mt-4 mt-sm-0"><p class="text-muted mb-2">Publicado por</p><h5 class="font-size-15">Gilbert Smith</h5></div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="my-5">
                  <img src="assets/images/blog/img-2.9ad853b2.jpg" class="img-thumbnail mx-auto d-block" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                </div>
                <hr>
                <div class="mt-4">
                  <div class="text-muted font-size-14"><p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam enim ad minima veniam quis</p>
                    <p class="mb-4">Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt</p>
                    <blockquote class="p-4 border-light border rounded mb-4">
                      <div class="d-flex">
                        <div class="me-3"><i class="bx bxs-quote-alt-left text-dark font-size-24"></i></div>
                        <div><p class="mb-0"> At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium deleniti atque corrupti quos dolores et quas molestias excepturi sint quidem rerum facilis est</p></div>
                      </div>
                    </blockquote>
                    <p>Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Sed ut perspiciatis unde omnis iste natus error sit</p>
                    <div class="mt-4"><h5 class="mb-3">Titúlo: </h5>
                      <div>
                        <div class="row">
                          <div class="col-lg-4 col-sm-6">
                            <div>
                              <ul class="ps-4">
                                <li class="py-1">Donec sodales sagittis</li>
                                <li class="py-1">Sed consequat leo eget</li>
                                <li class="py-1">Aliquam lorem ante</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-6">
                            <div>
                              <ul class="ps-4">
                                <li class="py-1">Aenean ligula eget</li>
                                <li class="py-1">Cum sociis natoque</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="mt-5"><h5 class="font-size-15"><i class="bx bx-message-dots text-muted align-middle me-1"></i> Comentarios :</h5>
                    <div>
                      <div class="media py-3">
                        <div class="avatar-xs me-3">
                          <div class="avatar-title rounded-circle bg-light text-primary"><i class="bx bxs-user"></i>
                          </div>
                        </div>
                        <div class="media-body"><h5 class="font-size-14 mb-1">Delores Williams <small class="text-muted float-end">Hace 1 hora</small></h5>
                          <p class="text-muted">Neque porro quisquam est, qui dolorem ipsum quia dolor sit ame</p>
                          <div><a class="text-success" href="/blog-details"><i class="mdi mdi-reply"></i> Responder</a>
                          </div>
                        </div>
                      </div>
                      <div class="media py-3 border-top">
                        <div class="avatar-xs me-3">
                          <img src="/static/media/avatar-2.feb0f89d.jpg" class="img-fluid d-block rounded-circle" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                        </div>
                        <div class="media-body"><h5 class="font-size-14 mb-1">Clarence Smith <small class="text-muted float-end">Hace 2 hora</small></h5>
                          <p class="text-muted">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet</p>
                          <div><a class="text-success" href="/blog-details"><i class="mdi mdi-reply"></i> Responder</a>
                          </div>
                          <div class="media pt-3">
                            <div class="avatar-xs me-3">
                              <div class="avatar-title rounded-circle bg-light text-primary"><i
                                class="bx bxs-user"></i></div>
                            </div>
                            <div class="media-body"><h5 class="font-size-14 mb-1">Silvia Martinez <small class="text-muted float-end">Hace 1 hora</small></h5>
                              <p class="text-muted">Neque porro quisquam est, qui dolorem ipsum quia dolor sit ame</p>
                              <div><a class="text-success" href="/blog-details"><i class="mdi mdi-reply"></i>  Responder</a></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="media py-3 border-top">
                        <div class="avatar-xs me-3">
                          <div class="avatar-title rounded-circle bg-light text-primary"><i class="bx bxs-user"></i>
                          </div>
                        </div>
                        <div class="media-body"><h5 class="font-size-14 mb-1">Keith McCoy <small class="text-muted float-end">12 Agosto</small></h5>
                          <p class="text-muted">Donec posuere vulputate arcu. phasellus accumsan cursus velit</p>
                          <div><a class="text-success" href="/blog-details"><i class="mdi mdi-reply"></i> Responder</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4"><h5 class="font-size-16 mb-3">Envía tu mensaje</h5>
                    <form class="">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3"><label for="commentname-input" class="">Nombre</label><input id="commentname-input" placeholder="Escribe tu nombre" type="text" class="form-control form-control"></div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3"><label for="commentemail-input" class="">Email</label><input type="email" class="form-control" id="commentemail-input" placeholder="Escribe tu email">
                          </div>
                        </div>
                      </div>
                      <div class="mb-3"><label for="commentmessage-input" class="">Mensaje</label><textarea class="form-control" id="commentmessage-input" placeholder="Escribe un mensaje..." rows="3"></textarea></div>
                      <div class="text-end">
                        <button type="submit" class="btn btn-success w-sm">Enviar</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="blogId=='Artículo 2'">
    <div>
      content2
    </div>

    <img class="img-fluid" mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">

    <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
    </div>
  </div>
</div>
