<mat-accordion>
    <mat-expansion-panel [expanded]="panelEconomicFlowState" class="mb-4 bg-white"
        (opened)="panelEconomicFlowOpen(true)" (closed)="panelEconomicFlowOpen(false)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="d-flex">
                    <h5>Datos económicos</h5>
                    <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelIncomeState" class="mb-4" (opened)="panelIncomeOpen(true)"
                (closed)="panelIncomeOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Relación de ingresos</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form [formGroup]="economicFlowIncomeForm" (ngSubmit)="onSubmitEconomicFlowIncome()">
                    <div class="row">
                        <div class="subtittle2">Agregar ingreso</div>

                        <div class="col-lg-3 col-md-6 marginCustom mb-3">
                            <div class="form-group">
                                <label>Tipo de ingreso </label>
                                <select #selectIncomeType class="form-control" formControlName="incomeType"
                                    [ngClass]="submitedIncome && validateRequiredFormIncome('incomeType') ? 'is-invalid' : ''">
                                    <option *ngFor="let item of incomeList" value="{{item.value}}"> {{item.value}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 marginCustom mb-3">
                            <div class="form-group">
                                <label>Monto</label>
                                <input class="form-control text-right" type="text" placeholder="$ 0"
                                    [textMask]="{mask: currencyMask}" formControlName="amount" autocomplete="off"
                                    autocapitalize="off"
                                    [ngClass]="submitedIncome && validateRequiredFormIncome('amount') ? 'is-invalid' : ''">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                            <div>
                                <div class="form-group"> <label>Frecuencia</label> <select #selectIncomeFrecuency
                                        class="form-control" formControlName="frequency"
                                        [ngClass]="submitedIncome && validateRequiredFormIncome('frequency') ? 'is-invalid' : ''">
                                        <option *ngFor="let item of frecuencyList" value="{{item.value}}">{{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <button class="btn btn-outline-primary internal-btn">
                                Agregar
                            </button>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <div class="react-bootstrap-table">
                            <table *ngIf="dsEconomicFlowIncomeData" mat-table [dataSource]="dsEconomicFlowIncomeData">
                                <ng-container matColumnDef="incomeType">
                                    <th mat-header-cell *matHeaderCellDef> Tipo de ingreso </th>
                                    <td mat-cell *matCellDef="let row"> {{row.incomeType}} </td>
                                    <td mat-footer-cell *matFooterCellDef> Total </td>
                                </ng-container>

                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef> Monto </th>
                                    <td mat-cell *matCellDef="let row"> <span>{{row.amount | currency}}</span> </td>
                                    <td mat-footer-cell *matFooterCellDef> {{getEconomicFlowIncomeTotal() | currency}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="frequency">
                                    <th mat-header-cell *matHeaderCellDef>Frecuencia</th>
                                    <td mat-cell *matCellDef="let row">{{row.frequency}}</td>
                                    <td mat-footer-cell *matFooterCellDef>Promedio mensual</td>
                                </ng-container>

                                <ng-container matColumnDef="buttonRemove">
                                    <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                                    <td mat-cell *matCellDef="let row; let i = index;">
                                        <i class="bx bx-x" (click)="onDeleteEconomicFlowIncome(i)"></i>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="economicIncomeColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: economicIncomeColumns;"></tr>
                                <tr mat-footer-row *matFooterRowDef="economicIncomeColumns"></tr>
                            </table>
                        </div>
                    </div>
                </form>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelExpenseState" (opened)="panelExpenseOpen(true)"
                (closed)="panelExpenseOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Relación de gastos</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form [formGroup]="economicFlowExpenseForm" (ngSubmit)="onSubmitEconomicFlowExpense()">
                    <div class="row">
                        <div class="subtittle2">Agregar gasto</div>

                        <div class="col-lg-3 col-md-6 marginCustom mb-3">
                            <div class="form-group">
                                <label>Tipo de gasto </label>
                                <select #selectExpenseType class="form-control" formControlName="expenseType"
                                    [ngClass]="submitedExpense && validateRequiredFormExpense('expenseType') ? 'is-invalid' : ''">
                                    <option *ngFor="let item of expenseList" value="{{item.value}}"> {{item.value}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 marginCustom mb-3">
                            <div class="form-group">
                                <label>Monto</label>
                                <input class="form-control text-right" type="text" placeholder="$ 0"
                                    [textMask]="{mask: currencyMask}" formControlName="amount" autocomplete="off"
                                    autocapitalize="off"
                                    [ngClass]="submitedExpense && validateRequiredFormExpense('amount') ? 'is-invalid' : ''">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                            <div>
                                <div class="form-group"> <label>Frecuencia</label> <select #selectExpenseFrecuency
                                        class="form-control" formControlName="frequency"
                                        [ngClass]="submitedExpense && validateRequiredFormExpense('frequency') ? 'is-invalid' : ''">
                                        <option *ngFor="let item of frecuencyList" value="{{item.value}}">{{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <button class="btn btn-outline-primary internal-btn">
                                Agregar
                            </button>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <div class="react-bootstrap-table">
                            <table *ngIf="dsEconomicFlowExpenseData" mat-table [dataSource]="dsEconomicFlowExpenseData">
                                <ng-container matColumnDef="expenseType">
                                    <th mat-header-cell *matHeaderCellDef> Tipo de gasto </th>
                                    <td mat-cell *matCellDef="let row"> {{row.expenseType}} </td>
                                    <td mat-footer-cell *matFooterCellDef> Total </td>
                                </ng-container>

                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef> Monto </th>
                                    <td mat-cell *matCellDef="let row"> <span>{{row.amount | currency}}</span> </td>
                                    <td mat-footer-cell *matFooterCellDef> {{getEconomicFlowExpenseTotal() | currency}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="frequency">
                                    <th mat-header-cell *matHeaderCellDef> Frecuencia </th>
                                    <td mat-cell *matCellDef="let row"> {{row.frequency}} </td>
                                    <td mat-footer-cell *matFooterCellDef> Promedio mensual</td>
                                </ng-container>

                                <ng-container matColumnDef="buttonRemove">
                                    <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                                    <td mat-cell *matCellDef="let row; let i = index;">
                                        <i class="bx bx-x" (click)="onDeleteEconomicFlowExpense(i)"></i>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="economicExpenseColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: economicExpenseColumns;"></tr>
                                <tr mat-footer-row *matFooterRowDef="economicExpenseColumns"></tr>
                            </table>
                        </div>
                    </div>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-expansion-panel>
</mat-accordion>
