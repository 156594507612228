import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { MatDialogRef } from '@angular/material/dialog';
import { KeyValue } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { TypeofbillService } from 'src/app/services/typeofbill/typeofbill.service';
import { MatTableDataSource } from '@angular/material/table';
import { subscriptionpaymentDTO } from 'src/app/models/subscriptionpayment/subscriptionpayment.dto.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-dialog-generate-bill',
  templateUrl: './dialog-generate-bill.component.html',
  styleUrls: ['./dialog-generate-bill.component.scss']
})

export class DialogGenerateBillComponent implements OnInit {

	@ViewChild('stepperBill') stepperSimulator: MatStepper;
	billTypeForm: FormGroup;
	typeOfBilltList: KeyValue<number, string>[] = [];
	subscriptionsForm: FormGroup;
	billDataSource: MatTableDataSource<subscriptionpaymentDTO>;
	rowSelected:subscriptionpaymentDTO = <subscriptionpaymentDTO>{};
	indexSelected = null;
	isDisabledNext = true;
	totalAmount: number = 0;

	subscriptionColumns: string[] = [
		'amount',
		'description',
		'fullName',
		'numberCard',
		'email',
		'methodPayment',
		'subscripcionStartDate',
		'subscripcionEndDate',
		'status',
	];

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	periodsList: KeyValue<number, string>[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private typeofbillService: TypeofbillService,
		private subscriptionpaymentService: SubscriptionpaymentService,
		private authService: AuthenticationService,
		private alertGlobalService: AlertGlobalService,
		private dialogRef: MatDialogRef<DialogGenerateBillComponent>,
		) {
			this.startbillTypeForm();
			this.startSubscriptionsForm();
		}

	requiredbillTypeErrorMessage: string = "por favor seleccione un tipo de factura";
	requiredSubscriptionTypePaymentErrorMessage: string = "por favor seleccione un metodo de pago";

	get f_billTypeForm() { return this.billTypeForm.controls; }
	get f_subscriptionsForm() { return this.subscriptionsForm.controls; }

	get f_billTypeFormProp() { return Object.getOwnPropertyNames(this.f_billTypeForm); }
	get f_subscriptionsFormProp() { return Object.getOwnPropertyNames(this.f_subscriptionsForm); }

	ngOnInit(): void {
		this.getTypeOfBillList();
	}

	startbillTypeForm() {
		this.billTypeForm = this.formBuilder.group({
			typeOfBill: ['', [Validators.required] ],
			completed: [false, Validators.requiredTrue ],
		});
	}

	getTaxProduct() {
		this.subscriptionpaymentService.getSearchProduct(this.rowSelected).subscribe((data) => {
			this.totalAmount = data.price + (data.price * data.taxes.find(x => x.name == "IVA").rate);
		});
	}

	getTypeOfBillList() {
		this.typeofbillService.getList()
		.subscribe((list)=>{
			this.typeOfBilltList = list;
		});
	}

	onChangeSelectTypeOfBill(value) {

	}

	validateBillTypedForm(formControlName: string): boolean {
		if(this.f_billTypeForm[formControlName].value == "") {
			return true;
		}
		return false;
	}

	validateSubscriptionsForm(formControlName: string): boolean {
		if(this.f_subscriptionsFormProp[formControlName].value == null) {
			return true;
		}
		return false;
	}

	startSubscriptionsForm() {
		this.subscriptionsForm = this.formBuilder.group({
			completed: [false, Validators.requiredTrue ],
		});
	}

	onSubmitBillTypeForm() {
		this.f_billTypeForm['completed'].patchValue(true);

		if (this.billTypeForm.invalid) {
			this.f_billTypeForm['completed'].patchValue(false);
			return;
		}

		this.getSubscriptionsBills();
		this.stepperSimulator.next();
	}

	onSubmitSubscriptionsForm() {
		this.submitSubscriptionsForm();
	}

	submitSubscriptionsForm() {
		this.f_subscriptionsForm['completed'].patchValue(true);

		if (this.subscriptionsForm.invalid) {
			this.f_subscriptionsForm['completed'].patchValue(false);
			return;
		}

		if (this.indexSelected == null) {
			return;
		}

		this.getTaxProduct();
		this.stepperSimulator.next();
	}

	onSubmitBillResumeForm() {
		this.rowSelected.generateFactura = true;

		this.subscriptionpaymentService.createBill(this.rowSelected, "1").subscribe((data) => {
			this.alertGlobalService.showAlertSuccess(data.responseCreatebill);
			this.dialogRef.close();
		});
	}

	getSubscriptionsBills(): void {
		this.subscriptionpaymentService.getSubscriptionsBills(true, 1).subscribe((data) => {
			this.billDataSource = new MatTableDataSource(data);
			if (data) {
				this.billDataSource.paginator = this.paginator;
				this.billDataSource.sort = this.sort;
				}
			}
		);
	}

	clickSelectSubscription(e) {
	}

	onClickSort() {
		this.rowSelected = <subscriptionpaymentDTO>{};
		this.indexSelected = null;
	}

	doubleClick (event, loanRequestId)  {
		this.submitSubscriptionsForm();
	}

	onRowClicked(row, index) {
		this.rowSelected = row;
		this.indexSelected = index;
		this.isDisabledNext = false;
	}

	onClickPage() {
		this.rowSelected = <subscriptionpaymentDTO>{};
		this.indexSelected = null;
	  }

	  getIsDisabledNext() {
		return this.isDisabledNext;
	}
}
