<form class="form-horizontal" [formGroup]="formrequestJsonForm">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelAddressOpenState" class="mb-4 bg-white"
            (opened)="panelAdressOpen(true)" (closed)="panelAdressOpen(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <h5>Datos domicilio</h5>
                        <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
                <div *ngIf="validateFormrequest('Street')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Street">Calle<span
                                *ngIf="validateFormrequestJson('Street')">*</span></label>
                        <input type="text" formControlName="value-Street" class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-Street') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('ExteriorNumber')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-ExteriorNumber">Número exterior<span
                                *ngIf="validateFormrequestJson('ExteriorNumber')">*</span></label>
                        <input type="text" formControlName="value-ExteriorNumber"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-ExteriorNumber') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('InteriorNumber')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-InteriorNumber">Número interior<span
                                *ngIf="validateFormrequestJson('InteriorNumber')">*</span></label>
                        <input type="text" formControlName="value-InteriorNumber"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-InteriorNumber') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('DistrictName')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-DistrictName">Colonia<span
                                *ngIf="validateFormrequestJson('DistrictName')">*</span></label>
                        <input type="text" formControlName="value-DistrictName"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-DistrictName') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('CountryId')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-CountryId">País<span
                                *ngIf="validateFormrequestJson('CountryId')">*</span></label>
                        <select #selectCountry class="form-control" formControlName="value-CountryId"
                            (change)="onChangeSelectCountriesRequest(selectCountry.value, false)"
                            [ngClass]="dr && validateRequiredFormF2('value-CountryId') ? 'is-invalid' : ''">
                            <option>Seleccione</option>
                            <option *ngFor="let item of countries" value="{{item.code}}">{{item.name}}
                            </option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('StateId')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-StateId">Estado<span
                                *ngIf="validateFormrequestJson('StateId')">*</span></label>
                        <select #selectState class="form-control" formControlName="value-StateId"
                            (change)="onChangeSelectStatesRequest(selectState.value, false)"
                            [ngClass]="dr && validateRequiredFormF2('value-StateId') ? 'is-invalid' : ''">
                            <option>Seleccione</option>
                            <option *ngFor="let item of states" value="{{item.code}}">{{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('CityId')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-CityId">Municipio<span
                                *ngIf="validateFormrequestJson('CityId')">*</span></label>
                        <select class="form-control" formControlName="value-CityId"
                            [ngClass]="dr && validateRequiredFormF2('value-CityId') ? 'is-invalid' : ''">
                            <option>Seleccione</option>
                            <option *ngFor="let item of cities" value="{{item.code}}">{{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('ZipCode')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-ZipCode">Código postal<span
                                *ngIf="validateFormrequestJson('ZipCode')">*</span></label>
                        <input type="number" formControlName="value-ZipCode"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-ZipCode') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('HousingType')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-HousingType">{{typeHousing}}<span
                                *ngIf="validateFormrequestJson('HousingType')">*</span></label>
                        <select class="form-control" formControlName="value-HousingType"
                            [ngClass]="dr && validateRequiredFormF2('value-HousingType') ? 'is-invalid' : ''">
                            <option>Seleccione</option>
                            <option *ngFor="let item of getHousingTypeList()" value="{{item.key}}">
                                {{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('HomeOwnership')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-HomeOwnership">{{homeOwnership}}<span
                                *ngIf="validateFormrequestJson('HomeOwnership')">*</span></label>
                        <select class="form-control" formControlName="value-HomeOwnership"
                            [ngClass]="dr && validateRequiredFormF2('value-HomeOwnership') ? 'is-invalid' : ''">
                            <option>Seleccione</option>
                            <option *ngFor="let item of homeOwnershipList" value="{{item.key}}">
                                {{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('YearsDomicile')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-YearsDomicile">Antigüedad en domicilio<span
                                *ngIf="validateFormrequestJson('YearsDomicile')">*</span></label>
                        <input autofocus="autofocus" class="form-control"
                            formControlName="value-YearsDomicile" type="date"
                            [ngClass]="dr && validateRequiredFormF2('value-YearsDomicile') ? 'is-invalid' : ''" />
                    </div>
                </div>
                <div *ngIf="validateFormrequest('YearsCity') && personType != 1"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-YearsCity">{{tittleCity}}<span
                                *ngIf="validateFormrequestJson('YearsCity')">*</span></label>
                        <input autofocus="autofocus" class="form-control"
                            formControlName="value-YearsCity" type="date"
                            [ngClass]="dr && validateRequiredFormF2('value-YearsCity') ? 'is-invalid' : ''" />
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FiscalDomicile') && personType == 1"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FiscalDomicile">{{tittleCity}}<span
                                *ngIf="validateFormrequestJson('FiscalDomicile')">*</span></label>
                        <select #selectFiscalDomicile class="form-control"
                            formControlName="value-FiscalDomicile"
                            (change)="onChangeFiscalDomicile(selectFiscalDomicile.value)"
                            [ngClass]="dr && validateRequiredFormF2('value-FiscalDomicile') ? 'is-invalid' : ''">
                            <option>Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion *ngIf="personType == 1 && showFiscalDomilie">
        <mat-expansion-panel [expanded]="panelFiscalAddressOpenState" class="mb-4 bg-white"
            (opened)="panelFiscalAdressOpen(true)" (closed)="panelFiscalAdressOpen(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <h5>Datos domicilio fiscal</h5>
                        <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
                <div *ngIf="validateFormrequest('FiscalStreet')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FiscalStreet">Calle<span
                                *ngIf="validateFormrequestJson('FiscalStreet')">*</span></label>
                        <input type="text" formControlName="value-FiscalStreet"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-FiscalStreet') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FiscalExteriorNumber')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FiscalExteriorNumber">Número exterior<span
                                *ngIf="validateFormrequestJson('FiscalExteriorNumber')">*</span></label>
                        <input type="text" formControlName="value-FiscalExteriorNumber"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-FiscalExteriorNumber') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FiscalInteriorNumber')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FiscalInteriorNumber">Número interior<span
                                *ngIf="validateFormrequestJson('FiscalInteriorNumber')">*</span></label>
                        <input type="text" formControlName="value-FiscalInteriorNumber"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-FiscalInteriorNumber') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FiscalDistrictName')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FiscalDistrictName">Colonia<span
                                *ngIf="validateFormrequestJson('FiscalDistrictName')">*</span></label>
                        <input type="text" formControlName="value-FiscalDistrictName"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-FiscalDistrictName') ? 'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FiscalCountryId')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FiscalCountryId">País<span
                                *ngIf="validateFormrequestJson('FiscalCountryId')">*</span></label>
                        <select #selectFiscalCountry class="form-control"
                            formControlName="value-FiscalCountryId"
                            (change)="onChangeSelectCountriesRequest(selectFiscalCountry.value, true)"
                            [ngClass]="dr && validateRequiredFormF2('value-FiscalCountryId') ? 'is-invalid' : ''">
                            <option>Seleccione</option>
                            <option *ngFor="let item of fiscalCountries" value="{{item.code}}">
                                {{item.name}}</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FiscalStateId')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FiscalStateId">Estado<span
                                *ngIf="validateFormrequestJson('FiscalStateId')">*</span></label>
                        <select #selectFiscalState class="form-control"
                            formControlName="value-FiscalStateId"
                            (change)="onChangeSelectStatesRequest(selectFiscalState.value, true)"
                            [ngClass]="dr && validateRequiredFormF2('value-FiscalStateId') ? 'is-invalid' : ''">
                            <option>Seleccione</option>
                            <option *ngFor="let item of fiscalStates" value="{{item.code}}">
                                {{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FiscalCityId')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FiscalCityId">Municipio<span
                                *ngIf="validateFormrequestJson('FiscalCityId')">*</span></label>
                        <select class="form-control" formControlName="value-FiscalCityId"
                            [ngClass]="dr && validateRequiredFormF2('value-FiscalCityId') ? 'is-invalid' : ''">
                            <option>Seleccione</option>
                            <option *ngFor="let item of fiscalCities" value="{{item.code}}">
                                {{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FiscalZipCode')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FiscalZipCode">Código postal<span
                                *ngIf="validateFormrequestJson('FiscalZipCode')">*</span></label>
                        <input type="number" formControlName="value-FiscalZipCode"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF2('value-FiscalZipCode') ? 'is-invalid' : ''">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>