import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LoanPaymentDTO, ProofOfPaymentMailDTO } from 'src/app/models/loanpayment/loanpayment.dto.model';
import { LoanPayment } from 'src/app/models/loanpayment/loanpayment.model';
import { ApiService } from '../api/api.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { AlertGlobalService } from '../../services/alert-global/alert-global.service';

@Injectable({
  providedIn: 'root'
})
export class LoanpaymentService {

  constructor(private apiService: ApiService,
              private authService: AuthenticationService,
              private alertGlobal: AlertGlobalService) { }

  createLoanPayment(loanPayment: LoanPayment) {
    return this.apiService.post('loanpayment', '', loanPayment)
    .pipe(map(response => {
      if (response.status === 201)
        this.alertGlobal.showAlertSuccess("Pago en proceso");
        
      return response.data as String;
    }));
  }

  getLoanPaymentsWithLoanId(isLender: boolean, customerId: string, loanId: string) {
    return this.apiService.get('loanpayment', 'payments/' + (isLender ? 'true' : 'false') + '/' + customerId + '/' + loanId)
    .pipe(map(response => {
      return response.data as LoanPaymentDTO[];
    }));
  }

  getLoanPayments(isLender: boolean, customerId: string) {
    return this.apiService.get('loanpayment', 'payments/' + (isLender ? 'true' : 'false') + '/' + customerId)
    .pipe(map(response => {
      return response.data as LoanPaymentDTO[];
    }));
  }

  deleteLoanPayment(loanPaymentId: string) {
    return this.apiService.delete('loanpayment', '', loanPaymentId)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  sendProofOfPaymentMail(proofOfPaymentMail: ProofOfPaymentMailDTO) {
    return this.apiService.post('loanpayment', 'sendProofOfPaymentMail', proofOfPaymentMail )
    .pipe(map(response => {
      return response.data as String;
    }));
  }
  
}
