import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
	selector: 'app-loanimport',
	templateUrl: './loanimport.component.html',
	styleUrls: ['./loanimport.component.scss']
})

export class LoanImportComponent implements OnInit {
	constructor(private authService: AuthenticationService,
		private router: Router) { }

	ngOnInit(): void {
		if (!this.authService.currentUserModeLenderValue) {
			this.router.navigate(['prestamos/']);
		}
	}
}
