<h1 mat-dialog-title>Opciones avanzadas</h1>
<form [formGroup]="advanceOptionsForm" (ngSubmit)="onSubmitAdvanceOptions()">
    <div *ngIf="mode === 'new'" class="d-flex align-items-end mb-3">
        <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="form-group">
                <label>Tipo de opción </label>
                <!-- <select #select class="form-control" formControlName="description">
                    <option value="">Seleccione</option>
                    <option *ngFor="let item of typeOfAdvanceOptions" value="{{item.value}}">{{item.value}}</option>
                </select> -->
            </div>
        </div>
        <button class="btn btn-outline-primary internal-btn">
            Agregar
        </button>
    </div>

    <div class="responsive-account-table" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
        <table *ngIf="dsAdvanceOptions" matTableResponsive mat-table [dataSource]="dsAdvanceOptions">
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef> Número </th>
                <td mat-cell *matCellDef="let row"> {{row.number}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Opción </th>
                <td mat-cell *matCellDef="let row"> {{row.description}} </td>
            </ng-container>

            <ng-container matColumnDef="buttonRemove">
                <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                <td mat-cell *matCellDef="let row; let i = index;">
                    <i class="bx bx-x" *ngIf="mode === 'new'" (click)="onDeleteAdvanceOptions(i)"></i>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="advanceOptionsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: advanceOptionsColumns;"></tr>
        </table>
    </div>
</form>
<div class="d-flex flex-wrap gap-2 justify-content-end">
    <button class="btn btn-outline-primary external-btn" mat-dialog-close>
        Cerrar
    </button>
    <button class="btn btn-primary external-btn" *ngIf="mode === 'new'" [mat-dialog-close]="onClickAdvanceOptions()">
        Guardar
    </button>
</div>
