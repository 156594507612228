import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AuthenticationService, } from '../../services/authentication/authentication.service';
import { LoanDTO, ExternalServicesDTO, CreateExternalServices, CollectionLog, CollectionLogResponse, CreateExternalServicesCollection, CollectionUser, OriginalName } from 'src/app/models/loan/loan.dto.model';
import { map } from 'rxjs/operators';
import { AlertGlobalService } from '../alert-global/alert-global.service';
import { LoanResumeComponent } from 'src/app/components/loan-resume/loan-resume.component';

@Injectable({
  providedIn: 'root'
})
export class LoanService {
  constructor(private apiService: ApiService,
    private authService: AuthenticationService,
    private alertGlobal: AlertGlobalService) { }

  getLoansPerLender() {
    return this.apiService.get('loan', 'perlender', this.authService.currentUserValue.customerId)
      .pipe(map(response => {
        return response.data as LoanDTO[];
      }));
  }

  getLoansPerBorrower() {
    return this.apiService.get('loan', 'perborrower', this.authService.currentUserValue.customerId)
      .pipe(map(response => {
        return response.data as LoanDTO[];
      }));
  }

  getExternalServices(personType: number) {
    return this.apiService.get('loan', 'getExternalServices/' + personType)
      .pipe(map(response => {
        return response.data as ExternalServicesDTO[];
      }));
  }

  getExternalServicesLanding() {
    return this.apiService.get('loan', 'getExternalServicesLanding')
      .pipe(map(response => {
        return response.data as ExternalServicesDTO[];
      }));
  }

  getCollectionLog(loanId: string) {
    return this.apiService.get('loan', 'getCollectionLog/' + loanId)
      .pipe(map(response => {
        return response.data as CollectionLog[];
      }));
  }

  getCollectionVerification(loanId: string) {
    return this.apiService.get('loan', 'getCollectionVerification/' + loanId)
      .pipe(map(response => {
        return response.data as CollectionLog[];
      }));
  }

  getCollectionUser() {
    return this.apiService.get('loan', 'getCollectionUser/')
      .pipe(map(response => {
        return response.data as CollectionUser[];
      }));
  }

  getCollectionLogDetails(externalId: string) {
    return this.apiService.get('loan', 'getCollectionLogDetails/' + externalId)
      .pipe(map(response => {
        return response.data as CollectionLogResponse;
      }));
  }

  createExternalService(idExternalService: string, loanId: string, createExternalService: CreateExternalServices) {
    return this.apiService.post('loan', 'createExternalService/' + idExternalService + '/' + loanId, createExternalService)
      .pipe(map(response => {
        return response.data as ExternalServicesDTO[];
      }));
  }

  createExternalServiceCollection(idExternalService: string, loanId: string, createExternalServicesCollection: CreateExternalServicesCollection) {
    return this.apiService.post('loan', 'createExternalService/' + idExternalService + '/' + loanId, createExternalServicesCollection)
      .pipe(map(response => {
        return response.data as ExternalServicesDTO[];
      }));
  }

  getOriginalName(externalId: string) {
    return this.apiService.get('loan', 'getOriginalName' , externalId)
      .pipe(map(response => {
        return response.data as OriginalName;
      }));
  }
}
