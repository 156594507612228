import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-simulator',
  templateUrl: './dialog-simulator.component.html',
  styleUrls: ['./dialog-simulator.component.scss']
})
export class DialogSimulatorComponent implements OnInit {

  @Input() isLender = false;
  @Input() isAnonimous = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
