import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ContactPhone, LoanRequestOptionsData, ContactEmail, SocialNetworks } from 'src/app/models/loanrequest/loanrequest.model';
import { GlobalLists } from 'src/app/global/global-data';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';

@Component({
	selector: 'app-dialog-contact-data',
	templateUrl: './dialog-contact-data.component.html',
	styleUrls: ['./dialog-contact-data.component.scss']
})

export class DialogContactDataComponent implements OnInit {
	submit = false;
	currentPath: string = window.location.pathname;

	contactPhoneColumns = [
		'phoneType',
		'phone',
		'comments',
		'buttonRemove'
	];

	contactEmailColumns = [
		'emailType',
		'email',
		'comments',
		'buttonRemove'
	];

	socialNetworksColumns = [
		'socialNetwork',
		'link',
		'comments',
		'buttonRemove'
	];

	panelContactDataState = false;
	panelPhoneState = false;
	panelEmailState = false;
	panelDifferentState = false;
	panelSocialNetworksState = false;
	phoneList = GlobalLists.phoneList;
	emailList = GlobalLists.emailList;
	socialNetworksList = GlobalLists.socialNetworksList;
	yesnoList = GlobalLists.yesnoList;
	phoneMask = GlobalLists.phoneMask;
	
	contactPhoneForm: FormGroup;
	contactEmailForm: FormGroup;
	socialNetworksForm: FormGroup;
	contactPhone = <ContactPhone[]>[];
	contactEmail = <ContactEmail[]>[];
	socialNetworks = <SocialNetworks[]>[];
	contactData: LoanRequestOptionsData;
	dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);
	dsContactEmailData = new MatTableDataSource<ContactEmail>(this.contactEmail);
	dsSocialNetworksData = new MatTableDataSource<SocialNetworks>(this.socialNetworks);
	
	formrequestJson: FormRequestAnswer[];
	formrequestJsonForm: FormGroup = this.formBuilder.group({});
	firstEmail = "";
	
	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() borrowerMail: string;
	@Input() borrowerPhoneMobile: string;
	@Input() dr: boolean;
	@Input() completed: boolean;
	@Input() personType: number;
	@Input() isDisabled: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService
		) {		
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get fContactPhone() { return this.contactPhoneForm.controls; }
	get fContactEmail() { return this.contactEmailForm.controls; }
	get fSocialNetworks() { return this.socialNetworksForm.controls; }
	get f2() { return this.formrequestJsonForm.controls; }

	ngOnInit(): void {
		this.createContactPhoneForm();
		this.createContactEmailForm();
		this.createSocialNetworksForm();

		this.loanrequestService.getLoanFormRequest(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.formrequestJson = result.optionForm;

				var phoneMobile = this.formrequestJson.find(x => x.conditionName == "PhoneMobile").value;
				this.firstEmail = this.formrequestJson.find(x => x.conditionName == "Email").value;

				if (phoneMobile == "") {
					phoneMobile = this.borrowerPhoneMobile;
				}

				if (this.firstEmail == "") {
					this.firstEmail = this.borrowerMail;
				}

				if (this.validateFormrequest('DifferenteLegal')) this.formrequestJsonForm.addControl("value-DifferenteLegal", new FormControl(this.formrequestJson.find(x => x.conditionName == "DifferenteLegal").value, [Validators.maxLength(150), Validators.minLength(0)]))
				if (this.validateFormrequest('PhoneMobile')) this.formrequestJsonForm.addControl("value-PhoneMobile", new FormControl(Number.parseInt(phoneMobile), [Validators.maxLength(110), Validators.minLength(0)]))
				if (this.validateFormrequest('Email')) this.formrequestJsonForm.addControl("value-Email", new FormControl(this.firstEmail, [Validators.maxLength(150), Validators.minLength(0)]))
				if (this.validateFormrequest('LegalFirstName')) this.formrequestJsonForm.addControl("value-LegalFirstName", new FormControl(this.formrequestJson.find(x => x.conditionName == "LegalFirstName").value, [Validators.maxLength(150), Validators.minLength(0)]))
				if (this.validateFormrequest('LegalMiddleName')) this.formrequestJsonForm.addControl("value-LegalMiddleName", new FormControl(this.formrequestJson.find(x => x.conditionName == "LegalMiddleName").value, [Validators.maxLength(150), Validators.minLength(0)]))
				if (this.validateFormrequest('LegalLastName1')) this.formrequestJsonForm.addControl("value-LegalLastName1", new FormControl(this.formrequestJson.find(x => x.conditionName == "LegalLastName1").value, [Validators.maxLength(150), Validators.minLength(0)]))
				if (this.validateFormrequest('LegalLastName2')) this.formrequestJsonForm.addControl("value-LegalLastName2", new FormControl(this.formrequestJson.find(x => x.conditionName == "LegalLastName2").value, [Validators.maxLength(150), Validators.minLength(0)]))
				if (this.validateFormrequest('LegalPosition')) this.formrequestJsonForm.addControl("value-LegalPosition", new FormControl(this.formrequestJson.find(x => x.conditionName == "LegalPosition").value, [Validators.maxLength(150), Validators.minLength(0)]))
				if (this.validateFormrequest('LegalArea')) this.formrequestJsonForm.addControl("value-LegalArea", new FormControl(this.formrequestJson.find(x => x.conditionName == "LegalArea").value, [Validators.maxLength(150), Validators.minLength(0)]))
				if (this.validateFormrequest('LegalPhone')) this.formrequestJsonForm.addControl("value-LegalPhone", new FormControl(this.formrequestJson.find(x => x.conditionName == "LegalPhone").value, [Validators.maxLength(150), Validators.minLength(0)]))
				if (this.validateFormrequest('LegalEmail')) this.formrequestJsonForm.addControl("value-LegalEmail", new FormControl(this.formrequestJson.find(x => x.conditionName == "LegalEmail").value, [Validators.maxLength(150), Validators.minLength(0)]))

				if (this.validateFormrequest('DifferenteLegal')) this.onChangeDifferenteLegal(this.f2["value-DifferenteLegal"].value);

				if (this.f2["value-LegalFirstName"] != null && this.getIsDisabledSave()) {
					this.f2["value-PhoneMobile"].disable();
					this.f2["value-Email"].disable();
					this.f2["value-LegalFirstName"].disable();
					this.f2["value-LegalMiddleName"].disable();
					this.f2["value-LegalLastName1"].disable();
					this.f2["value-LegalLastName2"].disable();
					this.f2["value-LegalPosition"].disable();
					this.f2["value-LegalArea"].disable();
					this.f2["value-LegalPhone"].disable();
					this.f2["value-LegalEmail"].disable();
					this.f2["value-DifferenteLegal"].disable();
				}

				this.loanrequestService.getContactDataRequest(this.loanRequestId).subscribe((result) => {
					if (result !== null) {
						this.contactPhone = result.contactPhone;
						this.contactEmail = result.contactEmail;
						this.socialNetworks = result.socialNetworks;
			
						this.dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);
						this.dsContactEmailData = new MatTableDataSource<ContactEmail>(this.contactEmail);
						this.dsSocialNetworksData = new MatTableDataSource<SocialNetworks>(this.socialNetworks);
			
						this.updateContactData();

						if (this.completed) {
							this.dr = true;
						}
					}
				});
			}
		});
	}

	createContactPhoneForm() {
		this.contactPhoneForm = this.formBuilder.group({
			phoneType: ['', Validators.required],      
			phone: [null, Validators.required],      
			comments: ['']
		});
	}

	createContactEmailForm() {
		this.contactEmailForm = this.formBuilder.group({
			emailType: ['', Validators.required],      
			email: ['', Validators.required],      
			comments: ['']
		});
	}

	createSocialNetworksForm() {
		this.socialNetworksForm = this.formBuilder.group({
			socialNetwork: ['', Validators.required],      
			link: ['', Validators.required],      
			comments: ['']
		});
	}

	onSubmitContactPhone() {
		if (this.contactPhoneForm.invalid || this.getIsDisabledSave()) {
		  return;
		}
	
		this.addContactPhone();
	}
	
	onDeleteContactPhone(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.contactPhone.splice(index, 1);
		this.dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);

		this.updateContactData();
	}
	
	addContactPhone(): void {
		var phone = this.fContactPhone['phone'].value + "";
		
		this.contactPhone.push(<ContactPhone>{
			phoneType: this.fContactPhone['phoneType'].value,
			phone: phone.replace("_", "").replace("-", ""),
			comments: this.fContactPhone['comments'].value,
		});

		this.dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);
		this.contactPhoneForm.reset();

		this.updateContactData();
	}

	onSubmitContactEmail() {
		if (this.contactEmailForm.invalid || this.getIsDisabledSave()) {
		  return;
		}
	
		this.addContactEmail();
	}

	onSubmitSocialNetworks() {
		if (this.socialNetworksForm.invalid || this.getIsDisabledSave()) {
		  return;
		}
	
		this.addSocialNetworks();
	}
	
	onDeleteContactEmail(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.contactEmail.splice(index, 1);
		this.dsContactEmailData = new MatTableDataSource<ContactEmail>(this.contactEmail);

		this.updateContactData();
	}
	
	onDeleteSocialNetworks(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}
		
		this.socialNetworks.splice(index, 1);
		this.dsSocialNetworksData= new MatTableDataSource<SocialNetworks>(this.socialNetworks);

		this.updateContactData();
	}
	
	addContactEmail(): void {
		this.contactEmail.push(<ContactEmail>{
			emailType: this.fContactEmail['emailType'].value,
			email: this.fContactEmail['email'].value,
			comments: this.fContactEmail['comments'].value,
		});

		this.dsContactEmailData = new MatTableDataSource<ContactEmail>(this.contactEmail);
		this.contactEmailForm.reset();

		this.updateContactData();
	}
	
	addSocialNetworks(): void {
		this.socialNetworks.push(<SocialNetworks>{
			socialNetwork: this.fSocialNetworks['socialNetwork'].value,
			link: this.fSocialNetworks['link'].value,
			comments: this.fSocialNetworks['comments'].value,
		});

		this.dsSocialNetworksData = new MatTableDataSource<SocialNetworks>(this.socialNetworks);
		this.socialNetworksForm.reset();

		this.updateContactData();
	}

	panelPhoneOpen(e) {
		this.panelPhoneState = e;
	}

	panelDifferentOpen(e) {
		this.panelDifferentState = e;
	}

	panelEmailOpen(e) {
		this.panelEmailState = e;
	}

	panelContactDataOpen(e) {
		this.panelContactDataState = e;
	}

	panelSocialNetworksOpen(e) {
		this.panelSocialNetworksState= e;
	}

	updateContactData() {
		var pat = <LoanRequestOptionsData>{
			contactPhone: this.contactPhone,
			contactEmail: this.contactEmail,
			socialNetworks: this.socialNetworks
		};
		
		this.contactData = pat;
	}

	validateFormrequestJson(val: string) {
		if (!this.validateFormrequest(val)) {
			return;
		}

		if (this.formrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	validateFormrequest(val: string) {
		if (this.formrequestJson == null) {
			return false;
		}

		if (this.formrequestJson.find(x => x.conditionName == val) != null) {
			return true;
		}

		return false;
	}

	validateRequiredFormF2(formControlName: string): boolean {
		if (this.f2[formControlName] == null) {
			return;
		}

		if(this.f2[formControlName].errors !== null && this.f2[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	onChangeDifferenteLegal(value: string) {
		if (value == "0") {
			this.f2["value-LegalFirstName"].patchValue(this.formrequestJson.find(x => x.conditionName == "FirstName").value);
			this.f2["value-LegalMiddleName"].patchValue(this.formrequestJson.find(x => x.conditionName == "MiddleName").value);
			this.f2["value-LegalLastName1"].patchValue(this.formrequestJson.find(x => x.conditionName == "LastName1").value);
			this.f2["value-LegalLastName2"].patchValue(this.formrequestJson.find(x => x.conditionName == "LastName2").value);
		} else {
			this.f2["value-LegalFirstName"].patchValue("");
			this.f2["value-LegalMiddleName"].patchValue("");
			this.f2["value-LegalLastName1"].patchValue("");
			this.f2["value-LegalLastName2"].patchValue("");
		}
	}

	getIsDisabledSave() {
		if ((this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) && this.isDisabled) {
			return true;
		} else {
			return false;
		}
	}
}
