import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Alert, AlertGlobalService, TypeAlert } from '../../services/alert-global/alert-global.service';

@Component({
	selector: 'app-alert-global',
	templateUrl: './alert-global.component.html',
	styleUrls: ['./alert-global.component.scss']
})

export class AlertGlobalComponent implements OnInit {
	horizontalPosition: MatSnackBarHorizontalPosition = 'center';
	verticalPosition: MatSnackBarVerticalPosition = 'top';
	alertSubscription!: Subscription;
	routeSubscription!: Subscription;
	@Input() id = 'default-alert';
	@Input() fade = true;

	constructor(
		private alertGlobalService: AlertGlobalService,
		private toastrService: ToastrService,
		private cdRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.init();
	}

	init() {
		this.alertGlobalService.getAlertObserver().subscribe((alert) => {
			if (alert.show) {
				this.openSnackBar(alert);
			}
			this.cdRef.detectChanges();
		});
	}

	openSnackBar(alert: Alert) {
		if (alert.typeAlert === TypeAlert.ERROR) {
			this.toastrService.error(alert.message);
		}
		if (alert.typeAlert === TypeAlert.SUCCESS) {
			this.toastrService.success(alert.message);
		}
		if (alert.typeAlert === TypeAlert.WARNING) {
			this.toastrService.warning(alert.message);
		}
	}
}