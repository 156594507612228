import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SendInfoSuscriptionsComponent } from '../send-info-suscriptions/send-info-suscriptions.component';
import { Router } from '@angular/router';
import { SubscriptionpaymentNewComponent } from '../subscriptionpayment-new/subscriptionpayment-new.component';

@Component({
  selector: 'app-subscriptions-index',
  templateUrl: './subscriptions-index.component.html',
  styleUrls: ['./subscriptions-index.component.scss']
})
export class SubscriptionsIndexComponent implements OnInit {

	@ViewChild('subscriptionpaymentNew') subscriptionpaymentNew: SubscriptionpaymentNewComponent;
  
  constructor(private dialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void {
  }

  showDialogSendInfo() {
    let config = new MatDialogConfig();
    config = {
      width: '80%'
    };
    const dialog = this.dialog.open(SendInfoSuscriptionsComponent, config); 
  }

}
