<div class="container">
  <div #divExport>
    <div class="align-items-center d-flex justify-content-between">
      <h5>Detalle de la llamada</h5>
    </div>

    <div class="card bg-gray">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Gestor</p>
            <h5 class="mb-2">{{ capitalizeFirstLetter(this.getCalls.agent)}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Tipo de llamada</p>
            <h5 class="mb-2">{{this.getCalls.callTypeName}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Fecha y hora de Llamada</p>
            <h5 class="mb-2">{{dateStartManagement |date: 'dd/MM/yyyy'}}, {{dateStartManagement |date:
              'shortTime'}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Duracion de llamada</p>
            <h5 class="mb-2">{{timeDifference}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Nombre de Acreedor</p>
            <h5 class="mb-2">{{ capitalizeFirstLetter(this.lenderFullName) }}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Nombre del Deudor</p>
            <h5 class="mb-2">{{capitalizeFirstLetter(this.applicantName)}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Tipo de Contacto</p>
            <h5 class="mb-2">{{this.getCalls.contactTypeName == 'Solicitante' ? 'Deudor': ''}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Teléfono del Deudor</p>
            <h5 class="mb-2">{{this.getCalls.phone}}</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="card bg-gray" id="content">
      <div class="card-body">
        <h5>Cuestionario</h5>

        <div class="row">
          <div class="col-md-6">
            <ng-container *ngFor="let item of newQuestions">
              <div class="col-mb-12">
                <div class="row">
                  <div class="col-md-6 fw-bold">
                    <p class="">{{ item.Question }}</p>
                  </div>
                  <div class="col-md-6">
                    <p>{{ item.Answer }}</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="col-md-6">
            <ng-container *ngFor="let item of newQuestionsHalf">
              <div class="col-mb-12">
                <div class="row">
                  <div class="col-md-6 fw-bold">
                    <p class="">{{ item.Question }}</p>
                  </div>
                  <div class="col-md-6">
                    <p>{{ item.Answer }}</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <br>
          <ng-container *ngIf="this.newArchivesFilter.length !== 0">
            <hr>
            <h5>Imágenes</h5>

            <div class="row col-md-12">
              <div class="col-md-3" *ngFor="let file of newArchivesFilter">
                <img class="img_small" (click)="openImage(file.url)" [src]="extractFtpUrl(file.url)" />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>


  <div class="d-flex justify-content-end align-items-center mt-4 gap-2">
    <button class="btn btn-outline-primary external-btn" (click)="return()">
      Cerrar
    </button>

    <button class="btn btn-outline-primary external-btn" (click)="exportToPDF()">
      PDF
    </button>
  </div>
</div>
