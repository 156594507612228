import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AlertGlobalService {

	private alert$ = new BehaviorSubject<Alert>(<Alert>{ show: false });

	constructor(private toastrService: ToastrService) { }

	getAlertObserver(): Observable<Alert> {
		return this.alert$.asObservable();
	}

	showAlertError(message: string): void {
		this.toastrService.error(message);
	}

	showAlertSuccess(message: string): void {
		this.toastrService.success(message);
	}

	showAlertWarning(message: string): void {
		this.toastrService.warning(message);
	}
}

export class Alert {
	show: boolean;
	message: string;
	action: string;
	typeAlert: number;
}

export enum TypeAlert {
	ERROR,
	SUCCESS,
	WARNING
}
