import { Quote } from '../../models/quote/quote.model';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuoteService } from 'src/app/services/quote/quote.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { MatDialogRef } from '@angular/material/dialog';
import { RequiredDocumentsConfigService } from 'src/app/services/required-documents-config/required-documents-config.service';
import { AmortizeService } from 'src/app/services/amortize/amortize.service';
import { RequiredDocumentsLegalConfigService } from 'src/app/services/required-documents-legal-config/required-documents-legal-config.service';

@Component({
  selector: 'app-dialog-save-quote',
  templateUrl: './dialog-save-quote.component.html',
  styleUrls: ['./dialog-save-quote.component.scss']
})
export class DialogSaveQuoteComponent implements OnInit {
  
  @Input() parentForm: FormGroup;
  @Input() quoteId: string;
  saveQuoteForm: FormGroup;
  submitted = false;
  requiredErrorMessage: string = "Este campo es requerido.";
  
  constructor(private formBuilder: FormBuilder,
              private quoteService: QuoteService,
              private authService: AuthenticationService,
              private dialogRef: MatDialogRef<DialogSaveQuoteComponent>,
              private requiredDocumentsConfigService: RequiredDocumentsConfigService,
              private requiredDocumentsLegalConfigService: RequiredDocumentsLegalConfigService,
              private amortizeService: AmortizeService) {
    this.createSaveQuoteForm();
  }

  get fParent() { return this.parentForm.controls; }
  get f1() { return this.saveQuoteForm.controls; }

  createSaveQuoteForm() {
    this.saveQuoteForm = this.formBuilder.group({
      name: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if(this.quoteId !== '') {      
      this.setInputs();
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.saveQuoteForm.invalid && this.parentForm.invalid) {
        return;
    }

    this.quoteId = this.saveQuote();

    this.dialogRef.close();
  }

  saveQuote(): string {
    var result: string = "";
    const quote = <Quote> {
      loanAmount: parseFloat(this.fParent['loanAmount'].value),
      annualRate: parseFloat(this.fParent['annualRate'].value),
      totalPeriods: parseInt(this.fParent['totalPeriods'].value),
      periodAmortizeStart: parseInt(this.fParent['periodAmortizeStart'].value),
      typeOfPeriod: parseInt(this.fParent['typeOfPeriod'].value),
      typeOfAmortization: parseInt(this.fParent['typeOfAmortization'].value),
      taxPorcent: parseFloat(this.fParent['taxPorcent'].value),
      startDate: this.fParent['startDate'].value,
      typeOfCredit: parseInt(this.fParent['typeOfCredit'].value),
      // warranty: parseInt(this.fParent.warranty.value),
      warrantyDataJson: this.fParent['warrantyDataJson'].value,
      customerId: this.authService.currentUserValue.customerId,
      name: this.f1['name'].value,
      isDefault: false,
      expireDate: new Date(),
      comments: this.f1['comments'].value,
      documentsRequiredConfigJson: JSON.stringify(this.requiredDocumentsConfigService.currentrequiredDocuments),
      amortizationTableJson: this.fParent['amortizationTableJson'].value,
      commissionsJson: this.fParent['commissions'].value,
      monthlyPorcentOnArrears: parseFloat(this.fParent['monthlyPorcentOnArrears'].value),
      paymentsJson: JSON.stringify(this.amortizeService.currentAmortizeRequestValue.payments),
      documentsLegalRequiredConfigJson: JSON.stringify(this.requiredDocumentsLegalConfigService.currentrequiredDocuments),
      legalConditions: parseInt(this.fParent['legalConditions'].value),
			customPayments: this.fParent['customPayments'].value,
      percentRetentionISR: this.fParent['retention'].value
    }; 

    if(this.quoteId === '') {      
      this.quoteService.create(quote).subscribe((data) => {
        result = data as string;
      });
    }
    else {
      this.quoteService.update(this.quoteId, quote).subscribe((data) => {
        result = data as string;
      });
    }

    return result;
  }

  validateRequiredForm(formControlName: string): boolean {
    if(this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
      return true;
    }
    return false;
  }

  setInputs(): void {
    this.quoteService.get(this.quoteId).subscribe((result) => {
      var data = JSON.parse(result.quoteDataJson);

      this.f1['name'].patchValue(data.name);
      this.f1['isDefault'].patchValue(data.isDefault);
      this.f1['expireDate'].patchValue(data.expireDate);
      this.f1['comments'].patchValue(data.comments);      
    });
  }
}
