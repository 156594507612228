import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { DialogShowConditionsExternalServices } from 'src/app/components/dialog-show-conditions-external-services/dialog-show-conditions-external-services.component';
import { CollectionLog, CollectionUser, ExternalServicesDTO } from 'src/app/models/loan/loan.dto.model';
import { LoanService } from 'src/app/services/loan/loan.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { ExternalServiceCollectionComponent } from 'src/app/components/external-services-collection/external-service-collection.component';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { Balance, BalanceMovements } from 'src/app/models/subscriptionpayment/subscriptionpayment.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { DialogShowImportCollectionComponent } from 'src/app/components/dialog-show-import-collection/dialog-show-import-collection.component';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UserLogged } from 'src/app/models/user/userlogged.model';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-collection',
	templateUrl: './collection.component.html',
	styleUrls: ['./collection.component.scss'],
	providers: [{
		provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
	}]
})

export class CollectionComponent implements OnInit {
	@ViewChild('externalServiceCollection') externalServiceCollection: ExternalServiceCollectionComponent;
	@ViewChild('stepperExternalServices') stepperExternalServices: MatStepper;
	@ViewChild('stepperLoan') stepperLoan: MatStepper;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	currentUser: UserLogged = new UserLogged;

	isLender = true;
	showExternalServices = false;
	personTypeCall = 0;
	personTypeVisit = 0;
	personTypeCallVerification = 0;
	personTypeVisitVerification = 0;
	personType = 0;
	FormrequestJson: FormRequestDTO[];
	dataCollection = [];

	panelContactDataOpenState: any;
	panelLoanProceedingsOpenState = true;
	externalServiceSelected: ExternalServicesDTO;
	dataSourceExternalServices = new MatTableDataSource<ExternalServicesDTO>([]);
	dsCollectionLog = new MatTableDataSource<CollectionUser>([]);
	rowCollectionLogSelected: CollectionUser;
	serviceName: string;

	rowSelected: BalanceMovements = <BalanceMovements>{};
	indexSelected: number;
	balanceMovementsDataSource: MatTableDataSource<BalanceMovements>;

	balanceMovementsColumns: string[] = [
		'nameDebt',
		'serviceName',
		'dateCreated',
		'amount',
		'statusName',
		'id'
	];

	loanRequestId: string;
	filteredExternalServices: ExternalServicesDTO[];
	dataViewCall: ExternalServicesDTO[];
	dataViewVisit: ExternalServicesDTO[];
	dataViewCallVerification: ExternalServicesDTO[];
	dataViewVisitVerification: ExternalServicesDTO[];


	constructor(
		private spinnerService: SpinnerService,
		private dialog: MatDialog,
		private loanService: LoanService,
		private router: Router,
		private datePipe: DatePipe,
		public authService: AuthenticationService,
		private subscriptionpaymentService: SubscriptionpaymentService,
		private changeDetector: ChangeDetectorRef) {
		this.spinnerService.requestStarted();
		this.authService.currentUser.subscribe(x => this.currentUser = x);
	}

	ngOnInit(): void {
		this.getExternalServices();
		this.statusService();
	}

	ngAfterViewInit(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewChecked(): void {
		this.changeDetector.detectChanges();
	}

	getExternalServices() {
		this.loanService.getExternalServicesLanding().subscribe((result) => {
			this.dataSourceExternalServices.data = result;

			this.dataViewCall = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeCall && x.serviceName == "Llamada de cobranza");
			this.dataViewVisit = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeVisit && x.serviceName == "Visita de cobranza");

			//this.dataViewCallVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeCallVerification && x.serviceName == "Llamada de verificación");
			//this.dataViewVisitVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeVisitVerification && x.serviceName == "Visita de verificación");


			this.filteredExternalServices = this.dataViewCall.concat(this.dataViewVisit);
		});
	}

	openCollection(externalServiceCollections: ExternalServicesDTO) {
		this.externalServiceSelected = externalServiceCollections;
		this.serviceName = externalServiceCollections.serviceName

		this.showExternalServices = true;

		if (this.externalServiceCollection != null) {
			this.externalServiceCollection.setExternalServiceCollection(this.externalServiceSelected);
		}
	}

	showConditionsExternalServices(serviceName: string) {
		this.dialog.open(DialogShowConditionsExternalServices, {
			data: {
				serviceName: serviceName
			}
		}).afterClosed().subscribe(
			(confirmado: Boolean) => { }
		);
	}

	panelContactDataOpen(e) {
		this.panelContactDataOpenState = e;

		this.balanceMovementsDataSource = new MatTableDataSource(this.dataCollection);
		this.balanceMovementsDataSource.paginator = this.paginator;
		this.balanceMovementsDataSource.sort = this.sort;
	}

	panelLoanProceedingsOpen(e) {
		this.panelLoanProceedingsOpenState = e;
	}

	getRowCollectionLogSelected() {
		return this.rowCollectionLogSelected;
	}

	capitalizeFirstLetter(value: string): string {
		if (!value || value.length === 0) {
			return '';
		}

		const words = value.split(' ');
		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
		return capitalizedWords.join(' ');
	}

	validateVisiblePersonMoral(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).visible) {
			return true;
		} else {
			return false;
		}
	}

	statusService() {
		this.loanService.getCollectionUser().subscribe(
			(data) => {
				this.dataCollection = data;
			});
	}

	onClickPage() {
		this.rowSelected = <BalanceMovements>{};
		this.indexSelected = null;
	}

	onRowClicked(row, index) {
		this.balanceMovementsDataSource = row;
		this.indexSelected = index;
	}

	openLink(row: CollectionUser) {
		var moduleName ="servicios";
		this.rowCollectionLogSelected = row;
		if (row.loanRequestId != null) {
			this.router.navigate(['prestamos/' + row.loanRequestId + '/detalle-de-servicio/' + row.id, { module: moduleName }])
		} else {

			this.router.navigate(['prestamos/detalle-de-servicio/' + row.id, { module: moduleName }])
		}
	}

	onCheck(data: any, item: any, serviceName: string) {

		if (item.typePerson == 0 && item.serviceName == "Llamada de cobranza") {
			this.personTypeCall = 1;
		} else if (item.typePerson == 1 && item.serviceName == "Llamada de cobranza") {
			this.personTypeCall = 0;
		}

		if (item.typePerson == 0 && item.serviceName == "Visita de cobranza") {
			this.personTypeVisit = 1;
		} else if (item.typePerson == 1 && item.serviceName == "Visita de cobranza") {
			this.personTypeVisit = 0;
		}

		this.dataViewCall = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeCall && x.serviceName == "Llamada de cobranza");
		this.dataViewVisit = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeVisit && x.serviceName == "Visita de cobranza");

		if (item.typePerson == 0 && item.serviceName == "Llamada de verificación") {
			this.personTypeCallVerification = 1;
		} else if (item.typePerson == 1 && item.serviceName == "Llamada de verificación") {
			this.personTypeCallVerification = 0;
		}

		if (item.typePerson == 0 && item.serviceName == "Visita de verificación") {
			this.personTypeVisitVerification = 1;
		} else if (item.typePerson == 1 && item.serviceName == "Visita de verificación") {
			this.personTypeVisitVerification = 0;
		}

		this.dataViewCallVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeCallVerification && x.serviceName == "Llamada de verificación");
		this.dataViewVisitVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personTypeVisitVerification && x.serviceName == "Visita de verificación");

		this.getExternalServices();
	}

	showConditionsCollecion(dataCollection: any) {
		this.dialog.open(DialogShowImportCollectionComponent, {
			data: {
				dataCollection: dataCollection
			}
		}).afterClosed().subscribe(
			(confirmado: Boolean) => { }
		);
	}

	jsonData: any;
	headers: any;

	replaceFieldNames(fieldName: string): string {

		const fieldMapping: any = {
			"Razón social - Datos Prestamista o Acreedor": "busisnessNameLender",
			"Nombre comercial - Datos Prestamista o Acreedor": "tradename",
			"Nombre - Datos Prestamista o Acreedor": "fistNameLender",
			"Segundo nombre -  Datos Prestamista o Acreedor": "secondNameLender",
			"Primer apellido -  Datos Prestamista o Acreedor": "fistLastNameLender",
			"Segundo apellido - Datos Prestamista o Acreedor": "secondLastNameLender",
			"Teléfono celular - Datos Prestamista o Acreedor": "phoneLender",
			"Otro teléfono - Datos Prestamista o Acreedor": "phoneOtherLender",
			"Correo electrónico - Datos Prestamista o Acreedor": "emailLender",
			"Otro correo - Datos Prestamista o Acreedor": "emailOtherLender",
			"Razón social - Datos de Deudor": "busisnessNameDebto",
			"Nombre comercial - Datos de Deudor": "tradenameDebto",
			"Tipo de Deudor - Datos de Deudor": "firstNameDebto",
			"Nombre - Datos de Deudor": "middleNameDebto",
			"Segundo nombre - Datos de Deudor": "lastName1Debto",
			"Primer apellido - Datos de Deudor": "lastName2Debto",
			"Segundo apellido - Datos de Deudor": "Segundo apellido - Datos de Deudor",
			"Teléfono celular - Datos de Deudor": "phone",
			"Otro teléfono - Datos de Deudor": "phoneOtherDebto",
			"Correo electrónico - Datos de Deudor": "email",
			"Otro Correo - Datos de Deudor": "emailOtherDebto",
			"Calle - Datos de Deudor": "street",
			"Número exterior - Datos de Deudor": "exteriorNumber",
			"Número interior - Datos de Deudor": "interiorNumber",
			"Colonia - Datos de Deudor": "districtName",
			"País - Datos de Deudor": "countryId",
			"Estado - Datos de Deudor": "stateId",
			"Municipio - Datos de Deudor": "cityId",
			"Código postal - Datos de Deudor": "zipCode",
			"Indicaciones adicionales de esta direccíon - Datos de Deudor": "referenceAddress",
			"Tipo de Adeudo - Datos préstamo / Adeudo": "typeLoanDebtor",
			"Fecha de Préstamo(aaaa-mm-dd) - Datos préstamo / Adeudo": "startDate",
			"Número / ID Préstamo - Datos préstamo / Adeudo": "idDebt",
			"Monto de Préstamo - Datos préstamo / Adeudo": "loanAmount",
			"Monto Total a Pagar - Datos préstamo / Adeudo": "totalPending",
			"Número de pagos realizados - Datos préstamo / Adeudo": "numberOfPayments",
			"Total Abonado - Datos préstamo / Adeudo": "totalPaid",
			"Saldo Total Pendiente - Datos préstamo / Adeudo": "totalBalanceToLiquited",
			"Saldo Exigible - Datos préstamo / Adeudo": "totalBalanceLate",
			"Dia visita(aaaa-mm-dd) - Datos opcionales": "dayVisit",
			"Horario visita desde - Datos opcionales": "hourStart",
			"Hasta - Datos opcionales": "hourEnd",
			"Tipo de visita - Datos opcionales": "visitType",
			"Banco - Datos opcionales": "bank",
			"Cuenta - Datos opcionales": "account",
			"CLABE - Datos opcionales": "clabe",
			"Número de tarjeta - Datos opcionales": "cardNumber",
			"Enlace de pago - Datos opcionales": "linkPay",
			"Comentarios adicionales - Datos opcionales": "comments"
		};
		return fieldMapping[fieldName] || fieldName;
	}

	onFileChange(event: any) {
		const file = event.target.files[0];
		const reader: FileReader = new FileReader();

		reader.onload = (e: any) => {
			const binaryString: string = e.target.result;
			const workbook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' });
			const worksheetName: string = workbook.SheetNames[0];
			const worksheet: XLSX.WorkSheet = workbook.Sheets[worksheetName];

			const excelData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

			const headerRow: any[] = excelData[1].map((header: any) => header === undefined ? null : header);

			const jsonDataArray: any[] = [];

			for (let i = 2; i < excelData.length; i++) {
				const currentRowData: any[] = excelData[i];

				if (currentRowData[2] !== '' && currentRowData[2] !== null && currentRowData[2] !== undefined) {
					const jsonData: any = {};

					const lenderFullName = `${currentRowData[2]} ${currentRowData[3]} ${currentRowData[4]} ${currentRowData[5]}`;

					for (let j = 0; j < headerRow.length; j++) {
						const header = headerRow[j];
						let value = currentRowData[j];

						if (j === 31) {
							if (value !== null && value !== '' && value !== undefined) {
								value = this.customDateParser(value);
							} else {
								value = null;
							}
						}

						const newHeader = this.replaceFieldNames(header);
						jsonData[newHeader] = value === undefined ? null : value;
					}

					jsonData['lenderFullName'] = lenderFullName;

					jsonDataArray.push(jsonData);
				}
			}

			this.showConditionsCollecion(jsonDataArray);
		};

		reader.readAsBinaryString(file);
	}

	customDateParser(dateString: number): string {
		const date = new Date(Date.UTC(1900, 0, 0));
		const dateResult = new Date(date.getTime() + dateString * 24 * 60 * 60 * 1000);

		dateResult.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });
		const dateFormatted = this.datePipe.transform(dateResult, 'yyyy-MM-dd');

		return dateFormatted || '';
	}

	layoutFormat() {
		let layoutArchive = '';
		let url ="/files/Templates/LayoutCobranza.xlsx";

		if (environment.production == true) {
			layoutArchive = "https://storage-prod.a-lender.com" + url;
		} else {
			layoutArchive = "https://storage-dev.a-lender.com" + url;
		}

		return layoutArchive;
	}
}
