<div>
    <h5>Detalle de notificaciones</h5>
    <hr>

    <p>Selecciona el renglón de la notificación deseada</p>

    <div class="d-flex justify-content-end mb-4">
        <div class="form-group col-md-4 col-12">
            <label>Buscar</label>
            <div>
                <i class="bx bx-search input_icon"></i>
                <input class="form-control" #filterText placeholder="" type="text" />
            </div>
        </div>
    </div>
</div>

<div class="table-responsive">
    <div class="react-bootstrap-table">
        <table mat-table [dataSource]="notificationsDataSource"
            (dblclick)="readNotification(rowSelected.notificationId)" class="table table align-middle" [ngClass]="isLender ? 'bg-primary' : 'bg-secondary'">
            <tbody>
                <ng-container matColumnDef="iconReaded">
                    <th mat-header-cell *matHeaderCellDef>Estatus</th>
                    <td mat-cell *matCellDef="let item">
                        <i class='bx bxs-envelope' *ngIf="!item.isReaded" (click)="readNotification(item.notificationId)"></i>
                        <i class='bx bx-envelope' *ngIf="item.isReaded" (click)="readNotification(item.notificationId)"></i>
                    </td>
                </ng-container>
                <ng-container matColumnDef="dateCreated">
                    <th mat-header-cell *matHeaderCellDef>Fecha</th>
                    <td mat-cell *matCellDef="let item"> {{item.dateCreated | date:'short'}} </td>
                </ng-container>
                <ng-container matColumnDef="subject">
                    <th mat-header-cell *matHeaderCellDef>Tipo de notificación</th>
                    <td mat-cell *matCellDef="let item"> {{item.subject}} </td>
                </ng-container>
                <ng-container matColumnDef="from">
                    <th mat-header-cell *matHeaderCellDef>Remitente</th>
                    <td mat-cell *matCellDef="let item"> {{item.from}} </td>
                </ng-container>
                <ng-container matColumnDef="bobyMessage">
                    <th mat-header-cell *matHeaderCellDef>Comentarios</th>
                    <td mat-cell *matCellDef="let item"> {{item.bobyMessage}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="notificationsColumns"></tr>
                <tr mat-row *matRowDef="let item; columns: notificationsColumns; let i = index"
                    (click)="onRowClicked(item, i)" [ngClass]="{'row-selected': indexSelected === i }"
                    [class.unread]="!item.isReaded"></tr>
            </tbody>
        </table>
    </div>
</div>
<mat-paginator [pageSizeOptions]="[10, 25, 100]" class="btn btn-default dropdown-toggle"></mat-paginator>

<div class="d-flex flex-wrap gap-2 justify-content-end">
    <div>
        <mat-button-toggle-group name="menu" aria-label="menu" appearance="standard">
            <button class="btn btn-outline-primary external-btn" routerLink="/inicio">
                Cerrar
            </button>
        </mat-button-toggle-group>

        <mat-menu #menuLoanRequestItem="matMenu">
            <mat-button-toggle-group name="menu" aria-label="menu" appearance="standard">
                <button class="btn btn-outline-primary external-btn" routerLink="/inicio">
                    Cerrar
                </button>
            </mat-button-toggle-group>
        </mat-menu>
    </div>
</div>

<!-- <app-root></app-root> -->