import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { CreateCall } from 'src/app/models/loan/callCenter.dto.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogPreviewDocumentComponent } from '../dialog-preview-document/dialog-preview-document.component';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export interface DialogData {
	row: CreateCall;
	lenderFullName: string;
	applicantName: string;
	phone: string;
}

@Component({
	selector: 'app-dialog-show-details-callcenter-results.',
	templateUrl: './dialog-show-details-callcenter-results.component.html',
	styleUrls: ['./dialog-show-details-callcenter-results.component.scss']
})

export class DialogShowDetailsCallCenterComponent implements OnInit, AfterViewInit {
	@ViewChild('divExport') divExport!: ElementRef;
	stylesHash: any;
	currentOrigin: any;
	getCalls: any = [];
	newArchivesFilter: any = [];
	newQuestions: any = [];
	newQuestionsHalf: any = [];
	dateEndManagement: any;
	dateStartManagement: any;
	timeDifference: string;
	lenderFullName: any;
	applicantName: any;
	stateData: any;
	externalId: any


	constructor(private datePipe: DatePipe, 
		private router: Router,
		private dialog: MatDialog,) {
		this.stateData = history.state;

		try {

		} catch (error) {
			this.router.navigate(['/centro-de-llamadas']);
		}

		if (this.stateData.navigationId == undefined || this.stateData.navigationId == null || this.stateData.navigationId <= 1) {
			this.router.navigate(['/centro-de-llamadas']);
		}
	}


	ngOnInit() {
		const rowData = this.stateData.rowData;
		this.lenderFullName = this.stateData.lenderFullName;
		this.applicantName = this.stateData.applicantName;
		this.externalId = this.stateData.externalId;
		this.getCalls = rowData;

		this.getCurrentUrl();
		this.dateEndManagement = new Date(this.getCalls.dateEndManagement);
		this.dateStartManagement = new Date(this.getCalls.dateStartManagement);
		const diffInSeconds = Math.floor((this.dateEndManagement.getTime() - this.dateStartManagement.getTime()) / 1000);
		const minutes = Math.floor(diffInSeconds / 60);
		const seconds = diffInSeconds % 60;
		this.timeDifference = `${minutes} min ${seconds} s`;

		if (environment.production == true) {
			this.stylesHash = '.b3e3a8f3e87bf153';
		} else {
			this.stylesHash = "";
		}

		this.countAnswers()

	}

	ngAfterViewInit(): void { }

	capitalizeFirstLetter(value: string): string {
		if (!value || value.length === 0) {
			return '';
		}

		const words = value.split(' ');
		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
		return capitalizedWords.join(' ');
	}

	getCurrentUrl() {
		this.currentOrigin = window.location.origin;
	}

	exportToPDF() {
		const printContents = this.divExport.nativeElement.innerHTML;

		const stylesLink = this.generateStylesLink();

		const windowsEmergent = window.open('', '', 'width=800,height=600');

		windowsEmergent.document.write(`
		  <html>
		  <head>
			<title>Centro de llamadas</title>
			${stylesLink}
			<style>
			@page {
				size: A3;
				margin: 10mm;
			  }
			@media print {
				#map {
					height: 400px;
				}
				body {
				font-family: "Poppins", sans-serif;
				}
				.close {
					display: none !important;
				  }
				p:not(:last-child) {
					margin-bottom: 0 !important;
				  }
				  
				  p {
					color: $c_black;
					font-size: 14px;
					font-weight: 500;
				  }
				  
				  .row {
					.col-md-3.col-6 {
					  p {
						margin-bottom: 0.5rem;
					  }
				  
					  &:last-child, &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4) {
						p {
						  margin-bottom: 0;
						}
					  }
					}
				  }
			}
        </style>
		  </head>
		  <body>${printContents}</body>
		  </html>
		`);
		windowsEmergent.document.close();

		windowsEmergent.onload = () => {
			windowsEmergent.print();
		};

		windowsEmergent.onafterprint = () => {
			windowsEmergent.close();
		};;
	}

	generateStylesLink() {
		const stylesLink = document.createElement('link');
		stylesLink.setAttribute('rel', 'stylesheet');
		stylesLink.setAttribute('type', 'text/css');
		stylesLink.setAttribute('href', `${this.currentOrigin}/styles${this.stylesHash}.css`);
		document.head.appendChild(stylesLink);
		return stylesLink.outerHTML;
	}

	extractFtpUrl(url: string): string {
		const domainRegex = /^(?:https?:\/\/)?[^/]+/i;
		url = url.replace(domainRegex, '');


		if (environment.production == true) {
			url = "https://storage-prod.a-lender.com" + url;
		} else {
			url = "https://storage-dev.a-lender.com" + url;
		}
		return url;
	}


	countAnswers() {
		let answer = 0;
		let additionalCount = 0;

		const questionAdd = {
			"answer": "Otro",
			"comments": "Comentarios",
			"formContact": "Forma de contacto",
			"amount": "Monto por pagar",
			"date": "Fecha de pago",
			"form": "Forma de pago",
			"otherForm": "Otra forma de pago",
			"hour": "Hora para volver a realizar la llamada",
			"name": "",
			"url": ""
		};


		var newQuestions = this.getCalls.questions.filter(function (question) {
			return question.idQuestion !== 16.5;
		});

		var newArchivesFilter = this.getCalls.questions.filter(function (question) {
			return question.idQuestion == 16.5;
		});

		newQuestions.forEach(item => {
			if (item.answer !== null) {
				const itemQuestion = item.question;
				let itemAnswer = item.answer;

				if (itemQuestion === "Evaluación de llamada" && itemAnswer === 0) {
					itemAnswer = "N/A";
				}

				this.newQuestions.push({ Question: itemQuestion, Answer: itemAnswer });
				answer++;
			}
			if (item.additionalAnswer !== null) {
				item.additionalAnswer.forEach(subItem => {
					for (const key in subItem) {
						if (subItem[key] !== null) {
							let itemAddQuestion = key;
							const itemAddAnswer = subItem[key];
							if (questionAdd.hasOwnProperty(itemAddQuestion)) {
								itemAddQuestion = questionAdd[itemAddQuestion];
							}
							this.newQuestions.push({ Question: itemAddQuestion, Answer: itemAddAnswer });
							additionalCount++;
						}
					}
				});
			}
		});

		const halfwayIndex = Math.ceil(this.newQuestions.length / 2);
		this.newQuestionsHalf = this.newQuestions.splice(halfwayIndex);

		newArchivesFilter.forEach(item => {
			if (item.additionalAnswer !== null) {
				item.additionalAnswer.forEach(subItem => {
					for (const key in subItem) {
						if (subItem[key] !== null) {
							let itemAddQuestion = key;
							const itemAddAnswer = subItem[key];
							if (itemAddQuestion == "url") {
								this.newArchivesFilter.push({ url: itemAddAnswer });
							}
							additionalCount++;
						}
					}
				});
			}
		});
	}

	return() {
		this.router.navigate(['/centro-de-llamadas/' + this.externalId]);
	}

	openImage(url: string) {

		const domainRegex = /^(?:https?:\/\/)?[^/]+/i;
		url = url.replace(domainRegex, '');


		if (environment.production == true) {
			url = "https://storage-prod.a-lender.com" + url;
		} else {
			url = "https://storage-dev.a-lender.com" + url;
		}
		var fileImage = url

		if (fileImage != "") {

			const dialogRef = this.dialog.open(DialogPreviewDocumentComponent, {
				autoFocus: false,
				width: '80%'
			});
			let instance = dialogRef.componentInstance;
			instance.url = fileImage;
			instance.extensionFile = /[^.]+$/.exec("foto.jpg")[0];
		}
	}
}

