import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';

@Component({
  selector: 'app-dialog-comment-loanrequest',
  templateUrl: './dialog-comment-loanrequest.component.html',
  styleUrls: ['./dialog-comment-loanrequest.component.scss']
})
export class DialogCommentLoanrequestComponent implements OnInit {

  conditionName: string;
  loanRequestDetailId: string;
  commentForm: FormGroup;
  submitted = false;
  requiredErrorMessage: string = "Este campo es requerido.";
  commentsSent: any;
  commentsReceived: any;
  
  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<DialogCommentLoanrequestComponent>,
              private loanrequestService: LoanrequestService,
              private authService: AuthenticationService,
              private alertGlobalService: AlertGlobalService) {
    this.createCommentForm();
  }

  get f1() { return this.commentForm.controls; }

  createCommentForm() {
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getComments();
  }

  getComments() {
    this.loanrequestService.getComments(this.loanRequestDetailId)
      .subscribe((data) => {
        this.commentsSent = data.filter(x => x.customerId === this.authService.currentUserValue.customerId);
        this.commentsReceived = data.filter(x => x.customerId !== this.authService.currentUserValue.customerId);
      });
  }

  onSubmit() {
    this.submitted = true;

    if (this.commentForm.invalid && this.commentForm.invalid) {
        return;
    }

    let comment = {
      customerId: this.authService.currentUserValue.customerId,
      comment: this.f1['comment'].value
    }

    this.loanrequestService.addComment(this.loanRequestDetailId, comment)
      .subscribe((data) => {
        this.alertGlobalService.showAlertSuccess('Comentario agregado con éxito.');
      });

    this.dialogRef.close();
  }

  validateRequiredForm(formControlName: string): boolean {
    if(this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
      return true;
    }
    return false;
  }

}
