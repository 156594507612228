import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeofamortizationService {

  typeOfAmortizationList: KeyValue<number, string>[] = [
    { key: 0, value: "Simple" },
    { key: 1, value: "Global" },
    { key: 2, value: "Bancario" },
  ];

  private typeOfAmortizationList$ = new BehaviorSubject<KeyValue<number, string>[]>(this.typeOfAmortizationList);
  
  constructor() { }

  getList() {
    return this.typeOfAmortizationList$.asObservable();
  }
}
