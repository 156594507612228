export class UserLogged {
    userId: string;
    firstName: string;
    middleName: string
    lastName1: string;
    lastName2: string;
    username: string;
    phoneNumber: string;
    countryPhoneCode: string;
    countryCode: string;
    languageCode: string;
    currencyCode: string;
    personType: number;
    emailConfirmed: boolean;
    phoneNumberConfirmed: boolean;
    customerId: string;
    isLender: boolean;
    token: string;
    idCustomerConekta: string
    phoneMobile: string;
    contact: string;
    registrationMethod: number;
    profilePicture: string;
    userType: number;
}
  
export class UsserIP {
  city: string;
  country: string;
  ip_address: string
  region: string;
}