<div class="container">
	<div class="row justify-content-center">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="card overflow-hidden">
				<div class="bg-primary bg-soft-primary">
					<div class="d-flex align-items-center">
						<div class="col-8 col-sm-9 col-md-9">
							<div class="text-white p-4">
								<h5 class="text-white">Ingresa a tu cuenta</h5>
								<p class="mb-0">Inicia sesión para continuar</p>
							</div>
						</div>
						<div class="col-4 col-sm-3 col-md-3">
							<div class="avatar-md">
								<span class="avatar-title rounded-circle bg-light">
									<a href="/">
										<img src="assets/images/icons/c_wing.svg" height="34"
											alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<form class="p-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
						<div class="form-horizontal">
							<div class="mb-3">
								<div class="form-group">
									<label>Correo electrónico</label>
									<input autocapitalize="off" autofocus="autofocus" autocomplete="email"
										class="form-control" formControlName="username" placeholder="Escribe tu correo"
										type="email"
										[ngClass]="submitted && validateRequiredForm('username') ? 'is-invalid' : ''" />
									<mat-error *ngIf="submitted && f.username.errors && f.username.errors.required">
										Este campo es requerido.
									</mat-error>
									<mat-error *ngIf="submitted && f.username.errors && f.username.errors.email">Formato
										de correo inválido.</mat-error>
								</div>
							</div>

							<div class="mb-3">
								<div class="form-group">
									<label>Contraseña</label>
									<div>
										<i [ngClass]="getClass()" aria-hidden="true" class="input_icon"
											(click)="hide = !hide"></i>
										<input autocomplete="current-password" class="form-control"
											formControlName="password" placeholder="Escribe tu contraseña"
											[type]="hide ? 'password' : 'text'"
											[ngClass]="submitted && validateRequiredForm('password') ? 'is-invalid' : ''" />
										<mat-error *ngIf="submitted && f.password.errors && f.password.errors.required">
											Este campo es requerido.
										</mat-error>
									</div>
								</div>
							</div>

							<div class="form-check">
								<input class="form-check-input" formControlName="remember" type="checkbox" />
								<label class="form-check-label">
									Recuérdame
								</label>
							</div>

							<div class="mt-3 text-center">
								<button class="btn_primary dark" type="submit">
									<a class="nav-link">Iniciar sesión</a>
								</button>
							</div>
							<!--<div class="mt-3 text-center">
								<p class="mb-02">O</p>

								<div style="display: inline-block;">
									<div id="g_id_onload"
									data-client_id="455335909360-v69r33d4ic9h02tfuei5ko4j76atsih5.apps.googleusercontent.com"
									data-context="use"
									data-ux_mode="popup"
									data-callback="handleCredentialResponse"
									data-itp_support="true">
							   </div>
							   
							   <div class="g_id_signin"
									data-type="standard"
									data-shape="pill"
									data-theme="filled_blue"
									data-text="signin_with"
									data-size="large"
									data-logo_alignment="left">
							   </div>
								</div>
								</div>-->

							<div class="mt-4 text-center">
								<a class="text-muted" href="/forgotpassword">
									<i class="fa fa-lock" aria-hidden="true"></i>
									¿Olvidaste tu contraseña?
								</a>
							</div>

							<div class="mt-4 text-center">
								<p class="mb-0">
									¿Aún no tienes una cuenta? <a href="/crear-cuenta" class="fw-medium text-primary">
										Regístrate aquí</a>
								</p>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>