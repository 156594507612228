<form [formGroup]="familyDataJsonForm">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelFamilyDataState" class="mb-4 bg-white"
            (opened)="panelFamilyDataOpen(true)" (closed)="panelFamilyDataOpen(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <h5>Datos familiares</h5><i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
                <div *ngIf="validateFormrequest('MaritalStatus')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-MaritalStatus" class="">Estado civil<span
                                *ngIf="validateFormrequestJson('MaritalStatus')">*</span></label>
                        <select #selectMaritalStatus class="form-control"
                            formControlName="value-MaritalStatus"
                            (change)="onChangeSelectMaritalStatus(selectMaritalStatus.value)"
                            [ngClass]="dr && validateRequiredFormF8('value-MaritalStatus') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of maritalStatusList" value="{{item.key}}">
                                {{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('MaritalRegime')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-MaritalRegime" class="">Régimen marital<span
                                *ngIf="validateFormrequestJson('MaritalRegime')">*</span></label>
                        <select class="form-control" formControlName="value-MaritalRegime"
                            [ngClass]="dr && validateRequiredFormF8('value-MaritalRegime') ?'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of maritalRegimeList" value="{{item.key}}">
                                {{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('NumberChildren')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-NumberChildren" class="">Número hijos<span
                                *ngIf="validateFormrequestJson('NumberChildren')">*</span></label>
                        <input type="number" formControlName="value-NumberChildren" class="form-control"
                            [ngClass]="dr && validateRequiredFormF8('value-NumberChildren') ?'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('NumberEconomicDependents')"
                    class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-NumberEconomicDependents" class="">Número dependientes
                            económicos<span
                                *ngIf="validateFormrequestJson('NumberEconomicDependents')">*</span></label>
                        <input type="number" formControlName="value-NumberEconomicDependents"
                            class="form-control"
                            [ngClass]="dr && validateRequiredFormF8('value-NumberEconomicDependents') ?'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('SpouseName')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-SpouseName" class="">Nombre cónyuge/pareja<span
                                *ngIf="validateFormrequestJson('SpouseName')">*</span></label>
                        <input type="text" formControlName="value-SpouseName" class="form-control"
                            [ngClass]="dr && validateRequiredFormF8('value-SpouseName') ?'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('SecondName')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-SecondName" class="">Segundo nombre<span
                                *ngIf="validateFormrequestJson('SecondName')">*</span></label>
                        <input type="text" formControlName="value-SecondName" class="form-control"
                            [ngClass]="dr && validateRequiredFormF8('value-SecondName') ?'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('LastName1')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-LastName1" class="">Primer apellido<span
                                *ngIf="validateFormrequestJson('LastName1')">*</span></label>
                        <input type="text" formControlName="value-LastName1" class="form-control"
                            [ngClass]="dr && validateRequiredFormF8('value-LastName1') ?'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('LastName2')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-LastName2" class="">Segundo apellido<span
                                *ngIf="validateFormrequestJson('LastName2')">*</span></label>
                        <input type="text" formControlName="value-LastName2" class="form-control"
                            [ngClass]="dr && validateRequiredFormF8('value-LastName2') ?'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Occupation')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Occupation">Ocupación cónyuge/ pareja <span
                                *ngIf="validateFormrequestJson('Occupation')">*</span></label>
                        <select #selectCountry class="form-control" formControlName="value-Occupation"
                            [ngClass]="dr && validateRequiredFormF8('value-Occupation') ?'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of getOccupationList('0')" value="{{item.key}}">
                                {{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('WorkplaceName')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-WorkplaceName">Nombre lugar de trabajo<span
                                *ngIf="validateFormrequestJson('WorkplaceName')">*</span></label>
                        <input type="text" formControlName="value-WorkplaceName" class="form-control"
                            [ngClass]="dr && validateRequiredFormF8('value-WorkplaceName') ?'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('WorkPhone')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-WorkPhone">Teléfono trabajo<span
                                *ngIf="validateFormrequestJson('WorkPhone')">*</span></label>
                        <input type="text" formControlName="value-WorkPhone"
                            [textMask]="{mask: phoneMask}" class="form-control"
                            [ngClass]="dr && validateRequiredFormF8('value-WorkPhone') ?'is-invalid' : ''">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('CellPhone')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-CellPhone">Teléfono celular<span
                                *ngIf="validateFormrequestJson('CellPhone')">*</span></label>
                        <input type="text" formControlName="value-CellPhone"
                            [textMask]="{mask: phoneMask}" class="form-control"
                            [ngClass]="dr && validateRequiredFormF8('value-CellPhone') ?'is-invalid' : ''">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>