<div class="container-fluid">
    <h4>Aviso Legal</h4>
    <div class="card">
        <div class="card-body">
            <p class="text">
                El aviso sobre ciertos términos y condiciones de navegación que a continuación se presenta (el “Aviso Legal”) constituye un acuerdo íntegro entre Apoyo Soluciones Productivas, S.A.P.I. de C.V., sus filiales y/o subsidiarias y/o sus partes relacionadas (el “A-Lender”), con domicilio en Av. Lázaro Cárdenas No. 2400 Pte., Oficina C31-4, Col. Residencial San Agustín, San Pedro Garza García, N.L., CP 66269, dirección electrónica: www.a-lender.com (el “Sitio”), y con correo electrónico de contacto hola@a-lender.com ; y cualquier usuario del Sitio. La utilización del Sitio, por parte de cualquier persona, le atribuye la calidad de usuario (el “Usuario”) y ello implica su adhesión plena e incondicional a este Aviso Legal. En consecuencia, es responsabilidad única y exclusiva del Usuario, leer previamente este Aviso Legal y sus modificaciones correspondientes, cada vez que accede al Sitio. Si, en cualquier momento, el Usuario no estuviera de acuerdo total o parcialmente con el presente Aviso Legal, deberá abstenerse inmediatamente de usar el Sitio.
            </p>
            
            <p class="text">
                <b>ACCESO, UTILIZACIÓN Y ESTANCIA EN EL SITIO</b>
                
                El acceso y utilización del Sitio tiene carácter gratuito para los Usuarios. El Usuario no estará obligado a registrarse o inscribirse en el Sitio. Los servicios ofrecidos a través del Sitio están dirigidos a toda persona con capacidad jurídica para obligarse de conformidad con los términos y condiciones aplicables una vez que el Usuario decide crear un usuario y contraseña para transaccionar mediante el Sitio. El Usuario es el único responsable frente a A-Lender, y cualquier tercero, respecto de su conducta al acceder, consultar y proporcionar información al Sitio y de las consecuencias que se puedan derivar de una utilización, con fines o efectos ilícitos o contrarios al objeto del Sitio, su contenido, elaborado o no por A-Lender, publicado o no bajo su nombre de forma oficial; así como aquellas consecuencias que se puedan derivar de la utilización contraria al contenido del Aviso Legal que sea lesiva de los intereses o derechos de terceros, o que de cualquier forma pueda dañar, inutilizar o deteriorar el Sitio e impedir el normal disfrute de otros Usuarios.
            </p>
            
            <p class="text">
                <b>OBJETO</b>
                
                A-Lender pone a disposición del Usuario el Sitio, ya sea que se trate de un sitio patrocinado, administrado por intermedio de un tercero o alojado por un tercero, facilitando el acceso a los Usuarios a información diversa proporcionada por A-Lender o por personas vinculadas a dicha información de manera directa o indirecta (los “Contenidos”). El Usuario reconoce que el uso del Sitio no le confiere ningún derecho de propiedad sobre el mismo, o cualquiera de sus elementos o Contenidos. A-Lender se reserva el derecho a modificar, total o parcialmente, en cualquier momento y sin aviso previo, la presentación, configuración, información, Contenidos y en general cualquier parte o aspecto relacionado directa o indirectamente con el Sitio.
            </p>

            <p class="text">
                El Usuario reconoce que las descripciones de los servicios expuestos en el Sitio se realizan de forma ilustrativa, y tendrán el alcance y las especificaciones comunes, básicas y simples que regularmente el mercado ofrece, salvo cuando dichos alcances y especificaciones se detallan en el Sitio o en los términos y condiciones que regulen una transacción comercial entre el Usuario y A-Lender. De igual forma, reconoce que la información contenida y/o publicada a través del Sitio, tiene fines meramente informativos sobre los servicios que proporciona A-Lender.
            </p>
            
            <p class="text">
                <b>CONTENIDOS</b>
                
                El Usuario se compromete a:
            </p>

            <ul>
                a)	utilizar el Sitio y sus Contenidos de acuerdo a las leyes aplicables y de orden público, absteniéndose de realizar acto que menoscabe, deteriore, inutilice o dañe la imagen y/o información divulgada por A-Lender o de alguna manera lesione derechos o intereses de terceras personas, vinculadas directa o indirectamente a éste; 
                
                b)	no copiar, difundir, modificar, reproducir, distribuir o utilizar de manera alguna con o sin fines de lucro los contenidos y los elementos utilizados en el Sitio, a menos que se cuente con la autorización expresa y por escrito de A-Lender;
                
                c)	no modificar o manipular las marcas, logotipos, avisos comerciales, nombres comerciales y signos distintivos en general de A-Lender, del Sitio o de las personas vinculadas con A-Lender (salvo que cuente con su autorización por escrito);
                
                d)	suprimir, eludir o modificar los Contenidos y los elementos utilizados en el Sitio, así como los dispositivos técnicos de protección, o cualquier mecanismo o procedimiento establecido en el Sitio.
            </ul>

            <p class="text">
                Queda excluida de los puntos anteriores, aquella información generada a través del Sitio para uso y manejo del Usuario, misma que podrá ser impresa y/o copiada para los intereses que más convengan al mismo. En caso de que el Usuario sea una persona moral, se apegará a lo dispuesto por el artículo 148, fracción IV de la Ley Federal del Derecho de Autor.
            </p>

            <p class="text">
                El Usuario reconoce y acepta que el uso del Sitio y de los Contenidos, es bajo su exclusiva y estricta responsabilidad, por lo que A-Lender no será, en ningún momento y bajo ninguna circunstancia, responsable por cualquier desperfecto o problema que se presente en el equipo de cómputo (hardware) o programas de cómputo (software) que utilice el Usuario para acceder o navegar en cualquier parte del Sitio.
            </p>

            <p class="text">
                A-Lender tiene derecho a realizar, durante intervalos temporales definidos, campañas promocionales para promover el registro de nuevos miembros en su Sitio. A-Lender se reserva el derecho de modificar los términos y condiciones de las compras o contrataciones de servicios, así como de proceder a la exclusión de cualquiera de los mismos.
            </p>

            <p class="text">
                A-Lender declara que todos los Contenidos, y los elementos utilizados en el Sitio, se encuentran debidamente registrados y protegidos bajo las autoridades y leyes correspondientes en México. El Usuario se obliga a respetar todos los derechos contenidos en el Aviso de Derecho de Autor establecido en el Sitio.
            </p>
            
            <p class="text">
                <b>SITIOS Y CONTENIDOS AJENOS a A-Lender</b>
                
                A-Lender podrá hacer uso de su derecho de publicación de cualquier material informativo y/o de sitios o subsitios propiedad de terceros, vinculados o no a A-Lender, que considere de interés para los Usuarios. No obstante lo anterior, A-Lender se deslinda de toda responsabilidad, del acceso y/o uso que realice el Usuario de la información ahí contenida y/o del uso, origen y destino de la información que se desprenda de ligas distintas (vínculo, hipervínculo, link).
                
                Toda publicación realizada dentro del Sitio, por parte del Usuario, no genera obligación de pago ante terceros por razón de promoción, publicación y/o manejo de información y/o imagen, a menos que se cuente con un contrato previamente firmado con A-Lender.
            </p>
            
            <p class="text">
                <b>RESPONSABILIDAD RESPECTO A LOS CONTENIDOS</b>
                
                A-Lender no asume responsabilidad alguna derivada, de manera enunciativa más no limitativa de: (i) la utilización que el Usuario pueda hacer de los materiales de este Sitio, o de los Contenidos, o de los sitios web de enlace, ya sean prohibidos o permitidos, en infracción de los derechos de propiedad intelectual y/o industrial de contenidos de la web o de terceros; (ii) los eventuales daños y perjuicios al Usuario causados por un funcionamiento normal o anormal de las herramientas de búsqueda, de la organización o la localización de los Contenidos y/o acceso al Sitio y, en general, de los errores o problemas que se generen en el desarrollo o instrumentación de los elementos técnicos que el Sitio facilite al Usuario; (iii) los contenidos de aquellas páginas a las que el Usuario pueda acceder desde enlaces incluidos en el Sitio, ya sean autorizados o no; (iv) los actos u omisiones de terceros, independientemente de la relación que dichos terceros pudieran tener con A-Lender; (v) el acceso de menores de edad a los Contenidos, así como el envío de información personal que estos pudieran realizar; (vi) las comunicaciones o diálogos en el transcurso de los debates, foros, chats y comunidades virtuales que se organicen a través de o en torno al Sitio de enlace, ni responderá, por tanto, de los eventuales daños y perjuicios que sufra el Usuario a consecuencia de dichas comunicaciones y/o diálogos; etc.
            </p>
            
            <p class="text">
                <b>RESPONSABILIDAD RESPECTO A FALLAS DE LAS TECNOLOGÍAS DE INFORMACIÓN Y COMUNICACIÓN</b>
                
                A-Lender no será responsable, en forma alguna, cuando se produzcan:
            </p>

            <ul>
                1.	errores o retrasos en el acceso al Sitio a la hora de introducir los datos en el formulario de solicitud;
                
                2.	la lentitud o imposibilidad de recepción por parte de los destinatarios de la confirmación de la solicitud o cualquier anomalía que pueda surgir por incidencias, ya sean debidas a problemas en la red Internet, caso fortuito o fuerza mayor o cualquier u otra contingencia imprevisible y ajena a A-Lender;
                
                3.	fallos o incidencias que pudieran producirse en las comunicaciones, ya sea borrándose o por transmisiones incompletas, de manera que no se garantiza que los servicios del Sitio estén constantemente operativos;
                
                4.	de los errores o daños producidos al Sitio por un mal uso del servicio por parte del Usuario u otros usuarios;
                
                5.	de la no operatividad o problemas en la dirección de correo electrónico facilitada por el Usuario para el envío de la confirmación de la solicitud realizada.
                
            </ul>

            <p class="text">
                En todos los casos expuestos, el compromiso de A-Lender será realizar su mejor esfuerzo para solucionar los problemas que estén a su alcance, y ofrecer todo el apoyo necesario al Usuario para llegar a una solución rápida y satisfactoria de la incidencia.
            </p>
            
            <p class="text">
                <b>EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD</b>
                
                El Usuario es el único responsable del uso que haga al Sitio y su Contenido. El Usuario reconoce que la información del Sitio y de los servicios de A-Lender se proporcionan “como están”, sin ninguna garantía expresa o implícita de comerciabilidad o de aptitud para un fin determinado. A-Lender no garantiza la precisión ni la integridad de la información, textos, gráficos, enlaces u otros elementos contenidos en el Sitio o Contenido. A-Lender no garantiza la operación ininterrumpida o libre de todo error del Sitio y/o su Contenido. Puesto que toda la información referida en el Sitio y su Contenido se encuentra en la nube, A-Lender no controla ni garantiza la ausencia de virus en los Contenidos, ni la ausencia de otros elementos en los Contenidos que puedan producir alteraciones en el sistema informático del Usuario (software y/o hardware) o en los documentos electrónicos almacenados en su sistema informático.
            </p>

            <p class="text">
                Todo material descargado u obtenido de un modo distinto al previsto en el Sitio, será bajo responsabilidad y riesgo único del Usuario, respecto de los daños que pudiera causar en el sistema informático del dispositivo a través del cual realice su conexión y/o la pérdida de datos que derive de la descarga de ese material. En ningún caso, ni A-Lender ni sus proveedores serán responsables de daño alguno derivado del uso del Sitio o Contenido, o de no poder usarlos (EN PARTICULAR, SIN LIMITACIÓN ALGUNA, DE LOS DAÑOS DIRECTOS O INDIRECTOS, MORALES, INCIDENTALES, EXCESIVOS, REMOTOS Y/O EVENTUALES, PERJUICIOS, LUCRO CESANTE, INTERRUPCIÓN DE LA ACTIVIDAD COMERCIAL O PÉRDIDA DE INFORMACIÓN O DATOS Y/O INFRACCIONES DE SEGURIDAD), aun cuando se hubiera advertido a A-Lender de dicha posibilidad.
            </p>
            
            <p class="text">
                <b>RETIRO DEL ACCESO AL SITIO Y/O A LOS CONTENIDOS</b>
                
                A-Lender se reserva el derecho a negar o retirar el acceso al Sitio, o sus Contenidos, en cualquier momento, sin responsabilidad alguna para A-Lender y sin previo aviso al Usuario o usuarios que incumplan de manera total o parcial con las condiciones aquí establecidas y/o que realicen acciones o actos tendientes a:
            </p>

            <ul>
                1.	“asediar" o de otra manera acosar o molestar a otros usuarios;
                
                2.	hacerse pasar como representante o empleado de A-Lender, realizando declaraciones falsas o de otro modo erróneas de su vinculación con A-Lender;
                
                3.	recopilar o almacenar datos personales de otros Usuarios en relación con la conducta y las actividades prohibidas;
                
                4.	falsificar encabezados o manipular identificadores del Sitio, con la finalidad de ocultar el origen de los Contenidos;
                
                5.	cargar, publicar, enviar por correo electrónico, transmitir o proporcionar de otro modo, cualquier contenido respecto del cual no tenga derecho a transmitir, en virtud de los términos contenidos en la Ley Federal de Protección a la Propiedad Industrial (“LFPPI”), la Ley Federal del Derecho de Autor (“LFDA”), y la Ley Federal de Protección de Datos Personales en Posesión de Particulares (“LFPDPPP”) o de relaciones contractuales protegidos por convenios de confidencialidad y no divulgación;
                
                6.	cargar, publicar, enviar por correo electrónico, transmitir o proporcionar de otro modo, materiales que contengan virus informáticos o cualquier otro código informático, archivos o programas diseñados para interrumpir, destruir o limitar la funcionalidad del software, hardware o de equipos de telecomunicaciones conectados al Sitio;
                
                7.	hacer uso del Sitio de una manera que pudiera dañar, deshabilitar, recargar o alterar los servidores de A-Lender o las conexiones de redes;
                
                8.	ignorar requisitos, procedimientos, políticas o normas de redes conectadas al Sitio que pudieran interferir con el uso y goce del Sitio por parte de cualquier tercero; y
                
                9.	acceder de manera no autorizada a cuentas, sistemas informáticos o redes conectadas a los servidores de A-Lender, a través de ataques propios de piratas informáticos, el descifrado de contraseñas o cualquier otro método para obtener o tratar de obtener materiales o información con cualquier medio que no se ofrece intencionalmente a través del Sitio.
                
            </ul>

            <p class="text">
                El Usuario acepta indemnizar y mantener en paz y a salvo a A-Lender y sus funcionarios, agentes, empleados, socios, proveedores y licenciantes frente a cualquier reclamo o demanda, así como a cubrir los honorarios razonables de abogados, que promueva cualquier tercero en contra de A-Lender a causa del contenido que el Usuario envíe, publique, transmita o proporcione de un modo distinto al previsto en el Sitio. Lo anterior, sin perjuicio del derecho de A-Lender de realizar las acciones judiciales necesarias para reclamar los daños y perjuicios que dichas acciones por parte del Usuario pudieran causarle.
            </p>
            
            <p class="text">
                <b>PROPIEDAD INDUSTRIAL Y DERECHO DE AUTOR</b>
                
                A-Lender autoriza al Usuario a utilizar el Sitio, exclusivamente bajo los términos aquí expresados, sin que ello implique que concede al Usuario licencia o autorización alguna, o algún tipo de derecho distinto al antes mencionado, respecto de la Propiedad Industrial y Derecho de Autor de A-Lender,  entendiéndose como ello: todas las marcas registradas y/o usadas en México o en el extranjero por A-Lender; todo derecho sobre invenciones (patentadas o no), diseños industriales, modelos de utilidad, información confidencial, nombres comerciales, secretos industriales, avisos comerciales, reservas de derechos, nombres de dominio; así como todo tipo de derechos patrimoniales sobre obras y creaciones protegidas por derechos de autor y demás formas de propiedad industrial o intelectual reconocida o que lleguen a reconocer las leyes correspondientes.
            </p>

            <p class="text">
                El Usuario reconoce y acepta que A-Lender es legítimo propietario, o tiene los derechos necesarios, sobre el Sitio, incluidos los nombres comerciales de A-Lender, marcas comerciales, marcas de servicio, logotipos, nombres de dominio y otras características distintivas de la marca contenidas en ellos (las “<u>Marcas Registradas de A-Lender</u>”), independientemente de que esos derechos estén registrados o no, y de cualquier lugar del mundo en el que puedan existir esos derechos, y que están protegidos por las leyes y tratados internacionales sobre protección a la propiedad industrial y derecho de autor. Por lo anterior, el Usuario acepta que las Marcas Registradas de A-Lender no podrán ser objeto de copia, reproducción, modificación, publicación, carga, envío, transmisión o distribución en modo alguno. Salvo indicación expresa en contrario en este documento, A-Lender no concede al Usuario ningún derecho expreso ni implícito en virtud de patentes, derecho de autor, marcas comerciales o información de secretos industriales. El Usuario reconoce y conviene que el Sitio, así como todos los diseños del mismo, son y, serán en todo momento, propiedad de A-Lender.
            </p>

            <p class="text">
                <u>Retroalimentación.</u> En caso de que el Usuario proporcione algún comentario a A-Lender respecto de la funcionalidad y el rendimiento del Sitio (incluida la identificación de posibles errores y mejoras), en este acto, el Usuario autoriza a A-Lender para que haga uso, sin restricción, de todos los derechos, títulos e intereses sobre los comentarios expresados. Lo anterior, sin que ello se considere como un derecho moral del Usuario para requerir participación o retribución monetaria alguna, o restricción en el uso de dichos comentarios para su explotación por parte de A-Lender.
            </p>
            
            <p class="text">
                <b>DATOS DE CARÁCTER PERSONAL</b>
                
                En cumplimiento a los términos previstos en la LFPDPPP, A-Lender, como responsable del tratamiento de sus datos personales, hace del conocimiento del Usuario que la información que el Usuario provea en este Sitio será tratada de conformidad con lo indicado en el Aviso de Privacidad contenido en el Sitio. Para utilizar o gozar de algunos de los Contenidos, es necesario que el Usuario proporcione previamente a A-Lender ciertos datos de carácter personal (“<u>Datos Personales</u>”).
            </p>

            <p class="text">
                Al acceder al Sitio, o a cualquiera de los Contenidos en que los Datos Personales son requeridos, el Usuario está autorizando a A-Lender a realizar análisis y estudios con base en ellos. El Usuario se obliga a proporcionar Datos Personales verdaderos y fidedignos. En caso de que el Usuario diera información falsa o confusa, A-Lender no asume responsabilidad alguna de los resultados que dichos actos ocasionen al Usuario, teniendo la facultad de negar el acceso al Sitio y sus Contenidos, sin perjuicio de que pueda requerir las indemnizaciones a que hubiere lugar.
            </p>
            
            <p class="text">
                <b>INDICADORES DE DATOS</b>
                
                La información que el Usuario provea en el Sitio, real o histórica, se procesa y ordena, para que genere indicadores de datos, mismos que A-Lender podrá usar para tomar decisiones pertinentes a su negocio, siempre de manera estadística y no individualizada. El Usuario, en este acto, autoriza el acceso a A-Lender a la información proporcionada y generada en el Sitio, en términos del presente documento y del Aviso de Privacidad.
            </p>
            
            <p class="text">
                <b>USO DE COOKIES</b>
                
                A-Lender informa al Usuario que, mediante el uso de cookies y tecnologías similares, busca: i) garantizar la mejor experiencia posible en el Sitio; y ii) proporcionar al Usuario información sobre sus preferencias de servicios y de mercadeo, ayudándolo así a obtener la información adecuada. En caso de que el Usuario requiera de mayor información respecto al uso de cookies y tecnologías similares, A-Lender pone a su disposición la Política de Uso de Cookies.
            </p>
            
            <p class="text">
                <b>COMPATIBILIDAD DE LOS DISPOSITIVOS ELECTRÓNICOS</b>
                
                El Usuario será responsable de obtener los dispositivos o hardware que sean compatibles con el Sitio, toda vez que A-Lender no garantiza que este funcione correctamente en cualquier dispositivo. De igual manera, el Usuario acepta no utilizar dispositivos, software o cualquier otro medio tendiente a interferir tanto en las actividades y/u operaciones del Sitio, como en las bases de datos y/o a la información que se contenga en el mismo.
            </p>
            
            <p class="text">
                <b>DURACIÓN Y TERMINACIÓN</b>
                
                El Sitio y los Contenidos tienen una duración indefinida. Sin embargo, A-Lender podrá dar por terminado o suspender temporalmente, en cualquier momento y sin necesidad de previo aviso, el Sitio y/o cualquiera de los Contenidos.
            </p>
            
            <p class="text">
                <b>OTRAS DISPOSICIONES</b>
                
                El Usuario acepta que una versión impresa del presente Aviso Legal, y de cualquier comunicación enviada y/o recibida en forma electrónica, será admisible como medio probatorio en cualquier procedimiento judicial y/o administrativo.
            </p>
            
            <p class="text">
                <b>DIVISIBILIDAD</b>
                
                En caso de que cualquier término, condición o estipulación contenida en el presente Aviso Legal se determine ineficaz, ilegal o sin efecto, el mismo podrá ser excluido del cuerpo del presente y el restante continuará en vigor y efecto en forma tan amplia como en derecho proceda.
            </p>
            
            <p class="text">
                <b>DERECHOS</b>
                
                Cualquier derecho que no se haya conferido expresamente en este documento, se entiende reservado a A-Lender.
            </p>
            
            <p class="text">
                <b>ACTUALIZACIONES</b>
                
                A-Lender podrá revisar y actualizar, en cualquier momento, el presente Aviso Legal, manteniendo en todo momento el acceso libre a todo Usuario que desee conocerlo. A-Lender se reserva el derecho de modificar, en cualquier momento, la presentación y configuración del Sitio, así como el presente Aviso Legal. Por ello, A-Lender recomienda al Usuario dar lectura atenta cada vez que acceda al Sitio. No obstante lo anterior, el Usuario siempre dispondrá este Aviso Legal en el Sitio, de forma visible y accesible en cualquier momento. Algunas cláusulas de este Aviso Legal pueden estar supeditadas a términos y condiciones designados expresamente y que se encuentren en el Sitio o en determinados sitios web.
            </p>
            
            <p class="text">
                <b>LEY APLICABLE Y JURISDICCIÓN</b>
                
                En todo lo relacionado con la interpretación y cumplimiento de lo aquí dispuesto, por el solo hecho de acceder al Sitio, el Usuario acepta someterse a la legislación federal de México y a la jurisdicción de los tribunales competentes en la Ciudad de Monterrey, Nuevo León, México; renunciando a cualquier otra jurisdicción que, por razón de sus domicilios presentes o futuros, pudiese corresponderles.
            </p>
            
            <p class="text">
                <b>ACEPTACIÓN DEL AVISO LEGAL</b>
                
                El Usuario reconoce que, mediante el ingreso y uso del Sitio, manifiesta su aceptación expresa y adhesión al presente Aviso Legal, en la versión publicada al momento en que se acceda al Sitio, en términos de lo establecido por los artículos 1803 y 1834 Bis del Código Civil Federal, 80, 81, 89 y demás relativos y aplicables del Código de Comercio y la legislación aplicable para la República Mexicana. Es responsabilidad única y exclusiva del Usuario, leer previamente este Aviso Legal, y sus modificaciones correspondientes, cada vez que accede al Sitio, por lo que si en cualquier momento, el Usuario no estuviera de acuerdo, total o parcialmente con el presente Aviso Legal, deberá abstenerse inmediatamente de acceder al Sitio y su Contenido. Por lo anterior, con la aceptación del presente Aviso Legal, el Usuario consiente expresamente sujetarse al mismo, por lo que manifiesta haber leído el contenido de todas y cada una de las disposiciones y ratifica su contenido.
            </p>

            <p class="text">
                Fecha de primera emisión: 1 de enero de 2022.
                
                Fecha de última modificación: 14 de octubre de 2022.    
            </p>
        </div>
    </div>
</div>