import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { GlobalLists } from 'src/app/global/global-data';
import { Tax } from 'src/app/models/amortize/amortize.model';

@Component({
	selector: 'app-dialog-tax-conditions',
	templateUrl: './dialog-tax-conditions.component.html',
	styleUrls: ['./dialog-tax-conditions.component.scss']
})

export class DialogTaxConditionsComponent implements OnInit {

	mode = "";
	taxForm: FormGroup;
	submitted = false;

	taxColumns = [
		'number',
		'description',
		'buttonRemove'
	];

	taxList = GlobalLists.taxList;

	filteredOptions: Observable<string[]>;

	tax = <Tax[]>[];
	taxPercent: boolean;

	dsTax = new MatTableDataSource<Tax>(<Tax[]>[]);

	constructor(private formBuilder: FormBuilder) {
		this.createTaxForm();
	}

	get fTax() { return this.taxForm.controls; }

	ngOnInit(): void {

		if (this.tax) {
			this.dsTax = new MatTableDataSource<Tax>(this.tax);
		}

		// this.filteredOptions = this.taxForm.controls.description.valueChanges
		//   .pipe(
		//     startWith(''),
		//     map(value => this._filter(value))
		//   );
		if (this.taxPercent) {
			this.fTax['description'].patchValue("16 %")
			this.addTax();
		}

	}

	createTaxForm() {
		this.taxForm = this.formBuilder.group({
			description: ['', Validators.required]
		});
	}

	onSubmitTax() {
		if (this.dsTax.data.length >= 1) {
			return;
		}

		this.submitted = true;

		if (this.taxForm.invalid) {
			return;
		}

		this.addTax();
	}

	onDeleteTax(index: number): void {
		this.tax.splice(index, 1);
		this.dsTax = new MatTableDataSource<Tax>(this.tax);
	}

	addTax(): void {
		var lastTaxNumber = 0;

		if (this.tax.length > 0) {
			lastTaxNumber = Math.max.apply(Math, this.tax.map(function (o: { number: any; }) { return o.number; }))
		}

		this.tax.push(<Tax>{
			number: lastTaxNumber + 1,
			description: this.fTax['description'].value
		});

		this.dsTax = new MatTableDataSource<Tax>(this.tax);
		this.fTax['description'].patchValue("");
	}

	validateRequiredFormTax(formControlName: string): boolean {
		if (this.fTax[formControlName] == null) {
			return;
		}

		if (this.fTax[formControlName].errors !== null && this.fTax[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	// private _filter(value: string): string[] {
	//   let filterValue = '';
	//   if (value) {
	//     filterValue = value.toLowerCase();
	//   }
	//   return this.typeOfTax.filter(option => option.toLowerCase().includes(filterValue));
	// }

	onClickTax() {
		return this.tax;
	}

}
