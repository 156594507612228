<mat-accordion>
    <mat-expansion-panel [expanded]="panelStatusState" class="bg-white" (opened)="panelStatusOpen(true)"
        (closed)="panelStatusOpen(false)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="d-flex">
                    <h5>Datos estado de resultados</h5>
                    <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelIncomeState" class="mb-4" (opened)="panelIncomeOpen(true)"
                (closed)="panelIncomeOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Relación de ingresos</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form *ngIf="!getIsDisabledSave()" [formGroup]="statusResultIncomeForm"
                    (ngSubmit)="onSubmitStatusResultIncome()">
                    <div class="row">
                        <div class="subtittle2">Agregar ingreso</div>

                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="startDate">Fecha inicio</label>
                                <input autofocus="autofocus" class="form-control" formControlName="startDate"
                                    type="date"
                                    [ngClass]="submitedIncome && validateRequiredFormIncome('startDate') ? 'is-invalid' : ''"
                                    (ngModelChange)="onStartDateChange()" />
                            </div>
                        </div>

                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="endDate">Fecha fin</label>
                                <input autofocus="autofocus" class="form-control" formControlName="endDate" type="date"
                                    [ngClass]="submitedIncome && validateRequiredFormIncome('endDate') ? 'is-invalid' : ''"
                                    (ngModelChange)="onEndDateChange()" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label>Tipo de ingreso </label>
                                <select #selectIncomeType class="form-control" formControlName="incomeType"
                                    [ngClass]="submitedIncome && validateRequiredFormIncome('incomeType') ? 'is-invalid' : ''">
                                    <option *ngFor="let item of incomeList" value="{{item.value}}">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                            <div>
                                <div class="form-group"> <label>Monto</label> <input class="form-control text-right"
                                        type="text" placeholder="$ 0" formControlName="amount" autocomplete="off"
                                        autocapitalize="off" [textMask]="{mask: currencyMask}"
                                        [ngClass]="submitedIncome && validateRequiredFormIncome('amount') ? 'is-invalid' : ''">
                                </div>
                            </div>
                            <button class="btn btn-outline-primary internal-btn">
                                Agregar
                            </button>
                        </div>
                    </div>
                </form>

                <div class="table-responsive">
                    <div class="react-bootstrap-table">
                        <table *ngIf="dsStatusResultIncomeData" mat-table [dataSource]="dsStatusResultIncomeData">
                            <ng-container matColumnDef="incomeType">
                                <th mat-header-cell *matHeaderCellDef> Tipo de ingreso </th>
                                <td mat-cell *matCellDef="let row"> {{row.incomeType}} </td>
                                <td mat-footer-cell *matFooterCellDef> Total </td>
                            </ng-container>

                            <ng-container matColumnDef="amount">
                                <th mat-header-cell *matHeaderCellDef> Monto </th>
                                <td mat-cell *matCellDef="let row"> <span>{{row.amount |
                                        currency}}</span> </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{getStatusResultIncomeTotal() | currency}} </td>
                            </ng-container>

                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef> Fecha inicio </th>
                                <td mat-cell *matCellDef="let row"> {{row.startDate |
                                    date:'mediumDate'}} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="endDate">
                                <th mat-header-cell *matHeaderCellDef> Fecha fin </th>
                                <td mat-cell *matCellDef="let row">{{row.endDate |
                                    date:'mediumDate'}} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="buttonRemove">
                                <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                                <td mat-cell *matCellDef="let row; let i = index;">
                                    <i class="bx bx-x" (click)="onDeleteStatusResultIncome(i)"></i>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="statusResultIncomeColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: statusResultIncomeColumns;">
                            </tr>
                            <tr mat-footer-row *matFooterRowDef="statusResultIncomeColumns"></tr>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelExpenseState" (opened)="panelExpenseOpen(true)"
                (closed)="panelExpenseOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Relación de gastos</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form *ngIf="!getIsDisabledSave()" [formGroup]="statusResultExpenseForm" (ngSubmit)="onSubmitStatusResultExpense()">
                    <div class="row">
                        <div class="subtittle2">Agregar gasto</div>

                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="startDate">Fecha inicio</label>
                                <input autofocus="autofocus" class="form-control" formControlName="startDate"
                                    type="date"
                                    [ngClass]="submitedExpense && validateRequiredFormExpense('startDate') ? 'is-invalid' : ''" />
                            </div>
                        </div>

                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="endDate">Fecha fin</label>
                                <input autofocus="autofocus" class="form-control" formControlName="endDate" type="date"
                                    [ngClass]="submitedExpense && validateRequiredFormExpense('endDate') ? 'is-invalid' : ''" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label>Tipo de gasto </label>
                                <select #selectExpenseType class="form-control" formControlName="expenseType"
                                    [ngClass]="submitedExpense && validateRequiredFormExpense('expenseType') ? 'is-invalid' : ''">
                                    <option *ngFor="let item of expenseList" value="{{item.value}}">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                            <div>
                                <div class="form-group"> <label>Monto</label> <input class="form-control text-right"
                                        type="text" placeholder="$ 0" formControlName="amount" autocomplete="off"
                                        autocapitalize="off" [textMask]="{mask: currencyMask}"
                                        [ngClass]="submitedExpense && validateRequiredFormExpense('amount') ? 'is-invalid' : ''">
                                </div>
                            </div>
                            <button class="btn btn-outline-primary internal-btn">
                                Agregar
                            </button>
                        </div>
                    </div>
                </form>

                <div class="table-responsive">
                    <div class="react-bootstrap-table">
                        <table *ngIf="dsStatusResultExpenseData" mat-table [dataSource]="dsStatusResultExpenseData">
                            <ng-container matColumnDef="expenseType">
                                <th mat-header-cell *matHeaderCellDef> Tipo de gasto </th>
                                <td mat-cell *matCellDef="let row"> {{row.expenseType}} </td>
                                <td mat-footer-cell *matFooterCellDef> Total </td>
                            </ng-container>

                            <ng-container matColumnDef="amount">
                                <th mat-header-cell *matHeaderCellDef> Monto </th>
                                <td mat-cell *matCellDef="let row"> <span>{{row.amount |
                                        currency}}</span> </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{getStatusResultExpenseTotal() | currency}} </td>
                            </ng-container>

                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef> Fecha inicio </th>
                                <td mat-cell *matCellDef="let row"> {{row.startDate |
                                    date:'mediumDate'}} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="endDate">
                                <th mat-header-cell *matHeaderCellDef> Fecha fin </th>
                                <td mat-cell *matCellDef="let row"> {{row.endDate |
                                    date:'mediumDate'}} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="buttonRemove">
                                <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                                <td mat-cell *matCellDef="let row; let i = index;">
                                    <i class="bx bx-x" (click)="onDeleteStatusResultExpense(i)"></i>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="statusResultExpenseColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: statusResultExpenseColumns;">
                            </tr>
                            <tr mat-footer-row *matFooterRowDef="statusResultExpenseColumns"></tr>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-expansion-panel>
</mat-accordion>
