import { formatDate, KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { TypeofcreditService } from 'src/app/services/typeofcredit/typeofcredit.service';
import { TypeofamortizationService } from 'src/app/services/typeofamortization/typeofamortization.service';
import { WarrantyService } from 'src/app/services/warranty/warranty.service';
import { AmortizationTableComponent } from '../amortization-table/amortization-table.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { evaluationElement, LoanRequestDetailDTO, LoanRequestDTO, LoanRequestEvaluationDTO } from 'src/app/models/loanrequest/loanrequest.dto.model';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { CurrencyPipe } from '@angular/common';
import { LoanRequestAnswer, LoanRequestAnswerDetail } from 'src/app/models/loanrequest/loanrequest.answer.model';
import { GlobalData, GlobalLists } from 'src/app/global/global-data';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { DialogComissionsConditionsComponent } from '../dialog-comissions-conditions/dialog-comissions-conditions.component';
import { DatePipe } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { RequiredDocumentDTO, RequiredFieldsDocumentDTO } from 'src/app/models/required-documents/requiredDocuments.dto.model';
import { DialogPreviewDocumentComponent } from '../dialog-preview-document/dialog-preview-document.component';
import { DialogCommentDocumentComponent } from '../dialog-comment-document/dialog-comment-document.component';
import { Uuidv4Service } from 'src/app/services/uuidv4/uuidv4.service';
import { DocumentServiceService } from 'src/app/services/document-service/document-service.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { HtmlViewerComponent } from 'src/app/views/html-viewer/html-viewer.component';
import { DialogWarrantysConditionsComponent } from '../dialog-warrantys-conditions/dialog-warrantys-conditions.component';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { DialogWarrantyPeopleDetailsComponent } from '../dialog-warranty-people-details/dialog-warranty-people-details.component';
import { DialogWarrantyObligorJointDetailsComponent } from '../dialog-warranty-obligorjoint-details/dialog-warranty-obligorjoint-details.component';
import { DialogWarrantyMortgageDetailsComponent } from '../dialog-warranty-mortgage-details/dialog-warranty-mortgage-details.component';
import { DialogWarrantyPledgeDetailsComponent } from '../dialog-warranty-pledge-details/dialog-warranty-pledge-details.component';
import { DialogWarrantyLiquidDetailsComponent } from '../dialog-warranty-liquid-details/dialog-warranty-liquid-details.component';
import { DialogWarrantyOtherDetailsComponent } from '../dialog-warranty-other-details/dialog-warranty-other-details.component';
import { DocumentsLegalGenerated } from 'src/app/models/loanrequest/loanrequestdocumentsgenerated.models';
import { CollectionLog, ExternalServicesDTO, LoanDisbursement } from 'src/app/models/loan/loan.dto.model';
import { DialogAmortizeTableComponent } from '../dialog-amortize-table/dialog-amortize-table.component';
import { AmortizeService } from 'src/app/services/amortize/amortize.service';
import { AdvanceOptions, Amortize, Commission, Payment } from 'src/app/models/amortize/amortize.model';
import { PaymentsJson } from 'src/app/models/loanrequest/loanrequestPaymentsJson.models';
import { ActivatedRoute, Router } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DialogHelpInfoComponent } from '../dialog-help-info/dialog-help-info.component';
import { DialogTaxConditionsComponent } from '../dialog-tax-conditions/dialog-tax-conditions.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DialogAdvanceOptionsComponent } from '../dialog-advance-options/dialog-advance-options.component';
import { DialogPersonDataComponent } from '../dialog-person-data/dialog-person-data.component';
import { LoanRequestOptionsForm, LoanRequestSave, LoanRequestUpdateBalanceSheet, LoanRequestUpdateBank, LoanRequestUpdateContact, LoanRequestUpdateEconomicFlow, LoanRequestUpdatePatrimonial, LoanRequestUpdateReference, LoanRequestUpdateStatusResult } from 'src/app/models/loanrequest/loanrequest.model';
import { DialogAddressDataComponent } from '../dialog-address-data/dialog-address-data.component';
import { DialogContactDataComponent } from '../dialog-contact-data/dialog-contact-data.component';
import { DialogEconomicActivityComponent } from '../dialog-economic-activity/dialog-economic-activity.component';
import { DialogPatrimonialDataComponent } from '../dialog-patrimonial-data/dialog-patrimonial-data.component';
import { DialogEconomicFlowComponent } from '../dialog-economic-flow/dialog-economic-flow.component';
import { DialogFamilyDataComponent } from '../dialog-family-data/dialog-family-data.component';
import { DialogReferenceDataComponent } from '../dialog-reference-data/dialog-reference-data.component';
import { DialogSocioeconomicComponent } from '../dialog-socioeconomic/dialog-socioeconomic.component';
import { DialogBankDataComponent } from '../dialog-bank-data/dialog-bank-data.component';
import { DialogBalanceSheetDataComponent } from '../dialog-balance-sheet-data/dialog-balance-sheet-data.component';
import { DialogStatusResultComponent } from '../dialog-status-result/dialog-status-result.component';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogPLDComponent } from '../dialog-pld-data/dialog-pld-data.component';
import { Customer } from 'src/app/models/customer/customer.model';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { UserService } from 'src/app/services/user/user.service';
import { LoanService } from 'src/app/services/loan/loan.service';
import { DialogShowConditionsExternalServices } from '../dialog-show-conditions-external-services/dialog-show-conditions-external-services.component';
import { ExternalServiceCollectionComponent } from '../external-services-collection/external-service-collection.component';

@Component({
	selector: 'app-loanrequest-resume',
	templateUrl: './loanrequest-resume.component.html',
	styleUrls: ['./loanrequest-resume.component.scss'],
	providers: [{
		provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
	}]
})

export class LoanrequestResumeComponent implements OnInit {
	@Input() loanRequestId: string = "";
	@ViewChild('amortizationTable') amortizationTable: AmortizationTableComponent;
	@ViewChild('personData') personData: DialogPersonDataComponent;
	@ViewChild('addressData') addressData: DialogAddressDataComponent;
	@ViewChild('contactData') contactData: DialogContactDataComponent;
	@ViewChild('economicActivityData') economicActivityData: DialogEconomicActivityComponent;
	@ViewChild('patrimonialData') patrimonialData: DialogPatrimonialDataComponent;
	@ViewChild('economicFlowData') economicFlowData: DialogEconomicFlowComponent;
	@ViewChild('familyData') familyData: DialogFamilyDataComponent;
	@ViewChild('referenceData') referenceData: DialogReferenceDataComponent;
	@ViewChild('socioeconomicData') socioeconomicData: DialogSocioeconomicComponent;
	@ViewChild('bankData') bankData: DialogBankDataComponent;
	@ViewChild('balanceSheetData') balanceSheetData: DialogBalanceSheetDataComponent;
	@ViewChild('statusResultData') statusResultData: DialogStatusResultComponent;
	@ViewChild('pldData') pldData: DialogPLDComponent;
	@ViewChild('stepperLoanRequest') stepperLoanRequest: MatStepper;
	@ViewChild('checkApplyMoralPerson') checkApplyMoralPerson: MatSlideToggle;
	@ViewChild('externalServiceCollection') externalServiceCollection: ExternalServiceCollectionComponent;
	@Inject(LOCALE_ID) private locale: string

	panelDetailsOpenState = false;
	modifyConditionsOpenState = false;
	advanceOptionsOpenState = false;
	loanrequestDetailForm: FormGroup;
	formrequestJsonForm: FormGroup = this.formBuilder.group({});
	formrequestDocumentsLegalForm: FormGroup = this.formBuilder.group({});
	formrequesFormDataform: FormGroup = this.formBuilder.group({});
	formAuthorizeRequest: FormGroup = this.formBuilder.group({});
	disbursementForm: FormGroup;
	newDocumentForm: FormGroup;
	loanRequestDetails: LoanRequestDetailDTO[] = [];
	loanrequestDetailDataSource: MatTableDataSource<LoanRequestDetailDTO>;
	loanrequestDataSource: MatTableDataSource<LoanRequestDTO>;
	evaluationDataSource: MatTableDataSource<evaluationElement>
	dataSourceExternalServices = new MatTableDataSource<ExternalServicesDTO>([]);
	isAddressee: boolean = false;
	isBorrower: boolean = false;
	addresseeCustomerId: string;
	submitted = false;
	buttonNextDescription: any;
	dataSource: any;
	loanRequestDTO: LoanRequestDTO;
	customPayments: any;
	amortize: Amortize;
	statusName: string;
	passRequestCompleted = true;
	loadFormRequestJsonForm = false;
	modifydata = false;
	pass = "1";
	countDsCollectionLog: boolean = false;

	loanrequestDetailColumns: string[] = [
		'description',
		'newConditionValue'/*,
		'addcomment'*/
	];

	loanrequestColumns: string[] = [
		'dateSent',
		'lenderCustomerFullName',
		'borrowerCustomerFullName',
		'typeOfMessageMediumDesc',
		'lenderEmail',
		'borrowerEmail',
		'statusName'
	];

	isDisabled = true;
	isDisabledRejection = false;
	isDisabledSaveSteep2 = false;
	isdisabled3 = false;
	isDisabledOtherSteep4 = false;
	isDisabledAproadSteep4 = true;
	isDisabledExplorerSteep4 = false;
	isDisabledSearchSteep4 = false;
	isDisabledAproadSteep5 = true;
	isDisabledExplorerSteep5 = false;
	isDisabledSearchSteep5 = false;
	isDisabledGiveLoan = true;
	requiredErrorMessage: string = "Este campo es requerido.";
	isDisabledDisbursement = true;

	columnsformRequest: string[] = [
		'conditionName',
		'value',
		'required'
	];

	columnsRequiredDocuments: string[] = [
		'description',
		'required',
		'option',
		'buttonAttach',
		'uploaded',
		'viewFile'/*,
		'addcomment'*/
	];

	columnsRequiredDocumentsLegal: string[] = [
		'description',
		'required',
		'generated',
		'download'/*,
		'addcomment'*/
	];

	columnsRequiredLoadDocumentsLegal: string[] = [
		'description',
		'required',
		'buttonAttach',
		'uploaded',
		'viewFile'
	];

	columnsFormWarrantys: string[] = [
		'number',
		'warranty',
		'warrantyFormCompleted',
		'warrantyForm',
	];

	evaluationColumn: string[] = [
		'question',
		'concept',
		'five',
		'four',
		'three',
		'two',
		'one'
	];

	advanceOptionsColumns = [
		'description',
		'number'
	];

	columnsExternalServices: string[] = [
		'serviceName',
		'dateCreated',
		'amount',
		'statusName',
		'id'
	];

	typeOfAdvanceOptions = GlobalLists.typeOfAdvanceOptions

	dataSourceformRequest = new MatTableDataSource<FormRequestDTO>([]);
	dataSourceformRequestLender = new MatTableDataSource<FormRequestDTO>([]);
	dataSourceRequiredDocuments = new MatTableDataSource<RequiredDocumentDTO>([]);
	dataSourceRequiredDocumentsLegal = new MatTableDataSource<RequiredDocumentDTO>([]);
	dsAdvanceOptions = new MatTableDataSource<AdvanceOptions>(GlobalLists.typeOfAdvanceOptions);

	selectionAdvanceOptions = new SelectionModel<AdvanceOptions>(true, []);

	dataSourceformWarrantys = new MatTableDataSource<WarrantysForm>(<WarrantysForm[]>[]);
	elementsFormWarrantys: WarrantysForm[] = [];
	documentsLegalGenerated: DocumentsLegalGenerated[] = [];

	isLinear = false;

	steptIndex: number = 0;
	steptIndexPass2: number = 0;
	elementsFormRequest: any;

	genders: any;
	countries: any;
	states: any;
	cities: any;
	statesBirth: any;
	nationalities: any;

	approadCountDocumentsRequired;
	approadCountDocumentsUpload;
	newDocumentDescription: string = "";

	selectedFiles: FileList;
	currentFile: File;
	progress: number[] = [];
	message = '';

	dataBorrower: any;
	borrowerCustomerId: any;
	lenderCustomerId: any;
	mode = "loanrequest"

	payments: Payment[] = [];

	modelStartDate = new Date();

	FormrequestJson: FormRequestDTO[];

	frv = false;
	gv = false;
	dv = false;
	dlv = false;
	dr = false;

	submitedSaveDisbursement = false;

	panelIntegrateFileOpenState: any;
	panelWarrantyOpenState: any;
	panelDocumentsOpenState: any;
	panelAuthorizeRequest = true;
	panelServiceRequest = true;
	panelDocumentsLegalOpenState: any;
	panelDocumentsLegalLoadOpenState: any;
	panelDeliveryMethodOpenState = true;
	panelCompanyDataOpenState: any;
	panelTypePersonOpenState: any;
	panelAddressOpenState: any;
	panelContactDataOpenState: any;
	panelEvaluationRequest: any;
	panelExternalServices = false;
	panelValidationExternalServices = false;
	panelVerificationExternalServices = false;
	panelAnalysisExternalServices = false;
	panelRecordDeliveryOpenState = true;

	typeRelationshipList = GlobalLists.typeRelationshipList;
	creditDestinationList = GlobalLists.creditDestinations;

	evaluationGroup: string;
	evaluationData: evaluationElement[] = GlobalLists.evaluationData;

	personTypeList = GlobalLists.personTypeList;
	occupationList = GlobalLists.occupationList;
	documentIneList = GlobalLists.documentIneList;
	documentAddressList = GlobalLists.documentAddressList;
	documentIncomeList = GlobalLists.documentIncomeList;
	documentWarrantyList = GlobalLists.documentWarrantyList;
	documentAvalList = GlobalLists.documentAvalList;
	documentLoanRequestList = GlobalLists.documentLoanRequestList;
	documentOtherList = GlobalLists.documentOtherList;

	currencyMask = GlobalLists.currencyMask;
	percentMask = GlobalLists.percentMask;
	numberMask = GlobalLists.numberMask;

	periodAmortizeStartList: KeyValue<number, number>[] = [
		{ key: 1, value: 1 },
	];

	giveLoanStartDate = false;

	personDataActive = false;
	economicActivityActive = false;
	patrimonialActive = false;
	economicFlowActive = false;
	familyActive = false;
	referenceActive = false;
	socioeconomicActive = false;
	bankActive = false;
	balanceSheetActive = false;
	statusResultActive = false;
	pldActive = false;

	addressDataJsonCompleted = false;
	contactDataJsonCompleted = false;
	economicActivityDataJsonCompleted = false;
	patrimonialDataJsonCompleted = false;
	economicFlowDataJsonCompleted = false;
	familyDataJsonCompleted = false;
	referenceDataJsonCompleted = false;
	socioeconomicDataJsonCompleted = false;
	bankDataJsonCompleted = false;
	balanceSheetDataJsonCompleted = false;
	statusResultDataJsonCompleted = false;
	pldDataJsonCompleted = false;

	dataRequiredCompleted = true;

	clientid = '63d7dc469b41c6001c63b011';
	flowid = '63d9f6cf79a298001d1978d6';
	dataProceedings: any;
	dataViewCallVerification: any;
	dataViewVisitVerification: any;
	serviceName: string;
	showExternalServices = false;


	constructor(
		private loanrequestService: LoanrequestService,
		private typeofperiodService: TypeofperiodService,
		private typeofcreditService: TypeofcreditService,
		private typeofamortizationService: TypeofamortizationService,
		private WarrantyService: WarrantyService,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private authService: AuthenticationService,
		private currencyPipe: CurrencyPipe,
		private alertGlobalService: AlertGlobalService,
		private datePipe: DatePipe,
		private spinnerService: SpinnerService,
		private uuidv4Service: Uuidv4Service,
		private documentServiceService: DocumentServiceService,
		public amortizeService: AmortizeService,
		private router: Router,
		private customerService: CustomerService,
		private userService: UserService,
		private loanService: LoanService,
		private changeDetector: ChangeDetectorRef,
		private route: ActivatedRoute) {
		this.spinnerService.requestStarted();

		this.loanRequestId = this.route.snapshot.paramMap.get('loanRequestId');

		this.createLoanrequestDetailForm();
	}

	totalAmortize: any;
	periodicPayment: any;
	totalInterest = 0;
	totalInterestTax = 0;
	totalCommission = 0;
	isCommission: any
	totalCommissionTax = 0;
	totalPeriodPay = 0;
	firstDatePayment: Date;
	lastDatePayment: Date;
	typeOfPeriodDesc = "";
	typeOfAmortization = "";
	startDate: Date;
	startPayment: Date;
	totalPeriods: number;
	idRequest: any;
	borrowerName: any;
	borrowerProfilePicture: string;
	borrowerMail: any;
	borrowerPhoneMobile: any;
	lenderEmail: any;
	lenderName: any;
	lenderPhoneMobile: any;
	lenderCustomerFullName: any;
	typeOfCredit: any;
	warrantyDataJson: any;
	monthlyRate: any;
	monthlyPorcentOnArrears: any;
	taxPorcent: any;
	periodAmortizeStart: any;
	isLender = this.authService.currentUserModeLenderValue
	initials: any;
	phoneNumberVerified = false;
	verificationMessage: any;
	steepLegalConditions: any;
	loanRequestStatus: number;
	warrantys: string = "";
	wayToPayPlaceHolder: string = "Detalle 1";
	detailPlaceHolder: string = "Detalle 2";
	customPaymentsAmortization: string;
	personType: number;
	occupation: number[];
	ocupationSelected: number[] = [];
	occupationTittle = ""

	typeOfPeriodList: KeyValue<number, string>[] = [];
	warrantyList: KeyValue<number, string>[] = [];
	typeOfCreditList: KeyValue<number, string>[] = [];
	legalConditionsList = GlobalLists.legalConditionsList;
	typeOfAmortizationList: KeyValue<number, string>[] = [];
	documentsNew = GlobalLists.documentsNew;
	dsCollectionLog = new MatTableDataSource<CollectionLog>([]);
	rowCollectionLogSelected: CollectionLog;

	wayToPayList = GlobalLists.wayToPayList;

	contSuscribeValueChanges = 0;
	contLoanRequestDetails = 0
	contSuscribeValueChangesCompleted = false;

	formRequestJsonCompleted: boolean;
	documentsRequiredConfigJsonCompleted: boolean;
	documentsLegalRequiredConfigJsonCompleted: boolean;
	disbursementMethod: string;
	legalConditionsValue: number;

	generateTableStatus: boolean = false;

	autorizationGroup: string;
	contactRelationShip: string;
	giveLoanGroup: number;
	disbursementDate: Date;
	filteredExternalServices: ExternalServicesDTO[];
	externalServiceSelected: ExternalServicesDTO;

	get f1() { return this.loanrequestDetailForm.controls; }
	get f4() { return this.disbursementForm.controls; }
	get f_formrequestDocumentsLegalForm() { return this.formrequestDocumentsLegalForm.controls; }
	get f_formrequesFormDataform() { return this.formrequesFormDataform.controls; }
	get f_formAuthorizeRequest() { return this.formAuthorizeRequest.controls; }
	get f_newDocumentForm() { return this.newDocumentForm.controls; }

	ngOnInit(): void {
		this.steptIndex = 0;

		this.getTypeOfPeriodList();
		this.getTypeofamortizationList();
		this.getWarrantyList();
		this.getTypeOfCreditList();
		this.createDisbursementForm();
		this.createNewDocumentForm();
		this.getLoanRequest(true);
		this.createFormrequesFormDataform();
		this.createFormAuthorizeRequest();
		this.createFormrequestDocumentsLegalForm();
		this.suscribeValueChanges();
		this.getCollectionVerification();
		//this.getExternalServices();
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewInit(): void {
		this.getResume();
	}

	createNewDocumentForm() {
		this.newDocumentForm = this.formBuilder.group({
			document: ['', Validators.required],
			other: [{ value: '', disabled: true }]
		});
	}


	getTypeOfPeriodList() {
		this.typeofperiodService.getList()
			.subscribe((list) => {
				this.typeOfPeriodList = list;
			});
	}

	getTypeofamortizationList() {
		this.typeofamortizationService.getList()
			.subscribe((list) => {
				this.typeOfAmortizationList = list;
			});
	}

	getWarrantyList() {
		this.WarrantyService.getList()
			.subscribe((list) => {
				this.warrantyList = list;
			});
	}

	getTypeOfCreditList() {
		this.typeofcreditService.getList()
			.subscribe((list) => {
				this.typeOfCreditList = list;
			});
	}

	getLoanRequest(generateTable: boolean) {
		this.steptIndex = 0;
		this.generateTableStatus = generateTable;

		this.loanrequestService.getLoanRequest(this.loanRequestId).subscribe((result) => {
			if (result !== null) {

				var data = JSON.parse(result.amortizationTableJson);
				var firstAmount: any = "";
				var breakAmount: Boolean = false;
				data.table.forEach((item: any) => {
					if (item.period != 0) {
						if (breakAmount == false) {
							if (firstAmount == "") {
								firstAmount = item.periodPay;
							} else {
								if (firstAmount != item.periodPay) {
									firstAmount = "Variable";
									breakAmount = true;
								}
							}
						}

					}
				});

				this.periodicPayment = firstAmount;

				var typeOfPeriod = parseInt(result.loanRequestDetails.find(x => x.conditionName === 'TypeOfPeriod').newConditionValue);
				var typeOfAmortization = parseInt(result.loanRequestDetails.find(x => x.conditionName === 'TypeOfAmortization').newConditionValue);
				this.totalPeriods = parseInt(result.loanRequestDetails.find(x => x.conditionName == 'TotalPeriods').newConditionValue);
				this.totalAmortize = data.totalAmortize;
				this.totalInterest = data.totalInterest + data.totalInterestTax;
				this.totalCommission = data.totalCommission + data.totalCommissionTax;
				this.totalPeriodPay = data.totalPeriodPay;

				if (this.totalCommission) {
					this.isCommission = "Si";
				} else {
					this.isCommission = "No";
				}

				if (data.table && generateTable) {
					var startDateStr = result.loanRequestDetails.find(x => x.conditionName === 'StartDate').newConditionValue;
					var startDateArray = startDateStr.split("/");
					this.startDate = new Date(startDateArray[2] + "/" + startDateArray[1] + "/" + startDateArray[0]);
					

					var starPaymentStr = result.loanRequestDetails.find(x => x.conditionName === 'StartPayment').newConditionValue;
					var startPaymentArray = starPaymentStr.split("/");
					this.startPayment = new Date(startPaymentArray[2] + "/" + startPaymentArray[1] + "/" + startPaymentArray[0]);


					var lastDatePaymentStr = data.table.find(x => x.period === this.totalPeriods).datePayment;
					var dateArray = lastDatePaymentStr.split("/");
					this.lastDatePayment = new Date(dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0]);

					this.typeOfPeriodDesc = this.typeOfPeriodList.find(x => x.key === typeOfPeriod).value;
					this.typeOfAmortization = this.typeOfAmortizationList.find(x => x.key === typeOfAmortization).value;
					this.amortizationTable.data = data;
					this.amortizationTable.ngOnInit();
				}

				this.idRequest = this.loanRequestId;
				this.borrowerName = result.borrowerCustomerFullName;
				this.borrowerProfilePicture = result.borrowerprofilePicture;

				if (result.formRequestEmail == "" || result.formRequestEmail == null) {
					this.borrowerMail = result.contact;
				} else {
					this.borrowerMail = result.formRequestEmail;
				}

				this.lenderEmail = result.lenderEmail;
				this.lenderName = result.lenderCustomerFullName;
				this.lenderPhoneMobile = result.lenderPhoneMobile;
				this.lenderCustomerFullName = result.lenderCustomerFullName;
				this.typeOfCredit = this.typeOfCreditList.find(x => x.key === result.typeOfCredit).value;

				this.warrantyDataJson = result.warrantyDataJson
				this.monthlyRate = result.monthlyRate;
				this.monthlyPorcentOnArrears = result.monthlyPorcentOnArrears;
				this.taxPorcent = result.taxPorcent;
				this.periodAmortizeStart = result.periodAmortizeStart;

				this.isAddressee = this.authService.currentUserValue.customerId === result.addresseeCustomerId;
				this.isBorrower = this.authService.currentUserValue.customerId === result.borrowerCustomerId;

				if (this.isBorrower) {
					this.addresseeCustomerId = result.lenderCustomerId
				} else {
					this.addresseeCustomerId = result.borrowerCustomerId
				}

				this.loanRequestDetails = result.loanRequestDetails;
				this.loanrequestDetailDataSource = new MatTableDataSource(result.loanRequestDetails);
				this.loanrequestDataSource = new MatTableDataSource(<LoanRequestDTO[]>[result]);
				this.f1['completed'].patchValue(result.completed);
				this.borrowerCustomerId = result.borrowerCustomerId;
				this.lenderCustomerId = result.lenderCustomerId;
				this.phoneNumberVerified = result.phoneNumberVerified;
				this.steepLegalConditions = result.legalConditions
				this.loanRequestDTO = result;
				this.statusName = result.statusName;
				this.contactRelationShip = result.contactRelationShip;
				this.giveLoanStartDate = result.giveLoan;

				this.contLoanRequestDetails = this.loanRequestDetails.length;

				if (this.phoneNumberVerified) {
					this.verificationMessage = "Número verificado"
				} else {
					this.verificationMessage = "Número sin verificar"
				}

				if (result.customPayments) {
					this.customPayments = "Si"
				} else {
					this.customPayments = "No"
				}

				var m = false;
				this.warrantys = "";

				this.loanRequestDetails.forEach((item) => {
					if (item.conditionName === "LoanAmount") {
						// this.f1[item.conditionName].patchValue(this.currencyPipe.transform(parseFloat(item.newConditionValue)).replace("$", ""));
						this.f1[item.conditionName].patchValue(item.newConditionValue);
					} else if (item.conditionName === "TaxPorcent") {
						this.f1[item.conditionName].patchValue(Number(item.newConditionValue) > 0 ? true : false);
					} else if (item.conditionName == "Occupation") {
						this.f1[item.conditionName].patchValue(JSON.parse(item.newConditionValue));
					} else {
						this.f1[item.conditionName].patchValue(item.newConditionValue);
					}

					if (item.conditionName === "AnnualRate") {
						this.loanRequestDetails.find(x => x.oldConditionValue === item.oldConditionValue).oldConditionValue = (parseFloat(item.oldConditionValue) / 12).toString();
						this.f1[item.conditionName].patchValue(parseFloat(item.newConditionValue) / 12);
					}

					if (!this.isAddressee) {

					} else {
						if (this.isDisabled) {
							this.isDisabled = false;
						}
					}

					if (item.conditionName == "WarrantyDataJson") {
						JSON.parse(item.newConditionValue).forEach((item) => {
							this.warrantys += item['description'] + ", ";
						});

						this.warrantys = this.warrantys.substring(0, this.warrantys.length - 2);

						if (this.warrantys == "") {
							this.warrantys = "Sin garantía(s)";
						}
					}

					if (item.conditionName == "CustomPayments") {
						this.customPaymentsAmortization = item.newConditionValue
					}

					if (item.conditionName == "LegalConditions") {
						this.legalConditionsValue = Number.parseInt(item.newConditionValue);
					}

					if (this.modifyConditionsOpenState) {
						if (item.newConditionValue != item.oldConditionValue) {
							m = true
						} else {
							if (!m) {
								m = false;
							}
						}
					}
				});

				this.onChangeTotalPeriods(this.f1['TotalPeriods'].value);

				this.modifyConditionsOpenState = m;
				this.loanRequestStatus = result.status

				if (this.isLender) {
					this.isDisabled = false;
					this.buttonNextDescription = "Guardar";
					this.isDisabledGiveLoan = false;
					this.isDisabledAproadSteep5 = false;
					this.isDisabledExplorerSteep5 = false;
					this.isDisabledSearchSteep5 = false;
				} else {
					this.isDisabled = true;
					this.buttonNextDescription = "Guardar";
					this.isDisabledGiveLoan = true;
					this.isDisabledAproadSteep5 = true;
					this.isDisabledExplorerSteep5 = true;
					this.isDisabledSearchSteep5 = true;
				}

				if (generateTable) {
					this.generateTableAmortize(this.generateTableStatus);
				}

				if (result.completed) {
					this.createEvaluatonForm(result.evaluationJson);
					this.loadFormRequestJsonForm = true;
				}

				if (result.formRequestPhoneMobile == "" || result.formRequestPhoneMobile == null) {
					this.borrowerPhoneMobile = this.loanRequestDTO.phoneMobileQs;
				} else {
					this.borrowerPhoneMobile = result.formRequestPhoneMobile;
				}

				var peopleExists = false;
				var obligorJointExists = false;
				var mortgageExists = false;
				var pledgeExists = false;
				var liquidExists = false;
				var otherExists = false;
				this.elementsFormWarrantys = [];

				if (result.loanRequestDetails.find(x => x.conditionName === 'PersonType') != null) {
					this.personType = parseInt(result.loanRequestDetails.find(x => x.conditionName === 'PersonType').newConditionValue);
					this.occupation = eval(result.loanRequestDetails.find(x => x.conditionName === 'Occupation').newConditionValue);

					this.onchangeDataExpedient(false, this.personType + "", this.occupation);
				}

				this.contactDataJsonCompleted = result.contactDataJsonCompleted;
				this.addressDataJsonCompleted = result.addressDataJsonCompleted;

				if (result.advanceOptions != null) {
					/*this.personDataActive = true;
					this.economicActivityActive = false;
					this.patrimonialActive = false;
					this.economicFlowActive = false;
					this.familyActive = false;
					this.referenceActive = false;
					this.socioeconomicActive = false;
					this.bankActive = false;
					this.balanceSheetActive = false;
					this.statusResultActive = false;
					this.pldActive = false;*/

					this.economicActivityDataJsonCompleted = result.economicActivityDataJsonCompleted;
					this.patrimonialDataJsonCompleted = result.patrimonialDataJsonCompleted;
					this.economicFlowDataJsonCompleted = result.economicFlowDataJsonCompleted;
					this.familyDataJsonCompleted = result.familyDataJsonCompleted;
					this.referenceDataJsonCompleted = result.referenceDataJsonCompleted;
					this.socioeconomicDataJsonCompleted = result.socioeconomicDataJsonCompleted;
					this.bankDataJsonCompleted = result.bankDataJsonCompleted;
					this.balanceSheetDataJsonCompleted = result.balanceSheetDataJsonCompleted;
					this.statusResultDataJsonCompleted = result.statusResultDataJsonCompleted;
					this.pldDataJsonCompleted = result.pldDataJsonCompleted;

					var options = JSON.parse(result.advanceOptions) as AdvanceOptions[]
					this.dataProceedings = options;

					for (let option of options.filter(x => x.number >= 5)) {
						if (option.description == "Datos actividad económica") {
							this.economicActivityActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 5))
						} else if (option.description == "Datos patrimoniales") {
							this.patrimonialActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 6))
						} else if (option.description == "Datos económicos") {
							this.economicFlowActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 7))
						} else if (option.description == "Datos familiares") {
							this.familyActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 8))
						} else if (option.description == "Datos referencias") {
							this.referenceActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 9))
						} else if (option.description == "Datos socioeconómicos") {
							this.socioeconomicActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 10))
						} else if (option.description == "Datos bancarios") {
							this.bankActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 11))
						} else if (option.description == "Datos balance general") {
							this.balanceSheetActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 12))
						} else if (option.description == "Datos estado de resultados") {
							this.statusResultActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 13))
						} else if (option.description == "Datos persona políticamente expuesta (PPE)") {
							this.pldActive = true;
							this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 14))
						}
					}

					this.updateDataRequired(options)
				}

				if (result.peopleGuaranteeDataJson != null) {
					peopleExists = true;

					var peopleResult = JSON.parse(result.peopleGuaranteeDataJson) as WarrantysForm[];

					for (let v of peopleResult) {
						this.elementsFormWarrantys.push({
							number: v.number,
							warranty: v.warranty,
							warrantyForm: v.warrantyForm,
							warrantyFormCompleted: v.warrantyFormCompleted
						})
					}
				}

				if (result.obligorJointGuaranteeDataJson != null) {
					var obligorJointResult = JSON.parse(result.obligorJointGuaranteeDataJson) as WarrantysForm[];
					obligorJointExists = true;

					for (let v of obligorJointResult) {
						this.elementsFormWarrantys.push({
							number: v.number,
							warranty: v.warranty,
							warrantyForm: v.warrantyForm,
							warrantyFormCompleted: v.warrantyFormCompleted
						})
					}
				}

				if (result.mortgageGuaranteeDataJson != null) {
					var mortgageResult = JSON.parse(result.mortgageGuaranteeDataJson) as WarrantysForm[];
					mortgageExists = true;

					for (let v of mortgageResult) {
						this.elementsFormWarrantys.push({
							number: v.number,
							warranty: v.warranty,
							warrantyForm: v.warrantyForm,
							warrantyFormCompleted: v.warrantyFormCompleted
						})
					}
				}

				if (result.pledgeGuaranteeDataJson != null) {
					var pledgeResult = JSON.parse(result.pledgeGuaranteeDataJson) as WarrantysForm[];
					pledgeExists = true;

					for (let v of pledgeResult) {
						this.elementsFormWarrantys.push({
							number: v.number,
							warranty: v.warranty,
							warrantyForm: v.warrantyForm,
							warrantyFormCompleted: v.warrantyFormCompleted
						})
					}
				}

				if (result.liquidGuaranteeDataJson != null) {
					var liquidResult = JSON.parse(result.liquidGuaranteeDataJson) as WarrantysForm[];
					liquidExists = true;

					for (let v of liquidResult) {
						this.elementsFormWarrantys.push({
							number: v.number,
							warranty: v.warranty,
							warrantyForm: v.warrantyForm,
							warrantyFormCompleted: v.warrantyFormCompleted
						})
					}
				}

				if (result.otherGuaranteeDataJson != null) {
					var otherResult = JSON.parse(result.otherGuaranteeDataJson) as WarrantysForm[];
					otherExists = true;

					for (let v of otherResult) {
						this.elementsFormWarrantys.push({
							number: v.number,
							warranty: v.warranty,
							warrantyForm: v.warrantyForm,
							warrantyFormCompleted: v.warrantyFormCompleted
						})
					}
				}

				if (result.disbursementDate.toString() != "01/01/0001 00:00:00") {
					this.disbursementDate = result.disbursementDate;
					this.f4['date'].patchValue(this.datePipe.transform(result.disbursementDate, 'yyyy-MM-dd'))
				}

				this.f4['methodOfPaymentId'].patchValue(result.disbursementMethod)
				this.onChangeMethodOfPayment(result.disbursementMethod);
				this.f4['methodOfPaymentOther'].patchValue(result.disbursementMethodOther)
				this.f4['methodOfPaymentDetail'].patchValue(result.disbursementMethodDetail)

				this.dataSourceformWarrantys.data = this.elementsFormWarrantys;

				if (result.authorizationLoanRequest) {
					this.autorizationGroup = "Autorizado";
				} else if (result.status == 3) {
					this.autorizationGroup = "Rechazado";
				}

				this.f_formAuthorizeRequest['authorization'].patchValue(result.authorizationLoanRequest);

				if (!result.completed) {
					this.stepperLoanRequest.selectedIndex = 0;
					this.isDisabledSaveSteep2 = true;
					this.isDisabledOtherSteep4 = true;
					this.f_newDocumentForm['document'].disable();
					this.isDisabledGiveLoan = true;
					this.isDisabledAproadSteep4 = true
					this.isDisabledAproadSteep5= true
					// this.isLinear = true;
				} else {
					this.buttonNextDescription = "Guardar";
					// this.isLinear = false;
					// this.stepperLoanRequest.linear = true;
					this.f1['completed'].patchValue(true);
					this.isDisabledAproadSteep4 = true;
					this.passRequestCompleted = true
					this.isDisabledSearchSteep5 = true;
					this.isDisabledGiveLoan = true;
					this.isDisabledExplorerSteep5 = true;

					if (peopleExists && !result.peopleGuaranteeDataJsonCompleted) {
						this.passRequestCompleted = false
					}

					if (obligorJointExists && !result.obligorJointGuaranteeDataJsonCompleted) {
						this.passRequestCompleted = false
					}

					if (mortgageExists && !result.mortgageGuaranteeDataJsonCompleted) {
						this.passRequestCompleted = false
					}

					if (pledgeExists && !result.pledgeGuaranteeDataJsonCompleted) {
						this.passRequestCompleted = false
					}

					if (liquidExists && !result.liquidGuaranteeDataJsonCompleted) {
						this.passRequestCompleted = false
					}

					if (otherExists && !result.otherGuaranteeDataJsonCompleted) {
						this.passRequestCompleted = false
					}

					this.dataSourceRequiredDocuments.data = JSON.parse(result.documentsRequiredConfigJson) as RequiredDocumentDTO[];
					this.dataSourceRequiredDocumentsLegal.data = JSON.parse(result.documentsLegalRequiredConfigJson) as RequiredDocumentDTO[];

					this.formRequestJsonCompleted = result.formRequestJsonCompleted;
					this.documentsRequiredConfigJsonCompleted = result.documentsRequiredConfigJsonCompleted;
					this.disbursementMethod = result.disbursementMethod;

					if (this.formRequestJsonCompleted) {
						this.frv = true;
					}

					if (this.passRequestCompleted) {
						this.gv = true;
					}

					if (this.documentsRequiredConfigJsonCompleted) {
						this.dv = true;
					}

					if (this.documentsLegalRequiredConfigJsonCompleted) {
						this.dlv = true
					}

					if (this.economicActivityActive && !this.economicActivityDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (this.patrimonialActive && !this.patrimonialDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (this.economicFlowActive && !this.economicFlowDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (this.familyActive && !this.familyDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (this.referenceActive && !this.referenceDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (this.socioeconomicActive && !this.socioeconomicDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (this.bankActive && !this.bankDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (this.balanceSheetActive && !this.balanceSheetDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (this.statusResultActive && !this.statusResultDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (this.pldActive && !this.pldDataJsonCompleted) {
						this.formRequestJsonCompleted = false;
					}

					if (!result.documentsRequiredConfigJsonCompleted || !result.formRequestJsonCompleted || !this.passRequestCompleted || !this.dataRequiredCompleted) {
						this.isDisabled = false;
						this.isDisabledSaveSteep2 = false;
						this.stepperLoanRequest.selectedIndex = 1;
						this.isDisabledAproadSteep4 = true
						this.isDisabledAproadSteep5 = true
						this.isDisabledOtherSteep4 = false
						this.f_newDocumentForm['document'].enable();
						this.pass = "2";
					} else if (result.documentsRequiredConfigJsonCompleted && result.formRequestJsonCompleted && this.passRequestCompleted && result.authorizationLoanRequest) {
						this.f_formrequesFormDataform['completed'].patchValue(true)
						this.stepperLoanRequest.selectedIndex = 1;
						this.stepperLoanRequest.selectedIndex = 2;
						if (result.evaluationJson != null) {
							this.stepperLoanRequest.selectedIndex = 4;

							this.giveLoanGroup = 1;

						}
						this.isDisabledAproadSteep4 = false;
						this.isDisabledExplorerSteep5= false;
						this.isDisabledSearchSteep5 = false;
						this.isDisabledGiveLoan = false;
						this.isDisabledSaveSteep2 = false;
						this.pass = "2";
					} else {
						this.f1['completed'].patchValue(true);
						this.f_formrequesFormDataform['completed'].patchValue(true)
						this.isDisabledDisbursement = true;
						this.stepperLoanRequest.selectedIndex = 1;
						this.stepperLoanRequest.selectedIndex = 2;
						this.isDisabledAproadSteep4 = false;
						this.isDisabledSearchSteep5 = false;
						this.isDisabledSaveSteep2 = false;
						this.pass = "2";
					}

					this.documentsLegalRequiredConfigJsonCompleted = result.documentsLegalRequiredConfigJsonCompleted;

					if (result.documentsRequiredConfigJsonCompleted) {
						this.f_formrequestDocumentsLegalForm['completed'].patchValue(true)
					} else {
						this.f_formrequestDocumentsLegalForm['completed'].patchValue(false)
					}
					// this.f4['date'].patchValue(this.f1['StartDate'].value);

					var stringStartDate = new String(this.f1['StartDate'].value).split("-");
					var startDate = new Date(Number.parseInt(stringStartDate[0]), Number.parseInt(stringStartDate[1]) - 1, Number.parseInt(stringStartDate[2]))

					this.f4['date'].patchValue(this.datePipe.transform(startDate, 'yyyy-MM-dd'));
					this.f4['date'].disable();

					if (result.status == 9) {
						this.buttonNextDescription = "Guardar y continuar";
						this.isDisabled = true;
						this.isdisabled3 = true
						this.isDisabledRejection = true;
						this.isDisabledSaveSteep2 = true;
						this.isDisabledAproadSteep4 = true;
						this.isDisabledExplorerSteep4 = true;
						this.isDisabledOtherSteep4 = true;
						this.isDisabledAproadSteep5 = true;
						this.isDisabledExplorerSteep5 = true;
						this.isDisabledSearchSteep5 = true;
						this.isDisabledGiveLoan = true;
						this.stepperLoanRequest.selectedIndex = 0;
					}
				}
			} else {
				this.router.navigate(['/404']);
			}

			this.spinnerService.requestEnded()
		});
	}

	skipServices(){
		this.stepperLoanRequest.selectedIndex = 3;
	}

	createFormrequesFormDataform() {
		this.formrequesFormDataform = this.formBuilder.group({
			completed: [false, Validators.requiredTrue]
		});
	}

	createFormAuthorizeRequest() {
		this.formAuthorizeRequest = this.formBuilder.group({
			authorization: [false, Validators.requiredTrue]
		});
	}

	createDisbursementForm() {
		this.disbursementForm = this.formBuilder.group({
			date: ['', Validators.required],
			methodOfPaymentId: ['', Validators.required],
			methodOfPaymentOther: [{ value: "", disabled: true }, Validators.required],
			methodOfPaymentDetail: [{ value: "", disabled: true }, Validators.required],
		});
	}

	createFormrequestDocumentsLegalForm() {
		this.formrequestDocumentsLegalForm = this.formBuilder.group({
			completed: [false, Validators.requiredTrue]
		});
	}

	getResume() {
		this.loanrequestService.getLoanRequest(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				var data = JSON.parse(result.amortizationTableJson);
				var typeOfPeriod = parseInt(result.loanRequestDetails.find(x => x.conditionName === 'TypeOfPeriod').newConditionValue);
				this.totalPeriods = parseInt(result.loanRequestDetails.find(x => x.conditionName == 'TotalPeriods').newConditionValue);

				this.totalAmortize = data.totalAmortize;
				this.totalInterest = data.totalInterest + data.totalInterestTax;
				this.totalCommission = data.totalCommission + data.totalCommissionTax;
				this.totalPeriodPay = data.totalPeriodPay;

				if (data.table) {
					// var stringStartDate = new String(result.loanRequestDetails.find(x => x.conditionName === 'StartDate').newConditionValue).split("-");
					// var startDate = new Date(Number.parseInt(stringStartDate[0]), Number.parseInt(stringStartDate[1]) - 1, Number.parseInt(stringStartDate[2]))
					// // this.startDate = startDate;
					// this.startDate = stringStartDate[0] + '-' + new String(Number.parseInt(stringStartDate[1]) - 1) + '-' + stringStartDate[2];
					// this.f1.StartDate.patchValue(this.startDate);

					var startDateStr = result.loanRequestDetails.find(x => x.conditionName === 'StartDate').newConditionValue;
					var startDateArray = startDateStr.split("/");
					this.startDate = new Date(startDateArray[2] + "/" + startDateArray[1] + "/" + startDateArray[0]);


					var starPaymentStr = result.loanRequestDetails.find(x => x.conditionName === 'StartPayment').newConditionValue;
					var startPaymentArray = starPaymentStr.split("/");
					this.startPayment = new Date(startPaymentArray[2] + "/" + startPaymentArray[1] + "/" + startPaymentArray[0]);


					var lastDatePaymentStr = data.table.find(x => x.period === this.totalPeriods).datePayment;
					var dateArray = lastDatePaymentStr.split("/");
					this.lastDatePayment = new Date(dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0]);

					this.typeOfPeriodDesc = this.typeOfPeriodList.find(x => x.key === typeOfPeriod).value;
					this.amortizationTable.data = data;
					this.amortizationTable.ngOnInit();
				}

				// if (result.paymentsJson) {
				// 	JSON.parse(result.paymentsJson).forEach(element => {
				// 		this.payments.push({
				// 			period: element.period,
				// 			amount: parseFloat(element.amount.toString())
				// 		});
				// 	});
				// }

				// this.amortizeService.currentAmortizeRequestValue.annualRate = this.amortize.annualRate;
				// this.amortizeService.currentAmortizeRequestValue.commissions = this.amortize.commissions;
				// this.amortizeService.currentAmortizeRequestValue.startDate = this.amortize.startDate;
				// this.amortizeService.currentAmortizeRequestValue.loanAmount = this.amortize.loanAmount;
				// this.amortizeService.currentAmortizeRequestValue.taxPorcent = this.amortize.taxPorcent;
				// this.amortizeService.currentAmortizeRequestValue.totalPeriods = this.amortize.totalPeriods;
				// this.amortizeService.currentAmortizeRequestValue.typeOfPeriod = this.amortize.typeOfPeriod;
				// this.amortizeService.currentAmortizeRequestValue.periodAmortizeStart = this.amortize.periodAmortizeStart;
				// this.amortizeService.currentAmortizeRequestValue.typeOfAmortization = this.amortize.typeOfAmortization;

				// this.amortizeService.recalculatePayments(this.payments)
			} else {
				this.router.navigate(['/404']);
			}
		});
	}

	createLoanrequestDetailForm() {
		this.loanrequestDetailForm = this.formBuilder.group({
			LoanAmount: ['', Validators.required],
			AnnualRate: ['', Validators.required],
			TaxPorcent: ['', Validators.required],
			TypeOfCredit: ['', Validators.required],
			PeriodAmortizeStart: ['', Validators.required],
			TypeOfPeriod: ['', Validators.required],
			TotalPeriods: ['', Validators.required],
			TypeOfAmortization: ['', Validators.required],
			StartDate: [this.datePipe.transform(this.startDate, 'yyyy-MM-dd'), Validators.required],
			StartPayment: [this.datePipe.transform(this.startPayment, 'yyyy-MM-dd'), Validators.required],
			WarrantyDataJson: ['[]'],
			MonthlyPorcentOnArrears: ['0', Validators.required],
			Commissions: ['[]'],
			LegalConditions: ['0', Validators.required],
			CreditDestination: ['0', Validators.required],
			// CustomPayments: ['', Validators.required],
			completed: [false, Validators.requiredTrue],
			PercentRetentionISR: [0, Validators.required],
			PersonType: ['', Validators.required],
			Occupation: [[], Validators.required]
		});
	}

	createEvaluatonForm(evaluationJson: string) {
		if (evaluationJson != null) {
			var evaluationList = JSON.parse(evaluationJson) as LoanRequestEvaluationDTO[];

			evaluationList.forEach((row) => {
				if (row['Value'] == 5) {
					this.evaluationData.find(x => x.id == row['Id']).five = true;
				} else if (row['Value'] == 4) {
					this.evaluationData.find(x => x.id == row['Id']).four = true;
				} else if (row['Value'] == 3) {
					this.evaluationData.find(x => x.id == row['Id']).three = true;
				} else if (row['Value'] == 2) {
					this.evaluationData.find(x => x.id == row['Id']).two = true;
				} else if (row['Value'] == 1) {
					this.evaluationData.find(x => x.id == row['Id']).one = true;
				}
			});
		}

		this.evaluationDataSource = new MatTableDataSource(this.evaluationData);
	}

	validateFormrequestJson(val: string) {
		if (!this.validateFormrequest(val)) {
			return;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	validateFormrequest(val: string) {
		if (this.FormrequestJson == null) {
			return false;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val) != null) {
			return true;
		}

		return false;
	}

	validateVisiblePersonMoral(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).visible) {
			return true;
		} else {
			return false;
		}
	}

	generateTableAmortize(generateTableStatus: boolean) {
		if (this.loanRequestDetails.length == 0) {
			return;
		}

		// if (parseInt(this.f1['PeriodAmortizeStart'].value) == NaN) {
		// 	return;
		// }

		// if (parseInt(this.f1['TotalPeriods'].value) == NaN) {
		// 	return;
		// }

		if (!this.contSuscribeValueChangesCompleted) {
			return;
		}

		if (!generateTableStatus) {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "LoanAmount").newConditionValue == "") {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "AnnualRate").newConditionValue == null) {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "TotalPeriods").newConditionValue == "") {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "PeriodAmortizeStart").newConditionValue == "") {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "TypeOfPeriod").newConditionValue == "") {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "StartDate").newConditionValue == "") {
			return;
		}
		if (this.loanRequestDetails.find(x => x.conditionName == "StartPayment").newConditionValue == "") {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "TypeOfCredit").newConditionValue == "") {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "TypeOfAmortization").newConditionValue == "") {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "MonthlyPorcentOnArrears").newConditionValue == "") {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "PercentRetentionISR").newConditionValue == "") {
			return;
		}

		if (this.loanRequestDetails.find(x => x.conditionName == "CreditDestination").newConditionValue == "") {
			return;
		}

		var retention = 0.0;

		if (this.loanRequestDetails.find(x => x.conditionName == "PercentRetentionISR") != null) {
			retention = parseFloat(this.loanRequestDetails.find(x => x.conditionName == "PercentRetentionISR").newConditionValue);
		}

		this.amortize = <Amortize>{
			loanAmount: parseFloat((this.loanRequestDetails.find(x => x.conditionName == "LoanAmount").newConditionValue + "").replace("$", "").replace(/,/g, "")),
			annualRate: parseFloat(this.loanRequestDetails.find(x => x.conditionName == "AnnualRate").newConditionValue) * GlobalData.MONTHS_PER_YEAR,
			totalPeriods: parseInt(this.loanRequestDetails.find(x => x.conditionName == "TotalPeriods").newConditionValue),
			periodAmortizeStart: parseInt(this.loanRequestDetails.find(x => x.conditionName == "PeriodAmortizeStart").newConditionValue),
			typeOfPeriod: parseInt(this.loanRequestDetails.find(x => x.conditionName == "TypeOfPeriod").newConditionValue),
			typeOfAmortization: parseInt(this.loanRequestDetails.find(x => x.conditionName == "TypeOfAmortization").newConditionValue),
			taxPorcent: (this.loanRequestDetails.find(x => x.conditionName == "TaxPorcent").newConditionValue) ? GlobalData.TAX : 0,
			startDate: new Date(this.loanRequestDetails.find(x => x.conditionName == "StartDate").newConditionValue),
			startPayment: new Date(this.loanRequestDetails.find(x => x.conditionName == "StartPayment").newConditionValue),
			commissions: JSON.parse(this.loanRequestDetails.find(x => x.conditionName == "Commissions").newConditionValue) as Commission[],
			trackId: this.uuidv4Service.uuidv4(),
			retention: retention
		};

		// this.amortizeService.resetAmortizeRequestObserver();
		this.amortizeService.generateTable(this.amortize);

		this.totalAmortize = parseFloat((this.loanRequestDetails.find(x => x.conditionName == "LoanAmount").newConditionValue + "").replace("$", "").replace(/,/g, ""));
		// this.totalInterest = this.amortizeService.currentAmortizeTableValue.totalInterest + this.amortizeService.currentAmortizeTableValue.totalInterestTax;
		this.monthlyRate = parseFloat(this.loanRequestDetails.find(x => x.conditionName == "AnnualRate").newConditionValue);
		this.typeOfCredit = this.typeOfCreditList.find(x => x.key === parseInt(this.loanRequestDetails.find(x => x.conditionName == "TypeOfCredit").newConditionValue)).value;

		// this.totalCommission = this.amortizeService.currentAmortizeTableValue.totalCommission + this.amortizeService.currentAmortizeTableValue.totalCommissionTax;

		// this.totalPeriodPay = this.amortizeService.currentAmortizeTableValue.totalPeriodPay;
		this.totalPeriods = parseInt(this.loanRequestDetails.find(x => x.conditionName == 'TotalPeriods').newConditionValue);

		var startDateStr = this.loanRequestDetails.find(x => x.conditionName === 'StartDate').newConditionValue;
		var startDateArray = startDateStr.split("/");
		this.startDate = new Date(startDateArray[2] + "/" + startDateArray[1] + "/" + startDateArray[0]);

		var startPaymentStr = this.loanRequestDetails.find(x => x.conditionName === 'StartPayment').newConditionValue;
		var startPaymentArray = startPaymentStr.split("/");
		this.startPayment = new Date(startPaymentArray[2] + "/" + startPaymentArray[1] + "/" + startPaymentArray[0]);
	
		if (this.amortizeService.currentAmortizeTableValue.table) {

			this.typeOfPeriodDesc = this.typeOfPeriodList.find(x => x.key === parseInt(this.loanRequestDetails.find(x => x.conditionName == "TypeOfPeriod").newConditionValue)).value;
		}

		this.contSuscribeValueChanges = 0;
	}

	suscribeValueChanges() {
		this.contSuscribeValueChanges = 0;

		Object.getOwnPropertyNames(this.f1).forEach((prop) => {
			if (prop == "CustomPayments") {
				return;
			}

			if (prop !== 'completed') {
				this.f1[prop].valueChanges.subscribe(() => {
					if (this.loanRequestDetails.find(x => x.conditionName == prop) != undefined) {
						this.contSuscribeValueChanges += 1;
						this.loanRequestDetails.find(x => x.conditionName == prop).newConditionValue = this.f1[prop].value;
						if ((this.contLoanRequestDetails - 1) == (this.contSuscribeValueChanges)) {
							this.contSuscribeValueChangesCompleted = true;
						}

						// if (prop == "Commissions") {
						// 	return;
						// }
						// if (prop == "LegalConditions") {
						// 	return;
						// }
						// if (prop == "CreditDestination") {
						// 	return;
						// }
						// if (prop == "WarrantyDataJson") {
						// 	return;
						// }

						this.generateTableAmortize(this.generateTableStatus);

					}
				});
			}
		});
	}

	onSubmit() {
		this.submitted = true;
		this.f1['completed'].patchValue(true);
		if (this.loanrequestDetailForm.invalid) {
			this.f1['completed'].patchValue(false);
			return;
		}

		const loanRequestAnswerDetail: LoanRequestAnswerDetail[] = [];

		Object.getOwnPropertyNames(this.f1).forEach((prop) => {
			let val = this.f1[prop].value;
			if (prop === "AnnualRate") {
				val = parseFloat(val.toString().replace("%", "")) * GlobalData.MONTHS_PER_YEAR;
			}
			if (prop === "TaxPorcent") {
				val = this.loanRequestDetails.find(x => x.conditionName == "TaxPorcent").newConditionValue ? GlobalData.TAX : 0;
			}
			if (prop === "LoanAmount") {
				val = parseFloat(val.replace("$", "").replace(/,/g, ""));
			}
			if (prop === "StartDate") {
				val = this.datePipe.transform(val, 'yyyy-MM-dd');
			}
			if (prop === "StartPayment") {
				val = this.datePipe.transform(val, 'yyyy-MM-dd');
			}
			if (prop === "Occupation") {
				val = JSON.stringify(val);
			}
			if (prop === "MonthlyPorcentOnArrears") {
				val = parseFloat(val.replace("%", ""));
			}
			if (prop === "PercentRetentionISR") {
				val = parseFloat(val.replace("%", ""));
			}

			if (prop != "legalConditions" && prop != "completed") {
				loanRequestAnswerDetail.push({ conditionName: prop, conditionValue: val.toString() });
			}
		});

		const loanRequestAnswer: LoanRequestAnswer = <LoanRequestAnswer>{
			senderCustomerId: this.authService.currentUserValue.customerId,
			addresseeCustomerId: this.addresseeCustomerId,
			details: loanRequestAnswerDetail,
			completed: this.f1['completed'].value,
			amortizationTableJson: JSON.stringify(this.amortizationTable.amortizeTableResponse),
			advanceOptionsJson: JSON.stringify(this.selectionAdvanceOptions.selected),
			borrowerCodeCustomer: this.loanRequestDTO.borrowerCodeCustomer,
			lenderCodeCustomer: this.loanRequestDTO.lenderCodeCustomer
		}

		this.loanrequestService.answerLoanRequest(this.loanRequestId, loanRequestAnswer).subscribe((result) => {
			const updateCustomPayments: PaymentsJson = <PaymentsJson>{
				paymentsJson: JSON.stringify(this.amortizeService.currentAmortizeRequestValue.payments)
			}

			if (this.pass == '2') {
				this.personData.createFormrequestJsonForm(false);
				this.addressData.createAddressDataJsonForm(false);

				if (this.bankActive) {
					this.bankData.setLoanFormRequest();
				}
			}

			this.loanrequestService.UpdatePaymentsJson(this.loanRequestId, updateCustomPayments).subscribe((result) => {
				this.getLoanRequest(true);
				// window.location.reload()
			});
		});
	}

	SendMailCompleteValidation() {
		this.loanrequestService.SendMailCompleteValidation(this.loanRequestId, this.borrowerMail, this.borrowerName, this.lenderEmail, this.lenderName).subscribe((result) => {
			//this.alertGlobalService.showAlertSuccess("de Confirmacion");
		});
	}

	saveIntegrateFile() {
		var customer: Customer = <Customer>{
			firstName: this.personData.f2['value-FirstName'].value,
			middleName: this.personData.f2['value-MiddleName'].value,
			lastName1: this.personData.f2['value-LastName1'].value,
			lastName2: this.personData.f2['value-LastName2'].value,
			contactNoUser: this.contactData.f2['value-Email'].value,
			gender: this.personData.f2['value-Gender'].value
		}

		this.customerService.createCustomer(customer).subscribe(data => {
			this.save(data.toString());
		})
	}

	save(contactBorrower: string) {
		var formJson = this.dataSourceRequiredDocuments.data;
		var countRequired = 0;
		var countRequiredCompleted = true;

		for (var key in formJson) {
			if (formJson[key]["Required"]) {
				countRequired++;
			}
			if (formJson[key]["Required"] && !formJson[key]["Uploaded"]) {
				countRequiredCompleted = false;
				break;
			}
		}

		var loanRequestSave: LoanRequestSave = {
			addressDataJson: null,
			formRequestJson: null,
			contactDataJson: null,
			economicActivityDataJson: null,
			patrimonialDataJson: null,
			economicFlowDataJson: null,
			familyDataJson: null,
			referenceDataJson: null,
			socioeconomicDataJson: null,
			bankDataJson: null,
			balanceSheetDataJson: null,
			statusResultDataJson: null,
			pldDataJson: null,
			contactBorrower: contactBorrower,
			advanceOptions: JSON.stringify(this.selectionAdvanceOptions.selected)
		};

		//datos persona
		var personDataAnswer: FormRequestAnswer[] = [];

		Object.getOwnPropertyNames(this.personData.f2).forEach((prop) => {
			var value = "";

			var form = prop.split("-")

			if (form[1] == "BirthdayDate" || form[1] == "DateConstitution" || form[1] == "DatePowers") {
				var date: string = this.personData.f2["value-" + form[1]].value;

				if (date !== null && date.trim() != "") {
					value += this.datePipe.transform(this.personData.f2["value-" + form[1]].value.toString(), 'yyyy-MM-ddT00:00:00')
				}
			} else {
				value = this.personData.f2["value-" + form[1]].value.toString();
			}

			personDataAnswer.push({
				conditionName: form[1],
				required: this.personData.FormrequestJson.find(x => x.conditionName == form[1]).required,
				value: value
			});
		});

		var personDataForm: LoanRequestOptionsForm;

		personDataForm = {
			optionForm: personDataAnswer,
			formRequestJsonCompleted: 0
		}

		loanRequestSave.formRequestJson = personDataForm;

		//datos domicilio
		var addressDataAnswer: FormRequestAnswer[] = [];

		Object.getOwnPropertyNames(this.addressData.f2).forEach((prop) => {
			var value = "";

			var form = prop.split("-")

			if (form[1] == "YearsDomicile" || form[1] == "YearsCity") {
				var date: string = this.addressData.f2["value-" + form[1]].value;

				if (date !== null && date.trim() != "") {
					value += this.datePipe.transform(this.addressData.f2["value-" + form[1]].value.toString(), 'yyyy-MM-ddT00:00:00')
				}
			} else {
				value = this.addressData.f2["value-" + form[1]].value.toString();
			}

			addressDataAnswer.push({
				conditionName: form[1],
				required: this.addressData.FormrequestJson.find(x => x.conditionName == form[1]).required,
				value: value
			});
		});

		var addressDataForm: LoanRequestOptionsForm;

		addressDataForm = {
			optionForm: addressDataAnswer,
			formRequestJsonCompleted: 0
		}

		loanRequestSave.addressDataJson = addressDataForm;

		//datos contacto
		var loanRequestUpdateContact: LoanRequestUpdateContact = {
			contactDataJson: this.contactData.contactData,
			phoneMobile: this.contactData.f2["value-PhoneMobile"] == null ? "" : this.contactData.f2["value-PhoneMobile"].value.toString().replace("-", "").replace("_", ""),
			email: this.contactData.f2["value-Email"].value,
			differenteLegal: this.contactData.f2["value-DifferenteLegal"] == null ? "" : this.contactData.f2["value-DifferenteLegal"].value,
			legalFirstName: this.contactData.f2["value-LegalFirstName"] == null ? "" : this.contactData.f2["value-LegalFirstName"].value,
			legalMiddleName: this.contactData.f2["value-LegalMiddleName"] == null ? "" : this.contactData.f2["value-LegalMiddleName"].value,
			legalLastName1: this.contactData.f2["value-LegalLastName1"] == null ? "" : this.contactData.f2["value-LegalLastName1"].value,
			legalLastName2: this.contactData.f2["value-LegalLastName2"] == null ? "" : this.contactData.f2["value-LegalLastName2"].value,
			legalPosition: this.contactData.f2["value-LegalPosition"] == null ? "" : this.contactData.f2["value-LegalPosition"].value,
			legalArea: this.contactData.f2["value-LegalArea"] == null ? "" : this.contactData.f2["value-LegalArea"].value,
			legalPhone: this.contactData.f2["value-LegalPhone"] == null ? "" : this.contactData.f2["value-LegalPhone"].value.toString().replace("-", "").replace("_", ""),
			legalEmail: this.contactData.f2["value-LegalEmail"] == null ? "" : this.contactData.f2["value-LegalEmail"].value
		}

		loanRequestSave.contactDataJson = loanRequestUpdateContact;

		//datos actividad economica
		if (this.economicActivityActive) {
			var economicActivityDataAnswer: FormRequestAnswer[] = [];

			Object.getOwnPropertyNames(this.economicActivityData.f8).forEach((prop) => {
				var form = prop.split("-")

				var value = "";
				var form = prop.split("-")

				if (form[1] == "LabAdmissionDate" || form[1] == "BusAntiquity") {
					if (this.economicActivityData.f8["value-" + form[1]].value != "") {
						value = this.datePipe.transform(this.economicActivityData.f8["value-" + form[1]].value.toString(), 'yyyy-MM-ddT00:00:00');
					}
				} else {
					value = this.economicActivityData.f8["value-" + form[1]].value.toString();
				}

				economicActivityDataAnswer.push({
					conditionName: form[1],
					required: this.economicActivityData.FormrequestJson.find(x => x.conditionName == form[1]).required,
					value: value
				});
			});

			var economicActivityDataForm: LoanRequestOptionsForm;

			economicActivityDataForm = {
				optionForm: economicActivityDataAnswer,
				formRequestJsonCompleted: 0
			}

			loanRequestSave.economicActivityDataJson = economicActivityDataForm;
		}

		//datos patrimoniales
		if (this.patrimonialActive) {
			var loanRequestUpdatePatrimonial: LoanRequestUpdatePatrimonial = {
				patrimonialDataJson: this.patrimonialData.patrimonialData
			}

			loanRequestSave.patrimonialDataJson = loanRequestUpdatePatrimonial;
		}

		//datos economicos
		if (this.economicFlowActive) {
			var loanRequestUpdateEconomicFlow: LoanRequestUpdateEconomicFlow = {
				economicFlowDataJson: this.economicFlowData.economicFlowData
			}

			loanRequestSave.economicFlowDataJson = loanRequestUpdateEconomicFlow;
		}

		//datos familiares
		if (this.familyActive) {
			var familyDataAnswer: FormRequestAnswer[] = [];

			Object.getOwnPropertyNames(this.familyData.f8).forEach((prop) => {
				var form = prop.split("-")

				familyDataAnswer.push({
					conditionName: form[1],
					required: this.familyData.FormrequestJson.find(x => x.conditionName == form[1]).required,
					value: this.familyData.f8["value-" + form[1]].value.toString()
				});
			});

			var familyDataForm: LoanRequestOptionsForm;

			familyDataForm = {
				optionForm: familyDataAnswer,
				formRequestJsonCompleted: 0
			}

			loanRequestSave.familyDataJson = familyDataForm;
		}

		//datos referencia
		if (this.referenceActive) {
			var loanRequestUpdateReference: LoanRequestUpdateReference = {
				referenceDataJson: this.referenceData.referenceData
			}

			loanRequestSave.referenceDataJson = loanRequestUpdateReference;
		}

		//datos socioeconomicos
		if (this.socioeconomicActive) {
			var socioeconomicDataAnswer: FormRequestAnswer[] = [];

			Object.getOwnPropertyNames(this.socioeconomicData.f8).forEach((prop) => {
				var form = prop.split("-")

				socioeconomicDataAnswer.push({
					conditionName: form[1],
					required: this.socioeconomicData.FormrequestJson.find(x => x.conditionName == form[1]).required,
					value: this.socioeconomicData.f8["value-" + form[1]].value.toString()
				});
			});

			var socioeconomicDataForm: LoanRequestOptionsForm;

			socioeconomicDataForm = {
				optionForm: socioeconomicDataAnswer,
				formRequestJsonCompleted: 0
			}

			loanRequestSave.socioeconomicDataJson = socioeconomicDataForm;
		}

		//Datos bancarios
		if (this.bankActive) {
			var LoanRequestUpdateBank: LoanRequestUpdateBank = {
				bankDataJson: this.bankData.bankDataOptions
			}

			loanRequestSave.bankDataJson = LoanRequestUpdateBank;
		}

		//Datos balance general
		if (this.balanceSheetActive) {
			var LoanRequestUpdatebalanceSheet: LoanRequestUpdateBalanceSheet = {
				balanceSheetDataJson: this.balanceSheetData.balanceSheetData
			}

			loanRequestSave.balanceSheetDataJson = LoanRequestUpdatebalanceSheet;
		}

		//Datos estado de resultados
		if (this.statusResultActive) {
			var LoanRequestUpdateStatusResult: LoanRequestUpdateStatusResult = {
				statusResultDataJson: this.statusResultData.statusResultData
			}

			loanRequestSave.statusResultDataJson = LoanRequestUpdateStatusResult;
		}

		//Datos pld
		if (this.pldActive) {
			var pldDataAnswer: FormRequestAnswer[] = [];

			Object.getOwnPropertyNames(this.pldData.f8).forEach((prop) => {
				var value = "";
				var form = prop.split("-")

				if (form[1] == "StartDate" || form[1] == "EndDate" || form[1] == "FamilyStartDate" || form[1] == "FamilyEndDate") {
					if (this.pldData.f8["value-" + form[1]].value != "") {
						value = this.datePipe.transform(this.pldData.f8["value-" + form[1]].value.toString(), 'yyyy-MM-ddT00:00:00');
					}
				} else {
					value = this.pldData.f8["value-" + form[1]].value.toString();
				}

				pldDataAnswer.push({
					conditionName: form[1],
					required: this.pldData.formrequestJson.find(x => x.conditionName == form[1]).required,
					value: value
				});
			});

			var pldDataForm: LoanRequestOptionsForm;

			pldDataForm = {
				optionForm: pldDataAnswer,
				formRequestJsonCompleted: 0
			}

			loanRequestSave.pldDataJson = pldDataForm;
		}

		this.loanrequestService.updateFormRequest(this.loanRequestId, loanRequestSave, countRequiredCompleted).subscribe((result) => {
			this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			this.frv = true;
			this.gv = true;
			this.dv = true;
			this.dr = true;
			this.getLoanRequest(false);
			this.SendMailCompleteValidation();
		});
	}

	onSubmitFormrequestDocumentsLegalForm() {
		if (this.formrequestDocumentsLegalForm.invalid) {
			return;
		}
	}

	authorizeRequest() {
		this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Confirmación Autorización',
				message: '¿Deseas autorizar esta solicitud?'
			}
		})
			.afterClosed()
			.subscribe((confirmado: Boolean) => {
				if (confirmado) {
					this.submitedSaveDisbursement = true;

					if (this.disbursementForm.invalid) {
						this.autorizationGroup = null;
						this.alertGlobalService.showAlertWarning("Favor de ingresar la forma de entrega");
						return;
					}

					var evaluation = <LoanRequestEvaluationDTO[]>[];

					this.evaluationDataSource.data.forEach((row) => {
						var val = 0;

						if (row.five) {
							val = 5;
						} else if (row.four) {
							val = 4;
						} else if (row.three) {
							val = 3;
						} else if (row.two) {
							val = 2;
						} else if (row.one) {
							val = 1;
						}

						if (val != 0) {
							evaluation.push({
								id: row.id,
								value: val
							});
						}
					});

					this.loanrequestService.UpdateEvaluation(this.loanRequestId, evaluation).subscribe((result) => {
						this.submitedSaveDisbursement = true;

						const loanDisbursement = <LoanDisbursement>{
							disbursementDate: this.datePipe.transform(this.f4['date'].value, 'yyyy-MM-ddT00:00:00'),
							disbursementMethod: this.f4['methodOfPaymentId'].value,
							disbursementMethodOther: this.f4['methodOfPaymentOther'].value,
							disbursementMethodDetail: this.f4['methodOfPaymentDetail'].value
						};

						this.loanrequestService.SaveDisbursementForm(this.loanRequestId, loanDisbursement).subscribe((result) => {
							this.loanrequestService.AuthorizationLoanRequest(this.loanRequestId).subscribe((result) => {
								this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
								// this.getLoanRequest(false);
							});
						});
					});
				} else {
					this.autorizationGroup = null;
				}
			});
	}

	rechazeRequest() {
		this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Confirmación rechazo',
				message: '¿Deseas rechazar esta solicitud?'
			}
		})
			.afterClosed()
			.subscribe((confirmado: Boolean) => {
				if (confirmado) {
					this.cancelLoanRequest();
				} else {
					this.autorizationGroup = null;
				}
			});
	}

	validateRequiredFormF4(formControlName: string): boolean {
		if (this.f4[formControlName] == null) {
			return;
		}

		if (this.f4[formControlName].errors !== null && this.f4[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateRequiredFormF1(formControlName: string): boolean {
		if (this.f1[formControlName] == null) {
			return;
		}

		if (this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	getIsDisabled() {
		return this.isDisabled;
	}
	getIsDisabledRejection() {
		return this.isDisabledRejection;
	}

	getIsDisabledSaveSteep2() {
		return this.isDisabledSaveSteep2;
	}

	getIsDisabledOtherSteep4() {
		return this.isDisabledOtherSteep4;
	}

	getIsDisabledAproadSteep4() {
		return this.isDisabledAproadSteep4;
	}

	getIsDisabledExplorerSteep4() {
		return this.isDisabledExplorerSteep4;
	}

	getIsdisabled3() {
		return this.isdisabled3;
	}

	getIsDisabledSearchrSteep4() {
		return this.isDisabledSearchSteep4;
	}

	getIsDisabledAproadSteep5() {
		return this.isDisabledAproadSteep5;
	}

	getIsDisabledExplorerSteep5() {
		return this.isDisabledExplorerSteep5;
	}

	getIsDisabledSearchrSteep5() {
		return this.isDisabledSearchSteep5;
	}

	getIsDisabledGiveLoan() {
		return this.isDisabledGiveLoan;
	}

	getIsDisabledDisbursement() {
		return this.isDisabledDisbursement;
	}

	onClickNextDisbursement() {
		this.stepperLoanRequest.selectedIndex = 2;
	}

	cancelLoanRequest() {
		this.dialog.open(DialogConfirmComponent, {
			data: {
				message: '¿Desea rechazar la solicitud?'
			}
		}).afterClosed().subscribe((confirmado: Boolean) => {
			if (confirmado) {
				this.loanrequestService.cancel(this.loanRequestId)
					.subscribe(() => {
						this.alertGlobalService.showAlertSuccess("Solicitud Rechazada con éxito.");
						this.router.navigate(['solicitud-de-prestamo/']);
						// this.dialogRef.close();
					});
			}
		});
	}

	openComissions(mode: string): void {
		const dialogRef = this.dialog.open(DialogComissionsConditionsComponent, {
			autoFocus: false,
			width: '85%',
			disableClose: true
		});
		let instance = dialogRef.componentInstance;
		instance.commissions = JSON.parse(this.f1['Commissions'].value) ? JSON.parse(this.f1['Commissions'].value) : [];
		instance.mode = mode;
		dialogRef.afterClosed()
			.subscribe((result) => {
				if (result === "") {
					return;
				}
				this.f1['Commissions'].patchValue(JSON.stringify(result));
			});
	}

	openTax(mode: string): void {
		const dialogRef = this.dialog.open(DialogTaxConditionsComponent, {
			autoFocus: false,
			width: '85%',
			disableClose: true
		});
		let instance = dialogRef.componentInstance;
		instance.taxPercent = this.f1['TaxPorcent'].value;
		instance.mode = mode;
		dialogRef.afterClosed()
			.subscribe((result) => {
				if (result === "") {
					return;
				}

				if (result.length > 0) {
					this.f1['TaxPorcent'].patchValue(true);
				} else {
					this.f1['TaxPorcent'].patchValue(false);
				}
			});
	}

	openWarrantys(mode: string): void {
		const dialogRef = this.dialog.open(DialogWarrantysConditionsComponent, {
			autoFocus: false,
			width: '85%',
			height: '500px',
			disableClose: true
		});
		let instance = dialogRef.componentInstance;
		instance.warrantys = JSON.parse(this.f1['WarrantyDataJson'].value) ? JSON.parse(this.f1['WarrantyDataJson'].value) : [];
		instance.mode = mode;
		dialogRef.afterClosed()
			.subscribe((result) => {
				if (result === "") {
					return;
				}
				this.f1['WarrantyDataJson'].patchValue(JSON.stringify(result));
			});
	}

	// openAdvanceOptions(mode: string): void {
	// 	const dialogRef = this.dialog.open(DialogAdvanceOptionsComponent, {
	// 	  autoFocus: false,
	// 	  width: '85%',
	// 	  height: '500px',
	// 	  disableClose: true
	// 	});
	// 	let instance = dialogRef.componentInstance;
	// 	instance.advanceOptions = JSON.parse(this.f_advanceOptions.AdvanceOptionsDataJson.value) ? JSON.parse(this.f_advanceOptions.AdvanceOptionsDataJson.value) : [];
	// 	instance.mode = mode;
	// 	dialogRef.afterClosed()
	// 	  .subscribe((result) => {
	// 		if (result === "") {
	// 			return;
	// 		}
	// 		this.f_advanceOptions.AdvanceOptionsDataJson.patchValue(JSON.stringify(result));
	// 	});
	// }

	openOldComissions(commissionsJson: string, mode: string): void {
		const dialogRef = this.dialog.open(DialogComissionsConditionsComponent, {
			autoFocus: false,
			width: '100vh',
			disableClose: true
		});
		let instance = dialogRef.componentInstance;
		instance.commissions = JSON.parse(commissionsJson) ? JSON.parse(commissionsJson) : [];
		instance.mode = mode;
	}

	openOldWarrantys(warrantysJson: string, mode: string): void {
		const dialogRef = this.dialog.open(DialogWarrantysConditionsComponent, {
			autoFocus: false,
			width: '100vh',
			disableClose: true
		});
		let instance = dialogRef.componentInstance;
		instance.warrantys = JSON.parse(warrantysJson) ? JSON.parse(warrantysJson) : [];
		instance.mode = mode;
	}

	openWarrantyDetails(number: number, warranty: string, warrantysJson) {
		if (warranty == "Aval") {
			const dialogRef = this.dialog.open(DialogWarrantyPeopleDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});

			let instance = dialogRef.componentInstance;

			dialogRef.afterClosed().subscribe((result) => {
				if (instance.submit) {
					this.gv = true;
					this.getLoanRequest(false);
				}
			});
		} else if (warranty == "Obligado solidario") {
			const dialogRef = this.dialog.open(DialogWarrantyObligorJointDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});

			let instance = dialogRef.componentInstance;

			dialogRef.afterClosed().subscribe((result) => {
				if (instance.submit) {
					this.gv = true;
					this.getLoanRequest(false);
				}
			});
		} else if (warranty == "Hipotecaria") {
			const dialogRef = this.dialog.open(DialogWarrantyMortgageDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus,
					borrowerName: this.borrowerName
				}
			});

			let instance = dialogRef.componentInstance;

			dialogRef.afterClosed().subscribe((result) => {
				if (instance.submit) {
					this.gv = true;
					this.getLoanRequest(false);
				}
			});
		} else if (warranty == "Prendaria") {
			const dialogRef = this.dialog.open(DialogWarrantyPledgeDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus,
					borrowerName: this.borrowerName
				}
			});

			let instance = dialogRef.componentInstance;

			dialogRef.afterClosed().subscribe((result) => {
				if (instance.submit) {
					this.gv = true;
					this.getLoanRequest(false);
				}
			});
		} else if (warranty == "Liquida") {
			const dialogRef = this.dialog.open(DialogWarrantyLiquidDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});

			let instance = dialogRef.componentInstance;

			dialogRef.afterClosed().subscribe((result) => {
				if (instance.submit) {
					this.gv = true;
					this.getLoanRequest(false);
				}
			});
		} else if (warranty == "Otra") {
			const dialogRef = this.dialog.open(DialogWarrantyOtherDetailsComponent, {
				autoFocus: false,
				height: '85%',
				width: '80%',
				disableClose: true,
				data: {
					number: number,
					warranty: warranty,
					loanRequestId: this.loanRequestId,
					warrantysForm: warrantysJson,
					loanRequestStatus: this.loanRequestStatus
				}
			});

			let instance = dialogRef.componentInstance;

			dialogRef.afterClosed().subscribe((result) => {
				if (instance.submit) {
					this.gv = true;
					this.getLoanRequest(false);
				}
			});
		}

	}

	openCustomPayments(mode: string): void {
		const dialogRef = this.dialog.open(DialogAmortizeTableComponent, {
			autoFocus: false,
			width: '80%'
		});
		let instance = dialogRef.componentInstance;
		instance.dataExport = false;

		if (mode == "old") {
			instance.disabledPaymentsColumn = true;
			instance.hiddenPaymentsColumn = true;
			instance.data = this.amortizeService.currentAmortizeTableValue;
		} else if (mode == "new") {
			instance.disabledPaymentsColumn = false;
			instance.hiddenPaymentsColumn = false;
			this.generateTableAmortize(this.generateTableStatus);

			dialogRef.afterClosed().subscribe((result) => {
				this.loanRequestDetails.find(x => x.conditionName === 'CustomPayments').newConditionValue = JSON.stringify(this.amortizeService.currentAmortizeTableValue)
				this.f1['CustomPayments'].patchValue(JSON.stringify(this.amortizeService.currentAmortizeTableValue));
			});
		}
	}


	onChangeMethodOfPayment(value: string): void {
		if (value == "") {
			return;
		}

		this.f4['methodOfPaymentOther'].patchValue('');
		this.f4['methodOfPaymentDetail'].patchValue('');

		if (value == "Efectivo") {
			this.f4['methodOfPaymentOther'].disable();
			this.f4['methodOfPaymentDetail'].disable();
			this.wayToPayPlaceHolder = "Detalle 1";
			this.detailPlaceHolder = "Detalle 2"
		} else if (value == "Depósito en cuenta") {
			this.f4['methodOfPaymentOther'].enable();
			this.f4['methodOfPaymentDetail'].enable();
			this.wayToPayPlaceHolder = "Banco";
			this.detailPlaceHolder = "Número de cuenta"
		} else if (value == "Transferencia electrónica") {
			this.f4['methodOfPaymentOther'].enable();
			this.f4['methodOfPaymentDetail'].enable();
			this.wayToPayPlaceHolder = "Banco";
			this.detailPlaceHolder = "Clabe interbancaria"
		} else if (value == "En especie") {
			this.f4['methodOfPaymentOther'].enable();
			this.f4['methodOfPaymentDetail'].enable();
			this.wayToPayPlaceHolder = "Cantidad";
			this.detailPlaceHolder = "Tipo de especie"
		} else if (value == "Cheque") {
			this.f4['methodOfPaymentOther'].enable();
			this.f4['methodOfPaymentDetail'].enable();
			this.wayToPayPlaceHolder = "Banco";
			this.detailPlaceHolder = "Número de cheque"
		} else if (value == "Descuento de nómina") {
			this.f4['methodOfPaymentOther'].disable();
			this.f4['methodOfPaymentDetail'].disable();
			this.wayToPayPlaceHolder = "Detalle 1";
			this.detailPlaceHolder = "Detalle 2"
		} else if (value == "Otra") {
			this.f4['methodOfPaymentOther'].enable();
			this.f4['methodOfPaymentDetail'].disable();
			this.wayToPayPlaceHolder = "Otra forma de entrega";
			this.detailPlaceHolder = "Detalle"
		} else {
			this.f4['methodOfPaymentOther'].disable();
			this.f4['methodOfPaymentDetail'].disable();
		}
	}

	aproadDocumentsRequired() {
		var formJson = this.dataSourceRequiredDocuments.data;
		var countRequired = 0;
		var countRequiredCompleted = true;

		for (var key in formJson) {
			if (formJson[key]["Required"]) {
				countRequired++;
			}
			if (formJson[key]["Required"] && !formJson[key]["uploaded"]) {
				countRequiredCompleted = false;
				break;
			}
		}

		if (!countRequiredCompleted) {
			this.alertGlobalService.showAlertWarning("Favor de subir los documentos requeridos.");
		} else {
			this.loanrequestService.UpdateDocumentsRequiredConfigJson(this.loanRequestId).subscribe((result) => {
				this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
				this.dv = true;
				this.getLoanRequest(false);
			});
		}
	}

	aproadDocumentsLegalRequired() {
		var formJson = this.dataSourceRequiredDocumentsLegal.data;
		var countRequired = 0;
		var countRequiredCompleted = true;

		for (var key in formJson) {
			if (formJson[key]["Required"]) {
				countRequired++;
			}
			if (formJson[key]["Required"] && !formJson[key]["uploaded"]) {
				countRequiredCompleted = false;
				break;
			}
		}

		if (this.f1['LegalConditions'].value == 2) {
			if (countRequired == 0) {
				this.alertGlobalService.showAlertWarning("Favor de subir al menos un documento.");
			} else if (!countRequiredCompleted) {
				this.alertGlobalService.showAlertWarning("Favor de subir los documentos requeridos.");
			} else {
				this.loanrequestService.UpdateDocumentsLegalRequiredConfigJson(this.loanRequestId).subscribe((result) => {
					this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
					this.getLoanRequest(false);
				});
			}
		} else {
			this.loanrequestService.UpdateDocumentsLegalRequiredConfigJson(this.loanRequestId).subscribe((result) => {
				this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
				this.getLoanRequest(false);
			});
		}
	}

	selectFile(event, index, documentType): void {
		this.selectedFiles = event.target.files;
		this.upload(index, documentType);
	}

	upload(index: number, documentType: string): void {
		this.progress[index] = 0;

		this.currentFile = this.selectedFiles.item(0);
		var loanRequestDate: Date = new Date;
		this.documentServiceService.upload(this.loanRequestId, documentType, loanRequestDate, this.currentFile).subscribe(
			event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progress[index] = Math.round(100 * event.loaded / event.total);
				} else if (event instanceof HttpResponse) {
					this.message = event.body.message;
				}
				if (this.progress[index] === 100) {
					this.dv = true;
					this.getLoanRequest(false);
				}
			},
			err => {
				this.progress[index] = 0;
				this.message = 'No se pudo subir el archivo.';
				this.currentFile = undefined;
			});
		this.selectedFiles = undefined;
	}

	previewFile(url: string, fileName: string) {
		const dialogRef = this.dialog.open(DialogPreviewDocumentComponent, {
			autoFocus: false,
			width: '80%'
		});
		let instance = dialogRef.componentInstance;
		instance.url = url;
		instance.extensionFile = /[^.]+$/.exec(fileName)[0];
	}

	openDialogComment(description: string) {
		if (description == "Contrato" || description == "Pagaré") {
			if (this.autorizationGroup != "Autorizado") {
				return;
			}
		}
		const dialogRef = this.dialog.open(DialogCommentDocumentComponent, {
			autoFocus: false,
			width: '100vh'
		});
		let instance = dialogRef.componentInstance;
		instance.loanRequestId = this.loanRequestId;
		instance.documentType = description;
		instance.borrowerName = this.borrowerName;
	}

	addOtherDocument() {
		if (this.newDocumentForm.invalid) {
			return;
		}

		let list = this.dataSourceRequiredDocuments.data;

		let newId = Math.max(...list.map(({ Id }) => Id)) + 1;

		let doc = <RequiredDocumentDTO>{
			Id: newId,
			Type: this.uuidv4Service.uuidv4(),
			Description: this.f_newDocumentForm['document'].value == "Otro" ? this.f_newDocumentForm['other'].value : this.f_newDocumentForm['document'].value,
			Required: true,
			Uploaded: false,
			UrlNavigate: "",
			FileName: ""
		};

		list.push(doc);

		this.loanrequestService.updateDocumentsRequired(this.loanRequestId, JSON.stringify(list))
			.subscribe(() => {
				this.newDocumentDescription = "";
				this.dataSourceRequiredDocuments.data = list;
			});
	}

	changeRequiredDocument() {
		this.loanrequestService.updateDocumentsRequired(this.loanRequestId, JSON.stringify(this.dataSourceRequiredDocuments.data))
			.subscribe(() => {

			});
	}

	changeRequiredDocumentLegal() {
		this.loanrequestService.updateDocumentsLegalRequired(this.loanRequestId, JSON.stringify(this.dataSourceRequiredDocumentsLegal.data))
			.subscribe(() => {

			});
	}

	openDialogHtmlViewer(description): void {
		if (this.getIsDisabledSearchrSteep4() || this.autorizationGroup != 'Autorizado') {
			return;
		}

		this.viewDocumentLegal(description);
	}

	viewDocumentLegal(description: string) {
		var documentGeneratorType = -1;

		if (description == "Contrato") {
			documentGeneratorType = 0
		} else if (description == "Pagaré") {
			documentGeneratorType = 1
		}

		const dialogRef = this.dialog.open(HtmlViewerComponent, {
			autoFocus: false,
			width: '80%',
			disableClose: true
		});

		let instance = dialogRef.componentInstance;

		dialogRef.afterClosed().subscribe((result) => {
			if (!instance.documentValid) {
				// this.router.navigate(['mi-perfil/']);
			}
		});

		instance.documentGeneratorType = documentGeneratorType;
		instance.loanRequestId = this.loanRequestId;
	}

	checkVerificationPhone(e) {
		if (e.checked) {
			this.phoneNumberVerified = false;
		} else {
			this.phoneNumberVerified = true;
		}
	}

	showValidation(control: string): void {
		var help = GlobalLists.helpControlsList.find(x => x.control === control);

		let dialogRef = this.dialog.open(DialogHelpInfoComponent);
		let instance = dialogRef.componentInstance;

		instance.title = help.title;
		instance.description = help.description;
		instance.isHtml = help.isHtml;
		instance.load();

		dialogRef.afterClosed().subscribe(() => {

		});
	}

	onStepChange(e) {

	}

	panelIntegrateFileOpen(e) {
		this.panelIntegrateFileOpenState = e;
	}

	panelWarrantyOpen(e) {
		this.panelWarrantyOpenState = e;
	}

	panelDocumentsOpen(e) {
		this.panelDocumentsOpenState = e;
	}

	panelAuthorizeRequestOpen(e) {
		this.panelAuthorizeRequest = e;
	}

	panelServiceRequestOpen(e) {
		this.panelServiceRequest = e;
	}

	panelDocumentsLegalOpen(e) {
		this.panelDocumentsLegalOpenState = e;
	}

	panelDocumentsLegalLoadOpen(e) {
		this.panelDocumentsLegalLoadOpenState = e;
	}

	panelDeliveryMethodOpen(e) {
		this.panelDeliveryMethodOpenState = e;
	}

	panelRecordDeliveryOpen(e) {
		this.panelRecordDeliveryOpenState = e;
	}

	panelCompanyDataOpen(e) {
		this.panelCompanyDataOpenState = e;
	}

	panelTypePersonOpen(e) {
		this.panelTypePersonOpenState = e
	}

	panelAdressOpen(e) {
		this.panelAddressOpenState = e;
	}

	panelContactDataOpen(e) {
		this.panelContactDataOpenState = e;
	}

	panelEvaluationOpen(e) {
		this.panelEvaluationRequest = e;
	}

	panelExternalServicesOpen(e) {
		this.panelExternalServices = e;
	}

	panelValidationExternalServicesOpen(e) {
		this.panelValidationExternalServices = e;
	}

	panelVerificationExternalServicesOpen(e) {
		this.panelVerificationExternalServices = e;
	}

	panelAnalysisExternalServicesOpen(e) {
		this.panelAnalysisExternalServices = e;
	}

	getAuthorizationText() {
		return `
		Autorizar solicitud
		`;
	}

	getAutorizationGroup() {
		if (this.autorizationGroup == "Autorizado" || this.autorizationGroup == "Rechazado" || this.statusName == "Rechazado") {
			if (this.statusName == "PreAutorizada") {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}

	getRechazeGroup() {
		if (this.autorizationGroup == "Autorizado" || this.autorizationGroup == "Rechazado" || this.statusName == "Rechazado") {
			if (this.statusName == "Autorizado") {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}

	}

	saveEvaluation() {
		var evaluation = <LoanRequestEvaluationDTO[]>[];

		this.evaluationDataSource.data.forEach((row) => {
			var val = 0;

			if (row.five) {
				val = 5;
			} else if (row.four) {
				val = 4;
			} else if (row.three) {
				val = 3;
			} else if (row.two) {
				val = 2;
			} else if (row.one) {
				val = 1;
			}

			if (val != 0) {
				evaluation.push({
					id: row.id,
					value: val
				});
			}
		});

		this.loanrequestService.UpdateEvaluation(this.loanRequestId, evaluation).subscribe((result) => {
			this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			this.getLoanRequest(false);
		});
	}

	// onChangeRelationShip(e: string) {
	// 	if (e == "0") {
	// 		this.f2['value-RelationShipType'].enable()
	// 	} else {
	// 		this.f2['value-RelationShipType'].disable()
	// 		this.f2['value-RelationShipType'].patchValue("");
	// 	}
	// }

	changeEvaluation(id: number, column: string) {
		this.evaluationData.find(x => x.id == id).five = false;
		this.evaluationData.find(x => x.id == id).four = false;
		this.evaluationData.find(x => x.id == id).three = false;
		this.evaluationData.find(x => x.id == id).two = false;
		this.evaluationData.find(x => x.id == id).one = false;

		this.evaluationData.find(x => x.id == id)[column] = true;
	}

	giveLoan() {
		if (!this.giveLoanStartDate) {
			this.alertGlobalService.showAlertWarning("Solicitud no se puede entregar hasta la fecha de entrega");

			return;
		}

		this.frv = true;
		this.gv = true;
		this.dv = true;
		this.dlv = true;

		if (this.legalConditionsValue > 1) {
			var formJson = this.dataSourceRequiredDocumentsLegal.data;
			var countRequired = 0;
			var countRequiredCompleted = true;

			for (var key in formJson) {
				if (formJson[key]["Required"]) {
					countRequired++;
				}
				if (formJson[key]["Required"] && !formJson[key]["Uploaded"]) {
					countRequiredCompleted = false;
					break;
				}
			}

			if (countRequired == 0) {
				this.alertGlobalService.showAlertWarning("Favor de subir al menos un documento.");
				return;
			} else if (!countRequiredCompleted) {
				this.alertGlobalService.showAlertWarning("Favor de subir los documentos requeridos.");
				return;
			}
		}

		if (!this.formRequestJsonCompleted) {
			this.showValidation("formRequestValidation");
			this.stepperLoanRequest.selectedIndex = 1;
			return;
		}

		if (!this.passRequestCompleted) {
			this.showValidation("warrantiesValidation");
			this.stepperLoanRequest.selectedIndex = 1;
			return;
		}

		if (!this.documentsRequiredConfigJsonCompleted) {
			this.showValidation("documentsValidation");
			this.stepperLoanRequest.selectedIndex = 1;
			return;
		}

		if (this.giveLoanGroup == 1) {
			this.alertGlobalService.showAlertWarning("Favor de marcar como entregado el desembolso");
			return
		}

		this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Confirmación Entrega',
				message: '¿Deseas registrar el desembolso?'
			}
		}).afterClosed().subscribe((confir: Boolean) => {
			if (confir) {
				this.dialog.open(DialogConfirmComponent, {
					data: {
						title: 'Enviar solicitud',
						message: '¿Deseas enviar el detalle al Solicitante del préstamo?'
					}
				}).afterClosed().subscribe((confirmado: Boolean) => {
					this.loanrequestService.closeLoanRequest(this.loanRequestId, confirmado).subscribe((data) => {
						this.dialog.open(DialogConfirmComponent, {
							data: {
								title: 'Préstamo desembolsado',
								message: 'Se ha registrado la entrega satisfactoriamente, por lo que su Solicitud es ahora un Préstamo activo',
								accept: true
							}
						}).afterClosed().subscribe((confirmado: Boolean) => {
							this.router.navigate(['prestamos/']);
						});
					});
				});
			}
		});
	}

	exportExcel() {
		var dataToExport = [];
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("Employee Data");
		let header = ["#", "Fecha de pago", "Capital", "Interés", "IVA Interés", "Comisión", "IVA Comisión", "Pago total", "Saldo final"]

		if (this.amortizeService.currentAmortizeTableValue.totalRetention) {
			if (!header.find(x => x == 'Retención ISR' && 'Pago neto')) {
				header.push('Retención ISR');
				header.push('Pago neto');
			} else {
				header.filter(x => x !== "Retención ISR" && x !== "Pago neto");
			}
		}

		let headerRow = worksheet.addRow(header);

		for (let i of this.amortizeService.currentAmortizeTableValue.table) {
			if (!this.amortizeService.currentAmortizeTableValue.totalRetention) {
				dataToExport.push({
					period: i.period,
					datePayment: i.datePayment,
					amortization: this.currencyPipe.transform(i.amortization),
					interest: this.currencyPipe.transform(i.interest),
					interestTax: this.currencyPipe.transform(i.interestTax),
					commission: this.currencyPipe.transform(i.commission),
					commissionTax: this.currencyPipe.transform(i.commissionTax),
					periodPay: this.currencyPipe.transform(i.periodPay),
					balance: this.currencyPipe.transform(i.balance),
				})
			} else {
				dataToExport.push({
					period: i.period,
					datePayment: i.datePayment,
					amortization: this.currencyPipe.transform(i.amortization),
					interest: this.currencyPipe.transform(i.interest),
					interestTax: this.currencyPipe.transform(i.interestTax),
					commission: this.currencyPipe.transform(i.commission),
					commissionTax: this.currencyPipe.transform(i.commissionTax),
					periodPay: this.currencyPipe.transform(i.periodPay),
					balance: this.currencyPipe.transform(i.balance),
					retention: this.currencyPipe.transform(i.retention),
					netPeriodPay: this.currencyPipe.transform(i.netPeriodPay),
				})
			}
		}

		if (!this.amortizeService.currentAmortizeTableValue.totalRetention) {
			dataToExport.push({
				period: "Total",
				datePayment: "",
				amortization: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalAmortize),
				interest: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalInterest),
				interestTax: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalInterestTax),
				commission: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalCommission),
				commissionTax: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalCommissionTax),
				periodPay: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalPeriodPay),
				balance: ""
			})
		} else {
			dataToExport.push({
				period: "Total",
				datePayment: "",
				amortization: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalAmortize),
				interest: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalInterest),
				interestTax: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalInterestTax),
				commission: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalCommission),
				commissionTax: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalCommissionTax),
				periodPay: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalPeriodPay),
				balance: "",
				retention: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalRetention),
				netPeriodPay: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalNetPeriodPay)
			})
		}

		for (let x1 of dataToExport) {
			let x2 = Object.keys(x1);
			let temp = []

			for (let y of x2) {
				temp.push(x1[y])
			}

			worksheet.addRow(temp)
		}

		let fname = "alender tabla de pagos "

		//add data and file name and download
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, fname + '- ' + `${new Date().toISOString()}.xlsx`);
		});
	}

	sendExpedientMail() {
		this.dialog.open(DialogConfirmComponent, {
			data: {
				message: '¿Desea enviar el formulario a ' + this.borrowerMail + '?'
			}
		}).afterClosed().subscribe((confirmado: Boolean) => {
			if (confirmado) {
				this.loanrequestService.sendExpedientMail(this.loanRequestId, this.borrowerMail, this.borrowerName, this.lenderEmail, this.lenderName).subscribe((result) => {
					this.alertGlobalService.showAlertSuccess("Correo enviado satisfactoriamente");
					this.getLoanRequest(false);
				});
			}
		});
	}

	isAllSelected() {
		const numSelected = this.selectionAdvanceOptions.selected.length;
		const numRows = this.dsAdvanceOptions.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.isAllSelected()) {
			this.selectionAdvanceOptions.clear();
			this.setInitialDataRequired();

			this.dataProceedings = this.dataProceedings.filter(item => {
				const row = this.dsAdvanceOptions.data.find(row => row === item);
				return !row || row.number <= 4;
			});

			this.dsAdvanceOptions.data.forEach(row => {
				if (row.number >= 5) {
					if (this.selectionAdvanceOptions.isSelected(row)) {
						this.selectionAdvanceOptions.deselect(row);
					}
				}
			});

			this.economicActivityActive = false;
			this.patrimonialActive = false;
			this.economicFlowActive = false;
			this.familyActive = false;
			this.referenceActive = false;
			this.socioeconomicActive = false;
			this.bankActive = false;
			this.balanceSheetActive = false;
			this.statusResultActive = false;
			this.pldActive = false;
		} else {

			this.dsAdvanceOptions.data.forEach(row => {
				if (row.number > 4) {
					if (!this.selectionAdvanceOptions.isSelected(row)) {
						this.selectionAdvanceOptions.select(row);
						this.dataProceedings.push(row);
					}
				}
			});
			this.economicActivityActive = true;
			this.patrimonialActive = true;
			this.economicFlowActive = true;
			this.familyActive = true;
			this.referenceActive = true;
			this.socioeconomicActive = true;
			this.bankActive = true;
			this.pldActive = true;
		}

	}

	isCheckDisabled(row: AdvanceOptions) {
		if (row.description == "Datos persona" || row.description == "Datos empresa" || row.description == "Datos domicilio" || row.description == "Datos contacto" || row.description == "Datos representante legal") {
			return true;
		} else {
			return false;
		}
	}

	setInitialDataRequired() {
		if (this.f1['PersonType'].value == "0" || this.f1['PersonType'].value == "2") {
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 1))
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 3))
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 4))

			if (this.f1['Occupation'].value.includes(1) || this.f1['Occupation'].value.includes(3)) {
				this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 5))
			}

			if (this.dsAdvanceOptions.data.find(x => x.description == "Datos empresa") != null) {
				this.dsAdvanceOptions.data.find(x => x.description == "Datos empresa").description = "Datos persona"
			}
		} else if (this.f1['PersonType'].value == "1") {
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 1))
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 2))
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 3))
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 4))

			if (this.dsAdvanceOptions.data.find(x => x.description == "Datos persona") != null) {
				this.dsAdvanceOptions.data.find(x => x.description == "Datos persona").description = "Datos empresa"
			}
		}
	}

	generateExpedient() {
		const dialogRef = this.dialog.open(HtmlViewerComponent, {
			autoFocus: false,
			width: '80%',
			disableClose: true
		});

		let instance = dialogRef.componentInstance;

		dialogRef.afterClosed().subscribe((result) => {

		});

		instance.documentGeneratorType = 4;
		instance.loanRequestId = this.loanRequestId;
	}

	updateDataRequired(options: AdvanceOptions[]) {
		this.dataRequiredCompleted = true;

		for (let option of options.filter(x => x.number >= 4)) {
			if (option.description == "Datos actividad económica" && !this.economicActivityDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}

			if (option.description == "Datos patrimoniales" && !this.patrimonialDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}

			if (option.description == "Datos económicos" && !this.economicFlowDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}

			if (option.description == "Datos familiares" && !this.familyDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}

			if (option.description == "Datos referencias" && !this.referenceDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}

			if (option.description == "Datos socioeconómicos" && !this.socioeconomicDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}

			if (option.description == "Datos bancarios" && !this.bankDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}

			if (option.description == "Datos balance general" && !this.balanceSheetDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}

			if (option.description == "Datos estado de resultados" && !this.statusResultDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}

			if (option.description == "Datos persona políticamente expuesta (PPE)" && !this.pldDataJsonCompleted) {
				this.dataRequiredCompleted = false;
				continue;
			}
		}
	}

	onChange(personType: number) {

		this.selectionAdvanceOptions.clear();
		this.setInitialDataRequired();
	}

	onchangeDataExpedient(flag: boolean, personType: string, value: number[]) {
		if (personType == "1") {
			this.occupationTittle = "Sector";
		} else {
			this.occupationTittle = "Ocupación";
		}

		if (flag) {
			this.f1['Occupation'].patchValue('');
			this.f1['CreditDestination'].patchValue('');
		}

		if (personType == "1") {
			var occupation: number[] = this.f1['Occupation'].value;

			if (value.length == 2) {
				this.f1['Occupation'].patchValue(this.eliminar(occupation, this.ocupationSelected[0]));
				this.ocupationSelected = this.f1['Occupation'].value;
			} else {
				this.ocupationSelected = value;
			}
		}

		// if (this.f1['Occupation'].value === 0) {
		// 	return;
		// }

		var data: AdvanceOptions[];
		var occupation: number[] = this.f1['Occupation'].value;
		var include = [0, 1]

		if (personType == "0") {
			data = this.typeOfAdvanceOptions.filter(x => x.personType == 0 || x.personType == 3);
		} else if (personType == "1") {
			data = this.typeOfAdvanceOptions.filter(x => x.personType == 1 || x.personType == 3);
		} else if (personType == "2") {
			occupation = [1];

			if (!this.f1['Occupation'].value.includes(1)) {
				this.f1['Occupation'].patchValue(occupation)
			}

			data = this.typeOfAdvanceOptions.filter(x => x.personType == 0 || x.personType == 3);
		}

		if (occupation.length == 0 || !occupation.some(x => include.includes(x))) {
			data = data.filter(x => x.description != "Datos actividad económica");
		}

		this.personType = parseInt(personType);
		this.occupation = this.f1['Occupation'].value;
		this.dsAdvanceOptions.data = data;

		this.selectionAdvanceOptions.clear();
		this.setInitialDataRequired();
	}

	selectOccupationDisabled(personType: string, occupation: number) {
		if (personType == "2" && occupation == 1) {
			return true
		}
	}

	getOccupationList(personType: string) {
		if (personType == "1") {
			return this.occupationList.filter(x => (x.key >= 10 && x.key <= 14))
		} else {
			return this.occupationList.filter(x => (x.key >= 0 && x.key <= 9) || (x.key == 14))
		}
	}

	getCreditDestinations(personType: string) {
		if (personType == "1") {
			return this.creditDestinationList.filter(x => (x.key >= 9 && x.key <= 14))
		} else {
			return this.creditDestinationList.filter(x => (x.key >= 0 && x.key <= 8) || (x.key == 14))
		}
	}

	eliminar(array: number[], elemento: number) {
		var resultado = []

		for (var i = 0; i < array.length; i++) {
			if (array[i] !== elemento) {
				resultado.push(array[i]);
			}
		}

		return resultado;
	}

	getDocumentName(description: string) {
		switch (description) {
			case "Identificación oficial": {
				return description;
			}
			case "Comprobante domicilio": {
				return description;
			}
			case "Comprobante ingresos": {
				return description;
			}
			case "Aval/ Obligado solidario": {
				return description;
			}
			case "Garantía prendaria/ hipotecaria": {
				return description;
			}
			case "Solicitud de crédito": {
				return description;
			}
			default: {
				return "Otro";
			}
		}
	}

	getDocumentList(description: string) {
		switch (description) {
			case "Identificación oficial": {
				return this.documentIneList;
			}
			case "Comprobante domicilio": {
				return this.documentAddressList;
			}
			case "Comprobante ingresos": {
				return this.documentIncomeList;
			}
			case "Aval/ Obligado solidario": {
				return this.documentAvalList;
			}
			case "Garantía prendaria/ hipotecaria": {
				return this.documentWarrantyList;
			}
			case "Solicitud de crédito": {
				return this.documentLoanRequestList;
			}
			default: {
				return this.documentOtherList;
			}
		}
	}

	otherDocumentChange(document: string) {
		if (document == "Otro") {
			this.f_newDocumentForm['other'].patchValue("");
			this.f_newDocumentForm['other'].enable();
			this.f_newDocumentForm['other'].setValidators([Validators.required]);
		} else {
			this.f_newDocumentForm['other'].patchValue("");
			this.f_newDocumentForm['other'].clearValidators();
			this.f_newDocumentForm['other'].disable();
		}
	}

	onChangeTotalPeriods(totalPeriods: string) {
		this.periodAmortizeStartList = [];

		for (var i = 1; i <= parseInt(totalPeriods); i++) {
			this.periodAmortizeStartList.push(
				{ key: i, value: i }
			)
		}
	}

	getTotalPeriods(): number {
		return this.totalPeriods;
	}

	setModifyData(val: boolean) {
		this.modifydata = val;
	}

	onCheckboxChange(event: any, description: string, row: AdvanceOptions[]) {
		const isSelected = event.checked;

		if (description == "Datos actividad económica") {
			if (isSelected) {
				this.economicActivityActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.economicActivityActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos actividad económica") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 5))
		} else if (description == "Datos patrimoniales") {
			if (isSelected) {
				this.patrimonialActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.patrimonialActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos patrimoniales") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 6))
		} else if (description == "Datos económicos") {
			if (isSelected) {
				this.economicFlowActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.economicFlowActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos económicos") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 7))
		} else if (description == "Datos familiares") {
			if (isSelected) {
				this.familyActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.familyActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos familiares") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 8))
		} else if (description == "Datos referencias") {
			if (isSelected) {
				this.referenceActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.referenceActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos referencias") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 9))
		} else if (description == "Datos socioeconómicos") {
			if (isSelected) {
				this.socioeconomicActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.socioeconomicActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos socioeconómicos") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 10))
		} else if (description == "Datos bancarios") {
			if (isSelected) {
				this.bankActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.bankActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos bancarios") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 11))
		} else if (description == "Datos balance general") {
			if (isSelected) {
				this.balanceSheetActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.balanceSheetActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos balance general") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 12))
		} else if (description == "Datos estado de resultados") {
			if (isSelected) {
				this.statusResultActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.statusResultActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos estado de resultados") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 13))
		} else if (description == "Datos persona políticamente expuesta (PPE)") {
			if (isSelected) {
				this.pldActive = true;
				this.dataProceedings.push(row);
			} else if (!isSelected) {
				this.pldActive = false;
				for (let i = 0; i < this.dataProceedings.length; i++) {
					if (this.dataProceedings[i].description == "Datos persona políticamente expuesta (PPE)") {
						this.dataProceedings.splice(i, 1);
						break;
					}
				}
			}
			this.selectionAdvanceOptions.select(GlobalLists.typeOfAdvanceOptions.find(x => x.number == 14))
		}

		this.updateDataRequired(this.dataProceedings)

	}


	getExternalServices() {
		this.loanService.getExternalServicesLanding().subscribe((result) => {
			this.dataSourceExternalServices.data = result;

			if(this.dataSourceExternalServices.data != null){
				this.dataViewCallVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personType && x.serviceName == "Llamada de verificación");
				this.dataViewVisitVerification = this.dataSourceExternalServices.data.filter(x => x.typePerson == this.personType && x.serviceName == "Visita de verificación");
	
				this.filteredExternalServices = this.dataViewCallVerification.concat(this.dataViewVisitVerification);
			}
		});
	}


	showConditionsExternalServices(serviceName: string) {
		this.dialog.open(DialogShowConditionsExternalServices, {
			data: {
				serviceName: serviceName
			}
		}).afterClosed().subscribe(
			(confirmado: Boolean) => { }
		);
	}

	openLink(row: CollectionLog) {
		var moduleName ="solicitud-de-prestamo";
		this.rowCollectionLogSelected = row;
		this.router.navigate(['prestamos/' + this.loanRequestId + '/detalle-de-servicio/' + row.id, { module: moduleName + '/'+ this.loanRequestId }])

	}

	getCollectionVerification() {
		this.loanService.getCollectionVerification(this.loanRequestId).subscribe((result) => {
			this.dsCollectionLog.data = result;
			if(this.dsCollectionLog.data.length > 0){
				this.countDsCollectionLog = true;
			}
		});
	}

	openCollection(externalServiceCollections: ExternalServicesDTO) {
		this.externalServiceSelected = externalServiceCollections;
		this.serviceName = externalServiceCollections.serviceName

		this.showExternalServices = true;

		if (this.externalServiceCollection != null) {
			this.externalServiceCollection.setExternalServiceCollection(this.externalServiceSelected);
		}
	}
}
