import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryMethodService {

  deliveryMethodServiceList: KeyValue<number, string>[] = [
    { key: 1, value: "Efectivo" },
    { key: 2, value: "Cheque" },
    { key: 3, value: "Depósito en cuenta" },
    { key: 4, value: "Transferencia" },
    { key: 5, value: "En especie" },
    { key: 6, value: "Pago adelantado" },
    { key: 7, value: "Otra" }
  ];

  private deliveryMethodServiceList$ = new BehaviorSubject<KeyValue<number, string>[]>(this.deliveryMethodServiceList);

  constructor() { }

  getList() {
    return this.deliveryMethodServiceList$.asObservable();
  }
}
