import { DatePipe, KeyValue } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalLists } from 'src/app/global/global-data';
import { LoanImport } from 'src/app/models/loanimport/loanimport.dto.model';
import { TypeofamortizationService } from 'src/app/services/typeofamortization/typeofamortization.service';
import { TypeofcreditService } from 'src/app/services/typeofcredit/typeofcredit.service';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';

@Component({
	selector: 'app-dialog-loanrequest-edit',
	templateUrl: './dialog-loanrequest-edit.component.html',
	styleUrls: ['./dialog-loanrequest-edit.component.scss']
})

export class DialogLoanRequestEditComponent implements OnInit {
	dataJsonForm: FormGroup = this.formBuilder.group({});
	panel = true;
	idSelected = 0;
	currencyMask = GlobalLists.currencyMask;
	percentMask = GlobalLists.percentMask;
	typeOfPeriodList: KeyValue<number, string>[] = [];
	typeOfCreditList: KeyValue<number, string>[] = [];
	typeOfAmortizationList: KeyValue<number, string>[] = [];
	taxList = GlobalLists.taxList;
	
	constructor(
		private formBuilder: FormBuilder,
		private typeofperiodService: TypeofperiodService,
		private typeofcreditService: TypeofcreditService,
		private typeofamortizationService: TypeofamortizationService,
		private datePipe: DatePipe,
		private dialogRef: MatDialogRef<DialogLoanRequestEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data:LoanImport
		) {		
	}
	
	get fdata() { return this.dataJsonForm.controls; }

	ngOnInit(): void {
		this.getTypeOfPeriodList();
		this.getTypeOfCreditList();
		this.getTypeOfAmortizationList();
		this.createForm();
	}

	getTypeOfPeriodList() {
		this.typeofperiodService.getList()
			.subscribe((list) => {
				this.typeOfPeriodList = list;
			});
	}

	getTypeOfCreditList() {
		this.typeofcreditService.getList()
			.subscribe((list) => {
				this.typeOfCreditList = list.sort((a, b) => b.key - a.key);
			});
	}

	getTypeOfAmortizationList() {
		this.typeofamortizationService.getList()
			.subscribe((list) => {
				this.typeOfAmortizationList = list.sort((a, b) => b.key - a.key);
			});
	}

	createForm() {
		this.dataJsonForm = this.formBuilder.group({
			firstName: [this.data.firstName, Validators.required],
			middleName: [this.data.middleName],
			lastName1: [this.data.lastName1, Validators.required],
			lastName2: [this.data.lastName2, Validators.required],
			email: [this.data.email, Validators.required],
			loanAmount: [this.data.loanAmount, Validators.required],
			typeOfPeriod: [this.typeOfPeriodList.find(x => x.value == this.data.typeOfPeriod) == undefined ? '' : this.typeOfPeriodList.find(x => x.value == this.data.typeOfPeriod).value, Validators.required],
			totalPeriods: [this.data.totalPeriods, Validators.required],
			monthlyRate: [this.data.monthlyRate, Validators.required],
			startDate: [this.datePipe.transform(this.data.startDate, 'yyyy-MM-dd'), Validators.required],
			typeOfCredit: [this.typeOfCreditList.find(x => x.value == this.data.typeOfCredit) == undefined ? '' : this.typeOfCreditList.find(x => x.value == this.data.typeOfCredit).value, Validators.required],
			typeOfAmortization: [this.typeOfAmortizationList.find(x => x.value == this.data.typeOfAmortization) == undefined ? '' : this.typeOfAmortizationList.find(x => x.value == this.data.typeOfAmortization).value, Validators.required],
			monthlyPorcentOnArrears: [this.data.monthlyPorcentOnArrears, Validators.required],
			periodAmortizeStart: [this.data.periodAmortizeStart, Validators.required],
			taxPorcent: [this.data.taxPorcent, Validators.required],
			percentRetentionISR: [this.data.percentRetentionISR, Validators.required],
		});
	}

	onSubmit() {
		if (this.dataJsonForm.invalid) {
			return;
		}

		this.data.firstName = this.fdata['firstName'].value;
		this.data.middleName = this.fdata['middleName'].value;
		this.data.lastName1 = this.fdata['lastName1'].value;
		this.data.lastName2 = this.fdata['lastName2'].value;
		this.data.email = this.fdata['email'].value;
		this.data.loanAmount = this.fdata['loanAmount'].value;
		this.data.typeOfPeriod = this.fdata['typeOfPeriod'].value;
		this.data.totalPeriods = this.fdata['totalPeriods'].value;
		this.data.monthlyRate = this.fdata['monthlyRate'].value;
		this.data.startDate = this.fdata['startDate'].value;
		this.data.typeOfCredit = this.fdata['typeOfCredit'].value;
		this.data.typeOfAmortization = this.fdata['typeOfAmortization'].value;
		this.data.monthlyPorcentOnArrears = parseFloat(this.fdata['monthlyPorcentOnArrears'].value.toString().replace("%", ""));
		this.data.periodAmortizeStart = this.fdata['periodAmortizeStart'].value;
		this.data.taxPorcent = this.fdata['taxPorcent'].value;
		this.data.percentRetentionISR = this.fdata['percentRetentionISR'].value;
		this.idSelected = this.data.id;

		this.dialogRef.close();
	}

	panelOpen(e) {
		this.panel = e;
	}

	validateRequired(formControlName: string): boolean {
		if (this.fdata[formControlName] == null) {
			return;
		}

		if(this.fdata[formControlName].errors !== null && this.fdata[formControlName].errors['required']) {
			return true;
		}

		return false;
	}
}
