import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, finalize, map } from 'rxjs/operators';
import { ApiResponse } from '../../models/api/api.response.model';
import { environment } from '../../../environments/environment';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { AlertGlobalService } from '../../services/alert-global/alert-global.service';
import { InputErrorsGlobalService } from '../../services/input-errors-global/input-errors-global.service';
import { Location } from "@angular/common";
import { UsserIP } from 'src/app/models/user/userlogged.model';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private apiURL = environment.apiURL;

  constructor(private http: HttpClient,
    private spinnerService: SpinnerService,
    private alertGlobalService: AlertGlobalService,
    private inputErrorsGlobalService: InputErrorsGlobalService,
    private location: Location) { }

  private getToken(): string {
    if (localStorage.getItem('currentUser') !== null) {
      const currentUser = localStorage.getItem('currentUser');
      return currentUser != null ? JSON.parse(currentUser).token : '';
    } else {
      return '';
    }
  }

  post(controller: string, action = '', body = null, spinner = true): Observable<ApiResponse> {

    if (spinner) {
      this.spinnerService.requestStarted();
    }

    return this.http.post<ApiResponse>(this.apiURL + '/' + controller + '/' + action, JSON.stringify(body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    })
      .pipe(
        retry(1),
        catchError(error => this.catchError(error)),
        finalize(() => { this.spinnerService.requestEnded(); })
      );
  }

  get(controller: string, action = '', parms = '', spinner = true): Observable<ApiResponse> {

    if (spinner) {
      this.spinnerService.requestStarted();
    }

    let url = this.apiURL + '/' + controller;

    if (action) {
      url += '/' + action;
    }

    if (parms) {
      url += '/' + parms;
    }

    return this.http.get<ApiResponse>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    })
      .pipe(
        retry(1),
        catchError(error => this.catchError(error)),
        finalize(() => this.spinnerService.requestEnded())
      );
  }

  put(controller: string, action = '', id = '', body = null, spinner = true): Observable<ApiResponse> {

    if (spinner) {
      this.spinnerService.requestStarted();
    }

    return this.http.put<ApiResponse>(this.apiURL + '/' + controller + '/' + action + '/' + id, JSON.stringify(body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    })
      .pipe(
        retry(1),
        catchError(error => this.catchError(error)),
        finalize(() => this.spinnerService.requestEnded())
      );
  }

  delete(controller: string, action = '', parms = '', spinner = true): Observable<ApiResponse> {

    if (spinner) {
      this.spinnerService.requestStarted();
    }

    return this.http.delete<ApiResponse>(this.apiURL + '/' + controller + '/' + action + '/' + parms, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    })
      .pipe(
        retry(1),
        catchError(error => this.catchError(error)),
        finalize(() => this.spinnerService.requestEnded())
      );
  }

  // Error handling
  catchError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.title}\ntraceId: ${error.traceId}`;

      if (error.status === 401) {
        localStorage.removeItem('currentUser');
        const currentPath = this.location.path();
        localStorage.clear();
        window.location.href = '/iniciar-sesion?redirect=' + currentPath;
      }
    }

    if (error.status === 400) {
      this.alertGlobalService.showAlertError("Errores en los campos de entrada.");
      this.inputErrorsGlobalService.showInputErrors(error.error.errors);
    }

    if (error.status === 409) {
      this.alertGlobalService.showAlertError(error.error.title);
    }

    if (error.status === 500) {
      this.alertGlobalService.showAlertError(error.error.title);
    }

    return throwError(errorMessage);
  }

  public getIpAddress() {
    return this.http.get('https://ipgeolocation.abstractapi.com/v1/?api_key=2a211f76a8b74949b9e18ecb3eab5d26').pipe(map(response => {
      return response as UsserIP;
    }));;
  }
}

