import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { subscriptionpaymentDTO } from 'src/app/models/subscriptionpayment/subscriptionpayment.dto.model';

@Component({
	selector: 'app-dialog-proof-of-payment',
	templateUrl: './dialog-proof-of-payment.component.html',
	styleUrls: ['./dialog-proof-of-payment.component.scss']
})

export class DialogProofOfPaymentComponent implements OnInit {
	
	rowSubscriptionPaymentSelected:subscriptionpaymentDTO = <subscriptionpaymentDTO>{};

	constructor() {
	}
	
	ngOnInit(): void {
	}

	print() {
		var mywindow = window.open();

		mywindow.document.write(this.getHTMLStyle())
		mywindow.document.write(document.getElementById("content").innerHTML);
		mywindow.stop();
		mywindow.print();
		
		return true;
	}
	
	getPeriod(e: number) {
		if (e == 1) {
			return "mensual"
		} else if (e == 2) {
			return "anual"
		}
	}

	private getHTMLStyle() {
		var html = `
		<style>
            .p1 {
                font-family: Calibri !important;
                font-size: 10px !important;
                text-align: justify !important;
            }
        </style>
		`;

		return html;
	}
}