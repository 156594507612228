import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CallCenterService } from 'src/app/services/callCenter/callCenter.service';
import { CallCenterDTO } from 'src/app/models/loan/callCenter.dto.model';
import { UserLogged } from 'src/app/models/user/userlogged.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CollectionUser } from 'src/app/models/loan/loan.dto.model';

@Component({
	selector: 'callcenter',
	templateUrl: './callcenter.component.html',
	styleUrls: ['./callcenter.component.scss']
})

export class CallcenterComponent implements OnInit {
	currentUser: UserLogged = new UserLogged;
	rowSelected: CallCenterDTO = <CallCenterDTO>{};
	dsCollectionLog = new MatTableDataSource<CollectionUser>([]);
	indexSelected: number;
	rowCollectionLogSelected: CollectionUser;

	callCenterDataSource: MatTableDataSource<CallCenterDTO>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	callcenterColumns: string[] = [
		'dateCreated',
		'lenderFullName',
		'applicantName',
		'callTypeName',
		'contactTypeName',
		'phone',
		'statusName',
		'id',
		'show'
	];

	constructor(private callCenterService: CallCenterService,
		public authService: AuthenticationService,
		private router: Router,) {

		this.authService.currentUser.subscribe(x => this.currentUser = x);

		if (this.currentUser.userType != 1) {
			this.router.navigate(['/inicio']);
		}
	}

	ngOnInit(): void {
		this.callCenterService.getCalls().subscribe(
			(result) => {
				this.callCenterDataSource = new MatTableDataSource(result);

				this.callCenterDataSource.paginator = this.paginator;
				this.callCenterDataSource.sort = this.sort;
			}
		);
	}

	onRowClicked(row: CallCenterDTO, index: number) {
		this.rowSelected = row;
		this.indexSelected = index;
	}

	onClickPage() {
		this.rowSelected = <CallCenterDTO>{};
		this.indexSelected = null;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.callCenterDataSource.filter = filterValue.trim().toLowerCase();
	}

	doubleClick(row: CallCenterDTO) {
		this.openLoanrequestResumeComponent(row.id);
	}

	openLoanrequestResumeComponent(externalServiceId: string) {
		this.router.navigate(['/centro-de-llamadas/' + externalServiceId]);
	}

	capitalizeFirstLetter(value: string): string {
		if (!value || value.length === 0) {
			return '';
		}

		const words = value.split(' ');
		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
		return capitalizedWords.join(' ');
	}

	openLink(row: CollectionUser) {
		var moduleName ="centro-de-llamadas";
		this.rowCollectionLogSelected = row;

		this.router.navigate(['prestamos/detalle-de-servicio/' + row.id, { module: moduleName }]);
	}
}