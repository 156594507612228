<div class="container-fluid">
	<div *ngIf="callCenterData" class="card">
		<div class="card-body">
			<div class="row">
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Tipo de llamada</p>
					<h5 class="mb-2">{{callCenterData.callTypeName}}</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Fecha de petición</p>
					<h5 class="mb-2">{{callCenterData.dateCreated | date: 'mediumDate'}}</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Duración de llamada</p>
					<h5 class="mb-2">{{ minutes | number: '2.0' }}:{{ seconds | number: '2.0' }}</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Estatus</p>
					<h5 class="mb-2">
						<h5 class="mb-2">{{callCenterData.statusName}}</h5>
					</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Nombre de Acreedor</p>
					<h5 class="mb-2">{{capitalizeFirstLetter(callCenterData.lenderFullName)}}</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Nombre del Deudor</p>
					<h5 class="mb-2">{{capitalizeFirstLetter(callCenterData.applicantName)}} </h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Tipo de contacto</p>
					<h5 class="mb-2">{{callCenterData.contactTypeName == 'Solicitante' ? 'Deudor': ''}}</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Teléfono celular</p>
					<h5 class="mb-2">{{callCenterData.phone}}</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Monto de {{ typeLoanDebtor === 'Préstamo' ? 'Crédito' :
						typeLoanDebtor }}</p>
					<h5 class="mb-2"> {{callCenterData.loanAmount | currency}}</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Saldo con atraso total</p>
					<h5 class="mb-2">{{callCenterData.totalBalanceLate | currency}}</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Días con atraso</p>
					<h5 class="mb-2">{{callCenterData.daysLate}}</h5>
				</div>
				<div class="col-md-3 col-6">
					<p class="text-muted text-truncate mb-1">Saldo Total a liquidar</p>
					<h5 class="mb-2">{{callCenterData.totalBalanceToLiquited | currency}}</h5>
				</div>
			</div>
		</div>
	</div>

	<div class="card">
		<div class="card-body">
			<mat-horizontal-stepper #stepperLoan labelPosition="bottom" [@.disabled]="false">
				<ng-template matStepperIcon="edit">
					<mat-icon>check</mat-icon>
				</ng-template>
				<ng-template matStepperIcon="active">
					<mat-icon>check</mat-icon>
				</ng-template>

				<mat-step [optional]="false" [editable]="true" optional *ngIf="loanRequestId != null">
					<ng-template matStepLabel>Expediente</ng-template>

					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Detalles de préstamos</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<app-conditions-data *ngIf="loanRequestDTO" #conditionsData
								[loanRequestDTO]="loanRequestDTO"></app-conditions-data>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Tabla de amortización</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<app-amortization-table #amortizationTable [hiddenPaymentsColumn]="true"
								[disabledPaymentsColumn]="true"></app-amortization-table>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Datos solicitante</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<div class="w-100">
								<div *ngIf="loadFormRequestJsonForm">
									<div class="row">
										<app-dialog-person-data #personData [mode]="mode"
											[legalConditionsValue]="legalConditionsValue" [personType]="personType"
											[loanRequestStatus]="loanRequestStatus" [dr]="dr"
											[loanRequestId]="loanRequestId" [borrowerCustomerId]="borrowerCustomerId"
											[contactRelationship]="contactRelationShip"
											[loanRequestDTO]="loanRequestDTO"
											[isDisabled]="true"></app-dialog-person-data>
										<app-dialog-address-data #addressData [personType]="personType"
											[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
											[dr]="dr" [completed]="addressDataJsonCompleted">
										</app-dialog-address-data>
										<app-dialog-contact-data #contactData [personType]="personType"
											[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
											[borrowerMail]="borrowerMail" [borrowerPhoneMobile]="borrowerPhoneMobile"
											[dr]="dr" [completed]="contactDataJsonCompleted">
										</app-dialog-contact-data>

										<app-dialog-economic-activity *ngIf="economicActivityActive"
											#economicActivityData [personData]="personData" [occupation]="occupation"
											[personType]="personType" [loanRequestStatus]="loanRequestStatus"
											[loanRequestId]="loanRequestId" [dr]="dr"
											[completed]="economicActivityDataJsonCompleted">
										</app-dialog-economic-activity>
										<app-dialog-patrimonial-data *ngIf="patrimonialActive" #patrimonialData
											[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
											[dr]="dr" [completed]="patrimonialDataJsonCompleted">
										</app-dialog-patrimonial-data>
										<app-dialog-economic-flow *ngIf="economicFlowActive" #economicFlowData
											[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
											[dr]="dr" [completed]="economicFlowDataJsonCompleted">
										</app-dialog-economic-flow>
										<app-dialog-family-data *ngIf="familyActive" #familyData
											[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
											[dr]="dr" [completed]="familyDataJsonCompleted">
										</app-dialog-family-data>
										<app-dialog-reference-data *ngIf="referenceActive" #referenceData
											[personType]="personType" [loanRequestStatus]="loanRequestStatus"
											[loanRequestId]="loanRequestId" [dr]="dr"
											[completed]="referenceDataJsonCompleted">
										</app-dialog-reference-data>
										<app-dialog-socioeconomic *ngIf="socioeconomicActive" #socioeconomicData
											[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
											[dr]="dr" [completed]="socioeconomicDataJsonCompleted">
										</app-dialog-socioeconomic>
										<app-dialog-bank-data *ngIf="bankActive" #bankData [personType]="personType"
											[loanRequestDTO]="loanRequestDTO" [loanRequestStatus]="loanRequestStatus"
											[loanRequestId]="loanRequestId" [dr]="dr"
											[completed]="bankDataJsonCompleted"></app-dialog-bank-data>
										<app-dialog-balance-sheet-data *ngIf="balanceSheetActive" #balanceSheetData
											[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
											[dr]="dr" [completed]="balanceSheetDataJsonCompleted">
										</app-dialog-balance-sheet-data>
										<app-dialog-status-result *ngIf="statusResultActive" #statusResultData
											[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
											[dr]="dr" [completed]="statusResultDataJsonCompleted">
										</app-dialog-status-result>
										<app-dialog-pld-data *ngIf="pldActive" #pldData
											[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
											[dr]="dr" [completed]="pldDataJsonCompleted"></app-dialog-pld-data>
									</div>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Ver datos garantía(s)</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<div class="table-responsive">
								<div class="react-bootstrap-table">
									<table mat-table *ngIf="dataSourceformWarrantys"
										[dataSource]="dataSourceformWarrantys">
										<ng-container matColumnDef="number">
											<th mat-header-cell *matHeaderCellDef> Número </th>
											<td mat-cell *matCellDef="let row"> {{row.number}} </td>
										</ng-container>
										<ng-container matColumnDef="warranty">
											<th mat-header-cell *matHeaderCellDef> Garantías </th>
											<td mat-cell *matCellDef="let row">
												{{row.warranty}}</td>
										</ng-container>
										<ng-container matColumnDef="warrantyFormCompleted">
											<th mat-header-cell *matHeaderCellDef> Estatus </th>
											<td mat-cell *matCellDef="let row">
												<p *ngIf="row.warrantyFormCompleted == 2">
													Completo </p>
												<p *ngIf="row.warrantyFormCompleted == 1">
													Pendiente por validar </p>
												<p *ngIf="row.warrantyFormCompleted == 0">
													Incompleto </p>
											</td>
										</ng-container>
										<ng-container matColumnDef="warrantyForm">
											<th mat-header-cell *matHeaderCellDef> Datos </th>
											<td mat-cell *matCellDef="let row">
												<a mat-button
													(click)="openWarrantyDetails(row.number, row.warranty, row.warrantyForm)">
													Ver Datos
												</a>
											</td>
										</ng-container>

										<tr mat-header-row *matHeaderRowDef="columnsFormWarrantys"></tr>
										<tr mat-row *matRowDef="let row; columns: columnsFormWarrantys;"></tr>
									</table>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Ver documentos</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<p>Visualiza los documentos requeridos en la solicitud de préstamo</p>

							<div class="table-responsive">
								<div class="react-bootstrap-table">
									<table mat-table [dataSource]="dataSourceRequiredDocuments">
										<ng-container matColumnDef="description">
											<th mat-header-cell *matHeaderCellDef> Documento </th>
											<td mat-cell *matCellDef="let row"> {{row.Description}} </td>
										</ng-container>
										<ng-container matColumnDef="required">
											<th mat-header-cell *matHeaderCellDef> </th>
											<td mat-cell *matCellDef="let row; let i = index">

												<mat-slide-toggle *ngIf="!isLender" [disabled]="true"
													[(ngModel)]="row.Required">
												</mat-slide-toggle>
											</td>
										</ng-container>
										<ng-container matColumnDef="uploaded">
											<th mat-header-cell *matHeaderCellDef> </th>
											<td mat-cell *matCellDef="let row; let i = index">
												<i class="bx bx-check"
													*ngIf="row.Uploaded && row.Required && (!progress[i] || progress[i] === 100)"></i>
												<mat-progress-spinner *ngIf="progress[i] > 0 && progress[i] < 99"
													color="primary" mode="determinate" [value]="progress[i]"
													strokeWidth="5" diameter="30">
												</mat-progress-spinner>
											</td>
										</ng-container>
										<ng-container matColumnDef="viewFile">
											<th mat-header-cell *matHeaderCellDef> Ver </th>
											<td mat-cell *matCellDef="let row; let i = index">
												<i class="fas fa-eye" [attr.disabled]="!row.Required || !row.uploaded"
													(click)="previewFile(row.UrlNavigate, row.fileName)"></i>
											</td>
										</ng-container>
										<tr mat-header-row *matHeaderRowDef="columnsRequiredDocuments"></tr>
										<tr mat-row *matRowDef="let row; columns: columnsRequiredDocuments;"></tr>
									</table>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<div class="d-flex flex-wrap gap-2 justify-content-end">
						<button class="btn btn-outline-primary external-btn" matStepperPrevious (click)="return()">
							Cerrar
						</button>
					</div>
				</mat-step>

				<mat-step [optional]="false" [editable]="true" optional *ngIf="loanRequestId != null">
					<ng-template matStepLabel>Estado de Cuenta</ng-template>

					<app-account-statement #accountStatement *ngIf="loadFormRequestJsonForm" [loanId]="id">
					</app-account-statement>

					<div class="d-flex flex-wrap gap-2 justify-content-end">
						<button class="btn btn-outline-primary external-btn" (click)="return()">
							Cerrar
						</button>
					</div>
				</mat-step>

				<mat-step [optional]="false" [editable]="false" optional>
					<ng-template matStepLabel>Datos de llamada</ng-template>

					<mat-accordion
						*ngIf="dayVisit || visitType || bank || commentsOption || clabeBank || accountBank || cardNumber || linkPay || nameCall">
						<mat-expansion-panel [expanded]="panelDetailsOpenOption">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<div class="d-flex">
										<h5>Recomendaciones opcionales</h5>
									</div>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="row custom_card">
								<div class="col-md-3 mb-3">
									<div class="form-group">
										<label for="nameCall">Cobrar a nombre de</label>
										<input autofocus="autofocus" class="form-control" type="text" [value]="nameCall"
											disabled />
									</div>
								</div>
								<div class="col-md-9 mb-3"></div>
								<div class="col-md-3 mb-3">
									<div class="form-group">
										<label for="dayVisit">Dia de llamada</label>
										<input autofocus="autofocus" class="form-control" type="text"
											[value]="dayVisit | date: 'dd/MM/yyyy'" disabled />
									</div>
								</div>
								<div class="col-md-3 mb-3">
									<div class="form-group">
										<label for="hourStart">Horario llamada de</label>
										<input autofocus="autofocus" class="form-control" type="text"
											[value]="hourStart" disabled />
									</div>
								</div>
								<div class="col-md-3 mb-3">
									<div class="form-group">
										<label for="hourEnd"> Horario llamada a</label>
										<input autofocus="autofocus" class="form-control" type="text" [value]="hourEnd"
											disabled />
									</div>
								</div>
								<div class="col-md-3 mb-3">
									<div class="form-group">
										<label for="visitType">Tipo de llamada</label>
										<input autofocus="autofocus" class="form-control" type="text"
											[value]="visitType" disabled />
									</div>
								</div>
								<div class="col-md-3 mb-3">
									<div class="form-group">
										<label for="bank">Banco</label>
										<input autofocus="autofocus" class="form-control" type="text" [value]="bank"
											disabled />
									</div>
								</div>
								<div class="col-md-3 mb-3">
									<div class="form-group">
										<label for="accountBank"> Cuenta </label>
										<input autofocus="autofocus" class="form-control" type="text"
											[value]="accountBank" disabled />
									</div>
								</div>
								<div class="col-md-3 mb-3">
									<div class="form-group">
										<label for="clabeBank">CLABE</label>
										<input autofocus="autofocus" class="form-control" type="text"
											[value]="clabeBank" disabled />
									</div>
								</div>
								<div class="col-md-3 mb-3">
									<div class="form-group">
										<label for="cardNumber">Número de tarjeta</label>
										<input autofocus="autofocus" class="form-control" type="text"
											[value]="cardNumber" disabled />
									</div>
								</div>
								<div class="col-12 mb-3">
									<div class="form-group">
										<label for="comments">Link de pago</label>
										<textarea class="form-control" rows="3" [value]="linkPay" disabled></textarea>
									</div>
								</div>
								<div class="col-12 mb-3">
									<div class="form-group">
										<label for="comments">Comentarios</label>
										<textarea  class="form-control" rows="3" [value]="commentsOption"
											disabled></textarea>
									</div>
								</div>
								<div class="col-12 mb-3">
									<div class="form-group">
										<label for="debtRason">Motivo de Adeudo</label>
										<textarea class="form-control" rows="3" [value]="debtRason" disabled></textarea>
									</div>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion *ngIf="typeCall == 'Cobranza' && statusCall == 'En proceso'">
						<mat-expansion-panel [expanded]="panelDetailsOpenState" (opened)="panelDetailsOpenState = true;"
							(closed)="panelDetailsOpenState = false;">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>{{ callScript }}</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div *ngIf="!callRating" class="row custom_card">
								<div class="col-lg-12 mb-4">
									<div class="table-responsive">
										<div class="react-bootstrap-table">
											<table *ngIf="dsContactPhoneData" mat-table
												[dataSource]="dsContactPhoneData">
												<ng-container matColumnDef="phoneType">
													<th mat-header-cell *matHeaderCellDef> Tipo de teléfono </th>
													<td mat-cell *matCellDef="let row"> {{ row.phoneType }} </td>
												</ng-container>
												<ng-container matColumnDef="phone">
													<th mat-header-cell *matHeaderCellDef> Número </th>
													<td mat-cell *matCellDef="let row">
														<span>{{ row.phone }}</span>
													</td>
												</ng-container>
												<ng-container matColumnDef="comments">
													<th mat-header-cell *matHeaderCellDef> Comentarios </th>
													<td mat-cell *matCellDef="let row"> {{ row.comments }} </td>
												</ng-container>

												<ng-container matColumnDef="select">
													<th mat-header-cell *matHeaderCellDef> Seleccionar </th>
													<td mat-cell *matCellDef="let row" class="text-center">
														<mat-checkbox [disabled]="disabledPhone"
															[checked]="selectPhone === (row.phone + ' - ' + row.phoneType) || (selectPhone === row.phone && row.phoneType)"
															(change)="selectPhoneCall(row)"></mat-checkbox>
													</td>
												</ng-container>

												<tr mat-header-row *matHeaderRowDef="contactPhoneColumns"></tr>

												<tr mat-row *matRowDef="let row; columns: contactPhoneColumns;"></tr>
											</table>
										</div>
									</div>
								</div>
								<hr>
								<div class="row" *ngIf="typeCall == 'Cobranza'">
									<div class="d-block">
										<button class="btn btn-primary" (click)="startTimer()"
											[disabled]="buttonClicked">Iniciar llamada</button>

										<div *ngFor="let question of questionsJson">
											<div>
												<p class="question" *ngIf="question.question != null"
													[innerHTML]="question.question"></p>
												<div class="container_script" *ngFor="let script of question.scripts">
													<p class="script" [innerHTML]="script.speech"></p>
												</div>
											</div>

											<div class="answer" *ngIf="question.question != null">
												<ng-container [ngSwitch]="question.type">
													<div class="form-group mt-1" *ngSwitchCase="'RadioButton'">
														<label class="d-inline-flex align-items-center me-3"
															*ngFor="let option of question.radioOptions">
															<input class="form-radio" type="radio"
																name="question-{{ question.idQuestion }}"
																[(ngModel)]="question.answer"
																[value]="option.value.charAt(0).toUpperCase() + option.value.slice(1)"
																(change)="nextQuestions(option.nextQuestion, question.idQuestion)">
															{{ option.label }}
														</label>
													</div>

													<div class="col-xl-3 col-lg-6 col-md-6">
														<div class="form-group mt-1" *ngSwitchCase="'Input'">
															<input #inputElement class="form-control"
																[ngClass]="isNumber ? 'is-amount' : ''" type="text"
																[textMask]="getMask(question.inputType)"
																[(ngModel)]="question.answer"
																(ngModelChange)="onInputChange($event, question.idQuestion)"
																[disabled]="isPromiseRegistered">
														</div>
													</div>

													<div class="mt-1" *ngSwitchCase="'Dropdown'">
														<div class="col-xl-3 col-lg-6 col-md-6 form-group">
															<select class="form-control" [(ngModel)]="question.answer"
																(change)="onSelectChange($event.target['value'], question.idQuestion)"
																#dropdownElement [disabled]="isPromiseRegistered">
																<option *ngFor="let option of question.dropdownOptions">
																	{{
																	option }}</option>
															</select>
														</div>

														<!-- rasons no payment-->
														<div *ngIf="question.idQuestion === 5.1 && question.answer && question.answer.trim().length > 1"
															class="container_script mt-3">
															Respuesta motivo de no pago: <p class="script"
																[innerHTML]="additionalOption">
															</p>
															<div *ngIf="inputAnswerObjetion === 'TextArea'"
																class="form-group mt-1">
																Comentarios
																<textarea #textareaElement class="form-control" rows="3"
																	type="text"
																	[(ngModel)]="question.additionalAnswer"></textarea>
															</div>
															<div *ngIf="inputAnswerObjetion === 'RadioInput'"
																class="form-group mt-1">
																<p>Seleccionar:</p>
																<label class="d-inline-flex align-items-center">
																	<input class="form-radio" type="radio"
																		name="optionRadio" [(ngModel)]="radioRadioInput"
																		value="Sí" (change)="reasonNoPaymentFunction()">
																	Sí
																	<input class="form-radio ms-3" type="radio"
																		name="optionRadio" [(ngModel)]="radioRadioInput"
																		#radioInput value="No"> No
																</label>
																<div class="col-xl-3 col-lg-6 col-md-6 form-group mt-1"
																	*ngIf="radioRadioInput == 'No'">
																	<label>Nos podría comentar el monto:</label>
																	<input
																		class="form-control col-xl-3 col-lg-6 col-md-6"
																		[ngClass]="isNumber ? 'is-amount' : ''"
																		type="text" [textMask]="getMask('Currency')"
																		[(ngModel)]="inputRadioInput"
																		(change)="reasonNoPaymentFunction()">
																</div>
															</div>
															<div *ngIf="inputAnswerObjetion === 'DatePay'"
																class="col-xl-3 col-lg-6 col-md-6 form-group mt-1">
																<label>Monto:</label>
																<input class="form-control col-xl-3 col-lg-6 col-md-6"
																	[ngClass]="isNumber ? 'is-amount' : ''" type="text"
																	[textMask]="getMask('Currency')" type="text"
																	[(ngModel)]="mountDatePay"
																	(change)="reasonNoPaymentFunction()">

																<label>Fecha:</label>
																<input class="form-control col-xl-3 col-lg-6 col-md-6"
																	type="date" [(ngModel)]="dateDatePay"
																	(change)="reasonNoPaymentFunction()">

																<label>Forma de pago:</label>
																<select class="form-control"
																	[(ngModel)]="formPayDatePay"
																	(change)="reasonNoPaymentFunction()"
																	#dropdownElement>
																	<option>Efectivo</option>
																	<option>Cheque</option>
																	<option>Depósito en cuenta</option>
																	<option>Transferencia electrónica</option>
																	<option>En especie</option>
																	<option>Descuento de nómina</option>
																	<option>Tarjeta de crédito/ débito</option>
																	<option>Vales de despensa</option>
																	<option>Condonación</option>
																	<option>Otra</option>
																</select>

																<div
																	*ngIf="formPayDatePay == 'Otra' || formPayDatePay == 'En especie'">
																	<div class="form-group mt-1">
																		Otra forma de pago
																		<input #inputElement autofocus="autofocus"
																			class="form-control" type="text"
																			[(ngModel)]="otherFormPayDatePay"
																			(change)="reasonNoPaymentFunction()">
																		<br>
																	</div>
																</div>
															</div>
															<div *ngIf="inputAnswerObjetion == 'Input'"
																class="col-xl-3 col-lg-6 col-md-6 form-group mt-1">
																<label>Monto</label>
																<input #inputElement class="form-control"
																	[ngClass]="isNumber ? 'is-amount' : ''" type="text"
																	[textMask]="getMask('Currency')"
																	[(ngModel)]="inputInputThree"
																	(change)="reasonNoPaymentFunction()">
															</div>
															<div *ngIf="inputAnswerObjetion == 'Datepicker'"
																class="col-xl-3 col-lg-6 col-md-6 form-group mt-1">
																<label>Fecha</label>
																<input #datepickerElement autofocus="autofocus"
																	class="form-control" type="date"
																	[(ngModel)]="dateDateFive"
																	(change)="reasonNoPaymentFunction()" />
															</div>

															<div *ngIf="inputAnswerObjetion === 'TextAreaDate'"
																class="col-xl-3 col-lg-6 col-md-6 form-group mt-1">
																Comentarios:
																<textarea #textareaElement class="form-control" rows="3"
																	type="text" [(ngModel)]="inputTextAreaDate"
																	(change)="reasonNoPaymentFunction()"></textarea>
																Fecha
																<input #datepickerElement autofocus="autofocus"
																	class="form-control col-xl-3 col-lg-6 col-md-6 form-group mt-1"
																	type="date" [(ngModel)]="dateTextAreaDate"
																	(change)="reasonNoPaymentFunction()" />
															</div>
															<div *ngIf="inputAnswerObjetion == 'DateNumber'"
																class="col-xl-3 col-lg-6 col-md-6 form-group mt-1">
																<label>Fecha</label>
																<input #datepickerElement autofocus="autofocus"
																	class="form-control" type="date"
																	[(ngModel)]="dateDateNumber"
																	(change)="reasonNoPaymentFunction()" />
																<label>Forma de contacto</label>
																<select class="form-control"
																	[(ngModel)]="formContactDateNumber"
																	(change)="reasonNoPaymentFunction()"
																	#dropdownElement>
																	<option>Llamada</option>
																	<option>Visita</option>
																</select>
															</div>
														</div>
														<!-- rasons no payment End-->
														<div *ngIf="question.idQuestion === 6.3" class="mt-3">
															<div *ngIf="question.answer == 'Otra' || question.answer == 'En especie'"
																class="col-xl-3 col-lg-6 col-md-6">
																<div class="form-group mt-1">
																	Otra forma de pago
																	<input #inputElement autofocus="autofocus"
																		class="form-control" type="text"
																		[(ngModel)]="question.additionalAnswer"
																		[disabled]="isPromiseRegistered">
																	<br>
																</div>
															</div>

															<button class="btn btn-primary"
																(click)="registerPromise(question.idQuestion)"
																*ngIf="isButtonsP">Registrar promesa</button>
															<button class="btn btn-primary mb-3"
																(click)="promiseReplace(question.idQuestion); clearField(dropdownElement)"
																*ngIf="!isButtonsP">Modificar promesa</button>
														</div>
													</div>
													<div *ngSwitchCase="'Datepicker'">
														<div class="col-xl-3 col-lg-6 col-md-6 form-group mt-1">
															<input #datepickerElement autofocus="autofocus"
																[min]="startDateString" class="form-control" type="date"
																[(ngModel)]="question.answer" [(ngModel)]="selectedDate"
																[disabled]="isPromiseRegistered" />
														</div>
														<div class="col-xl-3 col-lg-6 col-md-6 form-group mt-1">
															Hora:
															<input *ngIf="question.idQuestion != 6.2" #timepickerElement
																autofocus="autofocus" [attr.min]="startTimeString"
																class="form-control" type="time"
																[(ngModel)]="question.additionalAnswer" />
														</div>
													</div>

													<div class="form-group mt-1" *ngSwitchCase="'TextArea'">
														<textarea #textareaElement class="form-control" rows="3"
															type="text" [(ngModel)]="question.answer"
															(ngModelChange)="onInputChange($event, question.idQuestion)"></textarea>
													</div>
												</ng-container>
											</div>
										</div>
										<div class="d-flex flex-wrap gap-2 mt-3 justify-content-end"
											*ngIf="activeButton">
											<button class="btn btn-outline-primary"
												(click)="callRatingModal()">Finalizar
												llamada</button>
										</div>
									</div>
								</div>
							</div>

							<div *ngIf="callRating" class="row custom_card">
								<div *ngIf="!nextSection">
									<h5 class="fw-bold">Comentarios</h5>
									<div class="col-12 mb-3">
										<div class="col-12 mb-3">
											<div class="form-group">
											  <textarea class="form-control" rows="6" [(ngModel)]="comentts" (input)="updateMaxCaract()"  maxlength="170"></textarea>
											  <small [ngClass]="{ 'text-danger': lengCaracte === 0 }">Caracteres disponibles: {{ lengCaracte }}</small>
											</div>
										  </div>
										  <div *ngIf="lengCaracte != 170" class="d-flex justify-content-end">
											<button class="btn btn-primary" (click)="verificarOrtografia()">Corregir Texto</button>
										</div>																			  
									  </div>																			
									<br>
									<hr>
									<h5 class="fw-bold">Imágenes</h5>
									<br>
									<div class="container">
										<div class="table-responsive">
											<div class="react-bootstrap-table">
												<table fxFlex="100%">
													<thead>
														<tr class="text-right">
															<th>Tipo de Imagen</th>
															<th>Explorar</th>
															<th>Cargado</th>
															<th>Ver</th>
															<th>Eliminar</th>
														</tr>
													</thead>
													<tbody>
														<ng-container *ngFor="let document of documents; let i = index">
															<tr>
																<td class="col-xl-3 col-lg-6 col-md-6 form-group mt-1">
																	<select class="form-control">
																		<option value="captura-de-pantalla">Captura de
																			WhatsApp</option>
																		<option value="captura-de-mail">Captura de email
																		</option>
																		<option value="captura-de-sms">Captura de SMS
																		</option>
																	</select>
																</td>
																<td class="text-right">
																	<a><i class="bx bx-search" loading="lazy"
																			(click)="fileInput.click()"></i></a>
																	<input hidden #fileInput type="file"
																		accept=".jpg, .jpeg, .png"
																		(change)="selectFile($event, i)">
																</td>
																<td class="text-right">
																	<a><i class="bx bx-check"
																			*ngIf="uploadFile[i]"></i></a>
																</td>
																<td class="text-right">
																	<a><i class="bx bx-show" *ngIf="uploadFile[i]"
																			(click)="openImage(i)"></i></a>
																</td>
																<td class="text-right">
																	<a><i class="bx bx-x" *ngIf="uploadFile[i]"
																			(click)="deleteImage(i)"></i></a>
																</td>
															</tr>
														</ng-container>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<br>
									<div class="float-end just_end">
										<button class="btn btn-primary" (click)="openRating()">
											Continuar
										</button>
									</div>
								</div>

								<div *ngIf="nextSection">

									<!--<div class="py-4">
										<h5 class="fw-bold">Facilidad de contacto</h5>

										<p class="mb-0"></p>
									</div>
									<div class="py-4">
										<div class="d-flex justify-content-center align-items-center mb-2">
											<h5 style="margin-right: 20px;">Muy difícil</h5>
											<div class="d-flex align-items-center gap-2">
												<span *ngFor="let numberCall of numberCalls; let i = index" class="rating-box rounded-button"
													[class.hovered-rating-box]="numberCall === hoverNumberCalls"
													[class.filled]="numberCall <= selectedRating"
													(click)="selectRatingCall(numberCall, 'numberCall')"
													(mouseenter)="onCallHover(numberCall)" (mouseleave)="resetHover()"
													[style.background-color]="getColor(i)"
													[style.box-shadow]="numberCall === hoverNumberCalls ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none'">
													<button mat-icon-button color="accent">
													</button>
												</span>
											</div>
											<h5 style="margin-left: 20px;">Muy fácil</h5>
										</div>
									</div>																									
									<div class="d-flex justify-content-center mb-2">
										{{answerNumberCall}}
									</div>

									<hr class="m-0">-->
									<div class="py-4">
										<h5 class="fw-bold">Opinión del gestor</h5>

										<p class="mb-0">¿Cómo calificas llamada considerando la actitud o trato del
											deudor ?
										</p>
									</div>
									<div class="py-4">
										<div class="d-flex justify-content-center mb-2">
											<h5 style="margin-right: 160px;">Negativa Alta</h5>
											<h5 style="margin-right: 5px;">Positiva Alta</h5>
										</div>
										<div class="d-flex justify-content-center gap-2">
											<span *ngFor="let rating of ratings; let i = index" class="rating-box rounded-button"
												[class.hovered-rating-box]="rating === hoveredRatingCall"
												[style.background-color]="getColor(i)"
												[class.filled]="rating <= selectedRating"
												(click)="selectRatingCall(rating, 'rating')"
												(mouseenter)="onRatingHoveRrating(rating)" (mouseleave)="resetHover()"
												[style.box-shadow]="rating === hoveredRatingCall ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none'">
											</span>
										</div>
									</div>
									<div class="d-flex justify-content-center mb-2">
										{{answerSelectCall}}
									</div>

									<hr class="m-0">
									<div class="py-4">
									<h5 class="fw-bold">Intención de pago</h5>
									<p class="mb-0">¿Cómo evalúas la intención de pagar de parte del solicitante?
									</p>
								</div>
									<div class="py-4">
										<div class="d-flex justify-content-center mb-2">
											<h5 style="margin-right: 180px;">Muy Baja</h5>
											<h5 style="margin-right: 20px;">Muy Alta</h5>
										</div>
										<div class="d-flex justify-content-center gap-2">
											<span *ngFor="let star of stars" class="star"
												(click)="selectRatingCall(star, 'star')"
												(mouseenter)="onStarHover(star)" (mouseleave)="resetHover()">
												<i class="bx bxs-star"
													[class.filled]="star <= (hoveredRating || selectedRatingStar)"></i>
											</span>
										</div>
									</div>
									<div class="d-flex justify-content-center mb-2">
										{{answerSelectPay}}
									</div>
								</div>

								<div *ngIf="nextSection" class="float-end just_end">
									<button class="btn btn-primary" (click)="endcall()">
										Terminar
									</button>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion>
						<mat-expansion-panel (opened)="panelContactDataOpen(true)"
							(closed)="panelContactDataOpen(false)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<div class="d-flex">
										<h5>Historial de llamadas</h5>
									</div>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="table-responsive">
								<table class="table-nowrap table" *ngIf="callsListDataSource" mat-table
									[dataSource]="callsListDataSource">
									<ng-container matColumnDef="dateCreated">
										<th mat-header-cell *matHeaderCellDef>Fecha y hora de llamada</th>
										<td mat-cell *matCellDef="let row">
											{{ row.dateCreated | date: 'mediumDate' }},
											{{ row.dateCreated | date: 'shortTime' }}
										</td>
									</ng-container>
									<ng-container matColumnDef="firstNameLoan">
										<th mat-header-cell *matHeaderCellDef> Nombre del Deudor</th>
										<td mat-cell *matCellDef="let row">
											{{capitalizeFirstLetter(callCenterData.applicantName)}}
										</td>
									</ng-container>
									<ng-container matColumnDef="callTypeName">
										<th mat-header-cell *matHeaderCellDef> Tipo de llamada </th>
										<td mat-cell *matCellDef="let row"> {{row.callTypeName}} </td>
									</ng-container>
									<ng-container matColumnDef="agent">
										<th mat-header-cell *matHeaderCellDef>Gestor</th>
										<td mat-cell *matCellDef="let row"> {{capitalizeFirstLetter(row.agent)}} </td>
									</ng-container>
									<ng-container matColumnDef="phone">
										<th mat-header-cell *matHeaderCellDef> Nùmero</th>
										<td mat-cell *matCellDef="let row"> {{ truncatePhone(row.phone) }}</td>
									</ng-container>
									<ng-container matColumnDef="actions">
										<th mat-header-cell *matHeaderCellDef>Ver</th>
										<td mat-cell *matCellDef="let row">
											<i class="bx bx-show" (click)="onViewDetailCall(row)"></i>
										</td>
									</ng-container>
									<tr mat-header-row *matHeaderRowDef="callCenterResultColumns"></tr>
									<tr mat-row *matRowDef="let row; columns: callCenterResultColumns; let i = index ">
									</tr>
								</table>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<div class="d-flex flex-wrap gap-2 justify-content-end">
						<button class="btn btn-outline-primary external-btn" (click)="return()">
							Cerrar
						</button>
					</div>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
	</div>
</div>