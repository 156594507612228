<div class="container-fluid">
    <h4>Facturas</h4>
    <div class="card">
        <div class="card-body">
            <h5>Generar nueva factura</h5>
            <p>Sigue los siguientes pasos para generar tu factura</p>

            <mat-horizontal-stepper linear #stepperBill labelPosition="bottom">
                <mat-step [stepControl]="subscriptionsForm" [optional]="false">
                    <ng-template matStepperIcon="edit">
                        <i class="bx bx-check"></i>
                    </ng-template>
                    <ng-template matStepperIcon="active">
                        <i class="bx bx-check"></i>
                    </ng-template>
                    <ng-template matStepLabel>Compra a facturar</ng-template>

                    <form [formGroup]="subscriptionsForm" (ngSubmit)="onSubmitSubscriptionsForm()">
                        <app-input-errors-global *ngFor="let item of f_subscriptionsFormProp"
                            fieldName="{{item}}"></app-input-errors-global>
                        <div mat-dialog-content>
                            <div *ngIf="billDataSource">
                                <div class="table-responsive">
                                    <div class="react-bootstrap-table">
                                        <table matTableResponsive mat-table matSort [dataSource]="billDataSource"
                                            (matSortChange)="onClickSort()"
                                            (dblclick)="doubleClick($event, rowSelected.id)">
                                            <ng-container matColumnDef="numberOperations">
                                                <th mat-header-cell *matHeaderCellDef> Tipo de compra </th>
                                                <td mat-cell *matCellDef="let row"> {{row.numberOperations > 0 ?
                                                    'Suscripciones' : 'Recarga de saldo'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="description">
                                                <th mat-header-cell *matHeaderCellDef> Paquete </th>
                                                <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="dateCreated">
                                                <th mat-header-cell *matHeaderCellDef> Fecha de compra </th>
                                                <td mat-cell *matCellDef="let row"> {{row.dateCreated |
                                                    date:'mediumDate'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="methodPayment">
                                                <th mat-header-cell *matHeaderCellDef> Método de pago </th>
                                                <td mat-cell *matCellDef="let row"> {{row.methodPayment}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="amount">
                                                <th mat-header-cell *matHeaderCellDef> Monto </th>
                                                <td mat-cell *matCellDef="let row" class="text-right"> {{row.amount |
                                                    currency}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef> Estatus </th>
                                                <td mat-cell *matCellDef="let row"> <button
                                                        *ngIf="row.status === 'paid'" class="unclickable" type="button"
                                                        mat-icon-button color="accent"> <mat-icon>lens</mat-icon> Pagado
                                                    </button> <button *ngIf="row.status === 'pending_payment'"
                                                        class="unclickable" type="button" mat-icon-button
                                                        color="primary"> <mat-icon>lens</mat-icon> Pendiente </button>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="subscriptionColumns; sticky: true">
                                            </tr>
                                            <tr mat-row
                                                *matRowDef="let row; columns: subscriptionColumns; let i = index"
                                                (click)="onRowClicked(row, i)"
                                                [ngClass]="{'row-selected': indexSelected === i }"></tr>
                                        </table>
                                    </div>
                                </div>

                                <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>
                            </div>
                        </div>

                        <mat-error>
                            {{errorValidationMonthMessage}}</mat-error>

                        <div class="d-flex flex-wrap gap-2 justify-content-end">
                            <button class="btn btn-outline-primary external-btn" matStepperPrevious
                                routerLink="/mi-cuenta">
                                Cerrar
                            </button>

                            <button *ngIf="showErrorValidationMonth != null && !showErrorValidationMonth"
                                class="btn btn-primary external-btn" mat-raised-button type="submit">
                                Continuar
                            </button>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="billDataForm">
                    <ng-template matStepLabel>Validación de datos</ng-template>

                    <form [formGroup]="billDataForm" (ngSubmit)="onSubmitBillDataForm()">
                        <app-input-errors-global *ngFor="let item of f_billDataFormProp"
                            fieldName="{{item}}"></app-input-errors-global>

                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label>Razón social *</label>
                                    <input type="text" class="form-control" formControlName="businessName">
                                </div>
                            </div>

                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label>Regimen Fiscal*</label>
                                    <select #select class="form-control" formControlName="billingTaxRegime">
                                        <option value="">Seleccione</option>
                                        <option *ngFor="let item of fiscalRegimeList" value="{{item.value}}">
                                            {{item.value}}
                                            - {{item.name}}</option>
                                    </select>
                                    <mat-error *ngIf="submitted && validateRequiredForm('billingTaxRegime')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>

                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label for="billingRfc">RFC*</label>
                                    <input type="text" formControlName="billingRfc" class="form-control"
                                        (input)="rfcInput($event)">
                                    <mat-error *ngIf="submitted && validateRequiredForm('billingRfc')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="f_billDataForm['billingRfc'].errors && f_billDataForm['billingRfc'].errors['minlength']">El
                                        RFC debe tener minímo 12 caracteres.</mat-error>
                                </div>
                            </div>

                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label>Uso CFDI*</label>
                                    <select #select class="form-control" formControlName="cfdiUse">
                                        <option value="">Seleccione</option>
                                        <option *ngFor="let item of cfdiUseList" value="{{item.value}}">{{item.value}} -
                                            {{item.name}} </option>
                                    </select>
                                    <mat-error *ngIf="submitted && validateRequiredForm('cfdiUse')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label for="street">Calle </label>
                                    <input type="text" formControlName="street" class="form-control">
                                    <mat-error *ngIf="submitted && validateRequiredForm('street')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label for="exteriorNumber">Número exterior </label>
                                    <input type="text" formControlName="exteriorNumber" class="form-control">
                                    <mat-error *ngIf="submitted && validateRequiredForm('exteriorNumber')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label for="interiorNumber">Número interior</label>
                                    <input type="text" formControlName="interiorNumber" class="form-control">
                                    <mat-error *ngIf="submitted && validateRequiredForm('interiorNumber')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label for="districtName">Colonia </label>
                                    <input type="text" formControlName="districtName" class="form-control">
                                    <mat-error *ngIf="submitted && validateRequiredForm('districtName')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label for="countryId">País </label>
                                    <select #selectCountry class="form-control" formControlName="countryId"
                                        (change)="onChangeSelectCountries(selectCountry.value)">
                                        <option value="">Seleccione</option>
                                        <option *ngFor="let item of countries" value="{{item.code}}">{{item.name}}
                                        </option>
                                    </select>
                                    <mat-error *ngIf="submitted && validateRequiredForm('countryId')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label for="stateId">Estado </label>
                                    <select #selectState class="form-control" formControlName="stateId"
                                        (change)="onChangeSelectStates(selectCountry.value, selectState.value)">
                                        <option value="">Seleccione</option>
                                        <option *ngFor="let item of billingStates" value="{{item.code}}"> {{item.name}}
                                        </option>
                                    </select>
                                    <mat-error *ngIf="submitted && validateRequiredForm('stateId')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label for="cityId">Municipio </label>
                                    <select class="form-control" formControlName="cityId">
                                        <option value="">Seleccione</option>
                                        <option *ngFor="let item of billingCities" value="{{item.code}}"> {{item.name}}
                                        </option>
                                    </select>
                                    <mat-error *ngIf="submitted && validateRequiredForm('cityId')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label for="zipCode">Código postal*</label>
                                    <input type="number" formControlName="zipCode" class="form-control">
                                    <mat-error *ngIf="submitted && validateRequiredForm('zipCode')">
                                        {{requiredErrorMessage}}
                                    </mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-wrap gap-2 justify-content-end">
                            <button class="btn btn-outline-primary external-btn" matStepperPrevious
                                routerLink="/mi-cuenta">
                                Cerrar
                            </button>

                            <button class="btn btn-outline-primary external-btn" matStepperPrevious>
                                Atrás
                            </button>

                            <button class="btn btn-primary external-btn" mat-raised-button type="submit">
                                Continuar
                            </button>
                        </div>
                    </form>
                </mat-step>

                <mat-step>
                    <ng-template matStepLabel>Resumen</ng-template>
                    <div *ngIf="rowSelected">
                        <form (ngSubmit)="onSubmitBillResumeForm()">
                            <span class="card-errors"></span>
                            <div mat-dialog-content>
                                <div>
                                    <label class="mb-2">
                                        Resumen de factura
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <address>
                                                <span>Nombre: </span><strong>{{businessName}}</strong>
                                            </address>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table-nowrap table">
                                            <thead>
                                                <tr>
                                                    <th>Paquete</th>
                                                    <th>Cantidad</th>
                                                    <th>Correo</th>
                                                    <th>RFC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{rowSelected.description}}</td>
                                                    <td>{{rowSelected.periodQuantity}}</td>
                                                    <td>{{rowSelected.email}}</td>
                                                    <td>{{rowSelected.billingRfc}}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" class="border-0 text-end"><strong>Total</strong>
                                                    </td>
                                                    <td class="border-0 text-end">{{rowSelected.amount | currency}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <mat-error *ngIf="showError">
                                        {{messageError}}</mat-error>
                                </div>

                                <div class="d-flex flex-wrap gap-2 justify-content-end mt-4">
                                    <button class="btn btn-outline-primary external-btn" matStepperPrevious
                                        routerLink="/mi-cuenta">
                                        Cerrar
                                    </button>
                                    <button class="btn btn-outline-primary external-btn" matStepperPrevious>
                                        Atrás
                                    </button>
                                    <button class="btn btn-primary" mat-raised-button type="submit">
                                        Generar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>