<div class="container-fluid">
  <h4>Mi cuenta</h4>
  <div *ngIf="myBalance" class="row">
    <div class="col-md-4 col-12">
      <div class="card mb-4">
        <div class="card-body">
          <div class="mb-2">
            <h5 class="mb-2">
              Saldo disponible
            </h5>
            <hr>

            <h5 class="mb-0">
              {{ myBalance.balance | currency}} <span class="small_text">MXN</span>
            </h5>
          </div>
          <div class="d-flex justify-content-between align-items-center content-full">
            <a class="text-link" (click)="togglePanel(panel1)">Ver historial</a>
            <button class="btn btn-primary" routerLink="/mi-cuenta/recargar-saldo">
              Recargar saldo
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="card">
        <div class="card-body">
          <div class="mb-2">
            <h5 class="mb-2">
              Suscripciones
            </h5>
            <hr>

            <h5 class="mb-0">
              <span class="text-muted middle_text">Suscripción actual:</span> {{ myBalance.currentSubscription }}
            </h5>
          </div>
          <div class="d-flex justify-content-between align-items-center content-full">
            <a class="text-link" (click)="togglePanel(panel2)">Ver historial</a>
            <button *ngIf="!showNew" class="btn btn-primary" (click)="openNew('mejorar-plan')">
              Mejorar
            </button>
            <button *ngIf="showNew" class="btn btn-primary" (click)="openNew('nueva')">
              Nueva
            </button>
            <button *ngIf="showRenew" class="btn btn-primary" (click)="openNew('renovar')">
              Renovar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="card">
        <div class="card-body">
          <div class="mb-2">
            <h5 class="mb-2">
              Facturas
            </h5>
            <hr>

            <h5 class="mb-0">
              <span class="text-muted middle_text">&nbsp;</span> &nbsp;
            </h5>
          </div>
          <div class="d-flex justify-content-between align-items-center content-full">
            <a class="text-link" (click)="togglePanel(panel3)">Ver historial</a>
            <button class="btn btn-primary" routerLink="/facturas/generar-factura">
              Facturar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-2 text-muted">
            Historiales
          </h5>
          <hr>

          <mat-accordion>
            <mat-expansion-panel #panel1 (opened)="togglePanel(panel1)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="d-flex">
                    <h5>De mi saldo</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="d-flex justify-content-end mb-4">
                <div class="form-group col-md-4 col-12">
                  <label>Buscar</label>
                  <div>
                    <i class="bx bx-search input_icon"></i>
                    <input class="form-control btn-rounded" (keyup)="applyFilter($event)" #filterText placeholder=""
                      type="text" />
                  </div>
                </div>
              </div>


            <div id="content">
              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table *ngIf="balanceMovementsDataSource" mat-table [dataSource]="balanceMovementsDataSource" [ngClass]="isLender ? 'bg-primary' : 'bg-secondary'">
                    <tbody>

                      <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>Fecha</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.date | date:'mediumDate'}} </td>
                      </ng-container>

                      <ng-container matColumnDef="movement">
                        <th mat-header-cell *matHeaderCellDef>Tipo de movimiento</th>
                        <td mat-cell *matCellDef="let row"> {{row.movement}} </td>
                      </ng-container>
                      
                      <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Monto</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.amount | currency}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="methodPayment">
                        <th mat-header-cell *matHeaderCellDef>Método de pago</th>
                        <td mat-cell *matCellDef="let row"> {{row.methodPayment}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="errorMessage">
                        <th mat-header-cell *matHeaderCellDef>Estado de pago</th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.errorMessage ? 'Tarjeta declinada. Intenta con otra tarjeta o método de pago.' : (row.methodPayment === 'Saldo' ? 'Rebaje' : 'Pagado') }}
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="balanceMovementsColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: balanceMovementsColumns; let i = index"
                        (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }">
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>
            </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel #panel2 (opened)="togglePanel(panel2)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="d-flex">
                    <h5>De mis suscripciones</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <app-subscriptionpayment-list [isLender]="isLender" [showNew]="showNew"
                [showRenew]="showRenew"></app-subscriptionpayment-list>
            </mat-expansion-panel>
          </mat-accordion>


          <mat-accordion>
            <mat-expansion-panel #panel3 (opened)="togglePanel(panel3)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="d-flex">
                    <h5>De mis facturas</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <app-bill-list [isLender]="isLender"></app-bill-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>