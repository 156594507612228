<div>
    <h5>Detalle de Base de Datos</h5>
    <hr>

    <div class="d-flex justify-content-end mb-4">
        <div class="form-group col-md-4 col-12">
            <label>Buscar</label>
            <div>
                <i class="bx bx-search input_icon"></i>
                <input class="form-control" (keyup)="applyFilter($event)" #filterText placeholder=""
                    type="text" />
            </div>
        </div>
    </div>
</div>

<div id="content">
    <div class="table-responsive">
        <div class="react-bootstrap-table">
            <table *ngIf="data.entry == 'details' && administratorsubscriptionsDataSource != null" mat-table [dataSource]="administratorsubscriptionsDataSource"
                class="table table align-middle" [ngClass]="isLender ? 'bg-primary' : 'bg-secondary'">
                <tbody>
                      <ng-container matColumnDef="subscripcionStartDate">
                        <th mat-header-cell *matHeaderCellDef>Fecha inicio suscripción </th>
                        <td mat-cell *matCellDef="let row"> {{row.subscripcionStartDate | date: 'd MMM y'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Nombre suscripción</th>
                        <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                    </ng-container>
                    <ng-container matColumnDef="packageOperations">
                        <th mat-header-cell *matHeaderCellDef>No. operaciones</th>
                        <td mat-cell *matCellDef="let row"> {{row.packageOperations}} </td>
                    </ng-container>
                    <ng-container matColumnDef="idPeriod">
                        <th mat-header-cell *matHeaderCellDef>Periodicidad</th>
                        <td mat-cell *matCellDef="let row"> {{row.idPeriod}} </td>
                    </ng-container>
                    <ng-container matColumnDef="periodQuantity">
                        <th mat-header-cell *matHeaderCellDef>No. períodos</th>
                        <td mat-cell *matCellDef="let row"> {{row.periodQuantity}} </td>
                    </ng-container>
                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef>Primer nombre</th>
                        <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="middleName">
                        <th mat-header-cell *matHeaderCellDef>Segundo nombre</th>
                        <td mat-cell *matCellDef="let row"> {{row.middleName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName1">
                        <th mat-header-cell *matHeaderCellDef> Primer apellido</th>
                        <td mat-cell *matCellDef="let row"> {{row.lastName1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName2">
                        <th mat-header-cell *matHeaderCellDef> Segundo apellido</th>
                        <td mat-cell *matCellDef="let row"> {{row.lastName2}} </td>
                    </ng-container>
                    <ng-container matColumnDef="userName">
                        <th mat-header-cell *matHeaderCellDef> Correo electrónico</th>
                        <td mat-cell *matCellDef="let row"> {{row.userName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="isEnabled">
                        <th mat-header-cell *matHeaderCellDef>Estatus plan</th>
                        <td mat-cell *matCellDef="let row"> {{row.isEnabled}} </td>
                    </ng-container>
                    <ng-container matColumnDef="subscripcionEndDate">
                        <th mat-header-cell *matHeaderCellDef>Fecha fin suscripción actual</th>
                        <td mat-cell *matCellDef="let row"> {{row.subscripcionEndDate | date: 'd MMM y'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="amountWithoutTAX">
                        <th mat-header-cell *matHeaderCellDef>Monto suscripción actual sin IVA  </th>
                        <td mat-cell *matCellDef="let row"> {{row.amountWithoutTAX | currency }} </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Monto suscripción actual con IVA  </th>
                        <td mat-cell *matCellDef="let row"> {{row.amount | currency }} </td>
                    </ng-container>
                    <ng-container matColumnDef="idMethodPayment">
                        <th mat-header-cell *matHeaderCellDef>Metodo de pago</th>
                        <td mat-cell *matCellDef="let row"> {{row.idMethodPayment}} </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Estatus plan</th>
                        <td mat-cell *matCellDef="let row"> {{row.status}} </td>
                    </ng-container>
                    <ng-container matColumnDef="subscriptionType">
                        <th mat-header-cell *matHeaderCellDef>Tipo de suscripcion </th>
                        <td mat-cell *matCellDef="let row"> {{row.subscriptionType}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="detailsAdministratorColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: detailsAdministratorColumns; let i = index"
                        (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
                </tbody>
            </table>
        </div>
    </div>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>

    <div class="d-flex flex-wrap gap-2 justify-content-end">
        <button class="btn btn-outline-primary external-btn" mat-dialog-close>
            Cerrar
        </button>

        <button class="btn btn-outline-primary external-btn" (click)="exportExcel()">
            Excel
        </button>
    </div>
</div>
