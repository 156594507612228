import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-loanrequestview',
  templateUrl: './loanrequestview.component.html',
  styleUrls: ['./loanrequestview.component.scss']
})
export class LoanrequestViewComponent implements OnInit {

  loanRequestId: string = "";
  tabIndex: number = 0;

  tabTitle = "";
  isLender = false;

  constructor(private route: ActivatedRoute,
              private authService: AuthenticationService) { }

  ngOnInit(): void {

    this.isLender = this.authService.currentUserModeLenderValue;
    
    this.loanRequestId = this.route.snapshot.paramMap.get('loanRequestId');
  }

}
