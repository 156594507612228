<div [ngClass]="!isNew ? 'container-fluid' : ''">
  <h4 *ngIf="!isNew">Suscripciones</h4>
  <div [ngClass]="!isNew ? 'row' : ''">
    <div [ngClass]="!isNew ? 'col-lg-8' : ''">
      <div [ngClass]="!isNew ? 'card' : ''">
        <div class="card-body">
          <div *ngIf="!isNew" class="mb-4">
            <div>
              <h5>Contratar un plan</h5>
              <hr>

              <p>Sigue los siguientes pasos para contratar tu suscripción</p>
            </div>
          </div>
          <mat-horizontal-stepper id="isAuthenticated" [linear]="true" [ngClass]="isNew ? 'subscriptionsNone' : 'subscriptions'"
            [linear]="false" #stepperSubscription labelPosition="bottom">
            <mat-step [stepControl]="subscriptionTypeForm">
              <ng-template matStepperIcon="edit">
                <i class="bx bx-check"></i>
              </ng-template>
              <ng-template matStepperIcon="active">
                <i class="bx bx-check"></i>
              </ng-template>
              <ng-template matStepLabel>Plan a contratar</ng-template>
              <form [formGroup]="subscriptionTypeForm" (ngSubmit)="onSubmitSubscriptionTypeForm()">
                <app-input-errors-global *ngFor="let item of f_subscriptionTypeFormProp" fieldName="{{item}}">
                </app-input-errors-global>
                <section id="planes">
                  <div class="container-fluid" [ngClass]="!isNew ? 'py-3' : 'py-5'">
                    <div class="align-items-center row justify-content-between">
                      <div *ngIf="plans" class="mx-auto light">
                        <p class="title dark" *ngIf="isNew">Planes y precios</p>
                        <div [ngClass]="!isNew ? 'mb-5' : 'my-5'">
                          <p class="text fw-bold mb-3" [ngClass]="!isNew ? 'primary' : 'font-size-24 dark'">
                            Rentas / Paquetes</p>
                          <div class="align-items-center d-flex">
                            <p class="font-size-24 fw-bold dark">
                              Mensual
                            </p>
                            <div class="form-check form-switch">
                              <input (change)="onCheck($event)" class="form-check-input" type="checkbox" />
                            </div>
                            <p class="font-size-24 fw-bold dark">
                              Anual
                            </p>
                          </div>
                        </div>

                        <div class="row justify-content-around">
                          <div *ngFor="let item of getPlansListPlan()" class="plan-options btn_plan mb-5 mx-2">
                            <p class="text fw-bold">{{item.packageName}}</p>
                            <p *ngIf="!planPeriodicity" class="subtitle">
                              {{(item.packagePrice | currency).replace(".00", "") }} <span class="small_text">MXN</span>
                            </p>
                            <p *ngIf="planPeriodicity" class="subtitle">
                              {{(item.packagePriceAnnualAmount |
                              currency).replace(".00", "") }} <span class="small_text">MXN</span></p>
                            <p class="mb-2">{{item.packageNumber == 1 ? " Gratis 30 días" :
                              "+ IVA"}}</p>
                            <button class="btn_yellow">
                              <a *ngIf="!isNew" class="nav-link" (click)="selectPlann(item.packageName)">Seleccionar</a>
                              <a *ngIf="isNew" class="nav-link" (click)="register(item.packageName)">Seleccionar</a>
                            </button>
                            <p class="my-2 px-2">Operaciones activas</p>
                            <p class="subtitle">{{item.packageOperations}}</p>
                          </div>

                          <div class="d-md-flex d-block align-items-center mb-5 dark"
                            [ngClass]="isNew ? 'col-11' : 'col-12'">
                            <p class="mini_text text-left me-md-4 me-0 mb-md-0 mb-3">
                              Plan Empresarial Personalizado para un mayor número de operaciones contáctanos aquí:
                            </p>
                            <button (click)="showDialogSendInfo()" class="btn_yellow">
                              <a class="nav-link">Personaliza</a>
                            </button>
                          </div>
                          <p class="mini_text text-left dark" [ngClass]="isNew ? 'col-11' : 'col-12'">Comienza hoy con
                            a-lender y aprovecha los planes que te ofrecemos según tus necesidades. Puedes cambiar
                            fácilmente tu plan o cancelar tu suscripción cuando quieras. Todos los paquetes incluyen las
                            funcionalidades básicas, los servicios opcionales pueden tener costo adicional.</p>
                        </div>
                        <img class="img-fluid move five" [ngClass]="isNew ? '' : 'd-none'"
                          src="/assets/images/newImages/signoFondo2.svg"
                          alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                      </div>
                    </div>
                  </div>
                </section>
              </form>

              <div class="d-flex flex-wrap gap-2 justify-content-end">
                <button *ngIf="!isNew" class="btn btn-outline-primary external-btn" routerLink="/mi-cuenta">
                  Regresar
                </button>
              </div>
            </mat-step>

            <mat-step [stepControl]="subscriptionPaymentForm">
              <ng-template matStepLabel>Método de pago</ng-template>
              <form [formGroup]="subscriptionPaymentForm" (ngSubmit)="onSubmitSubscriptionMethodPaymentForm()"
                id="card-form" class="pt-3">
                <div>
                  <td valign="top" id="x_templateHeader" style="padding: 10px 18px; background-color: rgb(10, 24, 55); background-image: none; background-repeat: no-repeat; background-position: center center; background-size: 50% auto; border-top-left-radius: 10px; border-top-right-radius: 10px; border-top: 0px; display: block; color: rgb(179, 179, 179) !important; font-family: Helvetica; font-size: 12px; line-height: 150%; text-align: left;" data-ogsc="rgb(96, 96, 96)">
                    <span style="color: white;">Pagos seguros con</span>
                    <img data-imagetype="External" src="/assets/images/logos/conekta_white.svg" alt="" style="width: 11%; border: 0; height: auto; outline: none; text-decoration: none; display: block;">
                  </td>
              </div>
                <div class="typePayment">
                  <label class="mt-2 mb-n2">Selecciona un método de pago</label>
                  <div class="card-body ">
                    <div class="row just_around_nw">
                      <ng-container *ngFor="let paymentType of methodsPayment; let i = index">
                        <div class="col-6" [ngClass]="{'selected': selectedPaymentIndex === i}"
                          (click)="selectMethodPayment(paymentType.methodNumber, i)">
                          <i class="bx {{paymentType.icon == 'bx-credit-card' ? 'bx-credit-card' : 'bx-wallet'}}"></i>
                          <h5>{{paymentType.methodName == 'Tarjeta' ? 'Tarjeta' : 'Efectivo'}}</h5>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <span class="card-errors"></span>
                <div class="row" *ngIf="paymentSpeiSelected">
                  <div class="form-group mb-3">
                    <label for="fullName">Nombre en la tarjeta*</label>
                    <input type="text" formControlName="fullName" value="" class="form-control"
                      data-conekta="card[name]" required [attr.disabled]>
                    <mat-error *ngIf="subscriptionPaymentSpeiSubmited && validateRequiredForm('fullName')">
                      {{requiredErrorMessage}}
                    </mat-error>
                  </div>
                  <div class="col-lg-5 col-md-7 mb-3">
                    <div class="form-group">
                      <label for="tarjetNumber">Número de tarjeta*</label>
                      <div class="card-js">
                        <div class="card-number-wrapper">
                          <input type="text" formControlName="tarjetNumber" [textMask]="{mask: mask}"
                            class="form-control" data-conekta="card[number]" required>
                          <div class="card-type-icon">
                            <img id="imgCard" class="img-fluid" src="">
                          </div>
                        </div>
                      </div>
                      <mat-error *ngIf="subscriptionPaymentSpeiSubmited && validateRequiredForm('tarjetNumber')">
                        {{requiredErrorMessage}} </mat-error>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-7 mb-3">
                    <div class="form-group">
                      <label for="expirationDate">Fecha vencimiento*</label>
                      <div class="card-js">
                        <div class="card-number-wrapper">
                          <input type="text" formControlName="expirationDate" [textMask]="{mask: maskExpirationDate}"
                            placeholder="MM/YY" class="form-control" (input)="updateExpirationDate($event)" required>
                        </div>
                      </div>
                      <mat-error *ngIf="subscriptionPaymentSpeiSubmited && validateRequiredForm('expirationDate')">
                        {{requiredErrorMessage}} </mat-error>
                      <mat-error *ngIf="f_subscriptionPaymentForm['expirationDate'].hasError('invalidMonth')">
                        Mes de vencimiento inválido.
                      </mat-error>
                      <mat-error *ngIf="f_subscriptionPaymentForm['expirationDate'].hasError('invalidYear')">
                        Año de vencimiento inválido.
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 mb-3 d-none">
                    <div class="form-group"> <label for="dateEndMonth">Mes vencimiento*</label>
                      <select #selectPeriod class="form-control" formControlName="dateEndMonth"
                        data-conekta="card[exp_month]" required>
                        <option value="">Seleccione</option>
                        <option value="1">01 Enero</option>
                        <option value="2">02 Febrero</option>
                        <option value="3">03 Marzo</option>
                        <option value="4">04 Abril</option>
                        <option value="5">05 Mayo</option>
                        <option value="6">06 Junio</option>
                        <option value="7">07 Julio</option>
                        <option value="8">08 Agosto</option>
                        <option value="9">09 Septiembre</option>
                        <option value="10">10 Octubre</option>
                        <option value="11">11 Noviembre</option>
                        <option value="12">12 Diciembre</option>
                      </select>
                      <mat-error *ngIf="subscriptionPaymentSpeiSubmited && validateRequiredForm('dateEndMonth')">
                        {{requiredErrorMessage}} </mat-error>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-3 mb-3 d-none">
                    <div class="form-group"> <label for="dateEndYear">Año vencimiento*</label>
                      <select #selectPeriod class="form-control" formControlName="dateEndYear"
                        data-conekta="card[exp_year]" required>
                        <option value="">Seleccione</option>
                        <option *ngFor="let item of yearList" value="{{item.key}}">
                          {{item.value}}
                      </select>
                      <mat-error *ngIf="subscriptionPaymentSpeiSubmited && validateRequiredForm('dateEndYear')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-3 mb-3">
                    <div class="form-group">
                      <label for="cvc">CVV*</label>
                      <input type="text" formControlName="cvc" class="form-control" data-conekta="card[cvc]" required
                        [textMask]="{mask: maskCVV}">
                      <mat-error *ngIf="subscriptionPaymentSpeiSubmited && validateRequiredForm('cvc')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="paymentOxxoSelected">
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="fullName">Nombre*</label>
                      <input type="text" formControlName="fullName" class="form-control" required>
                      <mat-error *ngIf="validateRequiredForm('fullName')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="email">Correo electrónico*</label>
                      <input type="text" formControlName="email" class="form-control" required>
                      <mat-error *ngIf="validateRequiredForm('email')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="email">Teléfono*</label>
                      <input [textMask]="{mask: phoneMask}" type="text" formControlName="phone" class="form-control"
                        required>
                      <mat-error *ngIf="validateRequiredForm('phone')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap gap-2 justify-content-end">
                  <button class="btn btn-outline-primary external-btn" matStepperPrevious>
                    Atrás
                  </button>
                  <button class="btn btn-primary external-btn" type="submit"
                    *ngIf="paymentSpeiSelected || paymentOxxoSelected">
                    {{ paymentSpeiSelected ? 'Continuar' : 'Generar' }}
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step>
              <ng-template matStepLabel>Resumen</ng-template>
              <div *ngIf="paymentSpeiSelected">
                <form (ngSubmit)="onSubmitSubscriptionResumenForm()">
                  <span class="card-errors"></span>
                  <div mat-dialog-content>
                    <div class="mb-4">
                      <label class="mb-2">
                        Resumen de compra
                      </label>
                      <div class="row">
                        <div class="col-sm-6">
                          <address>
                            <strong>Nombre del tarjetahabiente:</strong>
                            {{f_subscriptionPaymentForm.fullName.value}}
                          </address>
                        </div>
                        <div class="col-sm-6">
                          <address>
                            <strong>Método de pago:</strong> {{paymentTypeSelected}}
                            ****{{numberLast}}
                          </address>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table-nowrap table">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Plan</th>
                              <th>Fecha inicio</th>
                              <th>Fecha fin</th>
                              <th>Precio plan</th>
                              <th>Descuento</th>
                              <th>Saldo a favor</th>
                              <th>Monto neto</th>
                              <th>IVA</th>
                              <th class="text-end">Monto a pagar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>{{f_subscriptionPaymentForm.detail.value}}
                                {{planOperations}}
                              </td>
                              <td>{{subscripcionStartDate | date}}</td>
                              <td>{{subscripcionEndDate | date}}</td>
                              <td class="text-end">{{pricePlan | currency}}</td>
                              <td class="text-end">{{discount | currency}}</td>
                              <td class="text-end">{{positiveBalance | currency}}</td>
                              <td class="text-end">{{netAmountPay | currency}}</td>
                              <td class="text-end">{{priceTax | currency}}</td>
                              <td class="text-end">{{totalAmount | currency}}</td>
                            </tr>
                            <tr>
                              <td colspan="9" class="border-0 text-end">
                                <strong>Total</strong>
                              </td>
                              <td class="border-0 text-end">{{totalAmount | currency}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="d-flex flex-wrap gap-2 justify-content-end">
                      <button class="btn btn-outline-primary external-btn" matStepperPrevious>
                        Atrás
                      </button>
                      <button class="btn btn-primary external-btn" type="submit">
                        Pagar
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div *ngIf="paymentOxxoSelected">
                <div mat-dialog-content>
                  <div id="contentPaymentOxxo" [innerHtml]="html">
                  </div>
                  <div class="d-flex flex-wrap gap-2 justify-content-end">
                    <button class="btn btn-outline-primary external-btn" routerLink="/mi-cuenta">
                      Cerrar
                    </button>
                    <button class="btn btn-outline-primary external-btn" (click)="print()">
                      Imprimir
                    </button>
                  </div>
                </div>
              </div>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
      </div>
    </div>

    <div class="col-lg-4 mt-lg-5 mt-3" *ngIf="!isNew">
      <div class="card detail_cart">
        <div class="card-body">
          <h5 class="fw-bold mb-2 primary">Resumen de compra</h5>
          <hr>

          <div class="d-flex justify-content-between">
            <h5 class="text-muted">Subtotal</h5>
            <h5 class="mb-0 text-muted">{{netAmountPay | currency}}
              <span class="small_text">MXN</span>
            </h5>
          </div>

          <div class="d-flex justify-content-between">
            <h5 class="text-muted">IVA</h5>
            <h5 class="mb-0 text-muted">{{priceTax | currency}}
              <span class="small_text">MXN</span>
            </h5>
          </div>
          <hr>

          <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">Monto a pagar</h5>
            <h5 class="mb-0 fw-bold">{{totalAmount | currency}}
              <span class="small_text">MXN</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>