import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { KeyValue } from '@angular/common';
import { MatSelect } from '@angular/material/select';

export interface FormRequestElement {
	conditionName: string;
	value: string;
	enabled: boolean;
	required: boolean;
}

export interface DialogData {
	number: number,
	warranty: string,
	warrantysForm: any;
	loanRequestId: string;
	warrantysCompleted: boolean;
	loanRequestStatus: number;
}

@Component({
	selector: 'app-dialog-warranty-other-details',
	templateUrl: './dialog-warranty-other-details.component.html',
	styleUrls: ['./dialog-warranty-other-details.component.scss']
})

export class DialogWarrantyOtherDetailsComponent implements OnInit {
	mode = "";
	otherGuaranteeJsonForm: FormGroup = this.formBuilder.group({});	
	elementsFormRequest: any;
	submit = false;	
	dataSourceformOtherJson = new MatTableDataSource<FormRequestDTO>([]);
	elementsFormOther: any;
	@ViewChild('selectOther') selectOther: MatSelect;

	columnsformOther: string[] = [
		'conditionName',
		'value',
		'required'
	];

	FormrequestJson: FormRequestDTO[];

	panelOtherState = true;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService,
		private alertGlobalService: AlertGlobalService,
		private dialogRef: MatDialogRef<DialogWarrantyOtherDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data:DialogData
		) {		
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get f8() { return this.otherGuaranteeJsonForm.controls; }

	ngOnInit(): void {
		this.createOtherGuaranteeForm();
	}

	getIsDisabledSave() {
		if (this.data.loanRequestStatus == 9 || this.data.loanRequestStatus == 4) {
			return true;
		} else {
			return false;
		}
	}

	createOtherGuaranteeForm() {
		this.FormrequestJson = this.data.warrantysForm;
		var formJson = this.FormrequestJson;
		
		for (var key in formJson) {
			if (this.isLender) {
				if (formJson[key]['required']) {
					this.otherGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
				} else {
					this.otherGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			} else {
				if (formJson[key]['required']) {
					this.otherGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
				} else {
					this.otherGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			}

			if (this.getIsDisabledSave()) {
				this.f8["value-" + formJson[key]['conditionName']].disable();
			}
		}
		
		const ELEMENT_DATA: (FormRequestElement)[] = this.data.warrantysForm;
		this.elementsFormOther = ELEMENT_DATA;

		this.dataSourceformOtherJson.data = ELEMENT_DATA as FormRequestDTO[];
	}

	validateRequiredFormF8(formControlName: string): boolean {
		if(this.f8[formControlName].errors !== null && this.f8[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	isGroup(index, item): boolean{
		return item.isGroupBy;
	}

	onSubmitFormOtherdJson() {
		// if (this.otherGuaranteeJsonForm.invalid) {
		// 	return;
		// }
		
		var warrantysForm: WarrantysForm;
		var otherGuaranteeAnswer: FormRequestAnswer[] = [];

		for (let val of this.elementsFormOther){
			otherGuaranteeAnswer.push({ 
				conditionName: val["conditionName"], 
				required: val["required"],
				value: this.f8["value-" + val["conditionName"]].value + ""
			});
		}

		warrantysForm = {
			number: this.data.number,
			warranty: this.data.warranty,
			warrantyForm: otherGuaranteeAnswer,
			warrantyFormCompleted: 0
		}

		this.loanrequestService.UpdateOtherGuaranteeJsonForm(this.data.loanRequestId, warrantysForm).subscribe((result) => {    
			this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			this.submit = true
			this.dialogRef.close();
		});
	}

	checkRequiredOther(e, conditionName) {
		if (!this.isLender) {
			if (e.checked) {
				this.f8["value-" + conditionName].setValidators([Validators.required]);
				this.f8["value-" + conditionName].updateValueAndValidity();
			} else {
				this.f8["value-" + conditionName].clearValidators();
				this.f8["value-" + conditionName].updateValueAndValidity();
			}
		}
	}

	validateFormrequestJson(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	panelOtherOpen(e) {
		this.panelOtherState = e;
	}

	onChange(val: string) {
		this.selectOther.value = 0
	}
}
