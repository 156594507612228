import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EconomicFlowIncome, LoanRequestOptionsData, EconomicFlowExpense } from 'src/app/models/loanrequest/loanrequest.model';
import { GlobalLists } from 'src/app/global/global-data';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { KeyValue } from '@angular/common';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';

@Component({
	selector: 'app-dialog-economic-flow',
	templateUrl: './dialog-economic-flow.component.html',
	styleUrls: ['./dialog-economic-flow.component.scss']
})

export class DialogEconomicFlowComponent implements OnInit {
	submit = false;

	economicIncomeColumns = [
		'incomeType',
		'amount',
		'frequency',
		'buttonRemove'
	];

	economicExpenseColumns = [
		'expenseType',
		'amount',
		'frequency',
		'buttonRemove'
	];

	submitedExpense = false
	submitedIncome = false

	panelEconomicFlowState = false;
	panelIncomeState = false;
	panelExpenseState = false;
	incomeList = GlobalLists.incomeList;
	expenseList = GlobalLists.expenseList;
	frecuencyList: KeyValue<number, string>[] = [];
	currencyMask = GlobalLists.currencyMask;
	
	economicFlowIncomeForm: FormGroup;
	economicFlowExpenseForm: FormGroup;
	economicFlowIncome = <EconomicFlowIncome[]>[];
	economicFlowExpense = <EconomicFlowExpense[]>[];
	economicFlowData: LoanRequestOptionsData;
	dsEconomicFlowIncomeData = new MatTableDataSource<EconomicFlowIncome>(this.economicFlowIncome);
	dsEconomicFlowExpenseData = new MatTableDataSource<EconomicFlowExpense>(this.economicFlowExpense);

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService,
		private typeofperiodService: TypeofperiodService
		) {		
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get fEconomicFlowIncome() { return this.economicFlowIncomeForm.controls; }
	get fEconomicFlowExpense() { return this.economicFlowExpenseForm.controls; }
	
	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;

	ngOnInit(): void {
		this.createEconomicFlowIncomeForm();
		this.createEconomicFlowExpenseForm();
		this.getTypeOfPeriodList();

		this.loanrequestService.getEconomicFlow(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.economicFlowIncome = result.economicFlowIncome;
				this.economicFlowExpense = result.economicFlowExpense;
	
				this.dsEconomicFlowIncomeData = new MatTableDataSource<EconomicFlowIncome>(this.economicFlowIncome);
				this.dsEconomicFlowExpenseData = new MatTableDataSource<EconomicFlowExpense>(this.economicFlowExpense);

				this.updateEconomicFlowData();

				if (this.completed) {
					this.dr = true;
				}
			}
		});
	}

	getTypeOfPeriodList() {
		this.typeofperiodService.getList()
		.subscribe((list)=>{
			this.frecuencyList = list;
		});
	}

	createEconomicFlowIncomeForm() {
		this.economicFlowIncomeForm = this.formBuilder.group({
			incomeType: ['', Validators.required],      
			amount: [0, Validators.required],      
			frequency: ['', Validators.required]
		});
	}

	createEconomicFlowExpenseForm() {
		this.economicFlowExpenseForm = this.formBuilder.group({
			expenseType: ['', Validators.required],      
			amount: [0, Validators.required],      
			frequency: ['', Validators.required]
		});
	}

	onSubmitEconomicFlowIncome() {
		this.submitedIncome = true;

		if (this.economicFlowIncomeForm.invalid || this.getIsDisabledSave()) {
		  return;
		}
	
		this.addEconomicFlowIncome();
	}
	
	onDeleteEconomicFlowIncome(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.economicFlowIncome.splice(index, 1);
		this.dsEconomicFlowIncomeData = new MatTableDataSource<EconomicFlowIncome>(this.economicFlowIncome);

		this.updateEconomicFlowData();
	}
	
	addEconomicFlowIncome(): void {
		this.economicFlowIncome.push(<EconomicFlowIncome>{
			incomeType: this.fEconomicFlowIncome['incomeType'].value,
			amount: parseFloat(this.fEconomicFlowIncome['amount'].value.toString().replace("$ ", "").replace(",", "")),
			frequency: this.fEconomicFlowIncome['frequency'].value,
		});

		this.dsEconomicFlowIncomeData = new MatTableDataSource<EconomicFlowIncome>(this.economicFlowIncome);
		this.economicFlowIncomeForm.reset();

		this.updateEconomicFlowData();
	}

	onSubmitEconomicFlowExpense() {
		this.submitedExpense = true;

		if (this.economicFlowExpenseForm.invalid || this.getIsDisabledSave()) {
		  return;
		}
	
		this.addEconomicFlowExpense();
	}
	
	onDeleteEconomicFlowExpense(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.economicFlowExpense.splice(index, 1);
		this.dsEconomicFlowExpenseData = new MatTableDataSource<EconomicFlowExpense>(this.economicFlowExpense);

		this.updateEconomicFlowData();
	}
	
	addEconomicFlowExpense(): void {
		this.economicFlowExpense.push(<EconomicFlowExpense>{
			expenseType: this.fEconomicFlowExpense['expenseType'].value,
			amount: parseFloat(this.fEconomicFlowExpense['amount'].value.toString().replace("$ ", "").replace(",", "")),
			frequency: this.fEconomicFlowExpense['frequency'].value,
		});

		this.dsEconomicFlowExpenseData = new MatTableDataSource<EconomicFlowExpense>(this.economicFlowExpense);
		this.economicFlowExpenseForm.reset();

		this.updateEconomicFlowData();
	}

	panelEconomicFlowOpen(e) {
		this.panelEconomicFlowState = e;
	}


	panelIncomeOpen(e) {
		this.panelIncomeState = e;
	}

	panelExpenseOpen(e) {
		this.panelExpenseState = e;
	}

	updateEconomicFlowData() {
		var loanRequestAdvanceOptionsData = <LoanRequestOptionsData>{
			economicFlowIncome: this.economicFlowIncome,
			economicFlowExpense: this.economicFlowExpense
		};
		
		this.economicFlowData = loanRequestAdvanceOptionsData;
	}

	getEconomicFlowIncomeTotal() {
		// return this.dsEconomicFlowIncomeData.data.map(x => x.amount).reduce((acc, value) => acc + value, 0);
		
		return this.sumTotal(this.dsEconomicFlowIncomeData);
	}

	sumTotal(matTableDataSource: any) {
		var sum = 0;

		matTableDataSource.data.forEach(element => {
			switch ( element.frequency ) {
				case "Diario":
					sum += element.amount * 30;
					break;
				case "Semanal":
					sum += element.amount * 4;
					break;
				case "Catorcenal":
					sum += element.amount * 2;
					break;
				case "Quincenal":
					sum += element.amount * 2;
					break;
				case "Mensual":
					sum += element.amount / 1;
					break;
				case "Bimestral":
					sum += element.amount / 2;
					break;
				case "Trimestral":
					sum += element.amount / 3;
					break;
				case "Semestral":
					sum += element.amount / 6;
					break;
				case "Anual":
					sum += element.amount / 12;
					break;
				default: 
					break;
			 }
		});
		
		return sum;	
	}

	getEconomicFlowExpenseTotal() {
		// return this.dsEconomicFlowExpenseData.data.map(x => x.amount).reduce((acc, value) => acc + value, 0);
		return this.sumTotal(this.dsEconomicFlowExpenseData);
	}

	validateRequiredFormExpense(formControlName: string): boolean {
		if (this.fEconomicFlowExpense[formControlName] == null) {
			return;
		}

		if(this.fEconomicFlowExpense[formControlName].errors !== null && this.fEconomicFlowExpense[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateRequiredFormIncome(formControlName: string): boolean {
		if (this.fEconomicFlowIncome[formControlName] == null) {
			return;
		}

		if(this.fEconomicFlowIncome[formControlName].errors !== null && this.fEconomicFlowIncome[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	getIsDisabledSave() {
		if (this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) {
			return true;
		} else {
			return false;
		}
	}
}
