<div>
    <h5>
        Guardar datos garantía prendaria
    </h5>

    <form [formGroup]="pledgeGuaranteeJsonForm" (ngSubmit)="onSubmitFormPledgeJson()">
        <div class="subtittle">
            Escribe o selecciona tus datos de garantía prendaria
        </div>
        <mat-accordion>
            <mat-expansion-panel [expanded]="panelPledgeOpenState" class="mb-4"
                (opened)="panelPledgeOpen(true)" (closed)="panelPledgeOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos de garantía prendaria</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-FornitureType" class="">Tipo bien mueble<span
                                    *ngIf="validateFormrequestJson('FornitureType')">*</span></label>
                            <select #selectFornitureType class="form-control"
                                formControlName="value-FornitureType"
                                (change)="onChangeSelectFornitureType(selectFornitureType.value)"
                                [ngClass]="validateRequiredFormF6('value-FornitureType') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of fornitureTypeList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-FornitureOther" class="">Otro bien mueble<span
                                    *ngIf="validateFormrequestJson('FornitureOther')">*</span></label>
                            <input type="text" formControlName="value-FornitureOther" class="form-control"
                                [ngClass]="validateRequiredFormF6('value-FornitureOther') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="form-group">
                            <label for="value-Description" class="">Descripción<span
                                    *ngIf="validateFormrequestJson('Description')">*</span></label>
                            <input type="text" formControlName="value-Description" class="form-control"
                                [ngClass]="validateRequiredFormF6('value-Description') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Year" class="">Año<span
                                    *ngIf="validateFormrequestJson('Year')">*</span></label>
                            <input type="number" formControlName="value-Year" class="form-control"
                                [ngClass]="validateRequiredFormF6('value-Year') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Brand" class="">Marca<span
                                    *ngIf="validateFormrequestJson('Brand')">*</span></label>
                            <input type="text" formControlName="value-Brand" class="form-control"
                                [ngClass]="validateRequiredFormF6('value-Brand') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Model" class="">Modelo<span
                                    *ngIf="validateFormrequestJson('Model')">*</span></label>
                            <input type="text" formControlName="value-Model" class="form-control"
                                [ngClass]="validateRequiredFormF6('value-Model') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-NumberSerie" class="">Número serie<span
                                    *ngIf="validateFormrequestJson('NumberSerie')">*</span></label>
                            <input type="text" formControlName="value-NumberSerie" class="form-control"
                                [ngClass]="validateRequiredFormF6('value-NumberSerie') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Owner" class="">Propietario bien<span
                                    *ngIf="validateFormrequestJson('Owner')">*</span></label>
                            <select #selectOwner class="form-control" formControlName="value-Owner"
                                (change)="onChangeSelectOwner(selectOwner.value)"
                                [ngClass]="validateRequiredFormF6('value-Owner') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of ownerList" value="{{item.key}}">{{item.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="form-group">
                            <label for="value-OwnerName" class="">Nombre completo propietario<span
                                    *ngIf="validateFormrequestJson('OwnerName')">*</span></label>
                            <input type="text" formControlName="value-OwnerName" class="form-control"
                                [ngClass]="validateRequiredFormF6('value-OwnerName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-3">
                        <div class="form-group">
                            <label for="value-RelationShip">Relación con Solicitante <span
                                    *ngIf="validateFormrequestJson('RelationShip')">*</span></label>
                            <select #contactRelationship class="form-control"
                                formControlName="value-RelationShip"
                                [ngClass]="validateRequiredFormF6('value-RelationShip') ? 'is-invalid' : ''"
                                required>
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of getContactRelationshipList('0')"
                                    value="{{item.value}}">{{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-ProofOfPaymentType" class="">Tipo comprobante<span
                                    *ngIf="validateFormrequestJson('ProofOfPaymentType')">*</span></label>
                            <select #selectProofOfPaymentType class="form-control"
                                formControlName="value-ProofOfPaymentType"
                                [ngClass]="validateRequiredFormF6('value-ProofOfPaymentType') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of poofOfPaymentTypeList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-InvoiceNumber"
                                class="">{{getInvoiceNumberText(selectProofOfPaymentType.value)}}<span
                                    *ngIf="validateFormrequestJson('InvoiceNumber')">*</span></label>
                            <input type="number" formControlName="value-InvoiceNumber" class="form-control"
                                [ngClass]="validateRequiredFormF6('value-InvoiceNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-InvoiceDate"
                                class="">{{getInvoiceDateText(selectProofOfPaymentType.value)}}<span
                                    *ngIf="validateFormrequestJson('InvoiceDate')">*</span></label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-InvoiceDate" type="date"
                                [ngClass]="validateRequiredFormF6('value-InvoiceDate') ? 'is-invalid' : ''" />
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-InvoiceIssuer" class="">Emisor factura<span
                                    *ngIf="validateFormrequestJson('InvoiceIssuer')">*</span></label>
                            <input type="text" formControlName="value-InvoiceIssuer" class="form-control"
                                [ngClass]="validateRequiredFormF6('value-InvoiceIssuer') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Conditions" class="">Condiciones<span
                                    *ngIf="validateFormrequestJson('Conditions')">*</span></label>
                            <select #selectOwner class="form-control" formControlName="value-Conditions"
                                [ngClass]="validateRequiredFormF6('value-Conditions') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of conditionsList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Debts" class="">Adeudos<span
                                    *ngIf="validateFormrequestJson('Debts')">*</span></label>
                            <select #selectOwner class="form-control" formControlName="value-Debts"
                                [ngClass]="validateRequiredFormF6('value-Debts') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of debtsList" value="{{item.value}}">{{item.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-InvoiceValue" class="">Valor de factura<span
                                    *ngIf="validateFormrequestJson('InvoiceValue')">*</span></label>
                            <input type="text" name="value-InvoiceValue"
                                formControlName="value-InvoiceValue" class="form-control text-right"
                                [textMask]="{mask: currencyMask}"
                                [ngClass]="validateRequiredFormF6('value-InvoiceValue') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-EstimatedMarketValue" class="">Valor de mercado estimado<span
                                    *ngIf="validateFormrequestJson('EstimatedMarketValue')">*</span></label>
                            <input type="text" name="value-EstimatedMarketValue"
                                formControlName="value-EstimatedMarketValue" class="form-control text-right"
                                [textMask]="{mask: currencyMask}"
                                [ngClass]="validateRequiredFormF6('value-EstimatedMarketValue') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" mat-dialog-close [mat-dialog-close]="submit">
                Cerrar
            </button>
            <button type="submit" class="btn btn-primary external-btn" *ngIf="!getIsDisabledSave()">
                Guardar
            </button>
        </div>
    </form>
</div>