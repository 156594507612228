import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoanPaymentType } from 'src/app/enums/loanpaymentType.enum';
import { Formats, GlobalLists } from 'src/app/global/global-data';
import { LoanpaymentService } from 'src/app/services/loanpayment/loanpayment.service';
import { HtmlViewerComponent } from 'src/app/views/html-viewer/html-viewer.component';
import { LoanPayment } from '../../models/loanpayment/loanpayment.model';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';

@Component({
	selector: 'app-dialog-loanpayment',
	templateUrl: './dialog-loanpayment.component.html',
	styleUrls: ['./dialog-loanpayment.component.scss']
})
export class DialogLoanpaymentComponent implements OnInit {

	@Input() loanId: string;
	@Input() fromCustomerId: string;
	@Input() toCustomerId: string;
	@Input() lenderEmail: string;
	@Input() borrowerMail: string;
	@Input() startDate: Date;

	startDateString: string;
	dateNow: string = this.datePipe.transform(new Date(), "yyyy-MM-dd");

	wayToPayList = GlobalLists.wayToPayList;
	tittleWayToPay = "Otra forma de pago";
	currencyDecimalMask = GlobalLists.currencyDecimalMask;

	constructor(private formBuilder: FormBuilder,
		private loanpaymentService: LoanpaymentService,
		private router: Router,
		private datePipe: DatePipe,
		private dialog: MatDialog) {
	}

	get f1() { return this.doPaymentForm.controls; }
	doPaymentForm: FormGroup;
	submitted = false;
	requiredErrorMessage: string = "Este campo es requerido.";

	createDoPaymentForm() {
		this.startDateString = this.datePipe.transform(this.startDate, "yyyy-MM-dd");

		this.doPaymentForm = this.formBuilder.group({
			amount: ['', Validators.required],
			date: ['', Validators.required],
			methodOfPaymentId: ['', Validators.required],
			methodOfPaymentOther: ['', Validators.required]
		});

		this.doPaymentForm.controls['methodOfPaymentOther'].disable();
	}

	ngOnInit(): void {
		this.createDoPaymentForm();
	}

	getPaymentTable(): void {

	}

	getCustomerData(): void {
		if (this.fromCustomerId) {

		}
	}

	onSubmit() {
		this.submitted = true;
		if (this.doPaymentForm.invalid) {
			return;
		}

		this.doPayment();
	}

	doPayment() {
		this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Registrar pago',
				message: '¿Deseas registrar este pago?'
			}
		}).afterClosed().subscribe((confirmado: Boolean) => {
			if (confirmado) {
				var loanAmount: string = this.f1['amount'].value;
				loanAmount = loanAmount.replace("$", "").replace(/,/g, "");

				const loanPayment = <LoanPayment>{
					amount: parseFloat(loanAmount),
					loanId: this.loanId,
					fromCustomerId: this.fromCustomerId,
					toCustomerId: this.toCustomerId,
					type: LoanPaymentType.LOAN_PAYMENT,
					paymentDate: this.f1['date'].value as Date,
					wayToPay: parseInt(this.f1['methodOfPaymentId'].value),
					WaytoOther: this.f1['methodOfPaymentOther'].value,
					sendProofOfPaymentBorrower: confirmado
				};

				this.loanpaymentService.createLoanPayment(loanPayment).subscribe((result) => {
					const dialogRef = this.dialog.open(HtmlViewerComponent, {
						autoFocus: false,
						width: '80%',
						disableClose: true
					});

					let instance = dialogRef.componentInstance;

					instance.documentGeneratorType = 3;
					instance.htmlString = "" + result;
					instance.lenderEmail = this.lenderEmail;
					instance.borrowerMail = this.borrowerMail;
					instance.body = "" + result;

					dialogRef.afterClosed().subscribe((result) => {
						// this.router.navigate(['prestamos/' + this.loanId]);
						window.location.reload();
					});
				});
			}
		});
	}

	validateRequiredForm(formControlName: string): boolean {
		if (this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
			return true;
		}
		return false;
	}

	onChangeMethodOfPayment(value: string): void {
		this.f1['methodOfPaymentOther'].patchValue('');
		if (value == "6" || value == "11") {
			this.tittleWayToPay = "Descripción";
			this.f1['methodOfPaymentOther'].enable();
		} else {
			this.tittleWayToPay = "Otra forma de pago";
			this.f1['methodOfPaymentOther'].disable();
		}
	}
}