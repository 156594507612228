import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent implements OnInit {
  isLender = false;

  constructor(private route: ActivatedRoute,
              private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.route.snapshot.paramMap.get('tabName');
    this.isLender = this.authService.currentUserModeLenderValue;    
  }

}
