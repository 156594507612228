import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentGeneratorService {

  constructor(private apiService: ApiService) { }

  getContract(loanRequestId: string) {
    return this.apiService.get('documentgenerator', 'contract', loanRequestId).pipe(map(response => {
      return response.data;
    }));
  }

  getPromissoryNote(loanRequestId: string) {
    return this.apiService.get('documentgenerator', 'promissorynote', loanRequestId).pipe(map(response => {
      return response.data;
    }));
  }

  download(loanRequestId: string) {
    return this.apiService.get('documentgenerator', 'download', loanRequestId).pipe(map(response => {
      return response.data;
    }));
  }
}
