import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import * as $ from 'jquery';
import { CalculatePrice, SendCalculatePriceBalance, Subscriptionpayment } from 'src/app/models/subscriptionpayment/subscriptionpayment.model';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { plansDTO } from 'src/app/models/plans/plans.dto.model';
import { MethodsPaymentDTO } from 'src/app/models/methodspayment/methodspayment.dto.model';
import { ConektaTokenResponse } from 'src/app/models/conektatokenresponse/conektatokenresponse.model';
import { KeyValue } from '@angular/common';
import { PeriodsService } from 'src/app/services/periods/periods.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GlobalLists } from 'src/app/global/global-data';
import { SendInfoSuscriptionsComponent } from '../send-info-suscriptions/send-info-suscriptions.component';

export interface plansRowsElement {
	detail: String;
}

@Component({
	selector: 'app-balance-reload',
	templateUrl: './balance-reload.component.html',
	styleUrls: ['./balance-reload.component.scss']
})

export class BalanceReloadComponent implements OnInit {
	@Input() action: string | null = "";
	@ViewChild('stepperBalance') stepperBalance: MatStepper;
	subscriptionTypeForm: FormGroup;
	balanceMethodPaymentForm: FormGroup;

	planList: plansDTO[] = [];
	calculatePrice: CalculatePrice;
	methodsPayment: MethodsPaymentDTO[] = [];
	paymentSpeiSelected: boolean = false;
	paymentOxxoSelected: boolean = false;
	paymentTransfer: boolean = false;
	panelPaymentDataOpenState = true;
	panelCardPaymentOpenState = true;
	requiredErrorMessage: string = "Este campo es requerido.";
	balanceMethodPaymentSubmited = false;
	email = "";
	phoneCustomerConekta: string;
	idCustomerConekta: string;
	phoneMask = GlobalLists.phoneMask;
	tokenGenerated: string;
	numberLast: string;
	html: SafeHtml;
	paymentTypeSelected: string;
	selectedButton: any;
	submmitedAmountPersonalized = false;
	selectedPaymentIndex: number;

	inputFilled: boolean = false;

	loanAmount: any;
	f_loanAmountForm: any;
	currencyMask = GlobalLists.currencyMask;

	mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
	maskCVV = [/\d/, /\d/, /\d/, /\d/];
	maskExpirationDate = [/\d/, /\d/, '/', /\d/, /\d/];

	yearList: KeyValue<number, number>[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private subscriptionpaymentService: SubscriptionpaymentService,
		private customerService: CustomerService,
		private authService: AuthenticationService,
		private alertGlobalService: AlertGlobalService,
		private sanitizer: DomSanitizer,
		private dialog: MatDialog,
		private changeDetector: ChangeDetectorRef,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.startSubscriptionTypeForm();
		this.startBalanceMethodPaymentForm();

		this.action = this.route.snapshot.paramMap.get('action');

		this.getCustomer()
		this.getMethodsPayment();
		this.suscribeValueChanges();

		this.email = this.authService.currentUserValue.username;
		this.f_balanceMethodPaymentForm['email'].patchValue(this.email);

		this.getPlans();
	}

	get f_subscriptionTypeForm() { return this.subscriptionTypeForm.controls; }
	get f_balanceMethodPaymentForm() { return this.balanceMethodPaymentForm.controls; }

	get f_subscriptionTypeFormProp() { return Object.getOwnPropertyNames(this.f_subscriptionTypeForm); }

	ngOnInit(): void {
		localStorage.setItem("_conekta_publishable_key", environment.conektakey);
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	startSubscriptionTypeForm() {
		this.subscriptionTypeForm = this.formBuilder.group({
			subscriptionType: ['', [Validators.required]],
			completed: [false, Validators.requiredTrue],
			amountPersonalized: [''],
			amountPersonalizedAmount: [0]
		});
	}

	startBalanceMethodPaymentForm() {
		this.balanceMethodPaymentForm = this.formBuilder.group({
			subscriptionTypePayment: [null, [Validators.required]],
			fullName: [{ value: "", disabled: true }, Validators.required],
			tarjetNumber: ["", Validators.required],
			cvc: ["", Validators.required],
			expirationDate: ["", [Validators.required, this.validateExpirationDate.bind(this)]],
			dateEndMonth: ["", Validators.required],
			dateEndYear: ["", Validators.required],
			email: [{ value: this.email, disabled: true }, Validators.required],
			phone: [{ value: this.phoneCustomerConekta, disabled: false }, Validators.required],
			detail: ["", Validators.required],
			completed: [false, Validators.requiredTrue]
		});
	}

	getPlans() {
		this.subscriptionpaymentService.getPlansBalance().subscribe((data) => {
			this.planList = data;
		})
	}

	selectPlan(column: string, personalized: boolean) {
		this.selectedButton = this.planList.find(x => x.packageName == column);

		var packageNumber = this.planList.find(x => x.packageName == column).packageNumber;
		this.f_subscriptionTypeForm['subscriptionType'].patchValue(packageNumber);

		let calculatePrice = <SendCalculatePriceBalance>{
			planId: packageNumber
		};

		if (personalized) {
			calculatePrice.amountPersonalized = parseFloat(this.f_subscriptionTypeForm['amountPersonalized'].value.toString().replace("$", "").replace(",", "").trim());
		} else {
			this.f_subscriptionTypeForm['amountPersonalizedAmount'].clearValidators();
			this.f_subscriptionTypeForm['amountPersonalized'].patchValue(null);
			this.f_subscriptionTypeForm['amountPersonalizedAmount'].patchValue(null);
			this.submmitedAmountPersonalized = false;
		}

		this.f_subscriptionTypeForm['completed'].patchValue(true);
		this.f_balanceMethodPaymentForm['detail'].patchValue(column);

		if (!this.subscriptionTypeForm.invalid) {
			this.subscriptionpaymentService.getCalculatePlanPriceBalance(calculatePrice).subscribe(
				(data) => {
					this.calculatePrice = data;

					this.selectMethodPayment(1, 0);

					this.f_subscriptionTypeForm['completed'].patchValue(true);
				}
			);
		}
	}

	getPlanList() {
		return this.planList.filter(x => x.packageNumber != 5);
	}

	showDialogSendInfo() {
		let config = new MatDialogConfig();
		config = {
			width: '80%'
		};
		const dialog = this.dialog.open(SendInfoSuscriptionsComponent, config);
	}

	selectMethodPayment(methodNumber: number, index: number) {
		this.selectedPaymentIndex = index;
		this.f_balanceMethodPaymentForm['subscriptionTypePayment'].patchValue(methodNumber);
		this.setPaymentTypeSelected(methodNumber)
	}

	getMethodsPayment() {
		this.subscriptionpaymentService.getMethodsPayment().subscribe((data) => {
			this.methodsPayment = data.filter(x => x.enabled == true);
		})
	}

	validateRequiredForm(formControlName: string): boolean {
		if (this.f_balanceMethodPaymentForm[formControlName].value == false) {
			return true;
		}
		return false;
	}

	onSubmitSubscriptionTypeForm() {
		if (this.subscriptionTypeForm.invalid) {
			this.f_subscriptionTypeForm['completed'].patchValue(false);
			return;
		}

		var currencyYear = new Date().getFullYear();

		for (let i = 0; i <= 10; i++) {
			this.yearList.push(
				{
					key: currencyYear, value: currencyYear
				}
			)

			currencyYear++;
		}

		this.stepperBalance.next();
	}

	onSubmitBalanceMethodPaymentForm() {
		this.balanceMethodPaymentSubmited = true;

		if (this.paymentSpeiSelected) {
			this.setConektaTokenResponse();
		} else if (this.paymentOxxoSelected) {
			this.onSubmitSubscriptionPaymentOxxoForm();
		}else if(this.paymentTransfer){
			this.onSubmitSubscriptionPaymentTransfer();
		}
	}

	setPaymentTypeSelected(option: number) {
		this.paymentTypeSelected = this.methodsPayment.find(x => x.methodNumber == option).methodName

		if (option == 1) {
			this.paymentSpeiSelected = true;
			this.paymentOxxoSelected = false;

			this.f_balanceMethodPaymentForm['fullName'].setValidators([Validators.required]);
			this.f_balanceMethodPaymentForm['tarjetNumber'].setValidators([Validators.required]);
			this.f_balanceMethodPaymentForm['tarjetNumber'].patchValue("");
			this.f_balanceMethodPaymentForm['cvc'].setValidators([Validators.required]);
			this.f_balanceMethodPaymentForm['cvc'].patchValue("");
			this.f_balanceMethodPaymentForm['dateEndMonth'].setValidators([Validators.required]);
			this.f_balanceMethodPaymentForm['dateEndMonth'].patchValue("");
			this.f_balanceMethodPaymentForm['dateEndYear'].setValidators([Validators.required]);
			this.f_balanceMethodPaymentForm['dateEndYear'].patchValue("");
			this.f_balanceMethodPaymentForm['completed'].patchValue(false);
		} else if (option == 2) {
			this.paymentSpeiSelected = false;
			this.paymentOxxoSelected = true;

			this.f_balanceMethodPaymentForm['fullName'].clearValidators();
			this.f_balanceMethodPaymentForm['tarjetNumber'].clearValidators();
			this.f_balanceMethodPaymentForm['tarjetNumber'].patchValue("");
			this.f_balanceMethodPaymentForm['cvc'].clearValidators();
			this.f_balanceMethodPaymentForm['cvc'].patchValue("");
			this.f_balanceMethodPaymentForm['dateEndMonth'].clearValidators();
			this.f_balanceMethodPaymentForm['dateEndMonth'].patchValue("");
			this.f_balanceMethodPaymentForm['dateEndYear'].clearValidators();
			this.f_balanceMethodPaymentForm['dateEndYear'].patchValue("");
			const expirationDateControl = this.balanceMethodPaymentForm.get('expirationDate');
			expirationDateControl.clearValidators();
			expirationDateControl.updateValueAndValidity();
			this.f_balanceMethodPaymentForm['completed'].patchValue(false);
		}else if (option == 4) {
			this.paymentTransfer = true;
			this.paymentSpeiSelected = false;
			this.paymentOxxoSelected = false;

			this.f_balanceMethodPaymentForm['fullName'].clearValidators();
			this.f_balanceMethodPaymentForm['tarjetNumber'].clearValidators();
			this.f_balanceMethodPaymentForm['tarjetNumber'].patchValue("");
			this.f_balanceMethodPaymentForm['cvc'].clearValidators();
			this.f_balanceMethodPaymentForm['cvc'].patchValue("");
			this.f_balanceMethodPaymentForm['dateEndMonth'].clearValidators();
			this.f_balanceMethodPaymentForm['dateEndMonth'].patchValue("");
			this.f_balanceMethodPaymentForm['dateEndYear'].clearValidators();
			this.f_balanceMethodPaymentForm['dateEndYear'].patchValue("");
			const expirationDateControl = this.balanceMethodPaymentForm.get('expirationDate');
			expirationDateControl.clearValidators();
			expirationDateControl.updateValueAndValidity();
			this.f_balanceMethodPaymentForm['completed'].patchValue(false);
		}

	}

	setConektaTokenResponse() {
		this.f_balanceMethodPaymentForm['completed'].patchValue(true);

		if (this.balanceMethodPaymentForm.invalid) {
			this.f_balanceMethodPaymentForm['completed'].patchValue(false);
			return;
		}

		if (this.subscriptionTypeForm.invalid) {
			return;
		}

		var conektaSuccessResponseHandler = function (token) { }

		var conektaErrorResponseHandler = function (err) { };

		var $form = $("#card-form")

		var xmlHttp = new XMLHttpRequest();
		xmlHttp = window.Conekta.Token.create($form, conektaSuccessResponseHandler, conektaErrorResponseHandler);
		xmlHttp.onreadystatechange = () => {
			if (xmlHttp.readyState === 4) {
				if (xmlHttp.status === 200) {
					let data: ConektaTokenResponse[] = JSON.parse(xmlHttp.response) as ConektaTokenResponse[];

					if (data['object'] == "token") {
						this.tokenGenerated = data['id'];
						this.alertGlobalService.showAlertSuccess("Comprobación correcta");
						this.numberLast = this.f_balanceMethodPaymentForm['tarjetNumber'].value + "";
						this.numberLast = this.numberLast.substring(this.numberLast.length - 4);

						this.stepperBalance.next();
					} else if (data['object'] == "error") {
						this.alertGlobalService.showAlertError(data['message_to_purchaser']);
						this.balanceMethodPaymentForm.setErrors({ 'data_incorrect': true });
					}
				} else {
					this.alertGlobalService.showAlertError("Error");
				}
			}
		};
	}

	onSubmitSubscriptionPaymentOxxoForm() {
		this.f_balanceMethodPaymentForm['completed'].patchValue(true);

		if (this.balanceMethodPaymentForm.invalid) {
			this.f_balanceMethodPaymentForm['completed'].patchValue(false);
			return;
		}

		if (this.subscriptionTypeForm.invalid) {
			return;
		}

		let subscriptionpayment = <Subscriptionpayment>{
			amount: this.calculatePrice.amountTotal,
			description: this.f_balanceMethodPaymentForm['detail'].value,
			fullName: this.f_balanceMethodPaymentForm['fullName'].value,
			idPlan: this.f_subscriptionTypeForm['subscriptionType'].value,
			email: this.authService.currentUserValue.username,
			phone: this.f_balanceMethodPaymentForm['phone'].value,
			idCustomerConekta: this.idCustomerConekta,
			amountWithoutTAX: this.calculatePrice.netAmountPay,
			periodQuantity: 1,
			idService: 2
		};

		this.subscriptionpaymentService.createSubscriptionPayment(subscriptionpayment, this.authService.currentUserValue.customerId, "2").subscribe((data) => {
			this.html = this.sanitizer.bypassSecurityTrustHtml(data.toString());
			this.stepperBalance.next();
		});
	}

	onSubmitSubscriptionResumenForm() {
		if (this.calculatePrice.amountTotal <= 0) {
			this.alertGlobalService.showAlertWarning("monto superior ");

			return;
		}

		let subscriptionpayment = <Subscriptionpayment>{
			amount: this.calculatePrice.amountTotal,
			description: this.f_balanceMethodPaymentForm['detail'].value,
			fullName: this.f_balanceMethodPaymentForm['fullName'].value,
			numberCard: this.numberLast,
			tokenId: this.tokenGenerated,
			idPlan: this.f_subscriptionTypeForm['subscriptionType'].value,
			idCustomerConekta: this.idCustomerConekta,
			email: this.authService.currentUserValue.username,
			phone: this.phoneCustomerConekta,
			periodQuantity: 1,
			amountWithoutTAX: this.calculatePrice.netAmountPay,
			idService: 2
		};

		this.subscriptionpaymentService.createSubscriptionPayment(subscriptionpayment, this.authService.currentUserValue.customerId, "1").subscribe((data) => {
			this.alertGlobalService.showAlertSuccess("Pago en proceso");

			this.router.navigate(['/mi-cuenta']);
		}, error => {
			this.f_balanceMethodPaymentForm['tarjetNumber'].patchValue("");
			this.f_balanceMethodPaymentForm['dateEndMonth'].patchValue("");
			this.f_balanceMethodPaymentForm['dateEndYear'].patchValue("");
			this.f_balanceMethodPaymentForm['cvc'].patchValue("");
		});
	}

	onSubmitSubscriptionPaymentTransfer() {
		this.f_balanceMethodPaymentForm['completed'].patchValue(true);

		if (this.balanceMethodPaymentForm.invalid) {
			this.f_balanceMethodPaymentForm['completed'].patchValue(false);
			return;
		}

		if (this.subscriptionTypeForm.invalid) {
			return;
		}

		let subscriptionpayment = <Subscriptionpayment>{
			amount: this.calculatePrice.amountTotal,
			description: this.f_balanceMethodPaymentForm['detail'].value,
			fullName: this.f_balanceMethodPaymentForm['fullName'].value,
			idPlan: this.f_subscriptionTypeForm['subscriptionType'].value,
			email: this.authService.currentUserValue.username,
			phone: this.f_balanceMethodPaymentForm['phone'].value,
			idCustomerConekta: this.idCustomerConekta,
			amountWithoutTAX: this.calculatePrice.netAmountPay,
			periodQuantity: 1,
			idService: 2
		};

		this.subscriptionpaymentService.createSubscriptionPayment(subscriptionpayment, this.authService.currentUserValue.customerId, "4").subscribe((data) => {
			this.html = this.sanitizer.bypassSecurityTrustHtml(data.toString());
			this.stepperBalance.next();
		});
	}

	getCustomer(): void {
		this.customerService.getCustomer(this.authService.currentUserValue.customerId)
			.subscribe((data) => {
				this.idCustomerConekta = data.idCustomerConekta;
				this.phoneCustomerConekta = data.phoneMobile;

				var fullNameCustomer = (data.firstName || "") + " ";
				fullNameCustomer += (data.middleName || "") + " ";
				fullNameCustomer += (data.lastName1 || "") + " ";
				fullNameCustomer += (data.lastName2 || "") + " ";

				this.f_balanceMethodPaymentForm['fullName'].patchValue(fullNameCustomer.replace("  ", " ").trim());
				this.f_balanceMethodPaymentForm['fullName'].patchValue(fullNameCustomer.replace("  ", " ").trim());
				this.f_balanceMethodPaymentForm['phone'].patchValue(data.phoneMobile);
			});
	}

	print() {
		var mywindow = window.open('', '');
		var htmlString = this.html.toString();
		htmlString = htmlString.replace("SafeValue must use [property]=binding:", "")
		htmlString = htmlString.replace("(see http://g.co/ng/security#xss)", "")

		mywindow.document.write(htmlString);
		mywindow.stop();
		mywindow.print();

		return true;
	}

	panelPaymentDataOpen(e) {
		this.panelPaymentDataOpenState = e;
	}

	panelCardPaymentOpen(e) {
		this.panelCardPaymentOpenState = e;
	}

	suscribeValueChanges() {
		Object.getOwnPropertyNames(this.f_balanceMethodPaymentForm).forEach((prop) => {
			if (prop == 'tarjetNumber') {
				this.f_balanceMethodPaymentForm[prop].valueChanges.subscribe(() => {
					var value: string = this.f_balanceMethodPaymentForm[prop].value;

					if (!value) {
						return;
					}

					var imgCard = document.getElementById("imgCard")
					imgCard.style.display = "inline";

					switch (value.substring(0, 1)) {
						case "3": {
							imgCard.style.opacity = "1";
							imgCard.style.backgroundPosition = "-0px 0px";
							imgCard.setAttribute("src", "/assets/images/logos/amex_logo.png");
							this.mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];
							this.maskCVV = [/\d/, /\d/, /\d/, /\d/];
							break;
						}
						case "4": {
							imgCard.style.opacity = "1";
							imgCard.style.backgroundPosition = "0px 0px";
							imgCard.setAttribute("src", "/assets/images/logos/visa_logo.png");
							this.mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
							this.maskCVV = [/\d/, /\d/, /\d/];
							break;
						}
						case "5": {
							imgCard.style.opacity = "1";
							imgCard.style.backgroundPosition = "0px 0px";
							imgCard.setAttribute("src", "/assets/images/logos/mastercard_logo.png");
							this.mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
							this.maskCVV = [/\d/, /\d/, /\d/];
							break
						}
						default: {
							imgCard.style.opacity = "0";
						}
					}
				});
			}
		});
	}

	handleInputFocusOut(event: Event) {
		const target = event.target as HTMLInputElement;
		if (target && target.value) {
			this.inputFilled = true;
			const amount = parseFloat(target.value.replace('$', '').replace(',', ''));

			this.submmitedAmountPersonalized = true;
			this.f_subscriptionTypeForm['amountPersonalizedAmount'].setValidators([Validators.min(100), Validators.max(20000)]);
			this.f_subscriptionTypeForm['amountPersonalizedAmount'].updateValueAndValidity();
			this.f_subscriptionTypeForm['amountPersonalizedAmount'].patchValue(amount);
			this.selectPlan(this.planList.find(x => x.packageNumber == 5).packageName, true);
		} else {
			this.inputFilled = false;
		}
	}

	updateExpirationDate(event: any) {
		const value = event.target.value;

		if (value == "") {
			return;
		}

		const parts = value.split('/');

		var month = parts[0].toString();

		if (!month.includes("_")) {
			this.f_balanceMethodPaymentForm['dateEndMonth'].patchValue(parseInt(month));
		}

		var year = parts[1].toString();

		if (!year.includes("_")) {
			let yearValue = parseInt(year, 10);

			this.f_balanceMethodPaymentForm['dateEndYear'].patchValue(parseInt("20" + yearValue.toString()));
		}
	}

	validateExpirationDate(control: any) {
		var currentYear = new Date().getFullYear();
		const value = control.value;

		if (value == "") {
			return;
		}

		const parts = value.split('/');
		const month = parseInt(parts[0], 10);
		const year = parseInt("20" + parts[1].toString());

		if (month < 1 || month > 12) {
			return { invalidMonth: true };
		}

		if (year < currentYear || year > currentYear + 5) {
			return { invalidYear: true };
		}

		return null;
	}
}
