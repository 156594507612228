<div>
    <h5>
        Guardar datos aval
    </h5>

    <form class="form-horizontal" [formGroup]="peopleGuaranteeJsonForm" (ngSubmit)="onSubmitFormPeopleGuaranteeJson()">
        <div class="subtittle">
            Escribe o selecciona los datos del Aval. Esta información es requerida para que la documentación
            legal de tus préstamos sea la correcta y sean válidos
        </div>

        <mat-slide-toggle class="special" color="primary" labelPosition="before"
            [checked]="checkApplyMoralPerson()" #checkApplyMoralPersonPeople
            (change)="applyMoralPerson(checkApplyMoralPersonPeople.checked, false)">Persona moral
        </mat-slide-toggle>

        <mat-accordion *ngIf="checkApplyMoralPersonPeople.checked">
            <mat-expansion-panel [expanded]="panelCompanyDataOpenState" class="mb-4"
                (opened)="panelCompanyDataOpen(true)" (closed)="panelCompanyDataOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos Empresa</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-9 mb-3"
                        *ngIf="validateVisiblePersonMoral('BusisnessName')">
                        <div class="form-group">
                            <label for="value-BusisnessName" class="">Razón social<span
                                    *ngIf="validateFormrequestJson('BusisnessName')">*</span></label>
                            <input type="text" formControlName="value-BusisnessName" value=""
                                class="form-control"
                                [ngClass]="panelCompanyDataOpenState && validateRequiredFormF3('value-BusisnessName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3"
                        *ngIf="validateVisiblePersonMoral('BusisnessRfc')">
                        <div class="form-group">
                            <label for="value-BusisnessRfc" class="">RFC<span
                                    *ngIf="validateFormrequestJson('BusisnessRfc')">*</span></label>
                            <input type="text" formControlName="value-BusisnessRfc" value=""
                                class="form-control"
                                [ngClass]="panelCompanyDataOpenState && validateRequiredFormF3('value-BusisnessRfc') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3"
                        *ngIf="validateVisiblePersonMoral('DateConstitution')">
                        <div class="form-group">
                            <label for="value-DateConstitution" class="">Fecha constitución<span
                                    *ngIf="validateFormrequestJson('DateConstitution')">*</span></label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-DateConstitution" type="date"
                                [ngClass]="panelCompanyDataOpenState && validateRequiredFormF3('value-DateConstitution') ? 'is-invalid' : ''" />
                        </div>
                    </div>
                    <div class="col-md-3 mb-3"
                        *ngIf="validateVisiblePersonMoral('WritingNumber')">
                        <div class="form-group">
                            <label for="value-WritingNumber" class="">Número escritura<span
                                    *ngIf="validateFormrequestJson('WritingNumber')">*</span></label>
                            <input type="text" formControlName="value-WritingNumber" value=""
                                class="form-control"
                                [ngClass]="panelCompanyDataOpenState && validateRequiredFormF3('value-WritingNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3"
                        *ngIf="validateVisiblePersonMoral('NotaryNumber')">
                        <div class="form-group">
                            <label for="value-NotaryNumber" class="">Número notario<span
                                    *ngIf="validateFormrequestJson('NotaryNumber')">*</span></label>
                            <input type="text" formControlName="value-NotaryNumber" value=""
                                class="form-control"
                                [ngClass]="panelCompanyDataOpenState && validateRequiredFormF3('value-NotaryNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3"
                        *ngIf="validateVisiblePersonMoral('NotaryName')">
                        <div class="form-group">
                            <label for="value-NotaryName" class="">Nombre notario<span
                                    *ngIf="validateFormrequestJson('NotaryName')">*</span></label>
                            <input type="text" formControlName="value-NotaryName" value=""
                                class="form-control"
                                [ngClass]="panelCompanyDataOpenState && validateRequiredFormF3('value-NotaryName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelTypePersonOpenState" class="mb-4"
                (opened)="panelTypePersonOpen(true)" (closed)="panelTypePersonOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>{{ checkApplyMoralPersonPeople.checked ? 'Datos representante legal' : "Datos de persona física"}}</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-FirstName" class="">Nombre<span
                                    *ngIf="validateFormrequestJson('FirstName')">*</span></label>
                            <input type="text" formControlName="value-FirstName" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-FirstName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-MiddleName" class="">Segundo nombre<span
                                    *ngIf="validateFormrequestJson('MiddleName')">*</span></label>
                            <input type="text" formControlName="value-MiddleName" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-MiddleName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-LastName1" class="">Primer apellido<span
                                    *ngIf="validateFormrequestJson('LastName1')">*</span></label>
                            <input type="text" formControlName="value-LastName1" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-LastName1') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-LastName2" class="">Segundo apellido<span
                                    *ngIf="validateFormrequestJson('LastName2')">*</span></label>
                            <input type="text" formControlName="value-LastName2" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-LastName2') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Alias">Alias<span
                                    *ngIf="validateFormrequestJson('Alias')">*</span></label>
                            <input type="text" formControlName="value-Alias" value="" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-Alias') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-3">
                        <div class="form-group">
                            <label>{{relationship}}<span
                                    *ngIf="validateFormrequestJson('RelationShip')">*</span></label>
                            <select #selectRelationShip class="form-control"
                                formControlName="value-RelationShip"
                                (change)="onChangeRelationShip(selectRelationShip.value)"
                                [ngClass]="validateRequiredFormF3('value-RelationShip') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option
                                    *ngFor="let item of getContactRelationshipList(checkApplyMoralPersonPeople.checked ? '1' : '0')"
                                    value="{{item.key}}">{{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-RelationShipType">Tipo parentesco<span
                                    *ngIf="validateFormrequestJson('RelationShipType')">*</span></label>
                            <select class="form-control" formControlName="value-RelationShipType"
                                [ngClass]="validateRequiredFormF3('value-RelationShipType') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of typeRelationshipList" value="{{item.key}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Gender" class="">Género<span
                                    *ngIf="validateFormrequestJson('Gender')">*</span></label>
                            <select class="form-control" formControlName="value-Gender"
                                [ngClass]="validateRequiredFormF3('value-Gender') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of genders" value="{{item.code}}">{{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="StartDate">Fecha de nacimiento<span
                                    *ngIf="validateFormrequestJson('BirthdayDate')">*</span></label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-BirthdayDate" type="date"
                                [ngClass]="validateRequiredFormF3('value-BirthdayDate') ? 'is-invalid' : ''" />
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-CountryBirthId" class="">País de nacimiento<span
                                    *ngIf="validateFormrequestJson('CountryBirthId')">*</span></label>
                            <select #selectCountryBirth class="form-control"
                                formControlName="value-CountryBirthId"
                                (change)="onChangeSelectCountrieBirth(selectCountryBirth.value)"
                                [ngClass]="validateRequiredFormF3('value-CountryBirthId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of countries" value="{{item.code}}">{{item.name}}
                                </option>
                                <option value="Otro">Otro</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PlaceOfBirth" class="">Estado de nacimiento<span
                                    *ngIf="validateFormrequestJson('PlaceOfBirth')">*</span></label>
                            <select class="form-control" formControlName="value-PlaceOfBirth"
                                [ngClass]="validateRequiredFormF3('value-PlaceOfBirth') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of statesBirth" value="{{item.code}}">{{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Nationality" class="">Nacionalidad<span
                                    *ngIf="validateFormrequestJson('Nationality')">*</span></label>
                            <select class="form-control" formControlName="value-Nationality"
                                [ngClass]="validateRequiredFormF3('value-Nationality') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of nationalities" value="{{item.code}}">
                                    {{getNationalityName(item.name)}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Curp" class="">CURP<span
                                    *ngIf="validateFormrequestJson('Curp')">*</span></label>
                            <input type="text" formControlName="value-Curp" value="" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-Curp') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Rfc" class="">RFC<span
                                    *ngIf="validateFormrequestJson('Rfc')">*</span></label>
                            <input type="text" formControlName="value-Rfc" value="" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-Rfc') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PersonId" class="">INE<span
                                    *ngIf="validateFormrequestJson('PersonId')">*</span></label>
                            <input type="text" formControlName="value-PersonId" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-PersonId') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-SerieFiel" class="">Número de serie FIEL<span
                                    *ngIf="validateFormrequestJson('SerieFiel')">*</span></label>
                            <input type="text" formControlName="value-SerieFiel" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-SerieFiel') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelAddressOpenState" class="mb-4"
                (opened)="panelAdressOpen(true)" (closed)="panelAdressOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos domicilio</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Street" class="">Calle<span
                                    *ngIf="validateFormrequestJson('Street')">*</span></label>
                            <input type="text" formControlName="value-Street" value="" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-Street') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-ExteriorNumber" class="">Número exterior<span
                                    *ngIf="validateFormrequestJson('ExteriorNumber')">*</span></label>
                            <input type="text" formControlName="value-ExteriorNumber" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-ExteriorNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-InteriorNumber" class="">Número interior<span
                                    *ngIf="validateFormrequestJson('InteriorNumber')">*</span></label>
                            <input type="text" formControlName="value-InteriorNumber" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-InteriorNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-DistrictName" class="">Colonia<span
                                    *ngIf="validateFormrequestJson('DistrictName')">*</span></label>
                            <input type="text" formControlName="value-DistrictName" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-DistrictName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-CountryId" class="">País<span
                                    *ngIf="validateFormrequestJson('CountryId')">*</span></label>
                            <select #selectCountry class="form-control" formControlName="value-CountryId"
                                (change)="onChangeSelectCountriesPeople(selectCountry.value)"
                                [ngClass]="validateRequiredFormF3('value-CountryId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of getCountriesDomicile()" value="{{item.code}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-StateId" class="">Estado<span
                                    *ngIf="validateFormrequestJson('StateId')">*</span></label>
                            <select #selectState class="form-control" formControlName="value-StateId"
                                (change)="onChangeSelectStatesPeople(selectState.value)"
                                [ngClass]="validateRequiredFormF3('value-StateId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of statesPeople" value="{{item.code}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-CityId" class="">Municipio<span
                                    *ngIf="validateFormrequestJson('CityId')">*</span></label>
                            <select class="form-control" formControlName="value-CityId"
                                [ngClass]="validateRequiredFormF3('value-CityId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of citiesPeople" value="{{item.code}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-ZipCode" class="">Código postal<span
                                    *ngIf="validateFormrequestJson('ZipCode')">*</span></label>
                            <input type="number" formControlName="value-ZipCode" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-ZipCode') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelContactDataOpenState" class="mb-4"
                (opened)="panelContactDataOpen(true)" (closed)="panelContactDataOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos de contacto</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PhoneMobile" class="">Teléfono celular<span
                                    *ngIf="validateFormrequestJson('PhoneMobile')">*</span></label>
                            <input type="text" formControlName="value-PhoneMobile"
                                [textMask]="{mask: phoneMask}" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-PhoneMobile') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PhoneHome" class="">Teléfono casa<span
                                    *ngIf="validateFormrequestJson('PhoneHome')">*</span></label>
                            <input type="text" formControlName="value-PhoneHome"
                                [textMask]="{mask: phoneMask}" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-PhoneHome') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PhoneOffice" class="">Teléfono trabajo<span
                                    *ngIf="validateFormrequestJson('PhoneOffice')">*</span></label>
                            <input type="text" formControlName="value-PhoneOffice"
                                [textMask]="{mask: phoneMask}" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-PhoneOffice') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PhoneMessages" class="">Teléfono recados<span
                                    *ngIf="validateFormrequestJson('PhoneMessages')">*</span></label>
                            <input type="text" formControlName="value-PhoneMessages"
                                [textMask]="{mask: phoneMask}" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-PhoneMessages') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Email">Correo electrónico<span
                                    *ngIf="validateFormrequestJson('Email')">*</span></label>
                            <input type="text" formControlName="value-Email" value="" class="form-control"
                                [ngClass]="validateRequiredFormF3('value-Email') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Facebook" class="">Facebook<span
                                    *ngIf="validateFormrequestJson('Facebook')">*</span></label>
                            <input type="text" formControlName="value-Facebook" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-Facebook') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Instagram" class="">Instagram<span
                                    *ngIf="validateFormrequestJson('Instagram')">*</span></label>
                            <input type="text" formControlName="value-Instagram" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-Instagram') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-LinkedIn" class="">LinkedIn<span
                                    *ngIf="validateFormrequestJson('LinkedIn')">*</span></label>
                            <input type="text" formControlName="value-LinkedIn" value=""
                                class="form-control"
                                [ngClass]="validateRequiredFormF3('value-LinkedIn') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" mat-dialog-close [mat-dialog-close]="submit">
                Cerrar
            </button>
            <button class="btn btn-primary external-btn" *ngIf="!getIsDisabledSave()">
                Guardar
            </button>
        </div>
    </form>
</div>