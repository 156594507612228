import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  requiredErrorMessage: string = "Este campo es requerido.";
  
  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
		private authService: AuthenticationService,
		private router: Router,) {
			// redirect to home if already logged in
			if (this.authService.currentUserValue) { 
				this.router.navigate(['/inicio']);
			}
      
      this.createForm();
    }

  get f1() { return this.form.controls; }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email] ]
    });
  }

  onSubmit() {
    this.submitted = true;

    if(this.form.invalid) {
      return;
    }

    this.sendMail();
  }

  validateRequiredForm(formControlName: string): boolean {
    if(this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
      return true;
    }
    return false;
  }

  sendMail() {
    this.userService.forgotPassword(this.f1['email'].value)
    .subscribe((data) => {
       
    });
  }

  return() {
    window.location.href = "/iniciar-sesion"
  }
}