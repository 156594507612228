<div class="d-flex justify-content-between">
    <div class="container_title">
        <img src="/assets/images/logos/logotipo-nv.svg" /> <h2>Diseña tu préstamo</h2>
    </div>
    <div class="close-button">
        <button class="btn btn-outline-primary external-btn" mat-dialog-close>
            Cerrar
        </button>
    </div>
</div>

<app-simulator [topNav]="false"></app-simulator>