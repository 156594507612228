<h3>Documentación Requerida</h3>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
    <table mat-table fxFlex #table [dataSource]="requiredDocuments">
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Documento </th>
            <td mat-cell *matCellDef="let row"> {{row.description}} </td>
        </ng-container>
        <ng-container matColumnDef="required">
            <th mat-header-cell *matHeaderCellDef> Requerido </th>
            <td mat-cell *matCellDef="let row; let i = index">
                <mat-slide-toggle (click)="patchRequiredDocument()" [(ngModel)]="requiredDocuments[i]['required']"></mat-slide-toggle>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsRequiredDocuments"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsRequiredDocuments;"></tr>
    </table>
</div>
