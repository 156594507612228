import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CurrencyPipe, DatePipe } from '@angular/common';

/* Angular material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import esMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';

registerLocaleData(esMx);

/* paginator ES */
import { getEsPaginatorIntl } from './es-paginator-intl';

/* Flex Layout */
import { FlexLayoutModule } from '@angular/flex-layout';

/* snotify */
// import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

/* Services */
import { ApiService } from '././services/api/api.service';
import { AuthenticationService } from '././services/authentication/authentication.service';
import { SpinnerService } from '././services/spinner/spinner.service';
import { AmortizeService } from '././services/amortize/amortize.service';
import { QuoteService } from '././services/quote/quote.service';
import { AccountStatementService } from '././services/account-statement/account-statement.service';
import { LoanrequestService } from '././services/loanrequest/loanrequest.service';
import { LoanService } from '././services/loan/loan.service';
import { RequiredDocumentsConfigService } from '././services/required-documents-config/required-documents-config.service';
import { DocumentServiceService } from '././services/document-service/document-service.service';
import { SignalrService } from '././services/signalr/signalr.service';
import { CallCenterService } from './services/callCenter/callCenter.service';

/* Components */
import { LoginComponent } from './views/login/login.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { HomeComponent } from './views/home/home.component';
import { SimulatorComponent } from './views/simulator/simulator.component';
import { LoanrequestComponent } from './views/loanrequest/loanrequest.component';
import { LoanComponent } from './views/loan/loan.component';
import { UserComponent } from './views/user/user.component';
import { NotificationComponent } from './views/notification/notification.component';
import { SignupComponent } from './views/signup/signup.component';
import { AlertGlobalComponent } from './components/alert-global/alert-global.component';
import { InputErrorsGlobalComponent } from './components/input-errors-global/input-errors-global.component';
import { AmortizationTableComponent } from './components/amortization-table/amortization-table.component';
import { AccountStatementComponent } from './views/account-statement/account-statement.component';
import { LoanrequestListComponent } from './components/loanrequest-list/loanrequest-list.component';
import { TypeofperiodSelectListComponent } from './components/typeofperiod-select-list/typeofperiod-select-list.component';
import { TypeofamortizationSelectListComponent } from './components/typeofamortization-select-list/typeofamortization-select-list.component';
import { WarrantySelectListComponent } from './components/warranty-select-list/warranty-select-list.component';
import { DialogSaveQuoteComponent } from './components/dialog-save-quote/dialog-save-quote.component';
import { RequiredDocumentsComponent } from './components/required-documents/required-documents.component';
import { LoanListComponent } from './components/loan-list/loan-list.component';
import { LoanpaymentComponent } from './views/loanpayment/loanpayment.component';
import { LoanpaymentListComponent } from './components/loanpayment-list/loanpayment-list.component';
import { DialogAmortizeTableComponent } from './components/dialog-amortize-table/dialog-amortize-table.component';
import { DialogLoanpaymentComponent } from './components/dialog-loanpayment/dialog-loanpayment.component';
import { DialogPreviewDocumentComponent } from './components/dialog-preview-document/dialog-preview-document.component';
import { IndexComponent } from './views/index/index.component';
import { ValidateEmailComponent } from './views/validate-email/validate-email.component';
import { HtmlViewerComponent } from './views/html-viewer/html-viewer.component';
import { DocumentGeneratorListComponent } from './components/document-generator-list/document-generator-list.component';
import { DialogCommentLoanrequestComponent } from './components/dialog-comment-loanrequest/dialog-comment-loanrequest.component';
import { DialogCommentDocumentComponent } from './components/dialog-comment-document/dialog-comment-document.component';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { DialogProofOfPaymentComponent } from './components/dialog-proof-of-payment/dialog-proof-of-payment.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { ConfirmcodeChangeEmailComponent } from './components/confirmcode-change-email/confirmcode-change-email.component';
import { ConfirmcodeChangePhonenumberComponent } from './components/confirmcode-change-phonenumber/confirmcode-change-phonenumber.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SimulatorAdvancedComponent } from './components/simulator-advanced/simulator-advanced.component';
import { QuoteListComponent } from './components/quote-list/quote-list.component';
import { DialogSimulatorComponent } from './components/dialog-simulator/dialog-simulator.component';
import { DialogComissionsConditionsComponent } from './components/dialog-comissions-conditions/dialog-comissions-conditions.component';
import { DialogWarrantysConditionsComponent } from './components/dialog-warrantys-conditions/dialog-warrantys-conditions.component';
import { DialogWarrantyPeopleDetailsComponent } from './components/dialog-warranty-people-details/dialog-warranty-people-details.component';
import { DialogWarrantyObligorJointDetailsComponent } from './components/dialog-warranty-obligorjoint-details/dialog-warranty-obligorjoint-details.component';
import { DialogWarrantyMortgageDetailsComponent } from './components/dialog-warranty-mortgage-details/dialog-warranty-mortgage-details.component';
import { DialogWarrantyPledgeDetailsComponent } from './components/dialog-warranty-pledge-details/dialog-warranty-pledge-details.component';
import { DialogWarrantyLiquidDetailsComponent } from './components/dialog-warranty-liquid-details/dialog-warranty-liquid-details.component';
import { DialogWarrantyOtherDetailsComponent } from './components/dialog-warranty-other-details/dialog-warranty-other-details.component';
import { TermsComponent } from './views/terms/terms.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { DialogHelpInfoComponent } from './components/dialog-help-info/dialog-help-info.component';
import { SubscriptionsIndexComponent } from './components/subscriptions-index/subscriptions-index.component';
import { SendInfoSuscriptionsComponent } from './components/send-info-suscriptions/send-info-suscriptions.component';
import { LoanrequestResumeComponent } from './components/loanrequest-resume/loanrequest-resume.component';
import { DialogLoanProceedingsComponent } from './components/dialog-loan-proceedings/dialog-loan-proceedings.component';
import { DialogLoanRequestFormComponent } from './components/dialog-loan-request-form/dialog-loan-request-form.component';
import { DialogVerifyCodePhoneComponent } from './components/dialog-verify-code-phone/dialog-verify-code-phone.component';
import { SubscriptionpaymentListComponent } from './components/subscriptionpayment-list/subscriptionpayment-list.component';
import { LegalConditionsSelectListComponent } from './components/legalconditions-select-list/legalconditions-select-list.component';
import { BlogComponent } from './views/blog/blog.component';
import { BlogListComponent } from './components/blog-list/blog-list.component';
import { FaqComponent } from './views/faq/faq.component';
import { BillListComponent } from './components/bill-list/bill-list.component';
import { DialogGenerateBillComponent } from './components/dialog-generate-bill/dialog-generate-bill.component';
import { DialogCompleteAccountComponent } from './components/dialog-complete-account/dialog-complete-account.component';
import { LoanrequestViewComponent } from './views/loanrequestview/loanrequestview.component';
import { LoanResumeComponent } from './components/loan-resume/loan-resume.component';
import { ReportComponent } from './views/report/report.component';
import { SubscriptionpaymentNewComponent } from './components/subscriptionpayment-new/subscriptionpayment-new.component';
import { DialogTaxConditionsComponent } from './components/dialog-tax-conditions/dialog-tax-conditions.component';
import { BillNewComponent } from './components/bill-new/bill-new.component';
import { DialogAdvanceOptionsComponent } from './components/dialog-advance-options/dialog-advance-options.component';
import { DialogPersonDataComponent } from './components/dialog-person-data/dialog-person-data.component';
import { DialogAddressDataComponent } from './components/dialog-address-data/dialog-address-data.component';
import { DialogContactDataComponent } from './components/dialog-contact-data/dialog-contact-data.component';
import { DialogEconomicActivityComponent } from './components/dialog-economic-activity/dialog-economic-activity.component';
import { DialogPatrimonialDataComponent } from './components/dialog-patrimonial-data/dialog-patrimonial-data.component';
import { DialogEconomicFlowComponent } from './components/dialog-economic-flow/dialog-economic-flow.component';
import { DialogFamilyDataComponent } from './components/dialog-family-data/dialog-family-data.component';
import { DialogReferenceDataComponent } from './components/dialog-reference-data/dialog-reference-data.component';
import { DialogSocioeconomicComponent } from './components/dialog-socioeconomic/dialog-socioeconomic.component';
import { DialogBankDataComponent } from './components/dialog-bank-data/dialog-bank-data.component';
import { DialogBalanceSheetDataComponent } from './components/dialog-balance-sheet-data/dialog-balance-sheet-data.component';
import { DialogStatusResultComponent } from './components/dialog-status-result/dialog-status-result.component';
import { DialogPLDComponent } from './components/dialog-pld-data/dialog-pld-data.component';
import { AdministratorComponent } from './views/administrator/administrator.component';
import { DialogSearchCustomerComponent } from './components/dialog-search-customer/dialog-search-customer.component';
import { DialogSubscriptionDetailComponent } from './components/dialog-subscription-detail/dialog-subscription-detail.component';
import { PolicyComponent } from './views/policy/policy.component';
import { LegalMessageComponent } from './views/legal-message/legal-message.component';
import { DialogTutorialComponent } from './components/dialog-tutorial/dialog-tutorial.component';
import { QuestionComponent } from './views/question/question.component';
import { LoanImportComponent } from './views/loanimport/loanimport.component';
import { LoanImportResumeComponent } from './components/loan-import-resume/loan-import-resume.component';
import { DialogLoanRequestEditComponent } from './components/dialog-loanrequest-edit/dialog-loanrequest-edit.component';
import { LoanImportNewComponent } from './components/loan-import-new/loan-import-new.component';
// import { PageNotFoundComponent } from './views/pagenotfound/pagenotfound.component';
import { DialogShowDetailsAdministratorComponent } from './components/dialog-show-details-administrator/dialog-show-details-administrator.component';
import { DialogShowDetailsAdministratorSubscriptionsComponent } from './components/dialog-show-details-administrator-subscriptions/dialog-show-details-administrator-subscriptions.component';
import { DialogShowCallRatingComponent } from './components/dialog-show-details-call-rating/dialog-show-details-call-rating.component';
import { DialogShowConditionsExternalServices } from './components/dialog-show-conditions-external-services/dialog-show-conditions-external-services.component';
import { ToastrModule } from 'ngx-toastr';
import { NgChartsModule } from 'ng2-charts';
import { ExternalServiceDetailsComponent } from './components/external-services-details/external-service-details.component';
import { ExternalServiceCollectionComponent } from './components/external-services-collection/external-service-collection.component';
import { AccountComponent } from './views/account/account.component';
import { CartComponent } from './views/cart/cart.component';
import { DetailServiceComponent } from './views/detail-service/detail-service.component';
import { BalanceReloadComponent } from './components/balance-reload/balance-reload.component';
import { CallcenterComponent } from './views/callcenter/callcenter.component';
import { CallcenterResumeComponent } from './components/callcenter-resume/callcenter-resume.component';
import { DialogShowDetailsCallCenterComponent } from './components/dialog-show-details-callcenter-results/dialog-show-details-callcenter-results.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { ConditionsDataComponent } from './components/conditions-data/conditions-data.component';

// currency text mask directive
import { TextMaskModule } from 'angular2-text-mask';
import { environment } from 'src/environments/environment';
import { VerificationDirective } from './verification.directive';
import { MatButtonModule } from "@angular/material/button";
import { CollectionComponent } from './views/collection/collection.component';
import { DialogShowImportCollectionComponent } from './components/dialog-show-import-collection/dialog-show-import-collection.component';

@NgModule({
  declarations: [
    BillNewComponent,
    AdministratorComponent,
    PolicyComponent,
    LegalMessageComponent,
    QuestionComponent,
    LoanImportComponent,
    LoanImportResumeComponent,
    LoanImportNewComponent,
    // PageNotFoundComponent,
    AccountComponent,
    BalanceReloadComponent,
    AppComponent,
    CartComponent,
    DetailServiceComponent,
    VerificationDirective,
    LoginComponent,
    SpinnerComponent,
    HomeComponent,
    SimulatorComponent,
    LoanrequestComponent,
    LoanComponent,
    UserComponent,
    NotificationComponent,
    SignupComponent,
    AlertGlobalComponent,
    InputErrorsGlobalComponent,
    AmortizationTableComponent,
    AccountStatementComponent,
    LoanrequestListComponent,
    TypeofperiodSelectListComponent,
    TypeofamortizationSelectListComponent,
    WarrantySelectListComponent,
    RequiredDocumentsComponent,
    LoanListComponent,
    LoanpaymentComponent,
    LoanpaymentListComponent,
    IndexComponent,
    ValidateEmailComponent,
    HtmlViewerComponent,
    DocumentGeneratorListComponent,
    NotificationListComponent,
    ConfirmcodeChangeEmailComponent,
    ConfirmcodeChangePhonenumberComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    SimulatorAdvancedComponent,
    QuoteListComponent,
    TermsComponent,
    PrivacyComponent,
    SubscriptionsIndexComponent,
    SendInfoSuscriptionsComponent,
    LoanrequestResumeComponent,
    SubscriptionpaymentListComponent,
    LegalConditionsSelectListComponent,
    BlogComponent,
    BlogListComponent,
    FaqComponent,
    BillListComponent,
    LoanrequestViewComponent,
    LoanResumeComponent,
    ReportComponent,
    CollectionComponent,
    SubscriptionpaymentNewComponent,
    DialogWarrantysConditionsComponent,
    DialogWarrantyPeopleDetailsComponent,
    DialogWarrantyObligorJointDetailsComponent,
    DialogWarrantyMortgageDetailsComponent,
    DialogWarrantyPledgeDetailsComponent,
    DialogWarrantyLiquidDetailsComponent,
    DialogWarrantyOtherDetailsComponent,
    DialogGenerateBillComponent,
    DialogCompleteAccountComponent,
    DialogSaveQuoteComponent,
    DialogHelpInfoComponent,
    DialogAmortizeTableComponent,
    DialogLoanpaymentComponent,
    DialogPreviewDocumentComponent,
    DialogCommentLoanrequestComponent,
    DialogCommentDocumentComponent,
    DialogConfirmComponent,
    DialogSimulatorComponent,
    DialogComissionsConditionsComponent,
    DialogLoanProceedingsComponent,
    DialogLoanRequestFormComponent,
    DialogVerifyCodePhoneComponent,
    DialogProofOfPaymentComponent,
    DialogTaxConditionsComponent,
    DialogAdvanceOptionsComponent,
    DialogPersonDataComponent,
    DialogAddressDataComponent,
    DialogContactDataComponent,
    DialogEconomicActivityComponent,
    DialogPatrimonialDataComponent,
    DialogEconomicFlowComponent,
    DialogFamilyDataComponent,
    DialogReferenceDataComponent,
    DialogSocioeconomicComponent,
    DialogBankDataComponent,
    DialogBalanceSheetDataComponent,
    DialogStatusResultComponent,
    DialogPLDComponent,
    DialogSearchCustomerComponent,
    DialogSubscriptionDetailComponent,
    DialogTutorialComponent,
    DialogShowDetailsAdministratorComponent,
    DialogShowDetailsAdministratorSubscriptionsComponent,
    ExternalServiceDetailsComponent,
    DialogLoanRequestEditComponent,
    CallcenterComponent,
    CallcenterResumeComponent,
    DialogShowDetailsCallCenterComponent,
    ConditionsDataComponent,
    DialogShowCallRatingComponent,
    DialogShowConditionsExternalServices,
    ExternalServiceCollectionComponent,
    DialogShowImportCollectionComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    // SnotifyModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation,
    TextMaskModule,
    NgChartsModule,
    ToastrModule.forRoot(), // ToastrModule added
    MatButtonModule
  ],
  providers: [
    ApiService,
    AuthenticationService,
    SpinnerService,
    AmortizeService,
    QuoteService,
    AccountStatementService,
    LoanrequestService,
    LoanService,
    CallCenterService,
    RequiredDocumentsConfigService,
    DocumentServiceService,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
    // { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    { provide: MatPaginatorIntl, useValue: getEsPaginatorIntl() },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.datasitekey } as RecaptchaSettings},
    // SnotifyService,
    SignalrService,
    CurrencyPipe,
    DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
