import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-typeofperiod-select-list',
  templateUrl: './typeofperiod-select-list.component.html',
  styleUrls: ['./typeofperiod-select-list.component.scss']
})
export class TypeofperiodSelectListComponent implements OnInit {

  @Input() onlyText: boolean = false;
  @Input() selected: string = "";

  selectedValue: string = "";
  showText: boolean = false;
  text: string = "";

  nameList: string = "Frecuencia de pago";
  list: KeyValue<number, string>[] = [
    { key: 1, value: "Diario" },
    { key: 7, value: "Semanal" },
    { key: 14, value: "Catorcenal" },
    { key: 15, value: "Quincenal" },
    { key: 30, value: "Mensual" },
    { key: 60, value: "Bimestral" },
    { key: 90, value: "Trimestral" },
    { key: 180, value: "Semestral" },
    { key: 360, value: "Anual" },
  ];

  constructor() { }

  ngOnInit(): void {
    this.showText = this.onlyText;
    this.text = this.list.find(x => x.key === parseInt(this.selected)).value;
    this.selectedValue = this.selected.toString();
  }

}
