import { Component, Input, OnInit } from '@angular/core';
import { AccountStatementService } from '../../services/account-statement/account-statement.service';
import { AccountStatement, Movement } from '../../models/account-statement/account-statement.model';
import { ActivatedRoute } from '@angular/router';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { CurrencyPipe, DatePipe, KeyValue } from '@angular/common';
import { TypeofcreditService } from 'src/app/services/typeofcredit/typeofcredit.service';
import { Formats } from 'src/app/global/global-data';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake"
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { AccountStatementMailDTO } from 'src/app/models/account-statement/summaryaccountstatement.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { LoanpaymentService } from 'src/app/services/loanpayment/loanpayment.service';
import { TypeofamortizationService } from 'src/app/services/typeofamortization/typeofamortization.service';
import { AmortizeTable } from 'src/app/models/amortize/amortize.table.model';

@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.scss']
})

export class AccountStatementComponent implements OnInit {

  @Input() loanId: string = "";
  accountStatement: AccountStatement;
  movements: Movement[];
  indexSelected = null;
  rowSelected: Movement = <Movement>{};
  typeOfPeriodDesc = "";
  typeOfCredit: any;
  typeOfAmortization = "";
  warrantys: string = "";
  accountStatementDetailColumns: string[] = [
    'period',
    'date',
    'movement',
    'debit',
    'credit',
    'capital',
    'ordinaryInterest',
    'tax',
    'commission',
    'commissionTax',
    'interestOnArrears',
    'interestOnArrearsTax',
    'retention',
    // 'netPeriodPay',
    'balance',
    'balanceToLiquidate',
    // 'capitalExpired',
    'capitalExpiredCalculate',
    // 'balanceExpired',
    'balanceExpiredCalculate',
    'buttonDeletePayment'
  ];
  accountStatementDetailColumns2: string[] = [
    'period',
    'date',
    'capitalExpired',
  ];

  typeOfPeriodList: KeyValue<number, string>[] = [];
  typeOfCreditList: KeyValue<number, string>[] = [];
  typeOfAmortizationList: KeyValue<number, string>[] = [];
  retention = false;
  currentTypeOfCredit = 0;

  panelLoanProceedingsOpenState = true;
  panelLoanProceedingsOpenTableState = false;

  dateToday = new Date();

  commissionAmount = 0;

  constructor(
    private accountStatementService: AccountStatementService,
    private typeofperiodService: TypeofperiodService,
    private typeofcreditService: TypeofcreditService,
    private typeofamortizationService: TypeofamortizationService,
    private currencyPipe: CurrencyPipe,
    private dialog: MatDialog,
    private alertGlobalService: AlertGlobalService,
    private datePipe: DatePipe,
    private loanpaymentService: LoanpaymentService,
  ) { }

  ngOnInit(): void {
    this.getTypeOfPeriodList();
    this.getTypeOfCreditList();
    this.getTypeOfAmortizationList();
    if (this.loanId) {
      this.getAccountStatement(this.loanId);
    }
  }

  getAccountStatement(loanId: string) {
    this.accountStatementService.getAccountStatement(loanId).subscribe((result) => {
      this.accountStatement = result;
      this.movements = result.resumeData.movements;

      if (result.conditionsData.percentRetentionISR > 0) {
        this.retention = true;
      } else {
        this.accountStatementDetailColumns = this.accountStatementDetailColumns.filter(x => x !== "retention" && x !== "netPeriodPay");
        this.retention = false;
      }

      this.typeOfPeriodDesc = this.typeOfPeriodList.find(x => x.key === Number.parseInt(this.accountStatement.conditionsData.typeOfPeriod)).value;
      this.typeOfCredit = this.typeOfCreditList.find(x => x.key === Number.parseInt(this.accountStatement.conditionsData.typeOfCredit)).value;
      this.typeOfAmortization = this.typeOfAmortizationList.find(x => x.key === Number.parseInt(this.accountStatement.conditionsData.typeOfAmortization)).value;

      if (parseInt(this.accountStatement.conditionsData.typeOfCredit) == 1) {
        this.accountStatementDetailColumns = this.accountStatementDetailColumns.filter(x => x !== "balance");
      }
      else {
        this.accountStatementDetailColumns = this.accountStatementDetailColumns.filter(x => x !== "balanceToLiquidate");
      }

      this.currentTypeOfCredit = parseInt(this.accountStatement.conditionsData.typeOfCredit);
      this.warrantys = "";
      JSON.parse(this.accountStatement.conditionsData.warranty).forEach((item) => {
        this.warrantys += item['description'] + ", ";
      });

      this.warrantys = this.warrantys.substring(0, this.warrantys.length - 2);

      if (this.warrantys == "") {
        this.warrantys = "Sin garantía(s)";
      }

      var movementCommission = this.movements.find(x => x.movement == "Cargo Periodo 1");
      var amortizeTable = JSON.parse(this.accountStatement.resumeData.amortizationTableJson).table as AmortizeTable[];

      if (movementCommission != null && this.movements.find(x => x.typeOfMovement == 4) != null && movementCommission.commission == 0) {
        this.commissionAmount = this.movements.find(x => x.typeOfMovement == 4).debit;
      }

      var amortizeTablePeriod0 = amortizeTable.find(x => x.period == 0);

      if (amortizeTablePeriod0.commission > 0) {
        this.commissionAmount = (amortizeTablePeriod0.commission + amortizeTablePeriod0.commissionTax) - amortizeTablePeriod0.retention;
      }
    });
  }

  getSumDebit() {
    return this.commissionAmount + this.accountStatement.resumeData.movements.filter(x => x.period > 0).map(x => x.debit).reduce((acc, value) => acc + value, 0);
  }

  getSumCredit() {
    return this.accountStatement.resumeData.movements.filter(x => x.period >= 0).map(x => x.credit).reduce((acc, value) => acc + value, 0);
  }

  getSumCapital() {
    return this.accountStatement.resumeData.movements.filter(x => x.period >= 1 && x.typeOfMovement == 0).map(x => x.capital).reduce((acc, value) => acc + value, 0);
  }

  getSumOrdinaryInterest() {
    return this.accountStatement.resumeData.movements.filter(x => x.period >= 1 && x.typeOfMovement == 0).map(x => x.ordinaryInterest).reduce((acc, value) => acc + value, 0);
  }

  getSumInterestOnArrears() {
    return this.accountStatement.resumeData.movements.filter(x => x.period >= 1 && x.typeOfMovement == 0).map(x => x.interestOnArrears).reduce((acc, value) => acc + value, 0);
  }

  getSumInterestOnArrearsTax() {
    return this.accountStatement.resumeData.movements.filter(x => x.period >= 1 && x.typeOfMovement == 0).map(x => x.interestOnArrearsTax).reduce((acc, value) => acc + value, 0);
  }

  getSumTax() {
    return this.accountStatement.resumeData.movements.filter(x => x.period >= 1 && x.typeOfMovement == 0).map(x => x.tax).reduce((acc, value) => acc + value, 0);
  }

  getSumCommission() {
    return this.accountStatement.resumeData.movements.filter(x => x.period >= 0 && x.typeOfMovement == 0).map(x => x.commission).reduce((acc, value) => acc + value, 0);
  }

  getSumCommissionTax() {
    return this.accountStatement.resumeData.movements.filter(x => x.period >= 0 && x.typeOfMovement == 0).map(x => x.commissionTax).reduce((acc, value) => acc + value, 0);
  }

  getSumRetentionISR() {
    return this.accountStatement.resumeData.movements.filter(x => x.period >= 0 && x.typeOfMovement == 0).map(x => x.retention).reduce((acc, value) => acc + value, 0);
  }

  getTypeOfPeriodList() {
    this.typeofperiodService.getList().subscribe((list) => {
      this.typeOfPeriodList = list;
    });
  }

  setTypeOfPeriodList(type: number) {
    return this.typeOfPeriodList.find(x => x.key == type).value
  }

  getTypeOfCreditList() {
    this.typeofcreditService.getList().subscribe((list) => {
      this.typeOfCreditList = list;
    });
  }

  getTypeOfAmortizationList() {
    this.typeofamortizationService.getList()
      .subscribe((list) => {
        this.typeOfAmortizationList = list;
      });
  }

  setTypeOfCreditList(type: number) {
    return this.typeOfCreditList.find(x => x.key == type).value
  }

  onClickSort() {
    this.rowSelected = <Movement>{};
    this.indexSelected = null;
  }

  onRowClicked(row, index) {
    this.rowSelected = row;
    this.indexSelected = index;
  }

  print() {
    var mywindow = window.open();
    mywindow.document.write(this.getPayTableHTMLString2())
    mywindow.stop();
    mywindow.print();

    return true;
  }

  panelLoanProceedingsOpen(e) {
    this.panelLoanProceedingsOpenState = e;
  }

  panelLoanProceedingsTableOpen(e) {
    this.panelLoanProceedingsTableOpen = e;
  }

  private getPayTableHTMLString2() {
    var currentUserTemp = JSON.parse(localStorage.getItem('currentUser'));

    var html = `
		<html>
		<head>
		<title>a-lender diseña tu préstamo</title>
		<style>
			table, th, td, tr {
				border: 0px solid black;
			}
			body {
				font-family: Calibri;
			}
		</style>
		</head>
		<body>
		<h1>Detalle de Estado de Cuenta</h1>
		<h3>De acuerdo a las condiciones definidas por el Usuario</h3>`;

    html += `
		<table>
		`;

    html += `
				<tr style="border: 0px solid black;">
					<td style="font-weight: bold; text-align: left;">Fecha</td>
					<td style="text-align: left;">` + this.datePipe.transform(new Date(this.dateToday), Formats.DATE) + `</td>
					<td style="font-weight: bold; text-align: left;">ID Préstamo</td>
					<td style="text-align: left;">` + this.accountStatement.conditionsData.loanId + `</td>
				</tr>`;

    if (currentUserTemp) {
      html += `
				<tr style="border: 0px solid black;">
					<td><p style="font-weight: 500; font-size: 10;">Participantes</p></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>`;

      html += `
				<tr style="border: 0px solid black;">
					<td style="font-weight: bold; text-align: left;">Nombre Solicitante</td>
					<td style="text-align: left;">` + this.accountStatement.borrowerData.fullName + `</td>
					<td style="font-weight: bold; text-align: left;">Teléfono celular</td>
					<td style="text-align: left;">` + (this.accountStatement.borrowerData.phoneNumber || "") + `</td>
				</tr>
				<tr style="border: 0px solid black;">
					<td style="font-weight: bold; text-align: left;">Correo electrónico</td>
					<td style="text-align: left;">` + this.accountStatement.borrowerData.email + `</td>
					<td></td>
					<td></td>
				</tr>

				<tr style="border: 0px solid black;">
					<td style="font-weight: bold; text-align: left;">Nombre Prestamista</td>
					<td style="text-align: left;">`+ this.accountStatement.lenderData.fullName + `</td>

					<td style="font-weight: bold; text-align: left;">Teléfono celular</td>
					<td style="text-align: left;">`+ (this.accountStatement.lenderData.phoneNumber || "") + `</td>
				</tr>
				<tr style="border: 0px solid black;">
					<td><p style="font-weight: 500; font-size: 10;"></p></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>
				<tr style="border: 0px solid black;">
					<td style="font-weight: bold; text-align: left;">Correo electrónico</td>
					<td style="text-align: left;">`+ this.accountStatement.lenderData.email + `</td>
					<td></td>
					<td></td>
				</tr>
			`;
    }


    html += `
		<tr style="border: 0px solid black;">
			<td><p style="font-weight: 500; font-size: 10;">Condiciones</p></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Monto del préstamo</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.accountStatement.conditionsData.loanAmount) + `</td>
			<td style="font-weight: bold; text-align: left;">Tipo de préstamo</td>
			<td style="text-align: left;">`+ this.typeOfCredit + `</td>
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Fecha de inicio</td>
			<td style="text-align: left;">`+ this.datePipe.transform(new Date(this.accountStatement.conditionsData.startDate), Formats.DATE) + `</td>
			<td style="font-weight: bold; text-align: left;">Cálculo de intereses</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.accountStatement.conditionsData.interestCalculate) + `</td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Frecuencia de pagos</td>
			<td style="text-align: left;">`+ this.typeOfPeriodDesc + `</td>
			<td style="font-weight: bold; text-align: left;">Periodo del primer pago</td>
			<td style="text-align: left;">`+ this.accountStatement.conditionsData.periodFirstPayment + `</td>
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Número de pagos</td>
			<td style="text-align: left;">`+ this.accountStatement.conditionsData.totalPeriods + `</td>`
    html += `
			<td style="font-weight: bold; text-align: left;">Tipo de garantía</td>
			<td style="text-align: left;">`+ this.warrantys + `</td>`
    html += `
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Plazo del préstamo</td>
			<td style="text-align: left;">`+ this.accountStatement.conditionsData.loanTerm + ` ` + this.typeOfPeriodDesc + `</td>
			<td style="font-weight: bold; text-align: left;">Interés moratorio mensual</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.accountStatement.conditionsData.monthlyPorcentOnArrears).replace('%', '').replace('$', '') + ` %</td>
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Interés mensual</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.accountStatement.conditionsData.monthtlyRate).replace('%', '').replace('$', '') + ` %</td>

			<td style="font-weight: bold; text-align: left;">IVA</td>
			<td style="text-align: left;">`+ (this.accountStatement.conditionsData.taxPorcent != 0 ? 'Si' : 'No') + `</td>
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Pago Periódico</td>
			<td style="text-align: left;"></td>
			<td style="font-weight: bold; text-align: left;">Comisiones</td>
			<td style="text-align: left;">`+ (this.accountStatement.conditionsData.applyCommissions ? 'Si' : 'No') + `</td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Monto total a pagar</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.accountStatement.conditionsData.totalPayment) + `</td>
			<td style="font-weight: bold; text-align: left;">Pagos personalizados</td>
			<td style="text-align: left;">`+ (this.accountStatement.conditionsData.customPayments ? 'Si' : 'No') + `</td>
		</tr>
		<tr style="border: 0px solid black;">
			<td><p style="font-weight: 500; font-size: 10;"></p></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
		<tr style="border: 0px solid black;">
			<td><p style="font-weight: 500; font-size: 10;">Resumen</p></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: center;">Monto del préstamo</td>
			<td style="font-weight: bold; text-align: center;">Monto interés total</td>
			<td style="font-weight: bold; text-align: center;">Monto comisiones total</td>
			<td style="font-weight: bold; text-align: center;">Monto total a pagar</td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="text-align: center;">`+ this.currencyPipe.transform(this.accountStatement.resumeData.loanAmount) + `</td>
			<td style="text-align: center;">`+ this.currencyPipe.transform(this.accountStatement.resumeData.totalInterest) + `</td>
			<td style="text-align: center;">`+ this.currencyPipe.transform(this.accountStatement.resumeData.totalCommissions) + `</td>
			<td style="text-align: center;">`+ this.currencyPipe.transform(this.accountStatement.resumeData.totalPayment) + `</td>
		</tr>
		</table>


		<h4 style="font-weight: 500; font-size: 10;">Tabla de pagos y movimientos</h4>
		<table style="font-size: 9px; width: 100%;">
		<tr style="border: 0px solid black;">
									<td style="font-weight: bold;">Período</td>
									<td style="font-weight: bold;">Fecha Movim.</td>
									<td style="font-weight: bold; text-align: right;">Tipo de Movimiento</td>
									<td style="font-weight: bold; text-align: right;">Cargo</td>
									<td style="font-weight: bold; text-align: right;">Abono</td>
									<td style="font-weight: bold; text-align: right;">Pago Capital</td>
									<td style="font-weight: bold; text-align: right;">Pago Interés</td>
									<td style="font-weight: bold; text-align: right;">Pago IVA</td>
									<td style="font-weight: bold; text-align: right;">Pago Comis.</td>
									<td style="font-weight: bold; text-align: right;">IVA Comis.</td>
									<td style="font-weight: bold; text-align: right;">Pago Morat.</td>
									<td style="font-weight: bold; text-align: right;">IVA Morat.</td>
								`;

    if (this.retention) {
      html += `
				<td style="font-weight: bold; text-align: right;">Retención ISR</td>
			`;
    }

    html += `
			<td style="font-weight: bold; text-align: right;">Saldo Final Total</td>
			<td style="font-weight: bold; text-align: right;">Capital vencido</td>
			<td style="font-weight: bold; text-align: right;">Saldo con atraso Total</td>
		</tr>
		`;

    this.movements.forEach((x) => {
      html += `<tr style="border: 0px solid black;">
								<td>`+ x.period + `</td>
								<td>`+ this.datePipe.transform(x.date, Formats.DATE) + `</td>
								<td style="text-align: right;">`+ x.movement + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.debit) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.credit) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.capital) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.ordinaryInterest) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.tax) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.commission) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.commissionTax) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.interestOnArrears) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.interestOnArrearsTax) + `</td>`;

      if (this.retention) {
        html += `<td style="text-align: right;">` + this.currencyPipe.transform(x.retention) + `</td>`;
      }

      html += `<td style="text-align: right;">` + this.currencyPipe.transform(x.balance) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.balanceExpired) + `</td>
								<td style="text-align: right;">`+ this.currencyPipe.transform(x.capitalExpired) + `</td>
							</tr>`;
    });

    html += `
		</table>
		`;

    html += `
		<p style="font-size: 2; text-align: left">
			<p style="font-weight: bold;">Avisos y recomendaciones</p>
			<ul style="font-weight: bold; list-style: none; padding-inline-start: 0; font-weight: normal;">
				<li>Esta Solicitud, Condiciones y Tabla de Pagos ha sido creada en <strong>www.a-lender.com</strong></li>
				<li>El contenido es responsabilidad del Usuario quien la generó La presente solicitud y tabla de pagos es informativa hasta que las partes formalizen el préstamo.</li>
				<li>Si vas a prestar, evalúa si el solicitante puede pagar y si cuentas con los recursos para hacerlo.</li>
				<li>Si necesitas dinero, solicita el préstamo de acuerdo a tus posibilidades de pago, evita sobre endeudarte.</li>
				<li style="font-weight: bold;">a-lender®</li>
				<li>Es la plataforma digital para administrar préstamos.</li>
				<li>Si vas a prestar, no pierdas ni a tu amigo ni tu dinero: ¡Utiliza a-lender® para administrarlo fácil y seguro!</li>
				<li>Si necesitas dinero, lo más sencillo es apoyarte en tus conocidos: ¡Utiliza a-lender® para administrarlo fácil y rápido!</li>
				<li>Regístrate en: <strong>www.a-lender.com</strong> o escríbenos a: <strong>hola@a-lender.com</strong></li>
			</ul>
		</p>
		`;

    html += `
			</body></html>
		`;
    return html;
  }

  exportPDF() {
    var html = htmlToPdfmake(this.getPayTableHTMLString2());
    const documentDefinition = { content: [html], styles: { table: { border: '0px solid black' } } };

    pdfMake.createPdf(documentDefinition).open();

  }

  sendContactMail() {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        message: '¿Desea enviar el estado de cuenta al solicitante?'
      }
    })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        var cc = "";

        if (confirmado) {
          cc = this.accountStatement.borrowerData.email;

          var accountStatementMail = <AccountStatementMailDTO>{
            to: this.accountStatement.lenderData.email,
            cc: cc,
            body: this.getPayTableHTMLString2()
          }

          this.accountStatementService.sendAccountStatementMail(accountStatementMail).subscribe((result) => {
            this.alertGlobalService.showAlertSuccess("Correo enviado satistactoriamente");
            dialogRef.closed;
          });
        }
      });
  }

  getTypeOfPeriod(type: string) {
    return this.accountStatement.conditionsData.typeOfPeriod
  }

  onDeletePayment(loanPaymentId: string, amount: number) {
    this.dialog
      .open(DialogConfirmComponent, {
        data: {
          message: '¿Desea eliminar el pago de ' + this.currencyPipe.transform(amount) + '?'
        }
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.loanpaymentService.deleteLoanPayment(loanPaymentId)
            .subscribe(() => {
              this.alertGlobalService.showAlertSuccess("Pago eliminado con éxito.");
              this.getAccountStatement(this.loanId);
            });
        }
      });
  }
}
