import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Alert } from 'bootstrap';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-confirmcode-change-email',
  templateUrl: './confirmcode-change-email.component.html',
  styleUrls: ['./confirmcode-change-email.component.scss']
})
export class ConfirmcodeChangeEmailComponent implements OnInit {
  oldValue: string;
  newValue: string;
  userId:string;

  form: FormGroup;
  submitted = false;
  requiredErrorMessage: string = "Este campo es requerido.";
  alertGlobalService: any;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private dialogRef: MatDialogRef<ConfirmcodeChangeEmailComponent>,) {
    this.createForm();
  }

  get f1() { return this.form.controls; }

  createForm() {
    this.form = this.formBuilder.group({
      code: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitted = true;

    if(this.form.invalid) {
      return;
    }

    this.changeEmail();
  }


  validateRequiredForm(formControlName: string): boolean {
    if(this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {

      return true;
    }
    return false;
  }

  changeEmail() {
    this.userService.changeEmail(this.oldValue, this.newValue, this.f1['code'].value)
    .subscribe((data) => {
      this.dialogRef.close();
    });
    this.SendMailConfirmChangeEmail();
  }


  SendMailConfirmChangeEmail() {
    this.userService.sendConfirmChangeEmail(this.userId).subscribe((result) => {
      //this.alertGlobalService.showAlertSuccess("si senencio Confirmacion");
    });
  }
}
