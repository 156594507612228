import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeofbillService {

  typeOfBillList: KeyValue<number, string>[] = [
    { key: 1, value: "Suscripciones" },
    { key: 2, value: "Recargas de saldo" },
  ];

  private typeOfBillList$ = new BehaviorSubject<KeyValue<number, string>[]>(this.typeOfBillList);

  constructor() { }

  getList() {
    return this.typeOfBillList$.asObservable();
  }
}
