import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TypeOfPeriod } from '../../global/global-data';

@Injectable({
  providedIn: 'root'
})
export class TypeofperiodService {

  typeOfPeriodList: KeyValue<number, string>[] = [
    { key: TypeOfPeriod.DAILY, value: "Diario" },
    { key: TypeOfPeriod.WEEKLY, value: "Semanal" },
    { key: TypeOfPeriod.FOURTEEN, value: "Catorcenal" },
    { key: TypeOfPeriod.BIWEEKLY, value: "Quincenal" },
    { key: TypeOfPeriod.MONTHLY, value: "Mensual" },
    { key: TypeOfPeriod.BIMONTHLY, value: "Bimestral" },
    { key: TypeOfPeriod.QUARTERLY, value: "Trimestral" },
    { key: TypeOfPeriod.BIANNUAL, value: "Semestral" },
    { key: TypeOfPeriod.ANNUAL, value: "Anual" },
  ];

  private typeOfPeriodList$ = new BehaviorSubject<KeyValue<number, string>[]>(this.typeOfPeriodList);

  constructor() { }

  getList() {
    return this.typeOfPeriodList$.asObservable();
  }
}
