import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { GlobalLists } from 'src/app/global/global-data';

@Component({
	selector: 'app-dialog-family-data',
	templateUrl: './dialog-family-data.component.html',
	styleUrls: ['./dialog-family-data.component.scss']
})

export class DialogFamilyDataComponent implements OnInit {
	familyDataJsonForm: FormGroup = this.formBuilder.group({});	
	elementsFormRequest: any;
	FormrequestJson: FormRequestAnswer[];
	panelFamilyDataState = false;
	maritalStatusList = GlobalLists.maritalStatusList;
	maritalRegimeList = GlobalLists.maritalRegimeList;
	occupationList = GlobalLists.occupationList;
	phoneMask = GlobalLists.phoneMask;

	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService
		) {		
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get f8() { return this.familyDataJsonForm.controls; }

	ngOnInit(): void {
		this.createFamilyDataForm();

		if (this.completed) {
			this.dr = true;
		}
	}

	getIsDisabledSave() {
		if (this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) {
			return true;
		} else {
			return false;
		}
	}

	createFamilyDataForm() {
		this.loanrequestService.getFamilyData(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.FormrequestJson = result.optionForm;
				var formJson = this.FormrequestJson;
		
				for (var key in formJson) {
					if (formJson[key]['conditionName'] != null) {
						if (this.isLender) {
							if (formJson[key]['required']) {
								this.familyDataJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.familyDataJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
							}
						} else {
							if (formJson[key]['required']) {
								this.familyDataJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.familyDataJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [ Validators.maxLength(150), Validators.minLength(0)]))
							}
						}

						if (formJson[key]['conditionName'] == "MaritalStatus") {
						}
		
						if (this.getIsDisabledSave()) {
							this.f8["value-" + formJson[key]['conditionName']].disable();
						}
					}
				}

				this.onChangeSelectMaritalStatus(this.f8['value-MaritalStatus'].value);
			}
		});
	}

	validateRequiredFormF8(formControlName: string): boolean {
		if(this.f8[formControlName].errors !== null && this.f8[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateFormrequestJson(val: string) {
		if (!this.validateFormrequest(val)) {
			return;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	validateFormrequest(val: string) {
		if (this.FormrequestJson == null) {
			return false;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val) != null) {
			return true;
		}

		return false;
	}

	panelFamilyDataOpen(e) {
		this.panelFamilyDataState = e;
	}

	onChangeSelectMaritalStatus(value: string) {
		if (value == "1") {
			this.f8['value-MaritalRegime'].enable()
			this.f8['value-MaritalRegime'].patchValue("");
		} else {
			this.f8['value-MaritalRegime'].disable({onlySelf: true})
			this.f8['value-MaritalRegime'].patchValue("");
		}
	}

	getOccupationList(personType: string) {
		if (personType == "1" ) {
			return this.occupationList.filter(x => (x.key >= 10 && x.key <= 14))
		} else {
			return this.occupationList.filter(x => (x.key >= 0 && x.key <= 9) || (x.key == 14))
		}
	}
}
