<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
    <mat-form-field fxFlex="20%">
        <mat-label>Filtro</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #filterText autocapitalize="off" autocomplete="off">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
</div>
<table mat-table matTableResponsive [dataSource]="quoteDataSource" matSort>
    <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha que se guardó </th>
        <td mat-cell *matCellDef="let row"> {{row.dateCreated | date}} </td>
    </ng-container>
    <ng-container matColumnDef="loanAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto del préstamo </th>
        <td mat-cell *matCellDef="let row"> {{row.loanAmount | currency}} </td>
    </ng-container>
    <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Inicio del préstamo </th>
        <td mat-cell *matCellDef="let row"> {{row.startDate | date}} </td>
    </ng-container>    
    <ng-container matColumnDef="typeOfPeriod">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Frecuencia de pagos </th>
        <td mat-cell *matCellDef="let row"> {{getTypeofperiodDesc(row.typeOfPeriod)}} </td>
    </ng-container>
    <ng-container matColumnDef="totalPeriods">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de pagos </th>
        <td mat-cell *matCellDef="let row"> {{row.totalPeriods}} </td>
    </ng-container>
    <ng-container matColumnDef="monthlyRate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Interés mensual </th>
        <td mat-cell *matCellDef="let row"> {{row.monthlyRate * 0.0100 | percent}} </td>
    </ng-container>
    <ng-container matColumnDef="totalInterest">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto intereses </th>
        <td mat-cell *matCellDef="let row"> {{row.totalInterest | currency}} </td>
    </ng-container>
    <ng-container matColumnDef="totalCommissions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto comisiones </th>
        <td mat-cell *matCellDef="let row"> {{row.totalCommissions | currency}} </td>
    </ng-container>
    <ng-container matColumnDef="totalPayment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto total a pagar </th>
        <td mat-cell *matCellDef="let row"> {{row.totalPayment | currency}} </td>
    </ng-container>
    <ng-container matColumnDef="goToSimulator">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
            <a title="Abrir contenido" (click)="goToSimulator(row.quoteId)" mat-flat-button><mat-icon>open_in_new</mat-icon></a>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="quoteColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: quoteColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
