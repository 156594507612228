import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { NotificationService } from '../../services/notification/notification.service';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private hubConnection: signalR.HubConnection;

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.wsURL + '/notification')
      .build();

    this.hubConnection
      .start()
      .then()
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public notificationListener = () => {
    this.hubConnection.on('send', data => {
      if (this.authService.currentUserValue.userId === (data.userId as string)) {
        
        this.notificationService.getCategoriesAvailable()
          .subscribe((result) => {
            result.forEach((item) => {
              this.notificationService.getNotificationsReceived(item.name).subscribe();
            });
          });

          this.toastr.info(data.title, data.bodyMessage);
      }      
    });
  }
  
  constructor(private notificationService: NotificationService,
            private toastr: ToastrService,
            private authService: AuthenticationService) {}
}
