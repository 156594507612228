<div class="container-fluid">
	<div class="card">
		<div class="card-body">
			<div class="row">
				<div class="col-lg-3 col-md-5 mb-md-0 mb-2">
					<div class="d-flex">
						<div class="me-3">
							<img class="avatar-md rounded-circle img-thumbnail" src="{{ borrowerProfilePicture }}"
								alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
						</div>
						<div class="flex-grow-1 align-self-center">
							<div class="text-muted">
								<p class="mb-2" *ngIf="!isLender">
									Préstamo adquirido con
								</p>
								<p class="mb-2" *ngIf="isLender">
									Solicitante
								</p>
								<h5 class="mb-0">
									{{ capitalizeFirstLetter(borrowerName) }}
								</h5>
							</div>
						</div>
					</div>
				</div>
				<div class="row col-lg-9 col-md-7">
					<div class="col-md-4 col-6">
						<p class="text-muted text-truncate mb-1">Monto del préstamo</p>
						<h5 class="mb-2">{{totalAmortize | currency}}</h5>
					</div>
					<div class="col-md-4 col-6">
						<p class="text-muted text-truncate mb-1">Fecha de inicio</p>
						<h5 class="mb-2">{{startDate | date}}</h5>
					</div>
					<div class="col-md-4 col-6">
						<p class="text-muted text-truncate mb-1">Estatus</p>
						<h5 class="mb-2">{{statusName}}</h5>
					</div>
					<div class="col-md-4 col-6"
						*ngIf="accountStatement != null && accountStatement.accountStatement != null">
						<p class="text-muted text-truncate mb-1">Saldo total a liquidar</p>
						<h5 class="mb-2">{{accountStatement.accountStatement.resumeData.totalBalanceToLiquited |
							currency}}</h5>
					</div>
					<div class="col-md-4 col-6"
						*ngIf="accountStatement != null && accountStatement.accountStatement != null">
						<p class="text-muted text-truncate mb-1">Días con atraso</p>
						<h5 class="mb-2">{{ accountStatement.accountStatement.resumeData.daysLate}}
						</h5>
					</div>
					<div class="col-md-4 col-6"
						*ngIf="accountStatement != null && accountStatement.accountStatement != null">
						<p class="text-muted text-truncate mb-1">Saldo con atraso total</p>
						<h5 class="mb-2">{{accountStatement.accountStatement.resumeData.totalBalanceLate | currency}}
						</h5>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card">
		<div class="card-body">
			<mat-horizontal-stepper class="loanResume" #stepperLoan labelPosition="bottom" [@.disabled]="false">
				<ng-template matStepperIcon="edit">
					<mat-icon>check</mat-icon>
				</ng-template>
				<ng-template matStepperIcon="active">
					<mat-icon>check</mat-icon>
				</ng-template>
				<mat-step [optional]="false" optional>
					<ng-template matStepLabel>Datos préstamo</ng-template>
					<mat-accordion>
						<mat-expansion-panel [expanded]="panelDetailsOpenState" (opened)="panelDetailsOpenState = true;"
							(closed)="panelDetailsOpenState = false;">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Ver condiciones</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="row">
								<h5>Detalle de Solicitud de Préstamo</h5>
								<p>De acuerdo a las condiciones definidas por el Usuario</p>
								<div class="d-flex justify-content-between">
									<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
										<div>
											<p class="text-muted mb-1">Fecha</p>
											<h5 class="mb-0">{{ startDate | date }}</h5>
										</div>
									</div>
									<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
										<div>
											<p class="text-muted mb-1">ID Préstamo</p>
											<h5 class="mb-0">{{ loanId }}</h5>
										</div>
									</div>
								</div>
								<hr>

								<h5 class="text-center mb-4">PARTICIPANTES</h5>
								<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Nombre Solicitante</p>
										<h5 class="mb-0">{{ borrowerName }}</h5>
									</div>
								</div>
								<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Correo electrónico</p>
										<h5 class="mb-0">{{ borrowerMail }}</h5>
									</div>
								</div>
								<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Teléfono celular</p>
										<h5 class="mb-0">{{ borrowerPhoneMobile }}</h5>
									</div>
								</div>
								<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Nombre Prestamista</p>
										<h5 class="mb-0">{{ lenderCustomerFullName }}</h5>
									</div>
								</div>
								<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Correo electrónico</p>
										<h5 class="mb-0">{{ lenderEmail }}</h5>
									</div>
								</div>
								<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Teléfono celular</p>
										<h5 class="mb-0">{{ lenderPhoneMobile }}</h5>
									</div>
								</div>
								<hr>

								<h5 class="text-center mb-4">CONDICIONES</h5>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Monto del préstamo</p>
										<h5 class="mb-0">{{totalAmortize | currency}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Tipo de préstamo</p>
										<h5 class="mb-0">{{typeOfCredit}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Fecha de Inicio</p>
										<h5 class="mb-0">{{startDate | date}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Cálculo de intereses</p>
										<h5 class="mb-0">{{typeOfAmortization}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Frecuencia de pagos</p>
										<h5 class="mb-0">{{typeOfPeriodDesc}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Período del primer pago</p>
										<h5 class="mb-0">{{periodAmortizeStart}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Número de pagos</p>
										<h5 class="mb-0">{{totalPeriods}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Tipo de Garantía</p>
										<h5 class="mb-0">{{warrantys}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Plazo del préstamo</p>
										<h5 class="mb-0">{{totalPeriods}} {{typeOfPeriodDesc}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Interés moratorio mensual</p>
										<h5 class="mb-0">{{monthlyPorcentOnArrears * 0.0100 | percent:'1.2-5'}}
										</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Interés mensual</p>
										<h5 class="mb-0">{{monthlyRate * 0.0100 | percent:'1.2-5'}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">IVA</p>
										<h5 class="mb-0">{{taxPorcent * 0.0100 | percent:'1.2-5'}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Pago periódico</p>
										<h5 class="mb-0">{{periodicPayment == 'Variable' ?periodicPayment :
											periodicPayment | currency}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Comisiones</p>
										<h5 class="mb-0">{{isCommission}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Monto Total a pagar</p>
										<h5 class="mb-0">
											{{amortizeService.currentAmortizeTableValue.totalPeriodPay | currency}}</h5>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
									<div>
										<p class="text-muted mb-1">Pagos personalizados</p>
										<h5 class="mb-0">{{customPayments}}</h5>
									</div>
								</div>
								<hr>

								<h5 class="text-center mb-4">RESUMEN</h5>
								<div class="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
									<div>
										<p class="text-muted mb-1">Monto del préstamo</p>
										<h5 class="mb-0">{{totalAmortize | currency}}</h5>
									</div>
								</div>
								<div class="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
									<div>
										<p class="text-muted mb-1">Monto Interés total</p>
										<h5 class="mb-0">{{totalInterest | currency}}</h5>
									</div>
								</div>
								<div class="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
									<div>
										<p class="text-muted mb-1">Monto Comisiones total</p>
										<h5 class="mb-0">{{totalCommission | currency}}</h5>
									</div>
								</div>
								<div class="col-12">
									<div class="text-center">
										<p class="text-muted mb-2">Total a pagar</p>
										<h4 class="mb-0">
											{{amortizeService.currentAmortizeTableValue.totalPeriodPay | currency}}</h4>
									</div>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Tabla de amortización</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<app-amortization-table #amortizationTable [hiddenPaymentsColumn]="true"
								[disabledPaymentsColumn]="true"></app-amortization-table>
						</mat-expansion-panel>
					</mat-accordion>

					<div class="d-flex flex-wrap gap-2 justify-content-end">
						<button class="btn btn-outline-primary external-btn" (click)="exportExcel()">
							Excel
						</button>
						<button class="btn btn-outline-primary external-btn" matStepperPrevious routerLink="/prestamos">
							Cerrar
						</button>
					</div>
				</mat-step>

				<mat-step [optional]="false" [editable]="true" optional>
					<ng-template matStepLabel>Expediente</ng-template>
					<mat-accordion>
						<mat-expansion-panel [expanded]="panelIntegrateFileOpenState"
							(opened)="panelIntegrateFileOpen(true)" (closed)="panelIntegrateFileOpen(false)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Ver datos solicitante</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<form class="form-horizontal" [formGroup]="formrequestJsonForm">
								<div class="w-100">
									<div *ngIf="loadFormRequestJsonForm">
										<div class="row">
											<app-dialog-person-data #personData [mode]="mode"
												[legalConditionsValue]="legalConditionsValue" [personType]="personType"
												[loanRequestStatus]="loanRequestStatus" [dr]="dr"
												[loanRequestId]="loanRequestId"
												[borrowerCustomerId]="borrowerCustomerId"
												[contactRelationship]="contactRelationShip"
												[loanRequestDTO]="loanRequestDTO"
												[isDisabled]="true"></app-dialog-person-data>
											<app-dialog-address-data #addressData [personType]="personType"
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="addressDataJsonCompleted">
											</app-dialog-address-data>
											<app-dialog-contact-data #contactData [personType]="personType"
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[borrowerMail]="borrowerMail"
												[borrowerPhoneMobile]="borrowerPhoneMobile" [dr]="dr"
												[completed]="contactDataJsonCompleted">
											</app-dialog-contact-data>

											<app-dialog-economic-activity *ngIf="economicActivityActive"
												#economicActivityData [personData]="personData"
												[occupation]="occupation" [personType]="personType"
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="economicActivityDataJsonCompleted">
											</app-dialog-economic-activity>
											<app-dialog-patrimonial-data *ngIf="patrimonialActive" #patrimonialData
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="patrimonialDataJsonCompleted">
											</app-dialog-patrimonial-data>
											<app-dialog-economic-flow *ngIf="economicFlowActive" #economicFlowData
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="economicFlowDataJsonCompleted">
											</app-dialog-economic-flow>
											<app-dialog-family-data *ngIf="familyActive" #familyData
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="familyDataJsonCompleted">
											</app-dialog-family-data>
											<app-dialog-reference-data *ngIf="referenceActive" #referenceData
												[personType]="personType" [loanRequestStatus]="loanRequestStatus"
												[loanRequestId]="loanRequestId" [dr]="dr"
												[completed]="referenceDataJsonCompleted">
											</app-dialog-reference-data>
											<app-dialog-socioeconomic *ngIf="socioeconomicActive" #socioeconomicData
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="socioeconomicDataJsonCompleted">
											</app-dialog-socioeconomic>
											<app-dialog-bank-data *ngIf="bankActive" #bankData [personType]="personType"
												[loanRequestDTO]="loanRequestDTO"
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="bankDataJsonCompleted"></app-dialog-bank-data>
											<app-dialog-balance-sheet-data *ngIf="balanceSheetActive" #balanceSheetData
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="balanceSheetDataJsonCompleted">
											</app-dialog-balance-sheet-data>
											<app-dialog-status-result *ngIf="statusResultActive" #statusResultData
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="statusResultDataJsonCompleted">
											</app-dialog-status-result>
											<app-dialog-pld-data *ngIf="pldActive" #pldData
												[loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
												[dr]="dr" [completed]="pldDataJsonCompleted"></app-dialog-pld-data>
										</div>
									</div>
								</div>
							</form>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion *ngIf="elementsFormWarrantys.length > 0">
						<mat-expansion-panel [expanded]="panelWarrantyOpenState" (opened)="panelWarrantyOpen(true)"
							(closed)="panelWarrantyOpen(false)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<div class="d-flex">
										<h5>Ver datos garantía(s)</h5>
										<i class="bx ms-3 bx-check"></i>
									</div>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<div class="table-responsive">
								<div class="react-bootstrap-table">
									<table mat-table *ngIf="dataSourceformWarrantys"
										[dataSource]="dataSourceformWarrantys">
										<ng-container matColumnDef="number">
											<th mat-header-cell *matHeaderCellDef> Número </th>
											<td mat-cell *matCellDef="let row"> {{row.number}} </td>
										</ng-container>
										<ng-container matColumnDef="warranty">
											<th mat-header-cell *matHeaderCellDef> Garantías </th>
											<td mat-cell *matCellDef="let row">
												{{row.warranty}}</td>
										</ng-container>
										<ng-container matColumnDef="warrantyFormCompleted">
											<th mat-header-cell *matHeaderCellDef> Estatus </th>
											<td mat-cell *matCellDef="let row">
												<p *ngIf="row.warrantyFormCompleted == 2">
													Completo </p>
												<p *ngIf="row.warrantyFormCompleted == 1">
													Pendiente por validar </p>
												<p *ngIf="row.warrantyFormCompleted == 0">
													Incompleto </p>
											</td>
										</ng-container>
										<ng-container matColumnDef="warrantyForm">
											<th mat-header-cell *matHeaderCellDef> Datos </th>
											<td mat-cell *matCellDef="let row">
												<a mat-button
													(click)="openWarrantyDetails(row.number, row.warranty, row.warrantyForm)">Ver
													Datos</a>
											</td>
										</ng-container>

										<tr mat-header-row *matHeaderRowDef="columnsFormWarrantys"></tr>
										<tr mat-row *matRowDef="let row; columns: columnsFormWarrantys;"></tr>
									</table>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion>
						<mat-expansion-panel [expanded]="panelDocumentsOpenState" (opened)="panelDocumentsOpen(true)"
							(closed)="panelDocumentsOpen(false)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Ver documentos</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<p>Visualiza los documentos requeridos en la solicitud de préstamo</p>

							<div class="table-responsive">
								<div class="react-bootstrap-table">
									<table mat-table [dataSource]="dataSourceRequiredDocuments">
										<ng-container matColumnDef="description">
											<th mat-header-cell *matHeaderCellDef> Documento </th>
											<td mat-cell *matCellDef="let row"> {{row.Description}} </td>
										</ng-container>
										<ng-container matColumnDef="required">
											<th mat-header-cell *matHeaderCellDef> Requerido </th>
											<td mat-cell *matCellDef="let row; let i = index">
												<mat-slide-toggle *ngIf="isLender" disabled [(ngModel)]="row.Required">
												</mat-slide-toggle>
												<mat-slide-toggle *ngIf="!isLender" [disabled]="true"
													[(ngModel)]="row.Required">
												</mat-slide-toggle>
											</td>
										</ng-container>
										<ng-container matColumnDef="uploaded">
											<th mat-header-cell *matHeaderCellDef> Cargado </th>
											<td mat-cell *matCellDef="let row; let i = index">
												<i class="bx bx-check"
													*ngIf="row.Uploaded && row.Required && (!progress[i] || progress[i] === 100)"></i>
												<mat-progress-spinner *ngIf="progress[i] > 0 && progress[i] < 99"
													color="primary" mode="determinate" [value]="progress[i]"
													strokeWidth="5" diameter="30">
												</mat-progress-spinner>
											</td>
										</ng-container>
										<ng-container matColumnDef="viewFile">
											<th mat-header-cell *matHeaderCellDef> Ver </th>
											<td mat-cell *matCellDef="let row; let i = index">
												<i class="fas fa-eye" [attr.disabled]="!row.Required || !row.uploaded"
													(click)="previewFile(row.UrlNavigate, row.fileName)"></i>
											</td>
										</ng-container>
										<tr mat-header-row *matHeaderRowDef="columnsRequiredDocuments"></tr>
										<tr mat-row *matRowDef="let row; columns: columnsRequiredDocuments;"></tr>
									</table>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion *ngIf="legalConditionsValue != 1">
						<mat-expansion-panel [expanded]="panelDocumentsLegalLoadOpenState"
							(opened)="panelDocumentsLegalLoadOpen(true)" (closed)="panelDocumentsLegalLoadOpen(false)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Ver documentos legales</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<p>Visualiza los documentos requeridos en la solicitud de préstamo</p>

							<div class="table-responsive">
								<div class="react-bootstrap-table">
									<table mat-table [dataSource]="dataSourceRequiredDocumentsLegal">
										<ng-container matColumnDef="description">
											<th mat-header-cell *matHeaderCellDef> Documento </th>
											<td mat-cell *matCellDef="let row"> {{row.Description}} </td>
										</ng-container>
										<ng-container matColumnDef="required">
											<th mat-header-cell *matHeaderCellDef> Requerido </th>
											<td mat-cell *matCellDef="let row; let i = index">
												<mat-slide-toggle *ngIf="isLender" disabled [(ngModel)]="row.Required">
												</mat-slide-toggle>
												<mat-slide-toggle *ngIf="!isLender" [disabled]="true"
													[(ngModel)]="row.required"></mat-slide-toggle>
											</td>
										</ng-container>
										<ng-container matColumnDef="uploaded">
											<th mat-header-cell *matHeaderCellDef> Cargado </th>
											<td mat-cell *matCellDef="let row; let i = index">
												<i class="bx bx-check"
													*ngIf="row.Uploaded && row.Required && (!progress[i] || progress[i] === 100)"></i>
												<mat-progress-spinner *ngIf="progress[i] > 0 && progress[i] < 99"
													color="primary" mode="determinate" [value]="progress[i]"
													strokeWidth="5" diameter="30">
												</mat-progress-spinner>
											</td>
										</ng-container>
										<ng-container matColumnDef="viewFile">
											<th mat-header-cell *matHeaderCellDef> Ver</th>
											<td mat-cell *matCellDef="let row; let i = index">
												<i class="fas fa-eye"
													(click)="previewFile(row.UrlNavigate, row.fileName)"></i>
											</td>
										</ng-container>
										<tr mat-header-row *matHeaderRowDef="columnsRequiredDocumentsLegal">
										</tr>
										<tr mat-row *matRowDef="let row; columns: columnsRequiredDocumentsLegal;">
										</tr>
									</table>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<div class="d-flex flex-wrap gap-2 justify-content-end">
						<button class="btn btn-outline-primary external-btn" routerLink="/prestamos">
							Cerrar
						</button>
					</div>
				</mat-step>

				<mat-step [optional]="false" [editable]="true" optional>
					<ng-template matStepLabel>Estado de Cuenta</ng-template>

					<app-account-statement #accountStatement *ngIf="loadFormRequestJsonForm" [loanId]="id">
					</app-account-statement>

					<div class="d-flex flex-wrap gap-2 justify-content-end">
						<button class="btn btn-outline-primary external-btn" routerLink="/prestamos">
							Cerrar
						</button>
					</div>
				</mat-step>

				<mat-step [optional]="false" [editable]="true" optional>
					<ng-template matStepLabel>Registrar Pagos</ng-template>
					<mat-accordion>
						<mat-expansion-panel [expanded]="panelLoanProceedingsOpenState"
							(opened)="panelLoanProceedingsOpen(true)" (closed)="panelLoanProceedingsOpen(false)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Registrar pagos</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<app-dialog-loanpayment *ngIf="loadFormRequestJsonForm" [loanId]="id"
								[fromCustomerId]="fromCustomerId" [toCustomerId]="toCustomerId"
								[lenderEmail]="lenderEmail" [borrowerMail]="borrowerMail">
							</app-dialog-loanpayment>
						</mat-expansion-panel>
					</mat-accordion>

					<div class="d-flex flex-wrap gap-2 justify-content-end">
						<button class="btn btn-outline-primary external-btn" routerLink="/prestamos">
							Cerrar
						</button>
					</div>
				</mat-step>

				<mat-step [optional]="false" [editable]="true" optional>
					<ng-template matStepLabel>Cobranza</ng-template>
					<mat-accordion>
						<mat-expansion-panel [expanded]="panelLoanProceedingsOpenState" class="mb-4"
							(opened)="panelLoanProceedingsOpen(true)" (closed)="panelLoanProceedingsOpen(false)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Servicios adicionales</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<p>Selecciona el(los) servicio(s) que quieras agregar para mejorar tu proceso de cobranza
							</p>

							<mat-horizontal-stepper #stepperExternalServices class="stepperExternalServices" labelPosition="bottom" [@.disabled]="false">
								<mat-step>
									<div *ngIf="dataSourceExternalServices" class="d-flex justify-content-center">
										<div class="row justify-content-around col-12">
											<div class="col-md-5" *ngFor="let item of dataSourceExternalServices.data">
												<div class="card">
													<div class="card-body p-4">
														<div class="d-flex align-items-center">
															<i class="bx big primary" [ngClass]="item.image"></i>
															<div class="ms-4">
																<h5 class="fw-bold">
																	{{item.serviceName}}
																</h5>
																<p class="mb-1">{{item.description}}{{item.serviceName
																	== 'Visita de cobranza' ? '.' : ''}}</p>
																	<p class="mb-2">
																		{{(item.serviceName === 'Visita de cobranza' || item.serviceName === 'Visita de verificación') ? '*Sujeto a ' : '*Disponible en todo México'}}
																		<a *ngIf="item.serviceName === 'Visita de cobranza'" class="fw-bold text-link black" (click)="showConditionsExternalServices('covarage')">cobertura</a>
																	  </p>
																<p class="mb-0"><a class="text-link"
																		(click)="showConditionsExternalServices(item.serviceName)">Ver
																		condiciones</a>
																</p>
															</div>
														</div>
														<div class="d-flex justify-content-lg-between justify-content-center align-items-center flex-wrap mt-4">
                              <h2 class="fw-bold mb-0 pe-3">
																{{item.amount | currency}} <span
																	class="small_text">MXN</span>
															</h2>
															<button class="btn_yellow" (click)="openDetails(item)"
																matStepperNext>
																<a class="nav-link">Seleccionar</a>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-step>

								<mat-step>
									<app-external-service-details #externalServiceDetails *ngIf="showExternalServices"
										[personData]="personData" [addressData]="addressData"
										[externalServiceSelected]="externalServiceSelected"
										[accountStatement]="accountStatement"
										[legalConditionsValue]="legalConditionsValue"
										[stepperExternalServices]="stepperExternalServices">
									</app-external-service-details>
								</mat-step>
							</mat-horizontal-stepper>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion>
						<mat-expansion-panel (opened)="panelContactDataOpen(true)"
							(closed)="panelContactDataOpen(false)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<div class="d-flex">
										<h5>Historial de cobranza</h5>
									</div>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="table-responsive">
								<table *ngIf="dsCollectionLog" mat-table [dataSource]="dsCollectionLog">
									<ng-container matColumnDef="serviceName">
										<th mat-header-cell *matHeaderCellDef>Servicio</th>
										<td mat-cell *matCellDef="let row"> {{ row.serviceName }} </td>
									</ng-container>

									<ng-container matColumnDef="dateCreated">
										<th mat-header-cell *matHeaderCellDef>Fecha</th>
										<td mat-cell *matCellDef="let row"> {{row.dateCreated | date:'d MMM y'}} </td>
									</ng-container>

									<ng-container matColumnDef="amount">
										<th mat-header-cell *matHeaderCellDef>Costo</th>
										<td mat-cell *matCellDef="let row"> {{ row.amount | currency }} </td>
									</ng-container>

									<ng-container matColumnDef="statusName">
										<th mat-header-cell *matHeaderCellDef>Estatus</th>
										<td mat-cell *matCellDef="let row"> {{ row.statusName }} </td>
									</ng-container>

									<ng-container matColumnDef="id">
										<th mat-header-cell *matHeaderCellDef>Ver</th>
										<td mat-cell *matCellDef="let row">
											<div *ngIf="row.serviceName == 'Llamada de cobranza' || row.status == 3 && row.serviceName == 'Visita de cobranza'" (click)="openLink(row)">
												<i class="bx bx-show"></i>
											</div>
										</td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="columnsExternalServices"></tr>
									<tr mat-row *matRowDef="let row; columns: columnsExternalServices;"></tr>
								</table>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<div class="d-flex flex-wrap gap-2 justify-content-end">
						<button class="btn btn-outline-primary external-btn" routerLink="/prestamos">
							Cerrar
						</button>
					</div>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
	</div>
</div>
