<h1 *ngIf="data.promotion == null" mat-dialog-title>{{!data.title ? 'Confirmación' : data.title}}</h1>
<div>
    <p>{{data.message}}</p>
</div>
<div *ngIf="data.promotion">
    <img class="w-100" src="/assets/images/promoLanzamiento.webp" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy">
</div>
<div mat-dialog-actions align="end">
    <div *ngIf="data.accept">
        <button class="btn btn-outline-primary" (click)="confirm()">Cerrar</button>
    </div>
    <div class="d-flex flex-wrap gap-2 justify-content-end" *ngIf="!data.accept">
        <button class="btn btn-outline-primary" [ngClass]="data.btnCancel == null ? 'external-btn' : ''" (click)="closeDialog()">{{ data.btnCancel != null ? data.btnCancel : 'No' }}</button>
        <button class="btn btn-primary" [ngClass]="data.btnConfirm == null ? 'external-btn' : ''" (click)="confirm()" cdkFocusInitial>{{ data.btnConfirm != null ? data.btnConfirm : 'Sí' }}</button>
    </div>
</div>
