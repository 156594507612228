import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { QuoteDTO } from 'src/app/models/quote/quote.dto.model';
import { Quote } from 'src/app/models/quote/quote.model';
import { NewExists, QuoteSend } from 'src/app/models/quote/quotesend.model';
import { ApiService } from '../../services/api/api.service';
import { AlertGlobalService } from '../alert-global/alert-global.service';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private apiService: ApiService,
              private alertGlobalService: AlertGlobalService) { }

  create(quote: Quote) {
    return this.apiService.post('quote', '', quote).pipe(map(response => {
      if (response.status === 201) {
        this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
        localStorage.removeItem('quoteTemp');
        localStorage.removeItem('tableAmortizeTemp');
      }
      return response.data as String;
    }));
  }

  send(quoteSend: QuoteSend) {
    return this.apiService.post('quote', 'send', quoteSend).pipe(map(response => {
      if (response.status === 201) {
        this.alertGlobalService.showAlertSuccess("Solicitud enviada con éxito.");
        // localStorage.removeItem('quoteTemp');
      }
      return response.data as String;
    }));
  }

  update(quoteId: string, quote: Quote) {
    return this.apiService.put('quote', '', quoteId, quote).pipe(map(response => {
      if (response.status === 200) {
        this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
      }
      return response.data as String;
    }));
  }

  get(id: string) {
    return this.apiService.get('quote', id).pipe(map(response => {
      return response.data as QuoteDTO;
    }));
  }

  getList(customerId: string) {
    return this.apiService.get('quote', 'percustomer', customerId).pipe(map(response => {
      return response.data as QuoteDTO[];
    }));
  }

  searchCustomer() {
    return this.apiService.get('quote', 'searchCustomer').pipe(map(response => {
      return response.data as NewExists[];
    }));
  }
}
