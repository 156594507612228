import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AmortizeService } from '../../services/amortize/amortize.service';
import { AmortizeTableResponse, AmortizeTable } from '../../models/amortize/amortize.table.model';
import { Payment } from 'src/app/models/amortize/amortize.model';

@Component({
	selector: 'app-amortization-table',
	templateUrl: './amortization-table.component.html',
	styleUrls: ['./amortization-table.component.scss']
})

export class AmortizationTableComponent implements OnInit {

	retention = false;
	amortizeTableColumns: string[] = [
		'period',
		'datePayment',
		'amortization',
		'interest',
		'interestTax',
		'commission',
		'commissionTax',
		'periodPay',
		'addPayment', 
		'balance',
	];

	@Input() data: AmortizeTableResponse;

	amortizeTableResponse: AmortizeTableResponse;
	amortizeTable: AmortizeTable[];
	payments: Payment[] = [];
	@Input() hiddenPaymentsColumn: boolean = false;
	@Input() disabledPaymentsColumn: boolean = false;
	customPayments = false;

	constructor(private amortizeService: AmortizeService,
				private cdRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		if (!this.data) {
			this.init();
		}
		else {
			this.amortizeTableResponse = this.data;
			this.amortizeTable = this.data.table;
			this.hiddenPaymentsColumn = true;
		}
	}

	getInterestTotal() {
		return this.amortizeTable.map(x => x.interest).reduce((acc, value) => acc + value, 0);
	}

	getInterestTaxTotal() {
		return this.amortizeTable.map(x => x.interestTax).reduce((acc, value) => acc + value, 0);
	}

	getCommissionTotal() {
		return this.amortizeTable.map(x => x.commission).reduce((acc, value) => acc + value, 0);
	}

	getCommissionTaxTotal() {
		return this.amortizeTable.map(x => x.commissionTax).reduce((acc, value) => acc + value, 0);
	}

	getPeriodPayTotal() {
		return this.amortizeTable.map(x => x.periodPay).reduce((acc, value) => acc + value, 0);
	}

	getNetPeriodPayTotal() {
		return this.amortizeTable.map(x => x.netPeriodPay).reduce((acc, value) => acc + value, 0);
	}

	getNetRetentionTotal() {
		return this.amortizeTable.map(x => x.retention).reduce((acc, value) => acc + value, 0);
	}

	init() {
		this.amortizeService.getAmortizeTableResponseObserver().subscribe((result) => {
			this.amortizeTableResponse = result;
			this.amortizeTable = this.amortizeTableResponse.table;
			this.cdRef.detectChanges();

			if (result) {
				if (result.totalRetention) {
					if (!this.amortizeTableColumns.find(x => x == 'retention' && 'netPeriodPay')) {
						this.amortizeTableColumns.push('retention');
						this.amortizeTableColumns.push('netPeriodPay');
					}

					this.retention = true;
				} else {
					this.amortizeTableColumns = this.amortizeTableColumns.filter(x => x !== "retention" && x !== "netPeriodPay");
					this.retention = false;
				}
			}
		});

		this.amortizeService.getAmortizeTableExtraPaymentsResponseObserver().subscribe((result) => {
			this.amortizeTableResponse = result;
			this.amortizeTable = this.amortizeTableResponse.table;
			this.cdRef.detectChanges();
		});
	}

	getPayment(period: number, periodPay: number): number {
		const payment = this.payments.find(x => x.period === period);
		
		if (!payment) {
			return periodPay;
		}

		return payment.amount;
	}

	addPayment(period: number, periodPay: number, value: string, t: any) {
		if (parseInt(value) > periodPay) {

			let index = this.payments.findIndex(x => x.period === period);      

			if (index >= 0) {
				this.payments.splice(index, 1);
			}

			this.payments.push({
				period: period,
				amount: parseFloat(value.toString())
			});
			
			this.amortizeService.recalculatePayments(this.payments);
		}
	}

	setPayment(payments: Payment[]) {
		this.amortizeService.recalculatePayments(payments);
	}

	resetValuePayment($event, period: number, periodPay: number) {
		let index = this.payments.findIndex(x => x.period === period);

		if ($event.srcElement.value.trim() === "") {
			$event.srcElement.value = this.getPayment(period, periodPay);
			if (index >= 0) {
			this.payments.splice(index, 1);
			this.amortizeService.recalculatePayments(this.payments);
			}
		} else if (parseFloat($event.srcElement.value.trim()) <= periodPay) {
			$event.srcElement.value = this.getPayment(period, periodPay);
			if (index >= 0) {
			this.payments.splice(index, 1);
			this.amortizeService.recalculatePayments(this.payments);
			}
		}
	}
}
