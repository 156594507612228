import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-preview-document',
  templateUrl: './dialog-preview-document.component.html',
  styleUrls: ['./dialog-preview-document.component.scss']
})
export class DialogPreviewDocumentComponent implements OnInit {

  url: string;
  extensionFile: string;

  urlSanitizer: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.urlSanitizer = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  isImage(): boolean {
    const allowedExtensions: string[] = [
      'png',
      'jpg',
      'jpeg'
    ]

    if (allowedExtensions.includes(this.extensionFile.toLowerCase())){
      return true;
    }

    return false;
  }

}
