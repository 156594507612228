<div class="container-fluid">
    <h4>Reportes</h4>
    <div class="card">
        <div class="card-body">
            <h5>Reportes generales</h5>
            <hr>

            <p>Elige la sección del reporte que deseas generar</p>

            <mat-accordion>
                <mat-expansion-panel [expanded]="panelBoardOpenState" (opened)="panelBoardOpen(true)" (closed)="panelBoardOpen(false)">

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>Tablero</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <app-home [topNav]="false"></app-home>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel [expanded]="panelColocationOpenState" (opened)="panelColocationOpen(true)"
                (closed)="panelColocationOpen(false)">

                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Colocación</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <app-loanrequest-list [made]="!isLender" [buttonsAction]="false"></app-loanrequest-list>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel [expanded]="panelCollectOpenState" (opened)="panelCollectOpen(true)"
                (closed)="panelCollectOpen(false)">

                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Cobranza</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <app-loanpayment-list [isLender]="isLender" [buttonsAction]="false"></app-loanpayment-list>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel [expanded]="panelWalletOpenState" (opened)="panelWalletOpen(true)" (closed)="panelWalletOpen(false)">

                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Cartera</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <app-loan-list [isLender]="isLender" [buttonsAction]="false"></app-loan-list>
                </mat-expansion-panel>
            </mat-accordion>

            <div class="d-flex flex-wrap gap-2 justify-content-end mt-4">
                <button class="btn btn-outline-primary external-btn" routerLink="/inicio">
                    Cerrar
                </button>
            </div>
        </div>
    </div>
</div>
