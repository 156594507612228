import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DialogTutorialComponent } from 'src/app/components/dialog-tutorial/dialog-tutorial.component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})

export class FaqComponent implements OnInit {

  isLender = false;
  myTxt = '';
  opened = false;
  borrowerSwitchActive: boolean;
  blogId = '';

  activeTab = 0;

  panelOpenState = false;

  constructor(private route: ActivatedRoute,
              private authService: AuthenticationService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isLender = this.authService.currentUserModeLenderValue;
    this.borrowerSwitchActive = false;
    this.blogId = this.route.snapshot.paramMap.get('blogId');

    $('button').click(() => {});
  }

  openDialogTutorial() {
		this.dialog.open(DialogTutorialComponent, {
			panelClass: ['md:w-3/5', 'w-full'],
      height: '90%',
  			width: '90%',
		    data: {
				title: '',
				message: '',
				accept: true
		    }
		})
		.afterClosed()
		.subscribe((confirmado: Boolean) => {
		});
	}
}
