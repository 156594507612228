import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanRequestOptionsData, PatrimonialActive, PatrimonialPassive } from 'src/app/models/loanrequest/loanrequest.model';
import { GlobalLists } from 'src/app/global/global-data';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';

@Component({
	selector: 'app-dialog-patrimonial-data',
	templateUrl: './dialog-patrimonial-data.component.html',
	styleUrls: ['./dialog-patrimonial-data.component.scss']
})

export class DialogPatrimonialDataComponent implements OnInit {
	submit = false;

	patrimonialActiveColumns = [
		'bienType',
		'estimatedValue',
		'description',
		'buttonRemove'
	];

	patrimonialPassiveColumns = [
		'debtType',
		'balancePayable',
		'description',
		'buttonRemove'
	];

	panelPatrimonialState = false
	panelActiveState = false;
	panelPassiveState = false;
	activiteList = GlobalLists.activiteList;
	passiveList = GlobalLists.passiveList;
	
	patrimonialActiveForm: FormGroup;
	patrimonialPassiveForm: FormGroup;
	patrimonialActive = <PatrimonialActive[]>[];
	patrimonialPassive = <PatrimonialPassive[]>[];
	patrimonialData: LoanRequestOptionsData;
	dsPatrimonialActiveData = new MatTableDataSource<PatrimonialActive>(this.patrimonialActive);
	dsPatrimonialPassiveData = new MatTableDataSource<PatrimonialPassive>(this.patrimonialPassive);
	
	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService,
		) {		
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get fPatrimonialActive() { return this.patrimonialActiveForm.controls; }
	get fPatrimonialPassive() { return this.patrimonialPassiveForm.controls; }

	ngOnInit(): void {
		this.createPatrimonialActiveForm();
		this.createPatrimonialPassiveForm();
		
		this.loanrequestService.getPatrimonialData(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.patrimonialActive = result.patrimonialActive;
				this.patrimonialPassive = result.patrimonialPassive;
	
				this.dsPatrimonialActiveData = new MatTableDataSource<PatrimonialActive>(this.patrimonialActive);
				this.dsPatrimonialPassiveData = new MatTableDataSource<PatrimonialPassive>(this.patrimonialPassive);
	
				this.updatePatrimonialData();

				if (this.completed) {
					this.dr = true;
				}
			}
		});
	}

	createPatrimonialActiveForm() {
		this.patrimonialActiveForm = this.formBuilder.group({
			bienType: ['', Validators.required],      
			estimatedValue: [0, Validators.required],      
			description: ['', Validators.required]
		});
	}

	createPatrimonialPassiveForm() {
		this.patrimonialPassiveForm = this.formBuilder.group({
			debtType: ['', Validators.required],      
			balancePayable: [0, Validators.required],      
			description: ['', Validators.required]
		});
	}

	onSubmitPatrimonialActive() {
		if (this.patrimonialActiveForm.invalid || this.getIsDisabledSave()) {
		  return;
		}
	
		this.addPatrimonialActive();
	}
	
	onDeletePatrimonialActive(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.patrimonialActive.splice(index, 1);
		this.dsPatrimonialActiveData = new MatTableDataSource<PatrimonialActive>(this.patrimonialActive);

		this.updatePatrimonialData();
	}
	
	addPatrimonialActive(): void {
		this.patrimonialActive.push(<PatrimonialActive>{
			bienType: this.fPatrimonialActive['bienType'].value,
			estimatedValue: parseFloat(this.fPatrimonialActive['estimatedValue'].value),
			description: this.fPatrimonialActive['description'].value,
		});

		this.dsPatrimonialActiveData = new MatTableDataSource<PatrimonialActive>(this.patrimonialActive);
		this.patrimonialActiveForm.reset();

		this.updatePatrimonialData();
	}

	onSubmitPatrimonialPassive() {
		if (this.patrimonialPassiveForm.invalid || this.getIsDisabledSave()) {
		  return;
		}
	
		this.addPatrimonialPassive();
	}
	
	onDeletePatrimonialPassive(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}
		
		this.patrimonialPassive.splice(index, 1);
		this.dsPatrimonialPassiveData = new MatTableDataSource<PatrimonialPassive>(this.patrimonialPassive);

		this.updatePatrimonialData();
	}
	
	addPatrimonialPassive(): void {
		this.patrimonialPassive.push(<PatrimonialPassive>{
			debtType: this.fPatrimonialPassive['debtType'].value,
			balancePayable: parseFloat(this.fPatrimonialPassive['balancePayable'].value),
			description: this.fPatrimonialPassive['description'].value,
		});

		this.dsPatrimonialPassiveData = new MatTableDataSource<PatrimonialPassive>(this.patrimonialPassive);
		this.patrimonialPassiveForm.reset();

		this.updatePatrimonialData();
	}

	panelPatrimonialOpen(e) {
		this.panelPatrimonialState = e;
	}

	panelActiveOpen(e) {
		this.panelActiveState = e;
	}

	panelPassiveOpen(e) {
		this.panelPassiveState = e;
	}

	updatePatrimonialData() {
		var pat = <LoanRequestOptionsData>{
			patrimonialActive: this.patrimonialActive,
			patrimonialPassive: this.patrimonialPassive
		};
		
		this.patrimonialData = pat;
	}

	getPatrimonialPassiveTotal() {
		return this.dsPatrimonialPassiveData.data.map(x => x.balancePayable).reduce((acc, value) => acc + value, 0);		
	}

	getPatrimonialActiveTotal() {
		return this.dsPatrimonialActiveData.data.map(x => x.estimatedValue).reduce((acc, value) => acc + value, 0);		
	}

	getIsDisabledSave() {
		if (this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) {
			return true;
		} else {
			return false;
		}
	}
}
