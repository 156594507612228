<h1 mat-dialog-title>Guardar solicitud</h1>
<form [formGroup]="saveQuoteForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>        
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="50%" appearance="fill">
                <mat-label>Nombre</mat-label>
                <input matInput type="text" placeholder="" formControlName="name" autocomplete="off" autocapitalize="off">
                <mat-error *ngIf="validateRequiredForm('name')">{{requiredErrorMessage}}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">            
            <mat-form-field fxFlex="50%" appearance="fill">
                <mat-label>Comentarios</mat-label>
                <textarea matInput formControlName="comments"></textarea>
                <mat-error *ngIf="validateRequiredForm('comments')">{{requiredErrorMessage}}</mat-error> 
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cerrar</button>
        <button mat-raised-button color="warn">
            <span class="material-icons">save</span> Guardar
        </button>        
    </div>
</form>