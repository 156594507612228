import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { GlobalLists } from 'src/app/global/global-data';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { Country } from 'src/app/models/loanrequest/loanrequest.model';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-dialog-address-data',
	templateUrl: './dialog-address-data.component.html',
	styleUrls: ['./dialog-address-data.component.scss']
})

export class DialogAddressDataComponent implements OnInit {
	formrequestJsonForm: FormGroup = this.formBuilder.group({});
	FormrequestJson: FormRequestAnswer[];
	loadAddressDataJsonForm = false;

	panelAddressOpenState = false;
	panelFiscalAddressOpenState = false;
	housingTypeList = GlobalLists.housingTypeList;
	homeOwnershipList = GlobalLists.homeOwnershipList;
	yesnoList = GlobalLists.yesnoList;
	
	countries: Country[];
	states: any;
	cities: any;
	fiscalCountries: Country[];
	fiscalStates: any;
	fiscalCities: any;

	tittleCity = "";
	
	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;
	@Input() personType: number;
	@Input() isDisabled: boolean = false;
	@Input() validateExternalServices: boolean = false;

	typeHousing = "";
	homeOwnership = "";

	showFiscalDomilie = false;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService,
		private catalogService: CatalogService,
		private datePipe: DatePipe
		) {
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get f2() { return this.formrequestJsonForm.controls; }

	ngOnInit(): void {
		this.getCountries();
		this.createAddressDataJsonForm(true);
	}

	getCountries() {
		this.catalogService.getCountries()
      	.subscribe((countries) => {
        	this.countries = (countries as Country[]).filter(x => x.code == "MX" || x.code == "US");
        	this.fiscalCountries = (countries as Country[]).filter(x => x.code == "MX" || x.code == "US");
      	});
	}

	getStates(countryId) {
		this.catalogService.getStates(countryId)
		.subscribe((states) => {
		this.states = states;
		});
	}

	getCitys(stateId) {
		this.catalogService.getCities(this.f2["value-CountryId"].value, stateId)
		.subscribe((cities) => {
		  this.cities = cities;
		});
	}

	getFiscalStates(countryId) {
		this.catalogService.getStates(countryId)
		.subscribe((states) => {
		this.fiscalStates = states;
		});
	}

	getFiscalCitys(stateId) {
		this.catalogService.getCities(this.f2["value-FiscalCountryId"].value, stateId)
		.subscribe((cities) => {
		  this.fiscalCities = cities;
		});
	}

	getIsDisabledSave() {
		if ((this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) && this.isDisabled) {
			return true;
		} else {
			return false;
		}
	}

	createAddressDataJsonForm(onChange: boolean) {
		if (this.personType == 1) {
			this.tittleCity = "Domicilio fiscal";
			this.typeHousing = "Tipo establecimiento";
			this.homeOwnership = "Propiedad establecimiento";
		} else {
			this.tittleCity = "Antigüedad en ciudad";
			this.typeHousing = "Tipo vivienda";
			this.homeOwnership = "Propiedad vivienda";
		}
		
		this.loanrequestService.getAddressDataRequest(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.FormrequestJson = result.optionForm;

				for (var key in this.FormrequestJson) {
					if (this.FormrequestJson[key]['conditionName'] != null) {
						if (onChange &&this.FormrequestJson[key]['conditionName'] == "CountryId" && this.FormrequestJson[key]['value'] != "") {
							this.getStates(this.FormrequestJson[key]['value']);
						}
		
						if (onChange && this.FormrequestJson[key]['conditionName'] == "StateId" && this.FormrequestJson[key]['value'] != "") {
							this.getCitys(this.FormrequestJson[key]['value']);
						}

						if (onChange &&this.FormrequestJson[key]['conditionName'] == "FiscalCountryId" && this.FormrequestJson[key]['value'] != "") {
							this.getFiscalStates(this.FormrequestJson[key]['value']);
						}
		
						if (onChange && this.FormrequestJson[key]['conditionName'] == "FiscalStateId" && this.FormrequestJson[key]['value'] != "") {
							this.getFiscalCitys(this.FormrequestJson[key]['value']);
						}

						if (this.isLender) {
							if (this.FormrequestJson[key]['required']) {
								this.formrequestJsonForm.addControl("value-" + this.FormrequestJson[key]['conditionName'], new FormControl(this.FormrequestJson[key]['value'] + "", [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.formrequestJsonForm.addControl("value-" + this.FormrequestJson[key]['conditionName'], new FormControl(this.FormrequestJson[key]['value'] + "", [Validators.maxLength(150), Validators.minLength(0)]))
							}
						} else {
							if (this.FormrequestJson[key]['required']) {
								this.formrequestJsonForm.addControl("value-" + this.FormrequestJson[key]['conditionName'], new FormControl(this.FormrequestJson[key]['value'] + "", [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.formrequestJsonForm.addControl("value-" + this.FormrequestJson[key]['conditionName'], new FormControl(this.FormrequestJson[key]['value'] + "", [Validators.maxLength(150), Validators.minLength(0)]))
							}
						}

						if (this.getIsDisabledSave()) {
							this.f2["value-" + this.FormrequestJson[key]['conditionName']].disable();
						}
					}
				}

				if (this.f2['value-YearsDomicile'].value != "") {
					this.f2['value-YearsDomicile'].patchValue(this.datePipe.transform(this.f2['value-YearsDomicile'].value, 'yyyy-MM-dd'));
				}

				if (this.f2["value-FiscalDomicile"] != null && this.f2["value-FiscalDomicile"].value != "0") {
					this.showFiscalDomilie = true;
				}
				else {
					this.showFiscalDomilie = false;
				}

				this.loadAddressDataJsonForm = true;
		
				this.formrequestJsonForm.updateValueAndValidity();

				if (this.completed) {
					this.dr = true;
				}

				if (this.validateExternalServices) {
					this.requiredExternalServices();
				}
			}
		});
	}
	
	validateFormrequestJson(val: string) {
		if (!this.validateFormrequest(val)) {
			return;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	validateFormrequest(val: string) {
		if (this.FormrequestJson == null) {
			return false;
		}

		if (this.FormrequestJson.find(x => x.conditionName == val) != null) {
			return true;
		}

		return false;
	}

	validateRequiredFormF2(formControlName: string): boolean {
		if (this.f2[formControlName] == null) {
			return;
		}

		if(this.f2[formControlName].errors !== null && this.f2[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	panelAdressOpen(e) {
		this.panelAddressOpenState = e;
	}

	panelFiscalAdressOpen(e) {
		this.panelFiscalAddressOpenState = e;
	}

	onChangeSelectCountriesRequest(countryId: string, fiscal: boolean) {
		if (!fiscal) {
			this.states = [];
			this.cities = [];
			this.catalogService.getStates(countryId)
			  .subscribe((states) => {
				this.f2["value-StateId"].patchValue("");
	
				this.states = states;
			});
		} else {
			this.fiscalStates = [];
			this.fiscalCities = [];
			this.catalogService.getStates(countryId)
			  .subscribe((states) => {
				this.f2["value-FiscalStateId"].patchValue("");
	
				this.fiscalStates = states;
			});
		}
	}

	onChangeSelectStatesRequest(stateId: string, fiscal: boolean) {
		if (!fiscal) {
			this.cities = [];
			this.catalogService.getCities(this.f2["value-CountryId"].value, stateId)
			  .subscribe((cities) => {
				this.f2["value-CityId"].patchValue("");
				this.cities = cities;
			});
		} else {
			this.fiscalCities = [];
			this.catalogService.getCities(this.f2["value-FiscalCountryId"].value, stateId)
			  .subscribe((cities) => {
				this.f2["value-FiscalCityId"].patchValue("");
				this.fiscalCities = cities;
			});
		}
	}

	getHousingTypeList() {
		if (this.personType == 1 ) {
			return this.housingTypeList.filter(x => (x.key >= 4 && x.key <= 7))
		} else {
			return this.housingTypeList.filter(x => (x.key >= 0 && x.key <= 3) || (x.key == 7))
		}
	}

	onChangeFiscalDomicile(value: string) {
		if (value != "0") {
			this.showFiscalDomilie = true;
		} else {
			this.showFiscalDomilie = false;
		}
	}

	requiredExternalServices() {
		this.f2["value-Street"].setValidators([Validators.required]);
		this.f2["value-ExteriorNumber"].setValidators([Validators.required]);
		this.f2["value-DistrictName"].setValidators([Validators.required]);
		this.f2["value-CityId"].setValidators([Validators.required]);
		this.f2["value-StateId"].setValidators([Validators.required]);
		this.f2["value-ZipCode"].setValidators([Validators.required]);
	}
}