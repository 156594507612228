<div class="container-fluid">
	<h4>Centro de llamadas</h4>
	<div class="card">
		<div class="card-body">
			<div>
				<h5>Detalle de llamadas</h5>
				<hr>

				<p>Selecciona el renglón de la solicitud deseada</p>

				<div class="d-flex justify-content-end mb-4">
					<div class="form-group col-md-4 col-12">
						<label>Buscar</label>
						<div>
							<i class="bx bx-search input_icon"></i>
							<input class="form-control btn-rounded" (keyup)="applyFilter($event)" #filterText
								placeholder="" type="text" />
						</div>
					</div>
				</div>
			</div>

			<div id="content">
				<div class="table-responsive">
					<div class="react-bootstrap-table">
						<table *ngIf="callCenterDataSource" mat-table [dataSource]="callCenterDataSource"
							(dblclick)="doubleClick(rowSelected)"
							class="table table align-middle bg-primary">
							<tbody>
								<ng-container matColumnDef="dateCreated">
									<th mat-header-cell *matHeaderCellDef>Fecha de petición</th>
									<td mat-cell *matCellDef="let row"> {{row.dateCreated | date: 'mediumDate'}},<br> {{row.dateCreated | date: 'shortTime'}}</td>
								</ng-container>
								<ng-container matColumnDef="lenderFullName">
									<th mat-header-cell *matHeaderCellDef>Nombre del Prestamista</th>
									<td mat-cell *matCellDef="let row"> {{ capitalizeFirstLetter(row.lenderFullName)}} </td>
								</ng-container>
								<ng-container matColumnDef="applicantName">
									<th mat-header-cell *matHeaderCellDef>Nombre del Solicitante</th>
									<td mat-cell *matCellDef="let row"> {{ capitalizeFirstLetter(row.applicantName) }} </td>
								</ng-container>
								<ng-container matColumnDef="callTypeName">
									<th mat-header-cell *matHeaderCellDef>Tipo de llamada</th>
									<td mat-cell *matCellDef="let row"> {{row.callTypeName}} </td>
								</ng-container>
								<ng-container matColumnDef="contactTypeName">
									<th mat-header-cell *matHeaderCellDef>Tipo de contacto</th>
									<td mat-cell *matCellDef="let row"> {{row.contactTypeName}} </td>
								</ng-container>
								<ng-container matColumnDef="phone">
									<th mat-header-cell *matHeaderCellDef> Teléfono de contacto </th>
									<td mat-cell *matCellDef="let row"> {{row.phone}} </td>
								</ng-container>
								<ng-container matColumnDef="statusName">
									<th mat-header-cell *matHeaderCellDef style="width: 13%;">Estatus</th>
									<td mat-cell *matCellDef="let row">
										<div *ngIf="row.statusName === 'En proceso'">
											<button class="unclickable" type="button" mat-icon-button color="proccess">
												<mat-icon>lens</mat-icon>
											</button> {{row.statusName}}
										</div>
										<div *ngIf="row.statusName === 'Finalizado'">
											<button class="unclickable" type="button"mat-icon-button color="accent">
												<mat-icon>lens</mat-icon>
											</button> {{row.statusName}}
										</div>
									</td>
								</ng-container>
								<ng-container matColumnDef="id">
									<th mat-header-cell *matHeaderCellDef style="text-align:center;">Total de llamadas</th>
									<td mat-cell *matCellDef="let row">{{row.totalCalls}}</td>
								</ng-container>
								<ng-container matColumnDef="show">
									<th mat-header-cell *matHeaderCellDef>Ver</th>
									<td mat-cell *matCellDef="let row">
									  <div *ngIf="row.totalCalls != '0 de 5' "
										(click)="openLink(row)">
										<i class="bx bx-show"></i>
									  </div>
									</td>
								  </ng-container>
								<tr mat-header-row *matHeaderRowDef="callcenterColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: callcenterColumns; let i = index"
									(click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }">
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>

				<div class="d-flex flex-wrap gap-2 justify-content-end">
					<button class="btn btn-outline-primary external-btn" routerLink="/inicio">
						Cerrar
					</button>
					<button class="btn btn-outline-primary external-btn" [disabled]="indexSelected === null"
					(click)="openLoanrequestResumeComponent(rowSelected?.id)">
						Abrir
					</button>
				</div>
			</div>
		</div>
	</div>
</div>