<div id="content">
    <div class="table-responsive">
        <div class="react-bootstrap-table">
            <table mat-table *ngIf="amortizeTable" [dataSource]="amortizeTable">
                <ng-container matColumnDef="period">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let table"> {{table.period}} </td>
                    <td class="align-center" mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none">Total</div>

                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div>Total</div>
                                <div></div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="datePayment">
                    <th mat-header-cell *matHeaderCellDef> Fecha de Pago </th>
                    <td mat-cell *matCellDef="let table"> {{table.datePayment}} </td>
                    <td class="align-center" mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none"></div>
                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div></div>
                                <div></div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="amortization">
                    <th mat-header-cell *matHeaderCellDef> Capital </th>
                    <td mat-cell *matCellDef="let table"> {{table.amortization | currency}} </td>
                    <td class="align-center" mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none">{{amortizeTableResponse.totalAmortize | currency}}</div>
                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div>{{amortizeTableResponse.totalAmortize | currency}}</div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="interest">
                    <th mat-header-cell *matHeaderCellDef> Interés </th>
                    <td mat-cell *matCellDef="let table"> {{table.interest | currency}} </td>
                    <td class="align-center" mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none">{{getInterestTotal() | currency}}</div>
                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div>{{getInterestTotal() | currency}}</div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="interestTax">
                    <th mat-header-cell *matHeaderCellDef> IVA Interés </th>
                    <td mat-cell *matCellDef="let table"> {{table.interestTax | currency}} </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none">{{getInterestTaxTotal() | currency}}</div>
                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div>{{getInterestTaxTotal() | currency}}</div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="commission">
                    <th mat-header-cell *matHeaderCellDef> Comisión </th>
                    <td mat-cell *matCellDef="let table"> {{table.commission | currency}} </td>
                    <td class="align-center" mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none">{{getCommissionTotal() | currency}}</div>
                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div>{{getCommissionTotal() | currency}}</div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="commissionTax">
                    <th mat-header-cell *matHeaderCellDef> IVA Comisión </th>
                    <td mat-cell *matCellDef="let table"> {{table.commissionTax | currency}} </td>
                    <td class="align-center" mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none">{{getCommissionTaxTotal() | currency}}</div>
                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div>{{getCommissionTaxTotal() | currency}}</div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="periodPay">
                    <th mat-header-cell *matHeaderCellDef> Pago total </th>
                    <td mat-cell *matCellDef="let table"> {{table.periodPay | currency}} </td>
                    <td class="align-center" mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none">{{getPeriodPayTotal() | currency}}</div>
                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div>{{getPeriodPayTotal() | currency}}</div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container *ngIf="retention" matColumnDef="retention">
                    <th mat-header-cell *matHeaderCellDef> Retención ISR </th>
                    <td mat-cell *matCellDef="let table"> {{table.retention | currency}} </td>
                    <td class="align-center" mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none">{{getNetRetentionTotal() | currency}}</div>
                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div>{{getNetRetentionTotal() | currency}}</div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container *ngIf="retention" matColumnDef="netPeriodPay">
                    <th mat-header-cell *matHeaderCellDef> Pago neto </th>
                    <td mat-cell *matCellDef="let table"> {{table.netPeriodPay | currency}} </td>
                    <td class="align-center" mat-footer-cell *matFooterCellDef>
                        <div class="d-md-block d-none">{{getNetPeriodPayTotal() | currency}}</div>
                        <div class="d-md-none d-block">
                            <td class="d-flex justify-content-between font-weight-bold">
                                <div>{{getNetPeriodPayTotal() | currency}}</div>
                            </td>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="addPayment">
                    <th [hidden]="hiddenPaymentsColumn" mat-header-cell *matHeaderCellDef> Modificar Pago total </th>
                    <td [hidden]="hiddenPaymentsColumn" mat-cell *matCellDef="let table; let i = index;">
                        <mat-form-field appearance="fill" *ngIf="!disabledPaymentsColumn && table.period > 0"  class="w-100 text-right">
                            <input
                                matInput type="text"
                                #inputAddPayment
                                inputmode="numeric"
                                (focusout)="resetValuePayment($event, table.period, table.periodPay)"
                                (change)="addPayment(table.period, table.periodPay, inputAddPayment.value, 'change')"
                                (keyup)="addPayment(table.period, table.periodPay, inputAddPayment.value, 'keyup')"
                                placeholder=""
                                autocomplete="off"
                                autocapitalize="off"
                                [value]="getPayment(table.period, table.periodPay) | currency"
                                [readonly]="(amortizeTable.length -1) === i"
                                disabled
                            >
                        </mat-form-field>
                        <span *ngIf="disabledPaymentsColumn && table.period > 0">{{getPayment(table.period, table.periodPay) | currency}}</span>
                    </td>
                    <td [hidden]="hiddenPaymentsColumn" fxHide.xs mat-footer-cell *matFooterCellDef></td>
                </ng-container> 
                
                <ng-container matColumnDef="balance">
                    <th mat-header-cell *matHeaderCellDef> Saldo final </th>
                    <td mat-cell *matCellDef="let table"> {{table.balance | currency}} </td>
                    <td fxHide.xs mat-footer-cell *matFooterCellDef></td>
                </ng-container>            
                
                <tr mat-header-row *matHeaderRowDef="amortizeTableColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: amortizeTableColumns;"></tr>
                <tr fxHide.xs mat-footer-row *matFooterRowDef="amortizeTableColumns"></tr>
            </table>
        </div>
    </div>
    
    <table fxHide.sm fxHide.md fxHide.lg fxHide.xl *ngIf="amortizeTable" class="total-xs">
        <tr>
            <th>Total capital</th>
            <td >{{amortizeTableResponse.totalAmortize | currency}}</td>
        </tr>
        <tr>
            <th>Total interés</th>
            <td>{{amortizeTableResponse.totalInterest | currency}}</td>
        </tr>
        <tr>
            <th>Total interés IVA</th>
            <td>{{amortizeTableResponse.totalInterestTax | currency}}</td>
        </tr>
        <tr>
            <th>Total comisión</th>
            <td>{{amortizeTableResponse.totalCommission | currency}}</td>
        </tr>
        <tr>
            <th>Total comisión IVA</th>
            <td>{{amortizeTableResponse.totalCommissionTax | currency}}</td>
        </tr>
        <tr>
            <th>Total a pagar</th>
            <td>{{amortizeTableResponse.totalPeriodPay | currency}}</td>
        </tr>
        <tr>
            <th></th>
            <td></td>
        </tr>
    </table>
</div>