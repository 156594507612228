<div>
    <h5>
        Guardar datos Obligado Solidario
    </h5>

    <form [formGroup]="obligorJointGuaranteeJsonForm" (ngSubmit)="onSubmitFormobligorJointJson()">
        <div class="subtittle">
            Escribe o selecciona los datos del Obligado Solidario. Esta información es requerida para que la
            documentación legal de tus préstamos sea la correcta y sean válidos
        </div>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelTypePersonOpenState" class="mb-4"
                (opened)="panelTypePersonOpen(true)" (closed)="panelTypePersonOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos de persona física</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-FirstName" class="">Nombre<span
                                    *ngIf="validateFormrequestJson('FirstName')">*</span></label>
                            <input type="text" formControlName="value-FirstName" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-FirstName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-MiddleName" class="">Segundo nombre<span
                                    *ngIf="validateFormrequestJson('MiddleName')">*</span></label>
                            <input type="text" formControlName="value-MiddleName" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-MiddleName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-LastName1" class="">Primer apellido<span
                                    *ngIf="validateFormrequestJson('LastName1')">*</span></label>
                            <input type="text" formControlName="value-LastName1" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-LastName1') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-LastName2" class="">Segundo apellido<span
                                    *ngIf="validateFormrequestJson('LastName2')">*</span></label>
                            <input type="text" formControlName="value-LastName2" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-LastName2') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Alias">Alias<span
                                    *ngIf="validateFormrequestJson('Alias')">*</span></label>
                            <input type="text" formControlName="value-Alias" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-Alias') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-3">
                        <div class="form-group">
                            <label>Relación o parentesco</label>
                            <select #selectRelationShip class="form-control"
                                formControlName="value-RelationShip"
                                (change)="onChangeRelationShip(selectRelationShip.value)" required>
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of contactRelationshipList" value="{{item.key}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-RelationShipType">Tipo parentesco<span
                                    *ngIf="validateFormrequestJson('RelationShipType')">*</span></label>
                            <select class="form-control" formControlName="value-RelationShipType"
                                [ngClass]="validateRequiredFormF4('value-RelationShipType') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of typeRelationshipList" value="{{item.key}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Gender" class="">Género<span
                                    *ngIf="validateFormrequestJson('Gender')">*</span></label>
                            <select class="form-control" formControlName="value-Gender"
                                [ngClass]="validateRequiredFormF4('value-Gender') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of genders" value="{{item.code}}">{{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-BirthdayDate">Fecha de nacimiento<span
                                    *ngIf="validateFormrequestJson('BirthdayDate')">*</span></label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-BirthdayDate" type="date"
                                [ngClass]="validateRequiredFormF4('value-BirthdayDate') ? 'is-invalid' : ''" />
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-CountryBirthId" class="">País de nacimiento<span
                                    *ngIf="validateFormrequestJson('CountryBirthId')">*</span></label>
                            <select #selectCountryBirth class="form-control"
                                formControlName="value-CountryBirthId"
                                (change)="onChangeSelectCountrieBirth(selectCountryBirth.value)"
                                [ngClass]="validateRequiredFormF4('value-CountryBirthId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of countries" value="{{item.code}}">{{item.name}}
                                </option>
                                <option value="Otro">Otro</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PlaceOfBirth" class="">Estado de nacimiento<span
                                    *ngIf="validateFormrequestJson('PlaceOfBirth')">*</span></label>
                            <select class="form-control" formControlName="value-PlaceOfBirth"
                                [ngClass]="validateRequiredFormF4('value-PlaceOfBirth') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of statesBirth" value="{{item.code}}">{{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Nationality" class="">Nacionalidad<span
                                    *ngIf="validateFormrequestJson('Nationality')">*</span></label>
                            <select class="form-control" formControlName="value-Nationality"
                                [ngClass]="validateRequiredFormF4('value-Nationality') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of nationalities" value="{{item.code}}">
                                    {{getNationalityName(item.name)}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Curp" class="">CURP<span
                                    *ngIf="validateFormrequestJson('Curp')">*</span></label>
                            <input type="text" formControlName="value-Curp" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-Curp') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Rfc" class="">RFC<span
                                    *ngIf="validateFormrequestJson('Rfc')">*</span></label>
                            <input type="text" formControlName="value-Rfc" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-Rfc') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PersonId" class="">INE<span
                                    *ngIf="validateFormrequestJson('PersonId')">*</span></label>
                            <input type="text" formControlName="value-PersonId" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-PersonId') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-SerieFiel" class="">Número de serie FIEL<span
                                    *ngIf="validateFormrequestJson('SerieFiel')">*</span></label>
                            <input type="text" formControlName="value-SerieFiel" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-SerieFiel') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelAddressOpenState" class="mb-4"
                (opened)="panelAdressOpen(true)" (closed)="panelAdressOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos domicilio</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Street" class="">Calle<span
                                    *ngIf="validateFormrequestJson('Street')">*</span></label>
                            <input type="text" formControlName="value-Street" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-Street') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-ExteriorNumber" class="">Número exterior<span
                                    *ngIf="validateFormrequestJson('ExteriorNumber')">*</span></label>
                            <input type="text" formControlName="value-ExteriorNumber" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-ExteriorNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-InteriorNumber" class="">Número interior<span
                                    *ngIf="validateFormrequestJson('InteriorNumber')">*</span></label>
                            <input type="text" formControlName="value-InteriorNumber" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-InteriorNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-DistrictName" class="">Colonia<span
                                    *ngIf="validateFormrequestJson('DistrictName')">*</span></label>
                            <input type="text" formControlName="value-DistrictName" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-DistrictName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-CountryId" class="">País<span
                                    *ngIf="validateFormrequestJson('CountryId')">*</span></label>
                            <select #selectCountry class="form-control" formControlName="value-CountryId"
                                (change)="onChangeSelectCountriesObligorJoint(selectCountry.value)"
                                [ngClass]="validateRequiredFormF4('value-CountryId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of getCountriesDomicile()" value="{{item.code}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-StateId" class="">Estado<span
                                    *ngIf="validateFormrequestJson('StateId')">*</span></label>
                            <select #selectState class="form-control" formControlName="value-StateId"
                                (change)="onChangeSelectStatesObligorJoint(selectState.value)"
                                [ngClass]="validateRequiredFormF4('value-StateId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of statesObligorJoint" value="{{item.code}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-CityId" class="">Municipio<span
                                    *ngIf="validateFormrequestJson('CityId')">*</span></label>
                            <select class="form-control" formControlName="value-CityId"
                                [ngClass]="validateRequiredFormF4('value-CityId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of citiesObligorJoint" value="{{item.code}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-ZipCode" class="">Código postal<span
                                    *ngIf="validateFormrequestJson('ZipCode')">*</span></label>
                            <input type="number" formControlName="value-ZipCode" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-ZipCode') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelContactDataOpenState" class="mb-4"
                (opened)="panelContactDataOpen(true)" (closed)="panelContactDataOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos de contacto</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PhoneMobile" class="">Teléfono celular<span
                                    *ngIf="validateFormrequestJson('PhoneMobile')">*</span></label>
                            <input type="text" formControlName="value-PhoneMobile"
                                [textMask]="{mask: phoneMask}" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-PhoneMobile') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PhoneHome" class="">Teléfono casa<span
                                    *ngIf="validateFormrequestJson('PhoneHome')">*</span></label>
                            <input type="text" formControlName="value-PhoneHome"
                                [textMask]="{mask: phoneMask}" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-PhoneHome') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PhoneOffice" class="">Teléfono trabajo<span
                                    *ngIf="validateFormrequestJson('PhoneOffice')">*</span></label>
                            <input type="text" formControlName="value-PhoneOffice"
                                [textMask]="{mask: phoneMask}" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-PhoneOffice') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PhoneMessages" class="">Teléfono recados<span
                                    *ngIf="validateFormrequestJson('PhoneMessages')">*</span></label>
                            <input type="text" formControlName="value-PhoneMessages"
                                [textMask]="{mask: phoneMask}" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-PhoneMessages') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Email">Correo electrónico<span
                                    *ngIf="validateFormrequestJson('Email')">*</span></label>
                            <input type="text" formControlName="value-Email" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-Email') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Facebook" class="">Facebook<span
                                    *ngIf="validateFormrequestJson('Facebook')">*</span></label>
                            <input type="text" formControlName="value-Facebook" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-Facebook') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Instagram" class="">Instagram<span
                                    *ngIf="validateFormrequestJson('Instagram')">*</span></label>
                            <input type="text" formControlName="value-Instagram" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-Instagram') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-LinkedIn" class="">LinkedIn<span
                                    *ngIf="validateFormrequestJson('LinkedIn')">*</span></label>
                            <input type="text" formControlName="value-LinkedIn" class="form-control"
                                [ngClass]="validateRequiredFormF4('value-LinkedIn') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" mat-dialog-close [mat-dialog-close]="submit">
                Cerrar
            </button>
            <button type="submit" class="btn btn-primary external-btn" *ngIf="!getIsDisabledSave()">
                Guardar
            </button>
        </div>
    </form>
</div>