import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationDTO } from 'src/app/models/notification/notification.dto.model';
import { checkPhoneVerification } from 'src/app/models/user/checkPhoneVerification';
import { startPhoneVerification } from 'src/app/models/user/startPhoneVerification';
import { AlertGlobalService } from '../alert-global/alert-global.service';
import { ApiService } from '../api/api.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { AppComponent } from 'src/app/app.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationCount$ = new BehaviorSubject<number>(0);
  private appComponent: AppComponent;

  constructor(private apiService: ApiService,
              private authService: AuthenticationService,
              private alertGlobalService: AlertGlobalService) { }

  public get currentNotificationCountValue(): number {
    return this.notificationCount$.value;
  }

  setAppComponent(appComponent: AppComponent): void {
    this.appComponent = appComponent;
  }

  setUnRead(): void {
    if (this.appComponent) {
      this.appComponent.getUnRead();
    }
  }
  
  getNotificationCountObserver(): Observable<number> {
    return this.notificationCount$.asObservable();
  }
  
  getNotificationsReceived(categoryName: string) {
    return this.apiService.get('notification', 'received/' + categoryName, this.authService.currentUserValue.customerId, false)
    .pipe(map(response => {
      this.getUnRead();    
      return response.data as NotificationDTO[];
    }));
  }

  readNotification(notificationId: string) {
    return this.apiService.post('notification', 'read/' + notificationId)
    .pipe(map(response => {
      this.getUnRead();
      return response.data as String;
    }));
  }

  getCategoriesAvailable() {
    return this.apiService.get('notification', 'categoriesAvailable', this.authService.currentUserValue.customerId, false)
    .pipe(map(response => {
      return response.data as any;
    }));
  }

  getUnRead() {
    return this.apiService.get('notification', 'unreadcount', this.authService.currentUserValue.customerId, false)
    .pipe(map(response => {
      var result = response.data as NotificationDTO[];
      this.notificationCount$.next(result.length);
      return result;
    }));
  }

  sendComments(data) {
    return this.apiService.post('notification', 'sendemailcontact', data, true)
    .pipe(map(response => {
      if (response.status === 201) {
        this.alertGlobalService.showAlertSuccess("Comentarios enviados con éxito.");
      }
      else {
        this.alertGlobalService.showAlertError("Ocurrio un error al enviar los comentarios.");
      }      
      return response;
    }));
  }

  sendCommentsForSuscriptions(data) {
    return this.apiService.post('notification', 'sendcommentsforsuscriptions', data, true)
    .pipe(map(response => {
      if (response.status === 201) {
        this.alertGlobalService.showAlertSuccess("Información enviada con éxito.");
      }
      else {
        this.alertGlobalService.showAlertError("Ocurrio un error al enviar la información.");
      }      
      return response;
    }));
  }

  sendCodeVerification(to: any) {
    return this.apiService.post('notification', 'startphoneverification', to)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  checkCodeVerification(to: any) {
    return this.apiService.post('notification', 'checkphoneverification', to)
    .pipe(map(response => {
      return response.data as String;
    }));
  }
}
