import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AuthenticationService, } from '../../services/authentication/authentication.service';
import { map, mergeMap } from 'rxjs/operators';
import { AlertGlobalService } from '../alert-global/alert-global.service';
import { CallCenterDTO, CallCenterData, CreateCall } from 'src/app/models/loan/callCenter.dto.model';
import { from } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CallCenterService {

	constructor(private apiService: ApiService,
		private authService: AuthenticationService,
		private alertGlobal: AlertGlobalService) { }

	getCalls() {
		return this.apiService.get('loan', 'callCenter/getCallsListByUser').pipe(
			map(
				response => {
					return response.data as CallCenterDTO[];
				}
			)
		);
	}

	getCallData(externalId: string) {
		return this.apiService.get('loan', 'callCenter/getCallData', externalId).pipe(
			map(
				response => {
					return response.data as CallCenterData;
				}
			)
		);
	}

	createCall(externalId: string, createCall: CreateCall, externalStatus: number) {
		return this.apiService.post('loan', 'callCenter/createCall/' + externalId + '/' + externalStatus, createCall).pipe(
			map(
				response => {
					this.alertGlobal.showAlertSuccess("Información guadado con éxito.");

					return response.data as String;
				}
			)
		);
	}
	createReport(externalId: string) {
		return from(this.apiService.get('loan', 'SendNotificationKobraResponse', externalId)).pipe(
			mergeMap(async (response: any) => {
				await this.alertGlobal.showAlertSuccess("Reporte enviado con éxito.");
				return response.data as string;
			})
		);
	}
}