<div class="container">
	<div class="row justify-content-center">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="card overflow-hidden">
				<div class="bg-primary bg-soft-primary">
					<div class="d-flex align-items-center">
						<div class="col-8 col-sm-9 col-md-9">
							<div class="text-white p-4">
								<h5 class="text-white">Restablece tu contraseña</h5>
								<p class="mb-0">Ingresa tu correo electrónico</p>
							</div>
						</div>
						<div class="col-4 col-sm-3 col-md-3">
							<div class="avatar-md">
								<span class="avatar-title rounded-circle bg-light">
									<img src="assets/images/icons/c_wing.svg" height="34" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<form class="p-2" [formGroup]="form" (ngSubmit)="onSubmit()">
						<div class="form-horizontal">
							<div class="mb-3">
								<div class="form-group">
									<label for="email" class="">Correo electrónico*</label>
									<input autocapitalize="off"
										autocomplete="email" autofocus="autofocus"
										class="form-control"
										formControlName="email"
										name="email"
										label="Correo electrónico"
										placeholder="Escribe tu correo"
										type="email"
										[ngClass]="submitted && validateRequiredForm('email') ? 'is-invalid' : ''"
									/>
								</div>
								<mat-error *ngIf="submitted && validateRequiredForm('email')">{{requiredErrorMessage}}</mat-error>
								<mat-error *ngIf="submitted && f1.email.errors && f1.email.errors.email">Formato de correo inválido.</mat-error>
							</div>

							<div class="mt-3 text-center">
                <button
                  class="btn_primary dark"
                  type="submit"
                >
                  <a class="nav-link">Restablecer</a>
                </button>
							</div>

							<div class="mt-4 text-center">
								<p class="mb-0">
									Regresar a <a href="/iniciar-sesion" class="fw-medium text-primary">Iniciar sesión</a>
								</p>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
