<div>
    <h5>
        Guardar datos garantía hipotecaria
    </h5>

    <form [formGroup]="mortgageGuaranteeJsonForm" (ngSubmit)="onSubmitFormMortgageJson()">
        <div class="subtittle">
            Escribe o selecciona tus datos de garantía hipotecaria
        </div>
        <mat-accordion>
            <mat-expansion-panel [expanded]="panelRealEstateDataOpenState" class="mb-4"
                (opened)="panelRealEstateDataOpen(true)" (closed)="panelRealEstateDataOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos bien inmueble</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PropertyType" class="">Tipo bien inmueble<span
                                    *ngIf="validateFormrequestJson('PropertyType')">*</span></label>
                            <select #selectPropertyType class="form-control"
                                formControlName="value-PropertyType"
                                (change)="onChangeSelectPropertyType(selectPropertyType.value)"
                                [ngClass]="validateRequiredFormF5('value-PropertyType') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of propertyTypeList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PropertyOther" class="">Otro bien inmueble<span
                                    *ngIf="validateFormrequestJson('PropertyOther')">*</span></label>
                            <input type="text" formControlName="value-PropertyOther" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-PropertyOther') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="form-group">
                            <label for="value-Description" class="">Descripción<span
                                    *ngIf="validateFormrequestJson('Description')">*</span></label>
                            <input type="text" formControlName="value-Description" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-Description') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-MetersGround" class="">Metros² Terreno<span
                                    *ngIf="validateFormrequestJson('MetersGround')">*</span></label>
                            <input type="number" formControlName="value-MetersGround" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-MetersGround') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-MetersConstruction" class="">Metros² Construcción<span
                                    *ngIf="validateFormrequestJson('MetersConstruction')">*</span></label>
                            <input type="number" formControlName="value-MetersConstruction"
                                class="form-control"
                                [ngClass]="validateRequiredFormF5('value-MetersConstruction') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-3">
                        <div class="form-group">
                            <label>Tipo comprobante propiedad*</label>
                            <select #selectTypeOfProofOfOwnership class="form-control"
                                formControlName="value-TypeOfProofOfOwnership"
                                [ngClass]="validateRequiredFormF5('value-TypeOfProofOfOwnership') ? 'is-invalid' : ''"
                                required>
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of typeOfProofOfOwnershipList"
                                    value="{{item.value}}">{{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-3">
                        <div class="form-group">
                            <label>Libre de gravamen*</label>
                            <select #selectLienFree class="form-control" formControlName="value-LienFree"
                                [ngClass]="validateRequiredFormF5('value-LienFree') ? 'is-invalid' : ''"
                                required>
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of lienFreeList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Owner" class="">Propietario bien<span
                                    *ngIf="validateFormrequestJson('Owner')">*</span></label>
                            <select #selectOwner class="form-control" formControlName="value-Owner"
                                (change)="onChangeSelectOwner(selectOwner.value)"
                                [ngClass]="validateRequiredFormF5('value-Owner') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of ownerList" value="{{item.value}}">{{item.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="form-group">
                            <label for="value-OwnerName" class="">Nombre completo propietario<span
                                    *ngIf="validateFormrequestJson('OwnerName')">*</span></label>
                            <input type="text" formControlName="value-OwnerName" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-OwnerName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-3">
                        <div class="form-group">
                            <label for="value-RelationShip">Relación con Solicitante <span
                                    *ngIf="validateFormrequestJson('RelationShip')">*</span></label>
                            <select #contactRelationship class="form-control"
                                formControlName="value-RelationShip"
                                [ngClass]="validateRequiredFormF5('value-RelationShip') ? 'is-invalid' : ''"
                                required>
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of contactRelationshipList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-WrittingNumber"
                                class="">{{getWrittingNumberText(selectTypeOfProofOfOwnership.value)}}<span
                                    *ngIf="validateFormrequestJson('WrittingNumber')">*</span></label>
                            <input type="number" formControlName="value-WrittingNumber" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-WrittingNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-WrittingDate"
                                class="">{{getWrittingDateText(selectTypeOfProofOfOwnership.value)}}<span
                                    *ngIf="validateFormrequestJson('WrittingDate')">*</span></label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-WrittingDate" type="date"
                                [ngClass]="validateRequiredFormF5('value-WrittingDate') ? 'is-invalid' : ''" />
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-NotaryPublicNumber" class="">Número notario<span
                                    *ngIf="validateFormrequestJson('NotaryPublicNumber')">*</span></label>
                            <input type="number" formControlName="value-NotaryPublicNumber"
                                class="form-control"
                                [ngClass]="validateRequiredFormF5('value-NotaryPublicNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-NotaryName" class="">Nombre notario<span
                                    *ngIf="validateFormrequestJson('NotaryName')">*</span></label>
                            <input type="text" formControlName="value-NotaryName" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-NotaryName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Debts" class="">Adeudos<span
                                    *ngIf="validateFormrequestJson('Debts')">*</span></label>
                            <select #selectOwner class="form-control" formControlName="value-Debts"
                                [ngClass]="validateRequiredFormF5('value-Debts') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of debtsList" value="{{item.value}}">{{item.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-PropertyValue" class="">Valor predial<span
                                    *ngIf="validateFormrequestJson('PropertyValue')">*</span></label>
                            <input type="text" placeholder="$ 0" [textMask]="{mask: currencyMask}"
                                formControlName="value-PropertyValue" class="form-control text-right"
                                [ngClass]="validateRequiredFormF5('value-PropertyValue') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-EvaluatedValue" class="">Valor avalúo<span
                                    *ngIf="validateFormrequestJson('EvaluatedValue')">*</span></label>
                            <input type="text" placeholder="$ 0" [textMask]="{mask: currencyMask}"
                                formControlName="value-EvaluatedValue" class="form-control text-right"
                                [ngClass]="validateRequiredFormF5('value-EvaluatedValue') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-EstimatedMarketValue" class="">Valor mercado estimado<span
                                    *ngIf="validateFormrequestJson('EstimatedMarketValue')">*</span></label>
                            <input type="text" placeholder="$ 0" [textMask]="{mask: currencyMask}"
                                formControlName="value-EstimatedMarketValue" class="form-control text-right"
                                [ngClass]="validateRequiredFormF5('value-EstimatedMarketValue') ? 'is-invalid' : ''">
                        </div>
                    </div>


                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelAddressOpenState" class="mb-4"
                (opened)="panelAdressOpen(true)" (closed)="panelAdressOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos domicilio</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Street" class="">Calle<span
                                    *ngIf="validateFormrequestJson('Street')">*</span></label>
                            <input type="text" formControlName="value-Street" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-Street') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-ExteriorNumber" class="">Número exterior<span
                                    *ngIf="validateFormrequestJson('ExteriorNumber')">*</span></label>
                            <input type="text" formControlName="value-ExteriorNumber" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-ExteriorNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-InteriorNumber" class="">Número interior<span
                                    *ngIf="validateFormrequestJson('InteriorNumber')">*</span></label>
                            <input type="text" formControlName="value-InteriorNumber" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-InteriorNumber') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-DistrictName" class="">Colonia<span
                                    *ngIf="validateFormrequestJson('DistrictName')">*</span></label>
                            <input type="text" formControlName="value-DistrictName" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-DistrictName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-CountryId" class="">País<span
                                    *ngIf="validateFormrequestJson('CountryId')">*</span></label>
                            <select #selectCountry class="form-control" formControlName="value-CountryId"
                                (change)="onChangeSelectCountriesMortgage(selectCountry.value)"
                                [ngClass]="validateRequiredFormF5('value-CountryId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of countriesMortgage" value="{{item.code}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-StateId" class="">Estado<span
                                    *ngIf="validateFormrequestJson('StateId')">*</span></label>
                            <select #selectState class="form-control" formControlName="value-StateId"
                                (change)="onChangeSelectStatesMortgage(selectState.value)"
                                [ngClass]="validateRequiredFormF5('value-StateId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of statesMortgage" value="{{item.code}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-CityId" class="">Municipio<span
                                    *ngIf="validateFormrequestJson('CityId')">*</span></label>
                            <select class="form-control" formControlName="value-CityId"
                                [ngClass]="validateRequiredFormF5('value-CityId') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of citiesMortgage" value="{{item.code}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-ZipCode" class="">Código postal<span
                                    *ngIf="validateFormrequestJson('ZipCode')">*</span></label>
                            <input type="number" formControlName="value-ZipCode" class="form-control"
                                [ngClass]="validateRequiredFormF5('value-ZipCode') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" mat-dialog-close [mat-dialog-close]="submit">
                Cerrar
            </button>
            <button type="submit" class="btn btn-primary external-btn" *ngIf="!getIsDisabledSave()">
                Guardar
            </button>
        </div>
    </form>
</div>