<div class="mb-3">
<label>Escribe los datos del pago recibido</label>
</div>
<form [formGroup]="doPaymentForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-md-3 mb-3">
            <div class="form-group">
                <label for="date">Fecha de pago *</label>
                <input autofocus="autofocus" class="form-control" formControlName="date" type="date" [min]="startDateString" [max]="dateNow" [ngClass]="submitted && validateRequiredForm('date') ? 'is-invalid' : ''" />
            </div>
        </div>

        <div class="col-md-3 mb-3">
            <div class="form-group">
                <label for="amount">Monto *</label>
                <input type="text" [textMask]="{mask: currencyDecimalMask}" formControlName="amount" class="form-control text-right" placeholder="$ 0" [ngClass]="submitted && validateRequiredForm('amount') ? 'is-invalid' : ''">
            </div>
        </div>

        <div class="col-md-3 mb-3">
            <div class="form-group">
                <label for="methodOfPaymentId">Forma de pago *</label>
                <select #selectMethodOfPayment class="form-control" formControlName="methodOfPaymentId"
                    (change)="onChangeMethodOfPayment(selectMethodOfPayment.value)"
                    [ngClass]="submitted && validateRequiredForm('methodOfPaymentId') ? 'is-invalid' : ''">
                    <option value="">Seleccione</option>
                    <option *ngFor="let item of wayToPayList" value="{{item.key}}">{{item.value}}</option>
                </select>
            </div>
        </div>

        <div class="col-md-3 mb-3">
            <div class="form-group">
                <label for="methodOfPaymentOther">{{tittleWayToPay}}</label>
                <input type="text" formControlName="methodOfPaymentOther" class="form-control"
                    [ngClass]="submitted && validateRequiredForm('methodOfPaymentOther') ? 'is-invalid' : ''">
            </div>
        </div>
    </div>
    <div class="d-flex flex-wrap gap-2 justify-content-end">
        <button class="btn btn-primary" type="submit">
            Registrar
        </button>
    </div>
</form>