import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';

export interface DialogData {
}

@Component({
	selector: 'app-dialog-show-import-collection.',
	templateUrl: './dialog-show-import-collection.component.html',
	styleUrls: ['./dialog-show-import-collection.component.scss']
})

export class DialogShowImportCollectionComponent implements OnInit, AfterViewInit {
	@ViewChild('divExport') divExport!: ElementRef;
	@ViewChild('inputContainer', { static: false }) inputContainer: ElementRef;

	dataForm: any[] = [];
	jsonString: string;


	constructor(
		public dialog: MatDialogRef<DialogShowImportCollectionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private datePipe: DatePipe,
		private alertGlobalService: AlertGlobalService) { }

	closeDialog(): void {
		this.dialog.close(false);
	}
	confirm(): void {
		this.dialog.close(true);
	}

	ngOnInit() {
		this.dataForm = this.data.dataCollection;

		if (this.dataForm != null) {
			this.displayJSON();
		}
	}

	displayJSON(): string {
		return this.jsonString = JSON.stringify(this.dataForm, null, 2);
	}

	ngAfterViewInit(): void { }

}
