<div *ngIf="buttonsAction">
    <h5>Detalle de solicitudes</h5>
    <hr>

    <p>Selecciona el renglón de la solicitud deseada</p>

    <div class="d-flex justify-content-end mb-4">
        <div class="form-group col-md-4 col-12">
            <label>Buscar</label>
            <div>
                <i class="bx bx-search input_icon"></i>
                <input class="form-control" (keyup)="applyFilter($event)" #filterText placeholder="" type="text" />
            </div>
        </div>
    </div>
</div>

<div id="content">
    <div class="table-responsive">
        <div class="react-bootstrap-table">
            <table *ngIf="loanrequestDataSource" mat-table [dataSource]="loanrequestDataSource"
                (dblclick)="doubleClick($event, rowSelected.loanRequestId)" class="table table align-middle"
                [ngClass]="isLender ? 'bg-primary' : 'bg-secondary'">
                <tbody>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef> Inicio del préstamo </th>
                        <td mat-cell *matCellDef="let row"> {{row.date | date: 'short'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="dateSent">
                        <th mat-header-cell *matHeaderCellDef> Fecha de registro</th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.date | date: 'mediumDate'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lenderCustomerFullName">
                        <th [hidden]="!made" mat-header-cell *matHeaderCellDef> Nombre de contacto</th>
                        <td [hidden]="!made" mat-cell *matCellDef="let row"> {{row.lenderCustomerFullName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="borrowerCustomerFullName">
                        <th [hidden]="made" mat-header-cell *matHeaderCellDef> Nombre de contacto</th>
                        <td [hidden]="made" mat-cell *matCellDef="let row"> {{row.borrowerCustomerFullName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lenderEmail">
                        <th [hidden]="!made" mat-header-cell *matHeaderCellDef> Datos de contacto </th>
                        <td [hidden]="!made" mat-cell *matCellDef="let row"> {{row.lenderEmail}} </td>
                    </ng-container>
                    <ng-container matColumnDef="borrowerEmail">
                        <th [hidden]="made" mat-header-cell *matHeaderCellDef> Datos de contacto </th>
                        <td [hidden]="made" mat-cell *matCellDef="let row"> {{row.borrowerEmail}} </td>
                    </ng-container>
                    <ng-container matColumnDef="loanAmount">
                        <th mat-header-cell *matHeaderCellDef> Monto solicitado </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.loanAmount | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="monthlyRate">
                        <th mat-header-cell *matHeaderCellDef> Interés mensual </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.monthlyRate * 0.0100 |
                            percent:'1.2-5'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="totalPeriodPay">
                        <th mat-header-cell *matHeaderCellDef> Monto total a pagar </th>
                        <td mat-cell *matCellDef="let row" class="text-right"> {{row.totalPeriodPay | currency}} </td>
                    </ng-container>
                    <ng-container matColumnDef="statusName">
                        <th mat-header-cell *matHeaderCellDef> Estatus </th>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.status === 0">
                                <button class="unclickable" type="button" mat-icon-button color="primary">
                                    <mat-icon>lens</mat-icon>
                                </button> {{row.statusName}}
                            </div>
                            <div *ngIf="row.status === 1">
                                <button class="unclickable" type="button" mat-icon-button>
                                    <mat-icon>lens</mat-icon>
                                </button> {{row.statusName}}
                            </div>
                            <div *ngIf="row.status === 2">
                                <button class="unclickable" type="button" mat-icon-button color="primary">
                                    <mat-icon>lens</mat-icon>
                                </button> {{row.statusName}}
                            </div>
                            <div *ngIf="row.status === 3">
                                <button class="unclickable" type="button" mat-icon-button>
                                    <mat-icon>lens</mat-icon>
                                </button> {{row.statusName}}
                            </div>
                            <div *ngIf="row.status === 4">
                                <button class="unclickable" type="button" mat-icon-button color="accent">
                                    <mat-icon>lens</mat-icon>
                                </button> {{row.statusName}}
                            </div>
                            <div *ngIf="row.status === 5">
                                <button class="unclickable" type="button" mat-icon-button>
                                    <mat-icon>lens</mat-icon>
                                </button> {{row.statusName}}
                            </div>
                            <div *ngIf="row.status === 7">
                                <button class="unclickable" type="button" mat-icon-button color="primary">
                                    <mat-icon>lens</mat-icon>
                                </button> {{row.statusName}}
                            </div>
                            <div *ngIf="row.status === 8">
                                <button class="unclickable" type="button" mat-icon-button color="accent">
                                    <mat-icon>lens</mat-icon>
                                </button> {{row.statusName}}
                            </div>
                            <div *ngIf="row.status === 12">
                                <button class="unclickable" type="button" mat-icon-button color="accent">
                                    <mat-icon>lens</mat-icon>
                                </button> {{row.statusName}}
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="loanrequestColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: loanrequestColumns; let i = index"
                        (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
                </tbody>
            </table>
        </div>
    </div>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>

    <div class="d-flex flex-wrap gap-2 justify-content-end">
        <button *ngIf="buttonsAction" class="btn btn-outline-primary external-btn" routerLink="/inicio">
            Cerrar
        </button>
        <button *ngIf="buttonsAction" class="btn btn-outline-primary external-btn" routerLink="/simulador">
            Nueva
        </button>
        <button *ngIf="buttonsAction" class="btn btn-outline-primary external-btn"
            [disabled]="userIdLogged != rowSelected.userIdCreated || indexSelected === null"
            (click)="removeLoanRequest(rowSelected.loanRequestId, rowSelected.loanAmount)">
            Eliminar
        </button>
        <button *ngIf="buttonsAction" class="btn btn-outline-primary external-btn" [disabled]="indexSelected === null"
            [routerLink]="['/solicitud-de-prestamo', rowSelected.loanRequestId]">
            Abrir
        </button>

        <button class="btn btn-outline-primary external-btn" (click)="exportExcel()" *ngIf="!buttonsAction">
            Excel
        </button>
    </div>
</div>