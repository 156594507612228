import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-loan-proceedings',
  templateUrl: './dialog-loan-proceedings.component.html',
  styleUrls: ['./dialog-loan-proceedings.component.scss']
})
export class DialogLoanProceedingsComponent implements OnInit {
  loanRequestId;
  borrowerCustomerId;
  type = "";

  constructor() { }

  ngOnInit(): void {
  }
}
