<div class="align-items-center d-flex justify-content-between mb-4" *ngIf="serviceName != 'covarage'">
  <h5 class="fw-bold mb-0">Condiciones {{serviceName.toLowerCase()}}</h5>
  <button class="close" (click)="closeDialog()"></button>
</div>

<div class="d-flex flex-wrap" *ngIf="serviceName === 'Llamada de cobranza'">
  <div class="col-sm-4 col-12">
    <p>Descripción</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Servicio de llamadas telefónicas al deudor, aval y/o obligado solidario, a nombre del Prestamista.</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Objetivos</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Realizar contacto vía telefónica</p>
    <p>Validar número de contacto</p>
    <p>Informar adeudo</p>
    <p>• Saldo vencido</p>
    <p class="mb-3">• Días vencido</p>
    <p>Solicitar pago vencido directamente al Prestamista</p>
    <p>Manejo de objeciones</p>
    <p>Resultados esperados</p>
    <p>• Promesa de pago</p>
    <p>• Promesa de contacto con Prestamista</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Medios de contacto</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Tipos de teléfono</p>
    <p>• Celular</p>
    <p>• Casa</p>
    <p>• Negocio</p>
    <p>• Empleo</p>
    <p>• Aval</p>
    <p>• Obligado Solidario</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Meta de servicio</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>1 a 5 días hábiles</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Informa a Prestamista</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Reporte en a-lender</p>
    <p>• Datos llamada</p>
    <p>• Fecha y hora llamada</p>
    <p>• Resultados</p>
    <p>• Comentarios</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Costo (IVA incluido)</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Titular Persona Física {{amountCallPf | currency}}</p>
    <p>Titular Persona Moral {{amountCallPm | currency}}</p>
    <p>Aval: {{amountCallPf | currency}}</p>
    <p>Obligado Solidario: {{amountCallPf | currency}}</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Condiciones servicio</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Se considerará como llamada efectuada si se hace contacto en el teléfono señalado, sea el deudor o no. En caso
      de no hacer contacto alguno, se realizarán hasta 5 intentos para considerarse como efectuada.</p>
    <p>Las actividades de los Gestores Telefónicos se apegan a lo establecido en las Disposiciones de Carácter
      General Aplicables a las Entidades Financieras en Materia de Despachos de Cobranza.</p>
  </div>
</div>

<div class="d-flex flex-wrap" *ngIf="serviceName === 'Visita de cobranza'">
  <div class="col-sm-4 col-12">
    <p>Descripción</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Servicio de visita de cobranza extrajudicial domiciliaria al deudor, aval y/o obligado solidario, a nombre del
      Prestamista.</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Objetivos</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Realizar contacto en persona</p>
    <p>Validar dirección de contacto</p>
    <p>Informar adeudo</p>
    <p>• Saldo vencido</p>
    <p class="mb-3">• Días vencido</p>
    <p>Solicitar pago vencido directamente al Prestamista</p>
    <p>Manejo de objeciones</p>
  </div>
  <div class="col-sm-4 col-12">
    <p>Resultados esperados</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>• Promesa de pago</p>
    <p>• Promesa de contacto con Prestamista</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Medios de contacto</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Tipos de domicilio</p>
    <p>• Particular (Casa)</p>
    <p>• Negocio</p>
    <p>• Empleo</p>
    <p>• Aval</p>
    <p>• Obligado Solidario</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Meta de servicio</p>
  </div>
  <div class="col-sm-8 col-12">
    <p> 3 a 15 días</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Informa a Prestamista</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Reporte en a-lender</p>
    <p>• Datos visita</p>
    <p>• Fecha, hora, geolocalización y evidencia fotográfica de visita</p>
    <p>• Resultados</p>
    <p>• Comentarios</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Costo (IVA incluido)</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Titular Persona Física {{amountVisitPf | currency}}</p>
    <p>Titular Persona Moral {{amountVisitPm | currency}}</p>
    <p>Aval: {{amountVisitPf | currency}}</p>
    <p>Obligado Solidario: {{amountVisitPf | currency}}</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Condiciones servicio</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Se considerará como visita de cobranza extrajudicial domiciliaria efectuada se encuentre el deudor o no en el
      domicilio señalado.</p>
    <p>Las actividades de los Gestores Domiciliarios se apegan a lo establecido en las Disposiciones de Carácter
      General Aplicables a las Entidades Financieras en Materia de Despachos de Cobranza.</p>
  </div>
</div>

<div class="d-flex flex-wrap" *ngIf="serviceName === 'Visita de verificación'">
  <div class="col-sm-4 col-12">
    <p>Descripción</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Servicio de visitas domiciliarias al solicitante o cliente, aval y/o obligado solidario, a nombre del Prestamista/Acreedor.</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Objetivos</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Realizar contacto en persona</p>
    <p>Validar dirección de contacto</p>
    <p>Obtener información adicional</p>
    <p>• Propiedad de domicilio</p>
    <p>• Antigüedad en domicilio</p>
    <p>• Ocupación del solicitante o cliente</p>
    <p>Resultados esperados</p>
    <p>• Corroborar datos</p>
  </div>
  <hr class="col-12">
  <div class="col-sm-4 col-12">
    <p>Informe a Prestamista</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Reporte PDF en a-lender</p>
    <p>• Datos Visita</p>
    <p>• Fecha/ Hora/ Geolocalización/ Fotografía</p>
    <p>• Resultados</p>
    <p>• Comentarios</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Meta de Servicio</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>3 a 15 días</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Medios de contacto</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Tipos de domicilio</p>
    <p>• Domicilio Particular (Casa)</p>
    <p>• Domicilio Negocio</p>
    <p>• Domicilio Empleo</p>
    <p>• Domicilio Aval</p>
    <p>• Domicilio Obligado Solidario</p>
    <p>• Otro Domicilio Proporcionado</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Costo (IVA incluido)</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Titular Persona Física {{amountVisiVerificationtPf | currency}}</p>
    <p>Titular Persona Moral {{amountVisiVerificationtPm | currency}}</p>
    <p>Aval: {{amountVisiVerificationtPf | currency}}</p>
    <p>Obligado Solidario: {{amountVisiVerificationtPf | currency}}</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Condiciones servicio</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>• Se considerará como visita efectuada se encuentre el solicitante o no en el domicilio señalado.</p>
  </div>
</div>

<div class="d-flex flex-wrap" *ngIf="serviceName === 'Llamada de verificación'">
  <div class="col-sm-4 col-12">
    <p>Descripción</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Servicio de llamadas telefónicas al solicitante o cliente, aval y/o obligado solidario, a nombre del Prestamista/Acreedor.</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Objetivos</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Realizar contacto en persona</p>
    <p>Validar número de contacto</p>
    <p>Obtener información adicional</p>
    <p>• Propiedad de domicilio</p>
    <p>• Antigüedad en domicilio</p>
    <p>• Ocupación del solicitante o cliente</p>
    <p>Resultados esperados</p>
    <p>• Corroborar datos</p>
  </div>
  <hr class="col-12">
  <div class="col-sm-4 col-12">
    <p>Informe a Prestamista</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Reporte PDF en a-lender</p>
    <p>• Datos Llamada</p>
    <p>• Fecha/ Hora Llamada </p>
    <p>• Resultados</p>
    <p>• Comentarios</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Meta de Servicio</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>1 a 5 días</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Medios de contacto</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Tipos de Teléfono</p>
    <p>• Teléfono Celular</p>
    <p>• Teléfono Casa</p>
    <p>• Teléfono Negocio</p>
    <p>• Teléfono Aval</p>
    <p>• Teléfono Obligado Solidario</p>
    <p>• Otro Teléfono Proporcionado</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Costo (IVA incluido)</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>Titular Persona Física {{amountCallVerificationtPf | currency}}</p>
    <p>Titular Persona Moral {{amountCallVerificationtPm | currency}}</p>
    <p>Aval: {{amountCallVerificationtPf | currency}}</p>
    <p>Obligado Solidario: {{amountCallVerificationtPf | currency}}</p>
  </div>
  <hr class="col-12">

  <div class="col-sm-4 col-12">
    <p>Condiciones servicio</p>
  </div>
  <div class="col-sm-8 col-12">
    <p>• Se considerará como llamada efectuada si se hace contacto en el teléfono señalado, sea el solicitante o no. En caso de no hacer contacto alguno, se
      realizarán hasta 5 intentos para considerarse como efectuada.</p>
  </div>
</div>

<div class="align-items-center d-flex justify-content-between mb-4" *ngIf="serviceName === 'covarage'">
  <div class="container">
    <div class="text-center">
      <h5 class="fw-bold mb-0">Visitas de Cobranza</h5>
    </div>
    <div class="text-center">
      <h5 class="fw-bold mb-0">Cobertura Disponible</h5>
    </div>
    <div class="text-center">
      <h5 class="fw-bold mb-0">(México)</h5>
    </div>
  </div>

  <button class="close" (click)="closeDialog()"></button>
</div>

<div class="row " *ngIf="serviceName === 'covarage'">
  <p class="text-center col-sm-6 fw-bold">Estado</p>
  <p class="text-center col-sm-6 fw-bold">Municipio</p>
  <hr class="my-1 mb-6">
  <div class="col-sm-12">
    <ul class="list-unstyled">
      <li *ngFor="let coverage of kobraCoverage">
        <div class="d-flex">
          <div class="col-6">
            <p>{{ coverage.estado }}</p>
          </div>
          <div class="col-md-6">
            <ul class="list-unstyled">
              <li *ngFor="let municipio of coverage.municipio">
                <p>•{{ municipio }}</p>
              </li>
            </ul>
          </div>
        </div>
        <hr class="my-1 mb-4">
      </li>
    </ul>
  </div>
  <p class="text-center">*Cobertura Diciembre</p>
</div>
