<div>
  <h5 style="text-align: center;">Intento de Contacto Terminado</h5>
  <h5 class="fw-bold" style="text-align: center; font-weight: 700;">{{totalNumberCalls}}/5</h5>
  <div class="row justify-content-center">
    <div class="col-auto">
      <button style="width: auto;"  class="btn btn-outline-primary external-btn btn-block" (click)="sendResponse(3)">Cerrar Reporte</button>
    </div>
    <div class="col-auto">
      <button style="width: auto;" class="btn btn-primary external-btn btn-block" (click)="sendResponse(1)">Dejar en Proceso</button>
    </div>
  </div>
</div>