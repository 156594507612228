<div class="container-fluid">
    <h4>Aviso de Privacidad</h4>
    <div class="card">
        <div class="card-body">
            <p class="text">
                A fin de dar cumplimiento con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y Lineamientos aplicables (la “Ley”), Apoyo Soluciones Productivas, S.A.P.I. de C.V., sus filiales y/o subsidiarias, y/o sus partes relacionadas (“A-Lender”), con domicilio en Av. Lázaro Cárdenas No. 2400 Pte., Oficina C31-4, Col. Residencial San Agustín, San Pedro Garza García, N.L., CP 66269, (el “Domicilio”), titular de los derechos del Software denominado A-Lender (el “Software”) para su uso a través de la plataforma digital con dirección electrónica www.a-lender.com (la “Plataforma”) y demás  plataformas digitales y aplicaciones web y/o móviles presentes y futuras de su propiedad, y con correo electrónico de contacto hola@a-lender.com (el “Correo Electrónico”), pone a su disposición el presente:
            </p>
            <p class="tittle">
                <b>AVISO DE PRIVACIDAD</b>
            </p>
            <p class="text">
                Con la finalidad de dar un tratamiento legítimo, controlado e informado a sus datos personales, que actualmente nos proporcione o en el futuro y que obren en nuestras bases de datos, o que hayan sido recopilados por cookies, o cualquier otra tecnología de seguimiento web, así como para garantizar su privacidad y su derecho a la autodeterminación informativa al proporcionarnos dichos datos, siendo A-Lender responsable del uso y protección de sus datos personales los cuales serán tratados con base en los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad previstos en la Ley.
            </p>
            <h6>
                <b>UTILIZACIÓN DE LA INFORMACIÓN</b>
            </h6>
            <p class="text">
                La información que usted nos provea a través del acceso, registro y creación de perfil de Usuario en el Sitio y/o en la Plataforma, y/o correo electrónico, y/o llenado de formularios o encuestas físicas o electrónicas, en tiempo real o histórico, se procesará y ordenará, para que genere indicadores de datos, mismos que A-Lender podrá usar para tomar decisiones pertinentes a su negocio. Toda la información que sea recopilada se utilizará con fines estadísticos, de manera genérica y no personalizada, y se asocian con el crecimiento, mantenimiento y administración de A-Lender, respetando en todo momento su privacidad. Estos usos incluyen nuestras operaciones y administración internas, la comunicación con usted y el cumplimiento de las solicitudes de servicios y/o productos provistos por A-Lender, así como para mejorar, desarrollar, perfeccionar y, proporcionar los servicios de A-Lender, a través de sus partes relacionadas, filiales, o proveedores autorizados y/o socios comerciales, estableciendo las medidas adecuadas a fin de limitar el uso de la información recabada de usted, únicamente para fines legales y autorizados de conformidad con este Aviso, así como con las debidas medidas de confidencialidad y seguridad.
            </p>
            <p class="text">
                A-Lender también podrá recabar su dirección de IP (Internet Protocol) para ayudar a diagnosticar problemas con el servidor de A-Lender y para administrar el Sitio y la Plataforma. Una dirección de IP es un número que se le asigna a su computadora cuando usa Internet. Su dirección de IP también es utilizada para ayudar a identificarle dentro de una sesión particular y para recolectar información demográfica general. A-Lender podrá hacer uso de tecnología “push” a través de la aplicación que A-Lender usa para enviar notificaciones con autorización previa del Usuario. Este medio de comunicación no tiene ningún tipo de acceso a otras funciones o información del equipo con el que se conecta al Sitio. La información puede incluir la URL de la que provienen (estén o no en el Sitio), a qué URL acceden seguidamente (estén o no en el Sitio), qué navegador están usando, así como también las páginas visitadas, las búsquedas realizadas, las publicaciones, preferencias comerciales, mensajes, etc.
            </p>
            <p class="text">
                <b>USO DE COOKIES</b>
                A-Lender le informa que, mediante el uso de cookies y tecnologías similares, busca garantizar la mejor experiencia posible en el Sitio y/o la Plataforma, al proporcionarle información personalizada, recordando sus preferencias de servicios y de mercadeo y ayudándolo a obtener la información adecuada. En caso de que requiera mayor información respecto al uso de cookies y tecnologías similares, A-Lender pone a su disposición la Política de Uso de Cookies.
            </p>
            <p class="text">
                <b>USO DE PLATAFORMAS DE TERCEROS COMO MEDIO DE OBTENCIÓN DE DATOS</b>
                Los Datos Personales pueden ser recabados mediante la integración manual de la información solicitada en la Plataforma o mediante la autorización de uso de los datos contenidos en la plataforma de Google Analytics, al tener el Usuario registrada una cuenta de correo electrónico en dicha plataforma. La solicitud de los datos del Usuario se realiza a través del enlace de la Plataforma a la API (por su significado abreviado en inglés Application Programming Interfaces) integrada en la plataforma de Google Analytics.
            </p>
            <p class="text">
                De igual forma, el ingreso de Usted como usuario a los Servicios de la Plataforma, puede estar asimismo sujeto a una primera verificación de identidad mediante validez del número celular, que a este efecto se proporcione, a través de mensaje directo SMS (por sus siglas en inglés Short Message Service)
            </p>
            <p class="text">
                <b>1. DATOS PERSONALES SOLICITADOS </b>
                A-Lender, y/o las empresas controladoras de éste y/o empresas filiales y/o subsidiarias y/o partes relacionadas (los “<u>Terceros Relacionados</u>”) y/o aquellos terceros que, por la relación comercial con A-Lender hayan contratado el uso de la Plataforma para complementar su propuesta de negocios y tengan la necesidad de tratar y/o utilizar sus datos personales (el “Cliente”), y/o por la naturaleza de su trabajo o funciones tengan la necesidad de tratar y/o utilizar sus datos personales, como proveedores o aliados comerciales de A-Lender (“<u>Socios Comerciales</u>”), solicita y obtiene datos personales en general, así como datos personales considerados sensibles por la Ley (en lo sucesivo “<u>Datos Personales Generales</u>” y “<u>Datos Personales Sensibles</u>”, respectivamente; y de manera conjunta referidos como los “<u>Datos Personales</u>”) de las personas en adelante descritas.
            </p>
            <p class="text">
                Los <u>Datos Personales Sensibles</u> podrán ser solicitados por medios electrónicos o físicos, en el entendido de que toda información proporcionada en físico, será considerada y tratada como si se hubiera proporcionado y autorizado en el Sitio y/o la Plataforma, y por lo cual se regirá por el presente documento.
            </p>
            <p class="text">
                En todos los casos, la recolección de Datos Personales por parte de A-Lender es realizada de buena fe y para los fines aquí expuestos; por tal motivo, se presume que los datos proporcionados por sus titulares son apegados a la verdad y completos, por lo que son responsabilidad del titular que los proporciona.
            </p>
            <p class="text">
                <b>A)	CLIENTES, COMO USUARIOS DE LA PLATAFORMA</b> Los Datos Personales que serán recabados de los Clientes que hagan uso de la Plataforma son necesarios para documentar la relación comercial y jurídica que existe o podrá existir con cada uno de ellos, y para poder realizar el objeto de los servicios contratados. Los Datos Personales que usted proporcionará voluntaria y libremente por sí mismo o por medio de sus Administradores, constan de información que es incluida o podrá ser incluida en contratos, cartas, formatos, listados, bases de datos u otros medios físicos y/o electrónicos, según corresponda, a efecto de que A-Lender pueda documentar la relación entre las partes, el proceso de uso y selección que realice o vaya a realizar de los Módulos que conforman los Servicios de la Plataforma; pueda llevar un registro adecuado de la relación comercial con sus socios comerciales; así como para dar cabal cumplimiento a las políticas internas, procedimientos y demás obligaciones legales aplicables a A-Lender.
            </p>
            <p class="text">
                Los <u>Datos Personales</u> que le serán solicitados son los siguientes: 
            </p>
            <p class="text">
                Generales:
            </p>
            <ul>
                <li>Nombre completo</li>
                <li>Género</li>
                <li>Fecha de nacimiento</li>
                <li>Lugar de nacimiento</li>
                <li>Género</li>
                <li>Nacionalidad</li>
                <li>Nivel de estudios</li>
                <li>Ocupación</li>
                <li>Datos dirección</li>
                <li>Datos contacto (correo, teléfono, redes sociales)</li>
            </ul>
            <p class="text">
                <b>B) 	SOCIOS COMERCIALES DEL CLIENTE, COMO USUARIOS DE LA PLATAFORMA.</b> Los Datos Personales que se recabará de los Socios Comerciales a través de la Plataforma, constan de información que es incluida o podrá ser incluida en contratos, cartas, formatos, listados, bases de datos u otros medios físicos y/o electrónicos dispuestos en cada uno de los Módulos de la Plataforma, según corresponda, a efecto de que se documente el proceso de uso y selección que realice o vaya a realizar de los Módulos de Servicios que conforman la Plataforma; se pueda llevar un registro adecuado de la relación comercial con los Socios Comerciales que hagan uso de la Plataforma; así como para dar cabal cumplimiento a las políticas internas, procedimientos y demás obligaciones legales aplicables a A-Lender.
            </p>
            <p class="text">
                Los <u>Datos Personales</u> que son recabados para su integración como parte de los Servicios de la Plataforma a los Socios Comerciales son:
            </p>
            <ul>
                <li>nombre de la persona física o denominación social;</li>
                <li>domicilio social;</li>
                <li>clave de Registro Federal de Contribuyentes (“RFC”);</li>
                <li>número de teléfono fijo y/o celular;</li>
                <li>nombre y cargo de la persona que funja como contacto principal;</li>
                <li>número de teléfono y/o móvil corporativo de la persona que funja como contacto principal; y</li>
                <li>correo electrónico corporativo de la persona que funja como contacto principal;  </li>
                <li>datos bancarios para el depósito o transferencia electrónica de los servicios brindados al Cliente; y</li>
                <li>aquellos específicos para procesar las órdenes de compra a los Socios Comerciales o cualquier documento requeridos en los módulos dispuestos en la Plataforma.</li>
            </ul>
            <p class="text">
                <b>Todos los Datos Personales son procesados por A-Lender y puestos en un repositorio para su tratamiento y transferencia al Cliente, a fin de dar cumplimiento al objeto de los Servicios adquiridos por parte del Cliente</b>. AL SER LA PLATAFORMA UNA HERRAMIENTA DE PROCESAMIENTO DE DATOS PUESTA AL SERVICIO DEL CLIENTE, QUE PERMITE MONITOREAR Y CENTRALIZAR TODA LA INFORMACIÓN DE SUS USUARIOS FINALES Y/O SOCIOS COMERCIALES, A-Lender NO TIENE INJERENCIA SOBRE LA TRANSFERENCIA Y FINALIDAD DEL USO POR PARTE DEL CLIENTE, POR LO QUE ESTAS ACTIVIDADES ESTÁN REGIDAS POR EL AVISO DE PRIVACIDAD QUE CADA CLIENTE DISPONGA A ESTE EFECTO.
            </p>
            <p class="text">
                <b>2.  FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</b>
                Los Datos Personales proporcionados a A-Lender a través de la Plataforma serán utilizados según se ha mencionado anteriormente, con la finalidad de:
            </p>
            <ul>
                a. realizar el procesamiento de datos que permita crear un registro de los Clientes y de los Módulos de los Servicios que utilice, administre o gestione a través de la Plataforma, como herramienta de enlace con los Socios Comerciales;<br />
                b. dar trámite a la solicitud de servicios realizada a través del Sitio y/o la Plataforma;<br />
                c.  brindar de la asesoría necesaria y seguimiento a los Servicios contratados;<br />
                d. que el Cliente pueda gestionar, operar, administrar y monitorear la relación de negocios con los Socios Comerciales;<br />
                e. actividades de promoción y marketing de nuevos productos y/o servicios dispuestos en la Plataforma;<br />
                f. enviar información sobre emprendimiento, inversión y demás información de utilidad para invertir y/o realizar una ronda de inversión; y<br />
                g. procesar los datos bancarios para realizar el pago de las órdenes de compra y/o cualquier trámite de negocios con sus Socios Comerciales, a través de los motores de pago dispuestos por los Clientes.         
            </ul>
            <p class="text">
                Una vez cumplidas las finalidades del tratamiento de sus Datos Personales, y cuando no exista disposición legal que establezca lo contrario, A-Lender a su sola discreción y bajo la autorización del Titular de los datos, podrá hacer uso de los datos recabados únicamente con fines estadísticos de manera genérica y no personalizada, y se asocian con el crecimiento, mantenimiento y administración de A-Lender, respetando en todo momento la privacidad del titular de los Datos Personales.
            </p>
            <p class="text">
                <b>El tratamiento que el Cliente como los motores de pago dispuestos por A-Lender y/o el Cliente, otorguen a los Datos Personales recabados, estarán sujetos a los términos y condiciones del Aviso de Privacidad del Cliente como aquellos contenidos en la página web del tercero que realice los servicios de motor de pago.</b>
            </p>
            <p class="text">
                <b>3. TRANSFERENCIA</b>
                <b>TRANSFERENCIA DE LOS DATOS PERSONALES E INFORMACIÓN.</b> Los Datos Personales a que se refiere este Aviso podrán ser transferidos a: (i) terceros relacionados y/o aliados comerciales, con la finalidad de engrandecer la propuesta de valor de A-Lender, así como ofrecerle, con base en sus necesidades, otros productos y servicios; (ii) autoridades judiciales, mexicanas y extranjeras, con la finalidad de dar cumplimiento a la Ley, legislación, notificaciones, requerimientos u oficios de carácter judicial; (iii) a proveedores de servicios de internet sobre la cual esté montada la infraestructura tecnológica de A-Lender; y/o (iv) a proveedores de servicios de soporte técnico de la Plataforma. En caso de realizar alguna transferencia de sus Datos Personales, en los que se requiera su consentimiento expreso, se lo informaremos a efecto de recabar el mismo.
            </p>
            <p class="text">
                En todos los casos, A-Lender comunicará el presente Aviso de Privacidad a estos terceros y se asegurará a través de la firma de convenios y/o la adopción de otros documentos vinculantes, que dichos terceros mantengan las medidas de seguridad administrativas, técnicas y físicas necesarias para resguardar sus Datos Personales, así como que dichos terceros únicamente utilicen sus Datos Personales para las finalidades para los cuales fueron recabados. Asimismo, tanto el Cliente como responsable de recabar los Datos Personales y A-Lender que facilita a través de la Plataforma la recabación y procesamiento de los mismos, así como cualquier otra persona relacionada con A-Lender que tenga acceso a la información contenida en este Aviso de Privacidad, quedarán obligados a resguardarla bajo las mismas normas de seguridad y confidencialidad, y a no revelarla ni hacer mal uso de la misma, o en caso contrario serán responsables de conformidad con las leyes aplicables.
            </p>
            <p class="text">
                No obstante lo anterior, A-Lender no transferirá sus Datos Personales a terceros no relacionados con A-Lender, salvo en los casos antes citados y los previstos en la Ley, sin su consentimiento previo.
            </p>
            <p class="text">
                <b>4. MEDIOS Y PROCEDIMIENTOS PARA EL EJERCICIO DE LOS DERECHOS ARCO</b>
                Usted, como titular de los Datos Personales proporcionados a A-Lender, podrá solicitar en cualquier momento, el ejercicio de sus derechos de acceso, rectificación, cancelación u oposición (los “Derechos ARCO”) al tratamiento de sus Datos Personales, consistentes en: (i) acceder a sus Datos Personales y a los detalles del tratamiento de los mismos; (ii) rectificar sus Datos Personales en caso de ser inexactos o incompletos; (iii) cancelar sus Datos Personales cuando considere que no se requieren para alguna de las finalidades señalados en este Aviso de Privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado su relación contractual o de servicio u otra con A-Lender; y (iv) oponerse al tratamiento de sus Datos Personales para fines específicos.
            </p>
            <p class="text">
                Para tal fin, usted deberá seguir el proceso de presentar su petición por escrito a A-Lender, o bien, enviar su petición al Correo Electrónico, según sea aplicable, la cual deberá contener, como mínimo, la siguiente información: (a) su nombre completo y domicilio, u otro medio idóneo para comunicarle la respuesta a su solicitud; (b) los documentos que acrediten su identidad o, en su caso, la de su representante legal; (c) la descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados; y (d) cualquier otro elemento o información que facilite la localización de los Datos Personales, así como cualquier otro documento requerido por la regulación actual en el momento de presentar la solicitud. Usted también podrá solicitar al Correo Electrónico mayor información sobre el procedimiento para ejercer sus Derechos ARCO.
            </p>
            <p class="text">
                La respuesta a su solicitud le será dada a conocer por A-Lender en los términos y plazos establecidos en la Ley. No obstante, usted podrá obtener más información acerca del estado que guarda su solicitud y del plazo de respuesta de la misma, contactando a A-Lender o enviando su petición al Correo Electrónico, donde además podrán atender cualquier aclaración o duda que pudiera tener respecto al tratamiento de sus Datos Personales y el ejercicio de sus Derechos ARCO.
            </p>
            <p class="text">
                <b>5. REVOCACIÓN DEL CONSENTIMIENTO; LIMITACIÓN DE USO Y DIVULGACIÓN DE LOS DATOS PERSONALES</b>
                Usted también podrá revocar, en cualquier momento, el consentimiento que haya otorgado a A-Lender para el tratamiento de sus Datos Personales, así como solicitar que se limite el uso y divulgación de los mismos, siempre y cuando no lo impida una disposición legal. Para tal fin, usted deberá presentar su solicitud por escrito a A-Lender, o bien, enviar su solicitud al Correo Electrónico, según sea aplicable. Dicha solicitud deberá cumplir con los mismos requisitos mencionados en la Sección 4. anterior.
            </p>
            <p class="text">
                La respuesta a su solicitud le será dada a conocer por A-Lender en los términos y plazos establecidos en la Ley. No obstante, usted podrá obtener más información acerca del estado que guarda su solicitud y del plazo de respuesta de la misma, contactando a A-Lender o enviando su petición al Correo Electrónico, donde además podrán atender cualquier aclaración o duda que pudiera tener respecto al tratamiento y estos derechos que le corresponden respecto a sus Datos Personales.
            </p>
            <p class="text">
                En caso de que sus Datos Personales hubiesen sido remitidos con anterioridad a la fecha de revocación del consentimiento, y sigan siendo tratados por encargados de A-Lender, éste hará del conocimiento de éstos últimos dicha revocación, para que procedan a efectuar lo conducente.
            </p>
            <p class="text">
                <b>6. CAMBIOS AL AVISO DE PRIVACIDAD</b>
                A-Lender se reserva el derecho de modificar y/o actualizar este Aviso de Privacidad, en alguna o todas sus partes, a su entera discreción, en cuyo caso lo comunicará aquí mismo a través de su Sitio y/o la Plataforma; y, según sea el caso particular de cada titular, a través de sus redes internas, o por medio de un aviso que se colocará en los medios habituales (físicos o electrónicos) de comunicación de A-Lender y en un lugar visible del Domicilio, o mediante un aviso por escrito dirigido a su correo electrónico, según sea legalmente requerido.
            </p>
            <p class="text">
                <b>7. FORMA DIGITAL, ELECTRÓNICA O EN LÍNEA</b>
                La Partes acuerdan que la forma para perfeccionar el acuerdo de voluntades entre ellas podrá ser el de formato Digital, Electrónico o en Línea, en donde bastará manifestar su voluntad por medio de su aceptación, así como proporcionar los datos personales, en el propio Sitio de A-Lender sin requerir estampar la firma en documento alguno.
            </p>
            <p class="text">
                Fecha de primera emisión: 1 de enero de 2022.
                Fecha de última modificación: 14 de octubre de 2022.    
            </p>
        </div>
    </div>
</div>