import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import * as $ from 'jquery';
import { SendCalculatePrice, Subscriptionpayment } from 'src/app/models/subscriptionpayment/subscriptionpayment.model';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { plansDTO } from 'src/app/models/plans/plans.dto.model';
import { MethodsPaymentDTO } from 'src/app/models/methodspayment/methodspayment.dto.model';
import { ConektaTokenResponse } from 'src/app/models/conektatokenresponse/conektatokenresponse.model';
import { KeyValue } from '@angular/common';
import { PeriodsService } from 'src/app/services/periods/periods.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import pdfMake from 'pdfmake/build/pdfmake';
import htmlToPdfmake from "html-to-pdfmake"
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GlobalLists } from 'src/app/global/global-data';
import { SendInfoSuscriptionsComponent } from '../send-info-suscriptions/send-info-suscriptions.component';
import { subscriptionpaymentDTO } from 'src/app/models/subscriptionpayment/subscriptionpayment.dto.model';

export interface plansRowsElement {
	detail: String;
}

@Component({
	selector: 'app-subscriptionpayment-new',
	templateUrl: './subscriptionpayment-new.component.html',
	styleUrls: ['./subscriptionpayment-new.component.scss']
})

export class SubscriptionpaymentNewComponent implements OnInit {
	@Input() action: string | null = "";
	@ViewChild('stepperSubscription') stepperSimulator: MatStepper;
	subscriptionTypeForm: FormGroup;
	subscriptionTypePaymentForm: FormGroup;
	subscriptionPaymentForm: FormGroup;
	plans: plansDTO[] = [];
	methodsPayment: MethodsPaymentDTO[] = [];
	paymentTypeSelected: string;
	paymentSpeiSelected: boolean = false;
	paymentOxxoSelected: boolean = false;
	tokenGenerated: any;
	isDisabledPay = true;
	isDisabledComprobation = false;
	planOperations: any;
	planOperationsOption: any;
	numberLast: string
	idCustomerConekta: string;
	emailCustomerConekta: string;
	phoneCustomerConekta: string;
	submit = false;
	html: SafeHtml;
	totalAmount: number = 0;
	price = 0;
	pricePlan = 0;
	priceTax = 0;
	positiveBalance = 0;
	discount = 0;
	netAmountPay = 0;
	currentSubscription: subscriptionpaymentDTO;
	requiredErrorMessage: string = "Este campo es requerido.";
	indexSelected = null;
	phoneMask = GlobalLists.phoneMask;
	phoneNumberUser:any

	periodsList: KeyValue<number, string>[] = [];
	subscriptionType = 1;

	subscripcionEndDate = new Date();
	subscripcionStartDate = new Date();

	mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
	maskCVV = [/\d/, /\d/, /\d/, /\d/];
	maskExpirationDate = [/\d/, /\d/, '/', /\d/, /\d/];

	yearList: KeyValue<number, number>[] = [];

	@Input() isNew: boolean = false;
	email = "";

	startDateTittle = "";
	endDateTittle = "";
	newStartDateTittle = "";
	newEndDateTittle = "";
	daysfavor = "";

	numberOperations: any;
	currentStartDate = "";
	currentEndDate = "";
	newStartDate: Date;
	newEndDate: Date;
	percentDiscount = 0;
	amountDiscount = 0;
	remainingSDays = 0;

	subscriptionPaymentSpeiSubmited = false;
	selectedPaymentIndex: number;

	constructor(
		private formBuilder: FormBuilder,
		private subscriptionpaymentService: SubscriptionpaymentService,
		private customerService: CustomerService,
		private authService: AuthenticationService,
		private alertGlobalService: AlertGlobalService,
		private periodsService: PeriodsService,
		private sanitizer: DomSanitizer,
		private dialog: MatDialog,
		private changeDetector: ChangeDetectorRef,
		private router: Router,
		private route: ActivatedRoute
	) {
		if (this.authService.currentUserValue == null) {
			this.isNew = true;
		}

		this.startSubscriptionTypeForm();
		this.startSubscriptionTypePaymentForm();
		this.startSubscriptionPaymentForm();

		this.action = this.route.snapshot.paramMap.get('action');

		if (this.action != null) {
			if (this.action == "nueva") {
				this.subscriptionType = 0;
				this.startDateTittle = "Fecha inicio"
				this.endDateTittle = "Fecha fin"
				this.newStartDateTittle = "Fecha inicio"
				this.newEndDateTittle = "Fecha fin"
			} else if (this.action == "renovar") {
				this.subscriptionType = 3;
				this.startDateTittle = "Fecha inicio actual"
				this.endDateTittle = "Fecha fin actual"
				this.newStartDateTittle = "Fecha inicio nuevo"
				this.newEndDateTittle = "Fecha fin nuevo"
				this.daysfavor = "Días a favor "
			} else if (this.action == "ampliar-plazo") {
				this.subscriptionType = 1;
				this.startDateTittle = "Fecha inicio actual"
				this.endDateTittle = "Fecha fin actual"
				this.newStartDateTittle = "Fecha inicio nuevo"
				this.newEndDateTittle = "Fecha fin nuevo"
			} else if (this.action == "mejorar-plan") {
				this.subscriptionType = 2;
				this.startDateTittle = "Fecha inicio actual"
				this.endDateTittle = "Fecha fin actual"
				this.newStartDateTittle = "Fecha inicio nuevo"
				this.newEndDateTittle = "Fecha fin nuevo"
			}
		}

		if (!this.isNew) {
			this.getCustomer()
			this.getMethodsPayment();
			this.getPeriodList();
			this.suscribeValueChanges();
			this.getCurrentSubscription();

			this.email = this.authService.currentUserValue.username;
			this.f_subscriptionPaymentForm['email'].patchValue(this.email);
		} else {
			this.getPlans();

      setTimeout(() => {
        var headerContainer = document.querySelector("mat-horizontal-stepper .mat-horizontal-stepper-header-container");

        headerContainer.classList.add("d-none");
      })
		}
	}

	requiredSubscriptionTypeErrorMessage: string = "Por favor seleccione un plan";
	requiredSubscriptionTypePaymentErrorMessage: string = "Por favor seleccione un método de pago";

	get f_subscriptionTypeForm() { return this.subscriptionTypeForm.controls; }
	get f_subscriptionTypePaymentForm() { return this.subscriptionTypePaymentForm.controls; }
	get f_subscriptionPaymentForm() { return this.subscriptionPaymentForm.controls; }

	get f_subscriptionTypeFormProp() { return Object.getOwnPropertyNames(this.f_subscriptionTypeForm); }
	get f_subscriptionTypePaymentFormProp() { return Object.getOwnPropertyNames(this.f_subscriptionTypePaymentForm); }

	ngOnInit(): void {
		localStorage.setItem("_conekta_publishable_key", environment.conektakey)
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	startSubscriptionTypeForm() {
		this.subscriptionTypeForm = this.formBuilder.group({
			subscriptionType: ['', [Validators.required]],
			completed: [false, Validators.requiredTrue],
		});
	}

	startSubscriptionTypePaymentForm() {
		this.subscriptionTypePaymentForm = this.formBuilder.group({
			subscriptionTypePayment: [null, [Validators.required]],
			completed: [false, Validators.requiredTrue],
		});
	}

	startSubscriptionPaymentForm() {
		this.subscriptionPaymentForm = this.formBuilder.group({
			fullName: [{ value: "", disabled: true }, Validators.required],
			tarjetNumber: ["", Validators.required],
			cvc: ["", Validators.required],
			expirationDate: ["", [Validators.required, this.validateExpirationDate.bind(this)]],
			dateEndMonth: ["", Validators.required],
			dateEndYear: ["", Validators.required],
			email: [{ value: this.email, disabled: true }, Validators.required],
			phone: [{ value: this.phoneCustomerConekta, disabled: true }, Validators.required],
			detail: [{ value: "", disabled: false }, Validators.required],
			period: ['', Validators.required],
			completed: [false, Validators.requiredTrue]
		});
	}

	getPlans() {
		this.subscriptionpaymentService.getPlans().subscribe((data) => {
			if (this.isNew) {
				this.plans = data.filter(x => x.isEnabled);
			} else {
				this.plans = data.filter(x => x.packageNumber != 1);
			}

			if (this.currentSubscription == null && (this.action == "nueva" || this.action == "mejorar-plan")) {
				this.plans = this.plans.filter(x => x.packageNumber > this.currentSubscription.idPlan);

			} else if (this.action == "ampliar-plazo") {
				this.plans = this.plans.filter(x => x.packageNumber == this.currentSubscription.idPlan);
			}

			if (localStorage.getItem("plan") != null) {
				this.selectPlann(localStorage.getItem("plan"));
				localStorage.removeItem("plan")
			}
		})
	}

	planPeriodicity: boolean = false;
	onCheck(e: any) {
		this.planPeriodicity = e.target.checked;
	}

	getPlansListPlan() {
		if (this.planPeriodicity) {
			return this.plans.filter(x => x.isEnabled && x.packageNumber != 1);
		} else {
			return this.plans.filter(x => x.isEnabled);
		}
	}

	onChangePlanDetail(plan: string) {
		var packageNumber = this.plans.find(x => x.packageName == plan).packageNumber;

		this.f_subscriptionTypeForm['subscriptionType'].patchValue(packageNumber);
		this.numberOperations = this.plans.find(x => x.packageName == plan).packageOperations;
		this.setSubscriptionTypeSelected(packageNumber);

		this.onChangePlan();
	}

	selectPlann(column: string | null) {
		var packageNumber = this.plans.find(x => x.packageName == column).packageNumber;

		this.f_subscriptionTypeForm['completed'].patchValue(true);
		this.f_subscriptionTypeForm['subscriptionType'].patchValue(packageNumber);

		this.f_subscriptionPaymentForm['detail'].patchValue(column);

		if (this.planPeriodicity) {
			this.f_subscriptionPaymentForm['period'].patchValue(2);
		} else if (!this.planPeriodicity) {
			this.f_subscriptionPaymentForm['period'].patchValue(1);
		}

		this.setSubscriptionTypeSelected(packageNumber);

		this.onChangePlanDetail(column);

		if (this.isNew) {
			this.stepperSimulator.next();
		}
	}

	register(plan: string) {
		if (this.isNew) {
			if (plan == "Prueba gratis") {
				window.location.href = "/inicio";
			} else {
				localStorage.setItem("plan", plan);
				window.location.href = "iniciar-sesion?redirect=suscripciones/contratar-plan/nueva";
			}
			return;
		}
	}

	showDialogSendInfo() {
		let config = new MatDialogConfig();
		config = {
			width: '80%'
		};
		const dialog = this.dialog.open(SendInfoSuscriptionsComponent, config);
	}

	selectMethodPayment(methodNumber: number, index: number) {
		this.selectedPaymentIndex = index;
		this.f_subscriptionTypePaymentForm['subscriptionTypePayment'].patchValue(methodNumber);
		this.setPaymentTypeSelected(methodNumber)

		this.onSubmitSubscriptionTypePaymentForm();
	}

	getMethodsPayment() {
		this.subscriptionpaymentService.getMethodsPayment().subscribe((data) => {
			this.methodsPayment = data.filter(x => x.methodName !== "Transferencia");
		})
	}

	getPeriodList() {
		this.periodsService.getList()
			.subscribe((list) => {
				this.periodsList = list;
			});
	}

	validateSubscriptionTypedForm(formControlName: string): boolean {
		if (this.f_subscriptionTypeForm[formControlName].value == "") {
			return true;
		}
		return false;
	}

	validateMethodsPaymentForm(formControlName: string): boolean {
		if (this.f_subscriptionTypePaymentForm[formControlName].value == null) {
			return true;
		}
		return false;
	}

	validateRequiredForm(formControlName: string): boolean {
		if (this.f_subscriptionPaymentForm[formControlName].value == false) {
			return true;
		}
		return false;
	}

	validateResumenForm(formControlName: string): boolean {
		return false;
	}

	onSubmitSubscriptionMethodPaymentForm() {
		if (this.paymentSpeiSelected) {
			this.onSubmitSubscriptionPaymentForm();
		} else if (this.paymentOxxoSelected) {
			this.onSubmitSubscriptionPaymentOxxoForm();
		}
	}

	onSubmitSubscriptionTypeForm() {
		this.f_subscriptionTypeForm['completed'].patchValue(true);

		if (this.subscriptionTypeForm.invalid) {
			this.f_subscriptionTypeForm['completed'].patchValue(false);
			return;
		}

		this.stepperSimulator.next();
	}

	onSubmitSubscriptionTypePaymentForm() {
		this.f_subscriptionTypePaymentForm['completed'].patchValue(true);

		if (this.subscriptionTypePaymentForm.invalid) {
			this.f_subscriptionTypePaymentForm['completed'].patchValue(false);
			return;
		}

		var currencyYear = new Date().getFullYear();

		for (let i = 0; i <= 10; i++) {
			this.yearList.push(
				{
					key: currencyYear, value: currencyYear
				}
			)

			currencyYear++;
		}
	}

	setPaymentTypeSelected(option) {
		if (option == 1) {
			this.paymentSpeiSelected = true;
			this.paymentOxxoSelected = false;

			this.f_subscriptionPaymentForm['fullName'].setValidators([Validators.required]);
			this.f_subscriptionPaymentForm['tarjetNumber'].setValidators([Validators.required]);
			this.f_subscriptionPaymentForm['tarjetNumber'].patchValue("");
			this.f_subscriptionPaymentForm['cvc'].setValidators([Validators.required]);
			this.f_subscriptionPaymentForm['cvc'].patchValue("");
			this.f_subscriptionPaymentForm['dateEndMonth'].setValidators([Validators.required]);
			this.f_subscriptionPaymentForm['dateEndMonth'].patchValue("");
			this.f_subscriptionPaymentForm['dateEndYear'].setValidators([Validators.required]);
			this.f_subscriptionPaymentForm['dateEndYear'].patchValue("");
			this.f_subscriptionPaymentForm['phone'].clearValidators();
			this.f_subscriptionPaymentForm['completed'].patchValue(false);
		} else {
			this.paymentSpeiSelected = false;
			this.paymentOxxoSelected = true;

			this.f_subscriptionPaymentForm['fullName'].clearValidators();
			this.f_subscriptionPaymentForm['tarjetNumber'].clearValidators();
			this.f_subscriptionPaymentForm['tarjetNumber'].patchValue("");
			this.f_subscriptionPaymentForm['cvc'].clearValidators();
			this.f_subscriptionPaymentForm['cvc'].patchValue("");
			this.f_subscriptionPaymentForm['dateEndMonth'].clearValidators();
			this.f_subscriptionPaymentForm['dateEndMonth'].patchValue("");
			this.f_subscriptionPaymentForm['dateEndYear'].clearValidators();
			this.f_subscriptionPaymentForm['dateEndYear'].patchValue("");
			this.f_subscriptionPaymentForm['completed'].patchValue(false);
			const expirationDateControl = this.subscriptionPaymentForm.get('expirationDate');
			expirationDateControl.clearValidators();
			expirationDateControl.updateValueAndValidity();
		}
		this.paymentTypeSelected = this.methodsPayment[option - 1].methodName;
	}

	setSubscriptionTypeSelected(option) {
		this.planOperations = this.plans.find(x => x.packageNumber == option)['packageOperations']
		this.planOperationsOption = option;
	}

	onSubmitSubscriptionPaymentForm() { 
		this.subscriptionPaymentSpeiSubmited = true;
		this.f_subscriptionPaymentForm['completed'].patchValue(true);

		if (this.subscriptionPaymentForm.invalid) {
			this.f_subscriptionTypePaymentForm['completed'].patchValue(false);
			return;
		}

		var conektaSuccessResponseHandler = function (token) { }

		var conektaErrorResponseHandler = function (err) { };

		var $form = $("#card-form")

		var xmlHttp = new XMLHttpRequest();
		xmlHttp = window.Conekta.Token.create($form, conektaSuccessResponseHandler, conektaErrorResponseHandler);
		xmlHttp.onreadystatechange = () => {
			if (xmlHttp.readyState === 4) {
				if (xmlHttp.status === 200) {
					let data: ConektaTokenResponse[] = JSON.parse(xmlHttp.response) as ConektaTokenResponse[];

					if (data['object'] == "token") {
						this.tokenGenerated = data['id']
						this.alertGlobalService.showAlertSuccess("Comprobación correcta");
						this.isDisabledPay = false;
						this.isDisabledComprobation = true;
						this.numberLast = this.f_subscriptionPaymentForm['tarjetNumber'].value + "";
						this.numberLast = this.numberLast.substring(this.numberLast.length - 4);

						this.stepperSimulator.next();
					} else if (data['object'] == "error") {
						this.alertGlobalService.showAlertError(data['message_to_purchaser']);
						this.stepperSimulator.selectedIndex = 2;
						this.subscriptionPaymentForm.setErrors({ 'data_incorrect': true });
					}
				} else {
					this.alertGlobalService.showAlertError("Error");
				}
			}
		};
	}

	onSubmitSubscriptionPaymentOxxoForm() { 
		this.f_subscriptionPaymentForm['completed'].patchValue(true);

		if (this.subscriptionPaymentForm.invalid) {
			for (const controlName in this.subscriptionPaymentForm.controls) {
			  if (this.subscriptionPaymentForm.controls[controlName].invalid) {
				console.log(`Campo ${controlName} es inválido.`);
			  }
			}
		  }

		let subscriptionpayment = <Subscriptionpayment>{
			amount: this.totalAmount,
			description: this.f_subscriptionPaymentForm['detail'].value,
			fullName: this.f_subscriptionPaymentForm['fullName'].value,
			idPlan: this.f_subscriptionTypeForm['subscriptionType'].value,
			email: this.authService.currentUserValue.username,
			phone: this.f_subscriptionPaymentForm['phone'].value,
			idCustomerConekta: this.idCustomerConekta,
			idPeriod: parseInt(this.f_subscriptionPaymentForm['period'].value),
			subscripcionStartDate: this.newStartDate,
			subscripcionEndDate: this.newEndDate,
			periodQuantity: 1,
			amountWithoutTAX: this.netAmountPay,
			subscriptionType: this.subscriptionType,
			idService: 1
		};

		this.subscriptionpaymentService.createSubscriptionPayment(subscriptionpayment, this.authService.currentUserValue.customerId, "2").subscribe((data) => {
			this.html = this.sanitizer.bypassSecurityTrustHtml(data.toString());
			this.stepperSimulator.next();
		});
	}

	onSubmitSubscriptionResumenForm() {
		if (this.totalAmount <= 0) {
			this.alertGlobalService.showAlertWarning("monto superior ");

			return;
		}

		let subscriptionpayment = <Subscriptionpayment>{
			amount: this.totalAmount,
			description: this.f_subscriptionPaymentForm['detail'].value,
			fullName: this.f_subscriptionPaymentForm['fullName'].value,
			numberCard: this.numberLast,
			tokenId: this.tokenGenerated,
			idPlan: this.f_subscriptionTypeForm['subscriptionType'].value,
			idCustomerConekta: this.idCustomerConekta,
			email: this.authService.currentUserValue.username,
			phone: this.phoneCustomerConekta,
			idPeriod: parseInt(this.f_subscriptionPaymentForm['period'].value),
			subscripcionStartDate: this.newStartDate,
			subscripcionEndDate: this.newEndDate,
			periodQuantity: 1,
			amountWithoutTAX: this.netAmountPay,
			subscriptionType: this.subscriptionType,
			idService: 1
		};


	    this.subscriptionpaymentService.createSubscriptionPayment(subscriptionpayment, this.authService.currentUserValue.customerId, "1").subscribe((data) => {
			this.alertGlobalService.showAlertSuccess("Pago en proceso");

			this.submit = true;
			this.router.navigate(['/suscripciones']);
		}, error => {
			this.stepperSimulator.selectedIndex = 2;
			this.f_subscriptionPaymentForm['tarjetNumber'].patchValue("");
			this.f_subscriptionPaymentForm['dateEndMonth'].patchValue("");
			this.f_subscriptionPaymentForm['dateEndYear'].patchValue("");
			this.f_subscriptionPaymentForm['cvc'].patchValue("");
		});
	}

	getIsDisabledPay() {
		return this.isDisabledPay;
	}

	getIsDisabledComprobation() {
		return this.isDisabledComprobation;
	}

	onChangePlan() {
		if (this.f_subscriptionPaymentForm['period'].value == "") {
			return;
		}

		let calculatePrice = <SendCalculatePrice>{
			subscriptionType: this.subscriptionType,
			periodId: Number.parseInt(this.f_subscriptionPaymentForm['period'].value),
			planId: this.f_subscriptionTypeForm['subscriptionType'].value,
			quantity: 1
		};

		this.subscriptionpaymentService.getCalculatePlanPrice(calculatePrice).subscribe((data) => {
			this.totalAmount = data.amountTotal;
			this.subscripcionStartDate = data.newStartDate;
			this.remainingSDays = data.remainingSDays;
			this.subscripcionEndDate = data.newEndDate;
			this.pricePlan = data.amountPay;
			this.discount = this.getDiscountFormat(data.discount, data.amountPay);
			this.netAmountPay = data.netAmountPay;
			this.price = data.amountPay;
			this.priceTax = data.amountTax;
			this.positiveBalance = data.positiveBalance;

			this.selectMethodPayment(1, 0);

			this.currentStartDate = data.currentStartDate;
			this.currentEndDate = data.currentEndDate;
			this.newStartDate = data.newStartDate;
			this.newEndDate = data.newEndDate;
			this.percentDiscount = data.discount / 100;
			this.amountDiscount = data.amountDiscount;
		});
	}

	getCustomer(): void {
		this.customerService.getCustomer(this.authService.currentUserValue.customerId)
			.subscribe((data) => {
				this.idCustomerConekta = data.idCustomerConekta;
				this.phoneCustomerConekta = data.phoneMobile;

				var fullNameCustomer = (data.firstName || "") + " ";
				fullNameCustomer += (data.middleName || "") + " ";
				fullNameCustomer += (data.lastName1 || "") + " ";
				fullNameCustomer += (data.lastName2 || "") + " ";

				this.f_subscriptionPaymentForm['fullName'].patchValue(fullNameCustomer.replace("  ", " ").trim());
				this.f_subscriptionPaymentForm['fullName'].patchValue(fullNameCustomer.replace("  ", " ").trim());
				this.f_subscriptionPaymentForm['phone'].patchValue(data.phoneMobile);
			});
	}

	getCurrentSubscription(): void {
		this.subscriptionpaymentService.getCurrentSubscription().subscribe((data) => {
			this.currentSubscription = data;
			this.getPlans();
		})
	}

	print() {
		var mywindow = window.open('', '');
		var htmlString = this.html.toString();
		htmlString = htmlString.replace("SafeValue must use [property]=binding:", "")
		htmlString = htmlString.replace("(see http://g.co/ng/security#xss)", "")

		mywindow.document.write(htmlString);
		mywindow.stop();
		mywindow.print();

		return true;
	}

	exportPDF() {
		var html = htmlToPdfmake(this.html.toString(), { tableAutoSize: true });
		const documentDefinition = { content: [html], styles: { table: { border: '0px solid black' } } };

		pdfMake.createPdf(documentDefinition).open();
	}

	suscribeValueChanges() {
		Object.getOwnPropertyNames(this.f_subscriptionPaymentForm).forEach((prop) => {
			if (prop == 'tarjetNumber') {
				this.f_subscriptionPaymentForm[prop].valueChanges.subscribe(() => {
					var value: string = this.f_subscriptionPaymentForm[prop].value;

					if (!value) {
						return;
					}

					var imgCard = document.getElementById("imgCard")
					imgCard.style.display = "inline";

					switch (value.substring(0, 1)) {
						case "3": {
							imgCard.style.opacity = "1";
							imgCard.style.backgroundPosition = "-0px 0px";
							imgCard.setAttribute("src", "/assets/images/logos/amex_logo.png");
							this.mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];
							this.maskCVV = [/\d/, /\d/, /\d/, /\d/];
							break;
						}
						case "4": {
							imgCard.style.opacity = "1";
							imgCard.style.backgroundPosition = "0px 0px";
							imgCard.setAttribute("src", "/assets/images/logos/visa_logo.png");
							this.mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
							this.maskCVV = [/\d/, /\d/, /\d/];
							break;
						}
						case "5": {
							imgCard.style.opacity = "1";
							imgCard.style.backgroundPosition = "0px 0px";
							imgCard.setAttribute("src", "/assets/images/logos/mastercard_logo.png");
							this.mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
							this.maskCVV = [/\d/, /\d/, /\d/];
							break;
						}
					}
				});
			}
		});
	}

	getDiscountFormat(discount: number, netAmountPay: number) {
		return netAmountPay * (discount / 100);
	}

	getPlansList() {
		if (this.currentSubscription == null && (this.action == "nueva" || this.action == "mejorar-plan")) {
			return this.plans.filter(x => x.packageNumber > this.currentSubscription.idPlan);
		}
		else {
			return this.plans;
		}
	}

	updateExpirationDate(event: any) {
		const value = event.target.value;

		if (value == "") {
			return;
		}

		const parts = value.split('/');

		var month = parts[0].toString();

		if (!month.includes("_")) {
			this.f_subscriptionPaymentForm['dateEndMonth'].patchValue(parseInt(month));
		}

		var year = parts[1].toString();

		if (!year.includes("_")) {
			let yearValue = parseInt(year, 10);

			this.f_subscriptionPaymentForm['dateEndYear'].patchValue(parseInt("20" + yearValue.toString()));
		}
	}

	validateExpirationDate(control: any) {
		var currentYear = new Date().getFullYear();
		const value = control.value;

		if (value == "") {
			return;
		}

		const parts = value.split('/');
		const month = parseInt(parts[0], 10);
		const year = parseInt("20" + parts[1].toString());

		if (month < 1 || month > 12) {
			return { invalidMonth: true };
		}

		if (year < currentYear || year > currentYear + 5) {
			return { invalidYear: true };
		}

		return null;
	}
}
