<form class="form-horizontal" [formGroup]="formrequestJsonForm">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelContactDataState" class="mb-4 bg-white"
            (opened)="panelContactDataOpen(true)" (closed)="panelContactDataOpen(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <h5>Datos contacto</h5>
                        <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-accordion *ngIf="personType == 1">
                <mat-expansion-panel [expanded]="panelDifferentState" class="mb-4"
                    (opened)="panelDifferentOpen(true)" (closed)="panelDifferentOpen(false)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>Persona contacto</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div *ngIf="validateFormrequest('DifferenteLegal') && personType == 1"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-DifferenteLegal">Representante Legal <span
                                        *ngIf="validateFormrequestJson('DifferenteLegal')">*</span></label>
                                <select #selectDifferenteLegal class="form-control"
                                    formControlName="value-DifferenteLegal"
                                    (change)="onChangeDifferenteLegal(selectDifferenteLegal.value)"
                                    [ngClass]="validateRequiredFormF2('value-DifferenteLegal') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of yesnoList" value="{{item.key}}">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div *ngIf="validateFormrequest('LegalFirstName')"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LegalFirstName">Nombre <span
                                        *ngIf="validateFormrequestJson('LegalFirstName')">*</span></label>
                                <input type="text" formControlName="value-LegalFirstName"
                                    class="form-control"
                                    [ngClass]="validateRequiredFormF2('value-LegalFirstName') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LegalMiddleName')"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LegalMiddleName">Segundo nombre <span
                                        *ngIf="validateFormrequestJson('LegalMiddleName')">*</span></label>
                                <input type="text" formControlName="value-LegalMiddleName"
                                    class="form-control"
                                    [ngClass]="validateRequiredFormF2('value-LegalMiddleName') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LegalLastName1')"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LegalLastName1">Primer apellido <span
                                        *ngIf="validateFormrequestJson('LegalLastName1')">*</span></label>
                                <input type="text" formControlName="value-LegalLastName1"
                                    class="form-control"
                                    [ngClass]="validateRequiredFormF2('value-LegalLastName1') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LegalLastName2')"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LegalLastName2">Segundo apellido <span
                                        *ngIf="validateFormrequestJson('LegalLastName2')">*</span></label>
                                <input type="text" formControlName="value-LegalLastName2"
                                    class="form-control"
                                    [ngClass]="validateRequiredFormF2('value-LegalLastName2') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LegalPosition')"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LegalPosition">Puesto/ cargo <span
                                        *ngIf="validateFormrequestJson('LegalPosition')">*</span></label>
                                <input type="text" formControlName="value-LegalPosition"
                                    class="form-control"
                                    [ngClass]="validateRequiredFormF2('value-LegalPosition') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LegalArea')"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LegalArea">Área/ departamento <span
                                        *ngIf="validateFormrequestJson('LegalArea')">*</span></label>
                                <input type="text" formControlName="value-LegalArea"
                                    class="form-control"
                                    [ngClass]="validateRequiredFormF2('value-LegalArea') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LegalPhone')"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LegalPhone">Teléfono <span
                                        *ngIf="validateFormrequestJson('LegalPhone')">*</span></label>
                                <input type="text" [textMask]="{mask: phoneMask}"
                                    formControlName="value-LegalPhone" class="form-control"
                                    [ngClass]="validateRequiredFormF2('value-LegalPhone') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LegalEmail')"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LegalEmail">Correo electrónico <span
                                        *ngIf="validateFormrequestJson('LegalEmail')">*</span></label>
                                <input type="text" formControlName="value-LegalEmail"
                                    class="form-control"
                                    [ngClass]="validateRequiredFormF2('value-LegalEmail') ? 'is-invalid' : ''">
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel [expanded]="panelPhoneState" class="mb-4"
                    (opened)="panelPhoneOpen(true)" (closed)="panelPhoneOpen(false)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>Teléfono</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div *ngIf="validateFormrequest('PhoneMobile')"
                            class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-PhoneMobile">
                                    Teléfono celular <span *ngIf="validateFormrequestJson('PhoneMobile')">*</span>
                                </label>
                                <input type="text" [textMask]="{mask: phoneMask}" formControlName="value-PhoneMobile" class="form-control" [ngClass]="validateRequiredFormF2('value-PhoneMobile') ? 'is-invalid' : ''">
                            </div>
                        </div>

                        <form [formGroup]="contactPhoneForm" (ngSubmit)="onSubmitContactPhone()">
                            <div *ngIf="!getIsDisabledSave()" class="row" [style.display]="currentPath.startsWith('/centro-de-llamadas/') ? 'none' : ''">
                                <div class="subtittle2">Agregar teléfono</div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <label>Tipo de teléfono </label>
                                        <select class="form-control" formControlName="phoneType">
                                            <option *ngFor="let item of phoneList"
                                                value="{{item.value}}">{{item.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <label>Número</label>
                                        <input class="form-control" type="text"
                                            [textMask]="{mask: phoneMask}" placeholder=""
                                            formControlName="phone" autocomplete="off"
                                            autocapitalize="off">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                                    <div>
                                        <div class="form-group">
                                            <label>Comentarios</label>
                                            <input class="form-control" type="text" placeholder="" formControlName="comments" autocomplete="off" autocapitalize="off">
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-primary internal-btn">
                                        Agregar
                                    </button>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <div class="react-bootstrap-table">
                                    <table *ngIf="dsContactPhoneData" mat-table
                                        [dataSource]="dsContactPhoneData">
                                        <ng-container matColumnDef="phoneType">
                                            <th mat-header-cell *matHeaderCellDef> Tipo de teléfono
                                            </th>
                                            <td mat-cell *matCellDef="let row"> {{row.phoneType}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="phone">
                                            <th mat-header-cell *matHeaderCellDef> Número </th>
                                            <td mat-cell *matCellDef="let row">
                                                <span>{{row.phone}}</span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="comments">
                                            <th mat-header-cell *matHeaderCellDef> Comentarios </th>
                                            <td mat-cell *matCellDef="let row"> {{row.comments}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="buttonRemove">
                                            <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                                            <td mat-cell *matCellDef="let row; let i = index;">
                                                <i class="bx bx-x" (click)="onDeleteContactPhone(i)"></i>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="contactPhoneColumns">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: contactPhoneColumns;">
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel [expanded]="panelEmailState" class="mb-4"
                    (opened)="panelEmailOpen(true)" (closed)="panelEmailOpen(false)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>Correo electrónico</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div *ngIf="validateFormrequest('Email')"
                            class="col-md-5 mb-3">
                            <div class="form-group">
                                <label for="value-Email">Correo electrónico <span
                                        *ngIf="validateFormrequestJson('Email')">*</span></label>
                                <input type="text" formControlName="value-Email"
                                    class="form-control"
                                    [ngClass]="validateRequiredFormF2('value-Email') ? 'is-invalid' : ''">
                            </div>
                        </div>

                        <form [formGroup]="contactEmailForm" (ngSubmit)="onSubmitContactEmail()">
                            <div *ngIf="!getIsDisabledSave()" class="row">
                                <div class="subtittle2">Agregar correo</div>
                                <div class="col-lg-3 col-md-6 marginCustom mb-3">
                                    <div class="form-group">
                                        <label>Tipo de correo </label>
                                        <select #selectDebtType class="form-control"
                                            formControlName="emailType">
                                            <option *ngFor="let item of emailList"
                                                value="{{item.value}}">{{item.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 marginCustom mb-3">
                                    <div class="form-group">
                                        <label>Correo</label>
                                        <input class="form-control" type="text" placeholder=""
                                            formControlName="email" autocomplete="off"
                                            autocapitalize="off">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                                    <div>
                                        <div class="form-group">
                                            <label>Comentarios</label>
                                            <input class="form-control" type="text" placeholder=""
                                                formControlName="comments" autocomplete="off"
                                                autocapitalize="off">
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-primary internal-btn">
                                        Agregar
                                    </button>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <div class="react-bootstrap-table">
                                    <table *ngIf="dsContactEmailData" mat-table
                                        [dataSource]="dsContactEmailData">
                                        <ng-container matColumnDef="emailType">
                                            <th mat-header-cell *matHeaderCellDef> Tipo de correo
                                            </th>
                                            <td mat-cell *matCellDef="let row"> {{row.emailType}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="email">
                                            <th mat-header-cell *matHeaderCellDef> correo </th>
                                            <td mat-cell *matCellDef="let row">
                                                <span>{{row.email}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="comments">
                                            <th mat-header-cell *matHeaderCellDef> Comentarios </th>
                                            <td mat-cell *matCellDef="let row"> {{row.comments}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="buttonRemove">
                                            <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                                            <td mat-cell *matCellDef="let row; let i = index;">
                                                <i class="bx bx-x" (click)="onDeleteContactEmail(i)"></i>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="contactEmailColumns">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: contactEmailColumns;">
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel [expanded]="panelSocialNetworksState" (opened)="panelSocialNetworksOpen(true)" (closed)="panelSocialNetworksOpen(false)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>Redes sociales</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <form [formGroup]="socialNetworksForm" (ngSubmit)="onSubmitSocialNetworks()">
                            <div *ngIf="!getIsDisabledSave()" class="row">
                                <div class="subtittle2">Agregar red social</div>
                                <div class="col-lg-3 col-md-6 marginCustom mb-3">
                                    <div class="form-group">
                                        <label>Tipo de red social </label>
                                        <select #selectDebtType class="form-control"
                                            formControlName="socialNetwork">
                                            <option *ngFor="let item of socialNetworksList"
                                                value="{{item.value}}">{{item.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 marginCustom mb-3">
                                    <div class="form-group">
                                        <label>Link</label>
                                        <input class="form-control" type="text" placeholder=""
                                            formControlName="link" autocomplete="off"
                                            autocapitalize="off">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                                    <div>
                                        <div class="form-group">
                                            <label>Comentarios</label>
                                            <input class="form-control" type="text" placeholder=""
                                                formControlName="comments" autocomplete="off"
                                                autocapitalize="off">
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-primary internal-btn">
                                        Agregar
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <div class="react-bootstrap-table">
                                    <table *ngIf="dsSocialNetworksData" mat-table [dataSource]="dsSocialNetworksData">
                                        <ng-container matColumnDef="socialNetwork">
                                            <th mat-header-cell *matHeaderCellDef>Red Social</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.socialNetwork}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="link">
                                            <th mat-header-cell *matHeaderCellDef>Correo</th>
                                            <td mat-cell *matCellDef="let row">
                                                <span>{{row.link}}</span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="comments">
                                            <th mat-header-cell *matHeaderCellDef>Comentarios</th>
                                            <td mat-cell *matCellDef="let row"> {{row.comments}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="buttonRemove">
                                            <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                                            <td mat-cell *matCellDef="let row; let i = index;">
                                                <i class="bx bx-x" (click)="onDeleteSocialNetworks(i)"></i>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="socialNetworksColumns">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: socialNetworksColumns;">
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-expansion-panel>
    </mat-accordion>
</form>
