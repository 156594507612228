import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { DatePipe, KeyValue } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { TypeofbillService } from 'src/app/services/typeofbill/typeofbill.service';
import { MatTableDataSource } from '@angular/material/table';
import { subscriptionpaymentDTO } from 'src/app/models/subscriptionpayment/subscriptionpayment.dto.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { Router } from '@angular/router';
import { FiscalRegime, cfdiUse } from 'src/app/models/subscriptionpayment/subscriptionpayment.model';
import { GlobalLists } from 'src/app/global/global-data';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { Customer } from 'src/app/models/customer/customer.model';

export interface plansRowsElement {
	detail: String;
}

@Component({
	selector: 'app-bill-new',
	templateUrl: './bill-new.component.html',
	styleUrls: ['./bill-new.component.scss']
})

export class BillNewComponent implements OnInit {

	@ViewChild('stepperBill') stepperSimulator: MatStepper;
	typeOfBilltList: KeyValue<number, string>[] = [];
	subscriptionsForm: FormGroup;
	billDataForm: FormGroup;
	billDataSource: MatTableDataSource<subscriptionpaymentDTO>;
	rowSelected: subscriptionpaymentDTO = <subscriptionpaymentDTO>{};
	indexSelected = null;
	isDisabledNext = true;
	totalAmount: number = 0;
	billTypeFormSubmitted = false;
	customerName = "";
	billingStates: any;
	billingCities: any;
	countries: any;
	requiredErrorMessage: string = "Este campo es requerido.";
	submitted = false;
	charCountRfc: number = 0;
	dataBillingRFC: string;

	subscriptionColumns: string[] = [
		'numberOperations',
		'description',
		'dateCreated',
		'methodPayment',
		'amount',
		'status'
	];

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	periodsList: KeyValue<number, string>[] = [];

	fiscalRegimeList: FiscalRegime[];
	cfdiUseList: cfdiUse[] = [];
	customerRFC: boolean;
	showError = false;
	billingTaxRegime: any;
	showErrorValidationMonth: boolean = null;
	errorValidationMonthMessage = "";
	messageError = "";
	activiteList = GlobalLists.admininTypeList;
	businessName: string;

	constructor(
		private formBuilder: FormBuilder,
		private typeofbillService: TypeofbillService,
		private subscriptionpaymentService: SubscriptionpaymentService,
		private authService: AuthenticationService,
		private alertGlobalService: AlertGlobalService,
		private customerService: CustomerService,
		private router: Router,
		private catalogService: CatalogService,
		private datePipe: DatePipe
	) {
		this.startSubscriptionsForm();
		this.startbillDataForm();

		this.catalogService.getCountries().subscribe(
			(countries) => {
				this.countries = countries;
			}
		);
	}

	requiredbillTypeErrorMessage: string = "por favor seleccione un tipo de factura";
	requiredSubscriptionTypePaymentErrorMessage: string = "por favor seleccione un metodo de pago";

	get f_subscriptionsForm() { return this.subscriptionsForm.controls; }
	get f_billDataForm() { return this.billDataForm.controls; }

	get f_subscriptionsFormProp() { return Object.getOwnPropertyNames(this.f_subscriptionsForm); }
	get f_billDataFormProp() { return Object.getOwnPropertyNames(this.f_billDataForm); }

	ngOnInit(): void {
		this.getCustomer();
		this.getTypeOfBillList();
		this.getFiscalRegimeList();

		this.showErrorValidationMonth = null;
		this.rowSelected = null;

		this.getSubscriptionsBills();
	}

	startbillDataForm() {
		this.billDataForm = this.formBuilder.group({
			billingTaxRegime: [''],
			cfdiUse: ['', [Validators.required]],
			street: ['', [Validators.required]],
			exteriorNumber: ['', [Validators.required]],
			interiorNumber: [''],
			districtName: ['', [Validators.required]],
			countryId: ['', [Validators.required]],
			stateId: ['', [Validators.required]],
			cityId: ['', [Validators.required]],
			zipCode: ['', [Validators.required]],
			businessName: [''],
			billingRfc: ['', [Validators.maxLength(13), Validators.minLength(12)]],
			completed: [null, Validators.requiredTrue]
		});
	}

	getTaxProduct() {
		this.subscriptionpaymentService.getSearchProduct(this.rowSelected).subscribe((data) => {
			this.totalAmount = data.price + (data.price * data.taxes.find(x => x.name == "IVA").rate);
		});
	}

	getTypeOfBillList() {
		this.typeofbillService.getList()
			.subscribe((list) => {
				this.typeOfBilltList = list;
			});
	}

	getFiscalRegimeList() {
		this.subscriptionpaymentService.getFiscalRegimeList().subscribe((data) => {
			this.fiscalRegimeList = data;
		});
	}

	getCfdiUseList(billingRfc: string) {
		this.subscriptionpaymentService.getCfdiUseList(billingRfc).subscribe((data) => {
			this.cfdiUseList = data;
		});
	}

	validateSubscriptionsForm(formControlName: string): boolean {
		if (this.f_subscriptionsFormProp[formControlName].value == null) {
			return true;
		}
		return false;
	}

	startSubscriptionsForm() {
		this.subscriptionsForm = this.formBuilder.group({
			completed: [false, Validators.requiredTrue],
		});
	}

	onSubmitSubscriptionsForm() {
		this.submitSubscriptionsForm();
	}

	onSubmitBillDataForm() {
		this.submitted = true;

		this.rowSelected.billingTaxRegime = this.f_billDataForm['billingTaxRegime'].value;
		this.rowSelected.cfdiUse = this.f_billDataForm['cfdiUse'].value;
		this.rowSelected.street = this.f_billDataForm['street'].value;
		this.rowSelected.exteriorNumber = this.f_billDataForm['exteriorNumber'].value;
		this.rowSelected.interiorNumber = this.f_billDataForm['interiorNumber'].value;
		this.rowSelected.districtName = this.f_billDataForm['districtName'].value;
		this.rowSelected.countryName = this.countries.find(x => x.code == this.f_billDataForm['countryId'].value).name;
		this.rowSelected.stateName = this.billingStates.find(x => x.code == this.f_billDataForm['stateId'].value).name;
		this.rowSelected.cityName = this.billingCities.find(x => x.code == this.f_billDataForm['cityId'].value).name;
		this.rowSelected.zipCode = this.f_billDataForm['zipCode'].value.toString();
		this.rowSelected.businessName = this.f_billDataForm['businessName'].value;
		this.rowSelected.billingRfc = this.f_billDataForm['billingRfc'].value;

		let data = <Customer>{
			billingStreet: this.f_billDataForm['street'].value,
			billingExteriorNumber: this.f_billDataForm['exteriorNumber'].value,
			billingInteriorNumber: this.f_billDataForm['interiorNumber'].value,
			billingZipCode: this.f_billDataForm['zipCode'].value.toString(),
			billingCountryId: this.f_billDataForm['countryId'].value,
			billingStateId: this.f_billDataForm['stateId'].value,
			billingCityId: this.f_billDataForm['cityId'].value,
			billingDistrictName: this.f_billDataForm['districtName'].value,
			billingBusinessName: this.f_billDataForm['businessName'].value,
			billingTaxRegime: this.f_billDataForm['billingTaxRegime'].value,
			billingRfc: this.f_billDataForm['billingRfc'].value,
			billingCfdiUse: this.f_billDataForm['cfdiUse'].value
		};

		this.customerService.updateCustomerBilling(this.authService.currentUserValue.customerId, data).subscribe((result) => {
			this.alertGlobalService.showAlertSuccess("Datos de facturación guardados.");
		});

		this.businessName = this.f_billDataForm['businessName'].value;
		this.stepperSimulator.next();
	}

	submitSubscriptionsForm() {
		this.f_subscriptionsForm['completed'].patchValue(true);

		if (this.subscriptionsForm.invalid) {
			this.f_subscriptionsForm['completed'].patchValue(false);
			return;
		}

		if (this.indexSelected == null) {
			return;
		}

		this.getTaxProduct();

		this.stepperSimulator.next();
	}

	onSubmitBillResumeForm() {
		this.rowSelected.generateFactura = true;

		this.subscriptionpaymentService.createBill(this.rowSelected, "1").subscribe((data) => {
			if (data.success) {
				this.alertGlobalService.showAlertSuccess(data.responseCreatebill);

				this.router.navigate(['/mi-cuenta']);
			} else {
				this.messageError = data.responseCreatebill;

				if (data.details.length > 0) {
					data.details.forEach(detail => {
						this.messageError += ", " + detail;
					});
				}

				this.showError = true;
			}
		});
	}

	getSubscriptionsBills(): void {
		this.subscriptionpaymentService.getBillsAvailables().subscribe(
			(data) => {
				this.billDataSource = new MatTableDataSource(data);
				if (data) {
					this.billDataSource.paginator = this.paginator;
					this.billDataSource.sort = this.sort;

					this.indexSelected = null;
					this.rowSelected = null;
					this.errorValidationMonthMessage = "";
				}
			}
		);
	}

	getCustomer(): void {
		this.customerService.getCustomer(this.authService.currentUserValue.customerId)
			.subscribe((data) => {
				if (data.middleName == null) {
					data.middleName = "";
				}

				if (data.lastName2 == null) {
					data.lastName2 = "";
				}
				this.customerName = (data.firstName + " " + data.middleName).trim() + " " + (data.lastName1 + " " + data.lastName2).trim()


				this.catalogService.getStates(data.billingCountryId).subscribe(
					(states) => {
						this.billingStates = states;
					}
				);

				if (data.billingCountryId && data.billingStateId) {
					this.catalogService.getCities(data.billingCountryId, data.billingStateId)
						.subscribe((cities) => {
							this.billingCities = cities;
						});
				}

				if (data.billingRfc.length >= 12 && data.billingRfc.length <= 13) {
					this.getCfdiUseList(data.billingRfc);
				}

				this.f_billDataForm['street'].patchValue(data.billingStreet);
				this.f_billDataForm['exteriorNumber'].patchValue(data.billingExteriorNumber);
				this.f_billDataForm['interiorNumber'].patchValue(data.billingInteriorNumber);
				this.f_billDataForm['districtName'].patchValue(data.billingDistrictName);
				this.f_billDataForm['countryId'].patchValue(data.billingCountryId);
				this.f_billDataForm['stateId'].patchValue(data.billingStateId);
				this.f_billDataForm['cityId'].patchValue(data.billingCityId);
				this.f_billDataForm['zipCode'].patchValue(data.billingZipCode);
				this.f_billDataForm['businessName'].patchValue(data.billingBusinessName);
				this.f_billDataForm['billingTaxRegime'].patchValue(data.billingTaxRegime);
				this.f_billDataForm['billingRfc'].patchValue(data.billingRfc);
				this.f_billDataForm['cfdiUse'].patchValue(data.billingCfdiUse);

			});
	}

	onClickSort() {
		this.rowSelected = <subscriptionpaymentDTO>{};
		this.indexSelected = null;
	}

	doubleClick(event, loanRequestId) {
		if (this.showErrorValidationMonth) {
			return;
		}

		this.submitSubscriptionsForm();
	}

	onRowClicked(row, index) {
		this.rowSelected = row;
		this.indexSelected = index;
		this.isDisabledNext = false;

		var limitDate = new Date(this.rowSelected.dateCreated);

		limitDate.setMonth(limitDate.getMonth() + 1);
		limitDate.setDate(0);
		limitDate.setHours(0, 0, 0, 0);

		var currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);

		this.errorValidationMonthMessage = "Fecha limite para facturar " + this.datePipe.transform(limitDate, "dd") + ' de ' + this.activiteList.find(x => x.key == limitDate.getMonth()).value + ' del ' + limitDate.getFullYear();

		if (currentDate > limitDate) {
			this.showErrorValidationMonth = true;
			this.f_billDataForm['completed'].patchValue(false);
		} else {
			this.showErrorValidationMonth = false;
			this.f_billDataForm['completed'].patchValue(true);
		}
	}

	onClickPage() {
		this.rowSelected = <subscriptionpaymentDTO>{};
		this.indexSelected = null;
	}

	getIsDisabledNext() {
		return this.isDisabledNext;
	}

	getPeriod(e: number) {
		if (e == 1) {
			return "Mensual"
		} else if (e == 2) {
			return "Anual"
		}
	}

	onChangeSelectCountries(countryId: string) {
		this.billingStates = [];
		this.billingCities = [];
		this.catalogService.getStates(countryId).subscribe(
			(states) => {
				this.f_billDataForm['stateId'].patchValue("");
				this.billingStates = states;
			}
		);
	}

	onChangeSelectStates(countryId: string, stateId: string) {
		this.billingCities = [];
		this.catalogService.getCities(countryId, stateId).subscribe(
			(cities) => {
				this.f_billDataForm['cityId'].patchValue("");
				this.billingCities = cities;
			}
		);
	}

	validateRequiredForm(formControlName: string): boolean {
		if (this.f_billDataForm[formControlName].errors !== (null && "") && this.f_billDataForm[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateRfcRewrite(value: string) {
		var billingRfc = value
		if (billingRfc.length > 11) {
			this.isValidRFC(billingRfc);
			if (this.isValidRFC) {
				this.getCfdiUseList(billingRfc);
			}
		}
	}

	isValidRFC(billingRfc: any) {
		const rfc = billingRfc;
		if (rfc == null || rfc === '' || rfc.length < 12 && rfc.length > 13) {
			this.customerRFC = false;
		} else {
			this.customerRFC = true;
		}
	}

	rfcInput(event: any) {
		this.charCountRfc = event.target.value.length;
		this.validateRfcRewrite(event.target.value);
	}
}
