import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoanRequestDTO } from 'src/app/models/loanrequest/loanrequest.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogLoanRequestFormComponent } from '../dialog-loan-request-form/dialog-loan-request-form.component';
import { DocumentGeneratorListComponent } from '../document-generator-list/document-generator-list.component';
import { CurrencyPipe } from '@angular/common';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake"
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-loanrequest-list',
  templateUrl: './loanrequest-list.component.html',
  styleUrls: ['./loanrequest-list.component.scss']
})
export class LoanrequestListComponent implements OnInit, AfterViewInit {
 
  @Input() made: boolean = true;
	@Input() buttonsAction: boolean = true;
  loanRequestId: string;

  userIdLogged: string;

  rowSelected: LoanRequestDTO = <LoanRequestDTO>{};
  indexSelected = null;

  loanrequestColumns: string[] = [
    'dateSent',
    'lenderCustomerFullName',
    'borrowerCustomerFullName',
    // 'typeOfMessageMediumDesc',
    'lenderEmail',
    'borrowerEmail',
    'loanAmount',
    // 'dateStart',
    'monthlyRate',
    'totalPeriodPay',
    'statusName'
  ];
  loanrequestDataSource: MatTableDataSource<LoanRequestDTO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loanRequests: LoanRequestDTO[];
  html: SafeHtml;
  isLender = false;
  
  constructor(private loanrequestService: LoanrequestService,
              private authService: AuthenticationService,
              private dialog: MatDialog,
              private alertGlobalService: AlertGlobalService,
              private route: ActivatedRoute,
              private router: Router,
              private currencyPipe: CurrencyPipe) {}
  
  ngOnInit(): void {
    this.userIdLogged = this.authService.currentUserValue.userId;
    this.isLender = this.authService.currentUserModeLenderValue;
  }

  ngAfterViewInit() {
    this.getLoanRequests();

    // muestra pantalla de detalle de condiciones
    this.loanRequestId = this.route.snapshot.paramMap.get('loanRequestId');
    if (this.loanRequestId) {
      this.loanrequestService.getLoanRequest(this.loanRequestId)
      .subscribe((result) => {
        if (result.lenderCustomerId === this.authService.currentUserValue.customerId) {
          this.authService.changeUserModeLenderValue(true);
          this.openDialogLoanrequestDetail(this.loanRequestId);
        } else if (result.borrowerCustomerId === this.authService.currentUserValue.customerId) {
          this.authService.changeUserModeLenderValue(false);
          this.openDialogLoanrequestDetail(this.loanRequestId);
        }
      });      
    }
  }

  getLoanRequests() {
    if(this.made) {
      this.getLoanRequestMade();
    }
    else {
      this.getLoanRequestReceived();
    }
  }

  getLoanRequestMade() { 
    this.loanrequestService.getLoanRequestMade(this.authService.currentUserValue.customerId, "")
    .subscribe((result) => {
      if (result) {
        this.loanrequestDataSource = new MatTableDataSource(result);
      } else {
        this.loanrequestDataSource = new MatTableDataSource([]);
      }
        this.loanrequestDataSource.paginator = this.paginator;
        this.loanrequestDataSource.sort = this.sort;
    });
  }

  getLoanRequestReceived() { 
    this.loanrequestService.getLoanRequestReceived(this.authService.currentUserValue.customerId, "")
    .subscribe((result) => {
      if (result == null) {
        this.loanrequestDataSource = new MatTableDataSource([]);
      }else {
        this.loanrequestDataSource = new MatTableDataSource(result);
      }
      this.loanrequestDataSource.paginator = this.paginator;
      this.loanrequestDataSource.sort = this.sort;
    });
  }

  openDialogLoanrequestDetail(loanRequestId: string) {
    this.openLoanrequestResumeComponent(loanRequestId);
  }

  openDialogDocumentGeneratorList(loanRequestId: string) {
    const dialogRef = this.dialog.open(DocumentGeneratorListComponent, {
      autoFocus: false,
      width: '80%'
    });
    let instance = dialogRef.componentInstance;
    instance.loanRequestId = loanRequestId;

    dialogRef.afterClosed().subscribe(() => {
      this.getLoanRequests();
    });
  }

  openDialogLoanRequestForm(loanRequestId: string, borrowerCustomerId: string) {
    const dialogRef = this.dialog.open(DialogLoanRequestFormComponent, {
      autoFocus: false,
      width: '80%',
      disableClose: true
    });
    let instance = dialogRef.componentInstance;
    instance.loanRequestId = loanRequestId;
    instance.borrowerCustomerId = borrowerCustomerId;

    dialogRef.afterClosed().subscribe(() => {
      this.getLoanRequests();
    });
  }

  removeLoanRequest(loanRequestId: string, amount: any) { 
    this.dialog
    .open(DialogConfirmComponent, {
      data: {
        message: '¿Desea eliminar la solicitud de ' + this.currencyPipe.transform(amount) + '?'
      }
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
      if (confirmado) {
        this.loanrequestService.remove(loanRequestId)
        .subscribe(() => {
          this.alertGlobalService.showAlertSuccess("Solicitud eliminada con éxito.");
          this.getLoanRequests();
          this.rowSelected = <LoanRequestDTO>{};
          this.indexSelected = null;
        });
      }
    });
  }

  onRowClicked(row, index) {
    this.rowSelected = row;
    this.indexSelected = index;
  }

  onClickPage() {
    this.rowSelected = <LoanRequestDTO>{};
    this.indexSelected = null;
  }

  onClickSort() {
    this.rowSelected = <LoanRequestDTO>{};
    this.indexSelected = null;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.loanrequestDataSource.filter = filterValue.trim().toLowerCase();
  }

  openDetailResume(loanRequestId) {
    this.openLoanrequestResumeComponent(loanRequestId);
  }

  doubleClick (event, loanRequestId)  {
    if (!loanRequestId) {
      return
    }

    if (!this.buttonsAction) {
      return
    }

    this.openLoanrequestResumeComponent(loanRequestId);
  }

  openLoanrequestResumeComponent(loanRequestId: any) {
    // window.location.href = "/solicitud-de-prestamo/" + loanRequestId;
    this.router.navigate(['solicitud-de-prestamo/' + loanRequestId]);
  }

  	exportExcel() {
		var dataToExport = [];
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("Employee Data");
		let header=["Fecha que se envió", "Nombre de contacto", "Medio de envio", "Datos de contacto", "Monto solicitado", "Interés mensual", "Monto total a pagar", "Estatus"]
		let headerRow = worksheet.addRow(header);

		for ( let i of this.loanrequestDataSource.data) {
			dataToExport.push({
				date: i.date,
				contact: this.made ? i.borrowerCustomerFullName : i.lenderCustomerFullName,
				typeOfMessageMediumDesc: i.typeOfMessageMediumDesc,
				lenderEmail: this.made ? i.borrowerEmail : i.lenderEmail,
				loanAmount: this.currencyPipe.transform(i.loanAmount),
				monthlyRate: i.monthlyRate,
				totalPeriodPay: this.currencyPipe.transform(i.totalPeriodPay),
				statusName: i.statusName
			})
		}

		for (let x1 of dataToExport) {
			let x2 = Object.keys(x1);
			let temp=[]

			for(let y of x2) {
			temp.push(x1[y])
			}

			worksheet.addRow(temp)
		}

		let fname="alender reporte colocación "

		//add data and file name and download
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, fname+'- '+`${new Date().toISOString()}.xlsx`);
		});
	}

	print()	{
		var mywindow = window.open();

		mywindow.document.write('<html><head><title>' + document.title  + '</title>');    
		mywindow.document.write('</head><body>');
		mywindow.document.write(`<style>
									table {
										font-size: 8px; 
									}

									.align-end { 
									text-align: end; 
									}

									.align-center { 
									text-align: center; 
									}

									.subtitle-table { 
									background-color: #d8d3d3; font-weight: bold; 
									}

									.title {
									font-weight: bold !important;
									}
									
									.subtitle {
									font-size: 6px !important;
									color: #bfbdbd !important;
									}

									.footer-text-bold {
									font-weight: bold;
									font-size: 8px;
									}

									td {
										border-bottom: 1px solid;
									}
									
									.footer-text-normal {
									font-size: 8px;
									}
									</style>`);
		mywindow.document.write(document.getElementById("content").innerHTML);
		mywindow.document.write('</body></html>');

		mywindow.stop();
		mywindow.print();

		var divExport = document.getElementById("content").innerHTML;
		// let divExport = this.html;
  
		var html = htmlToPdfmake(divExport);
		const documentDefinition = { content: [html], styles: {
  
		}};
  
		pdfMake.createPdf(documentDefinition).open();
	}
}