import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { UserAdministration, UserAdministrationViewModel } from 'src/app/models/user/user.model';
import { ChartData, ChartType } from 'chart.js';
import { AccountStatementService } from 'src/app/services/account-statement/account-statement.service';
import { SummaryAccountStatement } from 'src/app/models/account-statement/summaryaccountstatement.dto.model';
import { AdminTypeService } from 'src/app/services/admininTypeList/admintype.service';
import { DialogShowDetailsAdministratorComponent } from 'src/app/components/dialog-show-details-administrator/dialog-show-details-administrator.component';
import { DialogShowDetailsAdministratorSubscriptionsComponent } from 'src/app/components/dialog-show-details-administrator-subscriptions/dialog-show-details-administrator-subscriptions.component';
import { UserLogged } from 'src/app/models/user/userlogged.model';
import { GlobalLists } from 'src/app/global/global-data';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeyValue } from '@angular/common';

@Component({
	selector: 'app-administrator',
	templateUrl: './administrator.component.html',
	styleUrls: ['./administrator.component.scss']
})

export class AdministratorComponent implements OnInit {
	currentUser: UserLogged = new UserLogged;

	@Input() topNav: boolean = true;
	userTotal: UserAdministrationViewModel[];
	countList: UserAdministrationViewModel[];
	showpChartData = "SubscriptionChartData";
	summary: SummaryAccountStatement;
	userActived: number = 0;
	suscriptionPlans: any;
	clientActived: number = 0;
	userInactived: number = 0;
	clientInactived: number = 0;
	userGraphics: number = 0;
	clientGraphics: number = 0;
	subscriptionsCount: number = 0;
	subscriptionsAmount: number = 0;
	userAdministration: UserAdministration;
	SubscriptionSumGraphics: number = 0;
	SubscriptioncountGraphics: number = 0;
	subscriptionChartDataTotal: number;
	subscriptionsalesChartDataTotal: number;

	userFilterForm: FormGroup;

	userFilterYearList: KeyValue<number, number>[] = [];

	months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

	constructor(
		private customerService: CustomerService,
		public authService: AuthenticationService,
		public AdminTypeService: AdminTypeService,
		private dialog: MatDialog,
		private router: Router,
		private spinnerService: SpinnerService,
		private formBuilder: FormBuilder,
		private accountStatementService: AccountStatementService) {

		this.authService.currentUser.subscribe(x => this.currentUser = x);

		if (this.currentUser.userType != 1) {
			this.router.navigate(['/404']);
		}
	}

	activiteList = GlobalLists.admininTypeList;
	isUser = true;
	isLender = false;

	c_gray: string = "#DEDEDE";
	c_primary_soft: string = "#AC9BC6";
	c_primary_light: string = "#DBD3EA";
	c_primary_opacity: string = "#8573A8";
	c_primary: string = "#3F2A56";

	public userchartType: ChartType = 'doughnut'
	public useroptions = {
		plugins: {
			legend: {
				display: false
			}
		}
	};

	public SubscriptiondataChartData: ChartType = 'pie'
	public Subscriptiondataptions = {
		plugins: {
			legend: {
				display: false
			}
		}
	};

	public userTypeChartData: ChartType = 'bar'
	public userDataoptions = {
		plugins: {
			legend: {
				display: false,
			}
		},
	};

	//loanRequest graphics
	public loanRequestChartLabels: string[] = ['En proceso', 'Autorizadas'];
	public loanRequestChartData: ChartData<'doughnut'> = {
		labels: this.loanRequestChartLabels,
		datasets: [
			{
				label: "Monto",
				data: [0, 0],
				backgroundColor: [this.c_primary_soft, this.c_primary],
				hoverOffset: 4
			}
		]
	};

	//loan graphics
	public loanChartLabels: string[] = [!this.isLender ? 'Otorgados' : 'Recibidos', 'Liquidados'];
	public loanChartData: ChartData<'doughnut'> = {
		labels: this.loanChartLabels,
		datasets: [
			{
				label: "Monto",
				data: [0, 0],
				backgroundColor: [this.c_primary_soft, this.c_primary],
				hoverOffset: 4
			}
		]
	};

	//payments graphics
	public paymentsChartLabels: string[] = [!this.isLender ? 'Recibidos' : 'Realizados', 'Liquidados'];
	public paymentsChartData: ChartData<'doughnut'> = {
		labels: this.paymentsChartLabels,
		datasets: [
			{
				label: "Monto",
				data: [0, 0],
				backgroundColor: [this.c_primary_soft, this.c_primary],
				hoverOffset: 4
			}
		]
	};

	//balance graphics
	public balanceChartLabels: string[] = ['Sin atraso', 'Con atraso'];
	public balanceChartData: ChartData<'doughnut'> = {
		labels: this.balanceChartLabels,
		datasets: [
			{
				label: "Monto",
				data: [0, 0],
				backgroundColor: [this.c_primary_soft, this.c_primary],
				hoverOffset: 4
			}
		]
	};

	//Number suscription  graphicsc
	public SubscriptionChartLabels: string[] = [];
	public SubscriptionChartData: ChartData<'pie'> = {
		labels: this.SubscriptionChartLabels,
		datasets: [
			{
				label: "Cantidad",
				data: [],
				backgroundColor: [this.c_primary_light, this.c_primary_soft, this.c_primary_opacity, this.c_primary],
				hoverOffset: 4
			}
		]
	};

	//amount suscripcion graphics
	public SubscriptionsalesChartData: ChartData<'pie'> = {
		labels: this.SubscriptionChartLabels,
		datasets: [
			{
				label: "Monto",
				data: [],
				backgroundColor: [this.c_primary_light, this.c_primary_soft, this.c_primary_opacity, this.c_primary],
				hoverOffset: 4
			}
		]
	};

	//Users
	public userDataChartDataYear: ChartData<'bar'>;

	ngOnInit(): void {
		this.startUserFilterForm();
		this.getUsers();
	}

	get f_userFilterForm() { return this.userFilterForm.controls; }

	startUserFilterForm() {
		this.userFilterForm = this.formBuilder.group({
			year: ['', [Validators.required]]
		});
	}

	getUsers() {
		this.customerService.getUserAdministration().subscribe((result) => {
			this.userAdministration = result;

			this.fillIndicators("year");

			var subscriptionData = [];
			var subscriptionSalesData = []

			this.suscriptionPlans = this.userAdministration.plansList.filter(x => x.packageName && x.idService == 1);
			var suscriptionPlanList = result.plansList.filter(x => x.idService == 1);

			suscriptionPlanList.forEach(plan => {
				this.SubscriptionChartLabels.push(plan.packageName);

				subscriptionData.push(
					{
						packageName: plan.packageName,
						count: result.subcriptionAdministrationViewModel.filter(x => x.description == plan.packageName && plan.idService == 1).length

					}
				);

				subscriptionSalesData.push(
					{
						packageName: plan.packageName,
						amount: result.subcriptionAdministrationViewModel.filter(x => x.description == plan.packageName && plan.idService == 1).map(data => data.amountWithoutTAX).reduce((prev, curr) => prev + curr, 0)
					}
				);
			});

			this.SubscriptionChartData.datasets[0]['data'] = subscriptionData.map(data => data.count);
			this.subscriptionsCount = subscriptionData.map(data => data.count).reduce((prev, curr) => prev + curr, 0);
			this.SubscriptionsalesChartData.datasets[0]['data'] = subscriptionSalesData.map(data => data.amount);
			this.subscriptionsAmount = subscriptionSalesData.map(data => data.amount).reduce((prev, curr) => prev + curr, 0);

			this.onSubmitUserFilterForm();
		});

		this.authService.userModeLender.subscribe((data) => {
			this.isLender = data;
			if (this.authService.currentUserValue) {
				this.accountStatementService.getSummaryAdministrator(this.isLender).subscribe((summaryData) => {
					this.summary = summaryData;

					this.loanRequestChartData.datasets[0]['data'] = [
						this.summary.sq_LoanAmount_process,
						this.summary.sq_LoanAmount_authorized
					];

					this.loanChartData.datasets[0]['data'] = [
						this.summary.sq_SumLoanAmount,
						this.summary.sq_SumLoanAmountLiquidated
					];

					this.paymentsChartData.datasets[0]['data'] = [
						this.summary.sq_SumPaymentAmount,
						this.summary.sq_SumPaymentAmountLiquidated
					];

					this.balanceChartData.datasets[0]['data'] = [
						this.summary.sq_BalanceWithoutArrears,
						this.summary.sq_BalanceWithArrears
					];
				});
			}
		});
	}

	showDetailsModal(entry: string) {
		var userAdministration: UserAdministration = <UserAdministration>{
			userAdministrationList: this.userAdministration['userAdministrationList'],
			entry: entry
		};

		this.dialog.open(DialogShowDetailsAdministratorComponent, {
			height: '85%',
			width: '80%',
			data: userAdministration
		}).afterClosed().subscribe((confirmado: Boolean) => { });
	}

	showDetailsModalsubscriptions() {
		var userAdministration: UserAdministration = <UserAdministration>{
			subcriptionAdministrationViewModel: this.userAdministration['subcriptionAdministrationViewModel'],
			entry: "details"
		};

		this.dialog.open(DialogShowDetailsAdministratorSubscriptionsComponent, {
			height: '85%',
			width: '80%',
			data: userAdministration
		}).afterClosed().subscribe((confirmado: Boolean) => { });
	}

	onSubmitUserFilterForm() {
		this.userDataChartDataYear = {
			labels: [],
			datasets: [{
				label: "Registros",
				data: [],
				backgroundColor: [this.c_primary_opacity],
			}, {
				label: "Clientes",
				data: [],
				backgroundColor: [this.c_primary],
			}]
		};

		var startYear = 2022;

		if (this.f_userFilterForm['year'].value.toString().length == 0) {
			for (var i = startYear; i <= new Date().getFullYear(); i++) {
				this.userDataChartDataYear.labels.push(i);
				this.userDataChartDataYear.datasets[0]['data'].push(this.userAdministration['userAdministrationList'].filter(x => new Date(x.dateCreated).getFullYear() == i && x.amount == 0).length);
				this.userDataChartDataYear.datasets[1]['data'].push(this.userAdministration['userAdministrationList'].filter(x => new Date(x.subscripcionStartDate).getFullYear() == i && x.amount > 0).length);
			}

			this.fillIndicators("year");
		} else {
			for (var i = 0; i <= 12; i++) {
				this.userDataChartDataYear.labels.push(this.months[i]);
				this.userDataChartDataYear.datasets[0]['data'].push(this.userAdministration['userAdministrationList'].filter(x => new Date(x.dateCreated).getFullYear() == this.f_userFilterForm['year'].value && new Date(x.dateCreated).getMonth() == i && x.amount == 0).length);
				this.userDataChartDataYear.datasets[1]['data'].push(this.userAdministration['userAdministrationList'].filter(x => new Date(x.subscripcionStartDate).getFullYear() == this.f_userFilterForm['year'].value && new Date(x.subscripcionStartDate).getMonth() == i && x.amount > 0).length);
			}

			this.fillIndicators("month");
		}

		if (this.userFilterYearList.length == 0) {
			for (var i = startYear; i <= new Date().getFullYear(); i++) {
				this.userFilterYearList.push({ key: i, value: i })
			}
		}
	}

	fillIndicators(type: string) {
		if (type == "year") {
			this.userActived = this.userAdministration['userAdministrationList'].filter(x => x.subscripcionActivated === 'Activo' && x.amount <= 0).length;
			this.clientActived = this.userAdministration['userAdministrationList'].filter(x => x.subscripcionActivated === 'Activo' && x.amount > 0).length;

			this.userInactived = this.userAdministration['userAdministrationList'].filter(x => x.subscripcionActivated === 'Inactivo' && x.amount <= 0).length;
			this.clientInactived = this.userAdministration['userAdministrationList'].filter(x => x.subscripcionActivated === 'Inactivo' && x.amount > 0).length;

			this.userGraphics = this.userActived + this.userInactived;
			this.clientGraphics = this.clientActived + this.clientInactived;
		} else if (type == "month") {
			this.userGraphics = this.userAdministration['userAdministrationList'].filter(x => new Date(x.dateCreated).getFullYear() == this.f_userFilterForm['year'].value && x.subscripcionActivated === 'Activo' && x.amount <= 0).length +
				this.userAdministration['userAdministrationList'].filter(x => new Date(x.dateCreated).getFullYear() == this.f_userFilterForm['year'].value && x.subscripcionActivated === 'Inactivo' && x.amount <= 0).length;
			this.clientGraphics = this.userAdministration['userAdministrationList'].filter(x => new Date(x.subscripcionStartDate).getFullYear() == this.f_userFilterForm['year'].value && x.subscripcionActivated === 'Activo' && x.amount > 0).length +
				this.userAdministration['userAdministrationList'].filter(x => new Date(x.subscripcionStartDate).getFullYear() == this.f_userFilterForm['year'].value && x.subscripcionActivated === 'Inactivo' && x.amount > 0).length;
		}
	}
}
