<mat-horizontal-stepper [selectedIndex]="steptIndex" linear #stepperSignup labelPosition="bottom">
    <mat-step [optional]="false" [editable]="false">
        <ng-template matStepLabel>Validar cuenta</ng-template>
        <div *ngIf="data">
            <app-validate-email></app-validate-email>
        </div>
    </mat-step>

    <mat-step [stepControl]="personalDataForm" [optional]="false" [editable]="false">
        <ng-template matStepLabel>Datos de usuario</ng-template>

        <form [formGroup]="personalDataForm" (ngSubmit)="onSubmitPersonalDataForm()">
            <div class="subtittle">
                Datos del usuario
            </div>

            <div class="w-100">
                <div class="form-horizontal">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="firstName" class="">Primer nombre*</label>
                                <input type="text" formControlName="firstName" class="form-control"
                                    autocomplete="firstName" autofocus="autofocus"
                                    [ngClass]="personalDataFormSubmitted && validateRequiredFormPerson('firstName') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="middleName">Segundo nombre</label>
                                <input type="text" class="form-control" formControlName="middleName"
                                    autocomplete="middleName"
                                    [ngClass]="personalDataFormSubmitted && validateRequiredFormPerson('middleName') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="lastName1" class="">Primer apellido*</label>
                                <input type="text" formControlName="lastName1" class="form-control" name="lastName1"
                                    autocomplete="lastName"
                                    [ngClass]="personalDataFormSubmitted && validateRequiredFormPerson('lastName1') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="lastName2" class="">Segundo apellido</label>
                                <input type="text" formControlName="lastName2" class="form-control" name="lastName2"
                                    autocomplete="lastName"
                                    [ngClass]="personalDataFormSubmitted && validateRequiredFormPerson('lastName2') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="birthdayDate" class="">Fecha de nacimiento*</label>
                                <input autofocus="autofocus" class="form-control"
                                    formControlName="birthdayDate" type="date" [max]="getMaxDate()"
                                    [ngClass]="personalDataFormSubmitted && validateRequiredFormPerson('birthdayDate') ? 'is-invalid' : ''" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap gap-2 justify-content-end">
                <button type="button" mat-stroked-button mat-dialog-close>
                    <mat-icon>close</mat-icon> Cerrar
                </button>
                <button class="me-3" type="submit" mat-raised-button color="primary"> Guardar</button>
            </div>
        </form>
    </mat-step>

</mat-horizontal-stepper>
