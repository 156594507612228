import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { DocumentServiceService } from 'src/app/services/document-service/document-service.service';

export interface DialogData {
}

@Component({
	selector: 'app-dialog-show-details-call-rating.',
	templateUrl: './dialog-show-details-call-rating.component.html',
	styleUrls: ['./dialog-show-details-call-rating.component.scss']
})

export class DialogShowCallRatingComponent implements OnInit, AfterViewInit {
	@ViewChild('divExport') divExport!: ElementRef;
	@ViewChild('inputContainer', { static: false }) inputContainer: ElementRef;
	externalStatus: number;
	totalNumberCalls:number;



	constructor(
		public dialog: MatDialogRef<DialogShowCallRatingComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private datePipe: DatePipe,
		private alertGlobalService: AlertGlobalService) { }

	closeDialog(): void {
		this.dialog.close(false);
	}
	confirm(): void {
		this.dialog.close(true);
	}

	ngOnInit() {
		this.externalStatus = this.data.externalStatus;
		this.totalNumberCalls = this.data.responseCall;
	}

	sendResponse(response: number) {
		this.dialog.close({ externalStatus: response });
	}

	ngAfterViewInit(): void { }
}
