<mat-accordion>
  <mat-expansion-panel [expanded]="panelLoanProceedingsOpenState" (opened)="panelLoanProceedingsOpen(true)"
    (closed)="panelLoanProceedingsOpen(false)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5>Resumen del Estado de Cuenta</h5>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container-fluid">
      <div class="row">
        <div id="accountStatementContent" *ngIf="accountStatement">
          <div class="pb-3" fxLayout="row">
            <table class="w-100">
              <tr>
                <td class="align-center title">Estado de Cuenta de Préstamo</td>
              </tr>
              <tr>
                <td class="align-center subtitle">De acuerdo a las condiciones pactadas por los Participantes</td>
              </tr>
            </table>
          </div>

          <div fxLayout="row">
            <div class="container_datos_personales">
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Fecha
                </div>
                <div class="datos_items">
                  {{ accountStatement.headerData.todayDate | date }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  ID Préstamo
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.loanId }}
                </div>
              </div>

              <div class="datos_de_encabezado">
                Participantes
              </div>
              <div class="container_items col-12">
                <div class="datos_items">
                  Nombre Solicitante
                </div>
                <div class="datos_items">
                  {{ accountStatement.borrowerData.fullName }}
                </div>
              </div>
              <div class="container_items col-12">
                <div class="datos_items">
                  Correo electrónico
                </div>
                <div class="datos_items">
                  {{ accountStatement.borrowerData.email }}
                </div>
              </div>
              <div class="container_items col-12">
                <div class="datos_items">
                  Teléfono celular
                </div>
                <div class="datos_items">
                  {{ accountStatement.borrowerData.phoneNumber }}
                </div>
              </div>
              <div class="container_items col-12">
                <div class="datos_items">
                  Dirección
                </div>
                <div class="datos_items">
                  {{ accountStatement.borrowerData.address }}
                </div>
              </div>
              <div class="container_items col-12">
                <div class="datos_items">
                  Nombre Prestamista
                </div>
                <div class="datos_items">
                  {{ accountStatement.lenderData.fullName }}
                </div>
              </div>
              <div class="container_items col-12">
                <div class="datos_items">
                  Correo electrónico
                </div>
                <div class="datos_items">
                  {{ accountStatement.lenderData.email }}
                </div>
              </div>
              <div class="container_items col-12">
                <div class="datos_items">
                  Teléfono celular
                </div>
                <div class="datos_items">
                  {{ accountStatement.lenderData.phoneNumber }}
                </div>
              </div>

              <div class="datos_de_encabezado">
                Condiciones
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Monto del préstamo
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.loanAmount | currency }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Tipo de préstamo
                </div>
                <div class="datos_items">
                  {{ typeOfCredit }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Fecha de inicio
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.startDate | date }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Cálculo de intereses
                </div>
                <div class="datos_items">
                  {{ typeOfAmortization }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Frecuencia de pagos
                </div>
                <div class="datos_items">
                  {{ typeOfPeriodDesc }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Periodo del primer pago
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.periodFirstPayment }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Número de pagos
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.totalPeriods }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Tipo de garantía
                </div>
                <div class="datos_items">
                  {{ warrantys }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Plazo del prestamo
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.loanTerm }} {{typeOfPeriodDesc}}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Interés moratorio mensual
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.monthlyPorcentOnArrears * 0.0100 | percent:'1.2-5' }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Interés mensual
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.monthtlyRate * 0.0100 | percent:'1.2-5' }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  IVA
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.taxPorcent != 0 ? 'Sí' : 'No' }}
                </div>
              </div>

              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Comisiones
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.applyCommissions ? 'Sí' : 'No' }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Pagos personalizados
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.customPayments ? 'Sí' : 'No' }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Monto total a pagar
                </div>
                <div class="datos_items">
                  {{ accountStatement.resumeData.totalPayment | currency }}
                </div>
              </div>
              <div class="container_items col-md-6 col-12">
                <div class="datos_items">
                  Retención ISR
                </div>
                <div class="datos_items">
                  {{ accountStatement.conditionsData.percentRetentionISR * 0.0100 | percent:'1.2-5' }}
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row">
            <div class="container_datos_personales">
              <table class="w-100">
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td class="subtitle-table" colspan="4">Resumen</td>
                </tr>
                <tr>
                  <td>Monto del préstamo</td>
                  <td>Monto Interés total</td>
                  <td>Monto Comisiones total</td>
                  <td>Monto Total a pagar</td>
                </tr>
                <tr>
                  <td>{{accountStatement.resumeData.loanAmount | currency}}</td>
                  <td>{{accountStatement.resumeData.totalInterest | currency}}</td>
                  <td>{{accountStatement.resumeData.totalCommissions | currency}}</td>
                  <td>{{accountStatement.resumeData.totalPayment | currency}}</td>
                </tr>
                <tr *ngIf="currentTypeOfCredit != 1">
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr *ngIf="currentTypeOfCredit != 1">
                  <td>Saldo Capital a pagar</td>
                  <td>Saldo Interés a pagar</td>
                  <td>Saldo Comisiones a pagar</td>
                  <td>Saldo Total a pagar</td>
                </tr>
                <tr *ngIf="currentTypeOfCredit != 1">
                  <td>{{accountStatement.resumeData.balance | currency}}</td>
                  <td>{{accountStatement.resumeData.interestBalance | currency}}</td>
                  <td>{{accountStatement.resumeData.commissionsBalance | currency}}</td>
                  <td>{{accountStatement.resumeData.totalBalancePayment | currency}}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Saldo Capital a liquidar</td>
                  <td>Saldo Interés a liquidar</td>
                  <td>Saldo Comisiones a liquidar</td>
                  <td>Saldo Total a liquidar</td>
                </tr>
                <tr>
                  <td>{{accountStatement.resumeData.totalCapitalToLiquited | currency}}</td>
                  <td>{{accountStatement.resumeData.totalInterestOnArrearsToLiquited | currency}}</td>
                  <td>{{accountStatement.resumeData.totalCommissionToLiquited | currency}}</td>
                  <td>{{accountStatement.resumeData.totalBalanceToLiquited | currency}}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Fecha próximo pago</td>
                  <td>Monto próximo pago</td>
                  <td>Días con atraso</td>
                  <td>Saldo con atraso Total</td>
                </tr>
                <tr>
                  <td>{{accountStatement.resumeData.firstDatePayment | date: 'd MMM y'}}</td>
                  <td>{{accountStatement.resumeData.amountNextPayment | currency}}</td>
                  <td>{{accountStatement.resumeData.daysLate}}</td>
                  <td>{{accountStatement.resumeData.totalBalanceLate | currency}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion class="mb-4">
  <mat-expansion-panel (opened)="panelLoanProceedingsTableOpen(false)"
    (closed)="panelLoanProceedingsTableOpen(false)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5>Detalle del Estado de Cuenta</h5>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container-fluid">
      <div class="row">
          <div>
            <p class="subtitle-table" colspan="4">Tabla de pagos y movimientos</p>
          </div>

          <div class="responsive-account-table" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <table id="movementsTable" fxFlex mat-table *ngIf="movements" [dataSource]="movements">
              <ng-container matColumnDef="period">
                <th class="align-center" mat-header-cell *matHeaderCellDef> Período </th>
                <td class="align-center" mat-cell *matCellDef="let item"> {{item.period}} </td>
                <td class="align-center" mat-footer-cell *matFooterCellDef>
                  <div class="d-md-block d-none">-</div>

                  <div class="d-md-none d-block">
                <td class="d-flex justify-content-between font-weight-bold">
                  <div>Periodo</div>
                  <div>-</div>
                </td>
          </div>
          </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th class="align-center" mat-header-cell *matHeaderCellDef> Fecha Movim. </th>
            <td class="align-center" mat-cell *matCellDef="let item"> {{item.date | date}} </td>
            <td class="align-center" mat-footer-cell *matFooterCellDef>
              <div class="d-md-block d-none">-</div>

              <div class="d-md-none d-block">
            <td class="d-flex justify-content-between font-weight-bold">
              <div>Fecha Movim.</div>
              <div>-</div>
            </td>
        </div>
        </td>
        </ng-container>

        <ng-container matColumnDef="movement">
          <th class="align-center" mat-header-cell *matHeaderCellDef> Tipo de Movimiento </th>
          <td class="align-center" mat-cell *matCellDef="let item"> {{item.movement}} </td>
          <td class="align-center" mat-footer-cell *matFooterCellDef>
            <div class="d-md-block d-none">Total</div>

            <div class="d-md-none d-block">
          <td class="d-flex justify-content-between font-weight-bold">
            <div>Tipo de Movimiento</div>
            <div>Total</div>
          </td>
      </div>
      </td>
      </ng-container>

      <ng-container matColumnDef="debit">
        <th class="align-center" mat-header-cell *matHeaderCellDef> Cargo </th>
        <td class="align-end" mat-cell *matCellDef="let item"> {{item.debit | currency}} </td>
        <td class="align-end" mat-footer-cell *matFooterCellDef>
          <div class="d-md-block d-none">{{getSumDebit() | currency}}</div>

          <div class="d-md-none d-block">
        <td class="d-flex justify-content-between font-weight-bold">
          <div>Cargo</div>
          <div>{{getSumDebit() | currency}}</div>
        </td>
    </div>
    </td>
    </ng-container>

    <ng-container matColumnDef="credit">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Abono </th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.credit | currency}} </td>
      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">{{getSumCredit() | currency}}</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Abono</div>
        <div>{{getSumCredit() | currency}}</div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="capital">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Pago Capital </th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.capital | currency}} </td>
      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">{{getSumCapital() | currency}}</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Pago Capital</div>
        <div>{{getSumCapital() | currency}}</div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="ordinaryInterest">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Pago Interés </th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.ordinaryInterest | currency}} </td>
      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">{{getSumOrdinaryInterest() | currency}}</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Pago Interés</div>
        <div>{{getSumOrdinaryInterest() | currency}}</div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="interestOnArrears">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Pago Morat. </th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.interestOnArrears | currency}} </td>
      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">{{getSumInterestOnArrears() | currency}}</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Pago Morat.</div>
        <div>{{getSumInterestOnArrears() | currency}}</div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="interestOnArrearsTax">
      <th class="align-center" mat-header-cell *matHeaderCellDef> IVA morat.</th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.interestOnArrearsTax | currency}} </td>
      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">{{getSumInterestOnArrearsTax() | currency}}</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Pago Morat.</div>
        <div>{{getSumInterestOnArrearsTax() | currency}}</div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tax">
      <th class="align-center" mat-header-cell *matHeaderCellDef> IVA interés</th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.tax | currency}} </td>
      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">{{getSumTax() | currency}}</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Pago IVA</div>
        <div>{{getSumTax() | currency}}</div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="commission">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Pago comis. </th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.commission | currency}} </td>
      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">{{getSumCommission() | currency}}</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Pago Comis.</div>
        <div>{{getSumCommission() | currency}}</div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="commissionTax">
      <th class="align-center" mat-header-cell *matHeaderCellDef> IVA comis. </th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.commissionTax | currency}} </td>
      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">{{getSumCommissionTax() | currency}}</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Comis.</div>
        <div>{{getSumCommissionTax() | currency}}</div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Saldo final total </th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.balance | currency}} </td>

      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">-</div>
        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Saldo Final Total</div>
        <div></div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="balanceExpired">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Saldo con atraso total calculado</th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.balanceExpired | currency}} </td>

      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">-</div>
        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Saldo con atraso Total</div>
        <div></div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="balanceExpiredCalculate">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Saldo con atraso total</th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.balanceExpiredCalculate | currency}} </td>

      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">-</div>
        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Saldo con atraso Total 2</div>
        <div></div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="balanceToLiquidate">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Saldo total a liquidar</th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.balanceToLiquidate | currency}} </td>

      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">-</div>
        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Saldo total a liquidar</div>
        <div></div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="capitalExpired">
      <th class="align-center" mat-header-cell *matHeaderCellDef> Capital vencido calculado</th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.capitalExpired | currency}} </td>

      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">-</div>
        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Capital vencido</div>
        <div></div>
      </td>
      </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="capitalExpiredCalculate">
      <th class="align-center" mat-header-cell *matHeaderCellDef>Capital vencido</th>
      <td class="align-end" mat-cell *matCellDef="let item"> {{item.capitalExpiredCalculate | currency}} </td>

      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">-</div>
        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Capital vencido 2</div>
        <div></div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container *ngIf="retention" matColumnDef="retention">
      <th mat-header-cell *matHeaderCellDef> Retención ISR </th>
      <td mat-cell *matCellDef="let item"> {{item.retention | currency}} </td>
      <td class="align-center" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">{{getSumRetentionISR() | currency}}</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Retención ISR</div>
        <div>{{getSumRetentionISR() | currency}}</div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container *ngIf="retention" matColumnDef="netPeriodPay">
      <th mat-header-cell *matHeaderCellDef> Pago neto </th>
      <td mat-cell *matCellDef="let table"> {{table.netPeriodPay | currency}} </td>
      <td class="align-center" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">-</div>

        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div>Pago neto</div>
        <div></div>
      </td>
      </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="buttonDeletePayment">
      <th mat-header-cell *matHeaderCellDef>Eliminar pago</th>
      <td mat-cell *matCellDef="let row">
        <i class="bx bx-x" *ngIf="row.movement == 'Pago Depósito'"
          (click)="onDeletePayment(row.idPayment, row.credit)"></i>
      </td>
      <td class="align-end" mat-footer-cell *matFooterCellDef>
        <div class="d-md-block d-none">-</div>
        <div class="d-md-none d-block">
      <td class="d-flex justify-content-between font-weight-bold">
        <div></div>
        <div></div>
      </td>
      </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="accountStatementDetailColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: accountStatementDetailColumns; let i = index"
      (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
    <tr mat-footer-row *matFooterRowDef="accountStatementDetailColumns"></tr>
    </table>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <div fxFlex="100%">
        <p class="footer-text-bold">Avisos y recomendaciones</p>
        <p class="footer-text-normal">Este Estado de Cuenta ha sido generado por medio de www.a-lender.com.
          El registro de los pagos y movimientos son realizados directamente por el Prestamista; Contáctalo si tienes
          dudas
          o aclaraciones sobre el presente Estado de Cuenta.
          Tu Ángel Prestamista confió en ti para apoyarte en tus necesidades…, cumple con tu palabra y tus compromisos:
          Paga
          completo, a tiempo y siempre se agradecido; nunca se sabe si en un futuro necesitaras de su Apoyo nuevamente.
        </p>
        <p class="footer-text-bold">a-lender®</p>
        <p class="footer-text-normal">Es la plataforma digital para administrar préstamos
          Si prestas dinero, no pierdas a tu amigo y tu dinero: ¡Utiliza a-lender® para hacerlo fácil y seguro!
          Si pides dinero, lo más sencillo es apoyarte en tus conocidos: ¡Utiliza a-lender® para hacerlo fácil y rápido!
          Regístrate en: www.a-lender.com o escríbenos a: hola@a-lender.com</p>
      </div>
    </div>
    </div>
    </div>
    <div class="d-flex flex-wrap gap-2 justify-content-end">
      <button class="btn btn-outline-primary external-btn" (click)="exportPDF()">
        PDF
      </button>
      <button class="btn btn-outline-primary external-btn" (click)="print()">
        Imprimir
      </button>
      <button class="btn btn-outline-primary external-btn" (click)="sendContactMail()">
        Enviar
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
