import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalLists } from 'src/app/global/global-data';

@Injectable({
  providedIn: 'root'
})
export class AdminTypeService {

  admininTypeList: KeyValue<number, string>[] = [
    { key: 0, value: "1er Trimestre 2022" },
		

  ];

  private admininTypeList$ = new BehaviorSubject<KeyValue<number, string>[]>(this.admininTypeList);

  constructor() { }

  getList() {
    return this.admininTypeList$.asObservable();
  }
}
