import { KeyValue } from "@angular/common";
import { AdvanceOptions, PrelationConfig } from "../models/amortize/amortize.model";
import { evaluationElement } from "../models/loanrequest/loanrequest.dto.model";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export class GlobalData {
    public static TAX: number = 16.0;
    public static DAYS_PER_YEAR: number = 360;
    public static MONTHS_PER_YEAR: number = 12;
    public static DAYS_PER_MONTHS: number = 30;
}

export class TypeOfPeriod {
    public static DAILY: number = 1;
    public static WEEKLY: number = 7;
    public static FOURTEEN: number = 14;
    public static BIWEEKLY: number = 15;
    public static MONTHLY: number = 30;
    public static BIMONTHLY: number = 60;
    public static QUARTERLY: number = 90;
    public static BIANNUAL: number = 180;
    public static ANNUAL: number = 360;
}

export class TypeDateAdd {
    public static DAYS: string = "days";
    public static MONTHS: string = "months";
    public static YEARS: string = "years";
}

export class TypesWarranties {
    public static SIN_GARANTIA: number = 1;
    public static AVAL: number = 2;
    public static OBLIGADO_SOLIDARIO: number = 3;
    public static HIPOTECARIA: number = 4;
    public static PRENDARIA: number = 5;
    public static GARANTIA_LIQUIDA: number = 6;
    public static OTRA: number = 7;
}

export class GlobalLists {
    public static typeOfCommissions: string[] = [
        'Apertura o contratación',
        'Administración o manejo de cuenta',
        'Gastos de investigación',
        'Pago anticipado o prepago',
        'Pago tardio o inoportuno',
        'Falta de pago',
        'Gastos de cobranza',
        'Anualidad',
		"Pago con tarjeta",
        'Otra'
    ];

    public static typeOfWarranties: string[] = [
        'Aval',
        'Obligado solidario',
        'Hipotecaria',
        'Prendaria',
        'Liquida',
        'Otra'
    ];

    public static taxList: KeyValue<number, string>[] = [
		{ key: 0, value: "16" },
	];

    public static whenToPayArray: KeyValue<number, string>[] = [
        { key: 0, value: "Al inicio" },
        { key: 1, value: "En periodos" },
        { key: 2, value: "Al final" },
    ];

    public static prelationConfigs = <PrelationConfig[]>[
        {
          name: "taxPorcent",
          prelationOrder: 1
        },
        {
            name: "commissions",
            prelationOrder: 2
        },
        {
            name: "interestOnArrears",
            prelationOrder: 3
        },
        {
            name: "ordinaryInterest",
            prelationOrder: 4
        },
        {
            name: "capital",
            prelationOrder: 5
        }
      ];

    public static helpControlsList = [
        { control: 'loanAmount', title: 'Monto préstamo', description: 'Es el monto o cantidad del préstamo.', isHtml: false },
        { control: 'firstPayment', title: 'Fecha primer pago', description: 'Es el día que se realizará el primer pago del préstamo, puede ser pago de interés y/o abono o liquidación del capital prestado.', isHtml: false },
        { control: 'paytable', title: 'Tabla de Pagos', description: 'Muestra la tabla de amortización de pagos por período y conceptos de acuerdo a las condiciones definidas anteriormente.', isHtml: false },
        { control: 'startDate', title: 'Cuando inicia', description: 'Es el día en que se entrega y recibe el préstamo y se inicia el plazo que se determine para su pago.', isHtml: false },
        { control: 'typeOfPeriod', title: 'Frecuencia de pagos', description: 'Es la frecuencia de los pagos, es decir el período de tiempo en que se realizarán pagos del préstamo, puede ser pago de intereses y/o abonos del monto prestado.', isHtml: false },
        { control: 'totalPeriods', title: 'Número de pagos', description: 'Es el plazo del préstamo en número de períodos o frecuencia de pagos, es decir, es la cantidad de pagos totales que se realizarán.', isHtml: false },
        { control: 'monthlyRate', title: 'Interés mensual', description: 'Es la tasa de interés mensual que se tendrá que pagar por el préstamo. En caso de tasas anuales o de otras frecuencias, se debe convertir previamente para escribir la tasa mensual equivalente.', isHtml: false },
        { control: 'applyTax', title: 'IVA', description: 'Activar si se desea que se incluya el impuesto al valor agregado, es el 16%, sobre los intereses cobrados y sobre las comisiones que se estipulen.', isHtml: false },
        { control: 'typeOfAmortization', title: 'Tipo de calculo de intereses', description: `Simple: Pagos van disminuyendo durante el plazo. Los abonos a capital son fijos y el pago de intereses disminuyendo. (Método Alemán)
        Global: Pagos iguales durante el plazo. Los abonos a capital y el pago de intereses son fijos. (Tasa Flat)
        Bancario: Pagos iguales durante el plazo. Los abonos a capital van aumentando y el pago de intereses disminuyendo. (Método Francés)`, isHtml: false },
        { control: 'periodAmortizeStart', title: 'Periodo del primer pago', description: 'Es el número del período cuando se realizará el primer pago, en caso que se tenga un período de gracia, es el período inmediato posterior a dicho plazo.', isHtml: false },
        { control: 'typeOfCredit', title: 'Tipo de préstamo', description: `Liquidable al vencimiento:
        Se paga sólo intereses durante el plazo seleccionado y al final el monto del préstamo.
        Amortizable:
        Se va pagando el préstamo y los intereses durante el plazo seleccionado.`, isHtml: true },
        { control: 'warranty', title: 'Tipo de garantía', description: `Sin garantía: No se requiere ninguna garantía adicional o colateral.
        Aval: Se requiere que otra persona responda en caso de no pago.
        Obligado solidario: Se requiere que otra persona responda de manera conjunta en caso de no pago.
        Hipotecaria: Se requiere un bien inmueble: casa, terreno, local, etc. como garantía adicional.
        Prendaria: Se requiere un bien mueble: auto, joyas, equipo, mercancía, etc. como garantía adicional.
        Otra: Se requiere otro tipo de garantía diferente a las anteriores.`, isHtml: false },
        { control: 'commissions', title: 'Comisiones', description: 'Activar si se desea estipular comisiones o cobros adicionales en el préstamo. Se pueden configurar una o varias, ya sea como montos fijos o porcentaje del préstamo, así como cobrarse al inicio, durante o al final del plazo del préstamo, según se elija.', isHtml: false },
        { control: 'monthlyPorcentOnArrears', title: 'Interés moratorio', description: 'Es la tasa de interés moratorio mensual que se tendrá que pagar por el préstamo en caso de atrasos en cualquier pago del monto del préstamo.', isHtml: false },
        { control: 'payments', title: 'Personalizar pagos', description: 'Activar si se desea estipular pagos extraordinarios en algún o algunos períodos, por ejemplo, anualidades u otros. Se puede capturar pagos diferentes a los ordinarios de manera que la tabla de pagos final se ajuste a ellos.Estos pagos generalmente provienen de ingresos extraordinarios del Solicitante como por ejemplo aguinaldo, fondo de ahorro, vente de algún bien, etc.', isHtml: false },

        { control: 'formRequestValidation', title: 'Formulario cliente', description: 'Favor de capturar informacion faltante', isHtml: false },
        { control: 'warrantiesValidation', title: 'Garantía(s)', description: 'Favor de capturar informacion faltante', isHtml: false },
        { control: 'documentsValidation', title: 'Documento(s)', description: 'Favor de validar documentación', isHtml: false },
        { control: 'documentsLegalValidation', title: 'Documentación legal', description: 'Favor de validar documentación legal', isHtml: false },
        { control: 'legalConditions', title: 'Forma de préstamo', description: `A la palabra: Se registra el préstamo y tabla de pagos en el sistema, pero no se generan documentos legales para sustentarlo.
        Legal-Pagaré: Ademas se dan de alta algunos datos para generar un pagaré que sustente el préstamo.
        Legal-Contrato: Ademas se dan de alta mas datos para generar un contrato y un pagaré que sustente el préstamo.`, isHtml: false },
        { control: 'visita', title: 'Tipo de visita', description: `Suave: Visita de cobranza tipo recordatorio de pago, generalmente para Deudores con atrasos leves y/o con disposición de pago.
        Media: Visita de cobranza tipo requermiento de pago, generalmente para Deudores con atrasos moderados y/o con problemas de pago.
        Firme: Visita de cobranza tipo requerimiento de pago, generalmente para Deudores con atrasos mayores y/o sin disposición de pago.`, isHtml: false },
        { control: 'llamada', title: 'Tipo de llamada', description: `Suave: Llamadas de cobranza tipo recordatorio de pago, generalmente para Deudores con atrasos leves y/o con disposición de pago.Media: Llamadas de cobranza tipo requermiento de pago, generalmente para Deudores con atrasos moderados y/o con problemas de pago.
        Firme: Llamadas de cobranza tipo requerimiento de pago, generalmente para Deudores con atrasos mayores y/o sin disposición de pago.`, isHtml: false },
		
		
		{ control: 'selectedLoan', title: 'Monto Total a Pagar', description: 'Es el monto o cantidad total a pagar del préstamo incluyendo accesorios(intereses, comisiones, impuestos, etc.).', isHtml: false },
		{ control: 'selectedSale', title: 'Monto Total a Pagar', description: 'Es el monto o cantidad total a pagar de la venta se realiza a crédito incluyendo accesorios(intereses, comisiones, impuestos, etc.).', isHtml: false },
		{ control: 'typeLoanDebtorLoan', title: 'Monto de préstamo', description: 'Es el monto o cantidad del préstamo (capital).', isHtml: false },
		{ control: 'typeLoanDebtorSale', title: 'Monto de venta', description: 'Es el monto o cantidad del neto de la venta(precio + IVA - descuentos + comisiones).', isHtml: false },
		{ control: 'totalBalanceToLoan', title: 'Saldo Total Pendiente por Pagar', description: 'Es el monto o cantidad con el cual se liquidaría el préstamo a la fecha (monto total a pagar - monto abanado o pagado a la fecha).', isHtml: false },
		{ control: 'totalBalanceToSale', title: 'Saldo Total Pendiente por Pagar', description: 'Es el monto o cantidad con el cual se liquidaría el cuenta a la fecha (monto total a pagar - monto abanado o pagado a la fecha).', isHtml: false },
		{ control: 'totalBalanceLoan', title: 'Saldo Exigible', description: 'Es el monto o cantidad que se deberiá haber pagado a la fecha y no se ha cubierto (saldo vencido). Este importe es el que el gestor requerirá al deudor.', isHtml: false },
		{ control: 'totalBalanceSale', title: 'Saldo Exigible', description: 'Es el monto o cantidad que se deberiá haber pagado a la fecha y no se ha cubierto (saldo vencido). Este importe es el que el gestor requerirá al deudor.', isHtml: false },
		{ control: 'numberOfPayments', title: 'Numero de pagos realizados', description: 'Es el numero de pagos realizados por el deudor.', isHtml: false },

      ];

    public static suscriptions = [
        { key: 1, value: "1 - Libre" },
        { key: 2, value: "2 - Básico" },
        { key: 3, value: "3 - Estándar" },
        { key: 4, value: "4 - Premium" },
    ];

    public static wayToPayList = [
        { key: 1, value: "Efectivo" },
        { key: 2, value: "Cheque" },
        { key: 3, value: "Depósito en cuenta" },
        { key: 4, value: "Transferencia electrónica" },
        { key: 6, value: "En especie" },
        { key: 7, value: "Descuento de nómina" },
        { key: 8, value: "Tarjeta de crédito/ débito" },
        { key: 9, value: "Vales de despensa" },
        { key: 10, value: "Condonación" },
        { key: 11, value: "Otra" },
    ];

    public static typeMediumContact = [
        { key: "sms", value: "Celular" },
        { key: "email", value: "Correo electrónico" }
    ];

    public static evaluationData: evaluationElement[] = [
        {id: 1, question: '¿Qué tanto conoces al solicitante?', concept: 'Conocimiento', five: false, four: false, three: false, two: false, one: false, value: ''},
        {id: 2,question: '¿Qué tanta confianza le tienes?', concept: 'Confianza', five: false, four: false, three: false, two: false, one: false, value: ''},
        {id: 3,question: '¿Qué tanto compromiso tienes por prestarle al solicitante?', concept: 'Compromiso', five: false, four: false, three: false, two: false, one: false, value: ''},
        {id: 4,question: '¿Qué tan cumplido(a) consideras al solicitante?', concept: 'Caracter', five: false, four: false, three: false, two: false, one: false, value: ''},
        {id: 5,question: '¿Tiene ingresos suficientes para pagar en relación a sus deudas?', concept: 'Capacidad', five: false, four: false, three: false, two: false, one: false, value: ''},
        {id: 6,question: '¿Tiene bienes que cubren el préstamo solicitado?', concept: 'Capital', five: false, four: false, three: false, two: false, one: false, value: ''},
        {id: 7,question: '¿Ofrece garantías adicionales en caso de no poder pagar?', concept: 'Colateral', five: false, four: false, three: false, two: false, one: false, value: ''},
        {id: 8,question: '¿El monto, tasa, y plazo de pago son adecuados según las condiciones del solicitante?', concept: 'Condiciones', five: false, four: false, three: false, two: false, one: false, value: ''},
    ];

    public static typeOfAdvanceOptions: AdvanceOptions[] = [
        { number: 1, description: "Datos persona", completed: false, personType: 3 },
        { number: 2, description: "Datos representante legal", completed: false, personType: 1 },
        { number: 3, description: "Datos domicilio", completed: false, personType: 3 },
        { number: 4, description: "Datos contacto", completed: false, personType: 3 },
        { number: 5, description: "Datos actividad económica", completed: false, personType: 3 },
        { number: 6, description: "Datos patrimoniales", completed: false, personType: 0 },
        { number: 7, description: "Datos económicos", completed: false, personType: 0 },
        { number: 8, description: "Datos familiares", completed: false, personType: 0 },
        { number: 9, description: "Datos referencias", completed: false, personType: 3 },
        { number: 10, description: "Datos socioeconómicos", completed: false, personType: 0 },
        { number: 11, description: "Datos bancarios", completed: false, personType: 3 },
        { number: 12, description: "Datos balance general", completed: false, personType: 1 },
        { number: 13, description: "Datos estado de resultados", completed: false, personType: 1 },
        { number: 14, description: "Datos persona políticamente expuesta (PPE)", completed: false, personType: 0 }
    ];

    public static contactRelationshipList: KeyValue<number, string>[] = [
		{ key: 0, value: "Familiar" },
		{ key: 1, value: "Amigo" },
		{ key: 2, value: "Pareja" },
		{ key: 3, value: "Conocido" },
		{ key: 4, value: "Cliente" },
		{ key: 5, value: "Compañero" },
		{ key: 6, value: "Empleado" },
		{ key: 7, value: "Recomendado" },
		{ key: 8, value: "Accionista" },
		{ key: 9, value: "Inversionista" },
		{ key: 10, value: "Empleador" },
		{ key: 11, value: "Proveedor" },
		{ key: 12, value: "Cliente" },
		{ key: 13, value: "Ninguna" },
		{ key: 14, value: "Otro" }
	];

    public static comercialRelationshipList: KeyValue<number, string>[] = [
		{ key: 0, value: "Bancos" },
		{ key: 1, value: "Proveedores" },
		{ key: 2, value: "Clientes" },
		{ key: 3, value: "Fondeadores" },
		{ key: 4, value: "Otro" }
	];

	public static yesnoList: KeyValue<number, string>[] = [
		{ key: 0, value: "Si" },
		{ key: 1, value: "No" }
	];

    public static poofOfPaymentTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Factura" },
		{ key: 1, value: "Pedimiento" },
		{ key: 2, value: "Nota" },
		{ key: 3, value: "Carta" },
		{ key: 4, value: "Contrato compra-venta" },
		{ key: 5, value: "Otro" }
	];

    public static conditionsList: KeyValue<number, string>[] = [
		{ key: 0, value: "Excelente" },
		{ key: 1, value: "Buena" },
		{ key: 2, value: "Regular" },
		{ key: 3, value: "Mala" },
		{ key: 4, value: "Pésima" }
	];

    public static debtsList: KeyValue<number, string>[] = [
		{ key: 0, value: "Sin adeudo" },
		{ key: 1, value: "Refrendo" },
		{ key: 2, value: "Tenencia" },
		{ key: 3, value: "Multa" },
		{ key: 4, value: "Seguro" },
		{ key: 5, value: "Otro" }
	];

    public static typeRelationshipList: KeyValue<number, string>[] = [
		{ key: 0, value: "Padre/ Madre" },
		{ key: 1, value: "Hijo(a)" },
		{ key: 2, value: "Hermano(a)" },
		{ key: 3, value: "Esposo(a)" },
		{ key: 4, value: "Cónyuge" },
		{ key: 5, value: "Abuelo(a)" },
		{ key: 6, value: "Nieto(a)" },
		{ key: 7, value: "Tio(a)" },
		{ key: 8, value: "Sobrino(a)" },
		{ key: 9, value: "Primo(a)" },
		{ key: 10, value: "Suegro(a)" },
		{ key: 11, value: "Yerno/ Nuera" },
		{ key: 12, value: "Cuñado(a)" },
		{ key: 13, value: "Otro" },
	];

    public static housingTypeList: KeyValue<number, string>[] = [
        { key: 0, value: "Casa" },
        { key: 1, value: "Departamento" },
        { key: 2, value: "Finca Campestre" },
        { key: 3, value: "Granja o Rancho" },
        { key: 4, value: "Oficina" },
        { key: 5, value: "Planta" },
        { key: 6, value: "Bodega" },
        { key: 7, value: "Otra" }
    ];

    public static homeOwnershipList: KeyValue<number, string>[] = [
        { key: 0, value: "Propia" },
        { key: 1, value: "Rentada" },
        { key: 2, value: "Huésped" },
        { key: 3, value: "Familiar" },
        { key: 4, value: "Pagando" },
        { key: 5, value: "Otro" }
    ];

    public static phoneList: KeyValue<number, string>[] = [
        { key: 0, value: "Celular" },
        { key: 1, value: "Casa" },
        { key: 2, value: "Trabajo" },
        { key: 3, value: "Negocio" },
        { key: 4, value: "Cónyuge/ Pareja" },
        { key: 5, value: "Referencia" },
        { key: 6, value: "Aval/ Obligado" },
        { key: 7, value: "Familiar" },
        { key: 8, value: "Recados" },
        { key: 9, value: "Otro" }
    ];

    public static emailList: KeyValue<number, string>[] = [
        { key: 0, value: "Personal" },
        { key: 1, value: "Escuela" },
        { key: 2, value: "Trabajo" },
        { key: 3, value: "Negocio" },
        { key: 4, value: "Cónyuge/ Pareja" },
        { key: 5, value: "Referencia" },
        { key: 6, value: "Aval/ Obligado" },
        { key: 7, value: "Familiar" },
        { key: 8, value: "Recados" },
        { key: 9, value: "Otro" }
    ];

    public static socialNetworksList: KeyValue<number, string>[] = [
        { key: 0, value: "Facebook" },
        { key: 1, value: "Instagram" },
        { key: 2, value: "YouTube" },
        { key: 3, value: "Twitter" },
        { key: 4, value: "TikTok" },
        { key: 5, value: "LinkedIn" },
        { key: 6, value: "Snapchat" },
        { key: 7, value: "Pinterest" },
        { key: 8, value: "Twitch" },
        { key: 9, value: "Otro" }
    ];

    public static economicActivitySectorList: KeyValue<number, string>[] = [
		{ key: 0, value: "Industrial" },
		{ key: 1, value: "Comercial" },
		{ key: 2, value: "Servicios" },
		{ key: 3, value: "Agropecuario" },
		{ key: 4, value: "Otro" }
	];

    public static economicActivityBusTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Formal" },
		{ key: 1, value: "Informal" }
	];

    public static economicActivityBusFormList: KeyValue<number, string>[] = [
		{ key: 0, value: "Negocio establecido" },
		{ key: 1, value: "Ambulante" },
		{ key: 2, value: "Casa por casa" },
		{ key: 3, value: "En línea" },
		{ key: 4, value: "Otro" }
	];

    public static activiteList: KeyValue<number, string>[] = [
		{ key: 0, value: "Casas" },
		{ key: 1, value: "Terrenos" },
		{ key: 2, value: "Autos y Vehículos" },
		{ key: 3, value: "Maquinaria y Equipo" },
		{ key: 4, value: "Otro" }
	];

    public static passiveList: KeyValue<number, string>[] = [
		{ key: 0, value: "Bancarios" },
		{ key: 1, value: "Financieras" },
		{ key: 2, value: "Tiendas y Mueblerías" },
		{ key: 3, value: "Familiares y Amigos" },
		{ key: 4, value: "Otro" }
	];

    public static incomeList: KeyValue<number, string>[] = [
        { key: 0, value: "Empleo" },
        { key: 1, value: "Negocio propio" },
        { key: 2, value: "Familiar" },
        { key: 3, value: "Pensión" },
        { key: 4, value: "Remesas" },
        { key: 5, value: "Otro" }
    ];

    public static expenseList: KeyValue<number, string>[] = [
        { key: 0, value: "Personales" },
        { key: 1, value: "Negocio" },
        { key: 2, value: "Familiar" },
        { key: 3, value: "Casa" },
        { key: 4, value: "Deudas" },
        { key: 5, value: "Otro" }
    ];

    public static maritalStatusList: KeyValue<number, string>[] = [
		{ key: 0, value: "Soltero" },
		{ key: 1, value: "Casado" },
		{ key: 2, value: "Viudo" },
		{ key: 3, value: "Divorciado" },
		{ key: 4, value: "Unión libre" },
		{ key: 5, value: "Separado" }
	];

    public static maritalRegimeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Bienes mancomunados" },
		{ key: 1, value: "Separación bienes" }
	];

    public static legalConditionsList: KeyValue<number, string>[] = [
		{ key: 1, value: "A la palabra" },
		{ key: 2, value: "Legal - Pagaré" },
		{ key: 3, value: "Legal - Pagaré y Contrato" }
	];

    public static occupationList = [
		{ key: 0, value: "Empleado", checked: false },
		{ key: 1, value: "Negocio propio", checked: false },
		{ key: 2, value: "Jornalero o Eventual", checked: false },
		{ key: 3, value: "Hogar", checked: false },
		{ key: 4, value: "Jubilado", checked: false },
		{ key: 5, value: "Pensionado", checked: false },
		{ key: 6, value: "Estudiante", checked: false },
		{ key: 7, value: "Ama de casa", checked: false },
		{ key: 8, value: "Desempleado", checked: false },
		{ key: 9, value: "Desconocida", checked: false },
		{ key: 10, value: "Industrial", checked: false },
		{ kkey: 12, value: "Servicios", checked: false },
		{ key: 13, value: "Agropecuario", checked: false },
		{ey: 11, value: "Comercial", checked: false },
		{  key: 14, value: "Otra", checked: false }
	];

    public static documentIneList = [
		{ key: 0, value: "Credencial para votar (INE/ IFE)" },
		{ key: 1, value: "Pasaporte" },
		{ key: 2, value: "Cartilla de servicio militar" },
		{ key: 3, value: "Cédula profesional" },
		{ key: 4, value: "Matrícula consular" },
		{ key: 5, value: "Licencia de Conducir" },
		{ key: 6, value: "Otro" },
	];

    public static documentAddressList = [
		{ key: 0, value: "Recibo Luz" },
		{ key: 1, value: "Recibo Agua" },
		{ key: 2, value: "Recibo Teléfono" },
		{ key: 3, value: "Recibo Internet" },
		{ key: 4, value: "Recibo Gas" },
		{ key: 5, value: "Pago Predial" },
		{ key: 6, value: "Otro" },
	];

    public static documentIncomeList = [
		{ key: 0, value: "Recibo de Nómina" },
		{ key: 1, value: "Recibo de honorarios" },
		{ key: 2, value: "Recibo de arrendamiento" },
		{ key: 3, value: "Estado de Cuenta Bancario" },
		{ key: 4, value: "Declaración anual" },
		{ key: 5, value: "Carta de empresa" },
		{ key: 6, value: "Otro" },
	];

    public static documentWarrantyList = [
		{ key: 0, value: "Factura" },
		{ key: 1, value: "Pedimento" },
		{ key: 2, value: "Nota" },
		{ key: 3, value: "Carta" },
		{ key: 4, value: "Contrato compra-venta" },
		{ key: 5, value: "Escritura" },
		{ key: 6, value: "Título de propiedad" },
		{ key: 7, value: "Padrón catastral" },
		{ key: 8, value: "Otro" },
	];

	public static documentAvalList = [
		{ key: 0, value: "Credencial para votar (INE/ IFE)" },
		{ key: 1, value: "Pasaporte" },
		{ key: 2, value: "Recibo Luz" },
		{ key: 3, value: "Recibo Agua" },
		{ key: 4, value: "Recibo de Nómina" },
		{ key: 5, value: "Recibo de honorarios" },
		{ key: 6, value: "Otro" },
	];

    public static documentLoanRequestList = [
		{ key: 0, value: "Formato a-lender" },
		{ key: 1, value: "Formato propio" },
		{ key: 2, value: "Otro formato" }
	];

    public static documentOtherList = [];

    public static creditDestinations: KeyValue<number, string>[] = [
		{ key: 0, value: "Gastos personales" },
		{ key: 1, value: "Gastos de salud" },
		{ key: 2, value: "Gastos de educación" },
		{ key: 3, value: "Gastos de vehículo" },
		{ key: 4, value: "Gastos de casa" },
		{ key: 5, value: "Gastos de eventos" },
		{ key: 6, value: "Gastos de negocio" },
		{ key: 7, value: "Pagar deudas" },
		{ key: 8, value: "Emergencias" },
		{ key: 9, value: "Capital de trabajo" },
		{ key: 10, value: "Inventario" },
		{ key: 11, value: "Activo fijo" },
		{ key: 12, value: "Inversiones" },
		{ key: 13, value: "Pago de pasivos" },
		{ key: 14, value: "Otro" }
	];

    public static constructionTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Block" },
		{ key: 1, value: "Ladrillo" },
		{ key: 2, value: "Adobe" },
		{ key: 3, value: "Madera" },
		{ key: 4, value: "Lámina" },
		{ key: 5, value: "Otro" }
	];

    public static floorTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Mosaico" },
		{ key: 1, value: "Cemento" },
		{ key: 2, value: "Madera" },
		{ key: 3, value: "Alfombra" },
		{ key: 4, value: "Tierra" },
		{ key: 5, value: "Otro" }
	];

    public static ceilingTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Placa" },
		{ key: 1, value: "Teja" },
		{ key: 2, value: "Lámina" },
		{ key: 3, value: "Madera" },
		{ key: 4, value: "Paja" },
		{ key: 5, value: "Otro" }
	];

    public static cookingFuelTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Gas natural" },
		{ key: 1, value: "Gas butano" },
		{ key: 2, value: "Electricidad" },
		{ key: 3, value: "Carbón" },
		{ key: 4, value: "Leña" },
		{ key: 5, value: "Otro" }
	];

    public static educationLevelList: KeyValue<number, string>[] = [
		{ key: 1, value: "Primaria" },
		{ key: 2, value: "Secundaria" },
		{ key: 3, value: "Bachillerato o Preparatoria" },
		{ key: 4, value: "Carrera Técnica o Comercial" },
		{ key: 5, value: "Licenciatura" },
		{ key: 6, value: "Especialidad" },
		{ key: 7, value: "Maestría" },
		{ key: 8, value: "Doctorado" },
		{ key: 9, value: "Ninguno" },
	];

    public static bankList: KeyValue<number, string>[] = [
		{ key: 0, value: "ABC Capital" },
		{ key: 1, value: "American Express Bank" },
        { key: 2, value: "BANAMEX" },
		{ key: 3, value: "Banca Afirme" },
		{ key: 4, value: "Banca Mifel" },
		{ key: 5, value: "Banco Actinver" },
		{ key: 6, value: "Banco Autofin México" },
		{ key: 7, value: "Banco Azteca" },
		{ key: 8, value: "Banco Bancrea" },
		{ key: 9, value: "Banco Base" },
		{ key: 10, value: "Banco Compartamos" },
		{ key: 11, value: "Banco Covalto" },
		{ key: 12, value: "Banco Credit Suisse" },
		{ key: 13, value: "Banco de Inversión Afirme" },
		{ key: 14, value: "Banco del Bajío" },
		{ key: 15, value: "Banco Forjadores" },
		{ key: 16, value: "Banco Inbursa" },
		{ key: 17, value: "Banco Inmobiliario Mexicano" },
		{ key: 18, value: "Banco Invex" },
		{ key: 19, value: "Banco J.P. Morgan" },
		{ key: 20, value: "Banco KEB Hana México" },
		{ key: 21, value: "Banco Monex" },
		{ key: 22, value: "Banco Multiva" },
		{ key: 23, value: "Banco PagaTodo" },
		{ key: 24, value: "Banco S3 Caceis México" },
		{ key: 25, value: "Banco Sabadell" },
		{ key: 26, value: "Banco Santander" },
		{ key: 27, value: "Banco Shinhan de México" },
		{ key: 28, value: "Banco Ve por Más" },
		{ key: 29, value: "Bancoppel" },
		{ key: 30, value: "Bank of America México" },
		{ key: 31, value: "Bank of China México" },
		{ key: 32, value: "Bankaool" },
		{ key: 33, value: "Banorte" },
		{ key: 34, value: "Banregio" },
		{ key: 35, value: "Bansi" },
		{ key: 36, value: "Barclays Bank México" },
		{ key: 37, value: "BBVA México" },
		{ key: 38, value: "BNP Paribas México" },
		{ key: 39, value: "CIBANCO" },
		{ key: 40, value: "Consubanco" },
		{ key: 41, value: "Deutsche Bank México" },
		{ key: 42, value: "Fundación Dondé Banco" },
		{ key: 43, value: "HSBC" },
		{ key: 44, value: "Industrial and Commercial Bank of China México" },
		{ key: 45, value: "Intercam Banco" },
		{ key: 46, value: "Mizuho Bank México" },
		{ key: 47, value: "MUFG Bank México" },
		{ key: 48, value: "Scotiabank Inverlat" },
		{ key: 49, value: "Volkswagen Bank" },
		{ key: 50, value: "Otro" }
	];

    public static bienTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Caja y Bancos" },
		{ key: 1, value: "Inversiones" },
		{ key: 2, value: "Cuentas por Cobrar" },
		{ key: 3, value: "Inventario" },
		{ key: 4, value: "Terrenos y Edificios" },
		{ key: 5, value: "Maquinaria y Equipo" },
		{ key: 6, value: "Intangibles" },
		{ key: 7, value: "Diferido" },
		{ key: 8, value: "Otros Activos" }
	];

	public static admininTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Enero" },
		{ key: 1, value: "Febrero" },
		{ key: 2, value: "Marzo" },
		{ key: 3, value: "Abril" },
		{ key: 4, value: "Mayo" },
		{ key: 5, value: "Junio" },
		{ key: 6, value: "Julio" },
		{ key: 7, value: "Agosto" },
		{ key: 8, value: "Septiembre" },
		{ key: 9, value: "Octubre" },
		{ key: 10, value: "Noviembre" },
		{ key: 11, value: "Diciembre" }
	];

    public static passiveTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Proveedores" },
		{ key: 1, value: "Préstamos por Pagar C.P." },
		{ key: 2, value: "Préstamos por Pagar L.P." },
		{ key: 3, value: "Otras Cuentas por Pagar" }
	];

    public static capitalTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Capital social" },
		{ key: 1, value: "Utilidades Acumuladas" },
		{ key: 2, value: "Utilidades del Ejercicio" }
	];

    public static statusResultincomeList: KeyValue<number, string>[] = [
        { key: 0, value: "Ventas" },
        { key: 1, value: "Comisiones" },
        { key: 2, value: "Financieros" },
        { key: 3, value: "Venta Activos" },
        { key: 4, value: "Recuperaciones" },
        { key: 5, value: "Otro" }
    ];

    public static statusResultExpenseList: KeyValue<number, string>[] = [
        { key: 0, value: "Costo de ventas" },
        { key: 1, value: "Gastos de operación" },
        { key: 2, value: "Depreciación y amortización" },
        { key: 3, value: "Gastos financieros" },
        { key: 4, value: "Impuestos" },
        { key: 5, value: "Otros" }
    ];

    public static personTypeList: KeyValue<number, string>[] = [
        { key: 0, value: "Persona física" },
        { key: 1, value: "Persona moral" },
        { key: 2, value: "Persona física con actividad empresarial" },
    ];

    public static typeOfProofOfOwnershipList: KeyValue<number, string>[] = [
		{ key: 0, value: "Escritura" },
		{ key: 1, value: "Título de propiedad" },
		{ key: 2, value: "Padrón catastral" },
		{ key: 3, value: "Certificado" },
		{ key: 3, value: "Contrato compra-venta" },
		{ key: 4, value: "Otro" }
	];

    public static ownerList: KeyValue<number, string>[] = [
		{ key: 0, value: "Solicitante" },
		{ key: 1, value: "Otros" }
	];

    public static debtsMortgageList: KeyValue<number, string>[] = [
		{ key: 0, value: "Sin adeudo" },
		{ key: 1, value: "Predial" },
		{ key: 2, value: "Servicios" },
		{ key: 3, value: "Multas" },
		{ key: 4, value: "Seguro" },
		{ key: 5, value: "Otro" }
	];

    public static documentsNew: KeyValue<number, string>[] = [
		{ key: 0, value: "Identificación oficial" },
		{ key: 1, value: "Comprobante domicilio" },
		{ key: 2, value: "Comprobante ingresos" },
		{ key: 3, value: "Aval/ Obligado solidario" },
		{ key: 4, value: "Garantía prendaria/ hipotecaria" },
		{ key: 5, value: "Solicitud de crédito" },
		{ key: 6, value: "Otro" }
	];

    public static subscriptionType: KeyValue<number, string>[] = [
		{ key: 0, value: "Inicial" },
		{ key: 1, value: "Ampliar plazo" },
		{ key: 2, value: "Mejorar plan" }
	];

    public static visitTypeList: KeyValue<number, string>[] = [
		{ key: 0, value: "Suave" },
		{ key: 1, value: "Media" },
		{ key: 2, value: "Firme" }
	];

	public static currencyMask = createNumberMask({
		prefix: '$ ',
		suffix: '',
		allowDecimal: true,
		decimalLimit: 2,
		defaultValue: '00'
	});

	public static currencyDecimalMask = createNumberMask({
		prefix: '$ ',
		suffix: '',
		allowDecimal: true,
		decimalLimit: 2,
		defaultValue: '00'
	});

	public static percentMask = createNumberMask({
		prefix: '',
		suffix: ' %',
		allowDecimal: true,
		integerLimit: 2,
		maxValue: 100,
		minValue: 0
	});

	public static numberMask = createNumberMask({
		prefix: '',
		suffix: '',
		allowDecimal: false,
		integerLimit: 3,
		decimalLimit: 0
	});

	public static phoneMask = Array.from({ length: 10 }, () => /[0-9_]/);
	public static phoneCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	public static cardMask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
	public static accountMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/];
	public static clabeMask = [/\d/,/\d/,/\d/, ' ',/\d/,/\d/, /\d/, ' ',/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,' ',/\d/];



	public static getPhone(phone: string): string {
		var number = phone.split("-")
		var r = "";

		number.forEach(e => {
			r += e.replace("_", "");
		});

		if (r.indexOf("_") <= -1) {
			return r;
		} else {
			return r.substring(0, r.indexOf("_"));
		}
	}

	public static capitalizeFirstLetter(value: string): string {
		if (!value || value.length === 0) {
		  return '';
		}

		const words = value.split(' ');
		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
		return capitalizedWords.join(' ');
	}
}

export class Formats {
    public static DATE: string = "dd/MM/yyyy";
    public static MONEY: string = "1.2-2";
}
