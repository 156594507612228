import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';
import { map } from 'rxjs/operators';
import { RequiredDocumentDTO } from 'src/app/models/required-documents/requiredDocuments.dto.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentServiceService {

  private baseUrl = environment.apiURL;

  private getToken(): string {
    if (JSON.parse(localStorage.getItem('currentUser')) !== null) {
      return JSON.parse(localStorage.getItem('currentUser')).token;
    } else {
      return '';
    }
  }

  constructor(private http: HttpClient,
    private apiService: ApiService) { }

  upload(loanRequestId: string, documentType: string, loanRequestDateCreated: Date, file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('loanRequestId', loanRequestId);
    formData.append('documentType', documentType);
    // formData.append('loanRequestDateCreated', loanRequestDateCreated.toString());
    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/document/upload`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.getToken()
      })
    });

    return this.http.request(req);
  }

  uploadProfilePicture(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/document/uploadProfilePicture`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.getToken()
      })
    });

    return this.http.request(req);
  }

  addComment(comment: any) {
    return this.apiService.post('document', 'comment', comment)
      .pipe(map(response => {
        return response.data as String;
      }));
  }

  getComments(loanRequestId: string, documentType: string) {
    return this.apiService.get('document', 'comment/list', loanRequestId + '/' + documentType)
      .pipe(map(response => {
        return response.data as any;
      }));
  }

  getPDF() {
    return this.apiService.get('documentgenerator', 'getPDFTutorial')
      .pipe(map(response => {
        return response.data as ArrayBuffer;
      }));
  }

  getPerloanrequest(loanRequestId: string) {
    return this.apiService.get('document', 'perloanrequest', loanRequestId)
      .pipe(map(response => {
        return response.data as RequiredDocumentDTO[];
      }));
  }

  getDocumentsVisitExternalServices(loanRequestId: string, visitId: string) {
    return this.apiService.get('document', 'getDocumentsVisitExternalServices', loanRequestId + "/" + visitId)
      .pipe(map(response => {
        return response.data as RequiredDocumentDTO[];
      }));
  }

  getDocumentsVisitExternalServicesUser(visitId: string) {
    return this.apiService.get('document', 'getDocumentsVisitExternalServicesUser',visitId)
      .pipe(map(response => {
        return response.data as RequiredDocumentDTO[];
      }));
  }

  UploadOtherFiles(loanRequestId: string, documentType: string, loanRequestDateCreated: Date, file: File, folder: string, fileOriginalName: string, count: any): Promise<any> {
    const formData: FormData = new FormData();

    formData.append('loanRequestId', loanRequestId);
    formData.append('documentType', documentType);
    // formData.append('loanRequestDateCreated', loanRequestDateCreated.toString());
    formData.append('file', file);
    formData.append('fileOriginalName', fileOriginalName);
    formData.append('folder', folder);
    formData.append('count', count);

    return new Promise((resolve, reject) => {
      const req = new HttpRequest('POST', `${this.baseUrl}/document/UploadOtherFiles`, formData, {
        reportProgress: true,
        responseType: 'json',
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.getToken()
        })
      });

      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
          } else if (event.type === HttpEventType.Response) {
            if (event.body && event.body.data) {
              const currentDir = {
                name: documentType + "-" + count,
                url: event.body.data
              };
              resolve(currentDir);
            } else {
              reject("Error al cargar el archivo");
            }
          }
        },
        (error) => {
          reject("Error al cargar el archivo: " + error);
        }
      );
    });
  }

  getOriginalName(Id: string) {
    return this.apiService.get('document', 'getOriginalName',Id)
      .pipe(map(response => {
        return response.data as String;
      }));
  }

  deleteDocumentArchive(fileSavedName: string , documentId: string): Promise<any> {
    const formData: FormData = new FormData();

    formData.append('fileSavedName', fileSavedName);
    formData.append('documentId', documentId);

    return new Promise((resolve, reject) => {
      const req = new HttpRequest('DELETE', `${this.baseUrl}/document/deleteDocumentArchive`, formData, {
        reportProgress: true,
        responseType: 'json',
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.getToken()
        })
      });

      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
          } else if (event.type === HttpEventType.Response) {
            if (event.status === 200) {
              if (event.body && event.body.data) {
                const deletinfo = true
                reject(deletinfo);
              } else {
              }
            } else {
              reject("Error en la respuesta HTTP: " + event.status + " - " + event.statusText);
            }
          }
        },
        (error) => {
          reject("Error al eliminar el archivo: " + error);
        }
      );
      
    });
  }
}
  
