<mat-accordion>
    <mat-expansion-panel [expanded]="panelBankDataState" class="bg-white mb-4"
        (opened)="panelBankDataOpen(true)" (closed)="panelBankDataOpen(false)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="d-flex">
                    <h5>Cuentas bancarias</h5>
                    <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <form *ngIf="!getIsDisabledSave()" [formGroup]="bankDataForm" (ngSubmit)="onSubmitBankData()">
            <div class="row">
                <div class="subtittle2">Agregar cuenta</div>
                
                <div class="col-md-3 mb-3">
                    <div class="form-group">
                        <label>Nombre banco*</label>
                        <select class="form-control" formControlName="bankName"
                            [ngClass]="submited && validateRequiredFormBank('bankName') ? 'is-invalid' : ''">
                            <option *ngFor="let item of bankList" value="{{item.value}}">{{item.value}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="form-group">
                        <label>Número cuenta*</label>
                        <input class="form-control" type="number"
                            [ngClass]="submited && validateRequiredFormBank('bankNumber') ? 'is-invalid' : ''"
                            formControlName="bankNumber" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="form-group">
                        <label>Número tarjeta*</label>
                        <input class="form-control" type="number"
                            [ngClass]="submited && validateRequiredFormBank('tarjetNumber') ? 'is-invalid' : ''"
                            formControlName="tarjetNumber" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="form-group">
                        <label>Clabe interbancaria</label>
                        <input class="form-control" type="text" [ngClass]="submited && validateRequiredFormBank('clabe') ? 'is-invalid' : ''" formControlName="clabe" autocomplete="off" autocapitalize="off">
                    </div>
                </div>

                <div [ngClass]="personType == 1 ? 'd-flex align-items-end' : 'col-md-3'" class="mb-3">
                    <div class="form-group w-100">
                        <label for="value-FirstName">{{name}}*</label>
                        <input type="text" formControlName="firstName" [ngClass]="submited && validateRequiredFormBank('firstName') ? 'is-invalid' : ''" class="form-control">
                    </div>
                    <button *ngIf="personType == 1" class="btn btn-outline-primary internal-btn ms-3">
                        Agregar
                    </button>
                </div>
                <div *ngIf="personType != 1" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-MiddleName">Segundo nombre</label>
                        <input type="text" formControlName="middleName" value="" class="form-control">
                    </div>
                </div>
                <div *ngIf="personType != 1" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-LastName1">
                            Primer apellido <span *ngIf="validateFormrequestJson()">*</span>
                        </label>
                        <input type="text" formControlName="lastName1" value="" class="form-control">
                    </div>
                </div>
                <div *ngIf="personType != 1" class="col-md-3 d-flex align-items-end mb-3">
                    <div class="form-group w-100">
                        <label for="value-LastName2">Segundo apellido</label>
                        <input type="text" formControlName="lastName2" value=""  class="form-control">
                    </div>
                    <button class="btn btn-outline-primary internal-btn">
                        Agregar
                    </button>
                </div>
            </div>
        </form>
            
        <div class="table-responsive">
            <div class="react-bootstrap-table">
                <table *ngIf="dsBankData" mat-table [dataSource]="dsBankData">
                    <ng-container matColumnDef="bankName">
                        <th mat-header-cell *matHeaderCellDef>Nombre banco</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.bankName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="bankNumber">
                        <th mat-header-cell *matHeaderCellDef>Número cuenta</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{row.bankNumber}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tarjetNumber">
                        <th mat-header-cell *matHeaderCellDef>Número tarjeta</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{row.tarjetNumber}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="clabe">
                        <th mat-header-cell *matHeaderCellDef>CLABE</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.clabe}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef>Primer nombre</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.firstName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="middleName">
                        <th mat-header-cell *matHeaderCellDef>Segundo nombre</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.middleName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lastName1">
                        <th mat-header-cell *matHeaderCellDef>Primer apellido</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.lastName1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="lastName2">
                        <th mat-header-cell *matHeaderCellDef>Segundo apellido</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.lastName2}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="buttonRemove">
                        <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                        <td mat-cell *matCellDef="let row; let i = index;">
                            <i class="bx bx-x" (click)="onDeleteBankData(i)"></i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="bankDataColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: bankDataColumns;"></tr>
                </table>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>