import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { SimulatorComponent } from './views/simulator/simulator.component';
import { LoanrequestComponent } from './views/loanrequest/loanrequest.component';
import { LoanComponent } from './views/loan/loan.component';
import { NotificationComponent } from './views/notification/notification.component';
import { UserComponent } from './views/user/user.component';
import { SignupComponent } from './views/signup/signup.component';
import { AccountStatementComponent } from './views/account-statement/account-statement.component';
import { LoanpaymentComponent } from './views/loanpayment/loanpayment.component';
import { IndexComponent } from './views/index/index.component';
import { ValidateEmailComponent } from './views/validate-email/validate-email.component';
import { HtmlViewerComponent } from './views/html-viewer/html-viewer.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { TermsComponent } from './views/terms/terms.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { LoanrequestResumeComponent } from './components/loanrequest-resume/loanrequest-resume.component';
import { LoanResumeComponent } from './components/loan-resume/loan-resume.component';
import { BlogComponent } from './views/blog/blog.component';
import { FaqComponent } from './views/faq/faq.component';
import { ReportComponent } from './views/report/report.component';
import { SubscriptionpaymentNewComponent } from './components/subscriptionpayment-new/subscriptionpayment-new.component';
import { BillNewComponent } from './components/bill-new/bill-new.component';
import { AdministratorComponent } from './views/administrator/administrator.component';
import { PolicyComponent } from './views/policy/policy.component';
import { LegalMessageComponent } from './views/legal-message/legal-message.component';
import { QuestionComponent } from './views/question/question.component';
import { PageNotFoundComponent } from './views/pagenotfound/pagenotfound.component';
import { AccountComponent } from './views/account/account.component';
import { CartComponent } from './views/cart/cart.component';
import { DetailServiceComponent } from './views/detail-service/detail-service.component';
import { BalanceReloadComponent } from './components/balance-reload/balance-reload.component';
import { CallcenterComponent } from './views/callcenter/callcenter.component';
import { CallcenterResumeComponent } from './components/callcenter-resume/callcenter-resume.component';
import { DialogShowDetailsCallCenterComponent } from './components/dialog-show-details-callcenter-results/dialog-show-details-callcenter-results.component';
import { CollectionComponent } from './views/collection/collection.component';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'inicio', component: HomeComponent },
  { path: 'iniciar-sesion', component: LoginComponent },
  { path: 'simulador', component: SimulatorComponent },
  { path: 'simulador/:quoteId', component: SimulatorComponent },
  { path: 'solicitud-de-prestamo/:loanRequestId', component: LoanrequestResumeComponent },
  { path: 'solicitud-de-prestamo', component: LoanrequestComponent },
  { path: 'prestamos/:loanRequestId', component: LoanResumeComponent },
  { path: 'prestamos', component: LoanComponent },
  { path: 'notificaciones', component: NotificationComponent },
  { path: 'mi-perfil', component: UserComponent },
  { path: 'crear-cuenta', component: SignupComponent },
  { path: 'crear-cuenta/:mode', component: SignupComponent },
  { path: 'accountstatement', component: AccountStatementComponent },
  { path: 'accountstatement/:loanId', component: AccountStatementComponent },
  { path: 'pagos', component: LoanpaymentComponent },
  { path: 'validateemail', component: ValidateEmailComponent },
  { path: 'htmlviewer', component: HtmlViewerComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'resetear-contrasena/:email/:token', component: ResetPasswordComponent },
  { path: 'terminos-y-condiciones', component: TermsComponent },
  { path: 'aviso-de-privacidad', component: PrivacyComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:blogId', component: BlogComponent },
  { path: 'preguntas-frecuentes', component: FaqComponent },
  { path: 'reportes', component: ReportComponent },
  { path: 'servicios', component: CollectionComponent },
  { path: 'suscripciones/contratar-plan/:action', component: SubscriptionpaymentNewComponent },
  { path: 'facturas/generar-factura', component: BillNewComponent },
  { path: 'administracion', component: AdministratorComponent },
  { path: 'centro-de-llamadas', component: CallcenterComponent },
  { path: 'centro-de-llamadas/:externalId', component: CallcenterResumeComponent },
  { path: 'historial-de-llamadas', component: DialogShowDetailsCallCenterComponent },
  { path: 'politica-de-cookies', component: PolicyComponent },
  { path: 'aviso-legal', component: LegalMessageComponent },
  { path: 'preguntas', component: QuestionComponent },
  { path: 'mi-cuenta', component: AccountComponent },
  { path: 'mi-cuenta/recargar-saldo', component: BalanceReloadComponent },
  { path: 'carrito', component: CartComponent },
  { path: 'prestamos/:loanRequestId/detalle-de-servicio/:externalId', component: DetailServiceComponent },
  { path: 'prestamos/detalle-de-servicio/:externalId', component: DetailServiceComponent },
  // { path: '404', component: PageNotFoundComponent },
  // { path: 'importacion', component: LoanImportComponent },
  // { path: 'importacion/:action', component: LoanImportNewComponent },
  // default
  { path: '**', redirectTo: '' },
  // { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    onSameUrlNavigation: "ignore",
    anchorScrolling:'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
