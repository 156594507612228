<div class="row">
	<h5>Detalles y condiciones de préstamo</h5>
	<p>De acuerdo a las condiciones definidas por el prestamista</p>
	<div class="d-flex justify-content-between">
		<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
			<div>
				<p class="text-muted mb-1">Fecha</p>
				<h5 class="mb-0">{{ startDate | date }}</h5>
			</div>
		</div>
		<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
			<div>
				<p class="text-muted mb-1">ID Préstamo</p>
				<h5 class="mb-0">{{ loanId }}</h5>
			</div>
		</div>
	</div>
	<hr>

	<h5 class="text-center mb-4">PARTICIPANTES</h5>
	<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Nombre Solicitante</p>
			<h5 class="mb-0">{{ borrowerName }}</h5>
		</div>
	</div>
	<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Correo electrónico</p>
			<h5 class="mb-0">{{ borrowerMail }}</h5>
		</div>
	</div>
	<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Teléfono celular</p>
			<h5 class="mb-0">{{ borrowerPhoneMobile }}</h5>
		</div>
	</div>
	<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Nombre Prestamista</p>
			<h5 class="mb-0">{{ lenderCustomerFullName }}</h5>
		</div>
	</div>
	<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Correo electrónico</p>
			<h5 class="mb-0">{{ lenderEmail }}</h5>
		</div>
	</div>
	<div class="col-lg-4 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Teléfono celular</p>
			<h5 class="mb-0">{{ lenderPhoneMobile }}</h5>
		</div>
	</div>
	<hr>

	<h5 class="text-center mb-4">CONDICIONES</h5>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Monto del préstamo</p>
			<h5 class="mb-0">{{totalAmortize | currency}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Tipo de préstamo</p>
			<h5 class="mb-0">{{typeOfCredit}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Fecha de Inicio</p>
			<h5 class="mb-0">{{startDate | date}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Cálculo de intereses</p>
			<h5 class="mb-0">{{typeOfAmortization}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Frecuencia de pagos</p>
			<h5 class="mb-0">{{typeOfPeriodDesc}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Período del primer pago</p>
			<h5 class="mb-0">{{periodAmortizeStart}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Número de pagos</p>
			<h5 class="mb-0">{{totalPeriods}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Tipo de Garantía</p>
			<h5 class="mb-0">{{warrantys}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Plazo del préstamo</p>
			<h5 class="mb-0">{{totalPeriods}} {{typeOfPeriodDesc}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Interés moratorio mensual</p>
			<h5 class="mb-0">{{monthlyPorcentOnArrears * 0.0100 |
				percent:'1.2-5'}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Interés mensual</p>
			<h5 class="mb-0">{{monthlyRate * 0.0100 | percent:'1.2-5'}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">IVA</p>
			<h5 class="mb-0">{{taxPorcent * 0.0100 | percent:'1.2-5'}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Pago periódico</p>
			<h5 class="mb-0">{{periodicPayment == 'Variable'
				?periodicPayment : periodicPayment | currency}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Comisiones</p>
			<h5 class="mb-0">{{isCommission}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Monto Total a pagar</p>
			<h5 class="mb-0">
				{{amortizeService.currentAmortizeTableValue.totalPeriodPay |
				currency}}</h5>
		</div>
	</div>
	<div class="col-lg-3 col-md-4 col-sm-6 mb-4">
		<div>
			<p class="text-muted mb-1">Pagos personalizados</p>
			<h5 class="mb-0">{{customPayments}}</h5>
		</div>
	</div>
	<hr>

	<h5 class="text-center mb-4">RESUMEN</h5>
	<div class="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
		<div>
			<p class="text-muted mb-1">Monto del préstamo</p>
			<h5 class="mb-0">{{totalAmortize | currency}}</h5>
		</div>
	</div>
	<div class="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
		<div>
			<p class="text-muted mb-1">Monto Interés total</p>
			<h5 class="mb-0">{{totalInterest | currency}}</h5>
		</div>
	</div>
	<div class="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
		<div>
			<p class="text-muted mb-1">Monto Comisiones total</p>
			<h5 class="mb-0">{{totalCommission | currency}}</h5>
		</div>
	</div>
	<div class="col-12">
		<div class="text-center">
			<p class="text-muted mb-2">Total a pagar</p>
			<h4 class="mb-0">
				{{amortizeService.currentAmortizeTableValue.totalPeriodPay |
				currency}}
			</h4>
		</div>
	</div>
</div>