import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api/api.service'
import { User, UserAdministrationViewModel, UserResponse } from '../../models/user/user.model'
import { map } from 'rxjs/operators';
import { AlertGlobalService } from '../alert-global/alert-global.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { CompleteAccount } from 'src/app/models/user/completeaccount.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  PasswordChangeEmail(value: any) {
	  throw new Error('Method not implemented.');
  }

  constructor(private apiService: ApiService,
    private alertGlobalService: AlertGlobalService,
    private authService: AuthenticationService) { }

  create(user: User) {
    return this.apiService.post('user', '', user)
          .pipe(map(response => {
              this.alertGlobalService.showAlertSuccess("Su cuenta se ha creado.");
              return response.data as String;
          }));
  }

  getCodeForChangeEmail(newEmail: string) {
    return this.apiService.post('user', 'requestchangeemailcode', { newEmail: newEmail })
      .pipe(map(response => {
          this.alertGlobalService.showAlertSuccess("Su código de validación se ha enviado.");
          return response.data as String;
      }));
  }

  getCodeForChangePhoneNumber(newPhoneNumber: string) {
    return this.apiService.post('user', 'requestchangephonenumbercode', { newPhoneNumber: newPhoneNumber })
    .pipe(map(response => {
        this.alertGlobalService.showAlertSuccess("Su código de validación se ha enviado.");
        return response.data as String;
    }));
  }

  changeEmail(oldValue: string, newValue: string, code: string) {
    return this.apiService.post('user', 'changeemail', { oldValue: oldValue, newValue: newValue, code: code })
    .pipe(map(response => {
      if (response.status === 200){
        this.alertGlobalService.showAlertSuccess("Su correo electrónico se ha cambiado.");
        this.authService.logout();
        window.location.href = "/iniciar-sesion";
        return response.data as String;
      }        
    }));
  }

  changePhoneNumber(oldValue: string, newValue: string, code: string) {
    return this.apiService.post('user', 'changephonenumber', { oldValue: oldValue, newValue: newValue, code: code })
    .pipe(map(response => {
      if (response.status === 200){
        this.alertGlobalService.showAlertSuccess("Su teléfono se ha cambiado.");
        this.authService.logout();
        window.location.href = "/iniciar-sesion";
        return response.data as String;
      }
    }));
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.apiService.post('user', 'changepassword', { oldValue: oldPassword, newValue: newPassword })
    .pipe(map(response => {
      if (response.status === 200){
        this.alertGlobalService.showAlertSuccess("Su contraseña se ha cambiado.");
        this.authService.logout();
        window.location.href = "/iniciar-sesion";
        return response.data as String;
      }
    }));
  }

  forgotPassword(email: string) {
    return this.apiService.post('user', 'forgotpassword/' + email)
    .pipe(map(response => {
      if (response.status === 200){
        this.alertGlobalService.showAlertSuccess("Se ha enviado un correo para recuperar la contraseña.");
        setTimeout(function(){ window.location.href = "/iniciar-sesion"; }, 2000);
        return response.data as String;
      }
    }));
  }

  resetPassword(email: string, token: string, password: string) {
    return this.apiService.post('user', 'resetpassword', { email: email, token: token, password: password })
    .pipe(map(response => {
      if (response.status === 200){        
        this.alertGlobalService.showAlertSuccess("Su contraseña se ha restablecido con éxito.");
        this.authService.logout();
        setTimeout(function(){ window.location.href = "/iniciar-sesion"; }, 2000);        
        return response.data as String;
      }
    }));
  }

  completeAccount(email: string, data: CompleteAccount) {
    return this.apiService.post('user', 'completeaccount/' + email, data)
          .pipe(map(response => {
              this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
              return response.data as String;
          }));
  }

  validateUserExists(email: string, userName: string) {
    return this.apiService.post('user', 'validateUserExists/' + email + "/" + userName)
          .pipe(map(response => {
              return response.data as UserResponse;
          }));
  }

  updatePhoneNumber(phone: string) {
    return this.apiService.post('user', 'updatephonenumber/' + phone)
    .pipe(map(response => {
      if (response.status === 200){
        return response.data as String;
      }
    }));
  }

  getUsers(user: string) {
    return this.apiService.get('user', 'UserAdministrationViewModel/', user)
    .pipe(map(response => {
      return response.data as UserAdministrationViewModel[];
    }));
  }

  updateUser(data: User) {
    return this.apiService.put('user', 'updateUser', '', data)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  getPasswordChangeEmail(email: string) {
    return this.apiService.post('user', 'requestConfirmChangePassword/' + email )
      .pipe(map(response => {
      }));
  }

  sendConfirmChangeEmail(userId: string) {
    return this.apiService.post('user', 'SendConfirmChangeEmail/' + userId)
    .pipe(map(response => {
    }));
  }

  sendForgotPasswordEmail(email: string) {
    return this.apiService.post('user', 'RequestForgotPassword/' + email )
      .pipe(map(response => {
      }));
  }
}
