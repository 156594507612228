<div class="container-fluid" id="elementToExport">
  <h4>Detalle de servicio</h4>

  <div *ngIf="collectionLogResponse">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Nombre del Prestamista</p>
            <h5 class="mb-2">{{capitalizeFirstLetter(collectionLogResponse.dataSend.lenderFullName)}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Fecha de contratación</p>
            <h5 class="mb-2">{{collectionLogResponse.dateUpdated | date: 'mediumDate'}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Costo</p>
            <h5 class="mb-2">{{collectionLogResponse.amount| currency}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Estatus</p>
            <h5 class="mb-2">{{collectionLogResponse.statusName}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Nombre del Solicitante</p>
            <h5 class="mb-2">{{capitalizeFirstLetter(loanFullName)}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Saldo total a liquidar</p>
            <h5 class="mb-2">{{collectionLogResponse.dataSend.totalBalanceToLiquited | currency}}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Días con atraso</p>
            <h5 class="mb-2">{{ collectionLogResponse.dataSend.daysLate ?(collectionLogResponse.dataSend.daysLate) :'-' }}</h5>
          </div>
          <div class="col-md-3 col-6">
            <p class="text-muted text-truncate mb-1">Saldo con atraso total</p>
            <h5 class="mb-2">{{collectionLogResponse.dataSend.totalBalanceLate | currency}} </h5>
          </div>
        </div>
      </div>
    </div>

    <mat-accordion>
      <mat-expansion-panel [expanded]="panelDataLoanOpenState" (opened)="panelDataLoanOpen(true)"
        (closed)="panelDataLoanOpen(false)" class="bg-white">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h5>Datos del préstamo</h5>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <h5 class="mb-3 fw-bold custom-heading">INFORMACIÓN DEL SOLICITANTE</h5>
          <div class="col-md-3 col-6">
            <p>Nombre</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{capitalizeFirstLetter(loanFullName)}}</p>
          </div>

          <ng-container *ngFor="let field of getServiceFields(externalServiceType)">
            <div class="col-md-3 col-6">
              <p>{{ field.label }}</p>
            </div>
            <div class="col-md-3 col-6">
              <p>{{ getFieldData(field.key) }}</p>
            </div>
          </ng-container>
          <hr>

          <h5 class="mb-3 fw-bold">CONDICIONES DEL PRÉSTAMO</h5>
          <div class="col-md-3 col-6">
            <p>Monto del préstamo</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.loanAmount | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Fecha de inicio</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.startDate | date: 'mediumDate'}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Interés mensual</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.monthlyRate * 0.0100 | percent:'1.2-5'}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Fecha de fin</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{ collectionLogResponse.dataSend.endDate ? (collectionLogResponse.dataSend.endDate | date: 'mediumDate') :'-' }}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Interés moratorio mensual</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.monthlyPorcentOnArrears * 0.0100 | percent:'1.2-5'}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Frecuencia de pagos</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{ collectionLogResponse.dataSend.term ? (collectionLogResponse.dataSend.term) :'-' }}
            </p>
          </div>
          <div class="col-md-3 col-6">
            <p>Monto total a pagar</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.totalPayment | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Número de pagos</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.totalPeriods}}</p>
          </div>
          <hr>

          <h5 class="mb-3 fw-bold">RESUMEN DEL PRÉSTAMO</h5>
          <div class="col-md-3 col-6">
            <p>Saldo capital a pagar</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.pendingCapital | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Saldo capital con atraso</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.totalCapitalLate | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Saldo accesorios a pagar</p><!--interestBalance -->
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.interestBalance | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Saldo accesorios con atraso</p><!--commissionsBalance -->
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.commissionsBalance | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Saldo total a pagar</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.totalBalancePayment | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Saldo con atraso total</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.totalBalanceLate | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Saldo capital a liquidar</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.capitalExpired | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Días con atraso</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{ collectionLogResponse.dataSend.daysLate ?(collectionLogResponse.dataSend.daysLate) :'-' }}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Saldo accesorios a liquidar</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.totalInterestToLiquited | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Fecha de próximo pago</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{ collectionLogResponse.dataSend.firstDatePayment ? (collectionLogResponse.dataSend.firstDatePayment | date: 'mediumDate') :'-' }}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Saldo total a liquidar</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.totalBalanceToLiquited | currency}}</p>
          </div>
          <div class="col-md-3 col-6">
            <p>Monto próximo pago</p>
          </div>
          <div class="col-md-3 col-6">
            <p>{{collectionLogResponse.dataSend.amountNextPayment | currency}}</p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="card">
      <div class="card-body">
        <div *ngIf="externalServiceType == 'visita' || externalServiceType == 'visita de verificación'" #divExport>
          <div class="d-flex justify-content-between mb-4">
            <h5 class="mb-0 fw-bold">
              Detalles de {{collectionLogResponse.serviceName.toLowerCase()}}
            </h5>
            <h5 *ngIf="collectionLogResponse" class="mb-0 fw-bold">
              Id servicio {{externalId}}
            </h5>
          </div>

          <div *ngIf="collectionLogResponse">
            <div class="card bg-gray">
              <div class="card-body">
                <div class="row">
                  <h5 class="mb-3 fw-bold">DATOS DE VISITA</h5>
                  <div class="col-md-3 col-6">
                    <p>Gestor</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>{{capitalizeFirstLetter(collectionLogResponse.kobraResponse.agent)}}</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Tipo de visita</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>{{typeService}}</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Tipo de contacto</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Solicitante</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Fecha y hora de visita</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>{{collectionLogResponse.kobraResponse.startDate | date: 'dd/MM/yy, HH:mm'}}</p>
                  </div>
                  <hr>
                  <br>
                  <h5 class="mb-3 fw-bold">UBICACIÓN</h5>
                  <div class="col-md-3 col-6">
                    <p>Domicilio</p>
                  </div>
                  <div class="col-md-8 col-12">
                    <p>{{capitalizeFirstLetter(loanAddress)}}</p>
                  </div>
                </div>
              </div>

              <div id="map" class="w-100"></div>

              <div class="card-body">
                <div class="row">
                  <hr>

                  <h5 class="mb-3 fw-bold">CUESTIONARIO</h5>

                  <div class="row">
                    <div class="col-md-6">
                      <ng-container *ngFor="let question of firstHalf">
                        <div class="col-mb-12">
                          <div class="row">
                            <div class="col-md-6 fw-bold">
                              <p class="">{{ question.questionName }}</p>
                            </div>
                            <div class="col-md-6">
                              <p>{{ question.answers[0].value }}</p>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>

                   <div class="col-md-6">
                      <ng-container *ngFor="let question of secondHalf">
                        <div class="col-mb-12">
                          <div class="row">
                            <div class="col-md-6 fw-bold">
                              <p class="">{{ question.questionName }}</p>
                            </div>
                            <div class="col-md-6">
                              <p>{{ question.answers[0].value }}</p>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <hr>

                  <ng-container *ngIf="externalServiceType === 'visita'">
                  <h5 class="mb-3 fw-bold">CALIFICACIÓN DE COBRANZA</h5>
                  <div class="col-md-3 col-6">
                  <p>Intención de pago</p>
                  </div>
                  <div class="col-md-3 col-6"
                  *ngIf="collectionLogResponse">
                  <p>{{collectionLogResponse.kobraResponse.interest}}</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Evaluación intención de pago </p>
                    </div>
                    <div class="col-md-3 col-6" *ngIf="collectionLogResponse">
                      <ng-container [ngSwitch]="collectionLogResponse.kobraResponse.interest">
                        <p *ngSwitchCase="1"> No se logró ubicar la dirección, ni localizar al Deudor.</p>
                        <p *ngSwitchCase="2"> Expresa negativa a realizar el pago o no reconoce adeudo. No se obtuvo promesa de pago.</p>
                        <p *ngSwitchCase="3"> No expresa negativa ni voluntad para realizar el pago. No se obtuvo promesa de pago y/o si muestra disposición para contactar al acreedor.</p>
                        <p *ngSwitchCase="4"> Expresa voluntad para realizar el pago. Se obtuvo promesa de pago parcial y/o muestra disposición para contactar al acreedor.</p>
                        <p *ngSwitchCase="5"> Expresa muy buena voluntad para realizar el pago a la brevedad posible. Se obtuvo promesa de pago completo.</p>
                      </ng-container>
                    </div>
                  <hr>
                </ng-container>

                  <h5 class="mb-3 fw-bold">INFORMACIÓN RECOPILADA</h5>
                  <ng-container *ngFor="let item of phonesKobra">
                    <div class="col-md-3 col-6">
                      <p>Teléfonos</p>
                    </div>
                    <div class="col-md-3 col-6">
                      <p>{{item}}</p>
                    </div>
                  </ng-container>

                  <ng-container *ngFor="let item of emailsKobra">
                    <div class="col-md-3 col-6">
                      <p>Correos electrónicos</p>
                    </div>
                    <div class="col-md-3 col-6">
                      <p>{{item}}</p>
                    </div>
                  </ng-container>
                  <hr>

                  <h5 class="mb-3 fw-bold">COMENTARIOS</h5>
                  <p *ngIf="collectionLogResponse" class="mb-2">
                    {{collectionLogResponse.kobraResponse.comment}}
                  </p>
                  <hr>

                  <div *ngIf="requiredDocumentDTO">
                    <h5 class="mb-3 fw-bold">IMÁGENES</h5>
                    <ng-container *ngFor="let item of requiredDocumentDTO">
                      <img class="img_medium" [src]="item.navigationUrl" />
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="externalServiceType == 'llamada'" #divExport>
          <div class="d-flex justify-content-between mb-4">
            <h5 *ngIf="collectionLogResponse" class="mb-0 fw-bold">
              Detalles de {{collectionLogResponse.serviceName.toLowerCase()}}
            </h5>
            <h5 *ngIf="collectionLogResponse" class="mb-0 fw-bold">
              Id servicio {{externalId}}
            </h5>
          </div>

          <div *ngIf="dataQuestions">
            <div class="card bg-gray" *ngFor="let item of dataQuestions; let i = index;">
              <div class="card-body">
                <div class="row">
                  <h5 class="mb-3 fw-bold">DATOS DE LLAMADA #{{i + 1}}</h5>
                  <div class="col-md-3 col-6">
                    <p>Gestor</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>{{capitalizeFirstLetter(item.agent)}}</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Teléfono del Solicitante</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>{{capitalizeFirstLetter(item.phone)}}</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Tipo de contacto</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Solicitante</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Fecha y hora de llamada</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>{{item.dateCreated | date: 'dd/MM/yyyy'}}, {{item.dateCreated | date: 'shortTime'}}</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Tipo de llamada</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>{{item.callTypeName}}</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>Tiempo de llamada</p>
                  </div>
                  <div class="col-md-3 col-6">
                    <p>{{getDuration(item.dateStartManagement, item.dateEndManagement)}}</p>
                  </div>
                  <hr>

                  <h5 class="mb-3 fw-bold">CUESTIONARIO</h5>

                  <div class="row">
                    <div class="col-md-6">
                      <ng-container *ngFor="let question of item.questions.questionResult">
                        <div class="col-mb-12">
                          <div class="row">
                            <div class="col-md-6 fw-bold">
                              <p class="">{{ question.Question }}</p>
                            </div>
                            <div class="col-md-6">
                              <p>{{ question.Answer }}</p>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-md-6">
                      <ng-container *ngFor="let question of item.questions.questionHalf">
                        <div class="col-mb-12">
                          <div class="row">
                            <div class="col-md-6 fw-bold">
                              <p class="">{{ question.Question }}</p>
                            </div>
                            <div class="col-md-6">
                              <p>{{ question.Answer }}</p>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>

                    <br>
                    <ng-container *ngIf="item.questions.filesArchives.length !== 0">
                      <hr>
                      <h5>IMÁGENES</h5>

                      <div class="row col-md-12">
                        <div class="col-md-3" *ngFor="let file of item.questions.filesArchives">
                          <img class="img_small" (click)="openImage(file.url)" [src]="extractFtpUrl(file.url)" />
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap gap-2 justify-content-end mt-4 botones-imprimir">
          <button class="btn btn-outline-primary" (click)="return()">
            Regresar
          </button>
          <button *ngIf="collectionLogResponse.statusName == 'En proceso'" class="btn btn-outline-primary" (click)="export()">
            Imprimir PDF
          </button>
          <button *ngIf="collectionLogResponse.statusName === 'Finalizado' && !boolData" class="btn btn-outline-primary" (click)="export()">
            Imprimir PDF
          </button>
          <button *ngIf="(collectionLogResponse.statusName === 'Finalizado' && resultData != null) && boolData" class="btn btn-outline-primary" (click)="openDocument()">
            PDF
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
