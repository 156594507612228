import { KeyValue } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { LoanImport } from 'src/app/models/loanimport/loanimport.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import * as XLSX from 'xlsx';
import { DialogLoanRequestEditComponent } from '../dialog-loanrequest-edit/dialog-loanrequest-edit.component';

type AOA = any[][];

@Component({
	selector: 'app-loan-import-new',
	templateUrl: './loan-import-new.component.html',
	styleUrls: ['./loan-import-new.component.scss'],
})

export class LoanImportNewComponent implements OnInit {
	@Input() action: string = "";
	@ViewChild('stepper') stepper: MatStepper;

	importColumns: string[] = [
		'externalReference',
		'firstName',
		'middleName',
		'lastName1',
		'lastName2',
		'email',
		'loanAmount',
		'typeOfPeriod',
		'totalPeriods',
		'monthlyRate',
		'startDate',
		'typeOfCredit',
		'typeOfAmortization',
		'monthlyPorcentOnArrears',
		'periodAmortizeStart',
		'taxPorcent',
		'percentRetentionISR',
		'status',
		'edit',
		'buttonRemove'
	];

	typeOfPeriodList: KeyValue<number, string>[] = [];

	loanImport = <LoanImport[]>[];
	rowSelected: LoanImport = <LoanImport>{};
	indexSelected = null;
	selectedFiles: FileList;

	importDataSource: MatTableDataSource<LoanImport> = new MatTableDataSource([]);

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private typeofperiodService: TypeofperiodService,
		private alertGlobalService: AlertGlobalService,
		private dialog: MatDialog,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.action = this.route.snapshot.paramMap.get('action');

		if (this.action != "prestamos" && this.action != "pagos") {
			this.router.navigate(['importacion/']);
		}
	}

	ngOnInit(): void {
		this.getTypeOfPeriodList();
	}

	getTypeOfPeriodList() {
		this.typeofperiodService.getList()
			.subscribe((list) => {
				this.typeOfPeriodList = list;
			});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.importDataSource.filter = filterValue.trim().toLowerCase();
	}

	onFileChange(event: any) {
		this.selectedFiles = event.target.files;

		var fileName = this.selectedFiles.item(0).name
		var extension = fileName.replace(/^.*\./, '');

		if (extension != "xlsx") {
			this.alertGlobalService.showAlertWarning("Archivo xlsx inválido.");
			return;
		}

		const target: DataTransfer = <DataTransfer>(event.target);
		if (target.files.length !== 1) this.alertGlobalService.showAlertWarning("No se pueden subir multiples archivos");;

		const reader: FileReader = new FileReader();
		reader.onload = (e: any) => {
			const bstr: string = e.target.result;
			const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

			const wsname: string = wb.SheetNames[0];
			const ws: XLSX.WorkSheet = wb.Sheets[wsname];

			this.fillImportData(<AOA>XLSX.utils.sheet_to_json(ws, { header: 1 }));

			this.alertGlobalService.showAlertSuccess("Archivo importado exitosamente");
			this.stepper.next();
		};

		reader.readAsBinaryString(target.files[0]);
	}

	fillImportData(importdata: AOA) {
		for (let i = 1; i < importdata.length; i++) {
			this.loanImport.push(
				{
					id: i,
					externalReference: importdata[i][0],
					firstName: importdata[i][1],
					middleName: importdata[i][2],
					lastName1: importdata[i][3],
					lastName2: importdata[i][4],
					email: importdata[i][5],
					loanAmount: importdata[i][6],
					typeOfPeriod: importdata[i][7],
					totalPeriods: importdata[i][8],
					monthlyRate: importdata[i][9],
					startDate: importdata[i][10],
					typeOfCredit: importdata[i][11],
					typeOfAmortization: importdata[i][12],
					monthlyPorcentOnArrears: importdata[i][13],
					periodAmortizeStart: importdata[i][14],
					taxPorcent: importdata[i][15],
					percentRetentionISR: importdata[i][16],
					status: ''
				}
			);

			this.loanImport.find(x => x.id == i).status = this.validateStatus(this.loanImport.find(x => x.id == i));
		}

		this.importDataSource = new MatTableDataSource(this.loanImport);
		this.importDataSource.paginator = this.paginator;
		this.importDataSource.sort = this.sort;
	}

	onRowClicked(row, index) {
		this.rowSelected = row;
		this.indexSelected = index;
	}

	onClickPage() {
		this.rowSelected = <LoanImport>{};
		this.indexSelected = null;
	}

	onDelete(index: number): void {
		this.loanImport.splice(index, 1);
		this.importDataSource = new MatTableDataSource<LoanImport>(this.loanImport);
	}

	clear() {
		this.loanImport = [];
		this.importDataSource = new MatTableDataSource<LoanImport>(this.loanImport);
	}

	openDetails(row: LoanImport) {
		const dialogRef = this.dialog.open(DialogLoanRequestEditComponent, {
			autoFocus: false,
			height: '85%',
			width: '80%',
			disableClose: true,
			data: row
		});

		let instance = dialogRef.componentInstance;

		dialogRef.afterClosed().subscribe((result) => {
			if (instance.idSelected != 0) {
				this.loanImport.find(x => x.id == instance.idSelected).status = this.validateStatus(this.loanImport.find(x => x.id == instance.idSelected));
			}
		});
	}

	validateStatus(importdata: LoanImport) {
		if (importdata.externalReference == undefined || importdata.externalReference.length == 0) {
			return "Incorrecto"
		// } else if (importdata[1] == undefined || importdata[1].length == 0) { //primerNombre
		// 	return "primer apellido no debe ser vacio"
		// } else if (importdata[3] == undefined || importdata[3].length == 0) { //primerApellido
		// 	return "primer apellido no debe ser vacio"
		// } else if (importdata[4] == undefined || importdata[4].length == 0) { //segundoApellido
		// 	return "segundo apellido no debe ser vacio"
		// } else if (importdata[5] == undefined || importdata[5].length == 0) { //correo
		// 	return "correo no debe ser vacio"
		// } else if (importdata[6] == undefined || importdata[6].length == 0) { //monto
		// 	return "monto no debe ser vacio"
		// } else if (!Number.isInteger(importdata[6])) { //monto
		// 	return "monto no válido"
		// } else if (Number.parseInt(importdata[6].toString()) <= 0) { //monto
		// 	return "monto debe ser mayor a 0"
		} else if (this.typeOfPeriodList.find(x => x.value == importdata.typeOfPeriod) == undefined) {
			return "Incorrecto"
		} else {
			return "Correcto"
		}



	}
}
