import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SummaryAccountStatement } from 'src/app/models/account-statement/summaryaccountstatement.dto.model';
import { UserLogged } from 'src/app/models/user/userlogged.model';
import { AccountStatementService } from 'src/app/services/account-statement/account-statement.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit {
	summary: SummaryAccountStatement;

	panelBoardOpenState: any;
	panelColocationOpenState: any;
	panelCollectOpenState: any;
	panelWalletOpenState: any;

	isLender = false;
  
	constructor(
		private authService: AuthenticationService
	) { }

	ngOnInit(): void {
		this.isLender = this.authService.currentUserModeLenderValue;

		this.authService.userModeLender
			.subscribe((data) => {
			this.isLender = data;
		});
	}

	panelBoardOpen(e) {
		this.panelBoardOpenState = e;
	}

	panelColocationOpen(e) {
		this.panelColocationOpenState = e;
	}

	panelCollectOpen(e) {
		this.panelCollectOpenState = e;
	}

	panelWalletOpen(e) {
		this.panelWalletOpenState = e;
	}
}
