<div class="container-fluid">
  <h4>Mi Perfil</h4>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-4 col-md-5">
          <div class="d-flex">
            <form class="me-4" [formGroup]="profileForm">
              <img alt="Header Avatar" class="avatar-md rounded-circle img-thumbnail d-flex mx-auto mb-2"
                src="{{profilePicture}}">
              <a class="text-link file-select" id="src-file1">
                <input #fileInput type="file" accept="image/png, image/jpeg" (change)="selectFile($event)" />
                {{message}}
              </a>
            </form>
            <div class="flex-grow-1 align-self-center">
              <div class="text-muted">
                <p class="mb-2">Bienvenido de nuevo</p>
                <h5 class="mb-1" *ngIf="customer">
                  {{customer.firstName}} {{customer.lastName1}}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="align-self-center col-lg-5 col-md-7">
          <div class="text-lg-center mt-4 mt-md-0">
            <div class="row">
              <div class="col-sm-4 col-12 mb-2 mb-md-0">
                <p class="text-muted text-truncate mb-2"></p>
                <h5 class="mb-0"></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="mb-4">
        <mat-accordion>
          <mat-expansion-panel [expanded]="profileOpenState" (opened)="panelProfileOpen(true)"
            (closed)="panelProfileOpen(false)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h5>Datos de usuario</h5>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <form [formGroup]="profileForm">
                <div class="subtittle">
                  Escribe o selecciona tus datos personales. Esta información es requerida para que la
                  documentación legal de tus préstamos sea la correcta y sean válidos
                </div>

                <app-input-errors-global *ngFor="let item of f1_prop" fieldName="{{item}}">
                </app-input-errors-global>

                <mat-slide-toggle class="mb-4 special" color="primary" labelPosition="before" #checkApplyMoralPerson
                  (change)="applyMoralPerson(checkApplyMoralPerson.checked)">
                  Persona moral
                </mat-slide-toggle>

                <div class="w-100">
                  <div class="row">
                    <mat-accordion *ngIf="checkApplyMoralPerson.checked">
                      <mat-expansion-panel [expanded]="panelCompanyDataOpenState" class="bg-white"
                        (opened)="panelCompanyDataOpen(true)" (closed)="panelCompanyDataOpen(false)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h5>Datos Empresa</h5>
                          </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row">
                          <div class="col-md-9 mb-3" *ngIf="showPersonMoral">
                            <div class="form-group"> <label for="businessName">Razón
                                social</label> <input type="text" formControlName="businessName" class="form-control"
                                [ngClass]="validateRequiredForm('businessName') ? 'is-invalid' : ''">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3" *ngIf="showPersonMoral">
                            <div class="form-group"> <label for="businessRfc">RFC</label>
                              <input type="text" formControlName="businessRfc" class="form-control"
                                [ngClass]="validateRequiredForm('businessRfc') ? 'is-invalid' : ''">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3" *ngIf="showPersonMoral">
                            <div class="form-group">
                              <label for="dateConstitution">
                                Fecha constitución
                              </label>
                              <input autofocus="autofocus" class="form-control" formControlName="dateConstitution"
                                type="date">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3" *ngIf="showPersonMoral">
                            <div class="form-group"> <label for="writingNumber">Número
                                escritura</label> <input type="number" formControlName="writingNumber"
                                class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3" *ngIf="showPersonMoral">
                            <div class="form-group"> <label for="notaryNumber">Número
                                notario</label> <input type="number" formControlName="notaryNumber"
                                class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3" *ngIf="showPersonMoral">
                            <div class="form-group">
                              <label for="notaryName">Nombre notario</label>
                              <input type="text" formControlName="notaryName" class="form-control">
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <mat-accordion>
                      <mat-expansion-panel [expanded]="panelTypePersonOpenState" class="bg-white"
                        (opened)="panelTypePersonOpen(true)" (closed)="panelTypePersonOpen(false)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h5>
                              {{checkApplyMoralPerson.checked ? 'Datos Representante Legal' : "Datos de persona
                              física"}}
                            </h5>
                          </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row">
                          <div class="col-md-3 mb-3">
                            <div class="form-group has-validation">
                              <label for="firstName">Nombre</label>
                              <input type="text" formControlName="firstName" class="form-control"
                                [ngClass]="validateRequiredForm('firstName') ? 'is-invalid' : ''">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group">
                              <label for="middleName">Segundo nombre</label>
                              <input type="text" formControlName="middleName" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="lastName1">Primer
                                apellido</label> <input type="text" formControlName="lastName1" class="form-control"
                                [ngClass]="validateRequiredForm('lastName1') ? 'is-invalid' : ''">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="lastName2">Segundo apellido</label> <input type="text"
                                formControlName="lastName2" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="alias">Alias</label> <input type="text"
                                formControlName="alias" class="form-control"> </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="gender">Género</label>
                              <select class="form-control" formControlName="gender"
                                [ngClass]="validateRequiredForm('gender') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of genders" value="{{item.code}}"> {{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="educationLevel">Nivel de
                                estudios</label> <select class="form-control" formControlName="educationLevel">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of educationLevelList" value="{{item.key}}">{{item.value}}
                                </option>
                              </select> </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="ocupation">Ocupación</label> <select
                                class="form-control" formControlName="ocupation">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of ocupationList" value="{{item.key}}">{{item.value}}</option>
                              </select> </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="StartDate">Fecha de
                                nacimiento</label> <input autofocus="autofocus" class="form-control"
                                formControlName="birthdayDate" type="date"
                                [ngClass]="validateRequiredForm('birthdayDate') ? 'is-invalid' : ''" />
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="countryBirthId">País de
                                nacimiento</label> <select #selectCountryBirth class="form-control"
                                formControlName="countryBirthId"
                                (change)="onChangeSelectCountrieBirth(selectCountryBirth.value)">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of countries" value="{{item.code}}">{{item.name}}</option>
                                <option value="Otro">Otro</option>
                              </select> </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="placeOfBirth">Estado de
                                nacimiento</label> <select class="form-control" formControlName="placeOfBirth">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of statesBirth" value="{{item.code}}">{{item.name}}</option>
                              </select> </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="nationality">Nacionalidad</label> <select
                                class="form-control" formControlName="nationality">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of nationalities" value="{{item.code}}">{{item.name}}</option>
                              </select> </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="curp">CURP</label> <input type="text"
                                formControlName="curp" class="form-control"
                                [ngClass]="validateRequiredForm('curp') ? 'is-invalid' : ''">
                            </div>
                            <mat-error *ngIf="validateRequiredForm('curp')">
                              {{requiredErrorMessage}} </mat-error>
                            <mat-error *ngIf="f1['curp'].errors && f1['curp'].errors['minlength']">El
                              CURP debe tener minímo 18 caracteres.</mat-error>
                            <mat-error *ngIf="f1['curp'].errors && f1['curp'].errors['maxlength']">El
                              CURP debe tener minímo 18 caracteres.</mat-error>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="rfc">RFC</label> <input type="text"
                                formControlName="rfc" class="form-control"
                                [ngClass]="validateRequiredForm('rfc') ? 'is-invalid' : ''">
                            </div>
                            <mat-error *ngIf="validateRequiredForm('rfc')">
                              {{requiredErrorMessage}} </mat-error>
                            <mat-error *ngIf="f1['rfc'].errors && f1['rfc'].errors['minlength']">El
                              RFC debe tener minímo 10 caracteres.</mat-error>
                            <mat-error *ngIf="f1['rfc'].errors && f1['rfc'].errors['maxlength']">El
                              RFC debe tener minímo 13 caracteres.</mat-error>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="personId">INE</label>
                              <input type="text" formControlName="personId" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="serieFiel">Número de serie
                                FIEL</label> <input type="text" formControlName="serieFiel" class="form-control"> </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <mat-accordion>
                      <mat-expansion-panel [expanded]="panelAddressOpenState" class="bg-white"
                        (opened)="panelAddressOpen(true)" (closed)="panelAddressOpen(false)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h5>Datos de dirección</h5>
                          </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row">
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="street">Calle</label>
                              <input type="text" formControlName="street" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="exteriorNumber">Número
                                exterior</label> <input type="text" formControlName="exteriorNumber"
                                class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="interiorNumber">Número
                                interior</label> <input type="text" formControlName="interiorNumber"
                                class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="districtName">Colonia</label> <input type="text"
                                formControlName="districtName" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="countryId">País</label>
                              <select #selectCountry class="form-control" formControlName="countryId"
                                (change)="onChangeSelectCountries(selectCountry.value)">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of countries" value="{{item.code}}">{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="stateId">Estado</label>
                              <select #selectState class="form-control" formControlName="stateId"
                                (change)="onChangeSelectStates(selectCountry.value, selectState.value)">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of states" value="{{item.code}}"> {{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="cityId">Municipio</label>
                              <select class="form-control" formControlName="cityId">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of cities" value="{{item.code}}"> {{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="zipCode">Código
                                postal</label> <input type="number" formControlName="zipCode" class="form-control">
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <mat-accordion>
                      <mat-expansion-panel [expanded]="panelContactDataOpenState" class="bg-white"
                        (opened)="panelContactOpen(true)" (closed)="panelContactOpen(false)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h5>Datos de contacto</h5>
                          </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row">
                          <div class="col-md-3 mb-3">
                            <div class="form-group">
                              <label>Teléfono celular
                                <i class="bx bx-check" *ngIf="phoneNumberValidated || submitted"></i>
                              </label>
                              <input type="text" [textMask]="{mask: phoneMask}" formControlName="phoneMobile"
                                class="form-control" (keyup)="change($event)"
                                [ngClass]="submitted && validateRequiredForm('phoneMobile') ? 'is-invalid' : ''">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group">
                              <label>Solicitar código</label>
                              <button class="btn btn-outline-primary w-100" [ngClass]="{
                                        'btn-danger': !validatePhone,
                                        'btn-outline-primary': validatePhone
                                      }" (click)="sendCode()" [disabled]="showValidatePhone" type="button">
                                Validar teléfono
                              </button>
                            </div>
                            <mat-error *ngIf="!validatePhone">Es necesario validar el teléfono</mat-error>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group">
                              <label for="phoneHome">Teléfono casa</label>
                              <input type="text" [textMask]="{mask: phoneMask}" formControlName="phoneHome"
                                class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group">
                              <label for="phoneOffice">Teléfono trabajo</label>
                              <input type="text" [textMask]="{mask: phoneMask}" formControlName="phoneOffice"
                                class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="phoneMessages">Teléfono
                                recados</label> <input type="text" [textMask]="{mask: phoneMask}"
                                formControlName="phoneMessages" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="phoneOther">Teléfono
                                otro</label> <input type="text" [textMask]="{mask: phoneMask}"
                                formControlName="phoneOther" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="email">Correo electrónico
                                *</label> <input type="email" formControlName="email" class="form-control"
                                [ngClass]="validateRequiredForm('email') ? 'is-invalid' : ''">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="facebook">Facebook</label>
                              <input type="text" formControlName="facebook" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="instagram">Instagram</label> <input type="text"
                                formControlName="instagram" class="form-control"> </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="linkedin">LinkedIn</label>
                              <input type="text" formControlName="linkedin" class="form-control">
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <mat-accordion>
                      <mat-expansion-panel [expanded]="panelBillingAddressOpenState" class="bg-white"
                        (opened)="panelBillingAddressOpen(true)" (closed)="panelBillingAddressOpen(false)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h5>Datos de facturación</h5>
                          </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row">
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingBusinessName">{{businessNametittle}} *</label>
                              <input type="text" formControlName="billingBusinessName" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"><label for="billingTaxRegime">Regimen fiscal *</label>
                              <select #select class="form-control" formControlName="billingTaxRegime">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of fiscalRegimeList" value="{{item.value}}">
                                  {{item.value}}
                                  - {{item.name}}</option>
                              </select>
                              <mat-error *ngIf="submitted && validateRequiredForm('billingTaxRegime')">
                                {{requiredErrorMessage}}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingRfc">RFC *</label> <input type="text"
                                formControlName="billingRfc" class="form-control" (input)="rfcInput($event)"
                                [ngClass]="validateRequiredForm('billingRfc') ? 'is-invalid' : ''">
                            </div>
                            <mat-error *ngIf="validateRequiredForm('billingRfc')">
                              {{requiredErrorMessage}} </mat-error>
                            <mat-error
                              *ngIf="f1['billingRfc'].errors && f1['billingRfc'].errors['minlength'] && !checkApplyMoralPerson.checked  ">El
                              RFC debe tener minímo 12 caracteres.</mat-error>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingCfdiUse">Uso CFDI *</label>
                              <select #select class="form-control" formControlName="billingCfdiUse">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of cfdiUseList" value="{{item.value}}">{{item.value}} -
                                  {{item.name}} </option>
                              </select>
                              <mat-error *ngIf="submitted && validateRequiredForm('billingCfdiUse')">
                                {{requiredErrorMessage}}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingStreet">Calle</label>
                              <input type="text" formControlName="billingStreet" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingExteriorNumber">Número
                                exterior</label> <input type="text" formControlName="billingExteriorNumber"
                                class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingInteriorNumber">Número
                                interior</label> <input type="text" formControlName="billingInteriorNumber"
                                class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingDistrictName">Colonia</label> <input type="text"
                                formControlName="billingDistrictName" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingCountryId">País</label>
                              <select #selectBillingCountry class="form-control" formControlName="billingCountryId"
                                (change)="onChangeSelectBillingCountries(selectBillingCountry.value)">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of billingCountries" value="{{item.code}}">{{item.name}}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingStateId">Estado</label>
                              <select #selectBillingState class="form-control" formControlName="billingStateId"
                                (change)="onChangeSelectBillingStates(selectBillingCountry.value, selectBillingState.value)">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of billingStates" value="{{item.code}}"> {{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingCityId">Municipio</label>
                              <select class="form-control" formControlName="billingCityId">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of billingCities" value="{{item.code}}"> {{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="form-group"> <label for="billingZipCode">Código
                                postal *</label> <input type="number" formControlName="billingZipCode"
                                [ngClass]="validateRequiredForm('billingZipCode') ? 'is-invalid' : ''"
                                class="form-control">
                              <mat-error *ngIf="validateRequiredForm('billingZipCode')">
                                {{requiredErrorMessage}} </mat-error>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <div class="d-flex flex-wrap gap-2 justify-content-end">
                      <button class="btn btn-primary external-btn" (click)="onSubmitProfile()">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <mat-accordion>
        <mat-expansion-panel [expanded]="securityOpenState" (opened)="panelSecurityOpen(true)"
          (closed)="panelSecurityOpen(false)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5>Seguridad</h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="subtittle">
            Escribe y confirma los datos que quieres modificar
          </div>

          <div class="row">
            <mat-accordion>
              <mat-expansion-panel [expanded]="panelEmailOpenState" class="bg-white" (opened)="panelEmailOpen(true)"
                (closed)="panelEmailOpen(false)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h5>Correo electrónico</h5>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                  <form [formGroup]="changeEmailForm" (ngSubmit)="onSubmitChangeEmail()">
                    <app-input-errors-global fieldName="newEmail"></app-input-errors-global>
                    <div class="row">
                      <div class="col-md-4 mb-3">
                        <div class="form-group"> <label>Correo actual*
                            <mat-icon *ngIf="!emailValidated" title="No validado" matSuffix>
                              info
                            </mat-icon>
                            <i class="bx bx-check" *ngIf="emailValidated"></i>
                          </label> <input type="text" value="{{currentEmail}}" class="form-control" disabled>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="form-group"> <label for="newEmail">Nuevo correo*</label>
                          <input type="email" placeholder="" formControlName="newEmail" class="form-control"
                            [ngClass]="changeEmailSubmitted && validateChange('newEmail') ? 'is-invalid' : ''">
                          <mat-error
                            *ngIf="changeEmailSubmitted && f2.newEmail.errors && f2.newEmail.errors.email">Formato de
                            correo inválido.</mat-error>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="form-group"> <label for="newEmailConfirm">Confirmar nuevo
                            correo*</label> <input type="email" placeholder="" formControlName="newEmailConfirm"
                            class="form-control"
                            [ngClass]="changeEmailSubmitted && validateChange('newEmailConfirm') ? 'is-invalid' : ''">
                          <mat-error
                            *ngIf="changeEmailSubmitted && f2.newEmailConfirm.errors && f2.newEmailConfirm.errors.email">Formato
                            de correo inválido.</mat-error>
                        </div>
                      </div>
                      <div class="d-flex flex-wrap gap-2 justify-content-end">
                        <button class="btn btn-primary">
                          Actualizar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
              <mat-expansion-panel [expanded]="panelPasswordOpenState" class="bg-white"
                (opened)="panelPasswordOpen(true)" (closed)="panelPasswordOpen(false)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h5>Contraseña</h5>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                  <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmitChangePassword()">
                    <div class="row">
                      <div class="col-md-4 mb-3">
                        <div class="form-group">
                          <label>Contraseña actual*</label>
                          <div>
                            <i [ngClass]="getClass()" aria-hidden="true" class="input_icon" (click)="hide = !hide"></i>
                            <input formControlName="oldPassword" class="form-control"
                              [type]="hide ? 'password' : 'text'"
                              [ngClass]="changePasswordSubmitted && validateChangePassword('oldPassword') ? 'is-invalid' : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="form-group">
                          <label>Nueva contraseña*</label>
                          <div>
                            <i [ngClass]="getClassnewPassword()" aria-hidden="true" class="input_icon"
                              (click)="hidenewPassword = !hidenewPassword"></i>
                            <input formControlName="newPassword" class="form-control"
                              [type]="hidenewPassword ? 'password' : 'text'"
                              [ngClass]="changePasswordSubmitted && validateChangePassword('newPassword') ? 'is-invalid' : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="form-group">
                          <label>Confirmar nueva contraseña*</label>
                          <div>
                            <i [ngClass]="getClassnewPasswordConfirm()" aria-hidden="true" class="input_icon"
                              (click)="hidenewPasswordConfirm = !hidenewPasswordConfirm"></i>
                            <input type="password" formControlName="newPasswordConfirm" class="form-control"
                              [type]="hidenewPasswordConfirm ? 'password' : 'text'"
                              [ngClass]="changePasswordSubmitted && validateChangePassword('newPasswordConfirm') ? 'is-invalid' : ''">
                          </div>
                        </div>
                      </div>
                      <div class="d-flex flex-wrap gap-2 justify-content-end">
                        <button class="btn btn-primary">
                          Actualizar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>