import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AmortizeTableResponse } from 'src/app/models/amortize/amortize.table.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelpInfoComponent } from 'src/app/components/dialog-help-info/dialog-help-info.component';
import { GlobalLists } from '../../global/global-data';

@Component({
  selector: 'app-dialog-amortize-table',
  templateUrl: './dialog-amortize-table.component.html',
  styleUrls: ['./dialog-amortize-table.component.scss']
})
export class DialogAmortizeTableComponent implements OnInit {
	onEventPrint = new EventEmitter();
	onEventExportPDF = new EventEmitter();
	onEventExportExcel = new EventEmitter();

	data: AmortizeTableResponse;
	hiddenPaymentsColumn: boolean = false;
	disabledPaymentsColumn: boolean = false;
	dataExport: boolean = true;

	constructor(
		private dialog: MatDialog) { }

	ngOnInit(): void {
	}

	print(): void {
		this.onEventPrint.emit();
	}

	exportPDF(): void {
		this.onEventExportPDF.emit();
	}

	exportExcel(): void {
		this.onEventExportExcel.emit();
	}

	showHelp(control: string): void {
		var help = GlobalLists.helpControlsList.find(x => x.control === control);
	
		let dialogRef = this.dialog.open(DialogHelpInfoComponent);
		let instance = dialogRef.componentInstance;
	
		instance.title = help.title;
		instance.description = help.description;
		instance.isHtml = help.isHtml;
		instance.load();
	
		dialogRef.afterClosed().subscribe(() => {
		  
		});
		
	  }
}
