<mat-accordion>
    <mat-expansion-panel [expanded]="panelBalanceSheetState" class="mb-4 bg-white"
        (opened)="panelBalanceSheetOpen(true)" (closed)="panelBalanceSheetOpen(false)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="d-flex">
                    <h5>Datos balance general</h5>
                    <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelActiveState" class="mb-4"
                (opened)="panelActiveOpen(true)" (closed)="panelActiveOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Relación de activos</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form *ngIf="!getIsDisabledSave()" [formGroup]="balanceSheetActiveForm" (ngSubmit)="onSubmitBalanceSheetActive()">
                    <div class="row">
                        <div class="subtittle2">Agregar activos</div>
                        
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="courtDate">Fecha corte</label>
                                <input autofocus="autofocus" class="form-control" formControlName="courtDate" type="date" [ngClass]="submitedActive && validateRequiredFormActive('courtDate') ? 'is-invalid' : ''" (ngModelChange)="onDateChange()" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label>Tipo de activo </label>
                                <select #selectActiviteType class="form-control" formControlName="bienType" [ngClass]="submitedActive && validateRequiredFormActive('bienType') ? 'is-invalid' : ''"> <option *ngFor="let item of activiteList" value="{{item.value}}">     {{item.value}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-6 marginCustom d-flex align-items-end mb-3">
                            <div class="form-group"> <label>Monto</label> <input class="form-control text-right" type="text" placeholder="$ 0"     [textMask]="{mask: currencyMask}" formControlName="amount"     autocomplete="off" autocapitalize="off"     [ngClass]="submitedActive && validateRequiredFormActive('amount') ? 'is-invalid' : ''">
                            </div>
                            <button class="btn btn-outline-primary internal-btn">
                                Agregar
                            </button>
                        </div>
                    </div>
                </form>
                    
                <div class="table-responsive">
                    <div class="react-bootstrap-table">
                        <table *ngIf="dsBalanceSheetActiveData" mat-table
                            [dataSource]="dsBalanceSheetActiveData">
                            <ng-container matColumnDef="bienType"> <th mat-header-cell *matHeaderCellDef> Tipo de bien </th> <td mat-cell *matCellDef="let row"> {{row.bienType}} </td> <td mat-footer-cell *matFooterCellDef> Total </td>
                            </ng-container>

                            <ng-container matColumnDef="amount">
                                <th mat-header-cell *matHeaderCellDef>Monto</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{row.amount | currency}}</span>
                                </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{getBalanceSheetActiveTotal() | currency}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="courtDate">
                                <th mat-header-cell *matHeaderCellDef>Fecha corte</th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.courtDate | date:'mediumDate'}}
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="buttonRemove">
                                <th mat-header-cell *matHeaderCellDef>
                                    Eliminar
                                </th>
                                <td mat-cell *matCellDef="let row; let i = index;">
                                    <i class="bx bx-x" (click)="onDeleteBalanceSheetActive(i)"></i>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="balanceSheetActiveColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: balanceSheetActiveColumns;">
                            </tr>
                            <tr mat-footer-row *matFooterRowDef="balanceSheetActiveColumns"></tr>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelPassiveState" class="mb-4"
                (opened)="panelPassiveOpen(true)" (closed)="panelPassiveOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Relación de pasivos</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form *ngIf="!getIsDisabledSave()" [formGroup]="balanceSheetPassiveForm" (ngSubmit)="onSubmitBalanceSheetPassive()">
                    <div class="row">
                        <div class="subtittle2">Agregar pasivos</div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="courtDate">Fecha corte</label>
                                <input autofocus="autofocus" class="form-control" formControlName="courtDate" type="date" [ngClass]="submitedPassive && validateRequiredFormPassive('courtDate') ? 'is-invalid' : ''" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label>Tipo de pasivo </label>
                                <select class="form-control" formControlName="passiveType" [ngClass]="submitedPassive && validateRequiredFormPassive('passiveType') ? 'is-invalid' : ''">
                                    <option *ngFor="let item of passiveTypeList" value="{{item.value}}">
                                        {{item.value}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6 marginCustom d-flex align-items-end mb-3">
                            <div class="form-group">
                                <label>Monto</label>
                                <input class="form-control text-right" type="text" placeholder="$ 0" [textMask]="{mask: currencyMask}" formControlName="amount" autocomplete="off" autocapitalize="off" [ngClass]="submitedPassive && validateRequiredFormPassive('amount') ? 'is-invalid' : ''">
                            </div>
                            <button class="btn btn-outline-primary internal-btn">
                                Agregar
                            </button>
                        </div>
                    </div>
                </form>
                    
                <div class="table-responsive">
                    <div class="react-bootstrap-table">
                        <table *ngIf="dsBalanceSheetPassiveData" mat-table
                            [dataSource]="dsBalanceSheetPassiveData">
                            <ng-container matColumnDef="passiveType"> <th mat-header-cell *matHeaderCellDef> Tipo de pasivo </th> <td mat-cell *matCellDef="let row"> {{row.passiveType}} </td> <td mat-footer-cell *matFooterCellDef> Total </td>
                            </ng-container>

                            <ng-container matColumnDef="amount"> <th mat-header-cell *matHeaderCellDef> Monto </th> <td mat-cell *matCellDef="let row">     <span>{{row.amount | currency}}</span> </td> <td mat-footer-cell *matFooterCellDef>     {{getBalanceSheetPassiveTotal() | currency}} </td>
                            </ng-container>

                            <ng-container matColumnDef="courtDate"> <th mat-header-cell *matHeaderCellDef> Fecha corte </th> <td mat-cell *matCellDef="let row">     {{row.courtDate | date:'mediumDate'}} </td> <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="buttonRemove">
                                <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                                <td mat-cell *matCellDef="let row; let i = index;">
                                    <i class="bx bx-x" (click)="onDeleteBalanceSheetPassive(i)"></i>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="balanceSheetPassiveColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: balanceSheetPassiveColumns;">
                            </tr>
                            <tr mat-footer-row *matFooterRowDef="balanceSheetPassiveColumns"></tr>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelCapitalState" (opened)="panelCapitalOpen(true)" (closed)="panelCapitalOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Relación de capital contable</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form *ngIf="!getIsDisabledSave()" [formGroup]="balanceSheetCapitalForm" (ngSubmit)="onSubmitBalanceSheetCapital()">
                    <div class="row">
                        <div class="subtittle2">Agregar capital</div>
                        
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="courtDate">Fecha corte</label>
                                <input autofocus="autofocus" class="form-control" formControlName="courtDate" type="date" [ngClass]="submitedCapital && validateRequiredFormCapital('courtDate') ? 'is-invalid' : ''" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label>Tipo de capital </label>
                                <select class="form-control" formControlName="capitalType" [ngClass]="submitedCapital && validateRequiredFormCapital('capitalType') ? 'is-invalid' : ''"> <option *ngFor="let item of capitalTypeList" value="{{item.value}}">     {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6 marginCustom d-flex align-items-end mb-3">
                            <div class="form-group"> <label>Monto</label> <input class="form-control text-right" type="text" placeholder="$ 0"     [textMask]="{mask: currencyMask}" formControlName="amount"     autocomplete="off" autocapitalize="off"     [ngClass]="submitedCapital && validateRequiredFormCapital('amount') ? 'is-invalid' : ''">
                            </div>
                            <button class="btn btn-outline-primary internal-btn">
                                Agregar
                            </button>
                        </div>
                    </div>
                </form>
                    
                <div class="table-responsive">
                    <div class="react-bootstrap-table">
                        <table *ngIf="dsBalanceSheetCapitalData" mat-table
                            [dataSource]="dsBalanceSheetCapitalData">
                            <ng-container matColumnDef="capitalType"> <th mat-header-cell *matHeaderCellDef> Tipo de capital </th> <td mat-cell *matCellDef="let row"> {{row.capitalType}} </td> <td mat-footer-cell *matFooterCellDef> Total </td>
                            </ng-container>

                            <ng-container matColumnDef="amount"> <th mat-header-cell *matHeaderCellDef> Monto </th> <td mat-cell *matCellDef="let row">     <span>{{row.amount | currency}}</span> </td> <td mat-footer-cell *matFooterCellDef>     {{getalanceSheetCapitaTotal() | currency}} </td>
                            </ng-container>

                            <ng-container matColumnDef="courtDate"> <th mat-header-cell *matHeaderCellDef> Fecha corte </th> <td mat-cell *matCellDef="let row">     {{row.courtDate | date:'mediumDate'}} </td> <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="buttonRemove">
                                <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                                <td mat-cell *matCellDef="let row; let i = index;">
                                    <i class="bx bx-x" (click)="onDeleteBalanceSheetCapital(i)"></i>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="balanceSheetCapitalColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: balanceSheetCapitalColumns;">
                            </tr>
                            <tr mat-footer-row *matFooterRowDef="balanceSheetCapitalColumns"></tr>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-expansion-panel>
</mat-accordion>