import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { PersonReference, LoanRequestOptionsData, CommercialReference } from 'src/app/models/loanrequest/loanrequest.model';
import { GlobalLists } from 'src/app/global/global-data';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';

@Component({
	selector: 'app-dialog-reference-data',
	templateUrl: './dialog-reference-data.component.html',
	styleUrls: ['./dialog-reference-data.component.scss']
})

export class DialogReferenceDataComponent implements OnInit {
	personReferenceColumns = [
		'name',
		'secondName',
		'lastName1',
		'lastName2',
		'cellPhone',
		'email',
		'relationShip',
		'timeToMeet',
		'buttonRemove'
	];

	commercialReferenceColumns = [
		'personType',
		'busisnessName',
		'name',
		'secondName',
		'lastName1',
		'lastName2',
		'cellPhone',
		'email',
		'relationShip',
		'timeToMeet',
		'buttonRemove'
	];

	submitedPerson = false;
	submitedCommercial = false;
	panelReferenceState = false;
	panelPersonState = false;
	panelCommercialState = false;
	contactRelationshipList = GlobalLists.contactRelationshipList;
	comercialRelationshipList = GlobalLists.comercialRelationshipList;
	phoneMask = GlobalLists.phoneMask;

	personReferenceForm: FormGroup;
	commercialReferenceForm: FormGroup;
	personReference = <PersonReference[]>[];
	commercialReference = <CommercialReference[]>[];
	referenceData: LoanRequestOptionsData;
	dsReferencePersonData = new MatTableDataSource<PersonReference>(this.personReference);
	dsReferenceCommercialData = new MatTableDataSource<CommercialReference>(this.commercialReference);
	personTypeList = GlobalLists.personTypeList;

	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;
	@Input() personType: number;


	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService
	) {
	}

	isLender = this.authService.currentUserModeLenderValue;

	get fReferencePerson() { return this.personReferenceForm.controls; }
	get fReferenceCommercial() { return this.commercialReferenceForm.controls; }

	ngOnInit(): void {
		this.createReferencePersonForm();
		this.createReferenceCommercialForm();

		this.loanrequestService.getReferenceData(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.personReference = result.personReference;
				this.commercialReference = result.commercialReference;

				this.dsReferencePersonData = new MatTableDataSource<PersonReference>(this.personReference);
				this.dsReferenceCommercialData = new MatTableDataSource<CommercialReference>(this.commercialReference);

				this.updateReferenceData();
			}
		});

		if (this.completed) {
			this.dr = true;
		}
	}

	createReferencePersonForm() {
		this.personReferenceForm = this.formBuilder.group({
			name: ['', Validators.required],
			secondName: [''],
			lastName1: ['', Validators.required],
			lastName2: [''],
			cellPhone: ['', Validators.required],
			email: [''],
			relationShip: [''],
			timeToMeet: ['']
		});
	}

	createReferenceCommercialForm() {
		this.commercialReferenceForm = this.formBuilder.group({
			name: ['', Validators.required],
			secondName: [''],
			lastName1: ['', Validators.required],
			lastName2: [''],
			cellPhone: [''],
			email: [''],
			relationShip: [''],
			timeToMeet: [''],
			selectPersonType: ['0', Validators.required],
			busisnessName: ['']
		});
	}

	onSubmitReferencePerson() {
		this.submitedPerson = true;

		if (this.personReferenceForm.invalid || this.getIsDisabledSave()) {
			return;
		}

		this.addReferencePerson();
	}

	onDeleteReferencePerson(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.personReference.splice(index, 1);
		this.dsReferencePersonData = new MatTableDataSource<PersonReference>(this.personReference);

		this.updateReferenceData();
	}

	addReferencePerson(): void {
		this.personReference.push(<PersonReference>{
			name: this.fReferencePerson['name'].value,
			secondName: this.fReferencePerson['secondName'].value,
			lastName1: this.fReferencePerson['lastName1'].value,
			lastName2: this.fReferencePerson['lastName2'].value,
			cellPhone: this.fReferencePerson['cellPhone'].value + "",
			email: this.fReferencePerson['email'].value,
			relationShip: this.fReferencePerson['relationShip'].value,
			timeToMeet: this.fReferencePerson['timeToMeet'].value + ""
		});

		this.dsReferencePersonData = new MatTableDataSource<PersonReference>(this.personReference);
		this.personReferenceForm.reset();

		this.updateReferenceData();
	}

	onSubmitReferenceCommercial() {
		this.submitedCommercial = true;

		if (this.commercialReferenceForm.invalid || this.getIsDisabledSave()) {
			return;
		}

		this.addReferenceCommercial();
	}

	onDeleteReferenceCommercial(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.commercialReference.splice(index, 1);
		this.dsReferenceCommercialData = new MatTableDataSource<CommercialReference>(this.commercialReference);

		this.updateReferenceData();
	}

	addReferenceCommercial(): void {
		this.commercialReference.push(<CommercialReference>{
			name: this.fReferenceCommercial['name'].value,
			secondName: this.fReferenceCommercial['secondName'].value,
			lastName1: this.fReferenceCommercial['lastName1'].value,
			lastName2: this.fReferenceCommercial['lastName2'].value,
			cellPhone: this.fReferenceCommercial['cellPhone'].value + "",
			email: this.fReferenceCommercial['email'].value,
			relationShip: this.fReferenceCommercial['relationShip'].value,
			timeToMeet: this.fReferenceCommercial['timeToMeet'].value + "",
			personType: this.fReferenceCommercial['selectPersonType'].value,
			busisnessName: this.fReferenceCommercial['busisnessName'].value
		});

		this.dsReferenceCommercialData = new MatTableDataSource<CommercialReference>(this.commercialReference);
		this.commercialReferenceForm.reset();

		this.updateReferenceData();
	}

	panelReferenceOpen(e) {
		this.panelReferenceState = e;
	}

	panelPersonOpen(e) {
		this.panelPersonState = e;
	}

	panelCommercialOpen(e) {
		this.panelCommercialState = e;
	}

	updateReferenceData() {
		var loanRequestAdvanceOptionsData = <LoanRequestOptionsData>{
			personReference: this.personReference,
			commercialReference: this.commercialReference
		};

		this.referenceData = loanRequestAdvanceOptionsData;
	}

	getRelationShipName(relationShipKey: any) {
		if (relationShipKey == "" || relationShipKey == null) {
			return;
		}

		return this.contactRelationshipList.find(x => x.key == relationShipKey).value;
	}

	onChange(value: string) {
		// if (value == 0) {
		// 	this.fReferenceCommercial['busisnessName'].clearValidators();
		// } else if (value == 1) {
		// 	this.fReferenceCommercial['busisnessName'].setValidators([Validators.required]);
		// }
	}

	getPersonType(value: number) {
		return this.personTypeList.find(x => x.key == value).value;
	}

	getContactRelationshipList(personType: string) {
		if (personType == "1") {
			return this.comercialRelationshipList;
		} else {
			return this.contactRelationshipList.filter(x => (x.key >= 0 && x.key <= 7) || (x.key >= 13 && x.key <= 14));
		}
	}

	validateRequiredFormPerson(formControlName: string): boolean {
		if (this.fReferencePerson[formControlName] == null) {
			return;
		}

		if (this.fReferencePerson[formControlName].errors !== null && this.fReferencePerson[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateRequiredFormCommercial(formControlName: string): boolean {
		if (this.fReferenceCommercial[formControlName] == null) {
			return;
		}

		if (this.fReferenceCommercial[formControlName].errors !== null && this.fReferenceCommercial[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	getIsDisabledSave() {
		if (this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) {
			return true;
		} else {
			return false;
		}
	}
}
