import { Component, Inject, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { DatePipe, KeyValue } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { GlobalLists } from 'src/app/global/global-data';
import { Country } from 'src/app/models/loanrequest/loanrequest.model';

export interface FormRequestElement {
	conditionName: string;
	value: string;
	enabled: boolean;
	required: boolean;
}

export interface DialogData {
	number: number,
	warranty: string,
	warrantysForm: any;
	loanRequestId: string;
	warrantysCompleted: boolean;
	loanRequestStatus: number;
}

@Component({
	selector: 'app-dialog-warranty-people-details',
	templateUrl: './dialog-warranty-people-details.component.html',
	styleUrls: ['./dialog-warranty-people-details.component.scss']
})

export class DialogWarrantyPeopleDetailsComponent implements OnInit {
	mode = "";
	peopleGuaranteeJsonForm: FormGroup = this.formBuilder.group({});
	genders: any;
	countries: Country[];
	statesBirth: any;
	nationalities: any;
	// states: any;
	statesPeople: any;
	// cities: any;
	citiesPeople: any;
	elementsFormPeopleGuarantee: any;
	dataSourceformPeopleGuarantee = new MatTableDataSource<FormRequestDTO>([]);
	submit = false
	isDisabledSave = false;
	FormrequestJson: FormRequestDTO[];

	columnsformRequest: string[] = [
		'conditionName',
		'value',
		'required'
	];

	panelCompanyDataOpenState: any;
	panelTypePersonOpenState: any;
	panelAddressOpenState: any;
	panelContactDataOpenState: any;

	relationship = "";

	contactRelationshipList = GlobalLists.contactRelationshipList;
	typeRelationshipList = GlobalLists.typeRelationshipList;
	phoneMask = GlobalLists.phoneMask;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private catalogService: CatalogService,
		private loanrequestService: LoanrequestService,
		private alertGlobalService: AlertGlobalService,
		private dialogRef: MatDialogRef<DialogWarrantyPeopleDetailsComponent>,
		private datePipe: DatePipe,
		private spinnerService: SpinnerService,
		private cdRef: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data:DialogData
		) {
	}
	@ViewChild('checkApplyMoralPerson') checkApplyMoralPersonPeoplee: MatSlideToggle;
	checkApplyMoralPersonPeople: boolean;

	isLender = this.authService.currentUserModeLenderValue;

	get f3() { return this.peopleGuaranteeJsonForm.controls; }

	ngOnInit(): void {
		this.getGenders();
		this.getCountries();
		this.getNationalities();
		this.createPeopleGuaranteeForm();
	}

	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}

	getGenders() {
		this.catalogService.getGenders()
		  .subscribe((data) => {
			this.genders = data;
		});
	}

	getCountries() {
		this.catalogService.getCountries()
      	.subscribe((countries) => {
        	this.countries = countries as Country[];
      	});
	}

	getNationalities() {
		this.catalogService.getNationalities()
		  .subscribe((nationalities) => {
			this.nationalities = nationalities;
		});
	}

	getStates(countryBirthId) {
		this.catalogService.getStates(countryBirthId)
		.subscribe((states) => {
		this.statesBirth = states;
		});
	}

	getStatesPeople(countryId) {
		this.catalogService.getStates(countryId)
		.subscribe((states) => {
		this.statesPeople = states;
		});
	}

	getCitysPeople(stateId) {
		this.catalogService.getCities(this.f3["value-CountryId"].value, stateId)
		.subscribe((cities) => {
		  this.citiesPeople = cities;
		});
	}

	onChangeSelectCountrieBirth(countryId: string) {

		this.spinnerService.requestStarted();

		this.catalogService.getStates(countryId)
			.subscribe((statesBirth) => {
			this.statesBirth = statesBirth;
			this.spinnerService.requestEnded();

			if (countryId == "MX" || countryId == "US") {
				this.f3['value-PlaceOfBirth'].patchValue("");
				this.f3["value-PlaceOfBirth"].setValidators([Validators.required]);
				this.FormrequestJson.find(x => x.conditionName == "PlaceOfBirth").required = true;
			} else {
				this.f3['value-PlaceOfBirth'].patchValue("");
				this.f3["value-PlaceOfBirth"].clearValidators();
				this.FormrequestJson.find(x => x.conditionName == "PlaceOfBirth").required = false;
			}
		});
	}

	onChangeSelectCountriesPeople(countryId: string) {
		// this.states = [];
		// this.cities = [];
		this.catalogService.getStates(countryId)
		  .subscribe((states) => {
			this.f3["value-StateId"].patchValue("");
			this.statesPeople = states;
		});
	}

	onChangeSelectStatesPeople(stateId: string) {
		// this.cities = [];
		this.catalogService.getCities(this.f3["value-CountryId"].value, stateId)
		  .subscribe((cities) => {
			this.f3["value-CityId"].patchValue("");
			this.citiesPeople = cities;
		});
	}

	getIsDisabledSave() {
		if (this.data.loanRequestStatus == 9 || this.data.loanRequestStatus == 4) {
			return true;
		} else {
			return false;
		}
	}

	createPeopleGuaranteeForm() {
		this.FormrequestJson = this.data.warrantysForm;
		var formJson = this.FormrequestJson;

		for (var key in formJson) {
			if (formJson[key]['conditionName'] != null) {
				if (this.isLender) {
					if (formJson[key]['required']) {
						this.peopleGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
					} else {
						this.peopleGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
					}
				} else {
					if (formJson[key]['required']) {
						this.peopleGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
					} else {
						this.peopleGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
					}
				}

				if (formJson[key]['conditionName'] == "CountryBirthId" && formJson[key]['value'] != "") {
					this.getStates(formJson[key]['value']);
				}

				if (formJson[key]['conditionName'] == "CountryId" && formJson[key]['value'] != "") {
					this.getStatesPeople(formJson[key]['value']);
				}

				if (formJson[key]['conditionName'] == "StateId" && formJson[key]['value'] != "") {
					this.getCitysPeople(formJson[key]['value']);
				}

				if (this.getIsDisabledSave()) {
					this.f3["value-" + formJson[key]['conditionName']].disable();
				}

				if (this.FormrequestJson[key]['conditionName'] == "MoralPerson") {
					if (this.FormrequestJson[key]['value'] == "false") {
						this.f3['value-MoralPerson'].patchValue(false);
						this.checkApplyMoralPersonPeople = false;
						this.relationship = "Relación o parentesco";
					} else if (this.FormrequestJson[key]['value'] == "true") {
						this.f3['value-MoralPerson'].patchValue(true);
						this.checkApplyMoralPersonPeople = true;
						this.relationship = "Relación";
					}
				}
			}
		}

		if (this.f3['value-BirthdayDate'].value != "") {
			this.f3['value-BirthdayDate'].patchValue(this.datePipe.transform(this.f3['value-BirthdayDate'].value, 'yyyy-MM-dd'));
		}

		if (this.f3['value-DateConstitution'].value != "") {
			this.f3['value-DateConstitution'].patchValue(this.datePipe.transform(this.f3['value-DateConstitution'].value, 'yyyy-MM-dd'));
		}

		const ELEMENT_DATA: (FormRequestElement)[] = this.data.warrantysForm;
		this.elementsFormPeopleGuarantee = ELEMENT_DATA;

		this.dataSourceformPeopleGuarantee.data = ELEMENT_DATA as FormRequestDTO[];

		// if (this.elementsFormPeopleGuarantee.find(x => x.conditionName == null && x.initial == "Datos persona fisica")) {
		// 	this.checkApplyMoralPersonPeople = false;
		// } else if (this.elementsFormPeopleGuarantee.find(x => x.conditionName == null && x.initial == "Datos persona moral")) {
		// 	this.checkApplyMoralPersonPeople = true;
		// }
	}

	checkApplyMoralPerson() {
		return this.checkApplyMoralPersonPeople;
	}

	validateRequiredFormF3(formControlName: string): boolean {
		if(this.f3[formControlName].errors !== null && this.f3[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	isGroup(index, item): boolean{
		return item.isGroupBy;
	}

	checkRequiredPeople(e, conditionName) {
		if (!this.isLender) {
			if (e.checked) {
				this.f3["value-" + conditionName].setValidators([Validators.required]);
				this.f3["value-" + conditionName].updateValueAndValidity();
			} else {
				this.f3["value-" + conditionName].clearValidators();
				this.f3["value-" + conditionName].updateValueAndValidity();
			}
		}
	}

	onSubmitFormPeopleGuaranteeJson() {
		// if (this.peopleGuaranteeJsonForm.invalid) {
		// 	return;
		// }

		var warrantysForm: WarrantysForm;
		var peopleGuaranteeAnswer: FormRequestAnswer[] = [];

		for (let val of this.elementsFormPeopleGuarantee) {
			var value: any = "";

			if (val["conditionName"] != null) {
				if (val["conditionName"] == "BirthdayDate") {
					if (this.f3["value-" + val["conditionName"]].value != "") {
						value += this.datePipe.transform(this.f3["value-" + val["conditionName"]].value.toString(), 'yyyy-MM-ddT00:00:00')
					}
				} else if (val["conditionName"] == "DateConstitution") {
					if (this.f3["value-" + val["conditionName"]].value != "") {
						value += this.datePipe.transform(this.f3["value-" + val["conditionName"]].value.toString(), 'yyyy-MM-ddT00:00:00')
					}
				} else {
					value = this.f3["value-" + val["conditionName"]].value.toString();
				}
			}

			peopleGuaranteeAnswer.push({
				conditionName: val["conditionName"],
				required: val["required"],
				value: value
			});
		}

		warrantysForm = {
			number: this.data.number,
			warranty: this.data.warranty,
			warrantyForm: peopleGuaranteeAnswer,
			warrantyFormCompleted: 0
		}

		this.loanrequestService.UpdatePeopleGuaranteeJsonForm(this.data.loanRequestId, warrantysForm).subscribe((result) => {
			this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			this.submit = true
			this.dialogRef.close();
		});
	}

	applyMoralPerson(checked: boolean, loadRequest: boolean) {
		if (checked) {
			this.relationship = "Relación";
			// if (!loadRequest) {
			// 	this.elementsFormPeopleGuarantee.find(x => x.isGroupBy && x.initial == "Datos persona fisica").initial = "Datos persona moral";
			// }

			this.f3["value-BusisnessName"].setValidators([Validators.required]);
			this.f3["value-BusisnessRfc"].setValidators([Validators.required]);
			this.f3["value-DateConstitution"].setValidators([Validators.required]);
			this.f3["value-WritingNumber"].setValidators([Validators.required]);
			this.f3["value-NotaryNumber"].setValidators([Validators.required]);
			this.f3["value-NotaryName"].setValidators([Validators.required]);

			this.FormrequestJson.find(x => x.conditionName == 'BusisnessName').visible = true;
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessName').required = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'BusisnessName').visible = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'BusisnessName').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessRfc').visible = true;
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessRfc').required = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'BusisnessRfc').visible = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'BusisnessRfc').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'DateConstitution').visible = true;
			this.FormrequestJson.find(x => x.conditionName == 'DateConstitution').required = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'DateConstitution').visible = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'DateConstitution').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'WritingNumber').visible = true;
			this.FormrequestJson.find(x => x.conditionName == 'WritingNumber').required = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'WritingNumber').visible = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'WritingNumber').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'NotaryNumber').visible = true;
			this.FormrequestJson.find(x => x.conditionName == 'NotaryNumber').required = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'NotaryNumber').visible = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'NotaryNumber').required = true;
			this.FormrequestJson.find(x => x.conditionName == 'NotaryName').visible = true;
			this.FormrequestJson.find(x => x.conditionName == 'NotaryName').required = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'NotaryName').visible = true;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'NotaryName').required = true;

			this.peopleGuaranteeJsonForm.updateValueAndValidity();
		} else {
			this.relationship = "Relación o parentesco";
			// if (!loadRequest) {
			// 	this.elementsFormPeopleGuarantee.find(x => x.isGroupBy && x.initial == "Datos persona moral").initial = "Datos persona fisica";
			// }

			this.f3["value-BusisnessName"].clearValidators();
			this.f3["value-BusisnessName"].patchValue("");
			this.f3["value-BusisnessRfc"].clearValidators();
			this.f3["value-BusisnessRfc"].patchValue("");
			this.f3["value-DateConstitution"].clearValidators();
			this.f3["value-DateConstitution"].patchValue("");
			this.f3["value-WritingNumber"].clearValidators();
			this.f3["value-WritingNumber"].patchValue("");
			this.f3["value-NotaryNumber"].clearValidators();
			this.f3["value-NotaryNumber"].patchValue("");
			this.f3["value-NotaryName"].clearValidators();
			this.f3["value-NotaryName"].patchValue("");

			this.f3["value-BusisnessName"].clearValidators();
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessName').visible = false;
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessName').required = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'BusisnessName').visible = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'BusisnessName').required = false;
			this.f3["value-BusisnessRfc"].clearValidators();
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessRfc').visible = false;
			this.FormrequestJson.find(x => x.conditionName == 'BusisnessRfc').required = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'BusisnessRfc').visible = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'BusisnessRfc').required = false;
			this.f3["value-DateConstitution"].clearValidators();
			this.FormrequestJson.find(x => x.conditionName == 'DateConstitution').visible = false;
			this.FormrequestJson.find(x => x.conditionName == 'DateConstitution').required = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'DateConstitution').visible = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'DateConstitution').required = false;
			this.f3["value-WritingNumber"].clearValidators();
			this.FormrequestJson.find(x => x.conditionName == 'WritingNumber').visible = false;
			this.FormrequestJson.find(x => x.conditionName == 'WritingNumber').required = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'WritingNumber').visible = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'WritingNumber').required = false;
			this.f3["value-NotaryNumber"].clearValidators();
			this.FormrequestJson.find(x => x.conditionName == 'NotaryNumber').visible = false;
			this.FormrequestJson.find(x => x.conditionName == 'NotaryNumber').required = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'NotaryNumber').visible = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'NotaryNumber').required = false;
			this.f3["value-NotaryName"].clearValidators();
			this.FormrequestJson.find(x => x.conditionName == 'NotaryName').visible = false;
			this.FormrequestJson.find(x => x.conditionName == 'NotaryName').required = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'NotaryName').visible = false;
			this.elementsFormPeopleGuarantee.find(x => x.conditionName == 'NotaryName').required = false;

			this.peopleGuaranteeJsonForm.updateValueAndValidity();
		}

		this.f3["value-RelationShipType"].patchValue("");
		this.onChangeRelationShip("");
	}

	validateFormrequestJson(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	validateVisiblePersonMoral(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).visible) {
			return true;
		} else {
			return false;
		}
	}

	panelCompanyDataOpen(e) {
		this.panelCompanyDataOpenState = e;
	}

	panelTypePersonOpen(e) {
		this.panelTypePersonOpenState = e;
	}

	panelAdressOpen(e) {
		this.panelAddressOpenState = e;
	}

	panelContactDataOpen(e) {
		this.panelContactDataOpenState = e;
	}

	onChangeRelationShip(e: string) {
		if (e == "0") {
			this.f3['value-RelationShipType'].enable()
			this.f3['value-RelationShipType'].patchValue("");
		} else {
			this.f3['value-RelationShipType'].disable()
			this.f3['value-RelationShipType'].patchValue("");
		}
	}

	getContactRelationshipList(personType: string) {
		if (personType == "1" ) {
			return this.contactRelationshipList.filter(x => x.key >= 8 && x.key <= 14)
		} else {
			return this.contactRelationshipList.filter(x => (x.key >= 0 && x.key <= 7) || (x.key >= 13 && x.key <= 14))
		}
	}

	getNationalityName(nationality: string) {
		return nationality.substring(0, 1).toUpperCase() + nationality.substring(1).toLowerCase()
	}

	getCountriesDomicile() {
		if (this.countries == null) {
			return;
		}

		return this.countries.filter(x => x.code == "MX" || x.code == "US")
	}
}
