<div class="row" *ngIf="documentGenerated">
    <div *ngIf="documentValid || documentGeneratorType == 3 || documentGeneratorType == 2">
        <div mat-dialog-content>
            <div id="contentGenerator" [innerHtml]="html"></div>
        </div>
        <div class="d-flex gap-2 justify-content-end mt-4">
            <button class="btn btn-outline-primary external-btn" type="button" mat-dialog-close>
                Cerrar
            </button>
            <button class="btn btn-outline-primary external-btn" (click)="print()">
                Imprimir
            </button>
            <!-- <button class="btn btn-outline-primary ms-3" *ngIf="documentGeneratorType != 3" (click)="exportPDF()" mat-raised-button type="button">PDF</button> -->
            <button class="btn btn-primary external-btn" *ngIf="documentGeneratorType == 3" (click)="sendContactMail()">
                Enviar
            </button>
        </div>
    </div>
    <div *ngIf="!documentValid && documentGeneratorType != 3">
        <div mat-dialog-content>
            <p>Favor de guardar la información faltante</p>  
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                <table mat-table fxFlex="100%" #table [dataSource]="dataSourceRequiredFields">
                    <ng-container matColumnDef="Profile">
                        <th mat-header-cell *matHeaderCellDef> Perfil </th>
                        <td mat-cell *matCellDef="let row"> {{row.profile}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ConditionText">
                        <th mat-header-cell *matHeaderCellDef> Campo </th>
                        <td mat-cell *matCellDef="let row"> {{row.conditionText}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsRequiredFields"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsRequiredFields;"></tr>
                </table>        
            </div>
        </div>
        <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" mat-dialog-close>
                Cerrar
            </button>
        </div>
    </div>
</div>
