import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalLists } from 'src/app/global/global-data';
import { Commission } from 'src/app/models/amortize/amortize.model';
import { createNumberMask } from 'text-mask-addons';

@Component({
	selector: 'app-dialog-comissions-conditions',
	templateUrl: './dialog-comissions-conditions.component.html',
	styleUrls: ['./dialog-comissions-conditions.component.scss']
})
export class DialogComissionsConditionsComponent implements OnInit {

	mode = "";
	commissionsForm: FormGroup;
	submitted = false;

	isPorcentCommission: boolean = false;
	requiredErrorMessage: string = "Este campo es requerido.";

	commissionsColumns = [
		'description',
		'value',
		'whenToPay',
		'buttonRemove'
	];

	typeOfCommissions = GlobalLists.typeOfCommissions;
	whenToPayArray = GlobalLists.whenToPayArray;

	filteredOptions: Observable<string[]>;

	commissions = <Commission[]>[];

	dsCommissions = new MatTableDataSource<Commission>(this.commissions);

	currencyMask = GlobalLists.currencyMask;
	percentMask = GlobalLists.percentMask;

	constructor(private formBuilder: FormBuilder) {
		this.createCommissionsForm();
	}

	get fCommissions() { return this.commissionsForm.controls; }

	ngOnInit(): void {

		if (this.commissions) {
			this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
		}

		this.filteredOptions = this.commissionsForm.controls['description'].valueChanges
			.pipe(
				startWith(''),
				map(value => this._filter(value))
			);
	}

	createCommissionsForm() {
		this.commissionsForm = this.formBuilder.group({
			description: ['', Validators.required],
			isPorcent: ['false'],
			value: ['0', Validators.required],
			whenToPay: ['0', Validators.required],
		});
	}

	onSubmitCommission() {
		this.submitted = true;

		if (this.commissionsForm.invalid) {
			return;
		}

		this.addCommission();
	}

	onDeleteCommission(index: number): void {
		this.commissions.splice(index, 1);
		this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
	}

	addCommission(): void {
		this.commissions.push(<Commission>{
			description: this.fCommissions['description'].value,
			isPorcent: this.isPorcentCommission,
			value: parseFloat(this.fCommissions['value'].value.replace("$", "").replace("%", "")),
			whenToPay: parseInt(this.fCommissions['whenToPay'].value),
			whenToPayDesc: this.whenToPayArray.find(x => x.key === parseInt(this.fCommissions['whenToPay'].value)).value
		});

		this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
		this.commissionsForm.reset();
	}

	private _filter(value: string): string[] {
		let filterValue = '';
		if (value) {
			filterValue = value.toLowerCase();
		}
		return this.typeOfCommissions.filter(option => option.toLowerCase().includes(filterValue));
	}

	onFocusInCommissionDesc() {
		this.fCommissions['description'].patchValue(this.fCommissions['description'].value.trim());
	}

	onClickCommission() {
		return this.commissions;
	}

	validateRequiredFormCommissions(formControlName: string): boolean {
		if (this.fCommissions[formControlName] == null) {
			return;
		}

		if (this.fCommissions[formControlName].errors !== null && this.fCommissions[formControlName].errors['required']) {
			return true;
		}

		return false;
	}
}
