<div>
    <h5>
        Guardar datos otra garantía
    </h5>  

    <form [formGroup]="otherGuaranteeJsonForm" (ngSubmit)="onSubmitFormOtherdJson()">
        <div class="subtittle">
            Escribe tus datos de otra garantía
        </div>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelOtherState" class="mb-4" (opened)="panelOtherOpen(true)" (closed)="panelOtherOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Otros</h5>
                    </mat-panel-title> 
                </mat-expansion-panel-header>   

                <div class="row">
                    <div class="col-md-6 mb-3">    
                        <div class="form-group">
                            <label for="value-Other" class="">Otro<span *ngIf="validateFormrequestJson('Other')">*</span></label>
                            <input type="text" formControlName="value-Other" value="" class="form-control" [ngClass]="validateRequiredFormF8('value-Other') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">    
                        <div class="form-group">
                            <label for="value-OtherComments" class="">Comentarios<span *ngIf="validateFormrequestJson('OtherComments')">*</span></label>
                            <input type="text" formControlName="value-OtherComments" value="" class="form-control" [ngClass]="validateRequiredFormF8('value-OtherComments') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        
        <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" mat-dialog-close [mat-dialog-close]="submit">
                Cerrar
            </button>
            <button type="submit" class="btn btn-primary external-btn" *ngIf="!getIsDisabledSave()">
                Guardar
            </button> 
        </div>  
    </form>
</div>