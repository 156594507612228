import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-confirmcode-change-phonenumber',
  templateUrl: './confirmcode-change-phonenumber.component.html',
  styleUrls: ['./confirmcode-change-phonenumber.component.scss']
})
export class ConfirmcodeChangePhonenumberComponent implements OnInit {

  oldValue: string;
  newValue: string;

  form: FormGroup;
  submitted = false;
  requiredErrorMessage: string = "Este campo es requerido.";
  
  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private dialogRef: MatDialogRef<ConfirmcodeChangePhonenumberComponent>,) {
    this.createForm();
  }

  get f1() { return this.form.controls; }

  createForm() {
    this.form = this.formBuilder.group({
      code: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitted = true;

    if(this.form.invalid) {
      return;
    }

    this.changePhoneNumber();
  }

  validateRequiredForm(formControlName: string): boolean {
    if(this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
      return true;
    }
    return false;
  }

  changePhoneNumber() {
    this.userService.changePhoneNumber(this.oldValue, this.newValue, this.f1['code'].value)
    .subscribe((data) => {
      this.dialogRef.close();
    });
  }

}
