import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RequiredDocumentDTO } from 'src/app/models/required-documents/requiredDocuments.dto.model';

@Injectable({
  providedIn: 'root'
})
export class RequiredDocumentsConfigService {

  requiredDocuments: RequiredDocumentDTO[] = <RequiredDocumentDTO[]> [{
    Id: 0,
    Type: "ine",
    Description: "Identificación oficial",
    Required: false,
    Disabled: false,
    Option: ""
  }, {
    Id: 1,
    Type: "proof_of_address",
    Description: "Comprobante domicilio",
    Required: false,
    Disabled: false,
    Option: ""
  }, {
    Id: 2,
    Type: "proof_of_income",
    Description: "Comprobante ingresos",
    Required: false,
    Disabled: false,
    Option: ""
  }, {
    Id: 3,
    Type: "warranty_aval",
    Description: "Aval/ Obligado solidario",
    Required: false,
    Disabled: false,
    Option: ""
  }, {
    Id: 4,
    Type: "warranty_mortgage",
    Description: "Garantía prendaria/ hipotecaria",
    Required: false,
    Disabled: false,
    Option: ""
  }, {
    Id: 5,
    Type: "expedient",
    Description: "Solicitud de crédito",
    Required: false,
    Disabled: false,
    Option: ""
  }];

  private requiredDocuments$ = new BehaviorSubject<RequiredDocumentDTO[]>(this.requiredDocuments);

  constructor() { }

  public get currentrequiredDocuments(): RequiredDocumentDTO[] {
    return this.requiredDocuments$.value;
  }

  getRequiredDocumentObserver(): Observable<RequiredDocumentDTO[]> {
    return this.requiredDocuments$.asObservable();
  }

  resetRequiredDocumentObserver() {
    this.requiredDocuments$.next(this.requiredDocuments);
  }

  patchRequiredDocument(data: RequiredDocumentDTO[]) {
    this.requiredDocuments$.next(data);
  }
}
