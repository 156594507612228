import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogCompleteAccountComponent } from 'src/app/components/dialog-complete-account/dialog-complete-account.component';
import { ValidateAccountService } from 'src/app/services/validate-account/validate-account.service';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
// import { ToastService } from 'src/app/services/toast/toast.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

declare global {
  interface Window {
    angularComponent: any;
  }
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // @ViewChild('welcomeComponent') welcomeComponent: WelcomeComponent;
  // @ViewChild('appComponent') appComponent: AppComponent;

  hide = true;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;

  constructor(private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private validateAccountService: ValidateAccountService,
    private router: Router) {

    // redirect to home if already logged in
    if (this.authService.currentUserValue) {
      this.router.navigate(['/inicio']);
    }
    this.createLoginForm();

    // if (localStorage.getItem("ipAddress") == null) {
    //   this.authService.setIpAddress();
    // }

    window.angularComponent = this;
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember: [false],
    });
  }

  ngOnInit(): void {
    const url = new URL(document.URL);
    this.returnUrl = url.searchParams.get('redirect');
    this.authService.changeUserModeLenderValue(true);
    if (this.returnUrl != null) {
      if (this.returnUrl.includes("solicitante")) {
        var arr = this.returnUrl.split("/")
        arr.pop()

        this.returnUrl = "/" + arr[1] + "/" + arr[2]

        // this.authService.changeUserModeLenderValue(false);
        // this.authService.changeUserModeSelectedValue(false);

        localStorage.setItem("solicitante", "true");
      } else if (this.returnUrl.includes("prestamista")) {
        var arr = this.returnUrl.split("/")
        arr.pop()

        this.returnUrl = "/" + arr[1] + "/" + arr[2]

        // this.authService.changeUserModeLenderValue(true);
        // this.authService.changeUserModeSelectedValue(true);

        localStorage.setItem("prestamista", "true");
      }
    }
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    var city = "";
    var ip_address = "";
    var region = "";
    var country = ""

    if (this.authService.currentIPSubjectValue != null) {
      city = this.authService.currentIPSubjectValue.city;
      ip_address = this.authService.currentIPSubjectValue.ip_address;
      region = this.authService.currentIPSubjectValue.region;
      country = this.authService.currentIPSubjectValue.country;
    }

    this.authService.authenticate(this.loginForm.value.username, this.loginForm.value.password, city, ip_address, region, country).subscribe(user => {
      if (user.contact) {
        this.validateAccountService.setEmailObserver(user.contact);
        this.validateAccountService.sendValidateEmailCode(user.contact).subscribe();

        const dialogRef = this.dialog.open(DialogCompleteAccountComponent, {
          autoFocus: false,
          width: '100%',
          data: {
            contact: user.contact
          }
        });
      } else {
        window.location.href = this.returnUrl == null ? "/inicio" : this.returnUrl;
      }
    });
  }

  onReset() {
    this.submitted = false;
    this.loginForm.reset();
  }

  getClass() {
    if (this.hide) {
      return 'fa fa-eye';

    } else {
      return 'fa fa-eye-slash';
    }
  }

  validateRequiredForm(formControlName: string): boolean {
    if (this.f[formControlName].errors !== null && this.f[formControlName].errors['required']) {
      return true;
    }
    return false;
  }

  handleResponseFromHTML(responseString: any) {
    fetch('https://api-dev.a-lender.com/api/v1/user/googleApi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(responseString)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al enviar los datos a la API');
        }
        return response.json();
      })
      .then(data => {
        var responseLogin = data;
        if (responseLogin.status == 200) {
          this.authService.getData(responseLogin.data.data);
        }
        window.location.href = this.returnUrl == null ? "/inicio" : this.returnUrl;
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

}