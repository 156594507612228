import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { FormRequestDTO } from 'src/app/models/loanrequest/form-request.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarrantysForm } from 'src/app/models/loanrequest/warrantysform.model';
import { DatePipe, KeyValue } from '@angular/common';
import { DeliveryMethodService } from 'src/app/services/deliveryMethod/deliveryMethod.service';
import { GlobalLists } from 'src/app/global/global-data';

export interface FormRequestElement {
	conditionName: string;
	value: string;
	enabled: boolean;
	required: boolean;
}

export interface DialogData {
	number: number,
	warranty: string,
	warrantysForm: any;
	loanRequestId: string;
	warrantysCompleted: boolean;
	loanRequestStatus: number;
}

@Component({
	selector: 'app-dialog-warranty-liquid-details',
	templateUrl: './dialog-warranty-liquid-details.component.html',
	styleUrls: ['./dialog-warranty-liquid-details.component.scss']
})

export class DialogWarrantyLiquidDetailsComponent implements OnInit {
	mode = "";
	liquidGuaranteeJsonForm: FormGroup = this.formBuilder.group({});	
	submit = false;	
	dataSourceformLiquidJson = new MatTableDataSource<FormRequestDTO>([]);
	elementsFormLiquid: any;
	deliveryMethodList: KeyValue<number, string>[] = [];

	columnsformLiquid: string[] = [
		'conditionName',
		'value',
		'required'
	];

	FormrequestJson: FormRequestDTO[];

	panelLiquidOpenState = true;
	currencyMask = GlobalLists.currencyMask;
	percentMask = GlobalLists.percentMask;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private loanrequestService: LoanrequestService,
		private alertGlobalService: AlertGlobalService,
		private deliveryMethodService: DeliveryMethodService,
		private dialogRef: MatDialogRef<DialogWarrantyLiquidDetailsComponent>,
		private datePipe: DatePipe,
		@Inject(MAT_DIALOG_DATA) public data:DialogData
		) {
	}
	
	isLender = this.authService.currentUserModeLenderValue;

	get f7() { return this.liquidGuaranteeJsonForm.controls; }
	getDeliveryMethod(id) { return this.deliveryMethodList.find(x => x.key === parseInt(id)).value; }

	ngOnInit(): void {
		this.getDeliveryMethods();
		this.createLiquidGuaranteeForm();
	}

	getIsDisabledSave() {
		if (this.data.loanRequestStatus == 9 || this.data.loanRequestStatus == 4) {
			return true;
		} else {
			return false;
		}
	}

	createLiquidGuaranteeForm() {
		this.FormrequestJson = this.data.warrantysForm;
		var formJson = this.FormrequestJson;
		for (var key in formJson) {
			if (this.isLender) {
				if (formJson[key]['required']) {
					this.liquidGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
				} else {
					this.liquidGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			} else {
				if (formJson[key]['required']) {
					this.liquidGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
				} else {
					this.liquidGuaranteeJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
				}
			}

			if (formJson[key]['conditionName'] == "DeliveryMethodOther" && formJson[key]['value'] == "") {
				this.f7["value-DeliveryMethodOther"].disable();
				this.f7["value-DeliveryMethodOther"].patchValue('');
			}

			if (formJson[key]['conditionName'] == "PercentOrAmount") {
				var valPercentOrAmount = formJson[key]['value'] == 'false' ? false : true;

				this.f7["value-PercentOrAmount"].patchValue(valPercentOrAmount)
			}

			if (this.getIsDisabledSave()) {
				this.f7["value-" + formJson[key]['conditionName']].disable();
			}
		}
		
		if (this.f7['value-DeliveryDate'].value != "") {
			this.f7['value-DeliveryDate'].patchValue(this.datePipe.transform(this.f7['value-DeliveryDate'].value, 'yyyy-MM-dd'));
		}
		
		const ELEMENT_DATA: (FormRequestElement)[] = this.data.warrantysForm;
		this.elementsFormLiquid = ELEMENT_DATA;

		this.dataSourceformLiquidJson.data = ELEMENT_DATA as FormRequestDTO[];
	}

	validateRequiredFormF7(formControlName: string): boolean {
		if(this.f7[formControlName].errors !== null && this.f7[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	isGroup(index, item): boolean{
		return item.isGroupBy;
	}

	getDeliveryMethods() {
		this.deliveryMethodService.getList()
		.subscribe((list)=>{
		  this.deliveryMethodList = list;
		});
	}

	onSubmitFormLiquidJson() {
		// if (this.liquidGuaranteeJsonForm.invalid) {
		// 	return;
		// }
		
		var warrantysForm: WarrantysForm;
		var liquidGuaranteeAnswer: FormRequestAnswer[] = [];

		for (let val of this.elementsFormLiquid){
			var value: any = "";

			if (val["conditionName"] != null) {
				if (val["conditionName"] == "DeliveryDate") {
					if (this.f7["value-" + val["conditionName"]].value != "") {
						value = this.datePipe.transform(this.f7["value-" + val["conditionName"]].value.toString(), 'yyyy-MM-ddT00:00:00')
					}					
				} else {
					value = this.f7["value-" + val["conditionName"]].value.toString();
				}
			}

			liquidGuaranteeAnswer.push({ 
				conditionName: val["conditionName"], 
				required: val["required"],
				value: value
			});
		}

		warrantysForm = {
			number: this.data.number,
			warranty: this.data.warranty,
			warrantyForm: liquidGuaranteeAnswer,
			warrantyFormCompleted: 0
		}
		
		this.loanrequestService.UpdateLiquidGuaranteeJsonForm(this.data.loanRequestId, warrantysForm).subscribe((result) => {    
			this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			this.submit = true
			this.dialogRef.close();
		});
	}

	onChangeSelectDeliveryMethod(value) {
		if (value == "5") {
		  this.f7["value-DeliveryMethodOther"].patchValue('');
		  this.f7["value-DeliveryMethodOther"].enable();

		  this.f7["value-DeliveryMethodOther"].setValidators([Validators.required]);
		  this.f7["value-DeliveryMethodOther"].updateValueAndValidity();
		} else {
		  this.f7["value-DeliveryMethodOther"].disable();
		  this.f7["value-DeliveryMethodOther"].patchValue('');

		  this.f7["value-DeliveryMethodOther"].clearValidators();
		  this.f7["value-DeliveryMethodOther"].updateValueAndValidity();
		}
	}

	validateFormrequestJson(val: string) {
		if (this.FormrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	panelLiquidOpen(e) {
		this.panelLiquidOpenState = e;
	}
}
