<!-- <ng-snotify></ng-snotify> -->
<!-- <app-toaster></app-toaster> -->
<app-alert-global></app-alert-global>
<div [hidden]="!loggedMode">
  <nav class="navbar navbar-expand fixed-top" [ngClass]="borrowerSwitchActive ? 'borrower-mode' : 'lender-mode'"
    *ngIf="currentUser">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img src="/assets/images/logos/logotipo-nv-light.svg" alt="a-lender | Plataforma de Administración de Préstamos"
          loading="lazy" fxHide.xs />
        <img src="/assets/images/logos/logotipo-light-small.svg"
          alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" fxHide.xxl fxHide.xl fxHide.lg
          fxHide.md fxHide.sm />
      </a>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav dropdown" fxHide.xxl fxHide.xl fxHide.lg (click)="showLargeMenu()">
          <button aria-haspopup="true" aria-label="Menu" mat-icon-button>
            <i class="bx bx-menu font-size-32 text-white"></i>
          </button>

          <div class="dropdown-menu-center dropdown-menu-end dropdown-menu" [ngClass]="largeMenu ? 'show' : ''">
            <a class="dropdown-item" href="/simulador">Cotizador</a>
            <a class="dropdown-item" href="/solicitud-de-prestamo">Solicitudes</a>
            <a class="dropdown-item" href="/prestamos">Préstamos</a>
            <a class="dropdown-item" href="/reportes">Reportes</a>
            <a class="dropdown-item" href="/servicios">Servicios</a>
          </div>
        </ul>
        <ul class="navbar-nav" fxHide.md fxHide.sm fxHide.xs
          [ngClass]="borrowerSwitchActive ? 'borrower-mode' : 'lender-mode'" *ngIf="currentUser">
          <li class="nav-item" *ngIf="lenderSwitchActive">
            <a class="nav-link" mat-list-item routerLink="/simulador" routerLinkActive="active">
              Cotizador
            </a>
          </li>
          <li class="nav-item" *ngIf="!lenderSwitchActive">
            <a class="nav-link" mat-list-item routerLink="/simulador" routerLinkActive="active">
              Cotizador
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" mat-list-item routerLink="/solicitud-de-prestamo" routerLinkActive="active">
              Solicitudes
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" mat-list-item routerLink="/prestamos" routerLinkActive="active">
              Préstamos
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" mat-list-item routerLink="/reportes" routerLinkActive="active">
              Reportes
            </a>
          </li>
         <li class="nav-item last">
            <a class="nav-link" mat-list-item routerLink="/servicios" routerLinkActive="active">
              Servicios
            </a>
          </li>
        </ul>
      </div>

      <div class="right_menu">
        <div aria-haspopup="true" aria-label="Notificaciones" class="dropdown mx-2" mat-icon-button>
          <a routerLink="/carrito">
            <i class="bx bx-cart bx-tada font-size-24 text-white" matBadgeColor="warn" *ngIf="cartItemsCount > 0"
              matBadge="{{cartItemsShow}}"></i>
            <i class="bx bx-cart font-size-24 text-white" *ngIf="cartItemsCount == 0"></i>
          </a>
        </div>
        <div aria-haspopup="true" aria-label="Notificaciones" class="dropdown mx-2" mat-icon-button>
          <i class="bx bx-bell bx-tada font-size-24 text-white" matBadgeColor="warn" *ngIf="notificationsCount > 0"
            matBadge="{{notificationShow}}" (click)="showMenuDropdown()"></i>
          <i class="bx bx-bell font-size-24 text-white" *ngIf="notificationsCount == 0"
            (click)="showMenuDropdown()"></i>

          <div *ngIf="notificationList" class="dropdown-menu-lg dropdown-menu-end dropdown-menu"
            [ngClass]="menuDropdown ? 'show' : ''" (click)="showMenuDropdown()">
            <div class="dropdown-item bg-transparent">
              <div class="align-items-center row">
                <div class="col">
                  <h6 class="m-0">Notificaciones</h6>
                </div>
              </div>
            </div>
            <div *ngIf="notificationsCount == 0" class="dropdown-item">
              No tienes notificaciones sin leer.
            </div>
            <div *ngFor="let item of getNotificationList()" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <img class="rounded-circle header-profile-user" src="{{item.profilePicture}}"
                      alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{item.subject}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{item.bobyMessage}}
                    </p>
                    <p class="mb-0">
                      <i class="bx bx-time font-size-14"></i> {{item.dateCreated | date:'short'}}
                    </p>
                    <a (click)="readNotification(item.notificationId)" class="text-muted float-end">Marcar como leído</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <div class="dropdown-item" routerLink="/notificaciones">
              <i class="bx bx-right-arrow-alt align-middle me-2"></i> <span>Ver todo</span>
            </div>
          </div>
        </div>

        <div aria-label="Perfil" class="d-flex ms-2" (click)="showSettingDropdown()">
          <div class="dropdown d-flex align-items-center">
            <div class="text-start d-flex justify-content-center align-items-center">
              <img class="rounded-circle header-profile-user mx-2" src="{{getProfileSrc()}}"
                alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
              <div class="ms-2 me-4" fxHide.xs>
                <h5 class="mb-0 text-white">
                  {{ currentUser.firstName }}
                </h5>
                <p class="mb-0 text-white">
                  <span *ngIf="!lenderSwitchActive">
                    Soy solicitante
                  </span>
                  <span *ngIf="lenderSwitchActive">
                    Soy prestamista
                  </span>
                </p>
              </div>
              <i class="fas fa-caret-down text-white"></i>
            </div>

            <div class="dropdown-menu-end dropdown-menu" [ngClass]="settingsDropdown ? 'show' : ''">
              <div class="dropdown-item" *ngIf="lenderSwitchActive">
                <button class="btn btn-primary" (click)="switchActor(false, true)">
                  Cambiar a Soy solicitante
                </button>
              </div>
              <div class="dropdown-item" *ngIf="!lenderSwitchActive">
                <button class="btn btn-primary" (click)="switchActor(true, true)">
                  Cambiar a Soy prestamista
                </button>
              </div>
              <div class="dropdown-item" routerLink="/mi-perfil" *ngIf="menuListActive">
                <i class="bx bx-user align-middle me-2"></i> Mi perfil
              </div>
              <div class="dropdown-item" routerLink="/mi-cuenta" *ngIf="menuListActive">
                <i class="bx bx-wallet-alt align-middle me-2"></i> Mi cuenta
              </div>
              <div *ngIf="menuListActive && currentUser.userType == 1" class="dropdown-item"
                routerLink="/administracion">
                <i class="bx bx-spreadsheet align-middle me-2"></i> Administración
              </div>
              <div *ngIf="menuListActive && currentUser.userType ==  1"
                class="dropdown-item" routerLink="/centro-de-llamadas">
                <i class="bx bx-phone align-middle me-2"></i> Centro de llamadas
              </div>
              <div class="dropdown-item" (click)="openDialogTutorial()" *ngIf="menuListActive">
                <i class="bx bx-sidebar align-middle me-2"></i> Tutoriales
              </div>
              <div class="dropdown-item" routerLink="/preguntas" *ngIf="menuListActive">
                <i class="bx bx-help-circle align-middle me-2"></i> Preguntas frecuentes
              </div>
              <!--<div class="dropdown-item" routerLink="/importacion" *ngIf="lenderSwitchActive && menuListActive">
                                <i class="bx bx-import align-middle me-2"></i> Importación
                            </div> -->
              <div class="dropdown-divider"></div>
              <div class="dropdown-item" (click)="onLogout()">
                <i class="bx bx-power-off align-middle me-2 text-danger"></i> <span>Cerrar sesión</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <!-- Main content -->
  <div class="main-content" (click)="hideDropdown()">
    <div class="page-content no-top-margin">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div [hidden]="loggedMode">
  <nav class="navbar navbar-expand fixed-top less-log">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img src="/assets/images/logos/logotipo-nv.svg" alt="a-lender | Plataforma de Administración de Préstamos"
          loading="lazy" />
        <img src="/assets/images/logos/logotipo-small.svg" alt="a-lender | Plataforma de Administración de Préstamos"
          loading="lazy" />
      </a>

      <div class="collapse navbar-collapse">
        <ul class="navbar-nav mov dropdown" [ngClass]="!indexMode ? 'customResponsive' : ''" id="navbarSupportedContent"
          (click)="showShortMenu()">
          <button aria-haspopup="true" aria-label="Menu" mat-icon-button>
            <i class="bx bx-menu font-size-32 dark"></i>
          </button>

          <div class="dropdown-menu-end dropdown-menu" [ngClass]="shortMenu ? 'show' : ''">
            <div class="container_position" *ngIf="indexMode">
              <div class="dropdown-item" routerLink="/inicio" fragment="como">¿Cómo?</div>
              <div class="dropdown-item" routerLink="/inicio" fragment="quien">¿Quién?</div>
              <div class="dropdown-item" routerLink="/inicio" fragment="disenatuprestamo">Cotizador de préstamos</div>
              <div class="dropdown-item" routerLink="/inicio" fragment="porque">¿Por qué?</div>
              <div class="dropdown-item yellow-nav" routerLink="/inicio" fragment="servicios">Servicios</div>
              <div class="dropdown-item" routerLink="/inicio" fragment="planesyprecios">Planes</div>
              <div class="dropdown-item" routerLink="/inicio" fragment="contacto">Contacto</div>
            </div>
          </div>
        </ul>

        <ul class="navbar-nav desk" *ngIf="indexMode">
          <li class="nav-item">
            <a class="nav-link dark" mat-list-item routerLinkActive="active" routerLink="/inicio"
              fragment="como">¿Cómo?</a>
          </li>
          <li class="nav-item">
            <a class="nav-link dark" mat-list-item routerLinkActive="active" routerLink="/inicio"
              fragment="quien">¿Quién?</a>
          </li>
          <li class="nav-item">
            <a class="nav-link dark" mat-list-item routerLinkActive="active" routerLink="/inicio"
              fragment="disenatuprestamo">Cotizador de préstamos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link dark" mat-list-item routerLinkActive="active" routerLink="/inicio" fragment="porque">¿Por
              qué?</a>
          </li>
          <li class="nav-item">
            <a class="nav-link dark yellow-nav" mat-list-item routerLinkActive="active" routerLink="/inicio" fragment="servicios">Servicios</a>
          </li>
          <li class="nav-item">
            <a class="nav-link dark" mat-list-item routerLinkActive="active" routerLink="/inicio"
              fragment="planesyprecios">Planes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link dark" mat-list-item routerLinkActive="active" routerLink="/inicio"
              fragment="contacto">Contacto</a>
          </li>
        </ul>
      </div>

      <div class="right_menu" *ngIf="!loginMode">
        <button class="btn_yellow">
          <a class="nav-link" href="/iniciar-sesion" routerLinkActive="active">Iniciar sesión</a>
        </button>
      </div>
    </div>
  </nav>
</div>

<div class="main-content" [hidden]="loggedMode" (click)="hideDropdown()">
  <div class="page-content no-top-margin no-top bg_landing">
    <app-index #appIndex *ngIf="indexMode || notFoundMode" fragment="porque"></app-index>
    <div class="padding_landing" *ngIf="showLayout">
      <app-login *ngIf="loginMode"></app-login>
      <app-signup *ngIf="signupMode"></app-signup>
      <app-validate-email *ngIf="validateEmailMode"></app-validate-email>
      <app-forgot-password *ngIf="forgotPasswordMode"></app-forgot-password>
      <app-reset-password *ngIf="resetPasswordMode"></app-reset-password>
      <app-terms *ngIf="termsMode"></app-terms>
      <app-privacy *ngIf="privacyMode"></app-privacy>
      <app-policy *ngIf="cookiesPolicyMode"></app-policy>
      <app-legal-message *ngIf="legalMessage"></app-legal-message>
      <app-blog *ngIf="blogMode"></app-blog>
      <app-faq *ngIf="faqMode"></app-faq>
      <!-- <app-pagenotfound *ngIf="notFoundMode"></app-pagenotfound> -->
    </div>
  </div>
</div>

<app-spinner></app-spinner>

<div class="footer" [hidden]="loggedMode">
  <section class="py-5">
    <div class="container-fluid text-lg-start text-center">
      <div class="row justify-content-between mx-0">
        <div class="col-xl-4 col-lg-4 col-md-12 mb-lg-0 mb-5">
          <p>
            <img src="/assets/images/logos/logotipo-nv.svg" alt="a-lender | Plataforma de Administración de Préstamos"
              loading="lazy" />
          </p>
          <p class="text mb-4" href="mailto:hola@a-lender.com" target="_blank" rel="noreferrer">
            <span>Plataforma digital para administrar préstamos entre familiares, amigos y clientes.</span>
          </p>
          <div>
            <a href="https://www.instagram.com/a_lendermx/" class="me-4" target="_blank" rel="noreferrer">
              <i class="bx bxl-instagram-alt"></i>
            </a>
            <a href="https://www.facebook.com/alendermx/" class="me-4" target="_blank" rel="noreferrer">
              <i class="bx bxl-facebook"></i>
            </a>
            <a href="https://twitter.com/a_lendermx/" class="d-inline-flex me-4" target="_blank" rel="noreferrer">
              <svg viewBox="0 0 24 24" aria-hidden="true" class="r-18jsvk2 r-4qtqp9 r-yyyyoo r-16y2uox r-8kz0gk r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-lrsllp" style="height: 1.813rem;">
                <g>
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z">
                  </path>
                </g>
              </svg>
              <!--img src="/assets/images/icons/twitter.svg" alt="a-lender | Plataforma de Administración de Préstamos"
                loading="lazy" /-->
            </a>
            <a href="https://linkedin.com/company/a-lender/" target="_blank" rel="noreferrer">
              <i class="bx bxl-linkedin"></i>
            </a>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-12 mb-lg-0 mb-5">
          <p class="title">Legal</p>
          <p class="text">
            <a href="/terminos-y-condiciones" target="_blank">
              <span>Términos y condiciones</span>
            </a>
          </p>
          <p class="text">
            <a href="/aviso-de-privacidad" target="_blank">
              <span>Aviso de privacidad</span>
            </a>
          </p>
          <p class="text">
            <a href="/politica-de-cookies" target="_blank">
              <span>Política de cookies</span>
            </a>
          </p>
          <p class="text">
            <a href="/aviso-legal" target="_blank">
              <span>Aviso legal</span>
            </a>
          </p>
          <p class="text">
            <a href="/preguntas-frecuentes" target="_blank">
              <span>Preguntas frecuentes</span>
            </a>
          </p>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-12">
          <p class="title">Contáctanos</p>
          <p class="text">
            <a href="mailto:hola@a-lender.com" target="_blank" rel="noreferrer">
              <span>hola@a-lender.com</span>
            </a>
          </p>
          <p class="text">
            <a href="https://api.whatsapp.com/send?phone=528128970797" target="_blank" rel="noreferrer">
              <span>+52 81 2897 0797</span>
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="mask"></div>
  </section>
</div>
