import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { StatusResultIncome, LoanRequestOptionsData, StatusResultExpense } from 'src/app/models/loanrequest/loanrequest.model';
import { GlobalLists } from 'src/app/global/global-data';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { KeyValue } from '@angular/common';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';

@Component({
	selector: 'app-dialog-status-result',
	templateUrl: './dialog-status-result.component.html',
	styleUrls: ['./dialog-status-result.component.scss']
})

export class DialogStatusResultComponent implements OnInit {
	statusResultIncomeColumns = [
		'incomeType',
		'amount',
		'startDate',
		'endDate',
		'buttonRemove'
	];

	statusResultExpenseColumns = [
		'expenseType',
		'amount',
		'startDate',
		'endDate',
		'buttonRemove'
	];

	submitedExpense = false;
	submitedIncome = false;

	panelStatusState = false;
	panelIncomeState = false;
	panelExpenseState = false;
	incomeList = GlobalLists.statusResultincomeList;
	expenseList = GlobalLists.statusResultExpenseList;
	frecuencyList: KeyValue<number, string>[] = [];
	currencyMask = GlobalLists.currencyMask;

	statusResultIncomeForm: FormGroup;
	statusResultExpenseForm: FormGroup;
	statusResultIncome = <StatusResultIncome[]>[];
	statusResultExpense = <StatusResultExpense[]>[];
	statusResultData: LoanRequestOptionsData;
	dsStatusResultIncomeData = new MatTableDataSource<StatusResultIncome>(this.statusResultIncome);
	dsStatusResultExpenseData = new MatTableDataSource<StatusResultExpense>(this.statusResultExpense);

	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private typeofperiodService: TypeofperiodService,
		private loanrequestService: LoanrequestService
	) {
	}

	isLender = this.authService.currentUserModeLenderValue;

	get fStatusResultIncome() { return this.statusResultIncomeForm.controls; }
	get fStatusResultExpense() { return this.statusResultExpenseForm.controls; }

	ngOnInit(): void {
		this.createStatusResultIncomeForm();
		this.createStatusResultExpenseForm();
		this.getTypeOfPeriodList();

		this.loanrequestService.getStatusResultData(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.statusResultIncome = result.statusResultIncome;
				this.statusResultExpense = result.statusResultExpense;

				this.dsStatusResultIncomeData = new MatTableDataSource<StatusResultIncome>(this.statusResultIncome);
				this.dsStatusResultExpenseData = new MatTableDataSource<StatusResultExpense>(this.statusResultExpense);

				this.updateStatusResultData();
			}
		});

		if (this.completed) {
			this.dr = true;
		}
	}

	getTypeOfPeriodList() {
		this.typeofperiodService.getList()
			.subscribe((list) => {
				this.frecuencyList = list;
			});
	}

	createStatusResultIncomeForm() {
		this.statusResultIncomeForm = this.formBuilder.group({
			incomeType: ['', Validators.required],
			amount: [null, Validators.required],
			startDate: ['', Validators.required],
			endDate: ['', Validators.required]
		});
	}

	createStatusResultExpenseForm() {
		this.statusResultExpenseForm = this.formBuilder.group({
			expenseType: ['', Validators.required],
			amount: [null, Validators.required],
			startDate: [{ value: '', disabled: true }, Validators.required],
			endDate: [{ value: '', disabled: true }, Validators.required]
		});
	}

	onSubmitStatusResultIncome() {
		this.submitedIncome = true;

		if (this.statusResultIncomeForm.invalid || this.getIsDisabledSave()) {
			return;
		}

		this.addStatusResultIncome();
	}

	onDeleteStatusResultIncome(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.statusResultIncome.splice(index, 1);
		this.dsStatusResultIncomeData = new MatTableDataSource<StatusResultIncome>(this.statusResultIncome);

		this.updateStatusResultData();
	}

	addStatusResultIncome(): void {
		this.statusResultIncome.push(<StatusResultIncome>{
			incomeType: this.fStatusResultIncome['incomeType'].value,
			amount: parseFloat(this.fStatusResultIncome['amount'].value.replace("$", "")),
			startDate: this.fStatusResultIncome['startDate'].value,
			endDate: this.fStatusResultIncome['endDate'].value
		});

		this.dsStatusResultIncomeData = new MatTableDataSource<StatusResultIncome>(this.statusResultIncome);

		this.updateStatusResultData();
	}

	onSubmitStatusResultExpense() {
		this.submitedExpense = true;

		if (this.statusResultExpenseForm.invalid || this.getIsDisabledSave()) {
			return;
		}

		this.addStatusResultExpense();
	}

	onDeleteStatusResultExpense(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}

		this.statusResultExpense.splice(index, 1);
		this.dsStatusResultExpenseData = new MatTableDataSource<StatusResultExpense>(this.statusResultExpense);

		this.updateStatusResultData();
	}

	addStatusResultExpense(): void {
		this.statusResultExpense.push(<StatusResultExpense>{
			expenseType: this.fStatusResultExpense['expenseType'].value,
			amount: parseFloat(this.fStatusResultExpense['amount'].value.replace("$", "")),
			startDate: this.fStatusResultExpense['startDate'].value,
			endDate: this.fStatusResultExpense['endDate'].value,
		});

		this.dsStatusResultExpenseData = new MatTableDataSource<StatusResultExpense>(this.statusResultExpense);

		this.updateStatusResultData();
	}

	panelStatusOpen(e) {
		this.panelStatusState = e;
	}

	panelIncomeOpen(e) {
		this.panelIncomeState = e;
	}

	panelExpenseOpen(e) {
		this.panelExpenseState = e;
	}

	updateStatusResultData() {
		var loanRequestAdvanceOptionsData = <LoanRequestOptionsData>{
			statusResultIncome: this.statusResultIncome,
			statusResultExpense: this.statusResultExpense
		};

		this.statusResultData = loanRequestAdvanceOptionsData;
	}

	getStatusResultExpenseTotal() {
		return this.dsStatusResultExpenseData.data.map(x => x.amount).reduce((acc, value) => acc + value, 0);
	}

	getStatusResultIncomeTotal() {
		return this.dsStatusResultIncomeData.data.map(x => x.amount).reduce((acc, value) => acc + value, 0);
	}

	onStartDateChange() {
		this.fStatusResultExpense['startDate'].patchValue(this.fStatusResultIncome['startDate'].value);
	}

	onEndDateChange() {
		this.fStatusResultExpense['endDate'].patchValue(this.fStatusResultIncome['endDate'].value);
	}

	validateRequiredFormIncome(formControlName: string): boolean {
		if (this.fStatusResultIncome[formControlName] == null) {
			return;
		}

		if (this.fStatusResultIncome[formControlName].errors !== null && this.fStatusResultIncome[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateRequiredFormExpense(formControlName: string): boolean {
		if (this.fStatusResultExpense[formControlName] == null) {
			return;
		}

		if (this.fStatusResultExpense[formControlName].errors !== null && this.fStatusResultExpense[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	getIsDisabledSave() {
		if (this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) {
			return true;
		} else {
			return false;
		}
	}
}
