import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertGlobalService } from '../alert-global/alert-global.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ValidateAccountService {

  private email$ = new BehaviorSubject<string>(null);
  private validationSuccess$ = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService,
              private alertGlobalService: AlertGlobalService) { }

  getEmailObserver(): Observable<string> {
    return this.email$.asObservable();
  }

  getValidationSuccessObserver(): Observable<boolean> {
    return this.validationSuccess$.asObservable();
  }

  validateEmail(email: string, code: string) {
    const req = { email: email, code: code };
    return this.apiService.post('user', 'validateEmailCode', req).pipe(map(response => {
      if (response.status === 200) {
        this.validationSuccess$.next(true);
        this.alertGlobalService.showAlertSuccess("Validación exitosa.");
      }
      return response.status;
    }));
  }

  sendValidateEmailCode(email: string) {
    const req = { email: email };
    return this.apiService.post('user', 'requestValidateEmailCode', req).pipe(map(response => {
      if (response.status === 200) {
        this.validationSuccess$.next(false);
        this.alertGlobalService.showAlertSuccess("El código se ha enviado.");
      }
      return response.status;
    }));
  }

  setEmailObserver(email: string): void {
    this.validationSuccess$.next(false);
    return this.email$.next(email);
  }
}
