import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BalanceSheetActive, LoanRequestOptionsData, BalanceSheetPassive, BalanceSheetCapital } from 'src/app/models/loanrequest/loanrequest.model';
import { GlobalLists } from 'src/app/global/global-data';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-dialog-balance-sheet-data',
	templateUrl: './dialog-balance-sheet-data.component.html',
	styleUrls: ['./dialog-balance-sheet-data.component.scss']
})

export class DialogBalanceSheetDataComponent implements OnInit {
	balanceSheetActiveColumns = [
		'bienType',
		'amount',
		'courtDate',
		'buttonRemove'
	];

	balanceSheetPassiveColumns = [
		'passiveType',
		'amount',
		'courtDate',
		'buttonRemove'
	];

	balanceSheetCapitalColumns = [
		'capitalType',
		'amount',
		'courtDate',
		'buttonRemove'
	];

	submitedActive = false
	submitedPassive = false
	submitedCapital = false

	panelBalanceSheetState = false;
	panelActiveState = false;
	panelPassiveState = false;
	panelCapitalState = false;
	activiteList = GlobalLists.bienTypeList;
	passiveTypeList = GlobalLists.passiveTypeList;
	capitalTypeList = GlobalLists.capitalTypeList;
	currencyMask = GlobalLists.currencyMask;

	balanceSheetActiveForm: FormGroup;
	balanceSheetPassiveForm: FormGroup;
	balanceSheetCapitalForm: FormGroup;
	balanceSheetActive = <BalanceSheetActive[]>[];
	balanceSheetPassive = <BalanceSheetPassive[]>[];
	balanceSheetCapital = <BalanceSheetCapital[]>[];
	balanceSheetData: LoanRequestOptionsData;
	dsBalanceSheetActiveData = new MatTableDataSource<BalanceSheetActive>(this.balanceSheetActive);
	dsBalanceSheetPassiveData = new MatTableDataSource<BalanceSheetPassive>(this.balanceSheetPassive);
	dsBalanceSheetCapitalData = new MatTableDataSource<BalanceSheetCapital>(this.balanceSheetCapital);

	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private datePipe: DatePipe,
		private loanrequestService: LoanrequestService
	) {
	}

	isLender = this.authService.currentUserModeLenderValue;

	get fBalanceSheetActive() { return this.balanceSheetActiveForm.controls; }
	get fBalanceSheetPassive() { return this.balanceSheetPassiveForm.controls; }
	get fBalanceSheetCapital() { return this.balanceSheetCapitalForm.controls; }

	ngOnInit(): void {
		this.createBalanceSheetActiveForm();
		this.createBalanceSheetPassiveForm();
		this.createBalanceSheetCapitalForm();

		this.loanrequestService.getBalanceSheetData(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.balanceSheetActive = result.balanceSheetActive;
				this.balanceSheetPassive = result.balanceSheetPassive;
				this.balanceSheetCapital = result.balanceSheetCapital;

				this.dsBalanceSheetActiveData = new MatTableDataSource<BalanceSheetActive>(this.balanceSheetActive);
				this.dsBalanceSheetPassiveData = new MatTableDataSource<BalanceSheetPassive>(this.balanceSheetPassive);
				this.dsBalanceSheetCapitalData = new MatTableDataSource<BalanceSheetCapital>(this.balanceSheetCapital);

				this.updateBalanceSheetData();
			}
		});

		if (this.completed) {
			this.dr = true;
		}
	}

	createBalanceSheetActiveForm() {
		this.balanceSheetActiveForm = this.formBuilder.group({
			bienType: ['', Validators.required],
			amount: [0, Validators.required],
			courtDate: ['', Validators.required]
		});
	}

	createBalanceSheetPassiveForm() {
		this.balanceSheetPassiveForm = this.formBuilder.group({
			passiveType: ['', Validators.required],
			amount: [0, Validators.required],
			courtDate: [{ value: '', disabled: true }, Validators.required]
		});
	}

	createBalanceSheetCapitalForm() {
		this.balanceSheetCapitalForm = this.formBuilder.group({
			capitalType: ['', Validators.required],
			amount: [null, Validators.required],
			courtDate: [{ value: '', disabled: true }, Validators.required]
		});
	}

	onSubmitBalanceSheetActive() {
		this.submitedActive = true;

		if (this.balanceSheetActiveForm.invalid || this.getIsDisabledSave()) {
			return;
		}

		this.addBalanceSheetActive();
	}

	onDeleteBalanceSheetActive(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}
		
		this.balanceSheetActive.splice(index, 1);
		this.dsBalanceSheetActiveData = new MatTableDataSource<BalanceSheetActive>(this.balanceSheetActive);

		this.updateBalanceSheetData();
	}

	addBalanceSheetActive(): void {
		this.balanceSheetActive.push(<BalanceSheetActive>{
			bienType: this.fBalanceSheetActive['bienType'].value,
			amount: parseFloat((this.fBalanceSheetActive['amount'].value + "").replace("$", "")),
			courtDate: this.fBalanceSheetActive['courtDate'].value,
		});

		this.dsBalanceSheetActiveData = new MatTableDataSource<BalanceSheetActive>(this.balanceSheetActive);
		this.fBalanceSheetActive['bienType'].patchValue('');
		this.fBalanceSheetActive['amount'].patchValue(null);

		this.updateBalanceSheetData();
	}

	onSubmitBalanceSheetPassive() {
		this.submitedPassive = true;

		if (this.balanceSheetPassiveForm.invalid || this.getIsDisabledSave()) {
			return;
		}

		this.addBalanceSheetPassive();
	}

	onDeleteBalanceSheetPassive(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}
		
		this.balanceSheetPassive.splice(index, 1);
		this.dsBalanceSheetPassiveData = new MatTableDataSource<BalanceSheetPassive>(this.balanceSheetPassive);

		this.updateBalanceSheetData();
	}

	addBalanceSheetPassive(): void {
		this.balanceSheetPassive.push(<BalanceSheetPassive>{
			passiveType: this.fBalanceSheetPassive['passiveType'].value,
			amount: parseFloat((this.fBalanceSheetPassive['amount'].value + "").replace("$", "")),
			courtDate: this.fBalanceSheetPassive['courtDate'].value,
		});

		this.dsBalanceSheetPassiveData = new MatTableDataSource<BalanceSheetPassive>(this.balanceSheetPassive);
		this.fBalanceSheetPassive['passiveType'].patchValue('');
		this.fBalanceSheetPassive['amount'].patchValue(null);

		this.updateBalanceSheetData();
	}

	onSubmitBalanceSheetCapital() {
		this.submitedCapital = true;

		if (this.balanceSheetCapitalForm.invalid || this.getIsDisabledSave()) {
			return;
		}

		this.addBalanceSheetCapital();
	}

	onDeleteBalanceSheetCapital(index: number): void {
		if (this.getIsDisabledSave()) {
		  return;
		}
		
		this.balanceSheetCapital.splice(index, 1);
		this.dsBalanceSheetCapitalData = new MatTableDataSource<BalanceSheetCapital>(this.balanceSheetCapital);

		this.updateBalanceSheetData();
	}

	addBalanceSheetCapital(): void {
		this.balanceSheetCapital.push(<BalanceSheetCapital>{
			capitalType: this.fBalanceSheetCapital['capitalType'].value,
			amount: parseFloat((this.fBalanceSheetCapital['amount'].value + "").replace("$", "")),
			courtDate: this.fBalanceSheetCapital['courtDate'].value,
		});

		this.dsBalanceSheetCapitalData = new MatTableDataSource<BalanceSheetCapital>(this.balanceSheetCapital);
		this.fBalanceSheetCapital['capitalType'].patchValue('');
		this.fBalanceSheetCapital['amount'].patchValue(null);

		this.updateBalanceSheetData();
	}

	panelBalanceSheetOpen(e) {
		this.panelBalanceSheetState = e;
	}

	panelActiveOpen(e) {
		this.panelActiveState = e;
	}

	panelPassiveOpen(e) {
		this.panelPassiveState = e;
	}

	panelCapitalOpen(e) {
		this.panelCapitalState = e;
	}

	updateBalanceSheetData() {
		var pat = <LoanRequestOptionsData>{
			balanceSheetActive: this.balanceSheetActive,
			balanceSheetPassive: this.balanceSheetPassive,
			balanceSheetCapital: this.balanceSheetCapital
		};

		this.balanceSheetData = pat;
	}

	getBalanceSheetActiveTotal() {
		return this.dsBalanceSheetActiveData.data.map(x => x.amount).reduce((acc, value) => acc + value, 0);
	}

	getBalanceSheetPassiveTotal() {
		return this.dsBalanceSheetPassiveData.data.map(x => x.amount).reduce((acc, value) => acc + value, 0);
	}

	getalanceSheetCapitaTotal() {
		return this.dsBalanceSheetCapitalData.data.map(x => x.amount).reduce((acc, value) => acc + value, 0);
	}

	onDateChange() {
		if (this.fBalanceSheetActive['courtDate'].value == "") {
			this.fBalanceSheetPassive['courtDate'].patchValue("");
			this.fBalanceSheetCapital['courtDate'].patchValue("");
		} else {
			this.fBalanceSheetPassive['courtDate'].patchValue(this.datePipe.transform(this.fBalanceSheetActive['courtDate'].value, 'yyyy-MM-dd'));
			this.fBalanceSheetCapital['courtDate'].patchValue(this.datePipe.transform(this.fBalanceSheetActive['courtDate'].value, 'yyyy-MM-dd'));
		}
	}

	validateRequiredFormActive(formControlName: string): boolean {
		if (this.fBalanceSheetActive[formControlName] == null) {
			return;
		}

		if (this.fBalanceSheetActive[formControlName].errors !== null && this.fBalanceSheetActive[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateRequiredFormPassive(formControlName: string): boolean {
		if (this.fBalanceSheetPassive[formControlName] == null) {
			return;
		}

		if (this.fBalanceSheetPassive[formControlName].errors !== null && this.fBalanceSheetPassive[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateRequiredFormCapital(formControlName: string): boolean {
		if (this.fBalanceSheetCapital[formControlName] == null) {
			return;
		}

		if (this.fBalanceSheetCapital[formControlName].errors !== null && this.fBalanceSheetCapital[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	getIsDisabledSave() {
		if (this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) {
			return true;
		} else {
			return false;
		}
	}
}
