<form [formGroup]="economicActivityJsonForm">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelEconomicActivityState" class="mb-4 bg-white"
            (opened)="panelEconomicActivityOpen(true)" (closed)="panelEconomicActivityOpen(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <h5>Datos actividad económica</h5>
                        <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-accordion *ngIf="occupation.includes(0)">
                <mat-expansion-panel [expanded]="panelEconomicActivityLabState" class="mb-4"
                    (opened)="panelEconomicActivitLabyOpen(true)" (closed)="panelEconomicActivitLabyOpen(false)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>Laborales / empleo</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div *ngIf="validateFormrequest('CompanyName')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-CompanyName" class="">Nombre de empresa<span
                                        *ngIf="validateFormrequestJson('CompanyName')">*</span></label>
                                <input type="text" formControlName="value-CompanyName" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-CompanyName') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabSector')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabSector" class="">Sector<span
                                        *ngIf="validateFormrequestJson('LabSector')">*</span></label>
                                <select class="form-control" formControlName="value-LabSector"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabSector') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of economicActivitySectorList" value="{{item.key}}">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabPosition')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabPosition" class="">Puesto / cargo<span
                                        *ngIf="validateFormrequestJson('LabPosition')">*</span></label>
                                <input type="text" formControlName="value-LabPosition" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabPosition') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabAdmissionDate')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="LabAdmissionDate">Fecha de ingreso</label>
                                <input autofocus="autofocus" class="form-control"
                                    formControlName="value-LabAdmissionDate" type="date"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabAdmissionDate') ? 'is-invalid' : ''" />
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabEmployeeNumber')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabEmployeeNumber" class="">Número de empleado<span
                                        *ngIf="validateFormrequestJson('LabEmployeeNumber')">*</span></label>
                                <input type="text" formControlName="value-LabEmployeeNumber" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabEmployeeNumber') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabArea')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabArea" class="">Área/ Departamento<span
                                        *ngIf="validateFormrequestJson('LabArea')">*</span></label>
                                <input type="text" formControlName="value-LabArea" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabArea') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabBoss')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabBoss" class="">Nombre Jefe Inmediato<span
                                        *ngIf="validateFormrequestJson('LabBoss')">*</span></label>
                                <input type="text" formControlName="value-LabBoss" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabBoss') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabBossPosition')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabBossPosition" class="">Puesto/ Cargo Jefe Inmediato<span
                                        *ngIf="validateFormrequestJson('LabBossPosition')">*</span></label>
                                <input type="text" formControlName="value-LabBossPosition" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabBossPosition') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabStreet')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabStreet" class="">Calle<span
                                        *ngIf="validateFormrequestJson('LabStreet')">*</span></label>
                                <input type="text" formControlName="value-LabStreet" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabStreet') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabExtNumber')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabExtNumber" class="">Número exterior<span
                                        *ngIf="validateFormrequestJson('LabExtNumber')">*</span></label>
                                <input type="text" formControlName="value-LabExtNumber" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabExtNumber') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabIntNumber')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabIntNumber" class="">Número interior<span
                                        *ngIf="validateFormrequestJson('LabIntNumber')">*</span></label>
                                <input type="text" formControlName="value-LabIntNumber" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabIntNumber') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabDistrictName')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabDistrictName" class="">Colonia<span
                                        *ngIf="validateFormrequestJson('LabDistrictName')">*</span></label>
                                <input type="text" formControlName="value-LabDistrictName" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabDistrictName') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabCountryId')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabCountryId">País <span
                                        *ngIf="validateFormrequestJson('LabCountryId')">*</span></label>
                                <select #selectCountry class="form-control" formControlName="value-LabCountryId"
                                    (change)="onChangeSelectCountriesRequest(selectCountry.value)"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabCountryId') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of countries" value="{{item.code}}"> {{item.name}}</option>
                                    <option value="Otro">Otro</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabStateId')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabStateId">Estado <span
                                        *ngIf="validateFormrequestJson('LabStateId')">*</span></label>
                                <select #selectState class="form-control" formControlName="value-LabStateId"
                                    (change)="onChangeSelectStatesRequest(selectState.value)"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabStateId') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of states" value="{{item.code}}"> {{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabCityId')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabCityId">Municipio <span
                                        *ngIf="validateFormrequestJson('LabCityId')">*</span></label>
                                <select class="form-control" formControlName="value-LabCityId"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabCityId') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of cities" value="{{item.code}}"> {{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('LabZipCode')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-LabZipCode">Código postal <span
                                        *ngIf="validateFormrequestJson('LabZipCode')">*</span></label>
                                <input type="number" formControlName="value-LabZipCode" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-LabZipCode') ? 'is-invalid' : ''">
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion *ngIf="occupation.includes(1)">
                <mat-expansion-panel [expanded]="panelEconomicActivityBusState"
                    (opened)="panelEconomicActivityBusOpen(true)" (closed)="panelEconomicActivityBusOpen(false)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>Negocio</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div *ngIf="validateFormrequest('BusinessName')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusinessName" class="">Nombre de negocio<span
                                        *ngIf="validateFormrequestJson('BusinessName')">*</span></label>
                                <input type="text" formControlName="value-BusinessName" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusinessName') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusSector')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusSector" class="">Sector<span
                                        *ngIf="validateFormrequestJson('BusSector')">*</span></label>
                                <select class="form-control" formControlName="value-BusSector"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusSector') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of economicActivitySectorList" value="{{item.key}}">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusPosition')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusPosition" class="">Puesto / cargo<span
                                        *ngIf="validateFormrequestJson('BusPosition')">*</span></label>
                                <input type="text" formControlName="value-BusPosition" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusPosition') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusAntiquity')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="BusAntiquity">Fecha de inicio</label>
                                <input autofocus="autofocus" class="form-control" formControlName="value-BusAntiquity"
                                    type="date"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusAntiquity') ? 'is-invalid' : ''" />
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusType')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusType" class="">Tipo de negocio<span
                                        *ngIf="validateFormrequestJson('BusType')">*</span></label>
                                <select class="form-control" formControlName="value-BusType"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusType') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of economicActivityBusTypeList" value="{{item.key}}">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusRFC')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusRFC" class="">RFC negocio<span
                                        *ngIf="validateFormrequestJson('BusRFC')">*</span></label>
                                <input type="text" formControlName="value-BusRFC" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusRFC') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusForm')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusForm" class="">Forma de negocio<span
                                        *ngIf="validateFormrequestJson('BusForm')">*</span></label>
                                <select class="form-control" formControlName="value-BusForm"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusForm') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of economicActivityBusFormList" value="{{item.key}}">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusEmployees')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusEmployees" class="">Número empleados<span
                                        *ngIf="validateFormrequestJson('BusEmployees')">*</span></label>
                                <input type="number" formControlName="value-BusEmployees" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusEmployees') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusStreet')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusStreet" class="">Calle<span
                                        *ngIf="validateFormrequestJson('BusStreet')">*</span></label>
                                <input type="text" formControlName="value-BusStreet" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusStreet') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusExtNumber')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusExtNumber" class="">Número exterior<span
                                        *ngIf="validateFormrequestJson('BusExtNumber')">*</span></label>
                                <input type="text" formControlName="value-BusExtNumber" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusExtNumber') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusIntNumber')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusIntNumber" class="">Número interior<span
                                        *ngIf="validateFormrequestJson('BusIntNumber')">*</span></label>
                                <input type="text" formControlName="value-BusIntNumber" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusIntNumber') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusDistrictName')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusDistrictName" class="">Colonia<span
                                        *ngIf="validateFormrequestJson('BusDistrictName')">*</span></label>
                                <input type="text" formControlName="value-BusDistrictName" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusDistrictName') ? 'is-invalid' : ''">
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusCountryId')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusCountryId">País <span
                                        *ngIf="validateFormrequestJson('BusCountryId')">*</span></label>
                                <select #selectCountryBus class="form-control" formControlName="value-BusCountryId"
                                    (change)="onChangeSelectCountriesBusRequest(selectCountryBus.value)"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusCountryId') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of countries" value="{{item.code}}"> {{item.name}}</option>
                                    <option value="Otro">Otro</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusStateId')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusStateId">Estado <span
                                        *ngIf="validateFormrequestJson('BusStateId')">*</span></label>
                                <select #selectStateBus class="form-control" formControlName="value-BusStateId"
                                    (change)="onChangeSelectStatesBusRequest(selectStateBus.value)"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusStateId') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of statesBus" value="{{item.code}}"> {{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusCityId')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusCityId">Municipio <span
                                        *ngIf="validateFormrequestJson('BusCityId')">*</span></label>
                                <select class="form-control" formControlName="value-BusCityId"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusCityId') ? 'is-invalid' : ''">
                                    <option value="">Seleccione</option>
                                    <option *ngFor="let item of citiesBus" value="{{item.code}}"> {{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="validateFormrequest('BusZipCode')" class="col-md-3 mb-3">
                            <div class="form-group">
                                <label for="value-BusZipCode">Código postal <span
                                        *ngIf="validateFormrequestJson('BusZipCode')">*</span></label>
                                <input type="number" formControlName="value-BusZipCode" class="form-control"
                                    [ngClass]="dr && validateRequiredFormF8('value-BusZipCode') ? 'is-invalid' : ''">
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-expansion-panel>
    </mat-accordion>
</form>