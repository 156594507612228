import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { InputError, InputErrorsGlobalService } from '../../services/input-errors-global/input-errors-global.service';

@Component({
  selector: 'app-input-errors-global',
  templateUrl: './input-errors-global.component.html',
  styleUrls: ['./input-errors-global.component.scss']
})
export class InputErrorsGlobalComponent implements OnInit {

  @Input() fieldName: string;
  
  errors: InputError[];

  constructor(private inputErrorsGlobalService: InputErrorsGlobalService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.inputErrorsGlobalService.getInputErrorsObserver().subscribe((inputErrors) => {
      if (inputErrors.show) {
        this.errors = inputErrors.errors;
      }      
      this.cdRef.detectChanges();
    });
  }
}