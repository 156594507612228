import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-loan-request-form',
  templateUrl: './dialog-loan-request-form.component.html',
  styleUrls: ['./dialog-loan-request-form.component.scss']
})
export class DialogLoanRequestFormComponent implements OnInit {

  loanRequestId;
  borrowerCustomerId;
  
  constructor() { }

  ngOnInit(): void {
  }

}
