import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { subscriptionpaymentDTO } from 'src/app/models/subscriptionpayment/subscriptionpayment.dto.model';
import { Balance, CalculatePrice, Cart, cfdiUse, FiscalRegime, SendCalculatePrice, SendCalculatePriceBalance, Subscriptionpayment, SubscriptionSeachLoan } from 'src/app/models/subscriptionpayment/subscriptionpayment.model';
import { ApiService } from '../api/api.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { AlertGlobalService } from '../../services/alert-global/alert-global.service';
import { plansDTO } from 'src/app/models/plans/plans.dto.model';
import { MethodsPaymentDTO } from 'src/app/models/methodspayment/methodspayment.dto.model';
import { billDTO } from 'src/app/models/bill/bill.dto.model';
import { billGetProduct, billProduct, billResponse } from 'src/app/models/bill/bill.model';
import { AppComponent } from 'src/app/app.component';

@Injectable({
	providedIn: 'root'
})
export class SubscriptionpaymentService {
	private appComponent: AppComponent;

	constructor(private apiService: ApiService,
		private authService: AuthenticationService,
		private alertGlobal: AlertGlobalService) { }

	setAppComponent(appComponent: AppComponent): void {
		this.appComponent = appComponent;
	}

	updateCarList(cartItems: number): void {
		if (this.appComponent) {
			this.appComponent.updateCarList(cartItems);
		}
	}

	getCarListItems(): number {
		return this.appComponent.getCarListItems();
	}

	createSubscriptionPayment(subscriptionpayment: Subscriptionpayment, customerId: string, methodPayment: string) {
		return this.apiService.post('subscriptionpayment', 'customerId/' + customerId + "/" + methodPayment, subscriptionpayment).pipe(map(response => {
			if (response.status === 201) {
				this.alertGlobal.showAlertSuccess("Pago en proceso");
				return response.data as String;
			}
		}));
	}

	// getLoanPaymentsWithLoanId(isLender: boolean, customerId: string, loanId: string) {
	//   return this.apiService.get('loanpayment', 'payments/' + (isLender ? 'true' : 'false') + '/' + customerId + '/' + loanId)
	//   .pipe(map(response => {
	//     return response.data as subscriptionpaymentDTO[];
	//   }));
	// }

	getSubscriptionPayments(bill: boolean, idService: number) {
		return this.apiService.get('subscriptionpayment', '' + (bill ? 'true' : 'false') + '/' + idService).pipe(map(response => {
			return response.data as subscriptionpaymentDTO[];
		}));
	}

	// deleteLoanPayment(loanPaymentId: string) {
	//   return this.apiService.delete('loanpayment', '', loanPaymentId)
	//   .pipe(map(response => {
	//     return response.data as String;
	//   }));
	// }

	getPlans() {
		return this.apiService.get('subscriptionpayment/plans').pipe(map(response => {
			return response.data as plansDTO[];
		}));
	}

	getPlansBalance() {
		return this.apiService.get('subscriptionpayment/plans/getPlansBalance').pipe(map(response => {
			return response.data as plansDTO[];
		}));
	}

	getMethodsPayment() {
		return this.apiService.get('subscriptionpayment/methodsPayment').pipe(map(response => {
			return response.data as MethodsPaymentDTO[];
		}));
	}

	getCurrentSubscription() {
		return this.apiService.get('subscriptionpayment/currentSubscription').pipe(map(response => {
			return response.data as subscriptionpaymentDTO;
		}));
	}

	searchOrderOxxo(orderId: string) {
		return this.apiService.get('subscriptionpayment/searchOrderOxxo', orderId).pipe(map(response => {
			return response.data as MethodsPaymentDTO[];
		}));
	}

	createBill(subscriptionpayment: subscriptionpaymentDTO, methodPayment: string) {
		return this.apiService.post('subscriptionpayment', 'bills/createBill/' + methodPayment, subscriptionpayment).pipe(map(response => {
			return response.data as billResponse;
		}));
	}

	sendCFDIsByEmail(cfdiCreatedId: string, email: string) {
		return this.apiService.post('subscriptionpayment', 'sendCFDIsByEmail/' + cfdiCreatedId + '/' + email).pipe(map(response => {
			if (response.status === 201)
				this.alertGlobal.showAlertSuccess("Factura generada con éxito.");
			return response.data as String;
		}));
	}

	cancelCFDIs(billId: string, cfdiCreatedId: string) {
		return this.apiService.post('subscriptionpayment', 'bills/cancelCFDIs/' + billId + "/" + cfdiCreatedId).pipe(map(response => {
			return response.data as billResponse;
		}));
	}

	getBills() {
		return this.apiService.get('subscriptionpayment', 'bills/bills').pipe(map(response => {
			return response.data as billDTO[];
		}));
	}

	getSubscriptionsBills(bill: boolean, idService: number) {
		return this.apiService.get('subscriptionpayment', '' + (bill ? 'true' : 'false') + '/' + idService).pipe(map(response => {
			return response.data as subscriptionpaymentDTO[];
		}));
	}

	getSearchProduct(billGetProduct: billGetProduct) {
		return this.apiService.post('subscriptionpayment', 'bills/searchProduct', billGetProduct).pipe(map(response => {
			return response.data as billProduct;
		}));
	}

	getSearchLoan(subscriptionId: string) {
		return this.apiService.get('subscriptionpayment', 'SearchLoansBySubscription/' + subscriptionId).pipe(map(response => {
			return response.data as SubscriptionSeachLoan[];
		}));
	}

	deleteSubscription(subscriptionId: string) {
		return this.apiService.delete('subscriptionpayment', 'deleteSubscription/' + subscriptionId).pipe(map(response => {
			return response.data as SubscriptionSeachLoan[];
		}));
	}

	getCalculatePlanPrice(calculatePrice: SendCalculatePrice) {
		return this.apiService.post('subscriptionpayment', 'calculatePlanPrice', calculatePrice).pipe(map(response => {
			return response.data as CalculatePrice;
		}));
	}

	getFiscalRegimeList() {
		return this.apiService.get('subscriptionpayment/bills', 'getFiscalRegimeList').pipe(map(response => {
			return response.data as FiscalRegime[];
		}));
	}

	getCfdiUseList(rfc: string) {
		return this.apiService.get('subscriptionpayment/bills', 'getCfdiUseList/' + rfc).pipe(map(response => {
				return response.data as cfdiUse[];
		}));
	}

	getBalanceMovements() {
		return this.apiService.get('subscriptionpayment', 'getBalanceMovements')
			.pipe(map(response => {
				return response.data as Balance;
			}));
	}

	getCalculatePlanPriceBalance(calculatePrice: SendCalculatePriceBalance) {
		return this.apiService.post('subscriptionpayment', 'calculatePlanPriceBalance', calculatePrice).pipe(
			map(
				response => {
					return response.data as CalculatePrice;
				}
			)
		);
	}

	getCartList() {
		return this.apiService.get('subscriptionpayment', 'getCarList').pipe(
			map(
				response => {
					return response.data as Cart;
				}
			)
		);
	}

	deleteItem(externalServiceId: string, typeExternalService: number) {
		return this.apiService.post('subscriptionpayment', 'deleteItemCarList/' + externalServiceId + "/" + typeExternalService).pipe(
			map(
				response => {
					return response.data as Cart;
				}
			)
		);
	}

	payCartList() {
		return this.apiService.post('subscriptionpayment', 'payCarList/').pipe(
			map(
				response => {
					return response.data as String;
				}
			)
		);
	}

	getExternalServicesList() {
		return this.apiService.get('subscriptionpayment/getExternalServicesList').pipe(
			map(
				response => {
					return response.data as string[];
				}
			)
		);
	}

	getBillsAvailables() {
		return this.apiService.get('subscriptionpayment', 'getBillsAvailables').pipe(
			map(
				response => {
					return response.data as subscriptionpaymentDTO[];
				}
			)
		);
	}
}
