import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-loanrequest',
  templateUrl: './loanrequest.component.html',
  styleUrls: ['./loanrequest.component.scss']
})
export class LoanrequestComponent implements OnInit {

  loanRequestId: string = "";
  tabIndex: number = 0;

  tabTitle = "";
  isLender = false;

  constructor(private route: ActivatedRoute,
              private authService: AuthenticationService) { }

  ngOnInit(): void {

    this.isLender = this.authService.currentUserModeLenderValue;
    // if (this.authService.currentUserModeLenderValue) {
    //   this.tabTitle = "En proceso";
    // } else {
    //   this.tabTitle = "En proceso";
    // }

    // this.loanRequestId = this.route.snapshot.paramMap.get('loanRequestId');
    // if(this.loanRequestId) {
    //   this.tabIndex = 1;
    // }
  }
}
