<button mat-stroked-button mat-dialog-close><mat-icon>close</mat-icon> Cerrar</button>
<h1 mat-dialog-title>Tabla de pagos <span (click)="showHelp('paytable')" class="material-icons button-help">help</span></h1>

<div mat-dialog-content>        
    <app-amortization-table id="htmlData" [data]="data" [hiddenPaymentsColumn]="hiddenPaymentsColumn" [disabledPaymentsColumn]="disabledPaymentsColumn"></app-amortization-table>
</div>
<div></div>
<div *ngIf="dataExport">
    <button mat-stroked-button (click)="print()"><mat-icon>print</mat-icon> Imprimir</button>
    <button mat-stroked-button (click)="exportPDF()"><mat-icon>cloud_download</mat-icon> Exportar PDF</button>
    <button mat-stroked-button (click)="exportExcel()"><mat-icon>save_alt</mat-icon> Descargar Excel</button>
</div>
