<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-3 col-md-5">
          <div class="d-flex">
            <div class="me-3">
              <img src="{{ borrowerProfilePicture }}" class="avatar-md rounded-circle img-thumbnail"
                alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
            </div>
            <div class="flex-grow-1 align-self-center">
              <div class="text-muted">
                <p class="mb-2" *ngIf="!isLender">
                  Prestamista
                </p>
                <p class="mb-2" *ngIf="isLender">
                  Solicitante
                </p>
                <h5 class="mb-0">
                  {{borrowerName}}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="align-self-center col-lg-6 col-md-7">
          <div class="mt-4 mt-md-0">
            <div class="row">
              <div class="col-sm-4 col-12 mb-2 mb-md-0">
                <div>
                  <p class="text-muted text-truncate mb-2">Fecha Solicitud</p>
                  <h5 class="mb-0">{{startDate | date}}</h5>
                </div>
              </div>
              <div class="col-sm-4 col-12 mb-2 mb-md-0">
                <div>
                  <p class="text-muted text-truncate mb-2">Monto</p>
                  <h5 class="mb-0">{{totalAmortize | currency}}</h5>
                </div>
              </div>
              <div class="col-sm-4 col-12">
                <div>
                  <p class="text-muted text-truncate mb-2">Estatus</p>
                  <h5 class="mb-0">{{statusName}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none col-lg-3">
          <div class="clearfix mt-4 mt-lg-0">
            <div class="float-end">
              <button class="btn btn-primary" routerLink="/mi-perfil">
                Actualiza tu perfil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <mat-horizontal-stepper class="loanRequestResume" [selectedIndex]="steptIndex" #stepperLoanRequest
        labelPosition="bottom" (selectionChange)="onStepChange($event)" [@.disabled]="false" [linear]="true">
        <ng-template matStepperIcon="edit">
          <i class="bx bx-check"></i>
        </ng-template>
        <ng-template matStepperIcon="active">
          <i class="bx bx-check"></i>
        </ng-template>
        <mat-step [stepControl]="loanrequestDetailForm">
          <ng-template matStepLabel>Datos solicitud</ng-template>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Condiciones</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <h5>Detalle de Solicitud de Préstamo</h5>
                <p>De acuerdo a las condiciones definidas por el Usuario</p>
                <div class="d-flex justify-content-between">
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Fecha</p>
                      <h5 class="mb-0">{{startDate | date}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">ID Solicitud</p>
                      <h5 class="mb-0">{{idRequest}}</h5>
                    </div>
                  </div>
                </div>
                <hr>

                <h5 class="text-center mb-4">PARTICIPANTES</h5>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Nombre Solicitante</p>
                    <h5 class="mb-0">{{borrowerName}}</h5>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Correo electrónico</p>
                    <h5 class="mb-0">{{borrowerMail}}</h5>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Teléfono celular</p>
                    <h5 class="mb-0">{{borrowerPhoneMobile}}</h5>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Nombre Prestamista</p>
                    <h5 class="mb-0">{{lenderCustomerFullName}}</h5>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Correo electrónico</p>
                    <h5 class="mb-0">{{lenderEmail}}</h5>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Teléfono celular</p>
                    <h5 class="mb-0">{{lenderPhoneMobile}}</h5>
                  </div>
                </div>
                <hr>

                <h5 class="text-center mb-4">CONDICIONES</h5>
                <ng-container *ngIf="!modifydata">
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Monto del préstamo</p>
                      <h5 class="mb-0">{{totalAmortize | currency}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Tipo de préstamo</p>
                      <h5 class="mb-0">{{typeOfCredit}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Fecha de Inicio</p>
                      <h5 class="mb-0">{{startDate | date}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Fecha del primer pago</p>
                      <h5 class="mb-0">{{startPayment | date}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Cálculo de intereses</p>
                      <h5 class="mb-0">{{typeOfAmortization}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Frecuencia de pagos</p>
                      <h5 class="mb-0">{{typeOfPeriodDesc}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Período del primer pago</p>
                      <h5 class="mb-0">{{periodAmortizeStart}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Número de pagos</p>
                      <h5 class="mb-0">{{getTotalPeriods()}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Tipo de Garantía</p>
                      <h5 class="mb-0">{{warrantys}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Plazo del préstamo</p>
                      <h5 class="mb-0">{{getTotalPeriods()}} {{typeOfPeriodDesc}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Interés moratorio mensual</p>
                      <h5 class="mb-0">{{monthlyPorcentOnArrears * 0.0100 | percent:'1.2-5'}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Interés mensual</p>
                      <h5 class="mb-0">{{monthlyRate * 0.0100 | percent:'1.2-5'}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">IVA</p>
                      <h5 class="mb-0">{{taxPorcent * 0.0100 | percent:'1.2-5'}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Pago periódico</p>
                      <h5 class="mb-0">{{periodicPayment == 'Variable' ?periodicPayment : periodicPayment | currency}}
                      </h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Comisiones</p>
                      <h5 class="mb-0">{{isCommission}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Monto Total a pagar</p>
                      <h5 class="mb-0">{{amortizeService.currentAmortizeTableValue.totalPeriodPay | currency}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div>
                      <p class="text-muted mb-1">Pagos personalizados</p>
                      <h5 class="mb-0">{{customPayments}}</h5>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end col-12 pt-2 mb-3">
                    <button (click)="setModifyData(true)" class="btn_primary_outline">
                      <a class="nav-link">Modificar datos</a>
                    </button>
                  </div>
                </ng-container>

                <ng-container *ngIf="modifydata">
                  <form class="form-horizontal" [formGroup]="loanrequestDetailForm">
                    <div class="w-100">
                      <div class="row">
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="Monto">Monto</label>
                            <input type="text" placeholder="$ 0" formControlName="LoanAmount"
                              class="form-control text-right"
                              [ngClass]="validateRequiredFormF1('LoanAmount') ? 'is-invalid' : ''"
                              [textMask]="{mask: currencyMask}">
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="TypeOfPeriod">Frecuencia de pagos</label>
                            <select class="form-control" formControlName="TypeOfPeriod"
                              [ngClass]="validateRequiredFormF1('TypeOfPeriod') ? 'is-invalid' : ''">
                              <option value="">Seleccione</option>
                              <option *ngFor="let item of typeOfPeriodList" value="{{item.key}}">{{item.value}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group"> <label for="TotalPeriods">Número de
                              pagos</label>
                            <input #totalPeriods type="text" (change)="onChangeTotalPeriods(totalPeriods.value)"
                              [textMask]="{mask: numberMask}" formControlName="TotalPeriods" inputmode="numeric"
                              class="form-control text-right"
                              [ngClass]="validateRequiredFormF1('TotalPeriods') ? 'is-invalid' : ''">
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="AnnualRate">Interés mensual</label>
                            <input type="text" placeholder="0 %" [textMask]="{mask: percentMask}"
                              formControlName="AnnualRate" inputmode="numeric" class="form-control text-right"
                              [ngClass]="validateRequiredFormF1('AnnualRate') ? 'is-invalid' : ''">
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="StartDate">Fecha de inicio</label>
                            <input autofocus="autofocus" class="form-control" formControlName="StartDate" type="date"
                              [ngClass]="validateRequiredFormF1('StartDate') ? 'is-invalid' : ''" />
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="StartPayment">Fecha del primer pago</label>
                            <input autofocus="autofocus" class="form-control" formControlName="StartPayment" type="date"
                              [ngClass]="validateRequiredFormF1('StartPayment') ? 'is-invalid' : ''" />
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="TypeOfCredit">Tipo de préstamo</label>
                            <select class="form-control" formControlName="TypeOfCredit"
                              [ngClass]="validateRequiredFormF1('TypeOfCredit') ? 'is-invalid' : ''">
                              <option value="">Seleccione</option>
                              <option *ngFor="let item of typeOfCreditList" value="{{item.key}}">{{item.value}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="TypeOfAmortization">Cálculo de interéses</label>
                            <select class="form-control" formControlName="TypeOfAmortization"
                              [ngClass]="validateRequiredFormF1('TypeOfAmortization') ? 'is-invalid' : ''">
                              <option value="">Seleccione</option>
                              <option *ngFor="let item of typeOfAmortizationList" value="{{item.key}}">{{item.value}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="MonthlyPorcentOnArrears">Interés moratorio mensual</label>
                            <input type="text" [textMask]="{mask: percentMask}"
                              formControlName="MonthlyPorcentOnArrears" class="form-control text-right"
                              [ngClass]="validateRequiredFormF1('MonthlyPorcentOnArrears') ? 'is-invalid' : ''">
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="PeriodAmortizeStart">Periodo inicial</label>
                            <select class="form-control" formControlName="PeriodAmortizeStart">
                              <option *ngFor="let item of periodAmortizeStartList" value="{{item.key}}">{{item.value}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="TaxPorcent">IVA</label>
                            <button class="btn btn-outline-primary w-100" type="button" (click)="openTax('new')">Ver
                              IVA</button>
                          </div>
                        </div>
                        <div class="col-md-4 btn_al mb-3">
                          <div class="form-group">
                            <label for="WarrantyDataJson">Garantías</label>
                            <button class="btn btn-outline-primary w-100" type="button"
                              (click)="openWarrantys('new')">Ver
                              garantias</button>
                          </div>
                        </div>
                        <div class="col-md-4 btn_al mb-3">
                          <div class="form-group">
                            <label for="Commissions">Comisiones</label>
                            <button class="btn btn-outline-primary w-100" type="button"
                              (click)="openComissions('new')">Ver
                              comisiones</button>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="PercentRetentionISR">Retención ISR</label>
                            <input type="text" [textMask]="{mask: percentMask}" placeholder="0 %"
                              formControlName="PercentRetentionISR" class="form-control text-right"
                              [ngClass]="validateRequiredFormF1('PercentRetentionISR') ? 'is-invalid' : ''">
                          </div>
                        </div>
                        <div class="col-md-4 btn_al mb-3">
                          <div class="form-group">
                            <label for="customPayment">Personalizar pagos</label>
                            <button class="btn btn-outline-primary w-100 disabled_btn" type="button"
                              disabled>Personalizar
                              pagos</button>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="PersonType">Tipo de Solicitante</label>
                            <select #selectPersonType class="form-control"
                              (change)="onchangeDataExpedient(true, selectPersonType.value, selectOccupation.value)"
                              formControlName="PersonType">
                              <option *ngFor="let item of personTypeList" value="{{item.key}}">{{item.value}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="occupation">{{occupationTittle}}</label>
                            <mat-form-field appearance="fill">
                              <mat-label>Seleccione</mat-label>
                              <mat-select #selectOccupation
                                (selectionChange)="onchangeDataExpedient(false, selectPersonType.value, selectOccupation.value)"
                                formControlName="Occupation"
                                [ngClass]="validateRequiredFormF1('Occupation') ? 'is-invalid' : ''" multiple>
                                <mat-option *ngFor="let item of getOccupationList(selectPersonType.value)"
                                  [value]="item.key"
                                  [disabled]="selectOccupationDisabled(selectPersonType.value, item.key)">
                                  {{item.value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="CreditDestination">Destino</label>
                            <select class="form-control" formControlName="CreditDestination"
                              [ngClass]="validateRequiredFormF1('CreditDestination') ? 'is-invalid' : ''">
                              <option value="">Seleccione</option>
                              <option *ngFor="let item of getCreditDestinations(selectPersonType.value)"
                                value="{{item.key}}">
                                {{item.value}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <div class="form-group">
                            <label for="LegalConditions">Forma de préstamo</label>
                            <select class="form-control" formControlName="LegalConditions">
                              <option *ngFor="let item of legalConditionsList" value="{{item.key}}">{{item.value}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div class="d-flex justify-content-end col-12 pt-2 mb-3">
                    <button (click)="setModifyData(false)" class="btn_primary_outline">
                      <a class="nav-link">Continuar</a>
                    </button>
                  </div>
                </ng-container>
                <hr>

                <h5 class="text-center mb-4">RESUMEN</h5>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
                  <div>
                    <p class="text-muted mb-1">Monto del préstamo</p>
                    <h5 class="mb-0">{{totalAmortize | currency}}</h5>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
                  <div>
                    <p class="text-muted mb-1">Monto Interés total</p>
                    <h5 class="mb-0">{{totalInterest | currency}}</h5>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
                  <div>
                    <p class="text-muted mb-1">Monto Comisiones total</p>
                    <h5 class="mb-0">{{totalCommission | currency}}</h5>
                  </div>
                </div>
                <div class="col-12">
                  <div class="text-center">
                    <p class="text-muted mb-2">Total a pagar</p>
                    <h4 class="mb-0">{{amortizeService.currentAmortizeTableValue.totalPeriodPay | currency}}</h4>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Tabla de pagos</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-amortization-table #amortizationTable [hiddenPaymentsColumn]="true"
                [disabledPaymentsColumn]="true"></app-amortization-table>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" routerLink="/solicitud-de-prestamo">
              Cerrar
            </button>
            <button class="btn btn-outline-primary external-btn" (click)="exportExcel()">Excel</button>
            <button *ngIf="isLender" class="btn btn-primary external-btn" (click)="onSubmit()"
              [disabled]="getIsDisabled() || getAutorizationGroup()">
              {{ buttonNextDescription }}
            </button>
          </div>
        </mat-step>

        <mat-step [stepControl]="formrequesFormDataform">
          <ng-template matStepLabel>Integrar expediente</ng-template>

          <mat-accordion>
            <mat-expansion-panel [expanded]="advanceOptionsOpenState" (opened)="advanceOptionsOpenState = true;"
              (closed)="advanceOptionsOpenState = false;">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Seleccionar datos expediente</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle d-flex justify-content-between">
                <span>Selecciona los tipos de datos requeridos del solicitante</span>
              </div>

              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table *ngIf="dsAdvanceOptions" mat-table [dataSource]="dsAdvanceOptions">
                    <ng-container matColumnDef="number">
                      <th mat-header-cell *matHeaderCellDef class="text-right">
                        <div>Seleccionar</div>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selectionAdvanceOptions.hasValue() && isAllSelected()"
                          [indeterminate]="selectionAdvanceOptions.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                      </th>
                      <td class="text-right" mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selectionAdvanceOptions.toggle(row) : null"
                          (change)="onCheckboxChange($event, row.description, row)"
                          [checked]="selectionAdvanceOptions.isSelected(row)" [disabled]="isCheckDisabled(row)">
                        </mat-checkbox>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                      <th mat-header-cell *matHeaderCellDef class="text-left">
                        Sección
                      </th>
                      <td mat-cell *matCellDef="let row">
                        {{row.description}}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="advanceOptionsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: advanceOptionsColumns;"></tr>
                  </table>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel [expanded]="panelIntegrateFileOpenState" (opened)="panelIntegrateFileOpen(true)"
              (closed)="panelIntegrateFileOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="d-flex">
                    <h5>Datos Solicitante</h5>
                    <i *ngIf="frv" [ngClass]="formRequestJsonCompleted ? 'bx-check' : 'bx-error-circle'"
                      class="bx ms-3"></i>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle d-flex justify-content-between">
                <span>Ingresa la siguiente información</span>
                <span class="text-sm-start text-end">* Campo Obligatorio</span>
              </div>

              <form class="form-horizontal" [formGroup]="formrequestJsonForm">
                <div *ngIf="loadFormRequestJsonForm">
                  <div class="row">
                    <app-dialog-person-data *ngIf="pass == '2'" #personData [mode]="mode"
                      [legalConditionsValue]="legalConditionsValue" [personType]="personType"
                      [loanRequestStatus]="loanRequestStatus" [dr]="dr" [loanRequestId]="loanRequestId"
                      [borrowerCustomerId]="borrowerCustomerId" [contactRelationship]="contactRelationShip"
                      [loanRequestDTO]="loanRequestDTO"></app-dialog-person-data>
                    <app-dialog-address-data *ngIf="pass == '2'" #addressData [personType]="personType"
                      [loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="addressDataJsonCompleted"></app-dialog-address-data>
                    <app-dialog-contact-data *ngIf="pass == '2'" #contactData [personType]="personType"
                      [loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId"
                      [borrowerMail]="borrowerMail" [borrowerPhoneMobile]="borrowerPhoneMobile" [dr]="dr"
                      [completed]="contactDataJsonCompleted"></app-dialog-contact-data>

                    <app-dialog-economic-activity *ngIf="economicActivityActive" #economicActivityData
                      [personData]="personData" [occupation]="occupation" [personType]="personType"
                      [loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="economicActivityDataJsonCompleted">
                    </app-dialog-economic-activity>
                    <app-dialog-patrimonial-data *ngIf="patrimonialActive" #patrimonialData
                      [loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="patrimonialDataJsonCompleted">
                    </app-dialog-patrimonial-data>
                    <app-dialog-economic-flow *ngIf="economicFlowActive" #economicFlowData
                      [loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="economicFlowDataJsonCompleted">
                    </app-dialog-economic-flow>
                    <app-dialog-family-data *ngIf="familyActive" #familyData [loanRequestStatus]="loanRequestStatus"
                      [loanRequestId]="loanRequestId" [dr]="dr" [completed]="familyDataJsonCompleted">
                    </app-dialog-family-data>
                    <app-dialog-reference-data *ngIf="referenceActive" #referenceData [personType]="personType"
                      [loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="referenceDataJsonCompleted">
                    </app-dialog-reference-data>
                    <app-dialog-socioeconomic *ngIf="socioeconomicActive" #socioeconomicData
                      [loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="socioeconomicDataJsonCompleted">
                    </app-dialog-socioeconomic>
                    <app-dialog-bank-data *ngIf="bankActive" #bankData [personType]="personType"
                      [loanRequestDTO]="loanRequestDTO" [loanRequestStatus]="loanRequestStatus"
                      [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="bankDataJsonCompleted"></app-dialog-bank-data>
                    <app-dialog-balance-sheet-data *ngIf="balanceSheetActive" #balanceSheetData
                      [loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="balanceSheetDataJsonCompleted">
                    </app-dialog-balance-sheet-data>
                    <app-dialog-status-result *ngIf="statusResultActive" #statusResultData
                      [loanRequestStatus]="loanRequestStatus" [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="statusResultDataJsonCompleted">
                    </app-dialog-status-result>
                    <app-dialog-pld-data *ngIf="pldActive" #pldData [loanRequestStatus]="loanRequestStatus"
                      [loanRequestId]="loanRequestId" [dr]="dr"
                      [completed]="pldDataJsonCompleted"></app-dialog-pld-data>
                  </div>
                </div>
              </form>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion *ngIf="elementsFormWarrantys.length > 0">
            <mat-expansion-panel [expanded]="panelWarrantyOpenState" (opened)="panelWarrantyOpen(true)"
              (closed)="panelWarrantyOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="d-flex">
                    <h5>Garantía(s)</h5>
                    <i *ngIf="gv" [ngClass]="passRequestCompleted ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle">
                Ingresa la siguiente información
              </div>
              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table mat-table *ngIf="dataSourceformWarrantys" [dataSource]="dataSourceformWarrantys">
                    <ng-container matColumnDef="number">
                      <th mat-header-cell *matHeaderCellDef> Número </th>
                      <td mat-cell *matCellDef="let row"> {{row.number}} </td>
                    </ng-container>
                    <ng-container matColumnDef="warranty">
                      <th mat-header-cell *matHeaderCellDef> Garantías </th>
                      <td mat-cell *matCellDef="let row">
                        {{row.warranty}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="warrantyFormCompleted">
                      <th mat-header-cell *matHeaderCellDef> Estatus </th>
                      <td mat-cell *matCellDef="let row">
                        <p *ngIf="row.warrantyFormCompleted == 2">
                          Completo </p>
                        <p *ngIf="row.warrantyFormCompleted == 1">
                          Pendiente por validar </p>
                        <p *ngIf="row.warrantyFormCompleted == 0">
                          Incompleto </p>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="warrantyForm">
                      <th mat-header-cell *matHeaderCellDef> Datos </th>
                      <td mat-cell *matCellDef="let row">
                        <i class="bx bx-cloud-upload"
                          (click)="openWarrantyDetails(row.number, row.warranty, row.warrantyForm)"></i>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsFormWarrantys"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsFormWarrantys;"></tr>
                  </table>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [expanded]="panelDocumentsOpenState" (opened)="panelDocumentsOpen(true)"
              (closed)="panelDocumentsOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="d-flex">
                    <h5>Documentación <i *ngIf="legalConditionsValue == 1">(Opcional)</i></h5>
                    <i *ngIf="dv && legalConditionsValue > 1"
                      [ngClass]="documentsRequiredConfigJsonCompleted ? 'bx-check' : 'bx-error-circle'"
                      class="bx ms-3"></i>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="d-block">
                <div class="subtittle">
                  Carga y visualiza los documentos requeridos en la solicitud de préstamo
                </div>

                <div class="table-responsive">
                  <div class="react-bootstrap-table">
                    <table mat-table fxFlex="100%" [dataSource]="dataSourceRequiredDocuments">
                      <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Documento </th>
                        <td mat-cell *matCellDef="let row">
                          {{getDocumentName(row.Description)}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="required">
                        <th mat-header-cell *matHeaderCellDef> Requerido </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                          <mat-slide-toggle *ngIf="isLender" [disabled]="row.Disabled"
                            (change)="changeRequiredDocument()" [(ngModel)]="row.Required">
                          </mat-slide-toggle>
                          <mat-slide-toggle *ngIf="!isLender" [disabled]="true" [(ngModel)]="row.Required">
                          </mat-slide-toggle>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="option">
                        <th mat-header-cell *matHeaderCellDef> Tipo documento </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                          <div class="form-group">
                            <select class="form-control" [(ngModel)]="row.Option" (change)="changeRequiredDocument()">
                              <option *ngIf="getDocumentName(row.Description) != 'Otro'" value="">
                                Seleccione</option>
                              <option *ngIf="getDocumentName(row.Description) == 'Otro'" value="" [selected]="true">
                                {{row.Description}}
                              </option>
                              <option *ngFor="let item of getDocumentList(row.Description)" value="{{item.key}}">
                                {{item.value}}</option>
                            </select>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="uploaded">
                        <th mat-header-cell *matHeaderCellDef> Cargado </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                          <i class="bx bx-check"
                            *ngIf="row.Uploaded && row.Required && (!progress[i] || progress[i] === 100)"></i>

                          <mat-progress-spinner *ngIf="progress[i] > 0 && progress[i] < 99" color="primary"
                            mode="determinate" [value]="progress[i]" strokeWidth="5" diameter="30">
                          </mat-progress-spinner>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="buttonAttach">
                        <th mat-header-cell *matHeaderCellDef> Explorar </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                          <i class="bx bx-search" loading="lazy" (click)="fileInput.click()"></i>
                          <input hidden #fileInput type="file"
                            [disabled]="(!row.Required || getIsDisabledExplorerSteep4())"
                            (change)="selectFile($event, i, row.Type)" />
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="viewFile">
                        <th mat-header-cell *matHeaderCellDef> Ver </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                          <i class="bx bx-show" [attr.disabled]="!row.Required || !row.uploaded"
                            (click)="previewFile(row.UrlNavigate, row.fileName)"></i>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="columnsRequiredDocuments"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnsRequiredDocuments;">
                      </tr>
                    </table>
                  </div>
                </div>

                <form [formGroup]="newDocumentForm" (ngSubmit)="addOtherDocument()" class="mt-4">
                  <div class="row">
                    <div class="col-md-3 mb-3">
                      <div class="form-group">
                        <label>Documento </label>
                        <select #selectDocument class="form-control"
                          (change)="otherDocumentChange(selectDocument.value)" formControlName="document">
                          <option value="">Seleccione</option>
                          <option *ngFor="let item of documentsNew" value="{{item.value}}">
                            {{item.value}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 d-flex gap-2 align-items-end mb-3">
                      <div class="form-group">
                        <label for="">Tipo de documento</label>
                        <input type="text" formControlName="other" placeholder="" class="form-control">
                      </div>
                      <button class="btn btn-outline-primary" [disabled]="getIsDisabledOtherSteep4()">
                        Agregar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" matStepperPrevious routerLink="/solicitud-de-prestamo">
              Cerrar
            </button>
            <button class="btn btn-outline-primary external-btn" matStepperPrevious>
              <i class='bx bx-arrow-back'></i>
            </button>
            <button *ngIf="isLender" class="btn btn-outline-primary external-btn" (click)="generateExpedient()">
              Generar
            </button>
            <button *ngIf="isLender" class="btn btn-outline-primary external-btn" (click)="sendExpedientMail()">
              Enviar
            </button>
            <button class="btn btn-primary external-btn" (click)="saveIntegrateFile()"
              [disabled]="getIsDisabledSaveSteep2() || getAutorizationGroup()">
              Guardar
            </button>
          </div>
        </mat-step>

        <mat-step *ngIf="isLender">
          <ng-template matStepLabel>Servicios</ng-template>

          <!--<mat-accordion>
            <mat-expansion-panel [expanded]="panelServiceRequest" (opened)="panelServiceRequestOpen(true)"
              (closed)="panelServiceRequestOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Servicios de verificación</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle">
                Selecciona el(los) servicio(s) que desee agregar para su solicitud de su préstamo.
              </div>

              <mat-horizontal-stepper #stepperExternalServices class="stepperExternalServices" labelPosition="bottom"  [@.disabled]="false">
                <mat-step>
                  <div *ngIf="filteredExternalServices" class="d-flex justify-content-center">
                    <div class="row justify-content-around col-12">
                      <div class="col-md-5" *ngFor="let item of filteredExternalServices">
                        <br>
                        <div class="card">
                          <div class="card-body p-4">
                            <div class="d-flex">
                              <i class="bx big primary" [ngClass]="item.image"></i>
                              <div class="ms-4">
                                <h5 class="fw-bold">
                                  {{item.serviceName}}
                                </h5>
                                <p class="mb-1">
                                  {{item.description}}{{(item.serviceName === 'Visita de cobranza' || item.serviceName
                                  === 'Visita de verificación') ? '.' : ''}}
                                </p>
                                <p class="mb-2">
                                  {{(item.serviceName === 'Visita de cobranza' || item.serviceName === 'Visita de
                                  verificación') ? '*Sujeto a ' : '*Disponible en todo México'}}
                                  <a *ngIf="(item.serviceName === 'Visita de cobranza' || item.serviceName === 'Visita de verificación')"
                                    class="fw-bold text-link black"
                                    (click)="showConditionsExternalServices('covarage')">cobertura</a>
                                </p>
                                <p class="mb-0">
                                  <a class="text-link" (click)="showConditionsExternalServices(item.serviceName)">
                                    Ver condiciones
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div
                              class="d-flex justify-content-lg-between justify-content-center align-items-center flex-wrap mt-4">
                              <h2 class="fw-bold mb-0 pe-3">
                                {{item.amount | currency}} <span class="small_text">MXN</span>
                                <span class="d-block mini_text">*Incluye IVA</span>
                              </h2>
                              <button class="btn_yellow" (click)="openCollection(item)" matStepperNext>
                                <a class="nav-link">Seleccionar</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-step>

                <mat-step>
                  <app-external-service-collection #externalServiceCollection *ngIf="showExternalServices"
                    [externalServiceSelected]="externalServiceSelected"
                    [loansId] ="loanRequestId"
                    [addressData] = "addressData"
                    [personData] = "personData"
                    [stepperExternalServices]="stepperExternalServices">
                  </app-external-service-collection>
                </mat-step>
              </mat-horizontal-stepper>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel (opened)="panelContactDataOpen(true)" (closed)="panelContactDataOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="d-flex">
                    <h5>Historial de Servicios</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="table-responsive">
                <table *ngIf="dsCollectionLog" mat-table [dataSource]="dsCollectionLog">
                  <ng-container matColumnDef="serviceName">
                    <th mat-header-cell *matHeaderCellDef>Servicio</th>
                    <td mat-cell *matCellDef="let row"> {{ row.serviceName }} </td>
                  </ng-container>

                  <ng-container matColumnDef="dateCreated">
                    <th mat-header-cell *matHeaderCellDef>Fecha</th>
                    <td mat-cell *matCellDef="let row"> {{row.dateCreated | date:'d MMM y'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>Costo</th>
                    <td mat-cell *matCellDef="let row"> {{ row.amount | currency }} </td>
                  </ng-container>

                  <ng-container matColumnDef="statusName">
                    <th mat-header-cell *matHeaderCellDef>Estatus</th>
                    <td mat-cell *matCellDef="let row"> {{ row.statusName }} </td>
                  </ng-container>

                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Ver</th>
                    <td mat-cell *matCellDef="let row">
                      <div
                        *ngIf="row.serviceName == 'Llamada de verificación' || row.status == 3 && row.serviceName == 'Visita de verificación'"
                        (click)="openLink(row)">
                        <i class="bx bx-show"></i>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsExternalServices"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsExternalServices;"></tr>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>-->

          <div class="d-flex flex-wrap gap-2 justify-content-end">

            <button style="width: 50px" class="btn btn-outline-primary external-btn" matStepperPrevious>
              <i class='bx bx-arrow-back'></i>
            </button>
            <button  *ngIf = "!countDsCollectionLog" style="width:auto" class="btn btn-primary external-btn" (click)="skipServices()"
              [disabled]="getIsdisabled3()">
              Continuar sin servicios
            </button>
            <button *ngIf="countDsCollectionLog"  style="width:auto" class="btn btn-primary external-btn" (click)="skipServices()"
              [disabled]="getIsdisabled3()">
              Continuar
            </button>
          </div>
        </mat-step>


        <mat-step [stepControl]="formAuthorizeRequest">
          <ng-template matStepLabel>Autorizar solicitud</ng-template>
          <mat-accordion>
            <mat-expansion-panel [expanded]="panelEvaluationRequest" (opened)="panelEvaluationOpen(true)"
              (closed)="panelEvaluationOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Evaluación <i>(Opcional)</i></h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle">
                Califica cada pregunta según corresponda
              </div>

              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table class="evaluation" mat-table [dataSource]="evaluationDataSource">
                    <ng-container matColumnDef="question">
                      <th mat-header-cell *matHeaderCellDef>Pregunta</th>
                      <td mat-cell *matCellDef="let element">{{element.question}}</td>
                    </ng-container>
                    <ng-container matColumnDef="concept">
                      <th mat-header-cell *matHeaderCellDef>Concepto</th>
                      <td mat-cell *matCellDef="let element">{{element.concept}}</td>
                    </ng-container>

                    <ng-container matColumnDef="five">
                      <th mat-header-cell *matHeaderCellDef>Muy Alto (5)</th>
                      <td mat-cell *matCellDef="let element">
                        <mat-radio-button [name]="element.id" [checked]="element.five"
                          (click)="changeEvaluation(element.id, 'five')">
                        </mat-radio-button>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="four">
                      <th mat-header-cell *matHeaderCellDef>Alto (4)</th>
                      <td mat-cell *matCellDef="let element">
                        <mat-radio-button [name]="element.id" [checked]="element.four"
                          (click)="changeEvaluation(element.id, 'four')">
                        </mat-radio-button>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="three">
                      <th mat-header-cell *matHeaderCellDef>Medio (3)</th>
                      <td mat-cell *matCellDef="let element">
                        <mat-radio-button [name]="element.id" [checked]="element.three"
                          (click)="changeEvaluation(element.id, 'three')">
                        </mat-radio-button>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="two">
                      <th mat-header-cell *matHeaderCellDef>Bajo (2)</th>
                      <td mat-cell *matCellDef="let element">
                        <mat-radio-button [name]="element.id" [checked]="element.two"
                          (click)="changeEvaluation(element.id, 'two')">
                        </mat-radio-button>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="one">
                      <th mat-header-cell *matHeaderCellDef>Muy Bajo (1)</th>
                      <td mat-cell *matCellDef="let element">
                        <mat-radio-button [name]="element.id" [checked]="element.one"
                          (click)="changeEvaluation(element.id, 'one')">
                        </mat-radio-button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="evaluationColumn"></tr>
                    <tr mat-row *matRowDef="let row; columns: evaluationColumn;"></tr>
                  </table>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [expanded]="panelDeliveryMethodOpenState" (opened)="panelDeliveryMethodOpen(true)"
              (closed)="panelDeliveryMethodOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Forma de entrega</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle d-flex justify-content-between">
                <span>Ingresa la siguiente información</span>
                <span class="text-sm-start text-end">* Campo Obligatorio</span>
              </div>

              <form class="form-horizontal" [formGroup]="disbursementForm">
                <div class="w-100">
                  <div class="row">
                    <div class="col-md-3 mb-3">
                      <div class="form-group">
                        <label for="date">Fecha de entrega</label>
                        <input autofocus="autofocus" class="form-control" formControlName="date" type="date" />
                      </div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <div class="form-group">
                        <label for="date">Forma de entrega *</label>
                        <select #selectMethodOfPayment class="form-control" formControlName="methodOfPaymentId"
                          (change)="onChangeMethodOfPayment(selectMethodOfPayment.value)"
                          [ngClass]="submitedSaveDisbursement && validateRequiredFormF4('methodOfPaymentId') ? 'is-invalid' : ''">
                          <option value="">Seleccione</option>
                          <option *ngFor="let item of wayToPayList" value="{{item.value}}">
                            {{item.value}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <div class="form-group">
                        <label for="">{{wayToPayPlaceHolder}}</label>
                        <input type="text" formControlName="methodOfPaymentOther" placeholder="" class="form-control"
                          [ngClass]="submitedSaveDisbursement && validateRequiredFormF4('methodOfPaymentOther') ? 'is-invalid' : ''">
                      </div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <div class="form-group">
                        <label for="">{{detailPlaceHolder}}</label>
                        <input type="text" formControlName="methodOfPaymentDetail" placeholder="" class="form-control"
                          [ngClass]="submitedSaveDisbursement && validateRequiredFormF4('methodOfPaymentDetail') ? 'is-invalid' : ''">
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [expanded]="panelAuthorizeRequest" (opened)="panelAuthorizeRequestOpen(true)"
              (closed)="panelAuthorizeRequestOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Autorización</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle">
                Selecciona si quieres Autorizar o Rechazar la solicitud
              </div>

              <div class="w-100">
                <div class="row">
                  <div class="col-md-12">
                    <mat-radio-group [(ngModel)]="autorizationGroup">
                      <mat-radio-button value="Autorizado"
                        [disabled]="getAutorizationGroup() || getIsDisabledAproadSteep4() || !isLender"
                        (change)="authorizeRequest()">{{getAuthorizationText()}}
                      </mat-radio-button>
                      <mat-radio-button value="Rechazado" [disabled]="getRechazeGroup() || !isLender"
                        (change)="rechazeRequest()">
                        Rechazar</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion *ngIf="legalConditionsValue != 1">
            <mat-expansion-panel [expanded]="panelDocumentsLegalOpenState" (opened)="panelDocumentsLegalOpen(true)"
              (closed)="panelDocumentsLegalOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Generar documentos legales</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle">
                Visualiza, descarga e imprime los documentos legales
              </div>

              <div class="table-responsive">
                <div class="react-bootstrap-table">
                  <table mat-table #table class="w-100 text-center" [dataSource]="dataSourceRequiredDocumentsLegal">
                    <ng-container matColumnDef="description">
                      <th mat-header-cell *matHeaderCellDef> Documento </th>
                      <td mat-cell *matCellDef="let row"> {{row.Description}} </td>
                    </ng-container>
                    <ng-container matColumnDef="required">
                      <th mat-header-cell *matHeaderCellDef> Requerido </th>
                      <td mat-cell *matCellDef="let row; let i = index">
                        <mat-slide-toggle *ngIf="isLender" [disabled]="getIsDisabledAproadSteep4() || row.Disabled"
                          (change)="changeRequiredDocumentLegal()" [(ngModel)]="row.Required">
                        </mat-slide-toggle>
                        <mat-slide-toggle *ngIf="!isLender" [disabled]="true"
                          [(ngModel)]="row.Required"></mat-slide-toggle>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="download">
                      <th mat-header-cell *matHeaderCellDef> Generar </th>
                      <td mat-cell *matCellDef="let row; let i = index">
                        <i class="bx bx-search" loading="lazy" (click)="openDialogHtmlViewer(row.Description)"></i>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="generated">
                      <th mat-header-cell *matHeaderCellDef> Generado </th>
                      <td mat-cell *matCellDef="let row; let i = index">
                        <span *ngIf="autorizationGroup == 'Autorizado'" class="material-icons">check</span>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsRequiredDocumentsLegal"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsRequiredDocumentsLegal;">
                    </tr>
                  </table>
                </div>
              </div>

            </mat-expansion-panel>
          </mat-accordion>

          <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" matStepperPrevious routerLink="/solicitud-de-prestamo">
              Cerrar
            </button>
            <button class="btn btn-outline-primary external-btn" matStepperPrevious>
              <i class='bx bx-arrow-back'></i>
            </button>
            <button *ngIf="isLender" class="btn btn-primary external-btn" (click)="saveEvaluation()"
              [disabled]="getIsDisabledAproadSteep4()">
              Guardar
            </button>
          </div>
        </mat-step>

        <mat-step [stepControl]="formrequestDocumentsLegalForm">
          <ng-template matStepLabel>Registrar entrega</ng-template>
          <mat-accordion *ngIf="legalConditionsValue != 1">
            <mat-expansion-panel [expanded]="panelDocumentsLegalLoadOpenState"
              (opened)="panelDocumentsLegalLoadOpen(true)" (closed)="panelDocumentsLegalLoadOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Carga de documentos legales</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle">
                Carga los documentos legales ya firmados por las partes
              </div>

              <div class="w-100">
                <div class="row">
                  <table mat-table fxFlex="100%" #table matTableResponsive
                    [dataSource]="dataSourceRequiredDocumentsLegal">
                    <ng-container matColumnDef="description">
                      <th mat-header-cell *matHeaderCellDef> Documento </th>
                      <td mat-cell *matCellDef="let row"> {{row.Description}} </td>
                    </ng-container>
                    <ng-container matColumnDef="required">
                      <th mat-header-cell *matHeaderCellDef> Requerido </th>
                      <td mat-cell *matCellDef="let row; let i = index">
                        <mat-slide-toggle *ngIf="isLender" [disabled]="getIsDisabledAproadSteep5() || row.Disabled"
                          (change)="changeRequiredDocumentLegal()" [(ngModel)]="row.Required">
                        </mat-slide-toggle>
                        <mat-slide-toggle *ngIf="!isLender" [disabled]="true"
                          [(ngModel)]="row.required"></mat-slide-toggle>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="uploaded">
                      <th mat-header-cell *matHeaderCellDef> Cargado </th>
                      <td mat-cell *matCellDef="let row; let i = index">
                        <i class="bx bx-check"
                          *ngIf="row.Uploaded && row.Required && (!progress[i] || progress[i] === 100)"></i>
                        <mat-progress-spinner *ngIf="progress[i] > 0 && progress[i] < 99" color="primary"
                          mode="determinate" [value]="progress[i]" strokeWidth="5" diameter="30">
                        </mat-progress-spinner>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="buttonAttach">
                      <th mat-header-cell *matHeaderCellDef> Cargar documento escaneado </th>
                      <td mat-cell *matCellDef="let row; let i = index">
                        <i class="bx bx-search" loading="lazy" (click)="fileInput.click()"></i>
                        <input hidden #fileInput type="file"
                          [disabled]="getIsDisabledSearchrSteep5() || autorizationGroup != 'Autorizado'"
                          (change)="selectFile($event, i, row.Type)" />
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="viewFile">
                      <th mat-header-cell *matHeaderCellDef> Ver contrato</th>
                      <td mat-cell *matCellDef="let row; let i = index">
                        <i class="fas fa-eye" [attr.disabled]="!row.Required || !row.Uploaded"
                          (click)="previewFile(row.UrlNavigate, row.fileName)"></i>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="download">
                      <th mat-header-cell *matHeaderCellDef> Generar </th>
                      <td mat-cell *matCellDef="let row; let i = index">
                        <i>
                          <button [disabled]="getIsDisabledSearchrSteep5()" type="button"
                            (click)="openDialogHtmlViewer(row.Description)" mat-raised-button><span
                              class="material-icons">search</span>
                            Revisar
                          </button>
                        </i>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsRequiredLoadDocumentsLegal">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: columnsRequiredLoadDocumentsLegal;">
                    </tr>
                  </table>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [expanded]="panelRecordDeliveryOpenState" (opened)="panelRecordDeliveryOpen(true)"
              (closed)="panelRecordDeliveryOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Desembolso</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle">
                Selecciona si el desembolso ha sido entregado
              </div>

              <div class="row">
                <div class="col-md-12">
                  <mat-radio-group [disabled]="getIsDisabledGiveLoan()" [(ngModel)]="giveLoanGroup">
                    <mat-radio-button [value]="1">Por entregar</mat-radio-button>
                    <mat-radio-button [value]="2">Entregado</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <div class="d-flex flex-wrap gap-2 justify-content-end">
                <button *ngIf="isLender" class="btn btn-primary" [disabled]="getIsDisabledGiveLoan()"
                  (click)="giveLoan()">
                  Registrar
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>