import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';
import { ConfirmcodeChangeEmailComponent } from 'src/app/components/confirmcode-change-email/confirmcode-change-email.component';
import { ConfirmcodeChangePhonenumberComponent } from 'src/app/components/confirmcode-change-phonenumber/confirmcode-change-phonenumber.component';
import { CustomerDTO } from 'src/app/models/customer/customer.dto.model';
import { Customer } from 'src/app/models/customer/customer.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { UserService } from 'src/app/services/user/user.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { startPhoneVerification } from 'src/app/models/user/startPhoneVerification';
import { DialogVerifyCodePhoneComponent } from 'src/app/components/dialog-verify-code-phone/dialog-verify-code-phone.component';
import { DatePipe, KeyValue } from '@angular/common';
import { GlobalLists } from 'src/app/global/global-data';
import { DocumentServiceService } from 'src/app/services/document-service/document-service.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { UserLogged } from 'src/app/models/user/userlogged.model';
import { User } from 'src/app/models/user/user.model';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { FiscalRegime, cfdiUse } from 'src/app/models/subscriptionpayment/subscriptionpayment.model';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})

export class UserComponent implements OnInit {
	@ViewChild('checkApplyMoralPerson') checkApplyMoralPerson: MatSlideToggle;
	@ViewChild('checkApplyPhysicalPerson') checkApplyPhysicalPerson: MatSlideToggle;
	currentPhoneNumber: string;
	currentEmail: string;
	profilePicture: string;
	phoneNumberValidated: boolean;
	emailValidated: boolean;
	currentUser: UserLogged;

	selectedFiles: FileList;
	currentFile: File;
	message = '';
	profileLoaded = 0;

	customer: CustomerDTO;
	profileForm: FormGroup;
	changeEmailForm: FormGroup;
	changePhoneNumberForm: FormGroup;
	changePasswordForm: FormGroup;
	submitted = false;
	changeEmailSubmitted = false;
	changePasswordSubmitted = false;
	showValidatePhone = true;
	validatePhone = true;
	phoneCustomerVerified :boolean;

	genders: any;
	countries: any;
	states: any;
	placeOfBirths: any;
	statesBirth: any;
	cities: any;
	nationalities: any;
	personTypes: any;
	civilStatus: any;
	hide = true;
	hidenewPassword = true;
	hidenewPasswordConfirm = true;
	billingCountries: any;
	billingStates: any;
	billingCities: any = null;
	billingBusinessName: any;
	billingTaxRegime: any;
	billingRfc: any;
	billingCfdiUse: any;

	fiscalRegimeList: FiscalRegime[];
	cfdiUseList: cfdiUse[] = [];
	customerRFC = "";
	businessNametittle = "Nombre completo"

	requiredErrorMessage: string = "Este campo es requerido.";
	tittlePerson = "Datos persona fisica";

	phoneNumber = "";

	filteredOptionsCountrys: Observable<string[]>;
	showPersonMoral = false;

	panelCompanyDataOpenState = false;
	panelTypePersonOpenState = false;
	panelAddressOpenState = false;
	panelBillingAddressOpenState = false;
	panelContactDataOpenState = false;
	panelProfilePhoto = false;
	profileOpenState = false;
	securityOpenState = false;
	panelEmailOpenState: any;
	panelPasswordOpenState: any;
	buttonShowValidate: boolean = false;

	educationLevelList: KeyValue<number, string>[] = [
		{ key: 0, value: "Universidad" },
		{ key: 1, value: "Preparatoria" },
		{ key: 2, value: "Secundaria" },
		{ key: 3, value: "Primaria" },
	];

	ocupationList: KeyValue<number, string>[] = [
		{ key: 0, value: "Empleado" },
		{ key: 1, value: "Negocio propio" },
		{ key: 2, value: "Honorarios" },
		{ key: 3, value: "Eventual" },
		{ key: 4, value: "Pensionado" },
		{ key: 5, value: "Jubilado" },
		{ key: 6, value: "Ama de casa" },
		{ key: 7, value: "Estudiante" },
		{ key: 8, value: "Otro" },
	];

	phoneMask = GlobalLists.phoneMask;

	userFormSubmitted = false;
	charCountRfc: any;

	constructor(private customerService: CustomerService,
		private alertGlobalService: AlertGlobalService,
		private authService: AuthenticationService,
		private subscriptionpaymentService: SubscriptionpaymentService,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private userService: UserService,
		private catalogService: CatalogService,
		private datePipe: DatePipe,
		private spinnerService: SpinnerService,
		private documentServiceService: DocumentServiceService,
		private notificationService: NotificationService) {
		this.createProfileForm();
		this.createChangeEmailForm();
		this.createPhoneNumberForm();
		this.createPasswordForm();
	}

	get f1() { return this.profileForm.controls; }
	get f2() { return this.changeEmailForm.controls; }
	get f3() { return this.changePhoneNumberForm.controls; }
	get f4() { return this.changePasswordForm.controls; }

	get f1_prop() { return Object.getOwnPropertyNames(this.f1); }

	createProfileForm() {
		this.profileForm = this.formBuilder.group({
			//section1
			firstName: ['', Validators.required],
			middleName: [''],
			lastName1: ['', Validators.required],
			lastName2: [''],

			gender: [''],
			alias: [''],
			educationLevel: [''],
			ocupation: [''],
			birthdayDate: ['', Validators.required],
			countryBirthId: [''],
			placeOfBirth: [''],
			nationality: [''],

			curp: ['', [Validators.maxLength(18), Validators.minLength(18)]],
			rfc: ['', [Validators.maxLength(13), Validators.minLength(10)]],
			personId: [''],
			serieFiel: [''],

			//section2 --if panel moral person type is opened
			businessName: [''],
			businessRfc: [''],  //*new

			dateConstitution: [''], //*new
			writingNumber: [''], //*new
			notaryNumber: [''], //*new
			notaryName: [''], //*new

			//section3
			street: [''],
			exteriorNumber: [''],
			interiorNumber: [''],
			districtName: [''],

			countryId: [''],
			stateId: [''],
			cityId: [''],
			zipCode: [''],

			//section4
			phoneMobile: ['', this.validatePhone ? Validators.required : null],
			phoneHome: [''],  //*new
			phoneOffice: [''],  //*new
			phoneMessages: [''],  //*new
			phoneOther: [''],  //*new

			email: [{ value: '', disabled: true }],
			facebook: [''],
			instagram: [''],
			twitter: [''],  //*new
			linkedin: [''],

			verifyCode: [''],
			phoneNumberVerified: [{ value: false, disabled: true }],

			//section5
			billingStreet: [''],
			billingExteriorNumber: [''],
			billingInteriorNumber: [''],
			billingDistrictName: [''],

			billingCountryId: [''],
			billingStateId: [''],
			billingCityId: [''],
			billingZipCode: ['', Validators.required],
			billingBusinessName: [''],
			billingTaxRegime: [''],
			billingRfc: ['', [Validators.maxLength(13), Validators.minLength(10)]],
			billingCfdiUse: ['']
		});
	}

	createChangeEmailForm() {
		this.changeEmailForm = this.formBuilder.group({
			newEmail: ['', [Validators.required, Validators.email]],
			newEmailConfirm: ['', [Validators.required, Validators.email]]
		});
	}

	createPhoneNumberForm() {
		this.changePhoneNumberForm = this.formBuilder.group({
			newPhoneNumber: ['', Validators.required],
			newPhoneNumberConfirm: ['', Validators.required]
		});
	}

	createPasswordForm() {
		this.changePasswordForm = this.formBuilder.group({
			oldPassword: ['', Validators.required],
			newPassword: ['', Validators.required],
			newPasswordConfirm: ['', Validators.required]
		});
	}

	ngOnInit(): void {
		this.currentEmail = this.authService.currentUserValue.username;
		this.currentPhoneNumber = this.authService.currentUserValue.phoneNumber;
		this.phoneNumberValidated = this.authService.currentUserValue.phoneNumberConfirmed;
		this.emailValidated = this.authService.currentUserValue.emailConfirmed;

		this.getCustomer();
		this.getGenders();
		this.getNationalities();
		this.getCivilStatus();
		this.getFiscalRegimeList();
	}

	getCustomer(): void {
		this.customerService.getCustomer(this.authService.currentUserValue.customerId)
			.subscribe((data) => {
				this.catalogService.getCountries()
					.subscribe((countries) => {
						this.countries = countries;
						this.billingCountries = countries;
					});
				this.catalogService.getStates(data.countryId)
					.subscribe((states) => {
						this.states = states;
					});
				this.catalogService.getStates(data.billingCountryId)
					.subscribe((states) => {
						this.billingStates = states;
					});

				this.catalogService.getStates(data.countryBirthId)
					.subscribe((states) => {
						this.statesBirth = states;
					});

				if (data.countryId && data.stateId) {
					this.catalogService.getCities(data.countryId, data.stateId)
						.subscribe((cities) => {
							this.cities = cities;
						});
				}

				if (data.billingCountryId && data.billingStateId) {
					this.catalogService.getCities(data.billingCountryId, data.billingStateId)
						.subscribe((cities) => {
							this.billingCities = cities;
						});
				}

				let personId = data.personId;
				if (!data.personIdConfirmed)
					personId = '';

				let curp = data.curp;
				if (!data.curpConfirmed)
					curp = '';

				let rfc = data.rfc;
				if (!data.rfcConfirmed)
					rfc = '';

				this.customer = data;
				this.f1['firstName'].patchValue(data.firstName);
				this.f1['middleName'].patchValue(data.middleName);
				this.f1['lastName1'].patchValue(data.lastName1);
				this.f1['lastName2'].patchValue(data.lastName2);

				this.f1['gender'].patchValue(data.gender);
				this.f1['alias'].patchValue(data.alias);
				this.f1['educationLevel'].patchValue(data.educationLevel);
				this.f1['ocupation'].patchValue(data.ocupation);
				this.f1['birthdayDate'].patchValue(this.datePipe.transform(data.birthdayDate, 'yyyy-MM-dd'));
				this.f1['countryBirthId'].patchValue(data.countryBirthId);

				this.f1['placeOfBirth'].patchValue(data.placeOfBirth);
				this.f1['nationality'].patchValue(data.nationality);

				this.f1['curp'].patchValue(data.curp);
				this.f1['rfc'].patchValue(data.rfc);
				this.f1['personId'].patchValue(data.personId);
				this.f1['serieFiel'].patchValue(data.serieFiel);

				//section2 --if panel moral person type is opened
				if (data.businessName || data.businessRfc || data.dateConstitution
					|| data.writingNumber || data.notaryNumber || data.notaryName) {
					this.checkApplyMoralPerson.checked = true;

					this.showPersonMoral = true;
					this.tittlePerson = "Datos Representante Legal";

				} else {
					this.showPersonMoral = false;
					this.tittlePerson = "Datos personales";
				}

				this.f1['businessName'].patchValue(data.businessName);
				this.f1['businessRfc'].patchValue(data.businessRfc);

				this.f1['dateConstitution'].patchValue(data.dateConstitution);
				this.f1['writingNumber'].patchValue(data.writingNumber);
				this.f1['notaryNumber'].patchValue(data.notaryNumber);
				this.f1['notaryName'].patchValue(data.notaryName);

				//section3
				this.f1['street'].patchValue(data.street);
				this.f1['exteriorNumber'].patchValue(data.exteriorNumber);
				this.f1['interiorNumber'].patchValue(data.interiorNumber);
				this.f1['districtName'].patchValue(data.districtName);

				this.f1['countryId'].patchValue(data.countryId);
				this.f1['stateId'].patchValue(data.stateId);
				this.f1['cityId'].patchValue(data.cityId);
				this.f1['zipCode'].patchValue(data.zipCode);

				//section4
				this.f1['phoneMobile'].patchValue(data.phoneMobile);
				this.f1['phoneHome'].patchValue(data.phoneHome);
				this.f1['phoneOffice'].patchValue(data.phoneOffice);
				this.f1['phoneMessages'].patchValue(data.phoneMessages);
				this.f1['phoneOther'].patchValue(data.phoneOther);

				this.f1['email'].patchValue(this.currentEmail);
				this.f1['facebook'].patchValue(data.facebook);
				this.f1['instagram'].patchValue(data.instagram);
				this.f1['twitter'].patchValue(data.twitter);
				this.f1['linkedin'].patchValue(data.linkedin);
				this.f1['phoneNumberVerified'].patchValue(data.phoneNumberVerified);

				//section5
				this.f1['billingStreet'].patchValue(data.billingStreet);
				this.f1['billingExteriorNumber'].patchValue(data.billingExteriorNumber);
				this.f1['billingInteriorNumber'].patchValue(data.billingInteriorNumber);
				this.f1['billingDistrictName'].patchValue(data.billingDistrictName);

				this.f1['billingCountryId'].patchValue(data.billingCountryId);
				this.f1['billingStateId'].patchValue(data.billingStateId);
				this.f1['billingCityId'].patchValue(data.billingCityId);
				this.f1['billingZipCode'].patchValue(data.billingZipCode);
				this.f1['billingBusinessName'].patchValue(data.billingBusinessName);
				this.f1['billingTaxRegime'].patchValue(data.billingTaxRegime);
				this.f1['billingRfc'].patchValue(data.billingRfc);
				this.f1['billingCfdiUse'].patchValue(data.billingCfdiUse);

				this.phoneCustomerVerified =  data.phoneNumberVerified
				if (data.phoneNumberVerified) {
					this.phoneNumberValidated = true;
				}

				if (!data.phoneNumberVerified) {
					this.phoneNumberValidated = false;

				}

				if (data.billingRfc.length >= 12 && data.billingRfc.length <= 13) {
					this.getCfdiUseList(data.billingRfc);
				}else if (data.billingRfc == null || data.billingRfc == "") {
					this.customerRFC = "RFC no válido"
				}

				this.phoneNumber = data.phoneMobile;

				var currentUser = JSON.parse(localStorage.getItem('currentUser')) as UserLogged;
				currentUser.profilePicture = data.profilePicture;

				localStorage.setItem('currentUser', JSON.stringify(currentUser));

				this.authService.currentUserValue.profilePicture = data.profilePicture;
				this.profilePicture = this.authService.currentUserValue.profilePicture;
			});
	}

	getNationalities() {
		this.catalogService.getNationalities()
			.subscribe((nationalities) => {
				this.nationalities = nationalities;
			});
	}

	getCivilStatus() {
		this.catalogService.getCivilStatus()
			.subscribe((civilStatus) => {
				this.civilStatus = civilStatus;
			});
	}

	onSubmitProfile() {
		this.userFormSubmitted = true;

		if (
			this.profileForm.get('phoneMobile').value !== null &&
			this.profileForm.get('phoneMobile').value !== "" &&
			!this.phoneCustomerVerified
		) {
			this.alertGlobalService.showAlertError("Favor de Validar el teléfono");
			this.validatePhone = false;

			if (this.profileForm.invalid) {
				return;
			} else {
				return;
			}
		}

		let data = <Customer>{
			firstName: this.f1['firstName'].value,
			middleName: this.f1['middleName'].value,
			lastName1: this.f1['lastName1'].value,
			lastName2: this.f1['lastName2'].value,
			curp: this.f1['curp'].value,
			personId: this.f1['personId'].value,
			rfc: this.f1['rfc'].value,
			countryBirthId: this.f1['countryBirthId'].value,
			birthdayDate: this.f1['birthdayDate'].value,
			gender: this.f1['gender'].value,
			alias: this.f1['alias'].value,
			educationLevel: this.f1['educationLevel'].value,
			ocupation: this.f1['ocupation'].value,
			nationality: this.f1['nationality'].value,
			economicDependents: 0,
			facebook: this.f1['facebook'].value,
			instagram: this.f1['instagram'].value,
			twitter: this.f1['twitter'].value,
			linkedin: this.f1['linkedin'].value,
			businessName: this.f1['businessName'].value,
			businessRfc: this.f1['businessRfc'].value,
			dateConstitution: this.f1['dateConstitution'].value === "" ? null : this.f1['dateConstitution'].value,
			writingNumber: this.f1['writingNumber'].value,
			notaryNumber: this.f1['notaryNumber'].value,
			notaryName: this.f1['notaryName'].value,
			phoneMobile: this.f1['phoneMobile'].value === null || !this.submitted ? null : GlobalLists.getPhone(this.f1['phoneMobile'].value),
			phoneHome: this.f1['phoneHome'].value === null ? null : GlobalLists.getPhone(this.f1['phoneHome'].value),
			phoneOffice: this.f1['phoneOffice'].value === null ? null : GlobalLists.getPhone(this.f1['phoneOffice'].value),
			phoneMessages: this.f1['phoneMessages'].value === null ? null : GlobalLists.getPhone(this.f1['phoneMessages'].value),
			phoneOther: this.f1['phoneOther'].value === null ? null : GlobalLists.getPhone(this.f1['phoneOther'].value),
			street: this.f1['street'].value,
			exteriorNumber: this.f1['exteriorNumber'].value,
			interiorNumber: this.f1['interiorNumber'].value,
			zipCode: this.f1['zipCode'].value + "",
			countryId: this.f1['countryId'].value,
			stateId: this.f1['stateId'].value,
			cityId: this.f1['cityId'].value,
			districtName: this.f1['districtName'].value,
			placeOfBirth: this.f1['placeOfBirth'].value,
			serieFiel: this.f1['serieFiel'].value,
			phoneNumberVerified: this.f1['phoneNumberVerified'].value,
			billingStreet: this.f1['billingStreet'].value,
			billingExteriorNumber: this.f1['billingExteriorNumber'].value,
			billingInteriorNumber: this.f1['billingInteriorNumber'].value,
			billingZipCode: this.f1['billingZipCode'].value + "",
			billingCountryId: this.f1['billingCountryId'].value,
			billingStateId: this.f1['billingStateId'].value,
			billingCityId: this.f1['billingCityId'].value,
			billingDistrictName: this.f1['billingDistrictName'].value,
			billingBusinessName: this.f1['billingBusinessName'].value,
			billingTaxRegime: this.f1['billingTaxRegime'].value,
			billingRfc: this.f1['billingRfc'].value,
			billingCfdiUse: this.f1['billingCfdiUse'].value
		};

		var dataUser = <User>{
			firstName: this.f1['firstName'].value,
			middleName: this.f1['middleName'].value,
			lastName1: this.f1['lastName1'].value,
			lastName2: this.f1['lastName2'].value,
			birthdayDate: this.f1['birthdayDate'].value,
			countryPhoneCode: "52",
			countryCode: "MX",
			languageCode: "es",
			currencyCode: "MXN",
			personType: 612, // persona física
			phoneNumber: this.f1['phoneMobile'].value === null || !this.submitted ? null : GlobalLists.getPhone(this.f1['phoneMobile'].value),
		}


		this.userService.updateUser(dataUser).subscribe(() => {
			this.customerService.updateCustomer(this.authService.currentUserValue.customerId, data).subscribe((result) => {
				this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");

				var currentUser = JSON.parse(localStorage.getItem('currentUser')) as UserLogged;
				currentUser.firstName = this.f1['firstName'].value;
				currentUser.lastName1 = this.f1['lastName1'].value;

				localStorage.setItem('currentUser', JSON.stringify(currentUser));

				this.authService.currentUserValue.firstName = this.f1['firstName'].value;
				this.authService.currentUserValue.lastName1 = this.f1['lastName1'].value;

				this.customer.firstName = this.f1['firstName'].value;
				this.customer.lastName1 = this.f1['lastName1'].value;
			});
		});
	}

	onSubmitChangeEmail() {

		this.changeEmailSubmitted = true;

		this.changeEmailSubmitted = true;

		if (this.changeEmailForm.invalid) {
			return;
		}

		this.userService.getCodeForChangeEmail(this.f2['newEmail'].value)
			.subscribe(() => {
				const dialogRef = this.dialog.open(ConfirmcodeChangeEmailComponent, {
					autoFocus: false,
					width: '80%'
				});
				let instance = dialogRef.componentInstance;
				instance.oldValue = this.currentEmail;
				instance.newValue = this.f2['newEmail'].value;
			});
	}

	onSubmitChangePhoneNumber() {

		if (this.changePhoneNumberForm.invalid) {
			return;
		}

		this.userService.getCodeForChangePhoneNumber(this.f3['newPhoneNumber'].value)
			.subscribe(() => {
				const dialogRef = this.dialog.open(ConfirmcodeChangePhonenumberComponent, {
					autoFocus: false,
					width: '80%'
				});
				let instance = dialogRef.componentInstance;
				instance.oldValue = this.currentPhoneNumber;
				instance.newValue = this.f3['newPhoneNumber'].value;
			});
	}

	onSubmitChangePassword() {

		this.changePasswordSubmitted = true;


		this.changePasswordSubmitted = true;

		if (this.changePasswordForm.invalid) {
			return;
		}

		this.userService.changePassword(this.f4['oldPassword'].value, this.f4['newPassword'].value)
			.subscribe(() => {
			});
		this.sendMailPass();
	}

	sendMailPass() {
		this.userService.getPasswordChangeEmail(this.f1['email'].value)
			.subscribe(() => {
			});
	}

	getGenders() {
		this.catalogService.getGenders()
			.subscribe((data) => {
				this.genders = data;
			});
	}

	onChangeSelectCountries(countryId: string) {
		this.states = [];
		this.cities = [];
		this.catalogService.getStates(countryId)
			.subscribe((states) => {
				this.f1['stateId'].patchValue("");
				this.states = states;
			});
	}

	onChangeSelectBillingCountries(countryId: string) {
		this.billingStates = [];
		this.billingCities = [];
		this.catalogService.getStates(countryId)
			.subscribe((states) => {
				this.f1['billingStateId'].patchValue("");
				this.billingStates = states;
			});
	}

	onChangeSelectCountrieBirth(countryId: string) {
		this.catalogService.getStates(countryId)
			.subscribe((statesBirth) => {
				this.statesBirth = statesBirth;
			});
	}

	onChangeSelectStates(countryId: string, stateId: string) {
		this.cities = [];
		this.catalogService.getCities(countryId, stateId)
			.subscribe((cities) => {
				this.f1['cityId'].patchValue("");
				this.cities = cities;
			});
	}

	onChangeSelectBillingStates(countryId: string, stateId: string) {
		this.billingCities = [];
		this.catalogService.getCities(countryId, stateId)
			.subscribe((cities) => {
				this.f1['billingCityId'].patchValue("");
				this.billingCities = cities;
			});
	}

	validateRequiredForm(formControlName: string): boolean {
		if (this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
			return true;
		}
		return false;
	}

	applyMoralPerson(checked) {
		if (checked) {
			this.showPersonMoral = true;
			this.tittlePerson = "Datos Representante Legal";
			this.businessNametittle = "Razón social"
		} else {
			this.showPersonMoral = false;
			this.tittlePerson = "Datos personales";
			this.businessNametittle = "Nombre completo"
		}

		this.profileForm.clearValidators();
	}

	sendCode() {
		if (GlobalLists.getPhone(this.f1['phoneMobile'].value).length < 10) {
			return;
		}

		const dialogRef = this.dialog.open(DialogVerifyCodePhoneComponent, {
			autoFocus: false,
			width: '80%',
			data: {
				phoneMobile: parseInt(GlobalLists.getPhone(this.f1['phoneMobile'].value)),
				phoneNumberVerified: this.f1['phoneNumberVerified'],
				submitted: this.submitted,
				phoneMobileFormat: this.f1['phoneMobile'].value
			}
		});

		let instance = dialogRef.componentInstance

		dialogRef.afterClosed().subscribe(data => {
			this.submitted = instance.data.submitted
			if(this.submitted == true){
				this.validatePhone = true;
				this.phoneCustomerVerified = true;
			}
		})

		let data = <startPhoneVerification>{
			to: GlobalLists.getPhone(this.f1['phoneMobile'].value)
		}

		this.notificationService.sendCodeVerification(data).subscribe(() => {
			this.alertGlobalService.showAlertSuccess("Código enviado con éxito.");
		});
	}

	change(e: Event) {
		var value = (e.target as HTMLInputElement).value;
		var phone = GlobalLists.getPhone(value);

		if (phone.length == 10) {
			if ((this.phoneNumber != value)) {
				this.submitted = false;
				this.showValidatePhone = false;
				this.f1['phoneNumberVerified'].patchValue(false)
			} else {
				this.submitted = true
				this.showValidatePhone = true;
				this.f1['phoneNumberVerified'].patchValue(true)
			}
		} else {
			this.showValidatePhone = true;
			this.submitted = false
			this.f1['phoneNumberVerified'].patchValue(false)
		}
	}

	panelCompanyDataOpen(e) {
		this.panelCompanyDataOpenState = e;
	}

	panelTypePersonOpen(e) {
		this.panelTypePersonOpenState = e;
	}

	panelAddressOpen(e) {
		this.panelAddressOpenState = e;
	}

	panelBillingAddressOpen(e) {
		this.panelBillingAddressOpenState = e;
	}

	panelProfileOpen(e) {
		this.profileOpenState = e;
	}

	panelSecurityOpen(e) {
		this.securityOpenState = e;
	}

	panelEmailOpen(e) {
		this.panelEmailOpenState = e;
	}

	panelPasswordOpen(e) {
		this.panelPasswordOpenState = e;
	}

	panelContactOpen(e) {
		this.panelContactDataOpenState = e;
	}

	panelProfilePhotoOpen(e) {
		this.panelProfilePhoto = e;
	}

	selectFile(event): void {
		this.selectedFiles = event.target.files;
		this.upload();
	}

	upload(): void {
		this.currentFile = this.selectedFiles.item(0);

		if (this.currentFile.type != "image/jpeg" && this.currentFile.type != "image/png") {
			this.alertGlobalService.showAlertWarning("Imagen no válida");

			return
		}

		if (this.currentFile.size / 1024 / 1024 >= 3) {
			this.alertGlobalService.showAlertWarning("Imagen debe tener menos de 3 MB");

			return
		}

		this.documentServiceService.uploadProfilePicture(this.currentFile).subscribe(
			event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.profileLoaded = Math.round(100 * event.loaded / event.total);
				} else if (event instanceof HttpResponse) {
					this.message = event.body.message;
				}

				if (this.profileLoaded == 100) {
					this.alertGlobalService.showAlertSuccess("Foto cargada con éxito");
					window.location.reload();
				}
			},
			err => {
				this.message = 'No se pudo subir el archivo.';
				this.alertGlobalService.showAlertError("No se pudo subir el archivo.");
				this.currentFile = undefined;
			});
		this.selectedFiles = undefined;
	}

	getProfileSrc() {
		if (this.customer == null) {
			return
		}

		return this.customer.profilePicture;
	}

	validateChange(formControlName: string): boolean {
		if (this.f2[formControlName].errors !== null && this.f2[formControlName].errors['required']) {
			return true;
		}
		return false;
	}


	validateChangePassword(formControlName: string): boolean {
		if (this.f4[formControlName].errors !== null && this.f4[formControlName].errors['required']) {
			return true;
		}
		return false;
	}

	getClass() {
		if (this.hide) {
			return 'fa fa-eye';

		} else {
			return 'fa fa-eye-slash';
		}
	}
	getClassnewPassword() {
		if (this.hidenewPassword) {
			return 'fa fa-eye';

		} else {
			return 'fa fa-eye-slash';
		}
	}
	getClassnewPasswordConfirm() {
		if (this.hidenewPasswordConfirm) {
			return 'fa fa-eye';

		} else {
			return 'fa fa-eye-slash';
		}
	}
	getFiscalRegimeList() {
		this.subscriptionpaymentService.getFiscalRegimeList().subscribe((data) => {
			this.fiscalRegimeList = data;
		});
	}

	getCfdiUseList(billingRfc: string) {
		this.subscriptionpaymentService.getCfdiUseList(billingRfc).subscribe((data) => {
			this.cfdiUseList = data;
		});
	}

	validateRfcRewrite(value: string) {
		var billingRfc = value

		if (billingRfc == null || billingRfc == "" || billingRfc.length < 12) {
			this.customerRFC = "RFC no válido"
		}
		else {
			this.getCfdiUseList(billingRfc);
		}
	}

	rfcInput(event: any) {
		this.charCountRfc = event.target.value.length;
		this.validateRfcRewrite(event.target.value);
	}
}
