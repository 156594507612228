import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ValidateAccountService } from 'src/app/services/validate-account/validate-account.service';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit {
  
  validateForm: FormGroup;
  submitted = false;
  email: string;
  countSentCode: number = 0;
  disableSentCodeInput: boolean = false;
  disableSentCodeButton: boolean = false;
  
  constructor(private formBuilder: FormBuilder,
              private validateAccountService: ValidateAccountService,
              private route: ActivatedRoute) {
    this.createValidateForm();
  }

  createValidateForm() {
    this.validateForm = this.formBuilder.group({
      code: ['', Validators.required]
    });
  } 

  ngOnInit(): void {
    var url_string = window.location.href;
    var url = new URL(url_string);
    if (url.searchParams.get("email") != null) {
      this.validateAccountService.setEmailObserver(url.searchParams.get("email"));
    }
    
    this.validateAccountService.getEmailObserver()
    .subscribe((email) => {
      if (email !== null) {
        this.email = email;
      }     
    });
  }

  get f() { return this.validateForm.controls; }

  onSubmit() {
      this.submitted = true;

      if (this.validateForm.invalid) {
          return;
      }

      this.validateAccountService.validateEmail(this.email, this.f['code'].value)
      .subscribe();
  }

  onReset() {
      this.submitted = false;
      this.validateForm.reset();
  }

  sendCode() {
    this.countSentCode ++;

    if (this.countSentCode >= 3) {
      this.disableSentCodeButton = true;
    }

    this.validateAccountService.sendValidateEmailCode(this.email).subscribe();
  }

}
