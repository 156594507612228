<div class="d-flex justify-content-between">
  <div class="container_title">
      <img src="/assets/images/logos/logotipo-small.svg" /> <h2>Plan personalizado</h2>
  </div>
</div>

<div class="subtittle">
	Completa los siguientes datos y nos pondremos en contacto a la brevedad
</div>
<form [formGroup]="commentsForm" (ngSubmit)="commentsOnSubmit($event)">
	<div class="w-100">
		<div class="form-horizontal">
			<div class="row">
				<div class="col-md-6 mb-3">
					<div class="form-group">
						<label for="name">Nombre</label>
						<input type="text" name="name" formControlName="name" class="form-control">
						<mat-error *ngIf="submitted && f_commentsForm.name.hasError('required')">Campo obligatorio</mat-error>
					</div>
				</div>
				<div class="col-md-6 mb-3">
					<div class="form-group">
						<label for="email">Correo electrónico</label>
						<input type="text" name="email" formControlName="email" class="form-control">
						<mat-error *ngIf="submitted && f_commentsForm.email.hasError('required')">Campo obligatorio</mat-error>
						<mat-error *ngIf="submitted && f_commentsForm.email.hasError('email')">Email inválido</mat-error>
					</div>
				</div>
				<div class="col-md-6 mb-3">
					<div class="form-group">
						<label for="phoneNumber">Teléfono celular</label>
						<input type="text" name="phoneNumber" formControlName="phoneNumber" class="form-control">
						<mat-error *ngIf="submitted && f_commentsForm.phoneNumber.hasError('required')">Campo obligatorio</mat-error>
					</div>
				</div>
				<div class="col-md-6 mb-3">
					<div class="form-group">
						<label for="suscription">Operaciones estimadas por mes</label>
						<input type="number" name="suscription" formControlName="suscription" class="form-control">
						<mat-error *ngIf="submitted && f_commentsForm.suscription.hasError('required')">Campo obligatorio</mat-error>
					</div>
				</div>
				<div class="col-xl-12 mb-3">
					<div class="form-group">
						<label for="comments">Comentarios</label>
						<input type="text" name="comments" formControlName="comments" class="form-control">
						<mat-error *ngIf="submitted && f_commentsForm.comments.hasError('required')">Campo obligatorio</mat-error>

					</div>
				</div>
				<div class="d-flex flex-wrap gap-2 justify-content-end">
					<button class="btn btn-outline-primary external-btn" [mat-dialog-close]>
						Cerrar
					</button>

					<button class="btn btn-primary external-btn">
						Enviar
					</button>
				</div>
			</div>
		</div>
	</div>
</form>
