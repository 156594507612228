<div class="container-fluid">
  <h4 style="font-weight: 500">Servicios</h4>
  <div class="card">
    <div class="card-body">
      <!--<h5 style="font-weight: 500">Servicios de verificación y cobranza</h5>-->
      <h5 style="font-weight: 500">Servicios de cobranza</h5>
      <hr>
      <p *ngIf="!showExternalServices">Selecciona el(los) servicio(s) que desee agregar para su solicitud de su préstamo o para su proceso de cobranza.</p>
      <p *ngIf="showExternalServices">Completa o Verifica que los datos requerido para realizar {{serviceName}} sean correctos</p>

      <mat-accordion>
        <mat-expansion-panel [expanded]="panelLoanProceedingsOpenState" class="mb-4" (opened)="panelLoanProceedingsOpen(true)" (closed)="panelLoanProceedingsOpen(false)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex">
                <h5 *ngIf="!showExternalServices">Servicios disponibles</h5>
                <h5 *ngIf="showExternalServices">Servicios de {{serviceName}}</h5>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-horizontal-stepper #stepperExternalServices class="stepperExternalServices" labelPosition="bottom" [@.disabled]="false">
            <mat-step>
              <div *ngIf="filteredExternalServices" class="d-flex justify-content-center">
                <div class="row justify-content-around col-12">
                  <div class="col-md-5" *ngFor="let item of filteredExternalServices">
                    <br>
                    <div class="card">
                      <div class="card-body p-4">
                        <div class="d-flex">
                          <i class="bx big primary" [ngClass]="item.image"></i>
                          <div class="ms-4">
                            <h5 class="fw-bold">
                              {{item.serviceName}}
                            </h5>
                            <div class="d-flex flex-wrap justify-content-between align-items-center mb-2">
                              <a
                                [ngClass]="{'person-secondary': item.typePerson === 0, 'person-primary': item.typePerson !== 0}">
                                A {{ item.typePerson === 1 ? 'persona moral' : 'persona física' }}
                              </a>
                              <div class="d-flex">
                                <i class="bx bxs-user"
                                  [ngClass]="item.typePerson === 0 ? 'orange_c' : 'c_primary_opacity'"
                                  style="align-self: center;"></i>
                                <div class="form-check form-switch">
                                  <input (change)="onCheck($event,item,item.serviceName)" [checked]="item.typePerson"
                                    class="form-check-input"
                                    [ngClass]="{'color-btn': item.typePerson === 0, 'color-btn-switch': item.typePerson !== 0}"
                                    type="checkbox" />
                                </div>
                                <i class="bx bxs-business"
                                  [ngClass]="item.typePerson === 0 ? 'orange_light' : 'primary'"
                                  style="align-self: center;"></i>
                              </div>
                            </div>
                            <p class="mb-1">
                              {{item.description}}{{(item.serviceName === 'Visita de cobranza' || item.serviceName === 'Visita de verificación') ? '.' : ''}}
                            </p>
                            <p class="mb-2">
                              {{(item.serviceName === 'Visita de cobranza' || item.serviceName === 'Visita de verificación') ? '*Sujeto a ' : '*Disponible en todo México'}}
                              <a *ngIf="(item.serviceName === 'Visita de cobranza' || item.serviceName === 'Visita de verificación')" class="fw-bold text-link black" (click)="showConditionsExternalServices('covarage')">cobertura</a>
                            </p>
                            <p class="mb-0">
                              <a class="text-link" (click)="showConditionsExternalServices(item.serviceName)">
                                Ver condiciones
                              </a>
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-lg-between justify-content-center align-items-center flex-wrap mt-4">
                          <h2 class="fw-bold mb-0 pe-3">
                            {{item.amount | currency}} <span class="small_text">MXN</span>
                            <span class="d-block mini_text">*Incluye IVA</span>
                          </h2>
                          <button class="btn_yellow" (click)="openCollection(item)" matStepperNext>
                            <a class="nav-link">Seleccionar</a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>

            <mat-step>
              <app-external-service-collection #externalServiceCollection *ngIf="showExternalServices"
                [externalServiceSelected]="externalServiceSelected" [stepperExternalServices]="stepperExternalServices">
              </app-external-service-collection>
            </mat-step>
          </mat-horizontal-stepper>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion *ngIf="!showExternalServices">
        <mat-expansion-panel (opened)="panelContactDataOpen(true)" (closed)="panelContactDataOpen(false)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex">
                <h5>Historial de servicios</h5>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div id="content">
            <div class="table-responsive">
              <div class="react-bootstrap-table">
                <table *ngIf="balanceMovementsDataSource" mat-table [dataSource]="balanceMovementsDataSource">
                  <tbody>

                    <ng-container matColumnDef="nameDebt">
                      <th mat-header-cell *matHeaderCellDef>Nombre Deudor</th>
                      <td mat-cell *matCellDef="let row"> {{row.nameDebt}} </td>
                    </ng-container>

                    <ng-container matColumnDef="serviceName">
                      <th mat-header-cell *matHeaderCellDef>Tipo de movimiento</th>
                      <td mat-cell *matCellDef="let row"> {{row.serviceName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dateCreated">
                      <th mat-header-cell *matHeaderCellDef>Fecha</th>
                      <td mat-cell *matCellDef="let row"> {{row.dateCreated | date:'d MMM y'}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef>Monto</th>
                      <td mat-cell *matCellDef="let row"> {{row.amount | currency}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="statusName">
                      <th mat-header-cell *matHeaderCellDef>Estatus</th>
                      <td mat-cell *matCellDef="let row"> {{row.statusName}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef>Ver</th>
                      <td mat-cell *matCellDef="let row">
                        <div
                          *ngIf="row.serviceName == 'Llamada de cobranza' || row.status == 3 && (row.serviceName == 'Visita de cobranza' || row.serviceName == 'Visita de verificación')"
                          (click)="openLink(row)">
                          <i class="bx bx-show"></i>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="balanceMovementsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: balanceMovementsColumns; let i = index"
                      (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }">
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="onClickPage()"></mat-paginator>
          </div>

        </mat-expansion-panel>
      </mat-accordion>


      <div class="d-flex flex-wrap gap-2 justify-content-end mt-4">
        <!--<a [href]="layoutFormat()" download="LayoutCobranza.xlsx">
          <button class="btn btn-outline-primary">
            Layout cobranza
          </button>
        </a>
        <input type="file" (change)="onFileChange($event)" style="display: none;" #fileInput>
        <button class="btn btn-outline-primary" (click)="fileInput.click()"
          *ngIf="this.currentUser.userType == 1">Importar layout</button>-->
        <button class="btn btn-outline-primary external-btn" routerLink="/inicio">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
