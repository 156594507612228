import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class InputErrorsGlobalService {

	private error$ = new BehaviorSubject<Error>(<Error>{ show: false });

	constructor() { }

	getInputErrorsObserver(): Observable<Error> {
		return this.error$.asObservable();
	}

	showInputErrors(errors: InputError[]): void {
		this.error$.next(<Error>{
			show: true,
			errors: errors
		});
	}
}

export class Error {
	show: boolean;
	errors: InputError[];
}

export class InputError {
	fieldName: string;
	message: string;
}
