<div class="container-fluid">
	<h4>Mi carrito</h4>
	<div *ngIf="cart" class="row">
		<div class="col-lg-8">
			<div class="card col-12">
				<div class="card-body">
					<div class="row">
						<div class="col-lg-5 col-md-5">
							<div class="d-flex">
								<div class="me-3">
									<img src="{{ authService.currentUserValue.profilePicture }}"
										class="avatar-md rounded-circle img-thumbnail"
										alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
								</div>
								<div class="flex-grow-1 align-self-center">
									<div class="text-muted">
										<p class="mb-2">Bienvenido de nuevo</p>
										<h5 class="mb-1">
											{{ authService.currentUserValue.firstName }} {{
											authService.currentUserValue.lastName1 }}
										</h5>
										<p class="mb-2" *ngIf="isLender">
											Soy prestamista
										</p>
										<p class="mb-2" *ngIf="!isLender">
											Soy solicitante
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="align-self-center col-lg-7 col-md-7">
							<div class="mt-4 mt-md-0">
								<div class="row">
									<div class="col-lg-6 col-sm-6 col-12 mb-2 mb-sm-0">
										<p class="text-muted text-truncate mb-2">Saldo actual</p>
										<h5 class="mb-0">
											{{ cart.myBalance.balance | currency }} <span class="small_text">MXN</span>
										</h5>
									</div>
									<div class="col-lg-6 col-sm-6 col-12 mb-2 mb-sm-0">
										<p class="text-muted text-truncate mb-2">Suscripción actual</p>
										<h5 class="mb-0">{{ cart.myBalance.currentSubscription }}</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-body">
					<h5>Carrito</h5>
					<hr>
					<p>Revisa tus servicios seleccionados para proceder al pago</p>

					<div *ngFor="let item of cart.externalServicesList" class="card no_bg">
						<div class="card-body p-4">
							<div class="d-flex align-items-center justify-content-between container-mobile">
								<div class="d-flex">
									<i class="bx big primary" [ngClass]="item.image"></i>
									<div class="mx-4">
										<h5 class="fw-bold">{{ item.serviceName }}</h5>
										<p class="mb-0">
											Fecha y hora de solicitud <span class="fw-bold">{{ item.dateCreated |
												date:'d MMM y HH:mm' }}</span>
										</p>
										<p class="mb-0">
											Para <span class="fw-bold">{{ capitalizeFirstLetter(item.serviceTo)
												}}</span>
										</p>
										<p *ngIf="item.serviceType == 'Visita'" class="mb-0">
											En <span class="fw-bold">{{ item.serviceToDirection }}</span>
										<p *ngIf="item.serviceType == 'Llamada'" class="mb-0">
											Al número <span class="fw-bold">{{ item.phone }}</span>
										</p>
									</div>
								</div>

								<div class="d-flex mobile">
									<h2 class="fw-bold mb-0 amount-text">
										{{ item.amount | currency }} <span class="small_text">MXN</span>
									</h2>
									<h2 class="mb-0">
										<i (click)="deleteItem(item.id)" class="bx bxs-trash-alt primary ms-4"></i>
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-body">
					<h5>Todos nuestros servicios</h5>
					<hr>
					<p>Selecciona el servicio que te gustaría comprar</p>
					<div *ngIf="dataSource">
            <ng-container *ngFor="let item of dataSource.data; let i = index">
              <hr *ngIf="i > 0">
              <div class="d-flex align-items-center justify-content-between container-mobile">
                <div class="d-flex">
                  <i class="bx big primary" [ngClass]="item.image"></i>
                  <div class="mx-4">
                    <h5 class="fw-bold">{{item.serviceName}}</h5>
                    <p class="mb-0">{{item.description}}{{item.serviceName == 'Visita de cobranza' ? '.' : ''}}</p>
                    <p class="mb-0"><a class="text-link"  (click)="showConditionsExternalServices(item.serviceName)">Ver condiciones</a></p>
                  </div>
                </div>

                <div class="d-flex mobile">
                  <h2 class="fw-bold mb-0 amount-text pe-3">
                    {{ item.amount | currency }} <span class="small_text">MXN</span>
                  </h2>
                  <button class="btn_yellow">
                    <a (click)="openRoute()" class="nav-link">Seleccionar</a>
                  </button>
                </div>
              </div>
            </ng-container>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-4 mt-lg-5 mt-3">
			<div class="card detail_cart">
				<div class="card-body">
					<div>
						<h5 class="fw-bold mb-2 primary">Resumen de compra</h5>
						<hr>

						<div *ngIf="!cartFinalizedBuy && cart.externalServicesList.length > 0">
							<div *ngFor="let item of cart.externalServicesList">
								<div class="align-items-center d-flex justify-content-between">
									<h5 class="mb-0">
										{{item.serviceName}} {{' a ' +
										capitalizeFirstLetter(item.serviceTo)}}
									</h5>
									<h5 class="fw-bold mb-0">{{ item.amount | currency }} <span
											class="small_text">MXN</span></h5>
								</div>
								<hr>
							</div>

							<div class="align-items-center d-flex justify-content-between">
								<h5 class="mb-0">Total</h5>
								<h5 class="mb-0 fw-bold">{{ sumTotalCart | currency }} <span
										class="small_text">MXN</span>
								</h5>
							</div>

							<button class="complete_Cart btn_yellow mt-3" (click)="setPayment()">
								<a class="nav-link btn-mid">Aplicar</a>
							</button>
						</div>

						<div class="align-items-center d-flex justify-content-between"
							*ngIf="cart.externalServicesList.length == 0">
							<p class="text-muted mb-0">
								Aquí verás los importes de tu compra una vez que agregues servicios.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="card detail_card" *ngIf="cartFinalizedBuy">
				<div class="card-body">
					<div class="d-flex justify-content-between">
						<h5 class="text-muted">Saldo anterior</h5>
						<h5 class="text-muted">{{ currentBalance + sumTotalPay | currency }} <span
								class="small_text">MXN</span></h5>
					</div>
					<div class="d-flex justify-content-between">
						<h5 class="mb-0 text-muted">Monto aplicado</h5>
						<h5 class="mb-0 text-muted">- {{ sumTotalCart | currency }} <span class="small_text">MXN</span>
						</h5>
					</div>
					<hr>

					<div class="d-flex justify-content-between">
						<h5 class="mb-0 text-muted">Saldo actual</h5>
						<h5 class="mb-0 text-muted">{{ sumTotalPay | currency }} <span class="small_text">MXN</span>
						</h5>
					</div>
				</div>
			</div>

			<div class="container_button">
				<button class="w-100 btn_yellow_outline">
					<a class="nav-link">Seguir comprando</a>
				</button>
			</div>
		</div>
	</div>
