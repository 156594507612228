import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { GlobalLists } from 'src/app/global/global-data';
import { Country } from 'src/app/models/loanrequest/loanrequest.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { DialogAddressDataComponent } from '../dialog-address-data/dialog-address-data.component';
import { DialogPersonDataComponent } from '../dialog-person-data/dialog-person-data.component';
import { AccountStatementComponent } from 'src/app/views/account-statement/account-statement.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ContactPhone, CreateExternalServices, ExternalServicesDTO } from 'src/app/models/loan/loan.dto.model';
import { LoanService } from 'src/app/services/loan/loan.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { Router } from '@angular/router';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';
import { MatTableDataSource } from '@angular/material/table';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';

@Component({
	selector: 'app-external-service-details',
	templateUrl: './external-service-details.component.html',
	styleUrls: ['./external-service-details.component.scss']
})

export class ExternalServiceDetailsComponent implements OnInit {
	@ViewChild('stepper') stepper: MatStepper;
	@Input() personData: DialogPersonDataComponent;
	@Input() addressData: DialogAddressDataComponent;
	@Input() addressCountriesJson: Country[];
	@Input() externalServiceSelected: ExternalServicesDTO;
	@Input() accountStatement: AccountStatementComponent;
	@Input() legalConditionsValue: number;
	@Input() stepperExternalServices: MatStepper;

	fiscalStates: any;
	fiscalCities: any;

	relationshipName = "";
	relationShipTypeName = "";
	statesBirth: any;
	states: any;
	cities: any;
	panelTypePersonOpenState = false;
	panelAddressOpenState = false;
	panelOtherDataOpenState = true;
	panelCompanyDataOpenState = false;
	modifydata = false;
	countryName = "";
	stateName = "";
	cityName = "";
	legalConditions = "";
	visitTypeName = "";
	externalServiceType = "";

	dr = true;

	externalServiceForm: FormGroup;
	currencyMask = GlobalLists.currencyMask;
	typeRelationshipList = GlobalLists.typeRelationshipList;
	phoneMask = GlobalLists.phoneMask;
	legalConditionsList = GlobalLists.legalConditionsList;
	visitTypeList = GlobalLists.visitTypeList;
	bankList = GlobalLists.bankList;
	phoneList = GlobalLists.phoneList;

	cardMask = GlobalLists.cardMask;
	accountMask = GlobalLists.accountMask;
	clabeMask = GlobalLists.clabeMask;
	personTypeList = GlobalLists.personTypeList;
	selectedType: any;
	contactPhone = <ContactPhone[]>[];
	contactPhoneForm: FormGroup;
	dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);
	isDisabled: boolean = false;
	adittionalPhone: any;
	validateRequeridPhone: boolean = false;

	contactPhoneColumns = [
		'phoneType',
		'phone',
		'comments',
		'buttonRemove'
	];

	formattedHours: string[] = [];
	startDateString = this.datePipe.transform(new Date(), "yyyy-MM-dd");

	constructor(
		private formBuilder: FormBuilder,
		private changeDetector: ChangeDetectorRef,
		private alertGlobalService: AlertGlobalService,
		private loanService: LoanService,
		private currencyPipe: CurrencyPipe,
		private dialog: MatDialog,
		private router: Router,
		private datePipe: DatePipe,
		private subscriptionpaymentService: SubscriptionpaymentService,
		private loanrequestService: LoanrequestService,
		private catalogService: CatalogService) {

	}

	get fExternalService() { return this.externalServiceForm.controls; }
	get fExternalServiceProp() { return Object.getOwnPropertyNames(this.fExternalService); }
	get fContactPhone() { return this.contactPhoneForm.controls; }

	ngOnInit(): void {
		this.generateHours();
		this.setExternalServiceSelected(this.externalServiceSelected);
		this.selectedType = this.personTypeList.find(item => item.key === this.personData.personType).value;
		this.createContactPhoneForm();
		this.phoneLists()
	}

	phoneLists() {
		this.loanrequestService.getContactDataRequest(this.personData.loanRequestId).subscribe((result) => {
			this.contactPhone = result.contactPhone;

			this.dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);
			this.updateContactData();
		});
	}

	setExternalServiceSelected(externalServiceSelected: ExternalServicesDTO) {
		this.externalServiceSelected = externalServiceSelected;
		this.createExternalServiceForm();
	}

	ngAfterViewInit(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	getStatesBirth(countryBirthId) {
		this.catalogService.getStates(countryBirthId).subscribe(
			(states) => {
				this.statesBirth = states;
			}
		);
	}

	getStates(countryId) {
		this.catalogService.getStates(countryId).subscribe(
			(states) => {
				this.states = states;
				this.stateName = this.states.find(x => x.code == this.addressData.FormrequestJson.find(x => x.conditionName == 'StateId').value).name;
			}
		);
	}

	getCitys(stateId) {
		this.catalogService.getCities(this.fExternalService["countryId"].value, stateId).subscribe(
			(cities) => {
				this.cities = cities;
				this.cityName = this.cities.find(x => x.code == this.fExternalService['cityId'].value).name;
			}
		);
	}

	createExternalServiceForm() {
		this.externalServiceForm = this.formBuilder.group(
			{
				lenderFullName: [{ value: this.accountStatement.accountStatement.lenderData.fullName, disabled: true }],

				busisnessName: [this.personData.FormrequestJson.find(x => x.conditionName == 'BusisnessName').value],

				firstName: [this.personData.FormrequestJson.find(x => x.conditionName == 'FirstName').value],
				middleName: [this.personData.FormrequestJson.find(x => x.conditionName == 'MiddleName').value],
				lastName1: [this.personData.FormrequestJson.find(x => x.conditionName == 'LastName1').value],
				lastName2: [this.personData.FormrequestJson.find(x => x.conditionName == 'LastName2').value],

				phone: [this.accountStatement.accountStatement.borrowerData.phoneNumber],
				email: [this.personData.FormrequestJson.find(x => x.conditionName == 'Email').value],
				curp: [this.personData.FormrequestJson.find(x => x.conditionName == 'Curp').value],
				rfc: [this.personData.FormrequestJson.find(x => x.conditionName == 'Rfc').value],

				relationShip: [this.personData.FormrequestJson.find(x => x.conditionName == 'RelationShip').value, [Validators.required]],
				relationShipType: [this.personData.FormrequestJson.find(x => x.conditionName == 'RelationShipType').value],

				legalConditions: [{ value: this.legalConditions, disabled: true }],
				daysLate: [{ value: this.accountStatement.accountStatement.resumeData.daysLate, disabled: true }],
				totalBalanceToLiquited: [{ value: this.accountStatement.accountStatement.resumeData.totalBalanceToLiquited, disabled: true }],
				capitalExpired: [{ value: this.accountStatement.accountStatement.resumeData.totalCapitalToLiquited, disabled: true }],
				startDate: [{ value: this.accountStatement.accountStatement.conditionsData.startDate.toString().substring(0, 10), disabled: true }],
				numberOfPayments: [{ value: this.accountStatement.accountStatement.conditionsData.numberOfPayments, disabled: true }],
				term: [{ value: this.accountStatement.typeOfPeriodDesc, disabled: true }],
				sumPaymentsDeposit: [{ value: this.accountStatement.accountStatement.resumeData.sumPaymentsDeposit, disabled: true }],

				street: [this.addressData.FormrequestJson.find(x => x.conditionName == 'Street').value],
				exteriorNumber: [this.addressData.FormrequestJson.find(x => x.conditionName == 'ExteriorNumber').value],
				interiorNumber: [this.addressData.FormrequestJson.find(x => x.conditionName == 'InteriorNumber').value],
				districtName: [this.addressData.FormrequestJson.find(x => x.conditionName == 'DistrictName').value],
				countryId: [this.addressData.FormrequestJson.find(x => x.conditionName == 'CountryId').value],
				stateId: [this.addressData.FormrequestJson.find(x => x.conditionName == 'StateId').value],
				cityId: [this.addressData.FormrequestJson.find(x => x.conditionName == 'CityId').value],
				zipCode: [this.addressData.FormrequestJson.find(x => x.conditionName == 'ZipCode').value],

				loanAmount: [{ value: this.accountStatement.accountStatement.conditionsData.loanAmount, disabled: true }],
				balance: [{ value: this.accountStatement.accountStatement.resumeData.balance, disabled: true }],
				totalBalancePayment: [{ value: this.accountStatement.accountStatement.resumeData.totalBalancePayment, disabled: true }],
				totalBalanceLate: [{ value: this.accountStatement.accountStatement.resumeData.totalBalanceLate, disabled: true }],

				monthlyRate: [{ value: this.accountStatement.accountStatement.conditionsData.monthtlyRate, disabled: true }],
				monthlyPorcentOnArrears: [{ value: this.accountStatement.accountStatement.conditionsData.monthlyPorcentOnArrears, disabled: true }],
				totalPayment: [{ value: this.accountStatement.accountStatement.resumeData.totalPayment, disabled: true }],
				totalPeriods: [{ value: this.accountStatement.accountStatement.conditionsData.loanTerm, disabled: true }],
				totalCapitalLate: [{ value: this.accountStatement.accountStatement.resumeData.totalCapitalLate, disabled: true }],
				interestBalance: [{ value: this.accountStatement.accountStatement.resumeData.interestBalance + this.accountStatement.accountStatement.resumeData.commissionsBalance, disabled: true }],
				commissionsBalance: [{
					value: this.accountStatement.accountStatement.resumeData.totalInterestOnArrearsLate + this.accountStatement.accountStatement.resumeData.totalInterestOnArrearsTaxLate +
						this.accountStatement.accountStatement.resumeData.totalOrdinaryInterestLate + this.accountStatement.accountStatement.resumeData.totalOrdinaryInterestTaxLate +
						this.accountStatement.accountStatement.resumeData.totalCommissionLate + this.accountStatement.accountStatement.resumeData.totalCommissionTaxLate + this.accountStatement.accountStatement.resumeData.totalISRLate, disabled: true
				}],
				totalInterestToLiquited: [{ value: this.accountStatement.accountStatement.resumeData.totalInterestOnArrearsToLiquited + this.accountStatement.accountStatement.resumeData.totalCommissionToLiquited, disabled: true }],
				firstDatePayment: [{ value: this.accountStatement.accountStatement.resumeData.firstDatePayment.toString().substring(0, 10), disabled: true }],
				amountNextPayment: [{ value: this.accountStatement.accountStatement.resumeData.amountNextPayment, disabled: true }],


				dayVisit: [null],
				hourStart: [''],
				hourEnd: [''],
				visitType: [''],
				bank: [''],
				account: [''],
				clabe: [''],
				cardNumber: [''],
				comments: [null],
				linkPay: [null],
				adittionalPhone: ['']
			}
		);

		if (this.personData.checkApplyMoralPerson) {
			this.fExternalService["busisnessName"].setValidators([Validators.required]);
			this.fExternalService["firstName"].clearValidators();
			this.fExternalService["middleName"].clearValidators();
			this.fExternalService["lastName1"].clearValidators();
			this.fExternalService["lastName2"].clearValidators();
		} else {
			this.fExternalService["busisnessName"].clearValidators();
			this.fExternalService["firstName"].setValidators([Validators.required]);
			this.fExternalService["lastName1"].setValidators([Validators.required]);
		}

		if (this.externalServiceSelected.serviceName == 'Visita de cobranza') {
			this.externalServiceType = "visita"

			this.fExternalService["street"].setValidators([Validators.required]);
			this.fExternalService["exteriorNumber"].setValidators([Validators.required]);
			this.fExternalService["districtName"].setValidators([Validators.required]);
			this.fExternalService["countryId"].setValidators([Validators.required]);
			this.fExternalService["stateId"].setValidators([Validators.required]);
			this.fExternalService["cityId"].setValidators([Validators.required]);
			this.fExternalService["zipCode"].setValidators([Validators.required]);
			this.fExternalService["phone"].clearValidators();
		} else if (this.externalServiceSelected.serviceName == 'Llamada de cobranza') {
			this.externalServiceType = "llamada"

			this.fExternalService["street"].clearValidators();
			this.fExternalService["exteriorNumber"].clearValidators();
			this.fExternalService["districtName"].clearValidators();
			this.fExternalService["countryId"].clearValidators();
			this.fExternalService["stateId"].clearValidators();
			this.fExternalService["cityId"].clearValidators();
			this.fExternalService["zipCode"].clearValidators();
			this.fExternalService["phone"].setValidators([Validators.required]);
			this.fExternalService["phone"].updateValueAndValidity();
		}

		if (this.fExternalService['countryId'].value != "") {
			this.getStates(this.fExternalService['countryId'].value);
			this.countryName = this.addressData.countries.find(x => x.code == this.fExternalService['countryId'].value).name;
		}

		if (this.fExternalService['stateId'].value != "") {
			this.getCitys(this.fExternalService['stateId'].value);
		}

		this.onChangeRelationShip(this.fExternalService['relationShip'].value);

		this.legalConditions = this.legalConditionsList.find(x => x.key == this.legalConditionsValue).value;

		this.externalServiceForm.get('hourStart').setValidators(this.checkHourStartRange.bind(this));
		this.externalServiceForm.get('hourStart').updateValueAndValidity();

		this.externalServiceForm.get('hourEnd').setValidators(this.checkHourEndRange.bind(this));
		this.externalServiceForm.get('hourEnd').updateValueAndValidity();
	}

	getContactRelationshipList() {
		if (this.personData.personType == 1) {
			return this.personData.contactRelationshipList.filter(x => x.key >= 8 && x.key <= 14)
		} else {
			return this.personData.contactRelationshipList.filter(x => (x.key >= 0 && x.key <= 7) || (x.key >= 13 && x.key <= 14))
		}
	}

	onChangeRelationShip(e: string) {
		if (e == "0") {
			this.fExternalService['relationShipType'].enable()
		} else {
			this.fExternalService['relationShipType'].disable()
			this.fExternalService['relationShipType'].patchValue("");
		}

		this.relationshipName = this.personData.contactRelationshipList.find(x => x.key == parseInt(e)).value;
	}

	onChangeRelationShipType(e: string) {
		this.relationShipTypeName = this.personData.typeRelationshipList.find(x => x.key == parseInt(e)).value;
	}

	onChangeSelectCountrieBirth(countryId: string) {
		this.catalogService.getStates(countryId).subscribe((statesBirth) => {
			this.statesBirth = statesBirth;
		});

		if (countryId == "MX" || countryId == "US") {
			if (this.personData.legalConditionsValue == 3) {
				this.fExternalService["placeOfBirth"].setValidators([Validators.required]);
			}
		} else {
			this.fExternalService["placeOfBirth"].clearValidators();
		}
	}

	onChangeSelectCountriesRequest(countryId: string, fiscal: boolean) {
		if (!fiscal) {
			this.states = [];
			this.cities = [];
			this.catalogService.getStates(countryId).subscribe(
				(states) => {
					this.fExternalService["stateId"].patchValue("");

					this.states = states;
				}
			);
		} else {
			this.fiscalStates = [];
			this.fiscalCities = [];
			this.catalogService.getStates(countryId).subscribe(
				(states) => {
					this.fExternalService["fiscalStateId"].patchValue("");

					this.fiscalStates = states;
				}
			);
		}

		this.countryName = this.addressData.countries.find(x => x.code == this.fExternalService['countryId'].value).name;
	}

	onChangeSelectStatesRequest(stateId: string, fiscal: boolean) {
		if (!fiscal) {
			this.cities = [];
			this.catalogService.getCities(this.fExternalService["countryId"].value, stateId).subscribe(
				(cities) => {
					this.fExternalService["cityId"].patchValue("");
					this.cities = cities;
				}
			);
		} else {
			this.fiscalCities = [];
			this.catalogService.getCities(this.fExternalService["fiscalCountryId"].value, stateId).subscribe(
				(cities) => {
					this.fExternalService["fiscalCityId"].patchValue("");
					this.fiscalCities = cities;
				}
			);
		}

		this.stateName = this.states.find(x => x.code == this.fExternalService["stateId"].value).name;
	}

	onChangeSelectCityRequest() {
		this.cityName = this.cities.find(x => x.code == this.fExternalService['cityId'].value).name;
	}

	panelTypePersonOpen(e) {
		this.panelTypePersonOpenState = e;
	}

	panelAdressOpen(e) {
		this.panelAddressOpenState = e;
	}

	panelOtherDataOpen(e) {
		this.panelOtherDataOpenState = e;
	}

	panelCompanyDataOpen(e) {
		this.panelCompanyDataOpenState = e;
	}

	onSubmit() {
		if (this.externalServiceForm.invalid) {
			return;
		}

		if (this.externalServiceSelected.serviceName == 'Llamada de cobranza' && (this.countryName == null || this.countryName == "")) {
			this.countryName = "";
			this.stateName = "";
			this.cityName = ""
		} else {
			this.stateName = this.states.find(x => x.code == this.fExternalService['stateId'].value).name,
				this.cityName = this.cities.find(x => x.code == this.fExternalService['cityId'].value).name
		}

		var createExternalService: CreateExternalServices;

		createExternalService = {
			lenderFullName: this.fExternalService['lenderFullName'].value,
			busisnessName: this.fExternalService['busisnessName'].value,
			firstName: this.fExternalService['firstName'].value,
			middleName: this.fExternalService['middleName'].value,
			lastName1: this.fExternalService['lastName1'].value,
			lastName2: this.fExternalService['lastName2'].value,
			phone: this.fExternalService['phone'].value,
			email: this.fExternalService['email'].value,
			curp: this.fExternalService['curp'].value,
			rfc: this.fExternalService['rfc'].value,
			street: this.fExternalService['street'].value,
			exteriorNumber: this.fExternalService['exteriorNumber'].value,
			interiorNumber: this.fExternalService['interiorNumber'].value,
			districtName: this.fExternalService['districtName'].value,
			countryName: this.countryName,
			stateName: this.stateName,
			cityName: this.cityName,
			zipCode: this.fExternalService['zipCode'].value.toString(),
			loanAmount: this.fExternalService['loanAmount'].value,
			balance: this.fExternalService['balance'].value,
			totalBalancePayment: this.fExternalService['totalBalancePayment'].value,
			capitalExpired: this.fExternalService['capitalExpired'].value,
			totalBalanceLate: this.fExternalService['totalBalanceLate'].value,
			totalBalanceToLiquited: this.fExternalService['totalBalanceToLiquited'].value,
			monthlyRate: this.fExternalService['monthlyRate'].value,
			totalPayment: this.fExternalService['totalPayment'].value,
			monthlyPorcentOnArrears: this.fExternalService['monthlyPorcentOnArrears'].value,
			totalPeriods: this.fExternalService['totalPeriods'].value,
			totalCapitalLate: this.fExternalService['totalCapitalLate'].value,
			interestBalance: this.fExternalService['interestBalance'].value,
			totalInterestToLiquited: this.fExternalService['totalInterestToLiquited'].value,
			firstDatePayment: this.fExternalService['firstDatePayment'].value,
			amountNextPayment: this.fExternalService['amountNextPayment'].value,
			commissionsBalance: this.fExternalService['commissionsBalance'].value,
			dayVisit: this.fExternalService['dayVisit'].value,
			hourStart: this.fExternalService['hourStart'].value,
			hourEnd: this.fExternalService['hourEnd'].value,
			comments: this.fExternalService['comments'].value,
			linkPay: this.fExternalService['linkPay'].value,
			legalConditions: this.legalConditions,
			daysLate: this.fExternalService['daysLate'].value,
			numberOfPayments: this.fExternalService['numberOfPayments'].value,
			sumPaymentsDeposit: this.fExternalService['sumPaymentsDeposit'].value,
			relationShip: this.relationshipName,
			relationShipType: this.relationShipTypeName,
			startDate: this.fExternalService['startDate'].value,
			term: this.fExternalService['term'].value,
			visitType: this.fExternalService['visitType'].value,
			bank: this.fExternalService['bank'].value,
			account: this.fExternalService['account'].value.toString(),
			clabe: this.fExternalService['clabe'].value.toString(),
			cardNumber: this.fExternalService['cardNumber'].value.toString(),

			callType: this.externalServiceType == "llamada" ? 1 : null,
			contactType: this.externalServiceType == "llamada" ? 1 : null,
			personType: this.selectedType,
			phoneAdds: this.adittionalPhone
		};

		this.loanService.createExternalService(this.externalServiceSelected.id, this.accountStatement.loanId, createExternalService).subscribe(
			(result) => {
				this.subscriptionpaymentService.updateCarList(this.subscriptionpaymentService.getCarListItems() + 1);

				this.dialog.open(DialogConfirmComponent, {
					data: {
						title: 'Agregado al carrito',
						message: 'Tu ' + this.externalServiceSelected.serviceName + ' para ' + this.capitalizeFirstLetter(this.fExternalService['firstName'].value + ' ' + this.fExternalService['lastName1'].value) + '.',
						btnCancel: 'Seguir comprando',
						btnConfirm: 'Ir al carrito',
					}
				}).afterClosed().subscribe(
					(confirmado: Boolean) => {
						if (confirmado) {
							this.router.navigate(['/carrito']);
						} else {
							this.stepperExternalServices.selectedIndex = 0;
							this.modifydata = false;
						}
					}
				);
				this.alertGlobalService.showAlertSuccess("Datos guardados con éxito.");
			}
		);
	}

	validateRequiredForm(formControlName: string): boolean {
		if (this.fExternalService[formControlName] == null) {
			return;
		}

		if (this.fExternalService[formControlName].errors !== null && (this.fExternalService[formControlName].errors['required'] || this.fExternalService[formControlName].errors['minlength'])) {
			return true;
		}
		return false;
	}

	setModifyData(val: boolean) {
		this.modifydata = val;
	}

	onChangeVisitType() {
		this.visitTypeName = this.visitTypeList.find(x => x.key == this.fExternalService['visitType'].value).value;
	}

	return() {
		this.stepperExternalServices.selectedIndex = 0;
		this.modifydata = false;
	}

	validateFormrequestJson(val: string): boolean {
		const field = this.externalServiceForm.get(val);
		return field && field.validator && field.validator({} as AbstractControl) && field.validator({} as AbstractControl).hasOwnProperty('required');
	}

	generateHours() {
		for (let i = 9; i <= 20; i++) {
			const startHour = i.toString().padStart(2, '0') + ':00';
			const formattedHourRange = startHour
			this.formattedHours.push(formattedHourRange);
		}
	}

	checkHourEndRange(control) {
		const hourStart = this.externalServiceForm.get('hourStart').value;
		const hourEnd = control.value;

		if (hourStart && hourEnd && hourEnd < hourStart) {
			return { hourRange: true };
		} else {
			return null;
		}
	}

	checkHourStartRange(control) {
		const hourEnd = this.externalServiceForm.get('hourEnd').value;
		const hourStart = control.value;

		if (hourStart && hourEnd && hourStart > hourEnd) {
			return { hourRange: true };
		} else {
			return null;
		}
	}

	showHelp() {
		var help = GlobalLists.helpControlsList.find(x => x.control === this.externalServiceType);
		return help.description;
	}

	capitalizeFirstLetter(value: string): string {
		if (!value || value.length === 0) {
			return '';
		}
		const words = value.split(' ');
		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
		return capitalizedWords.join(' ');
	}

	createContactPhoneForm() {
		this.contactPhoneForm = this.formBuilder.group({
			phoneType: ['', Validators.required],
			phone: [null, [Validators.minLength(10)]],
			comments: ['']
		});
	}

	addContactPhone(): void {
		const phoneValue = this.fContactPhone['phone'].value;
		if (phoneValue != null) {
			const phoneNumber = phoneValue.replace(/[^0-9]/g, '');

			if (phoneNumber.length !== 10) {
				this.validateRequeridPhone = true
				return;
			}
			this.validateRequeridPhone = false;
			this.contactPhone.push(<ContactPhone>{
				phoneType: this.fContactPhone['phoneType'].value,
				phone: phoneNumber,
				comments: this.fContactPhone['comments'].value,
			});

			this.dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);
			this.contactPhoneForm.reset();

			this.updateContactData();
		} else {
			this.validateRequeridPhone = true

		}

	}

	onSubmitContactPhone() {
		if (this.contactPhoneForm.invalid || this.getIsDisabledSave()) {
			return;
		}

		this.addContactPhone();
	}

	getIsDisabledSave() {
		if (this.isDisabled) {
			return true;
		} else {
			return false;
		}
	}

	onDeleteContactPhone(index: number): void {
		if (this.getIsDisabledSave()) {
			return;
		}

		this.contactPhone.splice(index, 1);
		this.dsContactPhoneData = new MatTableDataSource<ContactPhone>(this.contactPhone);

		this.updateContactData();
	}

	updateContactData() {
		this.adittionalPhone = this.contactPhone.map((phoneData) => ({
			phoneType: phoneData.phoneType,
			phone: phoneData.phone.replace(/-/g, ''),
			comments: phoneData.comments
		}));
	}
}
