<div>
    <form [formGroup]="dataJsonForm" (ngSubmit)="onSubmit()">
        <div class="subtittle">
            Modificar datos
        </div>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panel" class="mb-4" (opened)="panelOpen(true)" (closed)="panelOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Solicitud</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="firstName" class="">Primer nombre*</label>
                            <input type="text" formControlName="firstName" class="form-control"
                                [ngClass]="validateRequired('firstName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="middleName" class="">Segundo nombre</label>
                            <input type="text" formControlName="middleName" class="form-control"
                                [ngClass]="validateRequired('middleName') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="lastName1" class="">Primer apellido*</label>
                            <input type="text" formControlName="lastName1" class="form-control"
                                [ngClass]="validateRequired('lastName1') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="lastName2" class="">Segundo apellido*</label>
                            <input type="text" formControlName="lastName2" class="form-control"
                                [ngClass]="validateRequired('lastName2') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="email" class="">Correo*</label>
                            <input type="email" formControlName="email" class="form-control"
                                [ngClass]="validateRequired('email') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="loanAmount" class="">Monto*</label>
                            <input type="text" formControlName="loanAmount" keyboardType="numeric" placeholder="$ 0"
                                [textMask]="{mask: currencyMask}" class="form-control"
                                [ngClass]="validateRequired('loanAmount') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="typeOfPeriod" class="">Frecuencia pagos*</label>
                            <select class="form-control" formControlName="typeOfPeriod"
                                [ngClass]="validateRequired('typeOfPeriod') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of typeOfPeriodList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="totalPeriods" class="">Número pagos*</label>
                            <input type="number" formControlName="totalPeriods" class="form-control text-right"
                                [ngClass]="validateRequired('totalPeriods') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="monthlyRate" class="">Interés mensual*</label>
                            <input type="text" placeholder="0 %" [textMask]="{mask: percentMask}"
                                formControlName="monthlyRate" class="form-control text-right"
                                [ngClass]="validateRequired('monthlyRate') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="startDate" class="">Fecha inicio*</label>
                            <input type="date" formControlName="startDate" class="form-control"
                                [ngClass]="validateRequired('startDate') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="typeOfCredit" class="">Tipo préstamo*</label>
                            <select class="form-control" formControlName="typeOfCredit"
                                [ngClass]="validateRequired('typeOfCredit') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of typeOfCreditList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="typeOfAmortization" class="">Calculo interéses*</label>
                            <select class="form-control" formControlName="typeOfAmortization"
                                [ngClass]="validateRequired('typeOfAmortization') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of typeOfAmortizationList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="monthlyPorcentOnArrears" class="">Interes moratorio mensual*</label>
                            <input type="text" placeholder="0 %" [textMask]="{mask: percentMask}"
                                formControlName="monthlyPorcentOnArrears" class="form-control text-right"
                                [ngClass]="validateRequired('monthlyPorcentOnArrears') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="periodAmortizeStart" class="">Periodo inicial*</label>
                            <input type="number" formControlName="periodAmortizeStart" class="form-control"
                                [ngClass]="validateRequired('periodAmortizeStart') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="taxPorcent" class="">IVA*</label>
                            <select class="form-control" formControlName="taxPorcent"
                                [ngClass]="validateRequired('taxPorcent') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of taxList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="percentRetentionISR" class="">Retención ISR*</label>
                            <input type="text" formControlName="percentRetentionISR" class="form-control"
                                [ngClass]="validateRequired('percentRetentionISR') ? 'is-invalid' : ''">
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button class="btn btn-outline-primary external-btn" mat-dialog-close>
                Cerrar
            </button>
            <button type="submit" class="btn btn-primary external-btn">
                Guardar
            </button>
        </div>
    </form>
</div>