<div class="card_services">
  <div>
    <div class="d-flex align-items-center mb-3">
      <i class="bx big primary" [ngClass]="externalServiceSelected.image"></i>
      <div class="ms-4">
        <h5 class="fw-bold">
          {{externalServiceSelected.serviceName}}
        </h5>
        <p>Verifica que los datos para la {{externalServiceSelected.serviceName}} sean correctos</p>
      </div>
    </div>

    <form class="form-horizontal" [formGroup]="externalServiceForm" (ngSubmit)="onSubmit()">
      <div *ngIf="!modifydata" class="row custom_card">
        <div *ngIf="personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Razón social</p>
          <p>{{fExternalService['busisnessName'].value}}</p>
        </div>
        <div *ngIf="personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Nombre</p>
          <p>{{fExternalService['firstName'].value}}</p>
        </div>
        <div *ngIf="personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Primer Apellido</p>
          <p>{{fExternalService['lastName1'].value}}</p>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Nombre</p>
          <p>{{fExternalService['firstName'].value}}</p>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Segundo nombre</p>
          <p>{{fExternalService['middleName'].value}}</p>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Primer apellido</p>
          <p>{{fExternalService['lastName1'].value}}</p>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Segundo apellido</p>
          <p>{{fExternalService['lastName2'].value}}</p>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Teléfono celular</p>
          <p>{{ fExternalService['phone'].value != '' ? fExternalService['phone'].value : '----' }}</p>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Correo electrónico</p>
          <p>{{ fExternalService['email'].value != '' ? fExternalService['email'].value : '----' }}</p>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>CURP</p>
          <p>{{ fExternalService['curp'].value != '' ? fExternalService['curp'].value : '----' }}</p>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-lg-3 col-md-6 col-12 mb-3">
          <p>RFC</p>
          <p>{{ fExternalService['rfc'].value != '' ? fExternalService['rfc'].value : '----' }}</p>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Calle</p>
          <p>{{fExternalService['street'].value}}</p>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Número exterior</p>
          <p>{{fExternalService['exteriorNumber'].value}}</p>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Número interior</p>
          <p>{{fExternalService['interiorNumber'].value != '' ? fExternalService['interiorNumber'].value : '-'}}</p>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Colonia</p>
          <p>{{fExternalService['districtName'].value}}</p>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <p>País</p>
          <p>{{countryName}}</p>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Estado</p>
          <p>{{stateName}}</p>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Municipio</p>
          <p>{{cityName}}</p>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <p>Código postal</p>
          <p>{{fExternalService['zipCode'].value}}</p>
        </div>

        <div class="d-flex justify-content-end col-12 pt-2">
          <button (click)="setModifyData(true)" class="btn_primary_outline">
            <a class="nav-link">Modificar datos</a>
          </button>
        </div>
      </div>

      <div *ngIf="modifydata" class="row custom_card">
        <div *ngIf="personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="busisnessName">Razón social<span
                *ngIf="validateFormrequestJson('busisnessName')">*</span></label>
            <input type="text" formControlName="busisnessName" class="form-control"
              [ngClass]="validateRequiredForm('busisnessName') ? 'is-invalid' : ''">
          </div>
        </div>
        <div *ngIf="personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="firstName">Nombre<span *ngIf="validateFormrequestJson('firstName')">*</span></label>
            <input type="text" formControlName="firstName" class="form-control"
              [ngClass]="validateRequiredForm('firstName') ? 'is-invalid' : ''">
          </div>
        </div>
        <div *ngIf="personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="lastName1">Primer Apellido<span *ngIf="validateFormrequestJson('lastName1')">*</span></label>
            <input type="text" formControlName="lastName1" class="form-control"
              [ngClass]="validateRequiredForm('lastName1') ? 'is-invalid' : ''">
          </div>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="firstName">Nombre<span *ngIf="validateFormrequestJson('firstName')">*</span></label>
            <input type="text" formControlName="firstName" class="form-control"
              [ngClass]="validateRequiredForm('firstName') ? 'is-invalid' : ''">
          </div>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="middleName">Segundo nombre<span *ngIf="validateFormrequestJson('middleName')">*</span></label>
            <input type="text" formControlName="middleName" class="form-control"
              [ngClass]="validateRequiredForm('middleName') ? 'is-invalid' : ''">
          </div>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="lastName1">Primer apellido<span *ngIf="validateFormrequestJson('lastName1')">*</span></label>
            <input type="text" formControlName="lastName1" class="form-control"
              [ngClass]="validateRequiredForm('lastName1') ? 'is-invalid' : ''">
          </div>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="lastName2">Segundo apellido<span *ngIf="validateFormrequestJson('lastName2')">*</span></label>
            <input type="text" formControlName="lastName2" class="form-control"
              [ngClass]="validateRequiredForm('lastName2') ? 'is-invalid' : ''">
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="phone">Teléfono celular<span *ngIf="validateFormrequestJson('phone')">*</span></label>
            <input type="text" [textMask]="{mask: phoneMask}" formControlName="phone" class="form-control"
              [ngClass]="validateRequiredForm('phone') ? 'is-invalid' : ''">
          </div>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="email">Correo electrónico<span *ngIf="validateFormrequestJson('email')">*</span></label>
            <input type="text" formControlName="email" class="form-control"
              [ngClass]="validateRequiredForm('email') ? 'is-invalid' : ''">
          </div>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="curp">CURP<span *ngIf="validateFormrequestJson('curp')">*</span></label>
            <input type="text" formControlName="curp" class="form-control"
              [ngClass]="validateRequiredForm('curp') ? 'is-invalid' : ''">
          </div>
        </div>
        <div *ngIf="!personData.checkApplyMoralPerson" class="col-md-3 mb-3">
          <div class="form-group">
            <label for="rfc">RFC<span *ngIf="validateFormrequestJson('rfc')">*</span></label>
            <input type="text" formControlName="rfc" class="form-control"
              [ngClass]="validateRequiredForm('rfc') ? 'is-invalid' : ''">
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="street">Calle<span *ngIf="validateFormrequestJson('street')">*</span></label>
            <input type="text" formControlName="street" class="form-control"
              [ngClass]="validateRequiredForm('street') ? 'is-invalid' : ''">
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="exteriorNumber">Número exterior<span
                *ngIf="validateFormrequestJson('exteriorNumber')">*</span></label>
            <input type="text" formControlName="exteriorNumber" class="form-control"
              [ngClass]="validateRequiredForm('exteriorNumber') ? 'is-invalid' : ''">
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="interiorNumber">Número interior<span
                *ngIf="validateFormrequestJson('interiorNumber')">*</span></label>
            <input type="text" formControlName="interiorNumber" class="form-control"
              [ngClass]="validateRequiredForm('interiorNumber') ? 'is-invalid' : ''">
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="districtName">Colonia<span *ngIf="validateFormrequestJson('districtName')">*</span></label>
            <input type="text" formControlName="districtName" class="form-control"
              [ngClass]="validateRequiredForm('districtName') ? 'is-invalid' : ''">
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="countryId">País<span *ngIf="validateFormrequestJson('countryId')">*</span></label>
            <select #selectCountry class="form-control" formControlName="countryId"
              (change)="onChangeSelectCountriesRequest(selectCountry.value, false)"
              [ngClass]="dr && validateRequiredForm('value-CountryId') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let item of addressData.countries" value="{{item.code}}">
                {{item.name}}
              </option>
              <option value="Otro">Otro</option>
            </select>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="stateId">Estado<span *ngIf="validateFormrequestJson('stateId')">*</span></label>
            <select #selectState class="form-control" formControlName="stateId"
              (change)="onChangeSelectStatesRequest(selectState.value, false)"
              [ngClass]="dr && validateRequiredForm('stateId') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let item of states" value="{{item.code}}">{{item.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="cityId">Municipio<span *ngIf="validateFormrequestJson('cityId')">*</span></label>
            <select class="form-control" formControlName="cityId"
              [ngClass]="dr && validateRequiredForm('cityId') ? 'is-invalid' : ''"
              (change)="onChangeSelectCityRequest()">
              <option value="">Seleccione</option>
              <option *ngFor="let item of cities" value="{{item.code}}">{{item.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="zipCode">Código postal<span *ngIf="validateFormrequestJson('zipCode')">*</span></label>
            <input type="number" formControlName="zipCode" class="form-control"
              [ngClass]="dr && validateRequiredForm('zipCode') ? 'is-invalid' : ''">
          </div>
        </div>

        <div class="d-flex justify-content-end col-12 pt-2">
          <button (click)="setModifyData(false)" class="btn_primary_outline">
            <a class="nav-link">Continuar</a>
          </button>
        </div>
      </div>

      <div class="d-flex align-items-center mb-3">
        <div class="ms-5-5">
          <h5 class="fw-bold">
            Datos opcionales
          </h5>
          <p>
            Te recomendamos llenar la siguiente información para el Gestor que realizará la
            {{externalServiceSelected.serviceName.toLowerCase()}}.
          </p>
        </div>
      </div>
      <div class="row custom_card">
        <h5 class="fw-bold">
          Condiciones de {{externalServiceType}}
        </h5>
        <p class="fw-normal mb-2">
          Si tienes recomendaciones para la {{externalServiceSelected.serviceName.toLowerCase()}}, ingresa la siguiente
          información.
        </p>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="dayVisit">Dia {{externalServiceType}}</label>
            <input autofocus="autofocus" class="form-control" formControlName="dayVisit" type="date"
              [min]="startDateString" [ngClass]="validateRequiredForm('dayVisit') ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="hourStart">Horario {{externalServiceType}} desde</label>
            <select #selectHourVisit class="form-control" formControlName="hourStart"
              [ngClass]="dr && validateRequiredForm('hourStart') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let hourRange of formattedHours" [value]="hourRange">{{hourRange}}</option>
            </select>
            <mat-error *ngIf="externalServiceForm.get('hourStart').hasError('hourRange')">La hora de inicio debe ser
              menor que la hora de finalización.</mat-error>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="hourEnd"> hasta</label>
            <select #selectHourVisit class="form-control" formControlName="hourEnd"
              [ngClass]="dr && validateRequiredForm('hourEnd') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let hourRange of formattedHours" [value]="hourRange">{{hourRange}}</option>
            </select>
            <mat-error *ngIf="externalServiceForm.get('hourEnd').hasError('hourRange')">La hora de finalización debe ser
              mayor que la hora de inicio.</mat-error>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="visitType">Tipo de {{externalServiceType}} <i class="bx bx-info-circle"
                matTooltip="{{showHelp()}}"></i></label>
            <select class="form-control" formControlName="visitType"
              [ngClass]="dr && validateRequiredForm('visitType') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let item of visitTypeList" value="{{item.value}}">{{item.value}}
              </option>
            </select>
          </div>
        </div>
        <hr>

        <ng-container *ngIf="externalServiceSelected.serviceName == 'Llamada de cobranza'">
          <h5 class="fw-bold">
            Teléfonos adicionales
          </h5>
          <p class="fw-normal mb-2">
            En caso de que quieras agregar algún otro número telefónico para contactar al Solicitante, ingrésalo a continuación.
            Si deseas que algún número telefónico registrado no sea contactado, puedes eliminarlo de la lista.
          </p>
          <form [formGroup]="contactPhoneForm" (ngSubmit)="onSubmitContactPhone()">
            <div *ngIf="!getIsDisabledSave()" class="row">
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Tipo de teléfono </label>
                  <select class="form-control" formControlName="phoneType">
                    <option *ngFor="let item of phoneList" value="{{item.value}}">{{item.value}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Número</label>
                  <input class="form-control" type="text" [textMask]="{mask: phoneMask}" placeholder=""
                    formControlName="phone" autocomplete="off" autocapitalize="off">
                </div>
                <mat-error *ngIf="validateRequeridPhone">
                  El número debe tener 10 dígitos.
                </mat-error>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Comentarios</label>
                  <input class="form-control" type="text" placeholder="" formControlName="comments" autocomplete="off"
                    autocapitalize="off">
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <br>
                <button class="btn btn-outline-primary internal-btn" [disabled]="contactPhoneForm.invalid">
                  Agregar
                </button>
              </div>
            </div>

            <div class="table-responsive mb-3">
              <div class="react-bootstrap-table">
                <table *ngIf="dsContactPhoneData" mat-table [dataSource]="dsContactPhoneData">
                  <ng-container matColumnDef="phoneType">
                    <th mat-header-cell *matHeaderCellDef> Tipo de teléfono
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.phoneType}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Número </th>
                    <td mat-cell *matCellDef="let row">
                      <span>{{row.phone}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="comments">
                    <th mat-header-cell *matHeaderCellDef> Comentarios </th>
                    <td mat-cell *matCellDef="let row"> {{row.comments}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="buttonRemove">
                    <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                    <td mat-cell *matCellDef="let row; let i = index;">
                      <i class="bx bx-x" (click)="onDeleteContactPhone(i)"></i>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="contactPhoneColumns">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: contactPhoneColumns;">
                  </tr>
                </table>
              </div>
            </div>
          </form>
          <hr>
        </ng-container>

        <h5 class="fw-bold">
          Forma de pago
        </h5>
        <p class="fw-normal mb-2">
          Ingresa la información de tu cuenta bancaria en caso que el solicitante desee pagar por medio de un depósito o
          transferencia.
        </p>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="bank">
              Banco
            </label>
            <select class="form-control" formControlName="bank"
              [ngClass]="dr && validateRequiredForm('bank') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let item of bankList" value="{{item.value}}">{{item.value}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="account">
              Cuenta
            </label>
            <input class="form-control" formControlName="account" type="text" [textMask]="{mask: accountMask}"
              [ngClass]="validateRequiredForm('account') ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="clabe">
              CLABE
            </label>
            <input class="form-control" formControlName="clabe" type="text" [textMask]="{mask: clabeMask}"
              [ngClass]="validateRequiredForm('clabe') ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="cardNumber">
              Número de tarjeta
            </label>
            <input class="form-control" formControlName="cardNumber" type="text" [textMask]="{mask: cardMask}"
              [ngClass]="validateRequiredForm('cardNumber') ? 'is-invalid' : ''" />
          </div>
        </div>

        <div class="col-12 mb-3">
          <label for="linkPay">
            Enlace de pago
          </label>
          <div class="col-12">
            <div class="form-group">
              <textarea formControlName="linkPay" class="form-control" rows="3"></textarea>
            </div>
          </div>
        </div>
        <hr>

        <h5 class="fw-bold">
          Comentarios adicionales
        </h5>
        <div class="col-12">
          <div class="form-group">
            <textarea formControlName="comments" class="form-control" rows="3"></textarea>
          </div>
        </div>
      </div>

      <app-input-errors-global *ngFor="let item of fExternalServiceProp" fieldName="{{item}}">
      </app-input-errors-global>

      <div class="d-flex justify-content-end align-items-center mt-4 gap-2">
        <button (click)="return()" class="btn_outline" type="button">
          <a class="nav-link btn-mid">Regresar</a>
        </button>

        <button class="btn_yellow" *ngIf="!modifydata">
          <a class="nav-link btn-mid">
            Agregar al carrito
          </a>
        </button>
      </div>
    </form>
  </div>
</div>