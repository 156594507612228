<form [formGroup]="socioeconomicJsonForm">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelSocioeconomicState" class="mb-4 bg-white"
            (opened)="panelSocioeconomicOpen(true)"
            (closed)="panelSocioeconomicOpen(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <h5>Datos socioeconómicos</h5>
                        <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                    </div>
                </mat-panel-title> 
            </mat-expansion-panel-header>

            <div class="row">
                <div *ngIf="validateFormrequest('ConstructionType')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-ConstructionType" class="">Tipo construcción<span *ngIf="validateFormrequestJson('ConstructionType')">*</span></label>
                        <select class="form-control" formControlName="value-ConstructionType" [ngClass]="validateRequiredFormF8('value-ConstructionType') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of constructionTypeList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('FloorType')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-FloorType" class="">Tipo piso<span *ngIf="validateFormrequestJson('FloorType')">*</span></label>
                        <select class="form-control" formControlName="value-FloorType" [ngClass]="validateRequiredFormF8('value-FloorType') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of floorTypeList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('CeilingType')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-CeilingType" class="">Tipo techo<span *ngIf="validateFormrequestJson('CeilingType')">*</span></label>
                        <select class="form-control" formControlName="value-CeilingType" [ngClass]="validateRequiredFormF8('value-CeilingType') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of ceilingTypeList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('CookingFueltype')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-CookingFueltype" class="">Tipo combustible cocina<span *ngIf="validateFormrequestJson('CookingFueltype')">*</span></label>
                        <select class="form-control" formControlName="value-CookingFueltype" [ngClass]="validateRequiredFormF8('value-CookingFueltype') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of cookingFuelTypeList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('ElectricLight')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-ElectricLight" class="">Luz eléctrica<span *ngIf="validateFormrequestJson('ElectricLight')">*</span></label>
                        <select class="form-control" formControlName="value-ElectricLight" [ngClass]="validateRequiredFormF8('value-ElectricLight') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('TubingWater')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-TubingWater" class="">Agua entubada<span *ngIf="validateFormrequestJson('TubingWater')">*</span></label>
                        <select class="form-control" formControlName="value-TubingWater" [ngClass]="validateRequiredFormF8('value-TubingWater') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('SewerSystem')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-SewerSystem" class="">Drenaje<span *ngIf="validateFormrequestJson('SewerSystem')">*</span></label>
                        <select class="form-control" formControlName="value-SewerSystem" [ngClass]="validateRequiredFormF8('value-SewerSystem') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('PayTV')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-PayTV" class="">TV paga<span *ngIf="validateFormrequestJson('PayTV')">*</span></label>
                        <select class="form-control" formControlName="value-PayTV" [ngClass]="validateRequiredFormF8('value-PayTV') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Internet')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Internet" class="">Internet<span *ngIf="validateFormrequestJson('Internet')">*</span></label>
                        <select class="form-control" formControlName="value-Internet" [ngClass]="validateRequiredFormF8('value-Internet') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Computer')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Computer" class="">Computadora/ laptop<span *ngIf="validateFormrequestJson('Computer')">*</span></label>
                        <select class="form-control" formControlName="value-Computer" [ngClass]="validateRequiredFormF8('value-Computer') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Smartphone')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Smartphone" class="">Smartphone<span *ngIf="validateFormrequestJson('Smartphone')">*</span></label>
                        <select class="form-control" formControlName="value-Smartphone" [ngClass]="validateRequiredFormF8('value-Smartphone') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Tablet')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Tablet" class="">Tablet<span *ngIf="validateFormrequestJson('Tablet')">*</span></label>
                        <select class="form-control" formControlName="value-Tablet" [ngClass]="validateRequiredFormF8('value-Tablet') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('BankAccount')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-BankAccount" class="">Cuenta bancaria<span *ngIf="validateFormrequestJson('BankAccount')">*</span></label>
                        <select class="form-control" formControlName="value-BankAccount" [ngClass]="validateRequiredFormF8('value-BankAccount') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('OnlineBank')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-OnlineBank" class="">Banca en línea<span *ngIf="validateFormrequestJson('OnlineBank')">*</span></label>
                        <select class="form-control" formControlName="value-OnlineBank" [ngClass]="validateRequiredFormF8('value-OnlineBank') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('Remittances')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-Remittances" class="">Remesas<span *ngIf="validateFormrequestJson('Remittances')">*</span></label>
                        <select class="form-control" formControlName="value-Remittances" [ngClass]="validateRequiredFormF8('value-Remittances') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('HealthInsurance')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-HealthInsurance" class="">Seguro gastos médicos<span *ngIf="validateFormrequestJson('HealthInsurance')">*</span></label>
                        <select class="form-control" formControlName="value-HealthInsurance" [ngClass]="validateRequiredFormF8('value-HealthInsurance') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of yesnoList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('SchoolLevel')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-SchoolLevel" class="">Nivel escolar<span *ngIf="validateFormrequestJson('SchoolLevel')">*</span></label>
                        <select class="form-control" formControlName="value-SchoolLevel" [ngClass]="validateRequiredFormF8('value-SchoolLevel') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of educationLevelList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="validateFormrequest('RoomsNumber')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-RoomsNumber" class="">Número cuartos<span *ngIf="validateFormrequestJson('RoomsNumber')">*</span></label>
                        <input class="form-control" type="number" placeholder="" formControlName="value-RoomsNumber" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('WindowsNumber')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-WindowsNumber" class="">Número ventanas<span *ngIf="validateFormrequestJson('WindowsNumber')">*</span></label>
                        <input class="form-control" type="number" placeholder="" formControlName="value-WindowsNumber" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
                <div *ngIf="validateFormrequest('LightsNumber')" class="col-md-3 mb-3">
                    <div class="form-group">
                        <label for="value-LightsNumber" class="">Número focos<span *ngIf="validateFormrequestJson('LightsNumber')">*</span></label>
                        <input class="form-control" type="number" placeholder="" formControlName="value-LightsNumber" autocomplete="off" autocapitalize="off">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>