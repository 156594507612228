import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanService } from 'src/app/services/loan/loan.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  isLender = false;
  myTxt = '';
  opened = false;
  borrowerSwitchActive: boolean;
  blogId = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private loanService: LoanService,
              private authService: AuthenticationService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isLender = this.authService.currentUserModeLenderValue;
    this.borrowerSwitchActive = false;
    this.blogId = this.route.snapshot.paramMap.get('blogId');

    $('button').click(() => {});
  }

  return() {
    window.location.href = "blog";
  }
}
