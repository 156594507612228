import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CustomErrorStateMatcher } from 'src/app/functions/custom-error';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email: string;
  token: string;
  form: FormGroup;
  submitted = false;
  requiredErrorMessage: string = "Este campo es requerido.";
  matcher = new CustomErrorStateMatcher();
	hide = true;
	hideConfirm = true;
  
  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private authService: AuthenticationService,
              private router: Router,
              private route: ActivatedRoute) {
                // redirect to home if already logged in
                if (this.authService.currentUserValue) { 
                  this.router.navigate(['/inicio']);
                }
                this.createForm();
              }

  get f1() { return this.form.controls; }

  ngOnInit(): void {
    sessionStorage["temp_email"] = this.route.snapshot.paramMap.get('email');
    sessionStorage["temp_token"] = this.route.snapshot.paramMap.get('token');
  }

  createForm() {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['']
    },
    {
      validator: this.checkPasswords
    });
  }

  onSubmit() {
    this.submitted = true;
    if(this.form.invalid) {
      return;
    }

    this.resetPassword();
  }

  validateRequiredForm(formControlName: string): boolean {
    if(this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
      return true;
    }
    return false;
  }

  resetPassword() {
    this.userService.resetPassword(sessionStorage["temp_email"], sessionStorage["temp_token"], this.f1['password'].value)
    .subscribe((data) => {
      this.userService.sendForgotPasswordEmail(sessionStorage["temp_email"]).subscribe(() => {});
      sessionStorage.removeItem('temp_email');
      sessionStorage.removeItem('temp_token');
     
    });
  }

  
  checkPasswords(group: FormGroup) {
    let password = group.get('password').value;
    let confirmPassword = group.get('confirmPassword').value;

    return password === confirmPassword ? null : { notSame: true };   
  }

	getClass() {
	  if (this.hide) {
		return 'fa fa-eye';
  
	  } else {
		return 'fa fa-eye-slash';
	  }
	}

	getClassC() {
	  if (this.hideConfirm) {
		return 'fa fa-eye';
  
	  } else {
		return 'fa fa-eye-slash';
	  }
	}
}