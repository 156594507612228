<h1 mat-dialog-title>Opciones avanzadas</h1>
<form  [formGroup]="form" (ngSubmit)="onSubmitAdvancedOptions()">
    <div mat-dialog-content>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="33%" appearance="fill">
                <label class="label-input" matPrefix>Tipo de crédito</label>
                <mat-select #selectTypeOfCredit formControlName="typeOfCredit" (selectionChange)="onChangeSelectTypeOfCredit(selectTypeOfCredit.value)">
                    <mat-option value="">Seleccione</mat-option>
                    <mat-option *ngFor="let item of typeOfCreditList" value="{{item.key}}">{{item.value}}</mat-option>
                </mat-select>
                <mat-hint>Selecciona el tipo de crédito</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="33%" appearance="fill">
                <label class="label-input" matPrefix>Tipo de amortización</label>
                <mat-select #select formControlName="typeOfAmortization">
                    <mat-option value="">Seleccione</mat-option>
                    <mat-option *ngFor="let item of typeOfAmortizationList" value="{{item.key}}">{{item.value}}</mat-option>
                </mat-select>
                <mat-hint>Selecciona el tipo de amortización</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="33%" appearance="fill">
                <label class="label-input" matPrefix>Periodo inicial</label>
                <input matInput type="number" placeholder="" name="periodAmortizeStart" formControlName="periodAmortizeStart" autocomplete="off" autocapitalize="off" min="1">
                <mat-hint>Escribe o selecciona el periodo inicial</mat-hint>
            </mat-form-field>
        </div>


        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="33%" appearance="fill">
                <label class="label-input" matPrefix>Garantía</label>
                <mat-select #select formControlName="warranty">
                    <mat-option value="">Seleccione</mat-option>
                    <mat-option *ngFor="let item of warrantyList" value="{{item.key}}">{{item.value}}</mat-option>
                </mat-select>
                <mat-hint>Selecciona la garantía</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="33%" appearance="fill">
                <label class="label-input" matPrefix>Interés moratorio</label>
                <input matInput type="number" placeholder="" name="monthlyPorcentOnArrears" formControlName="monthlyPorcentOnArrears" autocomplete="off" autocapitalize="off">
                <mat-hint>Escribe o selecciona el interés moratorio</mat-hint>
            </mat-form-field>
            <section fxFlex="33%">
                <mat-slide-toggle labelPosition="before" color="accent" formControlName="applyTax">Agregar IVA</mat-slide-toggle>
            </section>
        </div>


        <mat-accordion>
            <mat-expansion-panel [expanded]="panelCommissionOpenState"
                                (opened)="panelCommissionOpenState = true"
                                (closed)="panelCommissionOpenState = false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-slide-toggle labelPosition="before" [checked]="panelCommissionOpenState">Agregar comisiones</mat-slide-toggle>
                </mat-panel-title>
                <!--mat-panel-description>
                    {{panelCommissionOpenState ? '' : 'Sin comisiones'}}
                </mat-panel-description-->
                </mat-expansion-panel-header>
                <form [formGroup]="commissionsForm" (ngSubmit)="onSubmitCommission()" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                    <mat-form-field fxFlex="20%" appearance="fill">
                        <mat-label>Descripción</mat-label>
                        <input matInput type="text" placeholder="" formControlName="description" autocomplete="off" autocapitalize="off" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let item of filteredOptions | async" [value]="item">
                                {{item}}
                            </mat-option>
                            </mat-autocomplete>
                        <mat-error *ngIf="fCommissions.description.errors">{{requiredErrorMessage}}</mat-error>
                    </mat-form-field>
                    <div fxFlex="20%">
                        <mat-slide-toggle labelPosition="before" (change)="isPorcentCommission = !isPorcentCommission" color="primary">Es porcentaje</mat-slide-toggle>
                    </div>
                    <mat-form-field fxFlex="20%" appearance="fill">
                        <mat-label>{{isPorcentCommission ? 'Porcentaje' : 'Monto'}}</mat-label>
                        <input matInput type="number" placeholder="" formControlName="value" autocomplete="off" autocapitalize="off">
                        <mat-error *ngIf="fCommissions.value.errors">{{requiredErrorMessage}}</mat-error>
                        <span matSuffix *ngIf="isPorcentCommission">%</span>
                        <span matPrefix *ngIf="!isPorcentCommission">$</span>
                    </mat-form-field>
                    <mat-form-field fxFlex="20%" appearance="fill">
                        <mat-label>Se paga</mat-label>
                        <mat-select #selectWhenToPay formControlName="whenToPay">
                            <mat-option *ngFor="let item of whenToPayArray" value="{{item.key}}">{{item.value}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="fCommissions.whenToPay.errors">{{requiredErrorMessage}}</mat-error>
                    </mat-form-field>
                    <div fxFlex="20%">
                        <button title="Agregar comisión" mat-raised-button>
                            <span class="material-icons">add</span>
                        </button>
                    </div>
                </form>

                <div class="table-responsive">
                    <div class="react-bootstrap-table">
                        <table *ngIf="dsCommissions && dsCommissions.data.length > 0" mat-table [dataSource]="dsCommissions">
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef> Descripción </th>
                                <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                            </ng-container>

                            <ng-container matColumnDef="value">
                                <th mat-header-cell *matHeaderCellDef> Valor </th>
                                <td mat-cell *matCellDef="let row">
                                    <span *ngIf="row.isPorcent">{{row.value | number:'1.2-2'}} %</span>
                                    <span *ngIf="!row.isPorcent">{{row.value | currency}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="whenToPay">
                                <th mat-header-cell *matHeaderCellDef> Cuando se paga </th>
                                <td mat-cell *matCellDef="let row"> {{row.whenToPayDesc}} </td>
                            </ng-container>

                            <ng-container matColumnDef="buttonRemove">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let row; let i = index;">
                                    <i class="bx bx-x" (click)="onDeleteCommission(i)"></i>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="commissionsColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: commissionsColumns;"></tr>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>


        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <section fxFlex="33%">
                <mat-slide-toggle formControlName="customPayments" #checkCustomPayments labelPosition="before" (change)="applyCustomPayments(checkCustomPayments.checked)">Personalizar pagos</mat-slide-toggle>
            </section>
            <section fxFlex="33%">
                <button type="button" (click)="showDialogTable()" [disabled]="!checkCustomPayments.checked" mat-flat-button color="primary">Personalizar aquí tus pagos <span class="material-icons">tune</span></button>
            </section>
            <section fxFlex="33%"></section>
        </div>

    </div>
    <div mat-dialog-actions align="end">
        <button type="button" mat-stroked-button (click)="resetForm()">Reiniciar <span class="material-icons">settings_backup_restore</span></button>
        <button type="button" mat-flat-button color="accent" [mat-dialog-close]="form">Continuar <span class="material-icons">east</span></button>
    </div>
</form>
