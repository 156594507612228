<div class="form-horizontal">
    <div class="row">
        <mat-accordion>
            <mat-expansion-panel [expanded]="panelReferenceState" class="mb-4 bg-white"
                (opened)="panelReferenceOpen(true)" (closed)="panelReferenceOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="d-flex">
                            <h5>Datos referencias</h5>
                            <i *ngIf="dr" [ngClass]="completed ? 'bx-check' : 'bx-error-circle'" class="bx ms-3"></i>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-accordion *ngIf="personType != 1">
                    <mat-expansion-panel [expanded]="panelPersonState" (opened)="panelPersonOpen(true)"
                        (closed)="panelPersonOpen(false)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h5>Referencias familiares / personales</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <form *ngIf="!getIsDisabledSave()" [formGroup]="personReferenceForm" (ngSubmit)="onSubmitReferencePerson()">
                            <div class="row">
                                <div class="subtittle2">Agregar referencia</div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group"> <label>Nombre*</label> <input class="form-control"
                                            type="text" formControlName="name" autocomplete="off" autocapitalize="off"
                                            [ngClass]="submitedPerson && validateRequiredFormPerson('name') ? 'is-invalid' : ''">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group"> <label>Segundo nombre</label> <input class="form-control"
                                            type="text" formControlName="secondName" autocomplete="off"
                                            autocapitalize="off">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group"> <label>Primer apellido*</label> <input class="form-control"
                                            type="text" formControlName="lastName1" autocomplete="off"
                                            autocapitalize="off"
                                            [ngClass]="submitedPerson && validateRequiredFormPerson('lastName1') ? 'is-invalid' : ''">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group"> <label>Segundo apellido</label> <input class="form-control"
                                            type="text" formControlName="lastName2" autocomplete="off"
                                            autocapitalize="off">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group"> <label>Teléfono celular*</label> <input
                                            class="form-control" type="text" [textMask]="{mask: phoneMask}"
                                            formControlName="cellPhone" autocomplete="off" autocapitalize="off"
                                            [ngClass]="submitedPerson && validateRequiredFormPerson('cellPhone') ? 'is-invalid' : ''">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group"> <label>Correo electrónico</label> <input
                                            class="form-control" type="text" formControlName="email" autocomplete="off"
                                            autocapitalize="off">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group"> <label>Tipo Relación </label> <select class="form-control"
                                            formControlName="relationShip">
                                            <option *ngFor="let item of getContactRelationshipList('0')"
                                                value="{{item.key}}">{{item.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group"> <label>Tiempo Relación (años)</label>
                                        <input class="form-control" type="text" formControlName="timeToMeet"
                                            autocomplete="off" autocapitalize="off">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <button class="btn btn-outline-primary internal-btn">
                                        Agregar
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div class="table-responsive">
                            <div class="react-bootstrap-table">
                                <table *ngIf="dsReferencePersonData" mat-table [dataSource]="dsReferencePersonData">
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="secondName">
                                        <th mat-header-cell *matHeaderCellDef> Segundo nombre </th>
                                        <td mat-cell *matCellDef="let row"> {{row.secondName}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lastName1">
                                        <th mat-header-cell *matHeaderCellDef> Primer apellido </th>
                                        <td mat-cell *matCellDef="let row"> {{row.lastName1}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lastName2">
                                        <th mat-header-cell *matHeaderCellDef> Segundo apellido </th>
                                        <td mat-cell *matCellDef="let row"> {{row.lastName2}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="cellPhone">
                                        <th mat-header-cell *matHeaderCellDef> Teléfono celular </th>
                                        <td mat-cell *matCellDef="let row"> {{row.cellPhone}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef> Correo electrónico </th>
                                        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="relationShip">
                                        <th mat-header-cell *matHeaderCellDef> Relación </th>
                                        <td mat-cell *matCellDef="let row">
                                            {{getRelationShipName(row.relationShip)}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="timeToMeet">
                                        <th mat-header-cell *matHeaderCellDef> Tiempo conocerla </th>
                                        <td mat-cell *matCellDef="let row"> {{row.timeToMeet}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="buttonRemove">
                                        <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                                        <td mat-cell *matCellDef="let row; let i = index;">
                                            <i class="bx bx-x" (click)="onDeleteReferencePerson(i)"></i>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="personReferenceColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: personReferenceColumns;">
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion *ngIf="personType == 1">
                    <mat-expansion-panel [expanded]="panelCommercialState" (opened)="panelCommercialOpen(true)"
                        (closed)="panelCommercialOpen(false)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h5>Referencias comerciales</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <form *ngIf="!getIsDisabledSave()" [formGroup]="commercialReferenceForm" (ngSubmit)="onSubmitReferenceCommercial()">
                            <div class="row">
                                <div class="subtittle2">Agregar referencia</div>

                                <div class="col-lg-3 col-md-6 mb-3">
                                    <div class="form-group">
                                        <label>Tipo referencia*</label>
                                        <select #selectPersonType class="form-control" formControlName="selectPersonType"
                                            (change)="onChange(selectPersonType.value)"
                                            [ngClass]="submitedCommercial && validateRequiredFormCommercial('personType') ? 'is-invalid' : ''">
                                            <option value="">Seleccione</option>
                                            <option *ngFor="let item of personTypeList" value="{{item.key}}">
                                                {{item.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div *ngIf="selectPersonType.value == '1'" class="col-md-9 mb-3">
                                    <div class="form-group">
                                        <label for="busisnessName">Razón social</label>
                                        <input type="text" formControlName="busisnessName" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <label>Nombre contacto*</label>
                                        <input class="form-control" type="text" formControlName="name"
                                            autocomplete="off" autocapitalize="off"
                                            [ngClass]="submitedCommercial && validateRequiredFormCommercial('name') ? 'is-invalid' : ''">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <label>Segundo nombre</label>
                                        <input class="form-control" type="text" formControlName="secondName"
                                            autocomplete="off" autocapitalize="off">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <label>Primer apellido*</label>
                                        <input class="form-control" type="text" formControlName="lastName1"
                                            autocomplete="off" autocapitalize="off"
                                            [ngClass]="submitedCommercial && validateRequiredFormCommercial('lastName1') ? 'is-invalid' : ''">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <label>Segundo apellido</label>
                                        <input class="form-control" type="text" formControlName="lastName2"
                                            autocomplete="off" autocapitalize="off">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <label>Teléfono celular</label>
                                        <input class="form-control" type="text" [textMask]="{mask: phoneMask}"
                                            formControlName="cellPhone" autocomplete="off" autocapitalize="off">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <label>Correo electrónico</label>
                                        <input class="form-control" type="text" formControlName="email"
                                            autocomplete="off" autocapitalize="off">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group">
                                        <label>Tipo Relación </label>
                                        <select class="form-control" formControlName="relationShip">
                                            <option value="">Seleccione</option>
                                            <option *ngFor="let item of getContactRelationshipList(selectPersonType.value)"
                                                value="{{item.key}}">{{item.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <div class="form-group"> <label>Tiempo Relación (años)</label>
                                        <input class="form-control" type="text" formControlName="timeToMeet"
                                            autocomplete="off" autocapitalize="off">
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <button class="btn btn-outline-primary internal-btn">
                                        Agregar
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div class="table-responsive">
                            <div class="react-bootstrap-table">
                                <table *ngIf="dsReferenceCommercialData" mat-table
                                    [dataSource]="dsReferenceCommercialData">
                                    <ng-container matColumnDef="personType">
                                        <th mat-header-cell *matHeaderCellDef> Tipo Solicitante </th>
                                        <td mat-cell *matCellDef="let row">
                                            {{getPersonType(row.personType)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="busisnessName">
                                        <th mat-header-cell *matHeaderCellDef> Razón social </th>
                                        <td mat-cell *matCellDef="let row"> {{row.busisnessName}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="secondName">
                                        <th mat-header-cell *matHeaderCellDef> Segundo nombre </th>
                                        <td mat-cell *matCellDef="let row"> {{row.secondName}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="lastName1">
                                        <th mat-header-cell *matHeaderCellDef> Primer apellido </th>
                                        <td mat-cell *matCellDef="let row"> {{row.lastName1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="lastName2">
                                        <th mat-header-cell *matHeaderCellDef> Segundo apellido </th>
                                        <td mat-cell *matCellDef="let row"> {{row.lastName2}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cellPhone">
                                        <th mat-header-cell *matHeaderCellDef> Teléfono celular </th>
                                        <td mat-cell *matCellDef="let row"> {{row.cellPhone}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef> Correo electrónico </th>
                                        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="relationShip">
                                        <th mat-header-cell *matHeaderCellDef> Relación </th>
                                        <td mat-cell *matCellDef="let row">
                                            {{getRelationShipName(row.relationShip)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="timeToMeet">
                                        <th mat-header-cell *matHeaderCellDef> Tiempo conocerla </th>
                                        <td mat-cell *matCellDef="let row"> {{row.timeToMeet}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="buttonRemove">
                                        <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                                        <td mat-cell *matCellDef="let row; let i = index;">
                                            <i class="bx bx-x" (click)="onDeleteReferenceCommercial(i)"></i>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="commercialReferenceColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: commercialReferenceColumns;">
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>