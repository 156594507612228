import { CurrencyPipe, DatePipe, KeyValue } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogAmortizeTableComponent } from 'src/app/components/dialog-amortize-table/dialog-amortize-table.component';
import { DialogSaveQuoteComponent } from 'src/app/components/dialog-save-quote/dialog-save-quote.component';
import { Amortize, Commission, Payment, Tax } from 'src/app/models/amortize/amortize.model';
import { AmortizeService } from 'src/app/services/amortize/amortize.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { QuoteService } from 'src/app/services/quote/quote.service';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { Uuidv4Service } from 'src/app/services/uuidv4/uuidv4.service';
import { Location } from "@angular/common";
import { Formats, GlobalData, GlobalLists, TypeDateAdd, TypeOfPeriod, TypesWarranties } from '../../global/global-data';
import { CatalogService } from 'src/app/services/catalog/catalog.service';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { TypeofcreditService } from 'src/app/services/typeofcredit/typeofcredit.service';
import { TypeofamortizationService } from 'src/app/services/typeofamortization/typeofamortization.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AmortizationTableComponent } from 'src/app/components/amortization-table/amortization-table.component';
import { CustomerService } from '../../services/customer/customer.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake"
import { Warranty } from 'src/app/models/amortize/amortize.model';
import { QuoteSend } from 'src/app/models/quote/quotesend.model';
import { Customer } from 'src/app/models/customer/customer.model';
import { RequiredDocumentsConfigService } from 'src/app/services/required-documents-config/required-documents-config.service';
import { RequiredDocumentsLegalConfigService } from 'src/app/services/required-documents-legal-config/required-documents-legal-config.service';
import { UserService } from 'src/app/services/user/user.service';
import { DialogSearchCustomerComponent } from 'src/app/components/dialog-search-customer/dialog-search-customer.component';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})

export class SimulatorComponent implements OnInit {
  @Input() quoteId: string;
  @ViewChild('stepperSimulator') stepperSimulator: MatStepper;
  @ViewChild('amortizationTableComponent') amortizationTableComponent: AmortizationTableComponent;
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  @Input() topNav: boolean = true;

  paymentForm: FormGroup;

  paymentFormSubmitted = false;

  step1Title = "";
  loanAmountTitle = "";
  startDateTitle = "";
  totalPeriodsTitle = "";
  typeOfPeriodTitle = "";
  startPaymentTitle = "";

  dateToday = new Date();
  typeOfPeriodSelected = TypeOfPeriod.MONTHLY;
  startPayment = new Date();

  totalAmortize = 0;
  totalInterest = 0;
  totalInterestTax = 0;
  totalCommission = 0;
  totalCommissionTax = 0;
  totalPeriodPay = 0;
  firstDatePayment: Date;
  lastDatePayment: Date;
  typeOfPeriodDesc = "";
  amortizationTableJson = "[]";

  getInterestTotal:any = 0;
  getInterestTaxTotal:any = 0;
  getCommissionTotal:any = 0;
  getCommissionTaxTotal:any = 0;
  getPeriodPayTotal:any = 0;
  getNetRetentionTotal:any = 0;
  getNetPeriodPayTotal:any = 0;

  amortize: Amortize;

  panelAdvancedOptionsOpenState;
  panelSummaryOpenState = true;
  panelPaymentTableOpenState = false;
  panelRegisterLoanOpenState = true;

  typeOfAmortizationList: KeyValue<number, string>[] = [];
  typeOfPeriodList: KeyValue<number, string>[] = [];
  typeOfCreditList: KeyValue<number, string>[] = [];
  WarrantiesList: KeyValue<number, string>[] = [];

  defaultAdvancedOptions = {
    applyTax: false,
    typeOfAmortization: '2',
    periodAmortizeStart: 1,
    typeOfCredit: '2',
    commissions: '[]',
    warranties: '[]',
    tax: '[]',
    monthlyPorcentOnArrears: '0',
    retention: 0
  };

  panelBasicOptionsOpenState = true;
  panelCommissionOpenState: any;
  panelWarrantyOpenState: any;
  panelTaxOpenState: any;
  panelRetentionOpenState: any;
  commissions: any;
  warranties: any;
  tax = <Tax[]>[];

  dsCommissions = new MatTableDataSource<Commission>(<Commission[]>[]);
  commissionsForm: FormGroup;
  commissionsFormSubmitted = false;

  dsWarranties = new MatTableDataSource<Warranty>(<Warranty[]>[]);
  dsTax = new MatTableDataSource<Warranty>(<Tax[]>[]);
  warrantiesForm: FormGroup;
  taxForm: FormGroup;
  warrantiesFormSubmitted = false;
  taxFormSubmitted = false;

  isPorcentCommission: boolean = false;
  requiredErrorMessage: string = "Este campo es requerido.";

  commissionsColumns = [
    'description',
    'value',
    'whenToPay',
    'buttonRemove'
  ];

  warrantiesColumns = [
    'number',
    'description',
    'buttonRemove'
  ];

  taxColumns = [
    'number',
    'description',
    'buttonRemove'
  ];

  typeOfCommissions = GlobalLists.typeOfCommissions;
  typeOfWarranties = GlobalLists.typeOfWarranties;
  whenToPayArray = GlobalLists.whenToPayArray;
  filteredOptions: Observable<string[]>;
  filteredOptionsWarranties: Observable<string[]>;

  panelCustomPaymentsOpenState;

  customPayments: Payment[];
  prelationConfigs;
  customerData;
  mailCustomer;

  dataQuoteTemp: any;

  payments: Payment[] = [];

  taxList = GlobalLists.taxList;

  currencyMask = GlobalLists.currencyMask;
  percentMask = GlobalLists.percentMask;
  numberMask = GlobalLists.numberMask;
  phoneMask = GlobalLists.phoneMask;

  sendQuoteForm: FormGroup;
  submitted = false;
  personTypeList = GlobalLists.personTypeList;

  periodAmortizeStartList: KeyValue<number, number>[] = [
    { key: 1, value: 1 },
  ];

  person = "";
  alias = "";
  page = "";
  ocupation = ""
  relationship = "";

  fromLoanRequestId: string = null;

  creditDestinations = GlobalLists.creditDestinations;
  contactRelationshipList = GlobalLists.contactRelationshipList;
  occupationList = GlobalLists.occupationList;
  ocupationSelected: number[] = [];
  legalConditionsList = GlobalLists.legalConditionsList;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private typeofamortizationService: TypeofamortizationService,
    private typeofperiodService: TypeofperiodService,
    private typeofcreditService: TypeofcreditService,
    private amortizeService: AmortizeService,
    private uuidv4Service: Uuidv4Service,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private quoteService: QuoteService,
    private location: Location,
    private catalogService: CatalogService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private customerService: CustomerService,
    private requiredDocumentsConfigService: RequiredDocumentsConfigService,
    private requiredDocumentsLegalConfigService: RequiredDocumentsLegalConfigService,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private router: Router) {
    this.startPaymentForm();
    this.startCommissionsForm();
    this.startWarrantiesForm();
    this.startTaxForm();
    this.createSendQuoteForm();
  }

  private _filter(value: string, type: string): string[] {
    let filterValue = '';
    if (value) {
      filterValue = value.toLowerCase();
    }

    if (type == "commision") {
      return this.typeOfCommissions.filter(option => option.toLowerCase().includes(filterValue));
    } else if (type == "warranty") {
      return this.typeOfWarranties.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  get f_paymentForm() { return this.paymentForm.controls; }
  get f_Commissions() { return this.commissionsForm.controls; }
  get f_Warranties() { return this.warrantiesForm.controls; }
  get f_Tax() { return this.taxForm.controls; }
  get f1() { return this.sendQuoteForm.controls; }

  ngOnInit(): void {
    this.setTitles();
    this.getTypeOfAmortizationList();
    this.getTypeOfPeriodList();
    this.getTypeOfCreditList();
    this.suscribeAmortizationResult();

    this.quoteId = this.route.snapshot.paramMap.get('quoteId');
    if (!this.quoteId) {
      this.quoteId = "";
      if (localStorage.getItem('quoteTemp')) {
        this.getInputsTemp();
      } else {
      }

    } else {
      this.getInputs(this.quoteId);
    }

    this.suscribePaymentFormValueChanges();

    this.tax = JSON.parse(this.f_paymentForm['tax'].value) as Tax[];

    this.warranties = JSON.parse(this.f_paymentForm['warranties'].value) as Warranty[];
    if (this.warranties && this.warranties.length > 0) {
      this.panelWarrantyOpenState = true;
      this.dsWarranties = new MatTableDataSource<Warranty>(this.warranties);
    }

    this.filteredOptionsWarranties = this.warrantiesForm.controls['description'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, "warranty"))
      );

    this.commissions = JSON.parse(this.f_paymentForm['commissions'].value) as Commission[];
    if (this.commissions && this.commissions.length > 0) {
      this.panelCommissionOpenState = true;
      this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
    }

    this.filteredOptions = this.commissionsForm.controls['description'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, "commision"))
      );

    if (this.authService.currentUserValue != null) {
      this.customerService.getCustomer(this.authService.currentUserValue.customerId).subscribe((result) => {
        this.customerData = result;
      });
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.onChangeTypeOfPeriod(this.typeOfPeriodSelected);

    if (localStorage.getItem('quoteTemp')) {
      this.onChangeTypeOfPeriod(this.typeOfPeriodSelected);
      this.onSubmitPaymentForm();
    }
  }

  createSendQuoteForm() {
    this.sendQuoteForm = this.formBuilder.group({
      contactName: ['', Validators.required],
      middleName: [null],
      lastName1: ['', Validators.required],
      lastName2: [null],
      alias: [null],
      phoneMobile: ['', [Validators.required]],
      legalConditions: ['1', Validators.required],
      contact: ['', Validators.required],
      sendContact: [true, Validators.required],
      contactRelationship: ['', Validators.required],
      creditDestination: ['', Validators.required],
      comments: [''],
      occupation: ['', Validators.required],
      personType: ['0', Validators.required],
      busisnessName: ['', Validators.required],
      newExists: [0, Validators.required],
      page: [''],
    });

    this.onChange('0');
  }

  getInputsTemp() {
    this.payments.pop();

    var quoteTemp = JSON.parse(localStorage.getItem('quoteTemp'));
    var mode = quoteTemp.mode;
    var data = quoteTemp.data;
    this.f_paymentForm['loanAmount'].patchValue(this.currencyPipe.transform(data.loanAmount).replace("$", "").replace(".00", ""));
    this.f_paymentForm['startDate'].patchValue(data.startDate);
    this.f_paymentForm['startPayment'].patchValue(data.startPayment);
    this.f_paymentForm['totalPeriods'].patchValue(data.totalPeriods);
    this.f_paymentForm['typeOfPeriod'].patchValue(data.typeOfPeriod);
    this.typeOfPeriodSelected = parseInt(data.typeOfPeriod);
    this.f_paymentForm['monthlyRate'].patchValue(data.annualRate / 12 + " %");
    this.f_paymentForm['applyTax'].patchValue(data.taxPorcent > 0 ? true : false);
    this.f_paymentForm['typeOfAmortization'].patchValue(data.typeOfAmortization.toString());
    this.f_paymentForm['periodAmortizeStart'].patchValue(data.periodAmortizeStart);
    this.f_paymentForm['typeOfCredit'].patchValue(data.typeOfCredit.toString());

    this.f_paymentForm['commissions'].patchValue(data.commissionsJson);
    this.f_paymentForm['monthlyPorcentOnArrears'].patchValue(data.monthlyPorcentOnArrears);
    this.f_paymentForm['retention'].patchValue(data.retention);

    this.commissions = JSON.parse(data.commissionsJson) as Commission[];

    this.f_paymentForm['warranties'].patchValue(data.warrantyDataJson);

    this.onChangeSelectTypeOfCredit(data.typeOfCredit.toString());

    if (data.paymentsJson) {
      this.customPayments = JSON.parse(data.paymentsJson);
      this.prelationConfigs = GlobalLists.prelationConfigs;

      JSON.parse(data.paymentsJson).forEach(element => {
        this.payments.push({
          period: element.period,
          amount: parseFloat(element.amount.toString())
        });
      });

      this.amortizeService.currentAmortizeRequestValue.annualRate = data.annualRate;
      this.amortizeService.currentAmortizeRequestValue.commissions = this.commissions;
      this.amortizeService.currentAmortizeRequestValue.startDate = data.startDate;
      this.amortizeService.currentAmortizeRequestValue.startPayment = data.startPayment;
      this.amortizeService.currentAmortizeRequestValue.loanAmount = data.loanAmount;
      this.amortizeService.currentAmortizeRequestValue.taxPorcent = data.taxPorcent;
      this.amortizeService.currentAmortizeRequestValue.totalPeriods = data.totalPeriods;
      this.amortizeService.currentAmortizeRequestValue.typeOfPeriod = data.typeOfPeriod;
      this.amortizeService.currentAmortizeRequestValue.periodAmortizeStart = data.periodAmortizeStart;
      this.amortizeService.currentAmortizeRequestValue.typeOfAmortization = parseInt(data.typeOfAmortization);
      this.amortizeService.currentAmortizeRequestValue.payments = this.payments;
    }

    if (data.customPayments) {
      this.panelCustomPaymentsOpenState = true;
    }

    this.panelAdvancedOptionsOpenState = true;

    if (mode === "save") {
      this.onSave();
    } else if (mode === "send") {
      this.onSend("Tu solicitud esta lista para que la envíes a tu familiar, amigo o cliente");
    }

    if (data.customPayments) {
      this.panelCustomPaymentsOpenState = true;
    }

    if (this.f_paymentForm['applyTax'].value) {
      this.panelTaxOpenState = true;
      this.f_Tax['description'].patchValue('16 %');
      this.addTax();
    }
  }

  getInputs(quoteId) {
    this.quoteService.get(quoteId)
      .subscribe((result) => {
        var data = JSON.parse(result.quoteDataJson);

        this.f_paymentForm['loanAmount'].patchValue(this.currencyPipe.transform(data.loanAmount).replace("$", "").replace(".00", ""));
        this.f_paymentForm['startDate'].patchValue(data.startDate);
        this.f_paymentForm['startPayment'].patchValue(data.startPayment);
        this.f_paymentForm['totalPeriods'].patchValue(data.totalPeriods);
        this.f_paymentForm['typeOfPeriod'].patchValue(data.typeOfPeriod);
        this.typeOfPeriodSelected = parseInt(data.typeOfPeriod);
        this.f_paymentForm['monthlyRate'].patchValue(data.annualRate / 12 + " %");
        this.f_paymentForm['applyTax'].patchValue(data.taxPorcent > 0 ? true : false);
        this.f_paymentForm['typeOfAmortization'].patchValue(data.typeOfAmortization.toString());
        this.f_paymentForm['periodAmortizeStart'].patchValue(data.periodAmortizeStart);
        this.f_paymentForm['typeOfCredit'].patchValue(data.typeOfCredit.toString());
        this.f_paymentForm['commissions'].patchValue(data.commissionsJson);

        this.f_paymentForm['warranties'].patchValue(data.warrantyDataJson);
        this.warranties = JSON.parse(data.warrantyDataJson) as Warranty[];
        if (this.warranties && this.warranties.length > 0) {
          this.panelWarrantyOpenState = true;
          this.dsWarranties = new MatTableDataSource<Warranty>(this.warranties);
        }

        this.f_paymentForm['monthlyPorcentOnArrears'].patchValue(data.monthlyPorcentOnArrears);
        this.f_paymentForm['retention'].patchValue(data.retention);

        this.commissions = JSON.parse(data.commissionsJson) as Commission[];

        if (this.commissions && this.commissions.length > 0) {
          this.panelCommissionOpenState = true;
          this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
        }

        if (data.paymentsJson) {
          this.customPayments = JSON.parse(data.paymentsJson);
          this.prelationConfigs = GlobalLists.prelationConfigs;

          JSON.parse(data.paymentsJson).forEach(element => {
            this.payments.push({
              period: element.period,
              amount: parseFloat(element.amount.toString())
            });
          });

          this.amortizeService.currentAmortizeRequestValue.annualRate = data.annualRate;
          this.amortizeService.currentAmortizeRequestValue.commissions = this.commissions;
          this.amortizeService.currentAmortizeRequestValue.startDate = data.startDate;
          this.amortizeService.currentAmortizeRequestValue.startPayment = data.startPayment;
          this.amortizeService.currentAmortizeRequestValue.loanAmount = data.loanAmount;
          this.amortizeService.currentAmortizeRequestValue.taxPorcent = data.taxPorcent;
          this.amortizeService.currentAmortizeRequestValue.totalPeriods = data.totalPeriods;
          this.amortizeService.currentAmortizeRequestValue.typeOfPeriod = data.typeOfPeriod;
          this.amortizeService.currentAmortizeRequestValue.periodAmortizeStart = data.periodAmortizeStart;
          this.amortizeService.currentAmortizeRequestValue.typeOfAmortization = parseInt(data.typeOfAmortization);
          this.amortizeService.currentAmortizeRequestValue.payments = this.payments;
        }
        this.panelAdvancedOptionsOpenState = true;

        this.onChangeTypeOfPeriod(this.typeOfPeriodSelected);

        if (data.customPayments) {
          this.panelCustomPaymentsOpenState = true;
          this.amortizationTableComponent.amortizeTable = JSON.parse(data.amortizationTableJson).table;
          this.amortizationTableComponent.customPayments = true;
        }

        this.onSubmitPaymentForm();
      });
  }

  setTitles() {
    if (this.authService.currentUserModeLenderValue) {
      this.step1Title = "Datos";
      this.loanAmountTitle = "Monto";
      this.startDateTitle = "Fecha de inicio";
      this.totalPeriodsTitle = "Número de pagos";
      this.typeOfPeriodTitle = "Frecuencia de pagos";
      this.startPaymentTitle = "Fecha del primer pago";
    } else {
      this.step1Title = "Datos";
      this.loanAmountTitle = "Monto";
      this.startDateTitle = "Fecha de inicio";
      this.totalPeriodsTitle = "Número de pagos";
      this.typeOfPeriodTitle = "Frecuencia de pagos";
      this.startPaymentTitle = "Fecha del primer pago";
    }
  }

  startPaymentForm() {
    this.paymentForm = this.formBuilder.group({
      loanAmount: ['', [Validators.required, Validators.min(1), Validators.max(999999999)]],
      startDate: [this.datePipe.transform(this.dateToday, 'yyyy-MM-dd'), [Validators.required]],
      startPayment: [{ value: this.datePipe.transform(new Date, 'yyyy-MM-dd'), disabled: false }, [Validators.required]],
      totalPeriods: [1, [Validators.required, Validators.required, Validators.max(999)]],
      typeOfPeriod: [this.typeOfPeriodSelected, [Validators.required]],
      monthlyRate: ['0 %', [Validators.required, Validators.max(100)]],
      applyTax: [this.defaultAdvancedOptions.applyTax],
      typeOfAmortization: [this.defaultAdvancedOptions.typeOfAmortization, [Validators.required]],
      periodAmortizeStart: [this.defaultAdvancedOptions.periodAmortizeStart, [Validators.required]],
      typeOfCredit: [this.defaultAdvancedOptions.typeOfCredit, [Validators.required]],
      warranties: [this.defaultAdvancedOptions.warranties],
      tax: [this.defaultAdvancedOptions.tax],
      commissions: [this.defaultAdvancedOptions.commissions],
      monthlyPorcentOnArrears: [this.defaultAdvancedOptions.monthlyPorcentOnArrears, [Validators.required, Validators.min(0), Validators.max(100)]],
      retention: ['0 %', [Validators.required, Validators.min(0), Validators.max(100)]]
    });
  }

  startCommissionsForm() {
    this.commissionsForm = this.formBuilder.group({
      description: ['', Validators.required],
      isPorcent: ['false'],
      value: ['0', [Validators.required, Validators.min(0)]],
      whenToPay: ['0', Validators.required],
    });
  }

  startWarrantiesForm() {
    this.warrantiesForm = this.formBuilder.group({
      description: ['', Validators.required]
    });
  }

  startTaxForm() {
    this.taxForm = this.formBuilder.group({
      description: ['', Validators.required]
    });
  }

  onSubmitPaymentForm() {
    this.paymentFormSubmitted = true;

    if (this.f_paymentForm['monthlyPorcentOnArrears'].value.replace("%", "") > 0) {
      this.f_paymentForm['monthlyPorcentOnArrears'].setValidators([Validators.min(parseFloat(this.f_paymentForm['monthlyRate'].value.replace("%", "")) + .01)]);
      this.f_paymentForm['monthlyPorcentOnArrears'].updateValueAndValidity();
    }

    if (this.paymentForm.invalid) {
      return;
    }

    if (!this.panelAdvancedOptionsOpenState) {
      var monthlyRate: string = this.paymentForm.value.monthlyRate;
      var annualRate = parseFloat(monthlyRate.replace("%", "")) * GlobalData.MONTHS_PER_YEAR;
      var taxPorcent = this.dsTax.data.length > 0 ? GlobalData.TAX : 0;
      var loanAmount: string = this.f_paymentForm['loanAmount'].value;
      loanAmount = loanAmount.replace("$", "").replace(/,/g, "");

      this.amortize = <Amortize>{
        loanAmount: parseFloat(loanAmount),
        annualRate: annualRate,
        totalPeriods: parseInt(this.paymentForm.value.totalPeriods),
        periodAmortizeStart: this.defaultAdvancedOptions.periodAmortizeStart,
        typeOfPeriod: parseInt(this.paymentForm.value.typeOfPeriod),
        typeOfAmortization: parseInt(this.defaultAdvancedOptions.typeOfAmortization),
        taxPorcent: taxPorcent,
        startDate: this.paymentForm.value.startDate,
        startPayment: this.startPayment,
        commissions: JSON.parse(this.defaultAdvancedOptions.commissions),
        trackId: this.uuidv4Service.uuidv4(),
        payments: this.customPayments,
        prelationConfigs: this.prelationConfigs,
        retention: parseFloat(this.f_paymentForm['retention'].value.replace("%", ""))
      };

      this.applyCustomPayments(true);
    }
    else {
      var monthlyRate: string = this.paymentForm.value.monthlyRate;
      var annualRate = parseFloat(monthlyRate.replace("%", "")) * GlobalData.MONTHS_PER_YEAR;
      var taxPorcent = this.dsTax.data.length > 0 ? GlobalData.TAX : 0;
      var loanAmount: string = this.f_paymentForm['loanAmount'].value;
      loanAmount = loanAmount.replace("$", "").replace(/,/g, "");

      this.amortize = <Amortize>{
        loanAmount: parseFloat(loanAmount),
        annualRate: annualRate,
        totalPeriods: parseInt(this.paymentForm.getRawValue().totalPeriods),
        periodAmortizeStart: parseInt(this.paymentForm.getRawValue().periodAmortizeStart),
        typeOfPeriod: parseInt(this.paymentForm.getRawValue().typeOfPeriod),
        typeOfAmortization: parseInt(this.paymentForm.getRawValue().typeOfAmortization),
        taxPorcent: taxPorcent,
        startDate: this.paymentForm.value.startDate,
        startPayment: this.startPayment,
        commissions: this.commissions,
        trackId: this.uuidv4Service.uuidv4(),
        payments: this.customPayments,
        prelationConfigs: this.prelationConfigs,
        retention: parseFloat(this.f_paymentForm['retention'].value.replace("%", ""))
      };
      
      if (!this.panelCustomPaymentsOpenState) {
        this.amortizeService.resetAmortizeTableExtraPaymentsResponseObserver();
        this.amortizeService.generateTable(this.amortize);
      }
    }
    this.stepperSimulator.next();
  }

  getTypeOfAmortizationList() {
    this.typeofamortizationService.getList()
      .subscribe((list) => {
        this.typeOfAmortizationList = list.sort((a, b) => b.key - a.key);
      });
  }

  getTypeOfPeriodList() {
    this.typeofperiodService.getList()
      .subscribe((list) => {
        this.typeOfPeriodList = list;
      });
  }

  getTypeOfCreditList() {
    this.typeofcreditService.getList()
      .subscribe((list) => {
        this.typeOfCreditList = list.sort((a, b) => b.key - a.key);
      });
  }

  onChangeTypeOfPeriod(value) {

    if (value < GlobalData.DAYS_PER_MONTHS) {
      this.catalogService.dateAdd(TypeDateAdd.DAYS, this.f_paymentForm['startDate'].value, value)
        .subscribe((result) => {
          this.startPayment = result as Date;
          this.f_paymentForm['startPayment'].patchValue(this.datePipe.transform(this.startPayment, 'yyyy-MM-dd'))
        });
    }

    if (value >= GlobalData.DAYS_PER_MONTHS && value < GlobalData.DAYS_PER_YEAR) {
      this.catalogService.dateAdd(TypeDateAdd.MONTHS, this.f_paymentForm['startDate'].value, (value / GlobalData.DAYS_PER_MONTHS))
        .subscribe((result) => {
          this.startPayment = result as Date;
          this.f_paymentForm['startPayment'].patchValue(this.datePipe.transform(this.startPayment, 'yyyy-MM-dd'))
        });
    }

    if (value === GlobalData.DAYS_PER_YEAR) {
      this.catalogService.dateAdd(TypeDateAdd.YEARS, this.f_paymentForm['startDate'].value, 1)
        .subscribe((result) => {
          this.startPayment = result as Date;
          this.f_paymentForm['startPayment'].patchValue(this.datePipe.transform(this.startPayment, 'yyyy-MM-dd'))
        });
    }

    this.typeOfPeriodSelected = value;
    this.f_paymentForm['typeOfPeriod'].patchValue(value);
  }

  onChangeStartDate() {
    this.onChangeTypeOfPeriod(this.f_paymentForm['typeOfPeriod'].value)
  }

  suscribeAmortizationResult() {
    this.amortizeService.getAmortizeTableResponseObserver().subscribe((data) => {
      this.totalAmortize = data.totalAmortize;
      this.totalInterest = data.totalInterest;
      this.totalInterestTax = data.totalInterestTax;
      this.totalCommission = data.totalCommission;
      this.totalCommissionTax = data.totalCommissionTax;
      this.totalPeriodPay = data.totalPeriodPay;
      if (data.table) {
        var firstDatePaymentStr = data.table.find(x => x.period === 1).datePayment;
        var dateArray = firstDatePaymentStr.split("/");
        this.firstDatePayment = new Date(dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0]);

        var lastDatePaymentStr = data.table.find(x => x.period == this.f_paymentForm['totalPeriods'].value).datePayment;
        var dateArray = lastDatePaymentStr.split("/");
        this.lastDatePayment = new Date(dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0]);

        this.typeOfPeriodDesc = this.typeOfPeriodList.find(x => x.key === this.typeOfPeriodSelected).value;
        this.amortizationTableJson = JSON.stringify(data);
      }

      if (this.includeAdvancedOptions()) {
        this.panelAdvancedOptionsOpenState = true;
      }

      if (this.commissions && this.commissions.length > 0) {
        this.panelCommissionOpenState = true;
      }
      if (this.customPayments && this.commissions.length > 0) {
        this.panelCustomPaymentsOpenState = true;
      }
    });
  }

  suscribePaymentFormValueChanges() {
    Object.getOwnPropertyNames(this.f_paymentForm).forEach((prop) => {
      if (prop !== 'completed') {
        this.f_paymentForm[prop].valueChanges
          .subscribe(() => {
            if (this.panelCustomPaymentsOpenState) {
              this.applyCustomPayments(true);
            }
          });
      }
    });
  }

  showDialogTable() {
    const dialogRef = this.dialog.open(DialogAmortizeTableComponent, {
      autoFocus: false,
      width: '80%'
    });
    let instance = dialogRef.componentInstance;
    instance.disabledPaymentsColumn = true;
    instance.hiddenPaymentsColumn = true;
    instance.onEventPrint.subscribe(() => {
      this.print();
    });

    instance.onEventExportPDF.subscribe(() => {
      this.exportPDF();
    });

    instance.onEventExportExcel.subscribe(() => {
      this.exportExcel();
    });
  }

  onReset() {
    const currentPath = this.location.path();
    if (currentPath.includes("/simulador")) {
      this.paymentForm.reset();
      this.paymentForm.reset();
      this.typeOfPeriodSelected = this.typeOfPeriodSelected;
      this.f_paymentForm['startDate'].patchValue(new Date());
      this.f_paymentForm['typeOfPeriod'].patchValue(this.typeOfPeriodSelected);
      this.stepperSimulator.selectedIndex = 0;
      localStorage.removeItem('quoteTemp');
      localStorage.removeItem('tableAmortizeTemp');
      window.location.href = "/simulador";
    } else {
      this.paymentForm.reset();
      this.paymentForm.reset();
      this.typeOfPeriodSelected = this.typeOfPeriodSelected;
      this.f_paymentForm['startDate'].patchValue(new Date());
      this.f_paymentForm['typeOfPeriod'].patchValue(this.typeOfPeriodSelected);
      this.stepperSimulator.selectedIndex = 0;
    }
  }

  onModify() {
    this.payments.pop();
    this.stepperSimulator.selectedIndex = 0;
  }

  onSave() {
    var annualRate = parseFloat(this.paymentForm.value.monthlyRate.replace("%", "")) * GlobalData.MONTHS_PER_YEAR;
    var taxPorcent = this.dsTax.data.length > 0 ? GlobalData.TAX : 0;
    var customPayments = (this.panelCustomPaymentsOpenState ? true : false)
    var amortizationTable: any;

    if (localStorage.getItem('tableAmortizeTemp')) {
      var tableAmortizeTemp = JSON.parse(localStorage.getItem('tableAmortizeTemp'));

      amortizationTable = JSON.stringify(tableAmortizeTemp)
    } else {
      amortizationTable = JSON.stringify(this.amortizeService.currentAmortizeTableValue)
    }

    var loanAmount: string = this.f_paymentForm['loanAmount'].value;
    loanAmount = loanAmount.replace("$", "").replace(/,/g, "");

    var amortizationForm = this.formBuilder.group({
      loanAmount: [parseFloat(loanAmount)],
      annualRate: [annualRate],
      totalPeriods: [this.f_paymentForm['totalPeriods'].value],
      periodAmortizeStart: [this.f_paymentForm['periodAmortizeStart'].value],
      typeOfPeriod: [this.f_paymentForm['typeOfPeriod'].value],
      typeOfAmortization: [this.f_paymentForm['typeOfAmortization'].value],
      taxPorcent: [taxPorcent],
      startDate: [this.f_paymentForm['startDate'].value],
      startPayment: this.f_paymentForm['startPayment'].value,
      typeOfCredit: [this.f_paymentForm['typeOfCredit'].value],
      commissions: JSON.stringify(this.commissions),
      monthlyPorcentOnArrears: [this.f_paymentForm['monthlyPorcentOnArrears'].value],
      paymentsJson: JSON.stringify(this.amortizeService.currentAmortizeRequestValue.payments),
      customPayments: customPayments,
      monthlyRate: [this.f_paymentForm['monthlyRate'].value],
      applyTax: [this.f_paymentForm['applyTax'].value],
      warrantyDataJson: [this.f_paymentForm['warranties'].value],
      amortizationTableJson: amortizationTable,
      retention: [this.f_paymentForm['retention'].value],
    });

    if (!this.authService.currentUserValue) {

      this.dialog
        .open(DialogConfirmComponent, {
          data: {
            title: 'Tu solicitud esta lista para que la guardes en a-lender',
            message: 'Para poder guardarla te pedimos registrarte o iniciar sesión. ¿Deseas continuar?'
          }
        })
        .afterClosed()
        .subscribe((confirmado: Boolean) => {
          if (confirmado) {
            this.authService.changeUserModeLenderValue(false);
            var loanAmount: string = this.f_paymentForm['loanAmount'].value;
            loanAmount = loanAmount.replace("$", "").replace(/,/g, "");

            const quoteTemp = {
              mode: "save",
              data: {
                loanAmount: parseFloat(loanAmount),
                annualRate: annualRate,
                totalPeriods: this.f_paymentForm['totalPeriods'].value,
                periodAmortizeStart: this.f_paymentForm['periodAmortizeStart'].value,
                typeOfPeriod: this.f_paymentForm['typeOfPeriod'].value,
                typeOfAmortization: this.f_paymentForm['typeOfAmortization'].value,
                taxPorcent: taxPorcent,
                startDate: this.f_paymentForm['startDate'].value,
                startPayment: this.f_paymentForm['startPayment'].value,
                typeOfCredit: this.f_paymentForm['typeOfCredit'].value,
                commissionsJson: JSON.stringify(this.commissions),
                monthlyPorcentOnArrears: this.f_paymentForm['monthlyPorcentOnArrears'].value,
                paymentsJson: JSON.stringify(this.amortizeService.currentAmortizeRequestValue.payments),
                prelationConfigs: GlobalLists.prelationConfigs,
                warrantyDataJson: JSON.stringify(this.warranties),
                customPayments: customPayments,
                retention: this.f_paymentForm['retention'].value,

                table: this.amortizeService.currentAmortizeTableValue.table,
                totalAmortize: this.amortizeService.currentAmortizeTableValue.totalAmortize,
                totalCommission: this.amortizeService.currentAmortizeTableValue.totalCommission,
                totalCommissionTax: this.amortizeService.currentAmortizeTableValue.totalCommissionTax,
                totalInterest: this.amortizeService.currentAmortizeTableValue.totalInterest,
                totalInterestTax: this.amortizeService.currentAmortizeTableValue.totalInterestTax,
                totalPeriodPay: this.amortizeService.currentAmortizeTableValue.totalPeriodPay,
              }
            };

            localStorage.setItem('quoteTemp', JSON.stringify(quoteTemp));
            localStorage.setItem('tableAmortizeTemp', JSON.stringify(this.amortizeService.currentAmortizeTableValue));
            window.location.href = "/iniciar-sesion";
          }
          else {
          }
        });

    } else {
      const dialogRef = this.dialog.open(DialogSaveQuoteComponent, {
        autoFocus: false,
        width: '40%'
      });
      let instance = dialogRef.componentInstance;
      instance.parentForm = amortizationForm;
      instance.quoteId = this.quoteId;

      dialogRef.afterClosed().subscribe(() => {
      });
    }
  }

  onSend(tittle: string) {
    var annualRate = parseFloat(this.paymentForm.value.monthlyRate.replace("%", "")) * GlobalData.MONTHS_PER_YEAR;
    var taxPorcent = this.dsTax.data.length > 0 ? GlobalData.TAX : 0;
    var customPayments = (this.panelCustomPaymentsOpenState ? true : false)
    var amortizationTable: any;

    if (localStorage.getItem('tableAmortizeTemp')) {
      var tableAmortizeTemp = JSON.parse(localStorage.getItem('tableAmortizeTemp'));

      amortizationTable = JSON.stringify(tableAmortizeTemp)
    } else {
      amortizationTable = JSON.stringify(this.amortizeService.currentAmortizeTableValue)
    }

    var loanAmount: string = this.f_paymentForm['loanAmount'].value;
    loanAmount = loanAmount.replace("$", "").replace(/,/g, "");

    // var amortizationForm = this.formBuilder.group({
    // 	loanAmount: [parseFloat(loanAmount)],
    // 	annualRate: [annualRate],
    // 	totalPeriods: [this.f_paymentForm['totalPeriods'].value],
    // 	periodAmortizeStart: [this.f_paymentForm['periodAmortizeStart'].value],
    // 	typeOfPeriod: [this.f_paymentForm['typeOfPeriod'].value],
    // 	typeOfAmortization: [this.f_paymentForm['typeOfAmortization'].value],
    // 	taxPorcent: [taxPorcent],
    // 	startDate: [this.f_paymentForm['startDate'].value],
    // 	typeOfCredit: [this.f_paymentForm['typeOfCredit'].value],
    // 	commissions: JSON.stringify(this.commissions),
    // 	monthlyPorcentOnArrears: [this.f_paymentForm['monthlyPorcentOnArrears'].value],
    // 	warrantyDataJson: JSON.stringify(this.warranties),
    // 	customPayments: customPayments,
    // 	paymentsJson: JSON.stringify(this.amortizeService.currentAmortizeRequestValue.payments),
    // 	amortizationTableJson: amortizationTable,
    // 	retention: [parseFloat(this.f_paymentForm['retention'].value.replace("%", ""))],
    // });

    if (!this.authService.currentUserValue) {

      this.dialog
        .open(DialogConfirmComponent, {
          data: {
            title: tittle,
            message: 'Te pedimos registrarte o iniciar sesión. ¿Deseas continuar? '
          }
        })
        .afterClosed()
        .subscribe((confirmado: Boolean) => {
          if (confirmado) {
            this.authService.changeUserModeLenderValue(false);
            var loanAmount: string = this.f_paymentForm['loanAmount'].value;
            loanAmount = loanAmount.replace("$", "").replace(/,/g, "");

            const quoteTemp = {
              mode: "send",
              data: {
                loanAmount: parseFloat(loanAmount),
                annualRate: annualRate,
                totalPeriods: this.f_paymentForm['totalPeriods'].value,
                periodAmortizeStart: this.f_paymentForm['periodAmortizeStart'].value,
                typeOfPeriod: this.f_paymentForm['typeOfPeriod'].value,
                typeOfAmortization: this.f_paymentForm['typeOfAmortization'].value,
                taxPorcent: taxPorcent,
                startDate: this.f_paymentForm['startDate'].value,
                startPayment: this.f_paymentForm['startPayment'].value,
                typeOfCredit: this.f_paymentForm['typeOfCredit'].value,
                warrantyDataJson: JSON.stringify(this.warranties),
                commissionsJson: JSON.stringify(this.commissions),
                monthlyPorcentOnArrears: this.f_paymentForm['monthlyPorcentOnArrears'].value,
                paymentsJson: JSON.stringify(this.amortizeService.currentAmortizeRequestValue.payments),
                prelationConfigs: GlobalLists.prelationConfigs,
                customPayments: customPayments,
                retention: this.f_paymentForm['retention'].value,

                table: this.amortizeService.currentAmortizeTableValue.table,
                totalAmortize: this.amortizeService.currentAmortizeTableValue.totalAmortize,
                totalCommission: this.amortizeService.currentAmortizeTableValue.totalCommission,
                totalCommissionTax: this.amortizeService.currentAmortizeTableValue.totalCommissionTax,
                totalInterest: this.amortizeService.currentAmortizeTableValue.totalInterest,
                totalInterestTax: this.amortizeService.currentAmortizeTableValue.totalInterestTax,
                totalPeriodPay: this.amortizeService.currentAmortizeTableValue.totalPeriodPay,
                totalRetention: this.amortizeService.currentAmortizeTableValue.totalRetention,
                totalNetPeriodPay: this.amortizeService.currentAmortizeTableValue.totalNetPeriodPay
              }
            };

            localStorage.setItem('quoteTemp', JSON.stringify(quoteTemp));
            localStorage.setItem('tableAmortizeTemp', JSON.stringify(this.amortizeService.currentAmortizeTableValue));
            window.location.href = "/iniciar-sesion";
          }
          else {
            this.stepperSimulator.previous();
          }
        });
    } else {
    }
  }

  onChangeSelectTypeOfCredit(value) {
    if (value == "1") {
      this.f_paymentForm['periodAmortizeStart'].disable();
      this.f_paymentForm['periodAmortizeStart'].patchValue(this.f_paymentForm['totalPeriods'].value.toString());

      this.f_paymentForm['typeOfAmortization'].patchValue('0');
      this.f_paymentForm['typeOfAmortization'].disable();

      this.applyCustomPayments(this.panelCustomPaymentsOpenState)
    } else if (value == "2") {
      this.f_paymentForm['periodAmortizeStart'].enable();
      this.f_paymentForm['periodAmortizeStart'].patchValue('1');

      this.f_paymentForm['typeOfAmortization'].enable();
    }
  }

  addCommission(): void {
    this.commissions.push(<Commission>{
      description: this.f_Commissions['description'].value,
      isPorcent: this.isPorcentCommission,
      value: parseFloat(this.f_Commissions['value'].value.replace("$ ", "").replace(",", "").replace("% ", "")),
      whenToPay: parseInt(this.f_Commissions['whenToPay'].value),
      whenToPayDesc: this.whenToPayArray.find(x => x.key === parseInt(this.f_Commissions['whenToPay'].value)).value
    });

    this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
    this.f_paymentForm['commissions'].patchValue(JSON.stringify(this.commissions));
    this.commissionsFormSubmitted = false;
    this.f_Commissions['description'].patchValue('');
    this.f_Commissions['isPorcent'].patchValue('false');
    this.f_Commissions['value'].patchValue('0');
    this.f_Commissions['whenToPay'].patchValue('0');
  }

  addWarranties(): void {
    var lastWarrantysNumber = 0;
    if (this.warranties.length > 0) {
      lastWarrantysNumber = Math.max.apply(Math, this.warranties.map(function (o: { number: any; }) { return o.number; }))
    }

    this.warranties.push(<Warranty>{
      number: lastWarrantysNumber + 1,
      description: this.f_Warranties['description'].value
    });

    this.dsWarranties = new MatTableDataSource<Warranty>(this.warranties);
    this.f_paymentForm['warranties'].patchValue(JSON.stringify(this.warranties));
    this.warrantiesFormSubmitted = false;
    this.f_Warranties['description'].patchValue('');
  }

  addTax() {
    var lastTaxsNumber = 0;
    if (this.tax.length > 0) {
      lastTaxsNumber = Math.max.apply(Math, this.tax.map(function (o: { number: any; }) { return o.number; }))
    }

    this.tax.push(<Tax>{
      number: lastTaxsNumber + 1,
      description: this.f_Tax['description'].value
    });
    this.dsTax = new MatTableDataSource<Tax>(this.tax);
    this.f_paymentForm['tax'].patchValue(JSON.stringify(this.tax));
    this.taxFormSubmitted = false;
    this.f_Tax['description'].patchValue('');

  }

  onDeleteCommission(index: number): void {
    this.commissions.splice(index, 1);
    this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
    this.f_paymentForm['commissions'].patchValue(JSON.stringify(this.commissions));
  }

  onSubmitCommission() {
    this.commissionsFormSubmitted = true;

    if (this.commissionsFormSubmitted && this.commissionsForm.invalid) {
      return;
    }

    this.addCommission();
  }

  onSubmitWarranty() {
    this.warrantiesFormSubmitted = true;
    if (this.warrantiesFormSubmitted && this.warrantiesForm.invalid) {
      return;
    }

    this.addWarranties();
  }

  onSubmitTax() {
    this.taxFormSubmitted = true;
    if (this.f_Tax['description'].value.length == 0 || this.dsTax.data.length >= 1) {
      return;
    }

    this.addTax();
  }

  onDeleteWarranty(index: number): void {
    this.warranties.splice(index, 1);
    this.dsWarranties = new MatTableDataSource<Warranty>(this.warranties);
  }

  onDeleteTax(index: number): void {
    this.tax.splice(index, 1);
    this.dsTax = new MatTableDataSource<Tax>(this.tax);
  }

  onFocusInCommissionDesc() {
    this.f_Commissions['description'].patchValue(this.f_Commissions['description'].value.trim());
  }

  applyCustomPayments(checked) {
    if (this.paymentForm.invalid) {
      this.panelCustomPaymentsOpenState = false;
      return;
    }

    if (checked == undefined) {
      return;
    }

    if (checked) {
      var taxPorcent = this.dsTax.data.length > 0 ? GlobalData.TAX : 0;
      var loanAmount: string = this.f_paymentForm['loanAmount'].value;
      loanAmount = loanAmount.replace("$", "").replace(/,/g, "");

      this.amortize = <Amortize>{
        loanAmount: parseFloat(loanAmount),
        annualRate: parseFloat(this.f_paymentForm['monthlyRate'].value) * GlobalData.MONTHS_PER_YEAR,
        totalPeriods: parseInt(this.f_paymentForm['totalPeriods'].value),
        periodAmortizeStart: parseInt(this.f_paymentForm['periodAmortizeStart'].value),
        typeOfPeriod: parseInt(this.f_paymentForm['typeOfPeriod'].value),
        typeOfAmortization: parseInt(this.f_paymentForm['typeOfAmortization'].value),
        taxPorcent: taxPorcent,
        startDate: this.f_paymentForm['startDate'].value,
        startPayment: this.f_paymentForm['startPayment'].value,
        commissions: this.commissions,
        trackId: this.uuidv4Service.uuidv4(),
        retention: parseFloat(this.f_paymentForm['retention'].value.replace("%", ""))
      };

      this.amortizeService.resetAmortizeTableResponseObserver();

      this.amortizeService.generateTable(this.amortize);

      if (this.customPayments != undefined) {
        this.amortizeService.recalculatePayments(this.payments)
      }
    } else {
      this.amortizationTableComponent.payments = [];
    }
  }

  validateRequiredForm(formControlName: string): boolean {
    if (this.f_paymentForm[formControlName].errors !== null && this.f_paymentForm[formControlName].errors['required']) {
      return true;
    }

    return false;
  }

  validateRequiredFormF1(formControlName: string): boolean {
    if (this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
      return true;
    }
    return false;
  }

  showHelp(control: string) {
    var help = GlobalLists.helpControlsList.find(x => x.control === control);
    return help.description;
  }

  includeAdvancedOptions() {
    return this.customPayments ? true : false;
  }

  private getPayTableHTMLString2() {
    var currentUserTemp = JSON.parse(localStorage.getItem('currentUser'));

    var html = `
		<html>
		<head>
		<title>a-lender diseña tu préstamo</title>
		<style>
			table, th, td, tr {
				border: 0px solid black;
			}
		</style>
		</head>
		<body>
		<h1 style="font-size: 16px; text-align: center;">Detalle de Solicitud de Préstamo</h1>
		<h3 style="font-weight: 500; font-size: 11; text-align: center; line-height: 0px;">De acuerdo a las condiciones definidas por el Usuario</h3>`;

    html += `
		<table style="font-size: 10px; margin-top: 20px; border: 0px solid black; width: 100%;">
		`;

    html += `
		<tr style="border: 0px solid black;">
			<td style="width: 25%; font-weight: bold; text-align: left;">Fecha</td>
			<td style="width: 25%; text-align: left;">`+ this.datePipe.transform(new Date(this.dateToday), Formats.DATE) + `</td>
			<td style="width: 25%; font-weight: bold; text-align: left;">ID Solicitud</td>
			<td style="width: 25%; text-align: left;"></td>
		</tr>
		<tr style="border: 0px solid black;">
			<td><p style="font-weight: 500; font-size: 10;"></p></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>`;

    if (currentUserTemp) {
      html += `
				<tr style="border: 0px solid black;">
					<td><p style="font-weight: 500; font-size: 10px;">Participantes</p></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>`;

      if (this.authService.currentUserModeLenderValue) {
        html += `
					<tr style="border: 0px solid black;">
						<td style="font-weight: bold; text-align: left;">Nombre Solicitante</td>
						<td style="text-align: left;"></td>
						<td style="font-weight: bold; text-align: left;">Teléfono celular</td>
						<td style="text-align: left;"></td>
					</tr>
					<tr style="border: 0px solid black;">
						<td style="font-weight: bold; text-align: left;">Correo electrónico</td>
						<td style="text-align: left;"></td>
						<td></td>
						<td></td>
					</tr>

					<tr style="border: 0px solid black;">
						<td style="font-weight: bold; text-align: left;">Nombre Prestamista</td>
						<td style="text-align: left;">`+ this.customerData.firstName + ' ' + this.customerData.lastName1 + ' ' + this.customerData.lastName2 + `</td>

						<td style="font-weight: bold; text-align: left;">Teléfono celular</td>
						<td style="text-align: left;">`+ (this.customerData.phoneMobile || "") + `</td>
					</tr>
					<tr style="border: 0px solid black;">
						<td><p style="font-weight: 500; font-size: 10px;"></p></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr style="border: 0px solid black;">
						<td style="font-weight: bold; text-align: left;">Correo electrónico</td>
						<td style="text-align: left;">`+ currentUserTemp.username + `</td>
						<td></td>
						<td></td>
					</tr>
				`;
      } else {
        html += `
					<tr style="border: 0px solid black;">
						<td style="font-weight: bold; text-align: left;">Nombre Solicitante</td>
						<td style="text-align: left;">`+ this.customerData.firstName + ' ' + this.customerData.lastName1 + ' ' + this.customerData.lastName2 + `</td>

						<td style="font-weight: bold; text-align: left;">Teléfono celular</td>
						<td style="text-align: left;">`+ this.customerData.phoneMobile + `</td>
					</tr>
					<tr style="border: 0px solid black;">
						<td style="font-weight: bold; text-align: left;">Correo electrónico</td>
						<td style="text-align: left;">`+ currentUserTemp.username + `</td>
						<td></td>
						<td></td>
					</tr>
					<tr style="border: 0px solid black;">
						<td><p style="font-weight: 500; font-size: 10px;"></p></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr style="border: 0px solid black;">
						<td style="font-weight: bold; text-align: left;">Nombre Prestamista</td>
						<td style="text-align: left;"></td>
						<td style="font-weight: bold; text-align: left;">Teléfono celular</td>
						<td style="text-align: left;"></td>
					</tr>
					<tr style="border: 0px solid black;">
						<td style="font-weight: bold; text-align: left;">Correo electrónico</td>
						<td style="text-align: left;"></td>
						<td></td>
						<td></td>
					</tr>
					`;
      }
    }

    html += `
		<tr style="border: 0px solid black;">
			<td><p style="font-weight: 500; font-size: 10px;">Condiciones</p></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Monto del préstamo</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.totalAmortize) + `</td>
			<td style="font-weight: bold; text-align: left;">Tipo de crédito</td>
			<td style="text-align: left;">`+ this.typeOfCreditList.find(x => x.key === parseInt(this.f_paymentForm['typeOfCredit'].value)).value + `</td>
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Fecha de inicio</td>
			<td style="text-align: left;">`+ this.datePipe.transform(new Date(this.f_paymentForm['startDate'].value), Formats.DATE) + `</td>
			<td style="font-weight: bold; text-align: left;">Cálculo de intereses</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.totalInterest) + `</td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Frecuencia de pagos</td>
			<td style="text-align: left;">`+ this.typeOfPeriodList.find(x => x.key === parseInt(this.f_paymentForm['typeOfPeriod'].value)).value + `</td>
			<td style="font-weight: bold; text-align: left;">Periodo del primer pago</td>
			<td style="text-align: left;">`+ this.f_paymentForm['periodAmortizeStart'].value + `</td>
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Número de pagos</td>
			<td style="text-align: left;">`+ this.f_paymentForm['totalPeriods'].value + `</td>`
    // html += `
    // <td style="font-weight: bold; text-align: left;">Tipo de garantía</td>
    // <td style="text-align: left;">`+ this.warrantyList.find(x => x.key === parseInt(this.f_paymentForm.warranty.value)).value +`</td>`
    html += `
			<td style="font-weight: bold; text-align: left;">Tipo de garantía</td>
			<td style="text-align: left;"></td>`
    html += `
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Plazo del préstamo</td>
			<td style="text-align: left;">`+ this.f_paymentForm['totalPeriods'].value + ' ' + this.typeOfPeriodList.find(x => x.key === parseInt(this.f_paymentForm['typeOfPeriod'].value)).value + `</td>
			<td style="font-weight: bold; text-align: left;">Interés moratorio mensual</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.f_paymentForm['monthlyPorcentOnArrears'].value.replace('%', '')).replace('$', '') + ` %</td>
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Interés mensual</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.f_paymentForm['monthlyRate'].value.replace('%', '')).replace('$', '') + ` %</td>
			<td style="font-weight: bold; text-align: left;">IVA</td>
			<td style="text-align: left;">`+ (this.f_paymentForm['applyTax'].value ? 'Si' : 'No') + `</td>
		</tr>

		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Pago Periódico</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(JSON.parse(this.amortizationTableJson).table[1].periodPay) + `</td>
			<td style="font-weight: bold; text-align: left;">Comisiones</td>
			<td style="text-align: left;">`+ (this.totalCommission ? 'Si' : 'No') + `</td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: left;">Monto total a pagar</td>
			<td style="text-align: left;">`+ this.currencyPipe.transform(this.totalPeriodPay) + `</td>
			<td style="font-weight: bold; text-align: left;">Pagos personalizados</td>
			<td style="text-align: left;">`+ (this.panelCustomPaymentsOpenState ? 'Si' : 'No') + `</td>
		</tr>
		<tr style="border: 0px solid black;">
			<td><p style="font-weight: 500; font-size: 10;"></p></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
		<tr style="border: 0px solid black;">
			<td><p style="font-weight: 500; font-size: 10;">Resumen</p></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="font-weight: bold; text-align: center;">Monto del préstamo</td>
			<td style="font-weight: bold; text-align: center;">Monto interés total con IVA</td>
			<td style="font-weight: bold; text-align: center;">Monto comisiones total con IVA</td>
			<td style="font-weight: bold; text-align: center;">Monto total a pagar</td>
		</tr>
		<tr style="border: 0px solid black;">
			<td style="text-align: center;">`+ this.currencyPipe.transform(this.totalAmortize) + `</td>
			<td style="text-align: center;">`+ this.currencyPipe.transform(this.totalInterest + this.totalInterestTax) + `</td>
			<td style="text-align: center;">`+ this.currencyPipe.transform(this.totalCommission + this.totalCommissionTax) + `</td>
			<td style="text-align: center;">`+ this.currencyPipe.transform(this.totalPeriodPay) + `</td>
		</tr>
		</table>


		<h4 style="font-weight: 500; font-size: 10;">Tabla de pagos</h4>
		<table style="font-size: 9px; width: 100%;">
		<tr style="border: 0px solid black;">
									<td style="width: 4%; font-weight: bold;">#</td>
									<td style="width: 10%; font-weight: bold;">Fecha de pago</td>
									<td style="width: 10%; font-weight: bold; text-align: right;">Capital</td>
									<td style="width: 10%; font-weight: bold; text-align: right;">Interés</td>
									<td style="width: 10%; font-weight: bold; text-align: right;">IVA interés</td>
									<td style="width: 10%; font-weight: bold; text-align: right;">Comisión</td>
									<td style="width: 10%; font-weight: bold; text-align: right;">IVA comisión</td>
									<td style="width: 10%; font-weight: bold; text-align: right;">Pago total</td>
									<td style="width: 10%; font-weight: bold; text-align: right;">Saldo final</td>
								`;

    if (this.amortizeService.currentAmortizeTableValue.totalRetention) {
      html += `<td style="width: 10%; font-weight: bold; text-align: right;">Retención ISR</td>
					<td style="width: 10%; font-weight: bold; text-align: right;">Pago neto</td>`
    }

    html += `</tr>`

    this.amortizeService.currentAmortizeTableValue.table.forEach((x) => {
      html += `<tr style="border: 0px solid black;">
									<td>`+ x.period + `</td>
									<td>`+ x.datePayment + `</td>
									<td style="text-align: right;">`+ this.currencyPipe.transform(x.amortization) + `</td>
									<td style="text-align: right;">`+ this.currencyPipe.transform(x.interest) + `</td>
									<td style="text-align: right;">`+ this.currencyPipe.transform(x.interestTax) + `</td>
									<td style="text-align: right;">`+ this.currencyPipe.transform(x.commission) + `</td>
									<td style="text-align: right;">`+ this.currencyPipe.transform(x.commissionTax) + `</td>
									<td style="text-align: right;">`+ this.currencyPipe.transform(x.periodPay) + `</td>
									<td style="text-align: right;">`+ this.currencyPipe.transform(x.balance) + `</td>
								`;
      if (this.amortizeService.currentAmortizeTableValue.totalRetention) {
        html += `<td style="text-align: right;">` + this.currencyPipe.transform(x.retention) + `</td>
				<td style="text-align: right;">`+ this.currencyPipe.transform(x.netPeriodPay) + `</td>`
      }

      html += `</tr>`
    });

    html += `<tr style="border: 0px solid black;">
								<td style="font-weight: bold;">Total</td>
								<td style="font-weight: bold;"></td>
								<td style="font-weight: bold; text-align: right;">`+ this.currencyPipe.transform(this.totalAmortize) + `</td>
								<td style="font-weight: bold; text-align: right;">`+ this.currencyPipe.transform(this.getInterestTotal) + `</td>
								<td style="font-weight: bold; text-align: right;">`+ this.currencyPipe.transform(this.getInterestTaxTotal) + `</td>
								<td style="font-weight: bold; text-align: right;">`+ this.currencyPipe.transform(this.getCommissionTotal) + `</td>
								<td style="font-weight: bold; text-align: right;">`+ this.currencyPipe.transform(this.getCommissionTaxTotal) + `</td>
								<td style="font-weight: bold; text-align: right;">`+ this.currencyPipe.transform(this.getPeriodPayTotal) + `</td>
								<td style="font-weight: bold;"></td>
							`;
    if (this.amortizeService.currentAmortizeTableValue.totalRetention) {
      html += `<td style="text-align: right;">` + this.currencyPipe.transform(this.getNetRetentionTotal) + `</td>
			<td style="text-align: right;">`+ this.currencyPipe.transform(this.getNetPeriodPayTotal) + `</td>`
    }

    html += `</tr>`;

    html += `
		</table>
		`;

    html += `
		<p style="font-size: 5px; text-align: left">
			<p style="font-size: 12px; font-weight: bold;">Avisos y recomendaciones</p>
			<ul style="font-size: 12px; font-weight: bold; list-style: none; padding-inline-start: 0; font-weight: normal;">
				<li style="font-size: 12px; text-align: left">Esta Solicitud, Condiciones y Tabla de Pagos ha sido creada en <strong>www.a-lender.com</strong></li>
				<li style="font-size: 12px; text-align: left">El contenido es responsabilidad del Usuario quien la generó La presente solicitud y tabla de pagos es informativa hasta que las partes formalizen el préstamo.</li>
				<li style="font-size: 12px; text-align: left">Si vas a prestar, evalúa si el solicitante puede pagar y si cuentas con los recursos para hacerlo.</li>
				<li style="font-size: 12px; text-align: left">Si necesitas dinero, solicita el préstamo de acuerdo a tus posibilidades de pago, evita sobre endeudarte.</li>
				<li style="font-size: 12px; font-weight: bold;">a-lender®</li>
				<li style="font-size: 12px; text-align: left">Es la plataforma digital para administrar préstamos.</li>
				<li style="font-size: 12px; text-align: left">Si vas a prestar, no pierdas ni a tu amigo ni tu dinero: ¡Utiliza a-lender® para administrarlo fácil y seguro!</li>
				<li style="font-size: 12px; text-align: left">Si necesitas dinero, lo más sencillo es apoyarte en tus conocidos: ¡Utiliza a-lender® para administrarlo fácil y rápido!</li>
				<li style="font-size: 12px;">Regístrate en: <strong>www.a-lender.com</strong> o escríbenos a: <strong>hola@a-lender.com</strong></li>
			</ul>
		</p>
		`;

    html += `
			</body></html>
		`;
    return html;
  }

  print() {
    if (localStorage.getItem("currentUser") == null) {
      this.onSend("Tu solicitud esta lista para imprimir");
      return;
    }
    this.getInterestTotal = this.amortizationTableComponent.getInterestTotal();
    this.getInterestTaxTotal = this.amortizationTableComponent.getInterestTaxTotal();
    this.getCommissionTotal = this.amortizationTableComponent.getCommissionTotal();
    this.getCommissionTaxTotal = this.amortizationTableComponent.getCommissionTaxTotal();
    this.getPeriodPayTotal = this.amortizationTableComponent.getPeriodPayTotal();
    this.getNetRetentionTotal = this.amortizationTableComponent.getNetRetentionTotal();
    this.getNetPeriodPayTotal = this.amortizationTableComponent.getNetPeriodPayTotal();

    var mywindow = window.open();
    mywindow.document.write(this.getPayTableHTMLString2())
    mywindow.stop();
    mywindow.print();

    return true;
  }


  exportPDF() {
    if (localStorage.getItem("currentUser") == null) {
      this.onSend("Tu solicitud esta lista para exportar a PDF");
      return;
    }

    this.getInterestTotal = this.amortizationTableComponent.getInterestTotal();
    this.getInterestTaxTotal = this.amortizationTableComponent.getInterestTaxTotal();
    this.getCommissionTotal = this.amortizationTableComponent.getCommissionTotal();
    this.getCommissionTaxTotal = this.amortizationTableComponent.getCommissionTaxTotal();
    this.getPeriodPayTotal = this.amortizationTableComponent.getPeriodPayTotal();
    this.getNetRetentionTotal = this.amortizationTableComponent.getNetRetentionTotal();
    this.getNetPeriodPayTotal = this.amortizationTableComponent.getNetPeriodPayTotal();

    var html = htmlToPdfmake(this.getPayTableHTMLString2(), { tableAutoSize: true });
    const documentDefinition = { content: [html], styles: { table: { border: '0px solid black' } } };

    pdfMake.createPdf(documentDefinition).open();

  }

  exportExcel() {
    if (localStorage.getItem("currentUser") == null) {
      this.onSend("Tu solicitud esta lista para exportar a Excel");
      return;
    }

    var dataToExport = [];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Employee Data");
    let header = ["#", "Fecha de pago", "Capital", "Interés", "IVA Interés", "Comisión", "IVA Comisión", "Pago total", "Saldo final"]

    if (this.amortizeService.currentAmortizeTableValue.totalRetention) {
      if (!header.find(x => x == 'Retención ISR' && 'Pago neto')) {
        header.push('Retención ISR');
        header.push('Pago neto');
      } else {
        header.filter(x => x !== "Retención ISR" && x !== "Pago neto");
      }
    }

    let headerRow = worksheet.addRow(header);

    for (let i of JSON.parse(this.amortizationTableJson).table) {
      if (!this.amortizeService.currentAmortizeTableValue.totalRetention) {
        dataToExport.push({
          period: i.period,
          datePayment: i.datePayment,
          amortization: this.currencyPipe.transform(i.amortization),
          interest: this.currencyPipe.transform(i.interest),
          interestTax: this.currencyPipe.transform(i.interestTax),
          commission: this.currencyPipe.transform(i.commission),
          commissionTax: this.currencyPipe.transform(i.commissionTax),
          periodPay: this.currencyPipe.transform(i.periodPay),
          balance: this.currencyPipe.transform(i.balance),
        })
      } else {
        dataToExport.push({
          period: i.period,
          datePayment: i.datePayment,
          amortization: this.currencyPipe.transform(i.amortization),
          interest: this.currencyPipe.transform(i.interest),
          interestTax: this.currencyPipe.transform(i.interestTax),
          commission: this.currencyPipe.transform(i.commission),
          commissionTax: this.currencyPipe.transform(i.commissionTax),
          periodPay: this.currencyPipe.transform(i.periodPay),
          balance: this.currencyPipe.transform(i.balance),
          retention: this.currencyPipe.transform(i.retention),
          netPeriodPay: this.currencyPipe.transform(i.netPeriodPay),
        })
      }
    }

    if (!this.amortizeService.currentAmortizeTableValue.totalRetention) {
      dataToExport.push({
        period: "Total",
        datePayment: "",
        amortization: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalAmortize),
        interest: this.currencyPipe.transform(this.amortizationTableComponent.getInterestTotal()),
        interestTax: this.currencyPipe.transform(this.amortizationTableComponent.getInterestTaxTotal()),
        commission: this.currencyPipe.transform(this.amortizationTableComponent.getCommissionTotal()),
        commissionTax: this.currencyPipe.transform(this.amortizationTableComponent.getCommissionTaxTotal()),
        periodPay: this.currencyPipe.transform(this.amortizationTableComponent.getPeriodPayTotal()),
        balance: ""
      });
    } else {
      dataToExport.push({
        period: "Total",
        datePayment: "",
        amortization: this.currencyPipe.transform(this.amortizeService.currentAmortizeTableValue.totalAmortize),
        interest: this.currencyPipe.transform(this.amortizationTableComponent.getInterestTotal()),
        interestTax: this.currencyPipe.transform(this.amortizationTableComponent.getInterestTaxTotal()),
        commission: this.currencyPipe.transform(this.amortizationTableComponent.getCommissionTotal()),
        commissionTax: this.currencyPipe.transform(this.amortizationTableComponent.getCommissionTaxTotal()),
        periodPay: this.currencyPipe.transform(this.amortizationTableComponent.getPeriodPayTotal()),
        balance: "",
        retention: this.currencyPipe.transform(this.amortizationTableComponent.getNetRetentionTotal()),
        netPeriodPay: this.currencyPipe.transform(this.amortizationTableComponent.getNetPeriodPayTotal())
      });
    }

    for (let x1 of dataToExport) {
      let x2 = Object.keys(x1);
      let temp = []

      for (let y of x2) {
        temp.push(x1[y])
      }

      worksheet.addRow(temp)
    }

    let fname = "alender tabla de pagos "

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '- ' + `${new Date().toISOString()}.xlsx`);
    });
  }

  panelWarrantyOpen(e) {
    this.panelWarrantyOpenState = e;
  }

  panelTaxOpen(e) {
    this.panelTaxOpenState = e;
  }

  panelRetentionOpen(e) {
    this.panelRetentionOpenState = e;
  }

  panelBasicOptionsOpen(e) {
    this.panelBasicOptionsOpenState = e;
  }

  panelSummaryOpenStateOpen(e) {
    this.panelSummaryOpenState = e;
  }

  panelRegisterLoanStateOpen(e) {
    this.panelRegisterLoanOpenState = e;
  }

  panelPaymentTableOpenStateOpen(e) {
    this.panelPaymentTableOpenState = e;
  }

  onChange(value: string) {
    this.f1['occupation'].patchValue('');
    this.f1['creditDestination'].patchValue('');
    this.f1['contactRelationship'].patchValue('');

    if (value == '1') {
      this.f1['busisnessName'].setValidators([Validators.required]);
      this.person = "Contacto";
      this.alias = "Nombre comercial";
      this.page = "Página web";
      this.ocupation = "Sector";
      this.relationship = "Relación";
    } else if (value == '2') {
      var occupation: number[] = this.f1['occupation'].value;

      occupation = [1];

      if (!this.f1['occupation'].value.includes(1)) {
        this.f1['occupation'].patchValue(occupation)
      }
    } else {
      this.f1['busisnessName'].clearValidators();
      this.person = "Solicitante";
      this.alias = "Alias";
      this.page = "Facebook";
      this.ocupation = "Ocupación";
      this.relationship = "Relación o parentesco";
    }
  }

  onSubmitSendQuote() {
    this.submitted = true;

    if (this.sendQuoteForm.invalid) {
      return;
    }

    if (GlobalLists.getPhone(this.f1['phoneMobile'].value).length < 10) {
      return;
    }

    var annualRate = parseFloat(this.paymentForm.value.monthlyRate.replace("%", "")) * GlobalData.MONTHS_PER_YEAR;
    var taxPorcent = this.dsTax.data.length > 0 ? GlobalData.TAX : 0;
    var customPayments = (this.panelCustomPaymentsOpenState ? true : false)
    var amortizationTable: any;

    if (localStorage.getItem('tableAmortizeTemp')) {
      var tableAmortizeTemp = JSON.parse(localStorage.getItem('tableAmortizeTemp'));

      amortizationTable = JSON.stringify(tableAmortizeTemp)
    } else {
      amortizationTable = JSON.stringify(this.amortizeService.currentAmortizeTableValue)
    }

    var loanAmount: string = this.f_paymentForm['loanAmount'].value;
    loanAmount = loanAmount.replace("$", "").replace(/,/g, "");

    var amortizationForm = this.formBuilder.group({
      loanAmount: [parseFloat(loanAmount)],
      annualRate: [annualRate],
      totalPeriods: [this.f_paymentForm['totalPeriods'].value],
      periodAmortizeStart: [this.f_paymentForm['periodAmortizeStart'].value],
      typeOfPeriod: [this.f_paymentForm['typeOfPeriod'].value],
      typeOfAmortization: [this.f_paymentForm['typeOfAmortization'].value],
      taxPorcent: [taxPorcent],
      startDate: [this.f_paymentForm['startDate'].value],
      startPayment: this.f_paymentForm['startPayment'].value,
      typeOfCredit: [this.f_paymentForm['typeOfCredit'].value],
      commissions: JSON.stringify(this.commissions),
      monthlyPorcentOnArrears: [this.f_paymentForm['monthlyPorcentOnArrears'].value],
      warrantyDataJson: JSON.stringify(this.warranties),
      customPayments: customPayments,
      paymentsJson: JSON.stringify(this.amortizeService.currentAmortizeRequestValue.payments),
      amortizationTableJson: amortizationTable,
      retention: [parseFloat(this.f_paymentForm['retention'].value.replace("%", ""))],
    });


    var fAmortizationForm = amortizationForm.controls;

    this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Enviar solicitud',
        message: '¿Deseas enviar el detalle al Solicitante del préstamo?'
      }
    })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.f1['sendContact'].patchValue(true);
        } else {
          this.f1['sendContact'].patchValue(false);
        }

        if (this.quoteId === "") {
          this.quoteId = null;
        }

        var customPayments: boolean = fAmortizationForm.customPayments.value;
        var phone: string;

        if (this.f1['phoneMobile'].value == null) {
          phone = null;
        } else {
          phone = GlobalLists.getPhone(this.f1['phoneMobile'].value);
        }

        var quoteSend: QuoteSend = <QuoteSend>{
          quoteId: this.quoteId,
          contactName: this.f1['contactName'].value,
          contact: this.f1['contact'].value,
          middleName: this.f1['middleName'].value,
          lastName1: this.f1['lastName1'].value,
          lastName2: this.f1['lastName2'].value,
          alias: this.f1['alias'].value,
          phoneMobile: phone,
          loanAmount: fAmortizationForm['loanAmount'].value,
          annualRate: fAmortizationForm['annualRate'].value,
          totalPeriods: parseInt(fAmortizationForm['totalPeriods'].value),
          periodAmortizeStart: parseInt(fAmortizationForm['periodAmortizeStart'].value),
          typeOfPeriod: parseInt(fAmortizationForm['typeOfPeriod'].value),
          typeOfAmortization: parseInt(fAmortizationForm['typeOfAmortization'].value),
          taxPorcent: fAmortizationForm['taxPorcent'].value,
          startDate: this.datePipe.transform(fAmortizationForm['startDate'].value.toString(), 'yyyy-MM-dd'),
          startPayment: this.datePipe.transform(fAmortizationForm['startPayment'].value.toString(), 'yyyy-MM-dd'),
          typeOfCredit: parseInt(fAmortizationForm['typeOfCredit'].value),
          customerId: this.authService.currentUserValue.customerId,
          name: "Enviada a " + this.f1['contact'].value,
          isDefault: false,
          expireDate: new Date(),
          comments: this.f1['comments'].value,
          documentsRequiredConfigJson: JSON.stringify(this.requiredDocumentsConfigService.currentrequiredDocuments),
          amortizationTableJson: fAmortizationForm['amortizationTableJson'].value,
          commissionsJson: fAmortizationForm['commissions'].value,
          monthlyPorcentOnArrears: parseFloat(fAmortizationForm['monthlyPorcentOnArrears'].value),
          paymentsJson: JSON.stringify(this.amortizeService.currentAmortizeRequestValue.payments),
          lenderCreated: this.authService.currentUserModeLenderValue,
          fromContactName: (this.authService.currentUserValue.firstName + ' ' + this.authService.currentUserValue.middleName).replace("null", "").trimEnd(),
          fromContact: this.authService.currentUserValue.username,
          warrantyDataJson: fAmortizationForm['warrantyDataJson'].value,
          documentsLegalRequiredConfigJson: JSON.stringify(this.requiredDocumentsLegalConfigService.currentrequiredDocuments),
          customPayments: customPayments,
          legalConditions: parseInt(this.f1['legalConditions'].value),
          creditDestination: this.f1['creditDestination'].value,
          sendContact: this.f1['sendContact'].value,
          contactRelationship: this.f1['contactRelationship'].value + '',
          percentRetentionISR: parseFloat(fAmortizationForm['retention'].value + ''.replace("%", "")),
          personType: this.f1['personType'].value,
          occupation: JSON.stringify(this.f1['occupation'].value),
          busisnessName: this.f1['busisnessName'].value,
          page: this.f1['page'].value,
          newExists: parseInt(this.f1['newExists'].value),
          fromLoanRequestId: this.fromLoanRequestId,
          creditDestinationOther: ""
        }

        this.userService.validateUserExists(this.f1['contact'].value, this.f1['contactName'].value).subscribe(data => {
          if (data.userExists) {
            this.quoteService.send(quoteSend).subscribe((data) => {
              this.saveSendQuote();
            });
          } else {
            var customer: Customer = <Customer>{
              firstName: this.f1['contactName'].value,
              middleName: this.f1['middleName'].value,
              lastName1: this.f1['lastName1'].value,
              lastName2: this.f1['lastName2'].value,
              contactNoUser: this.f1['contact'].value,
              gender: ""
            }

            this.customerService.createCustomer(customer).subscribe(data => {
              this.quoteService.send(quoteSend).subscribe((data) => {
                this.saveSendQuote();
              });
            })
          }
        });
      });
  }

  saveSendQuote() {
    localStorage.removeItem('quoteTemp');
    localStorage.removeItem('tableAmortizeTemp');

    this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Solicitud registrada',
        message: 'La cotización se ha guardado exitosamente, por lo que ahora es una Solicitud de préstamo',
        accept: true
      }
    })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        this.router.navigate(['/solicitud-de-prestamo']);
      });
  }

  getContactRelationshipList(personType: string) {
    if (personType == "1") {
      return this.contactRelationshipList.filter(x => x.key >= 8 && x.key <= 14)
    } else {
      return this.contactRelationshipList.filter(x => (x.key >= 0 && x.key <= 7) || (x.key >= 13 && x.key <= 14))
    }
  }

  getOccupationList(personType: string) {
    if (personType == "1") {
      return this.occupationList.filter(x => (x.key >= 10 && x.key <= 14))
    } else {
      return this.occupationList.filter(x => (x.key >= 0 && x.key <= 9) || (x.key == 14))
    }
  }

  getCreditDestinations(personType: string) {
    if (personType == "1") {
      return this.creditDestinations.filter(x => (x.key >= 9 && x.key <= 14))
    } else {
      return this.creditDestinations.filter(x => (x.key >= 0 && x.key <= 8) || (x.key == 14))
    }
  }

  onchangeDataExpedient(personType: string, value: number[]) {
    var occupation: number[] = this.f1['occupation'].value;

    if (personType == '1') {

      if (value.length == 2) {
        this.f1['occupation'].patchValue(this.eliminar(occupation, this.ocupationSelected[0]));
        this.ocupationSelected = this.f1['occupation'].value;
      } else {
        this.ocupationSelected = value;
      }
    } else if (personType == '2') {
    }
  }

  eliminar(array: number[], elemento: number) {
    var resultado = []

    for (var i = 0; i < array.length; i++) {
      if (array[i] !== elemento) {
        resultado.push(array[i]);
      }
    }

    return resultado;
  }

  selectOccupationDisabled(personType: string, occupation: number) {
    if (personType == '2' && occupation == 1) {
      return true
    }
  }

  onChangeNewExists(value: string) {
    this.fromLoanRequestId = null;

    if (value == '1') {
      const dialogRef = this.dialog.open(DialogSearchCustomerComponent, {
        autoFocus: false,
        width: '80%',
        height: '90%'
      });

      let instance = dialogRef.componentInstance;

      dialogRef.afterClosed().subscribe((result) => {
        var rowSelected = instance.rowSelected;

        if (instance.submited) {
          this.fromLoanRequestId = instance.rowSelected.id;
          this.f1['personType'].patchValue(rowSelected.personType + "");
          this.onChange(rowSelected.personType);
          this.f1['busisnessName'].patchValue(rowSelected.busisnessName);
          this.f1['contactName'].patchValue(rowSelected.firstName);
          this.f1['middleName'].patchValue(rowSelected.middleName);
          this.f1['lastName1'].patchValue(rowSelected.lastName1);
          this.f1['lastName2'].patchValue(rowSelected.lastName2);
          this.f1['alias'].patchValue(rowSelected.alias);
          this.f1['phoneMobile'].patchValue(rowSelected.phoneMobile);
          this.f1['contact'].patchValue(rowSelected.email);
          this.f1['page'].patchValue(rowSelected.page);
          this.f1['contactRelationship'].patchValue(rowSelected.contactRelationship);
          this.f1['occupation'].patchValue(eval(rowSelected.occupation));
          this.f1['creditDestination'].patchValue(rowSelected.creditDestination + "");
          this.f1['legalConditions'].patchValue(rowSelected.legalConditions);
          this.f1['comments'].patchValue(rowSelected.comments);
        } else {
          this.f1['newExists'].patchValue(0);
        }
      });
    }
  }

  changeContactRelationship(value: string) {
    if (value == "6") {
      this.f1['occupation'].patchValue([0])
    }
  }

  selectOccupationRelationDisabled(value: string, selectOccupation: number) {
    if (value == "6" && selectOccupation == 0) {
      return true;
    } else {
      return false;
    }
  }

  onChangeTotalPeriods(totalPeriods: string) {
    this.periodAmortizeStartList = [];

    for (var i = 1; i <= parseInt(totalPeriods); i++) {
      this.periodAmortizeStartList.push(
        { key: i, value: i }
      )
    }
  }
}
