<div>
    <h5>
        Guardar datos garantía líquida
    </h5>

    <form [formGroup]="liquidGuaranteeJsonForm" (ngSubmit)="onSubmitFormLiquidJson()">
        <div class="subtittle">
            Escribe o selecciona tus datos de garantía líquida
        </div>

        <mat-accordion>
            <mat-expansion-panel [expanded]="panelLiquidOpenState" class="mb-4"
                (opened)="panelLiquidOpen(true)" (closed)="panelLiquidOpen(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>Datos garantía líquida</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <mat-slide-toggle labelPosition="before" value="false"
                                formControlName="value-PercentOrAmount"
                                [ngClass]="validateRequiredFormF7('value-PercentOrAmount') ? 'is-invalid' : ''">
                                Cambiar a porcentaje
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-Amount" class="">{{f7['value-PercentOrAmount'].value ?
                                'Porcentaje' : 'Monto'}}<span
                                    *ngIf="validateFormrequestJson('Amount')">*</span></label>
                            <input type="text"
                                [textMask]="{mask: f7['value-PercentOrAmount'].value ? percentMask : currencyMask}"
                                formControlName="value-Amount" class="form-control text-right"
                                [ngClass]="validateRequiredFormF7('value-Amount') ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-DeliveryMethod" class="">Forma entrega<span
                                    *ngIf="validateFormrequestJson('DeliveryMethod')">*</span></label>
                            <select #selectDeliveryMethod class="form-control"
                                formControlName="value-DeliveryMethod"
                                [ngClass]="validateRequiredFormF7('value-DeliveryMethod') ? 'is-invalid' : ''">
                                <option value="">Seleccione</option>
                                <option *ngFor="let item of deliveryMethodList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <div class="form-group">
                            <label for="value-DeliveryDate" class="">Fecha de Entrega<span
                                    *ngIf="validateFormrequestJson('DeliveryDate')">*</span></label>
                            <input autofocus="autofocus" class="form-control"
                                formControlName="value-DeliveryDate" type="date"
                                [ngClass]="validateRequiredFormF7('value-DeliveryDate') ? 'is-invalid' : ''" />
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button type="button" class="btn btn-outline-primary external-btn" mat-dialog-close [mat-dialog-close]="submit">
                Cerrar
            </button>
            <button type="submit" class="btn btn-primary external-btn" *ngIf="!getIsDisabledSave()">
                Guardar
            </button>
        </div>
    </form>
</div>