import { Component, Inject, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SubscriptionSeachLoan } from 'src/app/models/subscriptionpayment/subscriptionpayment.model';
import { SubscriptionpaymentService } from 'src/app/services/subscriptionpayment/subscriptionpayment.service';

@Component({
	selector: 'app-dialog-subscription-detail',
	templateUrl: './dialog-subscription-detail.component.html',
	styleUrls: ['./dialog-subscription-detail.component.scss']
})

export class DialogSubscriptionDetailComponent implements OnInit {
	@Input() subscriptionId: string;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	
	searchLoanColumns = [
		'status',
		'name',
		'phoneMobile',
		'email'
	];

	searchLoan = <SubscriptionSeachLoan[]>[];
	dsSearchLoan = new MatTableDataSource<SubscriptionSeachLoan>();
	rowSelected:SubscriptionSeachLoan = <SubscriptionSeachLoan>{};
	indexSelected = null;

	constructor(
		private subscriptionpaymentService: SubscriptionpaymentService,
		private dialogRef: MatDialogRef<DialogSubscriptionDetailComponent>,
		private router: Router) {
	}
	
	ngOnInit(): void {
		this.getSearchLoan();
	}
	
	getSearchLoan() {
		this.subscriptionpaymentService.getSearchLoan(this.subscriptionId).subscribe((result) => {
			if (result !== null) {
				this.searchLoan = result;

				this.dsSearchLoan = new MatTableDataSource<SubscriptionSeachLoan>(this.searchLoan);
				this.dsSearchLoan.paginator = this.paginator;
			}
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dsSearchLoan.filter = filterValue.trim().toLowerCase();
	}

	onSubmitSearchLoan() {
		if (this.rowSelected.loanId == null) {
			this.router.navigate(['solicitud-de-prestamo/' + this.rowSelected.id]);
		} else {
			this.router.navigate(['prestamos/' + this.rowSelected.id]);
		}

		this.dialogRef.close();
	}

	onRowClicked(row, index) {
		this.rowSelected = row;
		this.indexSelected = index;
	}

	onClickPage() {
		this.rowSelected = <SubscriptionSeachLoan>{};
		this.indexSelected = null;
	}

	getStatus(loanRequestStatus: string) {
		return loanRequestStatus;
	}

	getName(firstName: string, middleName: string, lastName1: string, lastName2: string) {
		return firstName.concat(" ", middleName).trim() + " " + lastName1.concat(" ", lastName2).trim();
	}
}