import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormRequestAnswer } from 'src/app/models/loanrequest/form-request.answer.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { GlobalLists } from 'src/app/global/global-data';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-dialog-pld-data',
	templateUrl: './dialog-pld-data.component.html',
	styleUrls: ['./dialog-pld-data.component.scss']
})

export class DialogPLDComponent implements OnInit {
	pldJsonForm: FormGroup = this.formBuilder.group({});
	formrequestJson: FormRequestAnswer[];
	panelPLDState = false;

	yesnoList = GlobalLists.yesnoList;
	typeRelationshipList = GlobalLists.typeRelationshipList;

	@Input() loanRequestStatus: number;
	@Input() loanRequestId: string;
	@Input() dr: boolean;
	@Input() completed: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private datePipe: DatePipe,
		private loanrequestService: LoanrequestService
	) {
	}

	isLender = this.authService.currentUserModeLenderValue;

	get f8() { return this.pldJsonForm.controls; }

	ngOnInit(): void {
		this.createPLDForm();

		if (this.completed) {
			this.dr = true;
		}
	}

	getIsDisabledSave() {
		if (this.loanRequestStatus == 9 || this.loanRequestStatus == 4 || this.loanRequestStatus == 10) {
			return true;
		} else {
			return false;
		}
	}

	createPLDForm() {
		this.loanrequestService.getPLDData(this.loanRequestId).subscribe((result) => {
			if (result !== null) {
				this.formrequestJson = result.optionForm;
				var formJson = this.formrequestJson;

				for (var key in formJson) {
					if (formJson[key]['conditionName'] != null) {
						if (this.isLender) {
							if (formJson[key]['required']) {
								this.pldJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.pldJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
							}
						} else {
							if (formJson[key]['required']) {
								this.pldJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.required, Validators.maxLength(150), Validators.minLength(0)]))
							} else {
								this.pldJsonForm.addControl("value-" + formJson[key]['conditionName'], new FormControl(formJson[key]['value'], [Validators.maxLength(150), Validators.minLength(0)]))
							}
						}

						if (this.getIsDisabledSave()) {
							this.f8["value-" + formJson[key]['conditionName']].disable();
						}
					}
				}

				if (this.f8['value-StartDate'].value != "") {
					this.f8['value-StartDate'].patchValue(this.datePipe.transform(this.f8['value-StartDate'].value, 'yyyy-MM-dd'));
				}

				if (this.f8['value-EndDate'].value != "") {
					this.f8['value-EndDate'].patchValue(this.datePipe.transform(this.f8['value-EndDate'].value, 'yyyy-MM-dd'));
				}

				if (this.f8['value-FamilyStartDate'].value != "") {
					this.f8['value-FamilyStartDate'].patchValue(this.datePipe.transform(this.f8['value-FamilyStartDate'].value, 'yyyy-MM-dd'));
				}

				if (this.f8['value-FamilyEndDate'].value != "") {
					this.f8['value-FamilyEndDate'].patchValue(this.datePipe.transform(this.f8['value-FamilyEndDate'].value, 'yyyy-MM-dd'));
				}
			}
		});
	}

	validateRequiredFormF8(formControlName: string): boolean {
		if (this.f8[formControlName].errors !== null && this.f8[formControlName].errors['required']) {
			return true;
		}

		return false;
	}

	validateFormrequestJson(val: string) {
		if (!this.validateFormrequest(val)) {
			return;
		}

		if (this.formrequestJson.find(x => x.conditionName == val).required) {
			return true;
		} else {
			return false;
		}
	}

	validateFormrequest(val: string) {
		if (this.formrequestJson == null) {
			return false;
		}

		if (this.formrequestJson.find(x => x.conditionName == val) != null) {
			return true;
		}

		return false;
	}

	panelPLDOpen(e) {
		this.panelPLDState = e;
	}
}
