<div class="container-fluid">
    <h4>Preguntas frecuentes</h4>
    <div class="card">
        <div class="card-body">
			<mat-tab-group>
				<mat-tab label="Soy Prestamista">
					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<div>
										<h5 class="text-dark custom-tittle">
											<span class="material-icons mr-2 text-success">
												help_outline
											</span>
											¿Cuáles son los pasos para otorgar un préstamo?
										</h5>
									</div>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<p>
								Si otorgas un préstamo informal a un familiar o amigo cercano, si eres un prestamista formal o incluso una financiera o un banco, el ciclo del préstamo consta en general de las siguientes etapas:
							</p>
							<ul>
								<li>Promoción
								<li>Solicitud e integración de información
								<li>Evaluación y resolución
								<li>Otorgamiento
								<li>Seguimiento y control
							</ul>
							<p>
								Cada etapa puede ser tan simple o profunda como lo requiera el Prestamista, es decir, depende de cómo quiera otorgar el préstamo, puede ser ‘a la palabra’ o documentarse legalmente con un pagaré que lo respalde y/o un contrato de préstamo que contenga todas las condiciones y acuerdos entre las partes.
							</p>
							<p>
								a-lender te ayuda en todo el ciclo de manera fácil y rápida, brindándote la flexibilidad que necesitas. Tu defines el tipo de préstamo harás, que información y documentación requieres, y la clase de apoyo que requieres para su recuperación, por lo que cuentas con las siguientes funcionalidades:
							</p>
							<ul>
								<li>Diseñar la Cotización: aquí puedes simular préstamos a la medida, definir las condiciones básicas y, en su caso, avanzadas. Puedes descargar, guardar y enviar la cotización al Solicitante.
								<li>Integrar una Solicitud: puedes ver y modificar condiciones, integrar un expediente digital con los datos y documentos del Solicitante que desees, evaluar y autorizar o rechazar la solicitud, generar contrato y/o pagaré si así lo deseas, y registrar la entrega del préstamo.
								<li>Dar seguimiento al Préstamo: puedes consultar el expediente digital y el estado de cuenta del préstamo, registrar pagos recibidos y definir el tipo de cobranza que desees en relación a envío automatizado de recordatorios y/o requerimientos de pago.
								<li>Generar Reportes: consulta y descarga reportes de la situación del préstamo o de todo tu portafolio, de lo que te solicitan, has prestado y cobrado.
							</ul>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo diseño un préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								a-lender cuenta con un cotizador que te ayuda fácil y rápido a diseñar y simular préstamos tan sencillos o sofisticados como desees. Es una calculadora financiera especializada que te permite elegir diversas variables y establecer parámetros para tener un préstamo a tu medida.
							</p>
							<p>
								Un préstamo requiere especificar ciertos datos mínimos necesarios (opciones básicas) para poder calcular las fechas y monto de pagos, así como el total a pagar, es decir, para generar una tabla de pagos (tabla de amortización):
							</p>
							<ul>
								<li>¿Cuánto dinero prestarás? (Monto)
								<li>¿Cada cuándo te harán los pagos? (Frecuencia de pagos)
								<li>¿Cuántos pagos te harán?: (Número de pagos)
								<li>¿Cuál es la tasa de interés que cobrarás? (Tasa de interés mensual)
								<li>¿Cuándo lo prestarás? (Fecha de inicio)
							</ul>
							<p>
								Con esta información básica a-lender puede elabora el resumen y tabla de pagos que muestran las condiciones principales y el monto total a pagar, así como el desglose por cada período de cada concepto y el saldo correspondiente.
							</p>
							<p>
								Además, tienes la opción de incluir una o más variables adicionales y establecer los parámetros (opciones avanzadas) para diseñar tu préstamo:
							</p>
							<ul>
								<li>Tipo de amortización del préstamo
								<li>Forma de cálculo de intereses
								<li>Intereses moratorios
								<li>Período de gracia
								<li>Comisiones
								<li>IVA
								<li>Retención ISR
								<li>Garantías
								<li>Pagos personalizados.
							</ul>
							<p>
								Puedes regresar a modificar cualquier dato, agregar o quitar variables, o bien hacer una nueva cotización. Cualquier cotización la puedes imprimir y/o descargar en formato PDF y en Excel.
							</p>
							<p>
								Finalmente, puedes guardar la cotización como una solicitud de préstamo, sólo es necesario llenar ciertos datos del Solicitante y la forma en que lo prestarías. Además, puedes elegir si deseas que se le envíe por correo electrónico el resumen y tabla de pagos.
							</p>
							<p>
								En resumen, el cotizador de a-lender te permite una gran flexibilidad para diseñar tus préstamos. Puedes simular desde un préstamo sin intereses hasta préstamos tipo bancarios con accesorios y pagos irregulares.
							</p>
							<p>
								Para diseñar y simular préstamos entra a Menú: <u><a class="text-link" href="/simulador">Cotizador.</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo integro una Solicitud de préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Una vez que definiste la cotización del préstamo, a-lender te ayuda también a integrar la Solicitud fácilmente y tan completa como desees. La finalidad es que cuentes con un expediente digital con los datos e información del Solicitante que requieras, así como copia de los documentos que le solicites, para que tengas elementos suficientes para evaluar y autorizar el préstamo.
							</p>
							<p>
								Los pasos para completar la Solicitud, evaluarla y, en su caso, entregar el préstamo, son los siguientes:
							</p>
							<ul>
								<li>Datos solicitud: revisar, en su caso modificar, y confirmar condiciones de la solicitud. Además de definir los tipos de datos que se desean para integrar el expediente digital del Solicitante del préstamo.
								<li>Integrar expediente: llenar los datos del Solicitante, así como seleccionar y cargar archivos de distintos documentos que se quieran.
								<li>Autorizar solicitud: evaluar al Solicitante y la solicitud, autorizar o rechazar, y en su caso definir la forma de entrega. Además, si la forma del préstamo que seleccionaste es ‘Legal’ se genera en automático la documentación legal (pagaré y contrato) para su impresión y/o envío.
								<li>Registrar entrega: registrar el desembolso del préstamo una vez que sea entregado al Solicitante. En este paso la solicitud se convierte en un préstamo activo.
							</ul>
							<p>
								Para ver el listado de tus solicitudes activas y abrir el detalle entra a Solicitudes/ Sección: <u><a class="text-link" href="/solicitud-de-prestamo">Integrar expediente</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cuál es la diferencia entre otorgar un préstamo ‘a la palabra’ o ‘legal’?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Tú decides la manera que deseas otorgar un préstamo desde el punto de vista legal, generalmente depende del grado de confianza que le tengas al Solicitante, o la probabilidad de pago (riesgo crediticio) que estimes. Las formas básicas de préstamos son:
							</p>
							<ul>
								<li>‘A la palabra’: cuando no se firma ningún documento legal para respaldar el préstamo otorgado. En caso de no pago, la calidad moral del Solicitante es la que respalda el préstamo. No es posible ejercer ninguna acción judicial en contra del deudor. Generalmente más usado en préstamos a familiares directos y amigos muy cercanos. En el sistema se registran las condiciones del préstamo y se genera la tabla de pagos, serán obligatorios sólo los datos básicos del Solicitante, y se pueden utilizar todas las funcionalidades.
								<li>‘Legal – Pagaré’: cuando se firma un pagaré para respaldar el préstamo otorgado. El pagaré es un título de crédito en el que el emisor se compromete a pagar una suma económica a un beneficiario antes de una fecha determinada. Este documento debe cumplir con ciertos requisitos legales tanto en su contenido como llenado para ser válido ante una instancia judicial. En caso de no pago, el Prestamista puede ejercer el derecho a presentar una demanda ejecutiva mercantil ante los juzgados competentes para tratar de recuperar legalmente el adeudo. Generalmente es muy usado y recomendado en préstamos a familiares, amigos, conocidos, clientes y empleados. En el sistema se registran las condiciones del préstamo y se genera la tabla de pagos, serán obligatorios los datos básicos del Solicitante y los necesarios para generar correctamente el Pagaré, y se pueden utilizar todas las funcionalidades.
								<li>‘Legal – Contrato y Pagaré’: cuando además del pagaré se firma un contrato de mutuo (préstamo) respaldar el préstamo otorgado. El contrato de mutuo es un acuerdo legal en el que una persona transfiere a otra una cantidad de dinero, quien se obliga a restituirlo en la misma especie y calidad. Este documento debe cumplir con ciertos requisitos legales tanto en su contenido como llenado para ser válido ante una instancia judicial. En caso de no pago, el Prestamista puede ejercer el derecho a presentar una demanda ordinaria mercantil ante los juzgados competentes para tratar de recuperar legalmente el adeudo. Generalmente es muy usado y recomendado en préstamos a clientes y empleados. En el sistema se registran las condiciones del préstamo y se genera la tabla de pagos, serán obligatorios los datos básicos del Solicitante y los necesarios para generar correctamente el Pagaré y el Contrato de mutuo, y se pueden utilizar todas las funcionalidades.
							</ul>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Puedo modificar las condiciones de la solicitud?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Sí, una vez que guardaste la solicitud y en tanto no sea autorizada, podrás revisar y modificar las condiciones de la misma, agregar o quitar variables y/o modificar parámetros, y volver a simular la tabla de pagos, cuantas veces lo quieras hasta que tú y el Solicitante estén de acuerdo.
							</p>
							<p>
								Para revisar, modificar y confirmar condiciones de la solicitud entra a Solicitudes/ Sección: <u><a class="text-link" href="/solicitud-de-prestamo">Datos solicitud</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo se llenan los datos del Solicitante en el expediente?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Tú eliges los datos y documentos con los que desees integrar el expediente del Solicitante. La plataforma te muestra diversas secciones de datos y campos recomendados para integrar un expediente, pero la gran mayoría no son obligatorios.
							</p>
							<p>
								Sólo los campos básicos marcados con un asterisco (*) son obligatorios para fines de contacto en caso de recordatorios y requerimientos automatizados de pago (nombre, teléfono, correo electrónico). Adicionalmente, si la forma del préstamo que seleccionaste es ‘Legal’ algunos otros datos también serán marcados obligatorios para generar la documentación legal (pagaré y contrato) con validez jurídica (género, fecha y lugar de nacimiento, domicilio, etc.).
							</p>
							<p>
								Asimismo, a-lender te da la opción de registrar información y cargar documentos de las garantías que solicites, ya sean Avales, Obligados Solidarios, Prendarias, Hipotecarias y/o Líquidas, para que tengas siempre un expediente completo.
							</p>
							<p>
								Los datos y documentos se pueden ingresar a la plataforma de las siguientes maneras:
							</p>
							<ul>
								<li>Captura directamente en la plataforma los datos y cargue los documentos del Solicitante.
								<li>Genera para imprimir y/o enviar en formato PDF una Solicitud de Préstamo personalizada con las secciones de datos y campos seleccionadas, para que el Solicitante la llene, firme y devuelva al Prestamista, quien sube la información a la plataforma.
								<li>Genera y envía una liga para que el Solicitante entre directamente a la plataforma y sólo pueda capturar los datos y cargar los documentos solicitados en esta sección. El Prestamista puede revisar, modificar y añadir información.
							</ul>
							<p>
								Para llenar los datos del Solicitante y cargar documentos entra a Solicitudes/ Sección: <u><a class="text-link" href="/solicitud-de-prestamo">Integrar expediente</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo autorizo una solicitud y formalizo el préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Generalmente cuando prestas a familiares y amigos la decisión de si vas prestar o no, qué cantidad y las demás condiciones, se basan principalmente en cuánto conoces al Solicitante o el grado de compromiso que tienes con esa persona; además obviamente de tu capacidad económica o disponibilidad de recursos en ese momento. En resumen, prestamos de buena fe y basados en la confianza que el Solicitante cumplirá con su compromiso y recuperaremos nuestro dinero.
							</p>
							<p>
								Sin embargo, si vas a prestar a clientes, empleados o incluso familiares y amigos no tan cercanos, es recomendable hacer una evaluación y análisis más profundo para tomar la mejor decisión: prestar en las condiciones adecuadas o no prestar si el riesgo de no pago es alto.
							</p>
							<p>
								En a-lender puedes evaluar al Solicitante y la solicitud, autorizar o rechazar, y en su caso definir la forma de entrega. Además, si la forma del préstamo que seleccionaste es ‘Legal’ se genera en automático la documentación legal (pagaré y contrato) para su impresión y/o envío:
							</p>
							<ul>
								<li>Evaluación: serie de preguntas o guía para que el Prestamista auto-evalúe la solicitud de préstamo, dependiendo su nivel de conocimiento, confianza, compromiso, carácter, capacidad, capital, colateral y las condiciones del préstamo (esta sección es opcional e informativa).
								<li>Forma de entrega: ingresar la fecha de entrega del préstamo, así como detalles de su forma de entrega.
								<li>Autorización: seleccionar si se desea autorizar la solicitud para convertirse en préstamo, o bien rechazar en caso de no querer continuar.
								<li>Generar documentos legales: si se seleccionó alguna de las opciones de forma de préstamo legal, el sistema genera para descarga, envío o impresión los documentos legales para sustentar el préstamo: pagaré y contrato de mutuo.
							</ul>
							<p>
								La última palabra la tiene siempre el Prestamista (dueño del dinero), pero te recomendamos en la medida de lo posible lo siguiente:
							</p>
							<ul>
								<li>Diseñar el préstamo de acuerdo a la capacidad de pago del Solicitante más que en sus necesidades, es decir, que con sus ingresos pueda cubrir en tiempo y forma con la tabla de pagos.
								<li>Solicitar una garantía adicional para casos de no pago, que puede ser aval y/o garantía prendaria (bien mueble) o hipotecaria (bien inmueble).
								<li>Documentar el préstamo al menos con un pagaré con validez judicial, y si es posible con un contrato de mutuo (préstamo) que contenga las condiciones acordadas.
								<li>Integrar el expediente del Solicitante con toda aquella información y documentación que consideres necesaria para tomar la decisión de autorizar o rechazar la solicitud, pero también considera de manera precavida con la requerida en caso de no pago.
								<li>Revisar, evaluar y analizar la información y documentación del expediente, y de ser posible o necesario validarla y verificarla.
								<li>Utilizar una plataforma o sistema de administración de préstamos para tener orden y un buen control del estatus de las solicitudes y sus expedientes, así como apoyarte en generar la documentación legal que necesites.
							</ul>
							<p>
								Próximamente podrás contratar servicios adicionales de validaciones automatizadas, verificaciones telefónicas y domiciliarias de datos, así como consultas de historial crediticio y análisis de crédito del Solicitante, para que accedas a toda la información como los profesionales y tengas mayor tranquilidad al otorgar un préstamo y reduzcas el riesgo de perder tu dinero.
							</p>
							<p>
								Para autorizar y formalizar un préstamo entra a Menú: Solicitudes/ Sección: <u><a class="text-link" href="/solicitud-de-prestamo">Autorizar solicitud</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo registro la entrega del préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Si ya autorizaste una solicitud significa que estás dispuesto a prestar en las condiciones acordadas, por lo que el siguiente paso es entregar el dinero al Solicitante, a esta etapa se le conoce como Otorgamiento o Desembolso, sin embargo, si elegiste alguna de las opciones de forma de préstamo legal, e imprimiste el pagaré y/o contrato de mutuo, es muy importante primero firmarlos por ambas partes antes de la entrega.
							</p>
							<p>
								En la plataforma puedes cargar en su caso, los documentos legales firmados para que se agreguen al expediente digital del Solicitante. Posteriormente es necesario registrar el desembolso para que se considere como un préstamo activo y comience a correr la tabla de pagos y el estado de cuenta:
							</p>
							<ul>
								<li>Documentos legales: si se seleccionó alguna de las opciones legales, el sistema permite cargar los documentos legales debidamente firmados para integrarlo al expediente digital del préstamo: pagaré y contrato de mutuo.
								<li>Desembolso: seleccionar la opción entregado para convertirse en préstamo activo, en tanto no se seleccione será considerado como por entregar, y continuará en el estatus de solicitud pre-autorizada.
							</ul>
							<p>
								Para registrar la entrega de un préstamo entra a Menú: Solicitudes/ Sección: <u><a class="text-link" href="/solicitud-de-prestamo">Registrar entrega</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo doy seguimiento al préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Después de entregar un préstamo, será tiempo de dar seguimiento al cumplimiento de las obligaciones del Solicitante, para que puedas recuperar tu dinero en tiempo y forma, es decir, en las fechas acordadas y las cantidades pactadas.
							</p>
							<p>
								En a-lender puedes entrar a las siguientes secciones para ver el expediente digital con los datos del préstamo, del Solicitante, la documentación y su estado de cuenta. Además, puedes registrar los pagos que recibas del Solicitante:
							</p>
							<ul>
								<li>Datos préstamo: visualizar condiciones y tabla de pago del préstamo.
								<li>Expediente: ver los datos de Solicitante que hayas registrado, así como los documentos requeridos en la solicitud del préstamo y en su caso, los documentos legales que se tengan.
								<li>Estado de cuenta: ver, descargar, imprimir y enviar el estado de cuenta del préstamo.
								<li>Registrar pagos: escribe los datos de los pagos recibidos.
							</ul>
							<p>
								Los principales dolores de cabeza del Prestamista son recuperar su dinero, no deteriorar la amistad o relación con el Solicitante, hacer las cuentas correctamente y tener que cobrar directamente. Por eso a-lender calcula la tabla de pagos y los saldos, y envía recordatorios o requerimientos de pago automatizados al Solicitante para evitarte molestias, disgustos y pérdida de tiempo. Tu podrás definir en cualquier momento el tipo de cobranza que desees dependiendo la relación o situación de cada préstamo, si deseas que se contacte al Solicitante y la frecuencia y nivel de los mensajes.
							</p>
							<p>
								Para ver el listado de tus préstamos activos y abrir el detalle entra a Menú: <u><a class="text-link" href="/prestamos">Préstamos</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo visualizar condiciones y tabla de pago del préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								En cualquier momento puedes consultar y descargar las condiciones, resumen y tabla de pagos de cualquiera de tus préstamos registrados. Una vez entregado el préstamo no es posible modificar ningún dato del préstamo.
							</p>
							<p>
								Para consultar y descargar los datos del préstamo entra a Menú: Préstamos/ Sección: <u><a class="text-link" href="/prestamos">Préstamos</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo ver los datos de Solicitante y sus documentos?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								En cualquier momento puedes consultar y descargar el expediente, donde puedes acceder tanto a los datos de Solicitante registrados, como a los documentos cargados en la solicitud del préstamo y los documentos legales digitalizados que se tengan. Una vez entregado el préstamo no es posible modificar ningún dato del expediente.
							</p>
							<p>
								Para consultar y descargar el expediente del préstamo entra a Menú: Préstamos/ Sección: <u><a class="text-link" href="/prestamos">Expediente</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo visualizar el estado de cuenta?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								En cualquier momento puedes consultar, descargar, e imprimir el estado de cuenta de cualquiera de tus préstamos registrados. También puedes seleccionar que se envié por correo electrónico en automático al Solicitante o la persona que desees.
							</p>
							<p>
								El estado de cuenta se va actualizando diariamente de acuerdo a la tabla de pagos del préstamo o en cualquier momento que se registre un nuevo pago o movimiento, por lo que los saldos cambian a través del tiempo.
							</p>
							<p>
								El estado de cuenta muestra los datos de los participantes, un resumen de las condiciones y la tabla de amortización real del préstamo. Aquí se muestra en cada renglón los movimientos por fecha, desglosando en columnas cada uno por tipo y concepto de aplicación, y calculando los saldos correspondientes:
							</p>
							<ul>
								<li>Número de movimiento
								<li>Fecha de movimiento
								<li>Tipo movimiento
								<li>Cargo / Abono
								<li>Capital
								<li>Intereses
								<li>Moratorios
								<li>Comisiones
								<li>IVA
								<li>Saldo total
								<li>Saldo con atraso
								<li>Capital vencido
							</ul>
							<p>
								Para consultar, descargar o enviar estados de cuenta entra a Menú: Préstamos/ Sección: <u><a class="text-link" href="/prestamos">Estados de cuenta</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo registro los pagos recibidos?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Cuando el Solicitante realice un pago a su préstamo, el Prestamista debe registrar en la plataforma el monto recibido, la fecha y la forma de pago, es decir si lo recibe en efectivo, transferencia, cheque, etc. Con estos datos se genera un Comprobante de pago que puedes imprimir, descargar y enviar al Solicitante.
							</p>
							<p>
								Es importante que cada vez que recibes un pago de tu préstamo lo registres lo más pronto posible en a-lender, ya que, si no lo haces, el estado de cuenta estará desactualizado y los saldos que muestre no serán los correctos, además se enviarían mensajes automáticos de requerimientos de pago, cuando éste ya se realizó.
							</p>
							<p>
								Para registrar los pagos recibidos entra a Menú: Préstamos/ Sección: <u><a class="text-link" href="/prestamos">Registrar pagos</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo ver el resumen de mi portafolio?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								En cualquier momento puedes consultar, descargar, e imprimir reportes de la situación del préstamo o de todo tu portafolio, de lo que te solicitan, has prestado y cobrado.
							</p>
							<ul>
								<li>Tablero: resumen del portafolio de operaciones (solicitudes, préstamos, pagos, saldos y suscripciones)
								<li>Colocación: listado de préstamos colocados
								<li>Cobranza: listado de pagos recibidos
								<li>Cartera: listado de préstamos
							</ul>
							<p>
								Tú eres el dueño de tu información y de tu base de datos, en a-lender puedes acceder a ella fácil, rápido y seguro. Próximamente podrás tener más reportes e información gráfica para que administres mejor tus préstamos.
							</p>
							<p>
								Para consultar reportes de tus solicitudes, préstamos, pagos y saldos entra a Menú: <u><a class="text-link" href="/prestamos">Reportes</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>
					</mat-accordion>
				</mat-tab>
				<mat-tab label="Soy Solicitante">
					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<div>
										<h5 class="text-dark custom-tittle">
											<span class="material-icons mr-2 text-success">
												help_outline
											</span>
											¿Cuáles son los pasos para solicitar un préstamo?
										</h5>
									</div>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Si solicitas un préstamo informal a un familiar o amigo cercano, o a un prestamista formal o incluso una financiera o un banco, el ciclo del préstamo consta en general de las siguientes etapas:
							</p>
							<ul>
								<li>Promoción
								<li>Solicitud e integración de información
								<li>Evaluación y resolución
								<li>Otorgamiento
								<li>Seguimiento y control
							</ul>
							<p>
								Cada etapa puede ser tan simple o profunda como lo requiera el Prestamista, es decir, depende de cómo te quiera otorgar el préstamo, puede ser ‘a la palabra’ o documentarse legalmente con un pagaré que lo respalde y/o un contrato de préstamo que contenga todas las condiciones y acuerdos entre las partes.
							</p>
							<p>
								Si a la persona o empresa a quien le solicitas el dinero (Prestamista) utiliza a-lender, tu cómo Solicitante tendrás también beneficios para hacer más fácil y rápido el proceso del préstamo a través de las siguientes funcionalidades:
							</p>
							<ul>
								<li>Diseñar la Cotización: simular préstamos a la medida, definir las condiciones básicas y, en su caso, avanzadas. Puedes descargar, guardar y enviar la cotización como una solicitud de préstamo al Prestamista.
								<li>Integrar una Solicitud: integrar tu expediente digital con los datos y documentos que el Prestamista te requiera.
								<li>Dar seguimiento al Préstamo: consultar tu expediente digital y el estado de cuenta del préstamo.
								<li>Generar Reportes: consultar y descargar reportes de la situación del préstamo o de todo el portafolio, de lo que le solicitas, te han prestado y has pagado.
							</ul>
							<p>
								Si tu Prestamista aún no utiliza a-lender, invítalo a conocernos para que pueda administrar sus préstamos como los profesionales.
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: Tutoriales
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo diseño una solicitud de préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								a-lender cuenta con un cotizador que te ayuda fácil y rápido a diseñar y simular préstamos tan sencillos o sofisticados como desees. Es una calculadora financiera especializada que te permite elegir diversas variables y establecer parámetros para tener un préstamo a tu medida.
							</p>
							<p>
								Un préstamo requiere especificar ciertos datos mínimos necesarios (opciones básicas) para poder calcular las fechas y monto de pagos, así como el total a pagar, es decir, para generar una tabla de pagos (tabla de amortización):
							</p>
							<ul>
								<li>¿Cuánto dinero necesitas? (Monto)
								<li>¿Cada cuándo serán tus pagos? (Frecuencia de pagos)
								<li>¿Cuántos pagos harás?: (Número de pagos)
								<li>¿Cuál es la tasa de interés mensual? (Tasa de interés mensual)
								<li>¿Cuándo necesitas el dinero? (Fecha de inicio)
							</ul>
							<p>
								Con esta información básica a-lender puede elabora el resumen y tabla de pagos que muestran las condiciones principales y el monto total a pagar, así como el desglose por cada período de cada concepto y el saldo correspondiente.
							</p>
							<p>
								Además, tienes la opción de incluir una o más variables adicionales y establecer los parámetros (opciones avanzadas) para diseñar tu préstamo:
							</p>
							<ul>
								<li>Tipo de amortización del préstamo
								<li>Forma de cálculo de intereses
								<li>Intereses moratorios
								<li>Período de gracia
								<li>Comisiones
								<li>IVA
								<li>Retención ISR
								<li>Garantías
								<li>Pagos personalizados.
							</ul>
							<p>
								Puedes regresar a modificar cualquier dato, agregar o quitar variables, o bien hacer una nueva cotización. Cualquier cotización la puedes imprimir y/o descargar en formato PDF y en Excel.
							</p>
							<p>
								Finalmente, puedes guardar y enviar la cotización como una solicitud de préstamo a la persona a quien le solicitarás el dinero (Prestamista). El Prestamista posteriormente podrá revisar y modificar las condiciones y continuar con el proceso de evaluación y autorización de la solicitud.
							</p>
							<p>
								En resumen, el cotizador de a-lender te permite una gran flexibilidad para diseñar tus solicitudes de préstamos. Puedes simular desde un préstamo sin intereses hasta préstamos tipo bancarios con accesorios y pagos irregulares.
							</p>
							<p>
								Para diseñar y simular solicitudes de préstamos entra a Menú: <u><a class="text-link" href="/simulador">Cotizador.</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo ver las condiciones de la solicitud?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Si tú Prestamista utiliza a-lender, entonces podrás revisar las condiciones de la solicitud. No podrás modificar, agregar o quitar variables y/o modificar parámetros directamente, estos permisos sólo los tiene el Prestamista. En caso de no estar de acuerdo o querer hacer alguna modificación, deberás comunicarte con el Prestamista.
							</p>
							<p>
								Si el Prestamista registró directamente la solicitud de préstamo, recibirás un correo electrónico con una liga para ingresar a la plataforma y poder consultar las condiciones. En este caso, sólo debes crear una contraseña para registrarte (sin costo).
							</p>
							<p>
								Para revisar las condiciones de la solicitud entra a Solicitudes/ Sección: <u><a class="text-link" href="/solicitud-de-prestamo">Datos solicitud</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo se llenan mis datos como Solicitante en el expediente?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Si tú Prestamista utiliza a-lender, entonces elegirá los datos y documentos con los que desea integrar el expediente tu solicitud.
							</p>
							<p>
								Se requieren ciertos campos básicos marcados con un asterisco (*) como obligatorios para fines de contacto en caso de recordatorios y requerimientos automatizados de pago (nombre, teléfono, correo electrónico). Adicionalmente, si la forma del préstamo seleccionada es ‘Legal’ algunos otros datos también serán marcados obligatorios para generar la documentación legal (pagaré y contrato) con validez jurídica (género, fecha y lugar de nacimiento, domicilio, etc.).
							</p>
							<p>
								Asimismo, en a-lender también se puede registrar información y cargar documentos de las garantías que se llegaran a solicitar ya sean Avales, Obligados Solidarios, Prendarias, Hipotecarias y/o Líquidas.
							</p>
							<p>
								Los datos y documentos se pueden ingresar a la plataforma de las siguientes maneras:
							</p>
							<ul>
								<li>El Prestamista captura directamente en la plataforma los datos y carga los documentos.
								<li>El Prestamista imprime o te envía en formato PDF una Solicitud de Préstamo personalizada con las secciones de datos y campos seleccionadas, para que los llenes, firmes y devuelvas al Prestamista, quien sube la información a la plataforma.
								<li>El Prestamista te envía una liga por correo electrónico para que tú como Solicitante entres directamente a la plataforma y sólo puedas capturar los datos y cargar los documentos solicitados en esta sección. En este caso, sólo debes crear una contraseña para registrarte (sin costo).
							</ul>
							<p>
								Para llenar los datos del Solicitante y cargar documentos entra a Solicitudes/ Sección: <u><a class="text-link" href="/solicitud-de-prestamo">Integrar expediente</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo doy seguimiento al préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Después de recibir un préstamo, será tiempo de dar cumplimiento a obligaciones contraídas como Solicitante, es decir pagar el dinero en tiempo y forma en las fechas acordadas y las cantidades pactadas.
							</p>
							<p>
								En a-lender puedes entrar a las siguientes secciones para ver el expediente digital con los datos del préstamo, del Solicitante, la documentación y su estado de cuenta. Además, puedes revisar los pagos que realices al Prestamista:
							</p>
							<ul>
								<li>Datos préstamo: visualizar condiciones y tabla de pago del préstamo.
								<li>Expediente: ver los datos de Solicitante que hayas registrado, así como los documentos requeridos en la solicitud del préstamo y en su caso, los documentos legales que se tengan.
								<li>Estado de cuenta: ver, descargar, imprimir y enviar el estado de cuenta del préstamo.
								<li>Revisar pagos: consultar los datos y comprobantes de los pagos realizados.
							</ul>
							<p>
								Para ver el listado de tus préstamos activos y abrir el detalle entra a Menú: <u><a class="text-link" href="/prestamos">Préstamos</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo visualizar condiciones y tabla de pago del préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								En cualquier momento puedes consultar y descargar las condiciones, resumen y tabla de pagos de cualquiera de tus préstamos registrados.
							</p>
							<p>
								Para consultar y descargar los datos del préstamo entra a Menú: Préstamos/ Sección: <u><a class="text-link" href="/prestamos">Datos préstamos</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo ver mis datos como Solicitante y los documentos?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								En cualquier momento puedes consultar y descargar el expediente, donde puedes acceder tanto a los datos de Solicitante registrados, como a los documentos cargados en la solicitud del préstamo y los documentos legales digitalizados que se tengan.
							</p>
							<p>
								Para consultar y descargar el expediente del préstamo entra a Menú: Préstamos/ Sección: <u><a class="text-link"href="/prestamos">Expediente</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo visualizar el estado de cuenta?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								En cualquier momento puedes consultar, descargar, e imprimir el estado de cuenta de cualquiera de tus préstamos registrados.
							</p>
							<p>
								El estado de cuenta se va actualizando diariamente de acuerdo a la tabla de pagos del préstamo o en cualquier momento que se registre un nuevo pago o movimiento, por lo que los saldos cambian a través del tiempo.
							</p>
							<p>
								El estado de cuenta muestra los datos de los participantes, un resumen de las condiciones y la tabla de amortización real del préstamo. Aquí se muestra en cada renglón los movimientos por fecha, desglosando en columnas cada uno por tipo y concepto de aplicación, y calculando los saldos correspondientes:
							</p>
							<ul>
								<li>Número de movimiento
								<li>Fecha de movimiento
								<li>Tipo movimiento
								<li>Cargo / Abono
								<li>Capital
								<li>Intereses
								<li>Moratorios
								<li>Comisiones
								<li>IVA
								<li>Saldo total
								<li>Saldo con atraso
								<li>Capital vencido
							</ul>
							<p>
								Para consultar y descargar el estado de cuenta entra a Menú: Préstamos/ Sección: <u><a class="text-link" href="/prestamos">Estados de cuenta</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo válido que mis pagos hayan sido registrados?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Cuando el Solicitante realice un pago a su préstamo, el Prestamista debe registrar en la plataforma el monto recibido, la fecha y la forma de pago, es decir si lo recibe en efectivo, transferencia, cheque, etc. Con estos datos se genera un Comprobante de pago que puedes imprimir, descargar y recibirás por correo electrónico como Solicitante.
							</p>
							<p>
								Es importante que cada vez que realices un pago de tu préstamo, el Prestamista lo registre lo más pronto posible en a-lender, ya que, si no lo hace, el estado de cuenta estará desactualizado y los saldos que muestre no serán los correctos, además podrás recibir mensajes automáticos de requerimientos de pago, cuando éste ya se realizó.
							</p>
							<p>
								Para revisar los pagos realizados entra a Menú: Préstamos/ Sección: <u><a class="text-link" href="/prestamos">Registrar pagos</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo ver el resumen de mi portafolio?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								En cualquier momento puedes consultar, descargar, e imprimir reportes de la situación del préstamo o de todo tu portafolio, de lo que solicitas, te han prestado y has pagado.
							</p>
							<ul>
								<li>Tablero: resumen del portafolio de operaciones (solicitudes, préstamos, pagos, saldos y suscripciones)
								<li>Colocación: listado de préstamos recibidos
								<li>Cobranza: listado de pagos realizados
								<li>Cartera: listado de préstamos
							</ul>
							<p>
								Tú eres el dueño de tu información y de tu base de datos, en a-lender puedes acceder a ella fácil, rápido y seguro. Próximamente podrás tener más reportes e información gráfica para que administres mejor tus préstamos.
							</p>
							<p>
								Para consultar reportes de tus solicitudes, préstamos, pagos y saldos entra a Menú: <u><a class="text-link" href="/prestamos">Reportes</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>
					</mat-accordion>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
</div>
