import { KeyValue } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Amortize, Commission } from 'src/app/models/amortize/amortize.model';
import { QuoteDTO } from 'src/app/models/quote/quote.dto.model';
import { TypeofamortizationService } from 'src/app/services/typeofamortization/typeofamortization.service';
import { TypeofcreditService } from 'src/app/services/typeofcredit/typeofcredit.service';
import { TypeofperiodService } from 'src/app/services/typeofperiod/typeofperiod.service';
import { WarrantyService } from 'src/app/services/warranty/warranty.service';
import { Uuidv4Service } from 'src/app/services/uuidv4/uuidv4.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AmortizeService } from 'src/app/services/amortize/amortize.service';
import { GlobalData, GlobalLists } from 'src/app/global/global-data';
import { DialogAmortizeTableComponent } from '../dialog-amortize-table/dialog-amortize-table.component';
import { MatDialog } from '@angular/material/dialog';

export enum FilterQuote {
  NEW,
  DEFAULT,
  SAVED
}

@Component({
  selector: 'app-simulator-advanced',
  templateUrl: './simulator-advanced.component.html',
  styleUrls: ['./simulator-advanced.component.scss']
})
export class SimulatorAdvancedComponent implements OnInit {

  @Input() filterQuote: number = 0;
  @Input() printMode: boolean = false;
  dateToday = new Date();
  commissionsForm: FormGroup;
  commissionsFormSubmitted = false;
  amortize: Amortize;
  submitted = false;
  trackId: string;
  quoteIdSelected: string = "";
  quotes: QuoteDTO[];
  tableShown: boolean = false;

  form: FormGroup;

  panelCommissionOpenState;
  isPorcentCommission: boolean = false;
  requiredErrorMessage: string = "Este campo es requerido.";

  commissionsColumns = [
    'description',
    'value',
    'whenToPay',
    'buttonRemove'
  ];

  typeOfCommissions = GlobalLists.typeOfCommissions;
  whenToPayArray = GlobalLists.whenToPayArray;

  filteredOptions: Observable<string[]>;  

  commissions = <Commission[]>[];

  dsCommissions = new MatTableDataSource<Commission>(this.commissions);

  typeOfAmortizationList: KeyValue<number, string>[] = [];
  typeOfPeriodList: KeyValue<number, string>[] = [];
  typeOfCreditList: KeyValue<number, string>[] = [];
  warrantyList: KeyValue<number, string>[] = [];
  
  constructor(private formBuilder: FormBuilder,
              private typeofamortizationService: TypeofamortizationService,
              private typeofperiodService: TypeofperiodService,
              private typeofcreditService: TypeofcreditService,
              private warrantyService: WarrantyService,
              private uuidv4Service: Uuidv4Service,
              private amortizeService: AmortizeService,
              private dialog: MatDialog) {
                this.createCommissionsForm();
                this.createForm();
              }

  get f1() { return this.form.controls; }
  get fCommissions() { return this.commissionsForm.controls; }
  get getTypeOfAmortization() { return this.typeOfAmortizationList.find(x => x.key === parseInt(this.f1['typeOfAmortization'].value)).value; }
  get getTypeOfPeriod() { return this.typeOfPeriodList.find(x => x.key === parseInt(this.f1['typeOfPeriod'].value)).value; }
  get getTypeOfCredit() { return this.typeOfCreditList.find(x => x.key === parseInt(this.f1['typeOfCredit'].value)).value; }
  get getTypeOfWarranty() { return this.warrantyList.find(x => x.key === parseInt(this.f1['warranty'].value)).value; }
  
  ngOnInit(): void {

    this.filteredOptions = this.commissionsForm.controls['description'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.trackId = this.uuidv4Service.uuidv4();
    this.getTypeOfAmortizationList();
    this.getTypeOfPeriodList();
    this.getTypeOfCreditList();
    this.getWarrantyList();

    this.commissions = JSON.parse(this.f1['commissions'].value) as Commission[];
    if (this.commissions && this.commissions.length > 0) {
      this.panelCommissionOpenState = true;
      this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
    }
    
    if (this.f1['typeOfCredit'].value.toString() === "1") {
      this.f1['periodAmortizeStart'].patchValue(this.f1['totalPeriods'].value.toString());
      this.f1['periodAmortizeStart'].disable();
    }
  }

  private _filter(value: string): string[] {
    let filterValue = '';
    if (value) {
      filterValue = value.toLowerCase();
    }
    return this.typeOfCommissions.filter(option => option.toLowerCase().includes(filterValue));
  }

  getTypeOfAmortizationList() {
    this.typeofamortizationService.getList()
    .subscribe((list)=>{
      this.typeOfAmortizationList = list;
    });
  }

  getTypeOfPeriodList() {
    this.typeofperiodService.getList()
    .subscribe((list)=>{
      this.typeOfPeriodList = list;
    });
  }

  getTypeOfCreditList() {
    this.typeofcreditService.getList()
    .subscribe((list)=>{
      this.typeOfCreditList = list;
    });
  }

  getWarrantyList() {
    this.warrantyService.getList()
    .subscribe((list)=>{
      this.warrantyList = list;
    });
  }  

  createForm() {
    this.form = this.formBuilder.group({
      loanAmount: [''],
      startDate: [''],
      totalPeriods: [''],
      typeOfPeriod: [''],
      monthlyRate:  [''],
      //advanced
      periodAmortizeStart: ['1', Validators.required],
      typeOfAmortization: ['1', Validators.required],
      typeOfCredit: ['1', Validators.required],
      warranty: ['1', Validators.required],
      commissions: ['[]'],
      monthlyPorcentOnArrears: ['0'],
      applyTax: [false],
      customPayments: [false],
      amortizeTableWithPayments: [''],
      warrantyDataJson: ['1', Validators.required]
    });
  }  

  createCommissionsForm() {
    this.commissionsForm = this.formBuilder.group({
      description: ['', Validators.required],      
      isPorcent: ['false'],      
      value: ['0', Validators.required],
      whenToPay: ['0', Validators.required],
    });
  }

  onSubmitCommission() {

    this.commissionsFormSubmitted = true;

    if (this.commissionsFormSubmitted && this.commissionsForm.invalid) {
      return;
    }

    this.addCommission();
  }

  // setInputs(loadTable: boolean) {
  //   this.quoteService.get(this.quoteIdSelected).subscribe((data) => {    
  //     this.f1['loanAmount'].patchValue(data.loanAmount.toString());      
  //     this.f1.annualRate.patchValue(data.annualRate.toString());     
  //     this.f1['totalPeriods'].patchValue(data.totalPeriods.toString());
  //     this.f1['periodAmortizeStart'].patchValue(data.periodAmortizeStart.toString());
  //     this.f1['typeOfPeriod'].patchValue(data.typeOfPeriod.toString());
  //     this.f1['typeOfAmortization'].patchValue(data.typeOfAmortization.toString());
  //     this.f1.taxPorcent.patchValue(data.taxPorcent.toString());
  //     this.f1['startDate'].patchValue(data.startDate.toString());
  //     this.f1['typeOfCredit'].patchValue(data.typeOfCredit.toString());
  //     this.f1['warranty'].patchValue(data.warranty.toString());

  //     if (loadTable) {
  //       this.onSubmit();
  //     }
  //   });
  // }

  // print() {      
  //   var mywindow = window.open();

  //   mywindow.document.write('<html><head><title>' + document.title  + '</title>');
  //   mywindow.document.write('</head><body>');
  //   mywindow.document.write('<style>table { font-family: Calibri; font-size: 8px; border: 1px solid black; }</style>');
  //   mywindow.document.write('<p>Tabla de amortización</p>');
  //   mywindow.document.write(document.getElementById("inputs").innerHTML);
  //   mywindow.document.write(document.getElementById("content").innerHTML);
  //   mywindow.document.write('</body></html>');
  //   mywindow.document.write('<script>document.getElementById("inputs").style.display = null;</script>');
    
  //   mywindow.stop();
  //   mywindow.print();

  //   return true;
  // }

  onDeleteCommission(index: number): void {
    this.commissions.splice(index, 1);
    this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
    this.f1['commissions'].patchValue(JSON.stringify(this.commissions));
  }

  addCommission(): void {
    this.commissions.push(<Commission>{
      description: this.fCommissions['description'].value,
      isPorcent: this.isPorcentCommission,
      value: parseFloat(this.fCommissions['value'].value),
      whenToPay: parseInt(this.fCommissions['whenToPay'].value),
      whenToPayDesc: this.whenToPayArray.find(x => x.key === parseInt(this.fCommissions['whenToPay'].value)).value
    });

    this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
    this.f1['commissions'].patchValue(JSON.stringify(this.commissions));
    this.commissionsFormSubmitted = false;
    this.fCommissions['description'].patchValue(' ');
    this.fCommissions['isPorcent'].patchValue('false');
    this.fCommissions['value'].patchValue('0');
    this.fCommissions['whenToPay'].patchValue('0');
  }

  onSubmitAdvancedOptions() {

  }

  applyCustomPayments(checked) {
    if (checked) {
      this.amortize = <Amortize> {
        loanAmount: parseFloat(this.f1['loanAmount'].value),
        annualRate: parseFloat(this.f1['monthlyRate'].value) * GlobalData.MONTHS_PER_YEAR,
        totalPeriods: parseInt(this.f1['totalPeriods'].value),
        periodAmortizeStart: parseInt(this.f1['periodAmortizeStart'].value),
        typeOfPeriod: parseInt(this.f1['typeOfPeriod'].value),
        typeOfAmortization: parseInt(this.f1['typeOfAmortization'].value),
        taxPorcent: this.f1['typeOfAmortization'].value ? GlobalData.TAX : 0,
        startDate: this.f1['startDate'].value,
        commissions: this.commissions,
        trackId: this.uuidv4Service.uuidv4()
      };
    } else {
      this.amortizeService.resetAmortizeTableExtraPaymentsResponseObserver();
    }
  }

  resetForm() {
    this.f1['customPayments'].patchValue(false);
    this.amortizeService.resetAmortizeTableExtraPaymentsResponseObserver();

    this.f1['periodAmortizeStart'].patchValue('1');
    this.f1['typeOfAmortization'].patchValue('1');
    this.f1['typeOfCredit'].patchValue('1');
    this.f1['warranty'].patchValue('1');
    this.f1['commissions'].patchValue('[]');
    this.f1['monthlyPorcentOnArrears'].patchValue('0');
    this.f1['applyTax'].patchValue(false);
    this.f1['customPayments'].patchValue(false);

    this.commissions = [];
    this.panelCommissionOpenState = false;
    this.dsCommissions = new MatTableDataSource<Commission>(this.commissions);
  }

  showDialogTable() {
    const dialogRef = this.dialog.open(DialogAmortizeTableComponent, {
      autoFocus: false,
      width: '80%'
    });
    let instance = dialogRef.componentInstance;

    if (!this.amortizeService.currentAmortizeTableExtraPaymentsResponseValue.table) {
      this.amortizeService.generateTable(this.amortize);
    }    
  }

  onChangeSelectTypeOfCredit(value) {
    if (value === "1") {
      this.f1['periodAmortizeStart'].patchValue(this.f1['totalPeriods'].value.toString());
      this.f1['periodAmortizeStart'].disable();
    } else {
      this.f1['periodAmortizeStart'].enable();
      this.f1['periodAmortizeStart'].patchValue('1');
    }
  }
}
