<div class="container-fluid">
  <h4>Recarga de saldo</h4>
  <div class="row">
    <div class="col-lg-8">
      <div class="card">
        <div class="card-body">
          <div class="mb-4">
            <h5>Comprar saldo</h5>
            <hr>

            <p>Sigue los siguientes pasos para comprar saldo</p>
          </div>

          <mat-horizontal-stepper [linear]="true" #stepperBalance labelPosition="bottom">
            <mat-step [stepControl]="subscriptionTypeForm">
              <ng-template matStepperIcon="edit">
                <i class="bx bx-check"></i>
              </ng-template>
              <ng-template matStepperIcon="active">
                <i class="bx bx-check"></i>
              </ng-template>
              <ng-template matStepLabel>Monto a recargar</ng-template>
              <form [formGroup]="subscriptionTypeForm" (ngSubmit)="onSubmitSubscriptionTypeForm()">
                <app-input-errors-global *ngFor="let item of f_subscriptionTypeFormProp" fieldName="{{item}}">
                </app-input-errors-global>
                <section id="planes">
                  <div class="container-fluid py-3">
                    <div class="row justify-content-between">
                      <div *ngIf="planList" class="mx-auto light">
                        <p class="text primary fw-bold mb-3">¿Cuánto saldo quieres adquirir?</p>

                        <div class="row mx-0 justify-content-between gap-2">
                          <div *ngFor="let item of getPlanList()" class="plan-options btn_balanced">
                            <button class="btn_custom" [ngClass]="selectedButton === item ? 'selected' : ''"
                              (click)="selectPlan(item.packageName, false)">
                              {{ (item.packagePrice | currency).replace(".00", "") }}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="mt-3">
                        <p class="mb-3">ó ingresa la cantidad de saldo que desees:</p>

                        <div class="d-flex justify-content-center">
                          <div class="form-group">
                            <input type="text" (focusout)="handleInputFocusOut($event)"
                              formControlName="amountPersonalized" keyboardType="numeric" placeholder="$0"
                              class="form-control buy-something mx-auto" [textMask]="{mask: currencyMask}"
                              autocomplete="loanAmount" [class.filled]="inputFilled" />
                            <mat-error
                              *ngIf="submmitedAmountPersonalized && f_subscriptionTypeForm['amountPersonalizedAmount'].errors?.min">El
                              saldo mínimo para adquirir es $100</mat-error>
                            <mat-error
                              *ngIf="submmitedAmountPersonalized && f_subscriptionTypeForm['amountPersonalizedAmount'].errors?.max">El
                              saldo máximo para adquirir es $20,000</mat-error>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div class="d-flex flex-wrap gap-2 justify-content-end">
                  <button class="btn btn-outline-primary external-btn" routerLink="/mi-cuenta">
                    Regresar
                  </button>
                  <button class="btn btn-primary external-btn" type="submit">
                    Continuar
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="balanceMethodPaymentForm" [optional]="false" [editable]="false">
              <ng-template matStepLabel>Método de pago</ng-template>
              <form [formGroup]="balanceMethodPaymentForm" (ngSubmit)="onSubmitBalanceMethodPaymentForm()"
                id="card-form" class="pt-3">
                <div>
                  <td valign="top" id="x_templateHeader"
                    style="padding: 10px 18px; background-color: rgb(10, 24, 55); background-image: none; background-repeat: no-repeat; background-position: center center; background-size: 50% auto; border-top-left-radius: 10px; border-top-right-radius: 10px; border-top: 0px; display: block; color: rgb(179, 179, 179) !important; font-family: Helvetica; font-size: 12px; line-height: 150%; text-align: left;"
                    data-ogsc="rgb(96, 96, 96)">
                    <span style="color: white;">Pagos seguros con</span>
                    <img data-imagetype="External" src="/assets/images/logos/conekta_white.svg" alt=""
                      style="width: 11%; border: 0; height: auto; outline: none; text-decoration: none; display: block;">
                  </td>
                </div>
                <div class="typePayment">
                  <label class="mt-2 mb-n2">Selecciona un método de pago</label>
                  <div class="card-body ">
                    <div class="row just_around_nw">
                      <ng-container *ngFor="let paymentType of methodsPayment; let i = index">
                        <div class="col" [ngClass]="{'selected': selectedPaymentIndex === i}"
                          (click)="selectMethodPayment(paymentType.methodNumber, i)">
                          <i
                            class="bx {{paymentType.icon == 'bx-credit-card' ? 'bx-credit-card' : paymentType.icon == 'bx-wallet' ?'bx-wallet' : 'bx-send' }}"></i>
                          <h5>{{paymentType.methodName == 'Tarjeta' ? 'Tarjeta' : paymentType.methodName == 'OXXO Pay' ?
                            'Efectivo' : 'Transferencia'}}</h5>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <span class="card-errors"></span>
                <div class="row" *ngIf="paymentSpeiSelected">
                  <div class="form-group mb-3">
                    <label for="fullName">Nombre en la tarjeta*</label>
                    <input type="text" formControlName="fullName" value="" class="form-control"
                      data-conekta="card[name]" required [attr.disabled]>
                    <mat-error *ngIf="balanceMethodPaymentSubmited && validateRequiredForm('fullName')">
                      {{requiredErrorMessage}}
                    </mat-error>
                  </div>
                  <div class="col-lg-5 col-md-7 mb-3">
                    <div class="form-group">
                      <label for="tarjetNumber">Número de tarjeta*</label>
                      <div class="card-js">
                        <div class="card-number-wrapper">
                          <input type="text" formControlName="tarjetNumber" [textMask]="{mask: mask}"
                            class="form-control" data-conekta="card[number]" required>
                          <div class="card-type-icon">
                            <img id="imgCard" class="img-fluid" src="">
                          </div>
                        </div>
                      </div>
                      <mat-error *ngIf="balanceMethodPaymentSubmited && validateRequiredForm('tarjetNumber')">
                        {{requiredErrorMessage}} </mat-error>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-7 mb-3">
                    <div class="form-group">
                      <label for="expirationDate">Fecha vencimiento*</label>
                      <div class="card-js">
                        <div class="card-number-wrapper">
                          <input type="text" formControlName="expirationDate" [textMask]="{mask: maskExpirationDate}"
                            placeholder="MM/YY" class="form-control" (input)="updateExpirationDate($event)" required>
                        </div>
                      </div>
                      <mat-error *ngIf="balanceMethodPaymentSubmited && validateRequiredForm('expirationDate')">
                        {{requiredErrorMessage}} </mat-error>
                      <mat-error *ngIf="f_balanceMethodPaymentForm['expirationDate'].hasError('invalidMonth')">
                        Mes de vencimiento inválido.
                      </mat-error>
                      <mat-error *ngIf="f_balanceMethodPaymentForm['expirationDate'].hasError('invalidYear')">
                        Año de vencimiento inválido.
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 mb-3 d-none">
                    <div class="form-group"> <label for="dateEndMonth">Mes vencimiento*</label>
                      <select #selectPeriod class="form-control" formControlName="dateEndMonth"
                        data-conekta="card[exp_month]" required>
                        <option value="">Seleccione</option>
                        <option value="1">01 Enero</option>
                        <option value="2">02 Febrero</option>
                        <option value="3">03 Marzo</option>
                        <option value="4">04 Abril</option>
                        <option value="5">05 Mayo</option>
                        <option value="6">06 Junio</option>
                        <option value="7">07 Julio</option>
                        <option value="8">08 Agosto</option>
                        <option value="9">09 Septiembre</option>
                        <option value="10">10 Octubre</option>
                        <option value="11">11 Noviembre</option>
                        <option value="12">12 Diciembre</option>
                      </select>
                      <mat-error *ngIf="balanceMethodPaymentSubmited && validateRequiredForm('dateEndMonth')">
                        {{requiredErrorMessage}} </mat-error>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-3 mb-3 d-none">
                    <div class="form-group"> <label for="dateEndYear">Año vencimiento*</label>
                      <select #selectPeriod class="form-control" formControlName="dateEndYear"
                        data-conekta="card[exp_year]" required>
                        <option value="">Seleccione</option>
                        <option *ngFor="let item of yearList" value="{{item.key}}">
                          {{item.value}}
                      </select>
                      <mat-error *ngIf="balanceMethodPaymentSubmited && validateRequiredForm('dateEndYear')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-3 mb-3">
                    <div class="form-group">
                      <label for="cvc">CVV*</label>
                      <input type="text" formControlName="cvc" class="form-control" data-conekta="card[cvc]" required
                        [textMask]="{mask: maskCVV}">
                      <mat-error *ngIf="balanceMethodPaymentSubmited && validateRequiredForm('cvc')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="paymentOxxoSelected || paymentTransfer">
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="fullName">Nombre*</label>
                      <input type="text" formControlName="fullName" class="form-control" required>
                      <mat-error *ngIf="validateRequiredForm('fullName')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="email">Correo electrónico*</label>
                      <input type="text" formControlName="email" class="form-control" required>
                      <mat-error *ngIf="validateRequiredForm('email')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="email">Teléfono <span *ngIf="!paymentTransfer">*</span></label>
                      <input [textMask]="{mask: phoneMask}" type="text" formControlName="phone" class="form-control">
                      <mat-error *ngIf="!paymentTransfer && validateRequiredForm('phone')">
                        {{requiredErrorMessage}}
                      </mat-error>
                    </div>
                  </div>
                </div>


                <div class="d-flex flex-wrap gap-2 justify-content-end">
                  <button class="btn btn-outline-primary external-btn" matStepperPrevious>
                    Atrás
                  </button>
                  <button class="btn btn-primary external-btn" type="submit"
                    *ngIf="paymentSpeiSelected || paymentOxxoSelected || paymentTransfer ">
                    {{ paymentSpeiSelected ? 'Continuar' : 'Generar' }}
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step>
              <ng-template matStepLabel>Resumen</ng-template>
              <div *ngIf="paymentSpeiSelected">
                <form (ngSubmit)="onSubmitSubscriptionResumenForm()">
                  <span class="card-errors"></span>
                  <div mat-dialog-content>
                    <div class="mb-4">
                      <label class="mb-2">
                        Resumen de compra
                      </label>
                      <div class="row">
                        <div class="col-sm-6">
                          <address>
                            <strong>Nombre del tarjetahabiente:</strong> {{f_balanceMethodPaymentForm.fullName.value}}
                          </address>
                        </div>
                        <div class="col-sm-6">
                          <address>
                            <strong>Método de pago:</strong> {{paymentTypeSelected}} ****{{numberLast}}
                          </address>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table-nowrap table">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Plan</th>
                              <th>Precio plan</th>
                              <th>Descuento</th>
                              <th>Monto neto</th>
                              <th>IVA</th>
                              <th class="text-end">Monto a pagar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>{{f_balanceMethodPaymentForm.detail.value}}
                              </td>
                              <td class="text-end">{{calculatePrice.amountPay | currency}}</td>
                              <td class="text-end">{{calculatePrice.amountDiscount | currency}}</td>
                              <td class="text-end">{{calculatePrice.netAmountPay | currency}}</td>
                              <td class="text-end">{{calculatePrice.amountTax | currency}}</td>
                              <td class="text-end">{{calculatePrice.amountTotal | currency}}</td>
                            </tr>
                            <tr>
                              <td colspan="6" class="border-0 text-end"><strong>Total</strong>
                              </td>
                              <td class="border-0 text-end">{{calculatePrice.amountTotal | currency}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="d-flex flex-wrap gap-2 justify-content-end">
                      <button class="btn btn-outline-primary external-btn" matStepperPrevious>
                        Atrás
                      </button>
                      <button class="btn btn-primary external-btn" type="submit">
                        Pagar
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div *ngIf="paymentOxxoSelected || paymentTransfer">
                <div mat-dialog-content>
                  <div id="contentPaymentOxxo" [innerHtml]="html">
                  </div>
                  <div class="d-flex flex-wrap gap-2 justify-content-end">
                    <button class="btn btn-outline-primary external-btn" routerLink="/mi-cuenta">
                      Cerrar
                    </button>
                    <button class="btn btn-outline-primary external-btn" (click)="print()">
                      Imprimir
                    </button>
                  </div>
                </div>
              </div>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
      </div>
    </div>

    <div class="col-lg-4 mt-lg-5 mt-3">
      <div class="card detail_cart">
        <div class="card-body">
          <h5 class="fw-bold mb-2 primary">Resumen de compra</h5>
          <hr>

          <div class="d-flex justify-content-between">
            <h5 class="text-muted">Subtotal</h5>
            <h5 class="mb-0 text-muted">{{calculatePrice != null ? (calculatePrice.netAmountPay | currency) : '$0.00'}}
              <span class="small_text">MXN</span>
            </h5>
          </div>
          <div class="d-flex justify-content-between">
            <h5 class="text-muted">IVA</h5>
            <h5 class="mb-0 text-muted">{{calculatePrice != null ? (calculatePrice.amountTax | currency) : '$0.00'}}
              <span class="small_text">MXN</span>
            </h5>
          </div>
          <hr>

          <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">Monto a pagar</h5>
            <h5 class="mb-0 fw-bold">{{calculatePrice != null ? (calculatePrice.amountTotal | currency) : '$0.00'}}
              <span class="small_text">MXN</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>