import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationDTO } from 'src/app/models/notification/notification.dto.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  rowSelected:NotificationDTO = <NotificationDTO>{};
  indexSelected = null;

  constructor(private notificationService: NotificationService, private authService: AuthenticationService) { }

  isLender = this.authService.currentUserModeLenderValue;
  @Input() category = "";
  notifications: NotificationDTO[];
  notificationsDataSource: MatTableDataSource<NotificationDTO>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  notificationsColumns: string[] = [
    'iconReaded',
    'dateCreated',
    'subject',
    'from',  
    'bobyMessage',   
  ];

  ngOnInit(): void {
    this.getNotificationsReceived();
  }

  getNotificationsReceived() {
    
    this.notificationService.getNotificationsReceived(this.category)
      .subscribe((data) => {
        this.notifications = data;
        this.notificationsDataSource = new MatTableDataSource(data);
        this.notificationsDataSource.paginator = this.paginator;
        this.notificationsDataSource.sort = this.sort;
      });
  }

  readNotification(notificationId: string, openLink: boolean = false, link: string = ''): void {
    this.notificationService.readNotification(notificationId)
      .subscribe(() => {
        this.getNotificationsReceived();
        this.notificationService.setUnRead();
        if(openLink){
          window.location.href = link;
        }
      });
  }

  openLink(notificationId: string, link: string): void {
    this.readNotification(notificationId, true, link);   
  }

  onRowClicked(row, index) {
    this.rowSelected = row;
    this.indexSelected = index;
  }
}
