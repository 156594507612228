import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { GlobalLists } from 'src/app/global/global-data';
import { LoanPaymentDTO } from 'src/app/models/loanpayment/loanpayment.dto.model';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoanpaymentService } from 'src/app/services/loanpayment/loanpayment.service';
import { DialogConfirmComponent } from '../../components/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-loanpayment-list',
  templateUrl: './loanpayment-list.component.html',
  styleUrls: ['./loanpayment-list.component.scss']
})
export class LoanpaymentListComponent implements OnInit {

	@Input() isLender: boolean;
	@Input() loanId: string;
	@Input() buttonsAction: boolean = true;

	loanPaymentColumns: string[] = [
		'datePayment',
		'lenderLoanCode',
		'borrowerLoanCode',
		'paymentAmount',
		'wayToPay',
		// 'lenderName',
		'borrowerName',
		'loanAmount',
		'totalPayment',
		'currentBalance',
		'balanceOnArrears',
		'buttonDeletePayment'
	];

	loanPaymentDataSource: MatTableDataSource<LoanPaymentDTO>;
	wayToPayList = GlobalLists.wayToPayList;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(private loanpaymentService: LoanpaymentService,
		private alertGlobalService: AlertGlobalService,
		private dialog: MatDialog,
		private authService: AuthenticationService,
		private currencyPipe: CurrencyPipe) { }

	ngOnInit(): void {
		this.getLoanPayments();

		if (!this.buttonsAction) {
			this.loanPaymentColumns.pop();
		}
	}

	getLoanPayments(): void {
		if (this.loanId) {
			this.loanpaymentService.getLoanPaymentsWithLoanId(this.isLender, this.authService.currentUserValue.customerId, this.loanId)
			.subscribe((data) => {
			this.loanPaymentDataSource = new MatTableDataSource(data);
			if (data) {
				this.loanPaymentDataSource.paginator = this.paginator;
				this.loanPaymentDataSource.sort = this.sort;
			}
			});
		} else {
			this.loanpaymentService.getLoanPayments(this.isLender, this.authService.currentUserValue.customerId)
			.subscribe((data) => {
			this.loanPaymentDataSource = new MatTableDataSource(data);
			if (data) {
				this.loanPaymentDataSource.paginator = this.paginator;
				this.loanPaymentDataSource.sort = this.sort;
			}
			});
		}
	}

	onDeletePayment(loanPaymentId: string, amount: number) {
		this.dialog
			.open(DialogConfirmComponent, {
			data: {
				message: '¿Desea eliminar el pago de $' + amount + '?'
			}
			})
			.afterClosed()
			.subscribe((confirmado: Boolean) => {
			if (confirmado) {
				this.loanpaymentService.deleteLoanPayment(loanPaymentId)
				.subscribe(() => {
				this.alertGlobalService.showAlertSuccess("Pago eliminado con éxito.");
				this.getLoanPayments();
				});
			}
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.loanPaymentDataSource.filter = filterValue.trim().toLowerCase();
	}

	exportExcel() {
		var dataToExport = [];
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("Employee Data");
		let header=["Fecha de pago", "ID préstamo", "Monto del pago", "Forma de pago", "Nombre del Prestamista", "Monto del préstamo", "Monto total a pagar", "Saldo total a la fecha", "Saldo con atraso a la fecha"]
		let headerRow = worksheet.addRow(header);

		for ( let i of this.loanPaymentDataSource.data) {
			dataToExport.push({
				datePayment: i.datePayment,
				contact: this.isLender ? i.lenderLoanCode : i.borrowerLoanCode,
				paymentAmount: this.currencyPipe.transform(i.paymentAmount),
				wayToPay: i.wayToPay,
				lenderEmail: this.isLender ? i.lenderName : i.borrowerName,
				loanAmount: this.currencyPipe.transform(i.loanAmount),
				totalPayment: this.currencyPipe.transform(i.totalPayment),
				currentBalance: this.currencyPipe.transform(i.balance),
				balanceOnArrears: this.currencyPipe.transform(i.balanceExpired)
			})
		}

		for (let x1 of dataToExport) {
			let x2 = Object.keys(x1);
			let temp=[]

			for(let y of x2) {
			temp.push(x1[y])
			}

			worksheet.addRow(temp)
		}

		let fname="alender reporte cobranza "

		//add data and file name and download
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, fname+'- '+`${new Date().toISOString()}.xlsx`);
		});
	}

	public getWayToPay(wayToPay: number) {
		return this.wayToPayList.find(x => x.key == wayToPay).value;
	}

}
