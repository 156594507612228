<div class="d-flex justify-content-end mb-4">
    <div class="form-group col-md-4 col-12">
        <label>Buscar</label>
        <div>
            <i class="bx bx-search input_icon"></i>
            <input class="form-control btn-rounded" #filterText placeholder="" type="text" />
        </div>
    </div>
</div>

<div class="table-responsive">
    <div class="react-bootstrap-table">
        <table *ngIf="subscriptionPaymentDataSource" mat-table [dataSource]="subscriptionPaymentDataSource" [ngClass]="isLender ? 'bg-primary' : 'bg-secondary'">
            <ng-container matColumnDef="subscripcionStartDate">
                <th mat-header-cell *matHeaderCellDef> Fecha inicio </th>
                <td mat-cell *matCellDef="let row" class="text-right"> {{row.subscripcionStartDate | date:'mediumDate'}} </td>
            </ng-container>

            <ng-container matColumnDef="period">
                <th mat-header-cell *matHeaderCellDef> Periodicidad </th>
                <td mat-cell *matCellDef="let row"> {{getPeriod(row.idPeriod)}} </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef> No. períodos </th>
                <td mat-cell *matCellDef="let row"> {{row.periodQuantity}} </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef> Monto pagado </th>
                <td mat-cell *matCellDef="let row" class="text-right"> {{row.amount | currency}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Plan </th>
                <td mat-cell *matCellDef="let row"> {{row.description}} </td>
            </ng-container>

            <ng-container matColumnDef="numberOperations">
                <th mat-header-cell *matHeaderCellDef> No. operaciones </th>
                <td mat-cell *matCellDef="let row"> {{row.numberOperations}} </td>
            </ng-container>

            <ng-container matColumnDef="subscripcionEndDate">
                <th mat-header-cell *matHeaderCellDef> Fecha fin </th>
                <td mat-cell *matCellDef="let row" class="text-right"> {{row.subscripcionEndDate |
                    date:'mediumDate'}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Estatus pago </th>
                <td mat-cell *matCellDef="let row">
                    <button *ngIf="row.status === 'paid'" class="unclickable" type="button" mat-icon-button color="accent">
                      <mat-icon>lens</mat-icon> Pagado
                    </button>
                    <button *ngIf="row.status == 'created' || row.status === 'pending_payment'"
                        class="unclickable" type="button" mat-icon-button color="primary">
                        <mat-icon>lens</mat-icon> Pendiente
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="statusPlan">
                <th mat-header-cell *matHeaderCellDef> Estatus plan </th>
                <td mat-cell *matCellDef="let row"> {{row.statusPlan}} </td>
            </ng-container>

            <ng-container matColumnDef="buttonDeletePayment">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let row">
                    <i class="bx bx-x" (click)="onDeletePayment(row.loanPaymentId, row.paymentAmount)"></i>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="subscriptionPaymentColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: subscriptionPaymentColumns; let i = index"
                (click)="onRowClicked(row, i)" [ngClass]="{'row-selected': indexSelected === i }"></tr>
        </table>
    </div>
</div>

<mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

<div *ngIf="subscriptionPaymentDataSource" class="d-flex flex-wrap gap-2 justify-content-end">
    <button class="btn btn-outline-primary external-btn" routerLink="/mi-cuenta">
        Regresar
    </button>
    <button *ngIf="rowSubscriptionPaymentSelected.status == 'paid'"
        class="btn btn-outline-primary external-btn" [disabled]="indexSelected === null"
        (click)="openLink(rowSubscriptionPaymentSelected)">
        Recibo
    </button>
    <button
        *ngIf="rowSubscriptionPaymentSelected.status == 'pending_payment' && rowSubscriptionPaymentSelected.methodPayment == 'OXXO Pay'"
        class="btn btn-outline-primary external-btn" [disabled]="indexSelected === null"
        (click)="openCardPayment(rowSubscriptionPaymentSelected.idOrderConekta)">
        Ficha
    </button>
    <button
        *ngIf="rowSubscriptionPaymentSelected.status == 'pending_payment' && rowSubscriptionPaymentSelected.methodPayment == 'OXXO Pay'"
        class="btn btn-outline-primary external-btn" [disabled]="indexSelected === null"
        (click)="deletePayment(rowSubscriptionPaymentSelected.id)">
        Eliminar
    </button>
    <button *ngIf="showNew" class="btn btn-outline-primary external-btn" (click)="openNew('nueva')">
        Nueva
    </button>
    <button *ngIf="showRenew" class="btn btn-outline-primary external-btn" (click)="openNew('renovar')">
        Renovar
    </button>
    <!--<button *ngIf="!showNew" class="btn btn-outline-primary external-btn"
        (click)="openNew('ampliar-plazo')">
        Ampliar plazo
    </button>-->
    <button *ngIf="!showNew" class="btn btn-primary external-btn" (click)="openNew('mejorar-plan')">
        Mejorar
    </button>
</div>
