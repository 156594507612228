<h1 mat-dialog-title>Comisiones</h1>
<div mat-dialog-content>
    <form [formGroup]="commissionsForm" (ngSubmit)="onSubmitCommission()">
        <div class="w-100">
            <div *ngIf="mode === 'new'" class="row">
                <div class="col-lg-3 col-md-6 mb-3">
                    <div class="form-group">
                        <label>Comisión</label>
                        <select class="form-control" formControlName="description"
                            [ngClass]="submitted && validateRequiredFormCommissions('description') ? 'is-invalid' : ''">
                            <option value="">Seleccione</option>
                            <option *ngFor="let item of filteredOptions | async" [value]="item">{{item}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <mat-slide-toggle class="specialSimulator" labelPosition="before" (change)="isPorcentCommission = !isPorcentCommission" color="accent">Es porcentaje
                    </mat-slide-toggle>
                </div>

                <div class="col-lg-3 col-md-6 marginCustom mb-3">
                    <div class="form-group">
                        <label>{{isPorcentCommission ? 'Porcentaje' : 'Monto'}}</label>
                        <input [textMask]="{mask: isPorcentCommission ? percentMask : currencyMask}" class="form-control text-right" type="text" placeholder="" formControlName="value" [ngClass]="submitted && validateRequiredFormCommissions('value') ? 'is-invalid' : ''">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 d-flex align-items-center mb-3">
                    <div>
                        <div class="form-group">
                            <label>Se paga </label>
                            <select #selectWhenToPay class="form-control" formControlName="whenToPay">
                                <option *ngFor="let item of whenToPayArray" value="{{item.key}}">{{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <button class="btn btn-outline-primary internal-btn">
                        Agregar
                    </button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <div class="react-bootstrap-table">
                <table *ngIf="dsCommissions" mat-table [dataSource]="dsCommissions">
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Descripción </th>
                        <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef>Valor</th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row.isPorcent">{{row.value | number:'1.2-2'}} %</span>
                            <span *ngIf="!row.isPorcent">{{row.value | currency}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="whenToPay">
                        <th mat-header-cell *matHeaderCellDef>Cuando se paga</th>
                        <td mat-cell *matCellDef="let row"> {{row.whenToPayDesc}} </td>
                    </ng-container>

                    <ng-container matColumnDef="buttonRemove">
                        <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                        <td mat-cell *matCellDef="let row; let i = index;">
                            <i class="bx bx-x" *ngIf="mode === 'new'" (click)="onDeleteCommission(i)"></i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="commissionsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: commissionsColumns;"></tr>
                </table>
            </div>
        </div>
    </form>
</div>

<div class="d-flex flex-wrap gap-2 mt-4 justify-content-end">
    <button class="btn btn-outline-primary external-btn" mat-dialog-close>
        Cerrar
    </button>
    <button class="btn btn-primary external-btn" *ngIf="mode === 'new'" [mat-dialog-close]="commissions">
        Guardar
    </button>
</div>
