<div class="container-fluid">
    <h4>Preguntas frecuentes</h4>
    <div class="card">
        <div class="card-body">
			<mat-tab-group>
				<mat-tab label="Generales">
					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<p class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Qué es a-lender?
									</p>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<p class="mb-0">Es una plataforma digital para administrar préstamos entre familiares, amigos y clientes: fácil, rápido y seguro.</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Para qué sirve a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Con a-lender puedes administrar todo el ciclo del préstamo como un profesional, teniendo orden y control de las solicitudes que recibes y de los préstamos que otorgas, mediante las siguientes funcionalidades:
							</p>
							<ul>
								<li>Cotizador: diseñar préstamos a la medida, definir las condiciones básicas y, en su caso, avanzadas. Descargar, guardar y enviar la cotización al Solicitante.
								<li>Solicitudes: ver y modificar condiciones, integrar un expediente digital con los datos y documentos del Solicitante que desees, evaluar y autorizar o rechazar la solicitud, generar contrato y/o pagaré si así lo deseas, y registrar la entrega del préstamo.
								<li>Préstamos: consultar el expediente digital y el estado de cuenta del préstamo, registrar pagos recibidos y definir el tipo de cobranza que desees en relación a envío automatizado de recordatorios y/o requerimientos de pago.
								<li>Reportes: consultar y descargar reportes de la situación del préstamo o de todo tu portafolio, de lo que has prestado y cobrado.
							</ul>
							<p class="mb-0">
								Próximamente podrás contratar servicios adicionales de validaciones y verificaciones de datos, consultas de historial crediticio y análisis de crédito, así como de cobranza telefónica, domiciliaria y judicial, entre otros.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Quién puede usar a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Cualquier persona física o moral que preste dinero (Prestamista) ocasional o regularmente a:
							</p>
							<ul>
								<li>Familiares
								<li>Amigos
								<li>Conocidos
								<li>Clientes
								<li>Empleados
							</ul>
							<p>
								Generalmente son préstamos informales, pero si eres un prestamista formal también puedes utilizarla.
							</p>
							<p class="mb-0">
								Además, lo pueden utilizar las personas que solicitan o deben dinero (Solicitante) a un Prestamista que use a-lender, principalmente para dar seguimiento y consulta de estados de cuenta.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo funciona a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								a-lender es un software al que puedes ingresar desde cualquier lugar donde tengas conexión a internet, en tu computadora, laptop, celular o tablet. Para ingresar debes registras tu usuario y contraseña por primera vez, y después usarlos para entrar en cualquier momento.
							</p>
							<p>
								Si eres Prestamista (prestas dinero) puedes iniciar tu prueba gratis por 30 días o adquirir una suscripción de acuerdo a tus necesidades. Si eres Solicitante (solicitas o debes dinero) de un Prestamista que tenga a-lender, no es necesario adquirir una suscripción.
							</p>
							<p>
								El Prestamista es el administrador de la plataforma, por lo que puede diseñar préstamos, integrar expedientes, analizar solicitudes para autorizarlas o rechazarlas, registrar pagos recibidos, consultar estados de cuenta y generar reportes. En general puede insertar, modificar, ver, descargar, enviar e imprimir todos los datos que requiera.
							</p>
							<p class="mb-0">
								El Solicitante es un invitado a la plataforma, por lo que puede simular una solicitud, pero sólo podrá cargar los datos que el Prestamista le solicite. En general puede consultar el estatus de su solicitud o préstamo y su estado de cuenta.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo se usa el cotizador de préstamos de a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Es una calculadora que sirve para diseñar y simular préstamos tan sencillos o sofisticados como desees. Sólo debes escribir a seleccionar los siguientes datos:
							</p>
							<ul>
								<li>Opciones básicas (datos mínimo obligatorios): monto, frecuencia y número de pagos, tasa de interés y fecha de inicio.
								<li>Opciones avanzadas (datos opcionales): tipo de amortización del préstamo, forma de cálculo de intereses, moratorios, período de gracia, comisiones, IVA, ISR, garantías y/o pagos personalizados.
							</ul>
							<p>
								De acuerdo a esta información se generan un resumen y tabla de pagos (tabla de amortización) que muestran las condiciones principales y el monto total a pagar, así como el desglose por cada período de cada concepto y el saldo correspondiente. En cualquier momento puedes modificar datos o hacer una nueva cotización, o si lo deseas imprimir y/o descargar un archivo en formato PDF y en Excel.
							</p>
							<p>
								Finalmente, puedes guardar la cotización como una solicitud de préstamo, sólo es necesario llenar ciertos datos del Solicitante y seleccionar si deseas que se le envíe por correo electrónico el resumen y tabla de pagos.
							</p>
							<p>
								Para diseñar y simular préstamos entra a Menú: <u><a class="text-link" href="/simulador">Cotizador.</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo me registro en a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Es muy fácil, sólo sigue los siguientes pasos:
							</p>
							<ul>
								<li>Entra a <a href="/">www.a-lender.com</a>
								<li>Da click en ‘Regístrate’ e introduce tu dirección de correo electrónico y crea una contraseña (para mayor seguridad la contraseña debe tener una longitud mínima de 8 caracteres, ser alfanumérica y contener al menos una letra, un número y un signo).
								<li>Te enviaremos un correo electrónico de validación con un código.
								<li>Ingresa el código recibido en la plataforma para validar tu usuario.
								<li>Después te llegará un correo de confirmación y bienvenida.
								<li>¡Listo! Ya puedes entrar a la plataforma utilizando tu usuario y contraseña.
							</ul>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Por qué es importante completar ‘Mi Perfil’?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								En esta sección se pueden ingresar datos de identificación de usuario (persona o empresa), dirección y medios de contacto. Es importante tener tu registro completo y actualizado ya que esta información es requerida para:
							</p>
							<ul>
								<li>Enviar notificaciones y avisos referente a la actividad de tus préstamos, así como de ayuda y soporte técnico.
								<li>Generar los pagarés y contratos legalmente válidos para los casos de préstamos que así elijas.
								<li>Generar las facturas electrónicas de tus suscripciones en a-lender.
							</ul>
							<p>
								Para completar y/o editar esta información entra a Menú: Perfil/ Sección: <u><a class="text-link" href="/mi-perfil">Mi Perfil.</a></u>
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cuánto cuesta utilizar a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								a-lender es un sistema en la nube (SaaS) por lo que puedes contratar una suscripción por el tiempo que desees (on-demand), sin licencias ni plazos forzosos.
							</p>
							<p>
								Contamos con diferentes planes y precios para diferentes perfiles de Prestamistas según el volumen de operaciones, es decir el número de préstamos activos que tengas en determinado momento. Además, puedes elegir el plazo que gustes con pagos mensuales o anuales.
							</p>
							<p>
								Todos los planes incluyen acceso total a las funcionalidades propias de la plataforma (no incluyen servicios adicionales opcionales). Nosotros nos encargamos del mantenimiento y actualizaciones de la plataforma, además de almacenar y resguardar tu información de forma segura en la nube.
							</p>
							<p>
								Ofrecemos un período de Prueba Gratis por 30 días sin costo y sin compromiso, para que comiences a utilizar la plataforma y conozcas sus funciones y beneficios.
							</p>
							<p>
								Tenemos planes Básico, Estándar, Premium, Profesional, o bien planes Personalizados para mayor cantidad de operaciones.
							</p>
							<p>
								Para conocer los detalles de cada uno entra a: <u><a class="text-link" href="/suscripciones">‘Planes y Precios’</a></u>*.
							</p>
							<p class="mb-0">
								<i>*Precios expresados en pesos mexicanos. No incluyen IVA (Impuesto al Valor Agregado).</i>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo pagar mi suscripción?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								El pago se puede realizar con cualquier tarjeta de crédito o débito (Visa, MasterCard, Carnet, American Express), o bien en efectivo, generando una referencia de pago para depositar en cualquier sucursal de tiendas OXXO, 7-eleven o Farmacias del Ahorro.
							</p>
							<p>
								La transacción de tu pago se realiza de forma segura ya que utilizamos el servicio de una pasarela de pagos (<u>Conekta</u>), que es un agregador autorizado, supervisado y regulado por la Comisión Nacional Bancaria y de Valores (CNBV) y Banco de México (BANXICO), que cumple con todos los procedimientos necesarios para validar de conformidad con PCI DSS en relación a las políticas, procedimientos y sistemas técnicos que almacenan, procesan y/o transmiten datos de tarjetahabientes.
							</p>
							<p>
								Para adquirir y pagar una suscripción entra a Menú: Perfil/ Sección: <u><a class="text-link" href="/suscripciones">Suscripciones</a></u>.
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo recibo mi factura por el pago de suscripción?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Dentro de la plataforma podrás consultar los pagos realizados y fácilmente descargar, guardar y enviar por correo electrónico la factura electrónica (CFDI) correspondiente en formato XML y en PDF.
							</p>
							<p>
								La emisión de los CFDI debidamente autorizados por el SAT, los realizamos utilizando un portal de timbrado de facturas en México (<u>Facturama</u>), proveedores que cumplen con las avanzadas regulaciones de facturación electrónica y contabilidad de México.
							</p>
							<p>
								Para descarga de facturas electrónicas entra a Menú: Perfil/ Sección: <u><a class="text-link" href="/mi-cuenta">Facturación</a></u>.
							</p>
							<p class="mb-0">
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Qué hago si tengo dudas o comentarios?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Dentro de la plataforma puedes consultar en cualquier momento una guía de todas las funcionalidades de a-lender, la cual te explica cómo usar todos los menús y secciones.
							</p>
							<p>
								Para consultar una Guía detallada entra a Menú: Perfil/ Sección: <u><a class="text-link" (click)="openDialogTutorial()">Tutoriales.</a></u>
							</p>
							<p>
								Si no encuentras la respuesta a tu duda o bien quieres ponerte en contacto directo con nosotros, tenemos diferentes medios de contacto para tu facilidad:
							</p>
							<ul>
								<li>Chat en línea en la plataforma
								<li>Mensaje directo desde la plataforma: Sección: ¿Necesitas más información?
								<li>Correo electrónico: hola@a-lender.com
								<li>Redes sociales: @a_lendermx
								<li>WhatsApp: +52 81 2897 0797
							</ul>
							<p class="mb-0">
								¡Estamos para apoyarte!
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo consultar los Términos y Condiciones de Uso de a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Recuerda que para registrarte y utilizar la plataforma, debes aceptar los términos y condiciones de uso, por lo que te invitamos a leerlos antes de dar tu consentimiento, para asegurarte que conoces y estás de acuerdo con el contenido y alcance de los mismo.
							</p>
							<p class="mb-0">
								Aquí puedes consultar los <u><a class="text-link" href="/terminos-y-condiciones">Términos y Condiciones de Uso</a></u> de la plataforma.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo consultar el Aviso de Privacidad de a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Tu información es muy importante y te pertenece. Nosotros cumplimos con la regulación de datos personales y cuidamos de ellos. Recuerda que para registrarte y utilizar la plataforma, debes aceptar el aviso de privacidad, por lo que te invitamos a leerlo antes de dar tu consentimiento, para asegurarte que conoces y estás de acuerdo con el contenido y alcance del mismo.
							</p>
							<p class="mb-0">
								Aquí puedes consultar el <u><a class="text-link" href="/aviso-de-privacidad">Aviso de Privacidad</a></u> de la plataforma.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo consultar la Política de Uso de Cookies de a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Para conocer a detalle el uso de cookies de la plataforma, te invitamos a leer nuestra política de usos de cookies, para asegurarte que conoces y estás de acuerdo con el contenido y alcance de la misma.
							</p>
							<p class="mb-0">
								Aquí puedes consultar la <u><a class="text-link" href="/politica-de-cookies">Política de Uso Cookies</a></u> de la plataforma.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo puedo consultar el Aviso Legal de a-lender?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Para conocer a detalle los términos y condiciones de navegación en la plataforma, te invitamos a leer nuestro aviso legal, para asegurarte que conoces y estás de acuerdo con el contenido y alcance del mismo.
							</p>
							<p class="mb-0">
								Aquí puedes consultar el <u><a class="text-link" href="/aviso-legal">Aviso Legal</a></u> de la plataforma.
							</p>
						</mat-expansion-panel>
					</mat-accordion>
				</mat-tab>
				<mat-tab label="Soy Prestamista">
					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<div>
										<h5 class="text-dark custom-tittle">
											<span class="material-icons mr-2 text-success">
												help_outline
											</span>
											¿Los préstamos que hago son a mis contactos o en a-lender puedo encontrar Solicitantes de préstamos?
										</h5>
									</div>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<p class="mb-0">
								a-lender es una plataforma de administración de préstamos. Una vez que diseñas una cotización o producto de crédito puedes ofrecerle el préstamo a tus contactos o a quien desees. Si lo indicas, a-lender les hará llegar un correo electrónico en tu nombre, con las condiciones y tabla de pagos.
								Si te interesa, próximamente podremos a tu disposición un directorio de Solicitantes que estén en busca de un préstamo, para que evalúes si te interesa que sean tus clientes.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Puedo registrar en la plataforma un directorio de Solicitantes?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Sí, puedes cargar de forma masiva la base de datos de tus Solicitantes, ya sea tu directorio de contactos, lista de clientes, registro de empleados, etc. Se requiere descargar de la plataforma un archivo de Excel (layout) que contiene los datos necesarios para identificar a los Solicitantes, así como sus medios de contacto. Una vez que lo completes podrás:
							</p>
							<ul>
								a)	Cargar directamente el archivo a la plataforma. <br>

								b)	Enviarlo por correo electrónico a: hola@a-lener.com para que lo validemos y subamos por ti.

							</ul>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										Si ya tengo préstamos activos, ¿puedo registrarlos en la plataforma?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Sí, puedes cargar de forma masiva los préstamos activos que tengas actualmente. Se requiere descargar de la plataforma un archivo de Excel (layout) que contiene los datos necesarios para generar las tablas de pago, registrar los pagos y conciliar los saldos. Una vez que lo completes podrás:
							</p>
							<ul>
								a)	Cargar directamente el archivo a la plataforma.<br>

								b)	Enviarlo por correo electrónico a: hola@a-lener.com para que lo validemos y subamos por ti.

							</ul>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Quién entrega el préstamo al Solicitante?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p class="mb-0">
								El préstamo es entregado directamente por el Prestamista al Solicitante, ya sea en efectivo, transferencia, cheque, etc. En la plataforma se registra el desembolso para fines de considerarlo como entregado, es decir, un préstamo activo. El dinero no pasa a través de la plataforma en ningún momento.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo recibo los pagos de mis préstamos?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p class="mb-0">
								Los pagos de los préstamos son entregados directamente por el Solicitante al Prestamista, ya sea en efectivo, transferencia, cheque, etc. En la plataforma se registra cada pago para fines de considerarlo como recibido y actualizar el estado de cuenta correspondiente. El dinero no pasa a través de la plataforma en ningún momento.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Puedo registrar en la plataforma varios pagos de diferentes préstamos al mismo tiempo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p>
								Sí, puedes cargar de forma masiva los pagos de los préstamos que tengas. Se requiere descargar de la plataforma un archivo de Excel (layout) que contiene los datos necesarios para identificar los préstamos, así como la fecha, monto y forma de pago. Una vez que lo completes podrás:
							</p>
							<ul>
								a)	Cargar directamente el archivo a la plataforma.<br>

								b)	Enviarlo por correo electrónico a: hola@a-lener.com para que lo validemos y subamos por ti.

							</ul>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Qué hago si no me pagan?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p class="mb-0">
								Entre las funcionalidades de a-lender se encuentra apoyar y facilitar el repago de los préstamos a través de envío automatizado de recordatorios y requerimientos de pago al Solicitante a nombre del Prestamista, para aumentar la probabilidad de recuperación, a través de una matriz de cobranza, donde puedes elegir la periodicidad e intensidad de los mensajes de requerimientos de pago al Solicitante. Asimismo, tienes la opción de contratar servicios adicionales de apoyo de cobranza telefónica, domiciliaria y hasta judicial en los casos que sea factible.
							</p>
						</mat-expansion-panel>

					</mat-accordion>
				</mat-tab>
				<mat-tab label="Soy Solicitante">
					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<div>
										<h5 class="text-dark custom-tittle">
											<span class="material-icons mr-2 text-success">
												help_outline
											</span>
											¿a-lender me puede prestar dinero directamente?
										</h5>
									</div>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<p class="mb-0">
								No, a-lender es un sistema de administración de préstamos. La plataforma no ofrece préstamos directamente o indirectamente a los Solicitantes. Los préstamos provienen de tus familiares, amigos o conocidos directamente.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Los préstamos que solicito son a mis contactos o en a-lender puedo encontrar Prestamistas?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p class="mb-0">
								a-lender es un sistema de administración de préstamos. Una vez que diseñas una cotización o solicitud de crédito puedes solicitarle el préstamo a tus contactos o a quien desees. Si lo indicas, a-lender les hará llegar un correo electrónico en tu nombre, con las condiciones y tabla de pagos. Si te interesa, próximamente podremos a tu disposición un directorio de Prestamistas que desean ofrecer un préstamo.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Quién me entrega el préstamo?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p class="mb-0">
								El préstamo es entregado directamente por el Prestamista al Solicitante, ya sea en efectivo, transferencia, cheque, etc. En la plataforma el Prestamista registra el desembolso para fines de considerarlo como entregado, es decir, un préstamo activo. El dinero no pasa a través de la plataforma en ningún momento.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Cómo hago los pagos de mis préstamos?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p class="mb-0">
								Los pagos de los préstamos son entregados directamente por el Solicitante al Prestamista, ya sea en efectivo, transferencia, cheque, etc. En la plataforma se registra cada pago para fines de considerarlo como recibido y actualizar el estado de cuenta correspondiente. El dinero no pasa a través de la plataforma en ningún momento.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Qué comprobantes recibo cuando pago?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p class="mb-0">
								Cuando realices un pago de tu préstamo, el Prestamista debe registrar en la plataforma el monto recibido, la fecha y la forma de pago, es decir si lo recibe en efectivo, transferencia, cheque, etc. Con estos datos en la plataforma se genera un Comprobante de pago que puedes imprimir, descargar y recibir por correo electrónico.
							</p>
						</mat-expansion-panel>

						<mat-expansion-panel (opened)="panelOpenState = true"
											(closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5 class="text-dark custom-tittle">
										<span class="material-icons mr-2 text-success">
											help_outline
										</span>
										¿Qué pasa si no pago?
									</h5>
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<p class="mb-0">
								Si no pagas perjudicas a quien te prestó Prestamista, puede perder la confianza en tu palabra y dañas tu imagen e historial crediticio, haciendo muy difícil que te vuelvan a prestar en un futuro. Además, a-lender te enviará constantemente mensajes de requerimientos de pago hasta que sea liquidado el saldo pendiente. Si además firmaste documentación legal, el Prestamista puede ejercer su derecho a establecer acciones legales.
							</p>
						</mat-expansion-panel>
					</mat-accordion>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
</div>
