<div class="container">
	<div class="row justify-content-center">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="card overflow-hidden">
				<div class="bg-primary bg-soft-primary">
					<div class="d-flex align-items-center">
						<div class="col-8 col-sm-9 col-md-9">
							<div class="text-white p-4">
								<h5 class="text-white">Actualización de contraseña</h5>
								<p class="mb-0">Ingresa tu contraseña nueva</p>
							</div>
						</div>
						<div class="col-4 col-sm-3 col-md-3">
							<div class="avatar-md">
								<span class="avatar-title rounded-circle bg-light">
									<a href="/">
										<img src="assets/images/icons/c_wing.svg" height="34" alt="a-lender | Plataforma de Administración de Préstamos" loading="lazy" />
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<form class="p-2" [formGroup]="form" (ngSubmit)="onSubmit()">
						<div class="form-horizontal">
							<div class="mb-3">
								<div class="form-group">
									<label>Ingresa tu contraseña nueva</label>
									<div>
										<i [ngClass]="getClass()" aria-hidden="true" class="input_icon" (click)="hide = !hide"></i>
                                        <input
                                            autocapitalize="off"
                                            autofocus="autofocus"
                                            class="form-control"
                                            formControlName="password"
                                            placeholder="Escribe tu contraseña"
                                            [type]="hide ? 'password' : 'text'"
                                            value=""
                                        />
                                    </div>
                                    <mat-error *ngIf="validateRequiredForm('password')">{{requiredErrorMessage}}</mat-error>
								</div>
							</div>

							<div class="mb-3">
								<div class="form-group">
									<label>Ingresa tu contraseña nuevamente</label>
									<div>
										<i [ngClass]="getClassC()" aria-hidden="true" class="input_icon" (click)="hide = !hide"></i>
                                        <input
                                            autocapitalize="off"
                                            autofocus="autofocus"
                                            class="form-control"
                                            formControlName="confirmPassword"
                                            name="confirmPassword"
                                            placeholder="Escribe tu contraseña nuevamente"
                                            [type]="hide ? 'password' : 'text'"
                                            value=""
                                        />
                                    </div>
                                    <mat-error *ngIf="form.hasError('notSame')">La contraseña no coincide con la anterior.</mat-error>
								</div>
							</div>

							<div class="mt-3 text-center">
                <button
                  class="btn_primary dark"
                  type="submit"
                >
                  <a class="nav-link">Actualizar contraseña</a>
                </button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
