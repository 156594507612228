<div [ngClass]="topNav ? 'container-fluid' : ''">
  <h4>Cotizador</h4>
  <div class="card">
    <div class="card-body">
      <mat-horizontal-stepper class="simulator" linear #stepperSimulator labelPosition="bottom">
        <mat-step [stepControl]="paymentForm" [optional]="false">
          <ng-template matStepperIcon="edit">
            <i class="bx bx-check"></i>
          </ng-template>
          <ng-template matStepperIcon="active">
            <i class="bx bx-check"></i>
          </ng-template>
          <form [formGroup]="paymentForm" (ngSubmit)="onSubmitPaymentForm()">
            <ng-template matStepLabel>{{ step1Title }}</ng-template>
            <mat-accordion>
              <mat-expansion-panel [expanded]="panelBasicOptionsOpenState" (opened)="panelBasicOptionsOpen(true)"
                (closed)="panelBasicOptionsOpen(false)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h5>Opciones básicas</h5>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="subtittle">
                  Escribe o selecciona los datos requeridos para simular tu préstamo
                </div>
                <div class="mt-3">
                  <div class="row">
                    <div class="col-lg-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label for="loanAmount">
                          {{loanAmountTitle}} <i class="bx bx-info-circle" matTooltip="{{showHelp('loanAmount')}}"></i>
                        </label>
                        <input type="text" formControlName="loanAmount" keyboardType="numeric" placeholder="$ 0"
                          class="form-control" [textMask]="{mask: currencyMask}" autocapitalize="off"
                          autocomplete="loanAmount"
                          [ngClass]="paymentFormSubmitted && f_paymentForm.loanAmount.errors ? 'is-invalid' : ''">
                      </div>
                    </div>

                    <div class="col-lg-9 col-md-6 mb-4">
                      <div class="form-group">
                        <label>
                          {{typeOfPeriodTitle}} <i class="bx bx-info-circle" matTooltip="{{showHelp('typeOfPeriod')}}"></i>
                        </label>
                        <mat-chip-list aria-label="Periodo">
                          <mat-chip *ngFor="let item of typeOfPeriodList" color="primary"
                            [selected]="item.key === typeOfPeriodSelected"
                            (click)="onChangeTypeOfPeriod(item.key)">{{item.value}}
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                    </div>
                  </div>

                  <mat-divider></mat-divider>

                  <div class="row mt-3">
                    <div class="col-xl-3 col-lg-6 col-md-6 mb-4">
                      <div class="form-group">
                        <label>
                          {{totalPeriodsTitle}} <i class="bx bx-info-circle" matTooltip="{{showHelp('totalPeriods')}}"></i>
                        </label>
                        <input #totalPeriods class="form-control text-right" type="text" [textMask]="{mask: numberMask}"
                          formControlName="totalPeriods" inputmode="numeric"
                          (change)="onChangeTotalPeriods(totalPeriods.value)"
                          [ngClass]="f_paymentForm.totalPeriods.errors ? 'is-invalid' : ''">
                      </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-6 mb-4">
                      <div class="form-group">
                        <label>
                          Interés mensual <i class="bx bx-info-circle" matTooltip="{{showHelp('monthlyRate')}}"></i>
                        </label>
                        <input class="form-control text-right" type="text" placeholder="0 %"
                          [textMask]="{mask: percentMask}" formControlName="monthlyRate"
                          [ngClass]="f_paymentForm.monthlyRate.errors ? 'is-invalid' : ''">
                      </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-6 mb-4">
                      <div class="form-group">
                        <label>
                          {{startDateTitle}} <i class="bx bx-info-circle" matTooltip="{{showHelp('startDate')}}"></i>
                        </label>
                        <input autofocus="autofocus" class="form-control"
                          [ngClass]="f_paymentForm.startDate.errors ? 'is-invalid' : ''" formControlName="startDate"
                          type="date" (ngModelChange)="onChangeStartDate()" />
                      </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>
                          {{startPaymentTitle}} <i class="bx bx-info-circle" matTooltip="{{showHelp('firstPayment')}}"></i>
                        </label>
                        <input autofocus="autofocus" class="form-control" formControlName="startPayment" type="date" />
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
              <mat-expansion-panel [expanded]="panelAdvancedOptionsOpenState"
                (opened)="panelAdvancedOptionsOpenState = true;"
                (closed)="panelAdvancedOptionsOpenState = false; panelCommissionOpenState = false; panelCustomPaymentsOpenState = false;">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h5>Opciones avanzadas</h5>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="subtittle">Escribe o selecciona las opciones que desees</div>

                <div class="row">
                  <div class="col-xl-3 col-lg-6 col-md-6 mb-4">
                    <div class="form-group">
                      <label>
                        Tipo de préstamo <i class="bx bx-info-circle" matTooltip="{{showHelp('typeOfCredit')}}"></i>
                      </label>
                      <select #selectTypeOfCredit class="form-control" formControlName="typeOfCredit"
                        (change)="onChangeSelectTypeOfCredit(selectTypeOfCredit.value)">
                        <option value="">Seleccione</option>
                        <option *ngFor="let item of typeOfCreditList" value="{{item.key}}">
                          {{item.value}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xl-3 col-lg-6 col-md-6 mb-4">
                    <div class="form-group">
                      <label>
                        Cálculo de intereses <i class="bx bx-info-circle" matTooltip="{{showHelp('typeOfAmortization')}}"></i>
                      </label>
                      <select #select class="form-control" formControlName="typeOfAmortization">
                        <option value="">Seleccione</option>
                        <option *ngFor="let item of typeOfAmortizationList" value="{{item.key}}">{{item.value}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xl-3 col-lg-6 col-md-6 mb-4">
                    <div class="form-group">
                      <label>
                        Interés moratorio mensual <i class="bx bx-info-circle" matTooltip="{{showHelp('monthlyPorcentOnArrears')}}"></i>
                      </label>
                      <input class="form-control text-right" type="text" [textMask]="{mask: percentMask}"
                        formControlName="monthlyPorcentOnArrears"
                        [ngClass]="paymentFormSubmitted && f_paymentForm.monthlyPorcentOnArrears.errors ? 'is-invalid' : ''">
                    </div>
                  </div>

                  <div class="col-xl-3 col-lg-6 col-md-6 mb-4">
                    <div class="form-group">
                      <label>
                        Periodo inicial <i class="bx bx-info-circle" matTooltip="{{showHelp('periodAmortizeStart')}}"></i>
                      </label>
                      <select class="form-control" formControlName="periodAmortizeStart">
                        <option *ngFor="let item of periodAmortizeStartList" value="{{item.key}}">{{item.value}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <label>
                  Comisiones <i class="bx bx-info-circle" matTooltip="{{showHelp('commissions')}}"></i>
                </label>
                <mat-accordion>
                  <mat-expansion-panel [expanded]="panelCommissionOpenState" (opened)="panelCommissionOpenState = true"
                    (closed)="panelCommissionOpenState = false" class="bg-white">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <h5>Agregar comisiones</h5>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <form class="w-100" [formGroup]="commissionsForm" (ngSubmit)="onSubmitCommission()">
                      <div class="row">
                        <div class="col-lg-3 col-md-6 mb-3">
                          <div class="form-group">
                            <label>Comisión</label>
                            <select class="form-control" formControlName="description"
                              [ngClass]="commissionsFormSubmitted && f_Commissions.description.errors ? 'is-invalid' : ''">
                              <option value="">Seleccione</option>
                              <option *ngFor="let item of filteredOptions | async" value="{{item}}">{{item}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                          <mat-slide-toggle class="specialSimulator" labelPosition="before"
                            (change)="isPorcentCommission = !isPorcentCommission" color="accent">
                            <label>Es porcentaje</label>
                          </mat-slide-toggle>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-3">
                          <div class="form-group">
                            <label>{{isPorcentCommission ? 'Porcentaje' : 'Monto'}}</label>
                            <input class="form-control text-right" type="text"
                              [textMask]="{mask: isPorcentCommission ? percentMask : currencyMask}"
                              formControlName="value"
                              [ngClass]="commissionsFormSubmitted && f_Commissions.value.errors ? 'is-invalid' : ''">
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6 d-flex align-items-end mb-3">
                          <div class="form-group w-100">
                            <label>Se paga</label>
                            <select #selectWhenToPay class="form-control" formControlName="whenToPay"
                              [ngClass]="commissionsFormSubmitted && f_Commissions.whenToPay.errors ? 'is-invalid' : ''">
                              <option *ngFor="let item of whenToPayArray" value="{{item.key}}">{{item.value}}</option>
                            </select>
                          </div>
                          <button class="btn btn-outline-primary internal-btn">
                            Agregar
                          </button>
                        </div>
                      </div>
                    </form>

                    <div class="table-responsive">
                      <div class="react-bootstrap-table">
                        <table *ngIf="dsCommissions && dsCommissions.data.length > 0" mat-table
                          [dataSource]="dsCommissions">
                          <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef>Comisión</th>
                            <td mat-cell *matCellDef="let row">
                              {{row.description}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="value">
                            <th mat-header-cell *matHeaderCellDef>Valor</th>
                            <td mat-cell *matCellDef="let row">
                              <span *ngIf="row.isPorcent">{{row.value | number:'1.2-2'}} %</span>
                              <span *ngIf="!row.isPorcent">{{row.value | currency}}</span>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="whenToPay">
                            <th mat-header-cell *matHeaderCellDef>
                              Cuando se paga
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{row.whenToPayDesc}} </td>
                          </ng-container>
                          <ng-container matColumnDef="buttonRemove">
                            <th mat-header-cell *matHeaderCellDef> Eliminar
                            </th>
                            <td mat-cell *matCellDef="let row; let i = index;">
                              <i class="bx bx-x" (click)="onDeleteCommission(i)"></i>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="commissionsColumns">
                          </tr>
                          <tr mat-row *matRowDef="let row; columns: commissionsColumns;">
                          </tr>
                        </table>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <label>
                  IVA <i class="bx bx-info-circle" matTooltip="{{showHelp('applyTax')}}"></i>
                </label>
                <mat-accordion>
                  <mat-expansion-panel [expanded]="panelTaxOpenState" (opened)="panelTaxOpen(true)"
                    (closed)="panelTaxOpen(false)" class="bg-white">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <h5>Agregar IVA</h5>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <form class="w-100 mb-4" [formGroup]="taxForm" (ngSubmit)="onSubmitTax()">
                      <div class="d-flex align-items-end">
                        <div class="col-xl-3 col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Tipo de IVA</label>
                            <select #select class="form-control" formControlName="description"
                              [ngClass]="taxFormSubmitted && f_Tax.description.errors ? 'is-invalid' : ''">
                              <option value="">Seleccione</option>
                              <option *ngFor="let item of taxList" value="{{item.value}}">{{item.value}}</option>
                            </select>
                          </div>
                        </div>
                        <button class="btn btn-outline-primary internal-btn">
                          Agregar
                        </button>
                      </div>
                    </form>

                    <div class="table-responsive">
                      <div class="react-bootstrap-table">
                        <table *ngIf="dsTax && dsTax.data.length > 0" mat-table [dataSource]="dsTax">
                          <ng-container matColumnDef="number">
                            <th mat-header-cell *matHeaderCellDef> Número </th>
                            <td mat-cell *matCellDef="let row"> {{row.number}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef> IVA </th>
                            <td mat-cell *matCellDef="let row">
                              {{row.description}} </td>
                          </ng-container>
                          <ng-container matColumnDef="buttonRemove">
                            <th mat-header-cell *matHeaderCellDef>Eliminar </th>
                            <td mat-cell *matCellDef="let row; let i = index;">
                              <i class="bx bx-x" (click)="onDeleteTax(i)"></i>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="taxColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: taxColumns;">
                          </tr>
                        </table>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <label>
                  Garantías <i class="bx bx-info-circle" matTooltip="{{showHelp('warranty')}}"></i>
                </label>
                <mat-accordion>
                  <mat-expansion-panel [expanded]="panelWarrantyOpenState" (opened)="panelWarrantyOpen(true)"
                    (closed)="panelWarrantyOpen(false)" class="bg-white">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <h5>Agregar garantía</h5>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                      <form class="d-flex align-items-end mb-4" [formGroup]="warrantiesForm"
                        (ngSubmit)="onSubmitWarranty()">
                        <div class="col-xl-3 col-lg-6 col-md-6">
                          <div class="form-group"> <label>Tipo de garantía </label>
                            <select #select class="form-control" formControlName="description"
                              [ngClass]="warrantiesFormSubmitted && f_Warranties.description.errors ? 'is-invalid' : ''">
                              <option value="">Seleccione</option>
                              <option *ngFor="let item of filteredOptionsWarranties | async" value="{{item}}">{{item}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <button class="btn btn-outline-primary internal-btn">
                          Agregar
                        </button>
                      </form>

                      <div class="table-responsive">
                        <div class="react-bootstrap-table">
                          <table *ngIf="dsWarranties && dsWarranties.data.length > 0" mat-table
                            [dataSource]="dsWarranties">
                            <ng-container matColumnDef="number">
                              <th mat-header-cell *matHeaderCellDef> Número </th>
                              <td mat-cell *matCellDef="let row"> {{row.number}}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="description">
                              <th mat-header-cell *matHeaderCellDef> Garantía
                              </th>
                              <td mat-cell *matCellDef="let row">
                                {{row.description}} </td>
                            </ng-container>
                            <ng-container matColumnDef="buttonRemove">
                              <th mat-header-cell *matHeaderCellDef>Eliminar </th>
                              <td mat-cell *matCellDef="let row; let i = index;">
                                <i class="bx bx-x" (click)="onDeleteWarranty(i)"></i>
                              </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="warrantiesColumns">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: warrantiesColumns;">
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <label>
                  Retención ISR <i class="bx bx-info-circle" matTooltip="{{showHelp('commissions')}}"></i>
                </label>
                <mat-accordion>
                  <mat-expansion-panel [expanded]="panelRetentionOpenState" (opened)="panelRetentionOpen(true)"
                    (closed)="panelRetentionOpen(false)" class="bg-white">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <h5>Agregar retención ISR</h5>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="col-xl-3 col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>
                          Retención <i class="bx bx-info-circle" matTooltip="{{showHelp('monthlyPorcentOnArrears')}}"></i>
                        </label>
                        <input class="form-control text-right" type="text" [textMask]="{mask: percentMask}"
                          formControlName="retention" placeholder="0 %"
                          [ngClass]="f_paymentForm.retention.errors ? 'is-invalid' : ''">
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <label>Personalizar pagos <span (click)="showHelp('payments')"
                    class="material-icons button-help">help</span></label>
                <mat-accordion>
                  <mat-expansion-panel [expanded]="panelCustomPaymentsOpenState"
                    (opened)="panelCustomPaymentsOpenState = true; applyCustomPayments(true);"
                    (closed)="panelCustomPaymentsOpenState = false; applyCustomPayments(false);" class="bg-white">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <h5>Personalizar pagos</h5>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-amortization-table #amortizationTableComponent>
                    </app-amortization-table>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-expansion-panel>
            </mat-accordion>

            <div class="d-flex flex-wrap gap-2 justify-content-end">
              <button *ngIf="topNav" class="btn btn-outline-primary external-btn" routerLink="/inicio">
                Cerrar
              </button>

              <button class="btn btn-primary external-btn">
                Cotizar
              </button>
            </div>
          </form>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Ver resumen</ng-template>
          <div class="card bg-gray">
            <div class="card-body">
              <div class="row">
                <h5 class="mb-4">Resumen de cotización</h5>
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Monto</p>
                    <h5 class="mb-0">{{totalAmortize | currency}}</h5>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Frecuencia de pagos</p>
                    <h5 class="mb-0">{{typeOfPeriodDesc}}</h5>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Número de pagos</p>
                    <h5 class="mb-0">{{f_paymentForm.totalPeriods.value}}</h5>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Tasa de interés mensual</p>
                    <h5 class="mb-0">{{f_paymentForm.monthlyRate.value}}</h5>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Fecha de inicio</p>
                    <h5 class="mb-0">{{f_paymentForm.startDate.value | date}}</h5>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Fecha fin</p>
                    <h5 class="mb-0">{{lastDatePayment | date}}</h5>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Monto interés total</p>
                    <h5 class="mb-0">{{totalInterest + totalInterestTax | currency}}</h5>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div>
                    <p class="text-muted mb-1">Monto comisiones total</p>
                    <h5 class="mb-0">{{totalCommission + totalCommissionTax | currency}}</h5>
                  </div>
                </div>
                <div class="col-12">
                  <div class="text-center">
                    <p class="text-muted mb-2">Total a pagar</p>
                    <h4 class="mb-0">{{totalPeriodPay | currency}}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <mat-accordion>
            <mat-expansion-panel [expanded]="panelPaymentTableOpenState" (opened)="panelPaymentTableOpenStateOpen(true)"
              (closed)="panelPaymentTableOpenStateOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Tabla de pagos</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <app-amortization-table id="htmlData" [hiddenPaymentsColumn]="true"
                [disabledPaymentsColumn]="true"></app-amortization-table>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="d-flex flex-wrap gap-2 justify-content-end">
            <button *ngIf="topNav" class="btn btn-outline-primary external-btn" routerLink="/inicio">Cerrar</button>
            <!--button *ngIf="topNav" class="btn btn-outline-primary external-btn" (click)="onReset()">Reiniciar</button-->
            <button class="btn btn-outline-primary external-btn" (click)="onModify()">Modificar</button>
            <button class="btn btn-outline-primary external-btn" (click)="print()">Imprimir</button>
            <button class="btn btn-outline-primary external-btn" (click)="exportPDF()">PDF</button>
            <button class="btn btn-outline-primary external-btn" (click)="exportExcel()">Excel</button>
            <button class="btn btn-primary external-btn"
              (click)="onSend('Tu solicitud esta lista para que la envíes a tu familiar, amigo o cliente')"
              matStepperNext>Continuar</button>
          </div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Registrar solicitud</ng-template>
          <mat-accordion>
            <mat-expansion-panel [expanded]="panelRegisterLoanOpenState" (opened)="panelRegisterLoanStateOpen(true)"
              (closed)="panelRegisterLoanStateOpen(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Solicitud de préstamo</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="subtittle d-flex justify-content-between">
                <span>Complete los datos siguientes del Solicitante para registrar la solicitud de préstamo</span>
                <span class="text-sm-start text-end">* Campo Obligatorio</span>
              </div>

              <form [formGroup]="sendQuoteForm" (ngSubmit)="onSubmitSendQuote()">
                <div class="w-100">
                  <div class="row">
                    <div class="col-xl-3 col-md-6 my-3">
                      <div class="form-group">
                        <label>Solicitante *</label> <select #selectNewExists class="form-control"
                          formControlName="newExists" (change)="onChangeNewExists(selectNewExists.value)"
                          [ngClass]="validateRequiredFormF1('newExists') && submitted ? 'is-invalid' : ''" required>
                          <option value="0">Nuevo</option>
                          <option value="1">Existente</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label>Tipo de Solicitante *</label>
                        <select #personType class="form-control" formControlName="personType"
                          (change)="onChange(personType.value)"
                          [ngClass]="validateRequiredFormF1('personType') && submitted ? 'is-invalid' : ''" required>
                          <option value="">Seleccione</option>
                          <option *ngFor="let item of personTypeList" value="{{item.key}}">
                            {{item.value}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-xl-9 col-md-6 mb-3">
                      <div *ngIf="personType.value === '1'" class="form-group">
                        <label for="busisnessName">Razón social *</label>
                        <input type="text" formControlName="busisnessName" value="" class="form-control"
                          [ngClass]="validateRequiredFormF1('busisnessName') && submitted ? 'is-invalid' : ''" required>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label for="contactName">Nombre {{person}}*</label>
                        <input type="text" formControlName="contactName" class="form-control"
                          [ngClass]="validateRequiredFormF1('contactName') && submitted ? 'is-invalid' : ''" required>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group"> <label for="middleName">Segundo
                          nombre</label> <input type="text" formControlName="middleName" class="form-control">
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group"> <label for="lastName1">Primer
                          apellido*</label> <input type="text" formControlName="lastName1" class="form-control"
                          [ngClass]="validateRequiredFormF1('lastName1') && submitted ? 'is-invalid' : ''" required>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group"> <label for="lastName2">Segundo
                          apellido</label> <input type="text" formControlName="lastName2" class="form-control"> </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group"> <label for="alias">{{alias}}</label>
                        <input type="text" formControlName="alias" class="form-control">
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label for="phoneMobile">Número celular*</label>
                        <input type="text" [textMask]="{mask: phoneMask}" formControlName="phoneMobile"
                          class="form-control text-right"
                          [ngClass]="validateRequiredFormF1('phoneMobile') && submitted ? 'is-invalid' : ''">
                        <mat-error *ngIf="f1.phoneMobile.errors && f1.phoneMobile.errors.minlength">
                          Minímo 10 caracteres.
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label for="contact">Correo electrónico*</label>
                        <input type="email" formControlName="contact" class="form-control"
                          [ngClass]="validateRequiredFormF1('contact') && submitted ? 'is-invalid' : ''" required>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group"> <label for="page">{{page}}</label>
                        <input type="email" formControlName="page" class="form-control"
                          [ngClass]="validateRequiredFormF1('page') && submitted ? 'is-invalid' : ''">
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label>{{relationship}}*</label>
                        <select #contactRelationship class="form-control" formControlName="contactRelationship"
                          (change)="changeContactRelationship(contactRelationship.value)"
                          [ngClass]="validateRequiredFormF1('contactRelationship') && submitted ? 'is-invalid' : ''"
                          required>
                          <option value="">Seleccione</option>
                          <option *ngFor="let item of getContactRelationshipList(personType.value)"
                            value="{{item.key}}"> {{item.value}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label for="occupation">{{ocupation}}*</label>
                        <mat-form-field appearance="fill">
                          <mat-label>Seleccione</mat-label>
                          <mat-select #selectOccupation
                            (selectionChange)="onchangeDataExpedient(personType.value, selectOccupation.value)"
                            [ngClass]="validateRequiredFormF1('occupation') && submitted ? 'is-invalid' : ''"
                            formControlName="occupation" multiple>
                            <mat-option *ngFor="let item of getOccupationList(personType.value)" [value]="item.key"
                              [disabled]="selectOccupationDisabled(personType.value, item.key)"
                              [disabled]="selectOccupationRelationDisabled(contactRelationship.value, item.key)">
                              {{item.value}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label>Destino*</label>
                        <select #creditDestination class="form-control" formControlName="creditDestination"
                          [ngClass]="validateRequiredFormF1('creditDestination') && submitted ? 'is-invalid' : ''"
                          required>
                          <option value="">Seleccione</option>
                          <option *ngFor="let item of getCreditDestinations(personType.value)" value="{{item.key}}">
                            {{item.value}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-3">
                      <div class="form-group">
                        <label>
                          Forma de préstamo* <i class="bx bx-info-circle" matTooltip="{{showHelp('legalConditions')}}"></i>
                        </label>
                        <select #selectLegalConditions class="form-control" formControlName="legalConditions"
                          [ngClass]="validateRequiredFormF1('legalConditions') && submitted ? 'is-invalid' : ''"
                          required>
                          <option value="">Seleccione</option>
                          <option *ngFor="let item of legalConditionsList" value="{{item.key}}">{{item.value}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-xl-12 mb-3">
                      <div class="form-group">
                        <label for="comments">Comentarios</label>
                        <input type="text" formControlName="comments" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap gap-2 justify-content-end">
                  <button *ngIf="topNav" class="btn btn-outline-primary external-btn" routerLink="/inicio">
                    Cerrar
                  </button>
                  <button class="btn btn-outline-primary external-btn" matStepperPrevious>
                    Regresar
                  </button>
                  <button class="btn btn-primary external-btn" type="submit">
                    Guardar
                  </button>
                </div>
              </form>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
