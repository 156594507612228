import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CustomerDTO } from 'src/app/models/customer/customer.dto.model';
import { Customer, CustomerBilling } from 'src/app/models/customer/customer.model';
import { UserAdministration} from 'src/app/models/user/user.model';
import { ApiService } from '../api/api.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private apiService: ApiService) { }  

  updateCustomerBilling(customerId: string, customer: CustomerBilling) {
    return this.apiService.put('customer/updateCustomerBilling', '', customerId, customer)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  
  updateCustomer(customerId: string, customer: Customer) {
    return this.apiService.put('customer', '', customerId, customer)
    .pipe(map(response => {
      return response.data as String;
    }));
  }

  getCustomer(customerId: string) {
    return this.apiService.get('customer', customerId)
    .pipe(map(response => {
      return response.data as CustomerDTO;
    }));
  }
  
  createCustomer(customer: Customer) {
    return this.apiService.post('customer', 'nouser', customer)
          .pipe(map(response => {
              return response.data as String;
          }));
  }
  
  getUserAdministration() {
    return this.apiService.get('customer', 'administration')
          .pipe(map(response => {
              return response.data as UserAdministration;
          }));
  }
  
}
