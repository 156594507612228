import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { checkPhoneVerification } from 'src/app/models/user/checkPhoneVerification';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AlertGlobalService } from 'src/app/services/alert-global/alert-global.service';
import { GlobalLists } from 'src/app/global/global-data';

export interface DialogData {
	phoneMobile: string;
	phoneMobileFormat: string;
	phoneNumberVerified: AbstractControl;
	submitted: boolean;
}

@Component({
	selector: 'app-dialog-verify-code-phone',
	templateUrl: './dialog-verify-code-phone.component.html',
	styleUrls: ['./dialog-verify-code-phone.component.scss']
})

export class DialogVerifyCodePhoneComponent implements OnInit {
	form: FormGroup;
	requiredErrorMessage: string = "Este campo es requerido.";
	phoneCodeMask = GlobalLists.phoneCodeMask;
	submitted = false;

	constructor(private formBuilder: FormBuilder,
				private dialogRef: MatDialogRef<DialogVerifyCodePhoneComponent>,
				@Inject(MAT_DIALOG_DATA) public data:DialogData,
				private notificationService: NotificationService,
				private alertGlobalService: AlertGlobalService) {
	this.createForm();
	}

	get f1() { return this.form.controls; }

	createForm() {
		this.form = this.formBuilder.group({
			code: ['', Validators.required]
		});
	}

	ngOnInit(): void {
		
	}

	onSubmit() {
		this.submitted = true;

		if(this.form.invalid) {
			return;
		}

		this.checkCode();
	}

	validateRequiredForm(formControlName: string): boolean {
		if(this.f1[formControlName].errors !== null && this.f1[formControlName].errors['required']) {
			return true;
		}
		return false;
	}

	checkCode() {
		let data = <checkPhoneVerification> {
			to: this.data.phoneMobile + "",
			code: this.f1['code'].value
		}

		this.notificationService.checkCodeVerification(data).subscribe(() => {
			this.alertGlobalService.showAlertSuccess("Código verificado con éxito.");
			this.dialogRef.close();
			this.data.phoneNumberVerified.patchValue(true)
			this.data.submitted = true
		});
	}
}
