<div class="card_services">
  <div>
    <div class="d-flex align-items-center mb-3">
      <i class="bx big primary" [ngClass]="externalServiceSelected.image"></i>
      <div class="ms-4">
        <h5 class="fw-bold">
          {{externalServiceSelected.serviceName}}
        </h5>
        <div *ngIf="externalServiceType == null">
          <p>Completa o verifica que los datos requeridos para realizar {{externalServiceSelected.serviceName}} sean
            correctos</p>
        </div>
      </div>
    </div>

    <form class="form-horizontal" [formGroup]="externalServiceForm" (ngSubmit)="onSubmit()">
      <div *ngIf="modifydata">
        <div class="d-flex align-items-center mb-3">
          <div class="ms-5-5">
            <h5 class="fw-bold">
              Datos de Prestamista o Acreedor
            </h5>
            <p class="fw-normal mb-2">
              Favor de completar los campos siguientes con tus datos (Prestamista o Acreedor)
            </p>
          </div>
        </div>
        <div class="row custom_card">
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="typePersonLender">Tipo de Acreedor<span>*</span></label>
              <select class="form-control" formControlName="typePersonLender"
                [ngClass]="validateRequiredForm('typePersonLender') ? 'is-invalid' : ''"
                (change)="selectLender($event.target['value'])">
                <option value="">Seleccione</option>
                <option *ngFor="let person of personTypeList" [value]="person.key">{{ person.value }}</option>
              </select>
            </div>
          </div>
          <div *ngIf="externalServiceSelected.serviceName != 'Llamada de cobranza'" class="col-md-3 mb-3"></div>
          <div *ngIf="selectedTypeLender == 0 && externalServiceSelected.serviceName == 'Llamada de cobranza'" class="col-md-3 mb-3"></div>
          <div *ngIf="(selectedTypeLender == 1 || selectedTypeLender == 2) && externalServiceSelected.serviceName == 'Llamada de cobranza'" class="col-md-3 mb-3">
              <div class="form-group">
                <label for="nameCall">Cobrar a nombre de<span>*</span></label>
                <select class="form-control" formControlName="nameCall"  (change)="selectNameCall($event.target['value'])">
                  <option value="1" selected>Nombre Comercial</option>
                  <option value="2" *ngIf="selectedTypeLender == 1">Razón social</option>
                  <option value="3">Nombre contacto</option>
                </select>
              </div>
          </div>
          <div class="col-md-6 mb-3"></div>
          <div class="col-md-6 mb-3" *ngIf="selectedTypeLender == 1">
            <div class="form-group">
              <label for="busisnessNameLender">Razón social<span>*</span></label>
              <input type="text" formControlName="busisnessNameLender" class="form-control"
                [ngClass]="validateRequiredForm('busisnessNameLender') ? 'is-invalid' : ''">
            </div>
          </div>
          <div *ngIf="selectedTypeLender == 1" class="col-md-6 mb-3">
            <div class="form-group">
              <label for="tradename">Nombre comercial<span>*</span></label>
              <input type="text" formControlName="tradename" class="form-control"
                [ngClass]="validateRequiredForm('tradename') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="firstNameLender">Nombre <span
                  *ngIf="selectedTypeLender ==1">&nbsp;contacto</span><span>*</span></label>
              <input type="text" formControlName="firstNameLender" class="form-control"
                [ngClass]="validateRequiredForm('firstNameLender') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="secondNameLender">Segundo nombre</label>
              <input type="text" formControlName="secondNameLender" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="lastNameLender1">Primer apellido<span>*</span></label>
              <input type="text" formControlName="lastNameLender1" class="form-control"
                [ngClass]="validateRequiredForm('lastNameLender1') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="lastNameLender2">Segundo apellido</label>
              <input type="text" formControlName="lastNameLender2" class="form-control">
            </div>
          </div>
          <div *ngIf="selectedTypeLender == 2" class="col-md-3 mb-3">
            <div class="form-group">
              <label for="tradename">Nombre comercial</label>
              <input type="text" formControlName="tradename" class="form-control"
              [ngClass]="validateRequiredForm('tradename') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="phoneLender">Teléfono celular<span>*</span></label>
              <input type="text" [textMask]="{mask: phoneMask}" formControlName="phoneLender" class="form-control"
                [ngClass]="validateRequiredForm('phoneLender') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="phoneOtherLender">Otro teléfono</label>
              <input type="text" [textMask]="{mask: phoneMask}" formControlName="phoneOtherLender" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="emailLender">Correo electrónico</label>
              <input type="text" formControlName="emailLender" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3" *ngIf="selectedTypeLender != 2">
            <div class="form-group">
              <label for="emailOtherLender">Otro correo</label>
              <input type="text" formControlName="emailOtherLender" class="form-control">
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center mb-3">
          <div class="ms-5-5">
            <h5 class="fw-bold">
              Datos de <span>{{ externalServiceType != 'visita de verificación' ? ' Deudor' : ' Cliente' }}</span> 
            </h5>
            <p class="fw-normal mb-2">
              Favor de completar los datos siguientes con los datos del  <span>{{ externalServiceType != 'visita de verificación' ? ' Deudor' : ' Cliente' }}</span> 
            </p>
          </div>
        </div>
        <div class="row custom_card">
          <div class="col-md-3 mb-3" *ngIf ="!idLoans">
            <div class="form-group">
              <label for="typePersonDebtor">Tipo de&nbsp; <span>{{ externalServiceType != 'visita de verificación' ? ' Deudor*' : ' Persona*' }}</span></label>
              <select class="form-control" formControlName="typePersonDebtor"
                [ngClass]="validateRequiredForm('typePersonDebtor') ? 'is-invalid' : ''"
                (change)="selectPersonType($event.target['value'])">
                <option value="">Seleccione</option>
                <option *ngFor="let person of personTypeList" [value]="person.key">{{ person.value }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-9 mb-3" *ngIf ="!idLoans"></div>
          <div *ngIf="personType == 1" class="col-md-6 mb-3">
            <div class="form-group">
              <label for="busisnessNameDebto">Razón social<span>*</span></label>
              <input type="text" formControlName="busisnessNameDebto" class="form-control"
                [ngClass]="validateRequiredForm('busisnessNameDebto') ? 'is-invalid' : ''">
            </div>
          </div>
          <div *ngIf="personType == 1" class="col-md-6 mb-3">
            <div class="form-group">
              <label for="tradenameDebto">Nombre comercial</label>
              <input type="text" formControlName="tradenameDebto" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="firstNameDebto">Nombre <span
                  *ngIf="personType ==1">&nbsp;contacto</span><span>*</span></label>
              <input type="text" formControlName="firstNameDebto" class="form-control"
                [ngClass]="validateRequiredForm('firstNameDebto') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="middleNameDebto">Segundo nombre</label>
              <input type="text" formControlName="middleNameDebto" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="lastName1Debto">Primer apellido<span>*</span></label>
              <input type="text" formControlName="lastName1Debto" class="form-control"
                [ngClass]="validateRequiredForm('lastName1Debto') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="lastName2Debto">Segundo apellido</label>
              <input type="text" formControlName="lastName2Debto" class="form-control">
            </div>
          </div>
          <div *ngIf="personType == 2" class="col-md-3 mb-3">
            <div class="form-group">
              <label for="tradenameDebto">Nombre comercial</label>
              <input type="text" formControlName="tradenameDebto" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="phone">Teléfono celular<span>*</span></label>
              <input type="text" [textMask]="{mask: phoneMask}" formControlName="phone" class="form-control"
                [ngClass]="validateRequiredForm('phone') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="phoneOtherDebto">Otro teléfono</label>
              <input type="text" [textMask]="{mask: phoneMask}" formControlName="phoneOtherDebto" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="email">Correo electrónico</label>
              <input type="text" formControlName="email" class="form-control">
            </div>
          </div>
          <div *ngIf="personType !=2" class="col-md-3 mb-3">
            <div class="form-group">
              <label for="emailOtherDebto">Otro Correo</label>
              <input type="text" formControlName="emailOtherDebto" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="street">Calle<span
                  *ngIf="(externalServiceSelected.serviceName === 'Visita de cobranza' || externalServiceSelected.serviceName === 'Visita de verificación')">*</span></label>
              <input type="text" formControlName="street" class="form-control"
                [ngClass]="validateRequiredForm('street') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="exteriorNumber">Número exterior<span
                  *ngIf="(externalServiceSelected.serviceName === 'Visita de cobranza' || externalServiceSelected.serviceName === 'Visita de verificación')">*</span></label>
              <input type="text" formControlName="exteriorNumber" class="form-control"
                [ngClass]="validateRequiredForm('exteriorNumber') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="interiorNumber">Número interior</label>
              <input type="text" formControlName="interiorNumber" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="districtName">Colonia<span
                  *ngIf="(externalServiceSelected.serviceName === 'Visita de cobranza' || externalServiceSelected.serviceName === 'Visita de verificación')">*</span></label>
              <input type="text" formControlName="districtName" class="form-control"
                [ngClass]="validateRequiredForm('districtName') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="countryId">País<span
                  *ngIf="(externalServiceSelected.serviceName === 'Visita de cobranza' || externalServiceSelected.serviceName === 'Visita de verificación')">*</span></label>
              <select #selectCountry class="form-control" formControlName="countryId"
                (change)="onChangeSelectCountriesRequest(selectCountry.value, false)"
                [ngClass]="!dr && validateRequiredForm('value-CountryId') ? 'is-invalid' : ''">
                <option value="">Seleccione</option>
                <option *ngFor="let item of countryArray" value="{{item.code}}">
                  {{item.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="stateId">Estado<span
                  *ngIf="(externalServiceSelected.serviceName === 'Visita de cobranza' || externalServiceSelected.serviceName === 'Visita de verificación')">*</span></label>
              <select #selectState class="form-control" formControlName="stateId"
                (change)="onChangeSelectStatesRequest(selectState.value, false)"
                [ngClass]="(dr && !validateRequiredForm('stateId')) ? '' : 'is-invalid'">
                <option value="">Seleccione</option>
                <option *ngFor="let item of states" value="{{item.code}}">{{item.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="cityId">Municipio<span
                  *ngIf="(externalServiceSelected.serviceName === 'Visita de cobranza' || externalServiceSelected.serviceName === 'Visita de verificación')">*</span></label>
              <select class="form-control" formControlName="cityId"
                [ngClass]="(dr && !validateRequiredForm('cityId')) ? '' : 'is-invalid'"
                (change)="onChangeSelectCityRequest()">
                <option value="">Seleccione</option>
                <option *ngFor="let item of cities" value="{{item.code}}">{{item.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="zipCode">Código postal<span
                  *ngIf="(externalServiceSelected.serviceName === 'Visita de cobranza' || externalServiceSelected.serviceName === 'Visita de verificación')">*</span></label>
              <input type="number" formControlName="zipCode" class="form-control"
                [ngClass]="dr && validateRequiredForm('zipCode') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-12 mb-3">
            <div class="form-group">
              <label for="referenceAddress">Indicaciones adicionales de esta direccíon</label>
              <textarea type="text" formControlName="referenceAddress" class="form-control" rows="3"></textarea>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center mb-3">
          <div class="ms-5-5">
            <h5 class="fw-bold">
              Datos de Préstamo <span *ngIf="externalServiceType != 'visita de verificación'">/ Adeudo</span>
            </h5>
            <p class="fw-normal mb-2">Favor de completar los datos siguientes con los datos de la cuenta</p>
          </div>
        </div>
        <div class="row custom_card">
          <div class="col-md-3 mb-3" *ngIf="externalServiceType != 'visita de verificación'">
            <div class="form-group">
              <label for="typeLoanDebtor">Tipo de Adeudo<span>*</span></label>
              <select class="form-control" formControlName="typeLoanDebtor"
                [ngClass]="validateRequiredForm('typeLoanDebtor') ? 'is-invalid' : ''"
                (change)="selectTypeLoan($event.target['value'])">
                <option value="1" selected>Préstamo</option>
                <option value="2">Cuenta por Cobrar</option>
              </select>
            </div>
          </div>
          <div class="col-md-9 mb-3" *ngIf="externalServiceType != 'visita de verificación'"></div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="startDate">Fecha de <span *ngIf="selectedLoan ==1">&nbsp; Préstamo</span><span
                  *ngIf="selectedLoan ==2">&nbsp; Venta</span> <span>*</span></label>
              <input autofocus="autofocus" class="form-control" formControlName="startDate" type="date"
                [ngClass]="validateRequiredForm('startDate') ? 'is-invalid' : ''" />
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="idDebt"><span *ngIf="selectedLoan ==1">Número / ID Préstamo</span><span
                  *ngIf="selectedLoan ==2">Factura / ID Venta</span></label>
              <input type="text" formControlName="idDebt" class="form-control">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="loanAmount">Monto de <span *ngIf="selectedLoan ==1">&nbsp; Préstamo*</span>
                <i *ngIf="selectedLoan ==1" class="bx bx-info-circle"
                  matTooltip="{{showHelp('typeLoanDebtorLoan')}}"></i>
                <span *ngIf="selectedLoan ==2">&nbsp; Venta*</span>
                <i *ngIf="selectedLoan ==2" class="bx bx-info-circle"
                  matTooltip="{{showHelp('typeLoanDebtorSale')}}"></i>
              </label>
              <input class="form-control" type="text" placeholder="$ 0" formControlName="loanAmount"
                class="form-control" [textMask]="{mask: currencyMask}" autocomplete="off" autocapitalize="off"
                [ngClass]="validateRequiredForm('loanAmount') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="totalPending">Monto Total a Pagar<span>*</span>
                <i *ngIf="selectedLoan ==1" class="bx bx-info-circle" matTooltip="{{showHelp('selectedLoan')}}"></i>
                <i *ngIf="selectedLoan ==2" class="bx bx-info-circle" matTooltip="{{showHelp('selectedSale')}}"></i>
              </label>
              <input class="form-control text-right" type="text" placeholder="$ 0" formControlName="totalPending"
                class="form-control text-right" [textMask]="{mask: currencyMask}" autocomplete="off"
                autocapitalize="off" [ngClass]="validateRequiredForm('totalPending') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="numberOfPayments">Número de pagos
                <i class="bx bx-info-circle" matTooltip="{{showHelp('numberOfPayments')}}"></i>
              </label>
              <input type="text " formControlName="numberOfPayments" class="form-control text-right">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="totalPaid">Total Abonado</label>
              <input class="form-control text-right" type="text" placeholder="$ 0" formControlName="totalPaid"
                class="form-control text-right" [textMask]="{mask: currencyMask}" autocomplete="off"
                autocapitalize="off">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="totalBalanceToLiquited">Saldo Total Pendiente*
                <i *ngIf="selectedLoan ==1" class="bx bx-info-circle"
                  matTooltip="{{showHelp('totalBalanceToLoan')}}"></i>
                <i *ngIf="selectedLoan ==2" class="bx bx-info-circle"
                  matTooltip="{{showHelp('totalBalanceToSale')}}"></i>
              </label>
              <input class="form-control text-right" type="text" placeholder="$ 0"
                formControlName="totalBalanceToLiquited" class="form-control text-right"
                [textMask]="{mask: currencyMask}" autocomplete="off" autocapitalize="off"
                [ngClass]="validateRequiredForm('totalBalanceToLiquited') ? 'is-invalid' : ''">
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="totalBalanceLate" class="fw-bold black">Saldo Exigible<span>*</span>
                <i *ngIf="selectedLoan ==1" class="bx bx-info-circle" matTooltip="{{showHelp('totalBalanceLoan')}}"></i>
                <i *ngIf="selectedLoan ==2" class="bx bx-info-circle" matTooltip="{{showHelp('totalBalanceSale')}}"></i>
              </label>
              <input class="form-control text-right" type="text" placeholder="$ 0" formControlName="totalBalanceLate"
                class="form-control text-right" [textMask]="{mask: currencyMask}" autocomplete="off"
                autocapitalize="off" [ngClass]="validateRequiredForm('totalBalanceLate') ? 'is-invalid' : ''">
            </div>
          </div>

          <div class="col-12 mb-3" *ngIf="externalServiceType != 'visita de verificación'">
            <label for="debtRason">
              Motivo de Adeudo
            </label>
            <div class="col-12">
              <div class="form-group">
                <textarea formControlName="debtRason" class="form-control" rows="3"></textarea>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3" *ngIf="externalServiceType == 'visita de verificación'">
            <label for="debtRason">
              Motivo de verificación
            </label>
            <div class="col-12">
              <div class="form-group">
                <textarea formControlName="debtRason" class="form-control" rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>


    </div>
      <div class="d-flex align-items-center mb-3" >
        <div class="ms-5-5">
          <h5 class="fw-bold">
            Datos opcionales
          </h5>
          <p>
            Te recomendamos llenar la siguiente información para el gestor que realizará la
            {{externalServiceSelected.serviceName.toLowerCase()}}.
          </p>
        </div>
      </div>
      <div class="row custom_card">
        <h5 class="fw-bold">
          Condiciones de {{externalServiceType}}
        </h5>
        <p class="fw-normal mb-2">
          Si tienes recomendaciones para la {{externalServiceSelected.serviceName.toLowerCase()}}, ingresa la siguiente
          información.
        </p>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="dayVisit">Dia {{externalServiceType}}</label>
            <input autofocus="autofocus" class="form-control" formControlName="dayVisit" type="date"
              [min]="startDateString" [ngClass]="validateRequiredForm('dayVisit') ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="hourStart">Horario desde</label>
            <select #selectHourVisit class="form-control" formControlName="hourStart"
              [ngClass]="dr && validateRequiredForm('hourStart') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let hourRange of formattedHours" [value]="hourRange">{{hourRange}}</option>
            </select>
            <mat-error *ngIf="externalServiceForm.get('hourStart').hasError('hourRange')">La hora de inicio debe ser
              menor que la hora de finalización.</mat-error>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="hourEnd"> Hasta</label>
            <select #selectHourVisit class="form-control" formControlName="hourEnd"
              [ngClass]="dr && validateRequiredForm('hourEnd') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let hourRange of formattedHours" [value]="hourRange">{{hourRange}}</option>
            </select>
            <mat-error *ngIf="externalServiceForm.get('hourEnd').hasError('hourRange')">La hora de finalización debe ser
              mayor que la hora de inicio.</mat-error>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="visitType">Tipo de {{nameTypeCondition}}</label>
            <select class="form-control" formControlName="visitType"
              [ngClass]="dr && validateRequiredForm('visitType') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let item of visitTypeList" value="{{item.value}}">{{item.value}}
              </option>
            </select>
          </div>
        </div>
        <hr>

        <ng-container *ngIf="aditionalCall">
          <h5 class="fw-bold">
            Teléfonos adicionales
          </h5>
          <p class="fw-normal mb-2">
            En caso de que quieras agregar algún otro número telefónico para contactar al Solicitante, ingrésalo a
            continuación.
            Si deseas que algún número telefónico registrado no sea contactado, puedes eliminarlo de la lista.
          </p>
          <form [formGroup]="contactPhoneForm" (ngSubmit)="onSubmitContactPhone()">
            <div *ngIf="!getIsDisabledSave()" class="row">
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Tipo de teléfono </label>
                  <select class="form-control" formControlName="phoneType">
                    <option *ngFor="let item of phoneList" value="{{item.value}}">{{item.value}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Número</label>
                  <input class="form-control" type="text" [textMask]="{mask: phoneMask}" placeholder=""
                    formControlName="phone" autocomplete="off" autocapitalize="off">
                </div>
                <mat-error *ngIf="validateRequeridPhone">
                  El número debe tener 10 dígitos.
                </mat-error>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Comentarios</label>
                  <input class="form-control" type="text" placeholder="" formControlName="comments" autocomplete="off"
                    autocapitalize="off">
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <br>
                <button class="btn btn-outline-primary internal-btn" [disabled]="contactPhoneForm.invalid">
                  Agregar
                </button>
              </div>
            </div>

            <div class="table-responsive mb-3">
              <div class="react-bootstrap-table">
                <table *ngIf="dsContactPhoneData" mat-table [dataSource]="dsContactPhoneData">
                  <ng-container matColumnDef="phoneType">
                    <th mat-header-cell *matHeaderCellDef> Tipo de teléfono
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.phoneType}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Número </th>
                    <td mat-cell *matCellDef="let row">
                      <span>{{row.phone}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="comments">
                    <th mat-header-cell *matHeaderCellDef> Comentarios </th>
                    <td mat-cell *matCellDef="let row"> {{row.comments}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="buttonRemove">
                    <th mat-header-cell *matHeaderCellDef> Eliminar</th>
                    <td mat-cell *matCellDef="let row; let i = index;">
                      <i class="bx bx-x" (click)="onDeleteContactPhone(i)"></i>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="contactPhoneColumns">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: contactPhoneColumns;">
                  </tr>
                </table>
              </div>
            </div>
          </form>
          <hr>
        </ng-container>

        <h5 class="fw-bold">
          Forma de pago
        </h5>
        <p class="fw-normal mb-2">
          Ingresa la información de tu cuenta bancaria en caso que el solicitante desee pagar por medio de un depósito o
          transferencia.
        </p>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="bank">
              Banco
            </label>
            <select class="form-control" formControlName="bank"
              [ngClass]="dr && validateRequiredForm('bank') ? 'is-invalid' : ''">
              <option value="">Seleccione</option>
              <option *ngFor="let item of bankList" value="{{item.value}}">{{item.value}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="account">
              Cuenta
            </label>
            <input class="form-control" formControlName="account" type="text" [textMask]="{mask: accountMask}"
              [ngClass]="validateRequiredForm('account') ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="clabe">
              CLABE
            </label>
            <input class="form-control" formControlName="clabe" type="text" [textMask]="{mask: clabeMask}"
              [ngClass]="validateRequiredForm('clabe') ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="form-group">
            <label for="cardNumber">
              Número de tarjeta
            </label>
            <input class="form-control" formControlName="cardNumber" type="text" [textMask]="{mask: cardMask}"
              [ngClass]="validateRequiredForm('cardNumber') ? 'is-invalid' : ''" />
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="linkPay">
            Enlace de pago
          </label>
          <div class="col-12">
            <div class="form-group">
              <textarea formControlName="linkPay" class="form-control" rows="3"></textarea>
            </div>
          </div>
        </div>
        <hr>



        <h5 class="fw-bold">
          Comentarios adicionales
        </h5>
        <div class="col-12">
          <div class="form-group">
            <textarea formControlName="comments" class="form-control" rows="3"></textarea>
          </div>
        </div>
      </div>

      <app-input-errors-global *ngFor="let item of fExternalServiceProp" fieldName="{{item}}">
      </app-input-errors-global>

      <div class="d-flex justify-content-end align-items-center mt-4 gap-2">
        <button (click)="return()" class="btn_outline" type="button">
          <a class="nav-link btn-mid">Regresar</a>
        </button>

        <button class="btn_yellow">
          <a class="nav-link btn-mid">
            Agregar al carrito
          </a>
        </button>
      </div>
    </form>
  </div>
</div>