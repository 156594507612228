import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserAdministration, UserAdministrationViewModel } from 'src/app/models/user/user.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LoanrequestService } from 'src/app/services/loanrequest/loanrequest.service';
import { GlobalLists } from 'src/app/global/global-data';

@Component({
	selector: 'app-dialog-show-details-administrator',
	templateUrl: './dialog-show-details-administrator.component.html',
	styleUrls: ['./dialog-show-details-administrator.component.scss']
})

export class DialogShowDetailsAdministratorComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	administratorDataSource: MatTableDataSource<any>;
	isLender = false;
	wayToPayList = GlobalLists.wayToPayList;

	rowSelected: UserAdministrationViewModel = <UserAdministrationViewModel>{};
	indexSelected = null;

	detailsAdministratorColumns: string[] = [
		'dateCreated',
		'firstName',
		'middleName',
		'lastName1',
		'lastName2',
		'gender',
		'educationLevel',
		'occupation',
		'birthdayDate',
		'placeOfBirthName',
		'countryBirth',
		'districtName',
		'city',
		'state',
		'country',
		'phoneNumber',
		'userName',
		'packageName',
		'packagePrice',
		'amountWithoutTAX',
		'amount',
		'subscripcionStartDate',
		'subscripcionEndDate',
		'typeSubscription',
		'subscripcionActivated',
		'activeLoansRequest',
		'sumAmountActiveLoansRequest',
		'authorizedLoans',
		'sumAuthorizedLoans',
		'activeLoans',
		'sumAmountActiveLoans',
		'payliquidatedLoans',
		'sumPayliquidatedLoans'
	];

	administrationColumns: string[] = [];
	headerAdministrationColumns: string[] = [];



	constructor(
		public dialog: MatDialogRef<DialogShowDetailsAdministratorComponent>,
		private authService: AuthenticationService,
		private changeDetector: ChangeDetectorRef,
		private loanrequestService: LoanrequestService,
		@Inject(MAT_DIALOG_DATA) public data: UserAdministration) { }

	closeDialog(): void {
		this.dialog.close(false);
	}
	confirm(): void {
		this.dialog.close(true);
	}

	ngOnInit(): void {
		this.isLender = this.authService.currentUserModeLenderValue;

		if (this.data.entry == "details") {
			this.administratorDataSource = new MatTableDataSource(this.data.userAdministrationList);

		} else if (this.data.entry == "loanRequests") {
			this.administrationColumns = [
				'date',
				'lenderFullName',
				'userName',
				'fullName',
				'email',
				'loanAmount',
				'monthlyRate',
				'totalPeriodPay',
				'statusName'
			];

			this.getLoanRequest(this.data.entry);
		} else if (this.data.entry == "loans") {
			this.administrationColumns = [
				'date',
				'lenderFullName',
				'fullName',
				'loanAmount',
				'monthlyRate',
				'totalPeriodPay',
				'balance',
				'balanceExpired',
				'statusName'
			];

			this.getLoanRequest(this.data.entry);
		} else if (this.data.entry == "loanPayments") {
			this.administrationColumns = [
				'datePayment',
				'loanId',
				'paymentAmount',
				'wayToPay',
				'borrowerName',
				'loanAmount',
				'totalPeriodPay',
				'balance',
				'balanceExpired'
			];

			this.getLoanRequest(this.data.entry);
		}

	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewInit(): void {
		if (this.administratorDataSource != null) {
			this.administratorDataSource.paginator = this.paginator;
			this.administratorDataSource.sort = this.sort;
		}
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.administratorDataSource.filter = filterValue.trim().toLowerCase();
	}

	onRowClicked(row, index) {
		this.rowSelected = row;
		this.indexSelected = index;
	}

	onClickPage() {
		this.rowSelected = <UserAdministrationViewModel>{};
		this.indexSelected = null;
	}

	exportExcel() {
		var dataToExport = [];
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("Detalle");
		if (this.data.entry == "details") {
			this.headerAdministrationColumns = [
				"Fecha registro usuario", "Primer nombre", "Segundo nombre", "Primer apellido", "Segundo apellido", "Género", "Nivel de estudios", "Ocupación", "Fecha nacimiento", "Estado nacimiento", "País nacimiento", "Domicilio colonia", "Domicilio municipio", "Domicilio estado", "Domicilio país", "Teléfono celular", "Correo electrónico", "Suscripción actual", "Precio suscripción ", "Monto de suscripción actual sin IVA", "Monto de suscripción actual con IVA", "Fecha inicio suscripción actual", "Fecha fin suscripción actual", "Tipo de usuario", "Estatus usuario", "Solicitudes", "Monto solicitudes", "Solicitudes autorizadas", "Monto solicitudes autorizadas", "Préstamos", "Monto préstamos", "Préstamos liquidados", "Monto préstamos liquidados"
			]
		} else if (this.data.entry == "loanRequests") {
			this.headerAdministrationColumns = [
				"Fecha registro", "Nombre de contacto", "Datos de contacto", "Monto solicitado", "Interés mensual", "Monto total a pagar", "Estatus"
			]
		} else if (this.data.entry == "loans") {
			this.headerAdministrationColumns = [
				"Fecha de registro", "Nombre de contacto", "Monto solicitado", "Interés mensual", "Monto total a pagar", "Saldo a la fecha", "Saldo con atraso", "Estatus"
			]
		} else if (this.data.entry == "loanPayments") {
			this.headerAdministrationColumns = [
				"Fecha de pago", "ID préstamo", "Monto del pago", "Forma de pago", "Nombre del solicitante", "Monto del préstamo", "Monto total a pagar", "Saldo total a la fecha", "Saldo con atraso a la fecha"
			]
		}

		let headerRow = worksheet.addRow(this.headerAdministrationColumns);

		for (let i of this.administratorDataSource.data) {
			if (this.data.entry == "details") {
				dataToExport.push({
					dateCreated: new Date(i.dateCreated).toLocaleDateString('en-GB'),
					firstName: i.firstName,
					middleName: i.middleName,
					lastName1: i.lastName1,
					lastName2: i.lastName2,
					gender: i.gender,
					educationLevel: i.educationLevel,
					occupation: i.occupation,
					birthdayDate: new Date(i.birthdayDate).toLocaleDateString('en-GB'),
					placeOfBirthName: i.placeOfBirthName,
					countryBirth: i.countryBirth,
					districtName: i.districtName,
					city: i.city,
					state: i.state,
					country: i.country,
					phoneNumber: i.phoneNumber,
					userName: i.userName,
					packageName: i.packageName,
					packagePrice: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.packagePrice),
					amountWithoutTAX: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.amountWithoutTAX),
					amount: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.amount),
					subscripcionStartDate: i.subscripcionStartDate == null ? "" : new Date(i.subscripcionStartDate).toLocaleDateString('en-GB'),
					subscripcionEndDate: i.subscripcionEndDate == null ? "" : new Date(i.subscripcionEndDate).toLocaleDateString('en-GB'),
					typeSubscription: i.typeSubscription,
					subscripcionActivated: i.subscripcionActivated,
					activeLoansRequest: i.activeLoansRequest,
					sumAmountActiveLoansRequest: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.sumAmountActiveLoansRequest),
					authorizedLoans: i.authorizedLoans,
					sumAuthorizedLoans: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.sumAuthorizedLoans),
					activeLoans: i.activeLoans,
					sumAmountActiveLoans: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.sumAmountActiveLoans),
					payliquidatedLoans: i.payliquidatedLoans,
					sumPayliquidatedLoans: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.sumPayliquidatedLoans)
				})
			} else if (this.data.entry == "loanRequests") {
				dataToExport.push({
					date: new Date(i.date).toLocaleDateString('en-GB'),
					fullName: i.fullName,
					email: i.email,
					loanAmount: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.loanAmount),
					monthlyRate: i.monthlyRate.toFixed(2) + "%",
					totalPeriodPay: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.totalPeriodPay),
					statusName: i.statusName

				})
			} else if (this.data.entry == "loans") {
				dataToExport.push({
					date: new Date(i.date).toLocaleDateString('en-GB'),
					fullName: i.fullName,
					loanAmount: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.loanAmount),
					monthlyRate: i.monthlyRate.toFixed(2) + "%",
					totalPeriodPay: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.totalPeriodPay),
					balance: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.balance),
					balanceExpired: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.balanceExpired),
					statusName: i.statusName
				})
			} else if (this.data.entry == "loanPayments") {
				dataToExport.push({
					datePayment: new Date(i.date).toLocaleDateString('en-GB'),
					loanId: i.loanId,
					paymentAmount: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.paymentAmount),
					wayToPay: i.wayToPay,
					borrowerName: i.borrowerName,
					loanAmount: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.loanAmount),
					totalPeriodPay: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.totalPeriodPay),
					balance: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.totalPeriodPay),
					balanceExpired: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(i.balanceExpired),
				})
			}

		}

		for (let x1 of dataToExport) {
			let x2 = Object.keys(x1);
			let temp = []

			for (let y of x2) {
				temp.push(x1[y])
			}

			worksheet.addRow(temp)
		}

		let fname = "Detalle de Base de Datos"

		//add data and file name and download
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, fname + '- ' + `${new Date().toISOString()}.xlsx`);
		});
	}

	getLoanRequest(entry: string) {
		this.loanrequestService.getLoanRequestAdministrator(entry).subscribe((result) => {
			this.administratorDataSource = new MatTableDataSource(result);
			this.administratorDataSource.paginator = this.paginator;
			this.administratorDataSource.sort = this.sort;
			this.headerAdministrationColumns;
		});
	}

	getWayToPay(wayToPay: number) {
		return this.wayToPayList.find(x => x.key == wayToPay).value;
	}
}